import React, { Fragment, ChangeEvent, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from '../../ApplicationState';
import { saveFormAbono, addOrEditAbono, setFormsAbono, requestOneAbono, setActivateCreateBill, createAbonoPDF } from './AbonosStore';
import { getFormattedDate, addingOrEditing, lineasAbono, paqueteria, cargaCompleta, suscriptionData } from "aseguisShared";
import NumberFormat from "react-number-format";
import { urlParmsForm, abono, initLineaAbono, calculateIva } from "aseguisShared";
import InputDate from "../../common/Inputs/InputDate";
import { } from "../Login/LoginStore";
import { useHistory, useParams } from "react-router-dom";
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import ClientsAutocomplete from "../Clients/ClientsAutocomplete";
import PaqueteriaAutocomplete from "../Paqueteria/PaqueteriaAutocomplete";
import CargaCompletaAutocomplete from "../CargaCompleta/CargaCompletaAutocomplete";
import { GetSuscriptionDataFromLocalStorage } from "../../common/commonInterfaces/commonFunctions";

interface infoToAbonoForm {
    isEdditing: number;
    errorForm: boolean,
    setErrorForm: (value: boolean) => void,
    setEdditing: (value: addingOrEditing) => void,
}

function AbonoForm(props: infoToAbonoForm): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();

    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const companyIva: number = useSelector((state: ApplicationState) => state.company.company.porIva);
    const abonoForm: abono = useSelector((state: ApplicationState) => state.abonos.abonoForm);
    const abonoEditForm: abono = useSelector((state: ApplicationState) => state.abonos.abonoEditForm);
    const addingOrEditAbono: addingOrEditing = useSelector((state: ApplicationState) => state.abonos.addOrEditAbono) || addingOrEditing.none;
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [errorLinea, setErrorLinea] = useState(false);
    const [errorClients, setErrorClients] = useState(false);
    const [lineaAbonoTmp, setLineaAbonoTmp] = useState(initLineaAbono);
    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    useEffect(() => {
        if (
            lineaAbonoTmp.concepto.length < 2 ||
            lineaAbonoTmp.subTotal <= 0
        ) {
            setErrorLinea(true)
        } else {
            setErrorLinea(false)
        }
    }, [lineaAbonoTmp])


    useEffect(() => {
        if (
            (abonoEditForm.client.nif.length < 2) ||
            (abonoEditForm.lineasAbono.length < 1 && addingOrEditAbono === addingOrEditing.adding) ||
            (errorClients)
        ) {
            props.setErrorForm(true)
        } else {
            props.setErrorForm(false)
        }
    }, [abonoEditForm])

    useEffect(() => {
        let subTotal = 0
        let iva = 0
        let porIva = abonoEditForm.client.porIva > 0 ? abonoEditForm.client.porIva : companyIva

        for (var lineaAbono of abonoEditForm.lineasAbono) {
            subTotal = subTotal + lineaAbono.subTotal
        }

        if (abonoEditForm.client.address.cpMongo.idPais === "ES") {
            iva = calculateIva(subTotal, porIva)
        }

        let total = subTotal + iva

        dispatch(saveFormAbono({
            ...abonoEditForm,
            datosEconomicosAbono: {
                iva: iva,
                porIva: porIva,
                subTotal: subTotal,
                total: total
            }
        }))

    }, [abonoEditForm.lineasAbono.length, abonoEditForm.client.id])

    const listLineasAbono = (lineasAbono: lineasAbono[]) => {
        return lineasAbono.map((lineaAbono, index) => (
            <Fragment key={index}>
                <tr className={`table-row`} key={index}>
                    <td>
                        {lineaAbono.concepto}
                    </td>
                    <td>
                        <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            decimalScale={2}
                            onValueChange={(value) => {
                            }}
                            fixedDecimalScale={true}
                            allowNegative={true}
                            value={lineaAbono.subTotal}
                            className="form-control undefined"
                            renderText={value => <div>{value}</div>}
                            suffix={" \u20AC"}
                        />
                    </td>
                    {(props.isEdditing === addingOrEditing.edditing) &&
                        <td>
                            <button className="btn btn-success btn-red"
                                onClick={() => {
                                    dispatch(saveFormAbono({
                                        ...abonoEditForm,
                                        lineasAbono: abonoEditForm.lineasAbono.filter(concepto => concepto.concepto !== lineaAbono.concepto)
                                    }))
                                }}>
                                <i className="fas fa fa-times"></i>
                            </button>
                        </td>
                    }
                </tr>
            </Fragment>
        ));
    };

    useEffect(() => {
        //desactivamos el crear al renderizar
        dispatch(setActivateCreateBill(false));
    }, [abonoEditForm]);

    //para tener siempre la ultima versión...
    let history = useHistory()
    let { id } = useParams<urlParmsForm>();
    useEffect(() => {
        if (id === "new") {
            dispatch(addOrEditAbono(addingOrEditing.adding));
            props.setEdditing(addingOrEditing.edditing)
        } else {
            requestOneAbono(id)
                .then((abono) => {
                    if (abono.data.abonosResults.length === 1) {
                        dispatch(addOrEditAbono(addingOrEditing.edditing));
                        dispatch(setFormsAbono(abono.data.abonosResults[0]))
                    } else {
                        history.push(allAppRoutes.abonosList)
                    }
                })
                .catch((error) => {
                    history.push(allAppRoutes.abonosList)
                })
        }
    }, [id]);
    //...para tener siempre la ultima versión

    const listPaqueterias = (paqueterias: paqueteria[]) => {
        return paqueterias.map((paqueteria) => (
            <li
                key={paqueteria.id}
                className={""}
                onClick={(event) => {
                }}
            >
                <a>
                    <span style={{ fontWeight: "bold" }}>{"Id: "}</span>
                    <span>{paqueteria.id}</span> &nbsp;&nbsp;
                    <span style={{ fontWeight: "bold" }}>{" Referencia/s: "}</span>
                    <span>{paqueteria.datosTransporte.referenciaString}</span>&nbsp;&nbsp;
                    <span style={{ fontWeight: "bold" }}>{" Dirección: "}</span>
                    <span>{paqueteria.datosTransporte.address.codigoPostal + ", " + paqueteria.datosTransporte.address.localidad}</span>
                    {props.isEdditing === addingOrEditing.edditing &&
                        <span
                            style={{ marginLeft: "20px", cursor: "pointer" }}
                            onClick={() => {
                                dispatch(saveFormAbono({
                                    ...abonoEditForm,
                                    paqueterias: abonoEditForm.paqueterias.filter(paq => paq.id !== paqueteria.id)
                                }))
                            }}
                        >
                            <i className="fa-solid fa-x"></i>
                        </span>
                    }
                </a>
            </li >
        ));
    };

    const listCargas = (cargas: cargaCompleta[]) => {
        return cargas.map((carga) => (
            <li
                key={carga.id}
                className={""}
                onClick={(event) => {
                }}
            >
                <a>
                    <span style={{ fontWeight: "bold" }}>{"Id: "}</span>
                    <span>{carga.id}</span> &nbsp;&nbsp;
                    <span style={{ fontWeight: "bold" }}>{" Referencia/s: "}</span>
                    <span>{carga.datosGenerales.referenciaString}</span>&nbsp;&nbsp;
                    <span style={{ fontWeight: "bold" }}>{" Tipo vehículo: "}</span>
                    <span>{carga.datosGenerales.typeVehicle.description}</span>
                    {props.isEdditing === addingOrEditing.edditing &&
                        <span
                            style={{ marginLeft: "20px", cursor: "pointer" }}
                            onClick={() => {
                                dispatch(saveFormAbono({
                                    ...abonoEditForm,
                                    cargasCompletas: abonoEditForm.cargasCompletas.filter(car => car.id !== carga.id)
                                }))
                            }}
                        >
                            <i className="fa-solid fa-x"></i>
                        </span>
                    }
                </a>
            </li >
        ));
    };

    return (
        <Fragment>

            <div className="card shadow mb-4">

                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">
                        {(addingOrEditAbono !== addingOrEditing.adding) &&
                            <span> {"# " + abonoEditForm.id.toString()}</span>
                        }
                        {(addingOrEditAbono === addingOrEditing.adding) &&
                            <span> {"Crear abono "}</span>
                        }
                    </h6>
                </div>

                <div className="header-buttons">
                    {addingOrEditAbono !== addingOrEditing.adding &&
                        <button
                            className="btn btn-info left-margin btn-sm"
                            onClick={() => {
                                dispatch(createAbonoPDF([abonoEditForm]))
                            }}>
                            Descargar Abono
                        </button>
                    }
                </div>

                <div className="card-body">

                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label style={{ fontWeight: 'bold' }} htmlFor="fecAlta">Fecha de alta</label>
                            <div>{getFormattedDate(abonoEditForm.fecAlta)}</div>
                        </div>

                        <InputDate
                            colMesure="3"
                            editEnabled={props.isEdditing}
                            addOrEdit={addingOrEditing.edditing}
                            name="Fecha de abono"
                            valueEdit={abonoEditForm.fecAbono}
                            value={abonoForm.fecAbono}
                            onChange={(date) => {
                                dispatch(saveFormAbono({
                                    ...abonoEditForm,
                                    fecAbono: date!,
                                    fecAlta: addingOrEditAbono === addingOrEditing.adding ? new Date() : abonoEditForm.fecAlta,
                                }))
                            }}
                        />

                        <div className="form-group col-md-6">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="name">Cliente</label>
                                {props.isEdditing === addingOrEditing.edditing ?
                                    <ClientsAutocomplete
                                        clientName={abonoEditForm.client.nombre}
                                        setClient={(client) => {
                                            dispatch(saveFormAbono({
                                                ...abonoEditForm,
                                                fecAlta: addingOrEditAbono === addingOrEditing.adding ? new Date() : abonoEditForm.fecAlta,
                                                client: client,
                                                paqueterias: client.id !== abonoEditForm.client.id ? [] : abonoEditForm.paqueterias,
                                                cargasCompletas: client.id !== abonoEditForm.client.id ? [] : abonoEditForm.cargasCompletas,
                                            }))
                                        }}
                                        keepClient={true}
                                        setErrorForm={setErrorClients}
                                    />
                                    :
                                    <input
                                        name="abonoCli"
                                        disabled={true}
                                        type="text"
                                        value={abonoForm.client.nombre}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>
                    </div>

                </div>

            </div>


            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">Albaranes relacionados</h6>
                </div>

                <div className="card-body">

                    <div className="form-row">

                        {suscriptionData.paqueteria &&
                            <div className="form-group col-md-6">
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="name">Paqueterias relacionadas</label>
                                    {props.isEdditing === addingOrEditing.edditing &&
                                        <PaqueteriaAutocomplete
                                            setPaqueteria={(paqueteria) => {
                                                dispatch(saveFormAbono({
                                                    ...abonoEditForm,
                                                    paqueterias: [...abonoEditForm.paqueterias, { ...paqueteria, idPaqRender: null }],
                                                    client: paqueteria.datosGenerales.client //OJO, estamos guardando el cliente de la paqueteria, puede ser que no esté actualizado
                                                }))
                                            }}
                                            keepPaqueteria={false}
                                            PaqueteriasToExclude={abonoEditForm.paqueterias}
                                            filterClient={abonoEditForm.client}
                                            onlyFacturated={true}
                                        />
                                    }

                                </div>
                            </div>
                        }

                        <div className="form-group col-md-6">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="name">Cargas completas relacionadas</label>

                                {props.isEdditing === addingOrEditing.edditing &&
                                    <CargaCompletaAutocomplete
                                        setCargaCompleta={(carga) => {
                                            dispatch(saveFormAbono({
                                                ...abonoEditForm,
                                                cargasCompletas: [...abonoEditForm.cargasCompletas, { ...carga, idCargaRender: null }],
                                                client: carga.datosGenerales.client //OJO, estamos guardando el cliente de la carga, puede ser que no esté actualizado
                                            }))
                                        }}
                                        keepCargaCompleta={false}
                                        cargasToExclude={abonoEditForm.cargasCompletas}
                                        filterClient={abonoEditForm.client}
                                        onlyFacturated={true}
                                    />
                                }

                            </div>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <div>
                                <ul className="">
                                    {props.isEdditing === addingOrEditing.edditing ?
                                        listPaqueterias(abonoEditForm.paqueterias)
                                        :
                                        listPaqueterias(abonoForm.paqueterias)
                                    }
                                </ul >
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <div>
                                <ul className="">
                                    {props.isEdditing === addingOrEditing.edditing ?
                                        listCargas(abonoEditForm.cargasCompletas)
                                        :
                                        listCargas(abonoForm.cargasCompletas)
                                    }
                                </ul >
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">Conceptos abono</h6>
                </div>

                <div className="card-body">

                    {props.isEdditing === addingOrEditing.edditing &&
                        <div className="form-row">
                            <div className="form-group col-md-8">
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="name"> Concepto </label>
                                    <input
                                        name="concepto"
                                        type="text"
                                        value={lineaAbonoTmp.concepto}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            setLineaAbonoTmp({ subTotal: lineaAbonoTmp.subTotal, concepto: event.target.value })
                                        }}
                                        className="form-control"
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="name"> SubTotal </label>
                                    <div style={{ display: "flex" }}>
                                        <NumberFormat
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            onValueChange={(value) => {
                                                setLineaAbonoTmp({ subTotal: value.floatValue ? value.floatValue : 0, concepto: lineaAbonoTmp.concepto })
                                            }}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            value={lineaAbonoTmp.subTotal}
                                            className="form-control undefined"
                                            suffix={" \u20AC"}
                                            prefix={" - "}
                                        />
                                        &nbsp;&nbsp;
                                        <button
                                            className={errorLinea === false ? "btn btn-success btn-green" : "btn btn-success btn-green disabled"}
                                            onClick={() => {
                                                if (errorLinea === false) {
                                                    dispatch(saveFormAbono({
                                                        ...abonoEditForm,
                                                        lineasAbono: [...abonoEditForm.lineasAbono, { ...lineaAbonoTmp, subTotal: lineaAbonoTmp.subTotal * -1 }]
                                                    }))
                                                    setLineaAbonoTmp(initLineaAbono)
                                                }
                                            }}>
                                            <i className="fas fa fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="table-hover portlet-600 my-3" key={props.isEdditing}>
                        <table className="table-sm table-bordered" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th scope="col">Concepto</th>
                                    <th scope="col">Subtotal</th>
                                    {(props.isEdditing === addingOrEditing.edditing) &&
                                        <th scope="col">Eliminar</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {(props.isEdditing === addingOrEditing.edditing) &&
                                    <>
                                        {abonoEditForm.lineasAbono.length > 0 ?
                                            listLineasAbono(abonoEditForm.lineasAbono)
                                            :
                                            <tr className={`table-row`}>
                                                <td>
                                                    <span style={{ color: "#dd3a44", fontWeight: "bold" }}>{"Inserte conceptos"}</span>
                                                </td>
                                                <td></td>
                                                {(props.isEdditing === addingOrEditing.edditing) &&
                                                    <td></td>
                                                }
                                            </tr>
                                        }
                                    </>
                                }
                                {(props.isEdditing !== addingOrEditing.edditing) &&
                                    <>
                                        {abonoForm.lineasAbono.length > 0 ?
                                            listLineasAbono(abonoForm.lineasAbono)
                                            :
                                            <tr className={`table-row`}>
                                                <td>
                                                    <span style={{ fontWeight: "bold" }}>{"Sin conceptos"}</span>
                                                </td>
                                                <td></td>
                                                {(props.isEdditing === addingOrEditing.edditing) &&
                                                    <td></td>
                                                }
                                            </tr>
                                        }
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>


                    <div className="form-row mt-4">

                        <div className="form-group col-md-8" style={{ marginTop: '8px' }}>
                        </div>

                        {props.isEdditing === addingOrEditing.edditing &&
                            <div className="form-group col-md-2">
                                <div>
                                    <NumberFormat
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        displayType="text"
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        allowNegative={true}
                                        value={abonoEditForm.datosEconomicosAbono.subTotal}
                                        className="form-control undefined"
                                        renderText={value => <div>{"SubTotal: " + value}</div>}
                                        suffix={" \u20AC"}
                                    />
                                </div>

                                {(abonoEditForm.client.address.cpMongo.idPais === "ES") &&
                                    <div>
                                        <NumberFormat
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            displayType="text"
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowNegative={true}
                                            value={abonoEditForm.datosEconomicosAbono.iva}
                                            className="form-control undefined"
                                            renderText={value => <div>{"IVA: " + value + " (" + (abonoEditForm.client.porIva > 0 ? abonoEditForm.client.porIva : companyIva) + "%)"}</div>}
                                            suffix={" \u20AC"}
                                        />
                                    </div>
                                }

                                <div>
                                    <NumberFormat
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        displayType="text"
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        allowNegative={true}
                                        value={abonoEditForm.datosEconomicosAbono.total}
                                        className="form-control undefined"
                                        renderText={value => <div>{"Total: " + value}</div>}
                                        suffix={" \u20AC"}
                                    />
                                </div>
                            </div>
                        }


                        {props.isEdditing !== addingOrEditing.edditing &&
                            <div className="form-group col-md-2">
                                <div>
                                    <NumberFormat
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        displayType="text"
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        allowNegative={true}
                                        value={abonoForm.datosEconomicosAbono.subTotal}
                                        className="form-control undefined"
                                        renderText={value => <div>{"SubTotal: " + value}</div>}
                                        suffix={" \u20AC"}
                                    />
                                </div>

                                {(abonoForm.client.address.cpMongo.idPais === "ES") &&
                                    <div>
                                        <NumberFormat
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            displayType="text"
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowNegative={true}
                                            value={abonoForm.datosEconomicosAbono.iva}
                                            className="form-control undefined"
                                            renderText={value => <div>{"IVA: " + value + " (" + (abonoForm.client.porIva > 0 ? abonoForm.client.porIva : companyIva) + "%)"}</div>}
                                            suffix={" \u20AC"}
                                        />
                                    </div>
                                }

                                <div>
                                    <NumberFormat
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        displayType="text"
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        allowNegative={true}
                                        value={abonoForm.datosEconomicosAbono.total}
                                        className="form-control undefined"
                                        renderText={value => <div>{"Total: " + value}</div>}
                                        suffix={" \u20AC"}
                                    />
                                </div>
                            </div>
                        }

                    </div>

                </div>
            </div>

        </Fragment>

    );
};

export default AbonoForm
