import React, { Fragment, ReactElement, ChangeEvent, useRef, useLayoutEffect, FocusEvent } from "react";
import { addingOrEditing } from "aseguisShared"

interface infoToInputText {
    colMesure: string
    editEnabled: number;
    addOrEdit: number;
    name: string,
    autoFocus?: boolean
    value: string,
    valueEdit: string,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    onFocus?: (event: FocusEvent<HTMLInputElement>) => void
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void
    className?: string
    autoComplete?: false
    small?: boolean
    showInvalidText?: string
}
function InputText(props: infoToInputText): ReactElement {

    const refFocus = useRef<HTMLInputElement>(null);
    useLayoutEffect(() => {
        if (null !== refFocus.current && props.autoFocus) {
            refFocus.current.focus()
        }
    })

    const setClassName = () => {
        if (props.small) {
            return "form-control form-control-sm"
        } else {
            if (props.className) {
                return props.className
            } else {
                return "form-control"
            }
        }
    }

    return (
        <Fragment>

            <div className={`form-group col-md-${props.colMesure}`}>
                <div>
                    <label style={{ fontWeight: 'bold' }} htmlFor="name"> {props.name} </label>
                    {(props.editEnabled === addingOrEditing.edditing) &&
                        <input
                            name={props.name}
                            type="text"
                            autoComplete={props.autoComplete === false ? "off" : "on"}
                            autoFocus={(props.autoFocus && props.valueEdit === "") && true}
                            value={props.valueEdit}
                            onFocus={(event) => {
                                if (props.onFocus) {
                                    props.onFocus(event)
                                }
                            }}
                            onBlur={(event) => {
                                if (props.onBlur) {
                                    props.onBlur(event)
                                }
                            }}
                            onChange={props.onChange}
                            className={setClassName()}
                            ref={refFocus}
                        />
                    }

                    {(props.editEnabled !== addingOrEditing.edditing && props.addOrEdit === addingOrEditing.edditing) &&
                        <input
                            name="inputText"
                            disabled={true}
                            type="text"
                            value={props.value}
                            className="form-control undefined"
                        />
                    }

                    {(props.editEnabled !== addingOrEditing.edditing && props.addOrEdit === addingOrEditing.adding) &&
                        <input
                            name="inputText"
                            disabled={true}
                            type="text"
                            value={props.valueEdit}
                            className="form-control undefined"
                        />
                    }

                    <div className="text-danger" style={{ height: "20px" }}>
                        <small>
                            {(props.showInvalidText && props.showInvalidText.length > 0 && props.editEnabled === addingOrEditing.edditing) &&
                                props.showInvalidText
                            }
                        </small>
                    </div>

                </div>
            </div>

        </Fragment>

    );
};

export default InputText;