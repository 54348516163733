import { Action, Dispatch } from 'redux';
import { ApplicationState } from '../../ApplicationState';
import axios from 'axios';
import {
    vehicle, inicialiceSearchFilterList, vehicleValidateFieldsResult, searchFilterList,
    addingOrEditing, loading, initialVehicle, inicialiceVehicleValidateFieldsResult,
    searchedVehicles
} from 'aseguisShared';
import { Notify } from '../../common/Toastify/Notify';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface vehiclesState {
    vehicles: vehicle[];
    vehicleForm: vehicle,
    vehicleEditForm: vehicle,
    addOrEditVehicles: number,
    isloadingVehicles: number,
    searchFilterVehicles: searchFilterList,
    totalVehiclesList: number,
    validateFieldsForm: vehicleValidateFieldsResult
    actualPage: number,
    totalPages: number
}

export const initialVehiclesState: vehiclesState = {
    vehicles: [],
    vehicleForm: initialVehicle,
    vehicleEditForm: initialVehicle,
    isloadingVehicles: loading.pending,
    addOrEditVehicles: addingOrEditing.none,
    searchFilterVehicles: inicialiceSearchFilterList,
    totalVehiclesList: 0,
    validateFieldsForm: inicialiceVehicleValidateFieldsResult,
    actualPage: 0,
    totalPages: 0
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface saveFormVehicleAction {
    type: 'SAVE_FORM_VEHICLE',
    vehicle: vehicle
}

export interface addEditVehicleAction {
    type: 'CHANGE_ADD_EDIT_VEHICLE',
    addOrEdit: number
}

export interface addReceivedVehicleAction {
    type: 'ADD_RECEIVED_VEHICLE',
    vehicle: vehicle
}

export interface saveEditReceivedVehicleAction {
    type: 'SAVE_EDIT_RECEIVED_VEHICLE',
    vehicle: vehicle
}

export interface RequestAllVehicleAction {
    type: 'REQUEST_ALL_VEHICLE'
}

export interface ReceivedAllVehicleAction {
    type: 'RECEIVED_ALL_VEHICLE',
    vehicles: searchedVehicles
}
export interface saveSearchVehicleTextAction {
    type: 'SAVE_SEARCH_FILTER_VEHICLE',
    searchFilterVehicle: searchFilterList
}

export interface setBothFormsVehicleAction {
    type: 'SET_BOTH_FORMS_VEHICLE',
    vehicle: vehicle
}

export interface setValidateFormVehicleAction {
    type: 'SET_VALIDATE_FORM_VEHICLE',
    form: vehicleValidateFieldsResult
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = saveFormVehicleAction |
    addReceivedVehicleAction | saveEditReceivedVehicleAction | RequestAllVehicleAction | ReceivedAllVehicleAction |
    addEditVehicleAction | saveSearchVehicleTextAction | setBothFormsVehicleAction | setValidateFormVehicleAction

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export function setFormsVehicle(vehicle: vehicle): setBothFormsVehicleAction {
    return {
        type: 'SET_BOTH_FORMS_VEHICLE',
        vehicle: vehicle
    };
}

export function saveFormVehicle(vehicle: vehicle): saveFormVehicleAction {
    return {
        type: 'SAVE_FORM_VEHICLE',
        vehicle: vehicle
    };
}

export function addOrEditVehicle(addOrEdit: number): addEditVehicleAction {
    return {
        type: 'CHANGE_ADD_EDIT_VEHICLE',
        addOrEdit: addOrEdit
    };
}

export function saveSearchFilterVehicle(searchVehiclesFilter: searchFilterList): saveSearchVehicleTextAction {
    return {
        type: 'SAVE_SEARCH_FILTER_VEHICLE',
        searchFilterVehicle: searchVehiclesFilter
    };
}

export function setValidateVehicleForm(form: vehicleValidateFieldsResult): setValidateFormVehicleAction {
    return {
        type: 'SET_VALIDATE_FORM_VEHICLE',
        form: form
    };
}

export function addVehicle(vehicle: vehicle) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: 'REQUEST_ALL_VEHICLE' });
        return axios.post('api/vehicles/', vehicle)
            .then((data) => {
                Notify("Vehículo creado", "success")
                dispatch({ type: 'ADD_RECEIVED_VEHICLE', vehicle: data.data });
            })
            .catch((error) => {
            })
    }
}

export function editVehicle(vehicle: vehicle) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: 'REQUEST_ALL_VEHICLE' });
        return axios.put('api/vehicles/', vehicle)
            .then((data) => {
                Notify("Vehículo modificado", "success")
                dispatch({ type: 'SAVE_EDIT_RECEIVED_VEHICLE', vehicle: data.data });
            })
            .catch((error) => {
            })
    }
}

export function requestAllVehicles(searchVehiclesFilter: searchFilterList) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        dispatch({ type: 'REQUEST_ALL_VEHICLE' });
        return axios.post<searchedVehicles>('api/vehicles/searchFilter/', searchVehiclesFilter)
            .then((data) => {
                dispatch({ type: 'RECEIVED_ALL_VEHICLE', vehicles: data.data });
            })
            .catch((error) => {
            })
    }

}

export const requestOneVehicle = async (idVehi: string) => {
    let searchFilter = { ...inicialiceSearchFilterList, idMongo: idVehi }
    return await axios.post<searchedVehicles>('api/vehicles/searchFilter/', searchFilter)
}

export function deleteVehicle(vehicle: vehicle) {
    return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
        return axios.post('api/vehicles/delete/', vehicle)
            .then(res => {
                var searchFilter = getState().vehicles.searchFilterVehicles
                dispatch(requestAllVehicles(searchFilter))
            })
            .catch((error) => {
            })
    }
}

export function deleteNotesAndObservaciones(vehicle: vehicle) {
    return async (dispatch: Dispatch) => {
        return axios.put('api/vehicles/controlList/stats/', vehicle)
            .then(res => {
            })
            .catch((error) => {
            })
    }
}

export function validateIfExistsFields(vehicle: vehicle) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        return axios.post('api/vehicles/validateExists/', vehicle)
            .then((data) => {
                return dispatch({ type: 'SET_VALIDATE_FORM_VEHICLE', form: data.data });
            })
            .catch((error) => {
            })
    }
}

export function exportVehiclesToExcel(searchFilter: searchFilterList) {
    return async (dispatch: Dispatch) => {
        return axios({ method: 'post', responseType: 'blob', url: 'api/vehicles/createExcel/getList/', data: searchFilter })
            .then((data) => {
                const blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Aseguis_vehiculos.xlsx";
                link.click();
            })
            .catch((error) => {
            })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
function vehiclesReducer(state: vehiclesState = initialVehiclesState, incomingAction: Action): vehiclesState {

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_BOTH_FORMS_VEHICLE': {
            return {
                ...state,
                vehicleForm: action.vehicle,
                vehicleEditForm: action.vehicle,
                validateFieldsForm: inicialiceVehicleValidateFieldsResult
            }
        }
        case 'SAVE_FORM_VEHICLE': {
            return {
                ...state,
                vehicleEditForm: action.vehicle,
            };
        }
        case 'CHANGE_ADD_EDIT_VEHICLE': {
            return {
                ...state,
                addOrEditVehicles: action.addOrEdit
            }
        }
        case 'ADD_RECEIVED_VEHICLE': {
            return {
                ...state,
                vehicleForm: action.vehicle,
                vehicleEditForm: action.vehicle,
                addOrEditVehicles: addingOrEditing.edditing
            };
        }
        case 'SAVE_EDIT_RECEIVED_VEHICLE': {
            return {
                ...state,
                vehicleForm: action.vehicle,
                vehicleEditForm: action.vehicle
            };
        }
        case 'REQUEST_ALL_VEHICLE': {
            return {
                ...state,
                isloadingVehicles: loading.pending
            };
        }
        case 'RECEIVED_ALL_VEHICLE': {
            return {
                ...state,
                isloadingVehicles: loading.done,
                vehicles: action.vehicles.vehiclesResults,
                totalVehiclesList: action.vehicles.totalResults,
                actualPage: action.vehicles.numPage,
                totalPages: action.vehicles.totalPages
            };
        }
        case 'SAVE_SEARCH_FILTER_VEHICLE': {
            return {
                ...state,
                searchFilterVehicles: action.searchFilterVehicle,
                isloadingVehicles: loading.pending
            };
        }
        case 'SET_VALIDATE_FORM_VEHICLE': {
            return {
                ...state,
                validateFieldsForm: action.form
            };
        }
        default:
            return state;
    }
};

export default vehiclesReducer;