"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialReviRepa = void 0;
const commonEnums_1 = require("../enums/commonEnums");
const loginFunctions_1 = require("./loginFunctions");
const vehiclesFunctions_1 = require("./vehiclesFunctions");
exports.initialReviRepa = {
    _id: null,
    id: 0,
    fecAlta: new Date(),
    fecRevision: new Date(),
    fecProxRevision: new Date(),
    //revision: initialRevision,
    kmProxRevision: 0,
    vehicle: vehiclesFunctions_1.initialVehicle,
    kms: 0,
    import: 0,
    observaciones: "",
    typeReviRepa: commonEnums_1.reviRepaTypes.revision,
    //employee: inicialiceEmployee
    user: loginFunctions_1.inicialiceLoginUser
};
