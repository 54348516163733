import React, { Fragment, useEffect, ReactElement, useState, useRef, useLayoutEffect, ChangeEvent } from "react";
import { openURL } from "./../commonInterfaces/commonFunctions";
import {
    validateAlphaNumeric, validateFormEmpty, addingOrEditing, address, postalCode,
    CapitalizefirstLetter, formatedDirection, replaceAnyDirectionFieldsOnStreet, splitTextBySpaces, quitarAcentosyMayusculas
} from "aseguisShared";
import ClientsDestinationsAutocomplete, { Destination, initDestination } from "./ClientsDestinationsAutocomplete";
import { PostalCodeApi } from "./../commonInterfaces/PostalCodeApi";
import { Button, Modal } from "react-bootstrap";
import CountryModal from "./CountryModal";
import GoogleAutocomplete from "./GoogleAutocomplete";
import { setSessionTokenForGoogle } from "../globalState/globalStateStore"
import { useDispatch } from "react-redux"

enum clientsOrGoogle {
    none = 0,
    clients = 1,
    google = 2
}

interface infoToClientDestinationsAutocomplete {
    onClick: (destination: Destination, fromModal: boolean) => void
    initialAddress: address
    activateClientsDirs: boolean
    placeholder: string
    isEditing: number
    valueNoEditing: string

    autoFocus?: boolean
    onBlur?: () => void
    onFocus?: () => void
    setErrorForm?: (value: boolean) => void
    clientID?: string
}

function GoogleAndClientsSuggestions(props: infoToClientDestinationsAutocomplete): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch()

    const refFocus = useRef<HTMLInputElement | null>(null);
    useLayoutEffect(() => {
        if (refFocus.current !== null && props.autoFocus) {
            refFocus.current!.focus();
        }
    }, [props.autoFocus]);


    const { getPostalCodeManually } = PostalCodeApi()

    const [searchText, setSearchText] = useState<string>(formatedDirection(props.initialAddress))
    const [googleOrClientsDests, setGoogleOrClientsDests] = useState<clientsOrGoogle>(clientsOrGoogle.none)
    const [addressSelected, setAddressSelected] = useState<Destination>({ ...initDestination, address: props.initialAddress })
    const [focusClientsDest, setFocusClientsDest] = useState(false)
    const [focusGoogleDest, setFocusGoogleDest] = useState(true)
    const [focusInput, setfocusInput] = useState(false)
    const [loadingApi, setLoadingApi] = useState(false)

    //MODAL más de 1 cp
    const [postalCodesGetted, setPostalCodesGetted] = useState<postalCode[]>([])
    const [modalOpened, setModalOpened] = useState(false)
    const [textToModal, setTextToModal] = useState("")
    const [addressToModal, setAddressToModal] = useState(initDestination)
    const handleClose = () => setModalOpened(false)

    //MODAL seleccionar pais
    const [countrySelected, setCountrySelected] = useState(props.initialAddress.cpMongo.idPais.length > 0 ? props.initialAddress.cpMongo.idPais : "ES")
    const [modalCountry, setModalCountry] = useState(false)

    const renewGooglePlacesToken = () => {
        dispatch(setSessionTokenForGoogle())
    }

    useEffect(() => {
        //decidimos que input se muestra dependiendo de la primera posicion. 
        //primera posicion numero, a destinos clientes, letra, a google
        if (searchText.length === 1) {
            if (props.activateClientsDirs === true) {
                const firstPosition = searchText.substring(0, 1)
                if (!isNaN(firstPosition as any)) {
                    setGoogleOrClientsDests(clientsOrGoogle.clients)
                    setFocusClientsDest(true)
                } else {
                    setGoogleOrClientsDests(clientsOrGoogle.google)
                    setFocusGoogleDest(true)
                }
            } else {
                setGoogleOrClientsDests(clientsOrGoogle.google)
                setFocusGoogleDest(true)
            }
        }
        if (searchText.length === 0) {
            setGoogleOrClientsDests(clientsOrGoogle.none)
            setFocusGoogleDest(false)
            setFocusClientsDest(false)
            setfocusInput(true)
        }
    }, [searchText]);

    useEffect(() => {
        // vemos si hay errores en la calle.
        if (props.setErrorForm) {
            if (formatedDirection(addressSelected.address).length < 5) {
                props.setErrorForm(true)
            } else {
                props.setErrorForm(false)
            }
        }
        renewGooglePlacesToken()
        setFocusGoogleDest(false)
        setFocusClientsDest(false)
    }, [addressSelected])

    useEffect(() => { 
        //si no, cuando recargamos pagina en form, no coge la direccion y no deja guardar
        setAddressSelected({ ...initDestination, address: props.initialAddress })
    }, [props.initialAddress]);

    useEffect(() => {
        // para cuando hacemos click en reestablecer, se restablezcan los valores
        if (focusInput === false && focusClientsDest === false && focusGoogleDest === false) {
            if (formatedDirection(props.initialAddress) !== searchText) {
                setSearchText(formatedDirection(props.initialAddress))
            }
        }
    }, [props.initialAddress]);

    useEffect(() => {
        //para que al poner la direccion desde otro lugar, se actualice
        if (focusInput === true && focusClientsDest === false && focusGoogleDest === false) {
            if (formatedDirection(props.initialAddress) !== searchText) {
                setSearchText(formatedDirection(props.initialAddress))
            }
        }
    }, [props.initialAddress]);

    const getCalle = (newAddress: Destination, postalCode: postalCode) => {
        if (newAddress.address.calle.length > 0) {
            return newAddress.address.calle.replace(/(\S*\*\S*)|\*/g, ""); //sustituimos las palabras que contienen astericos
        }
        if (newAddress.address.formatedAddress.length > 0) {
            let street = replaceAnyDirectionFieldsOnStreet(newAddress.address.formatedAddress, postalCode)
            return street.replace(/(\S*\*\S*)|\*/g, "");
        }
        if (newAddress.address.otherName.length > 0) {
            return newAddress.address.otherName.replace(/(\S*\*\S*)|\*/g, "");
        }
        return ""
    }

    const saveSearchAddressTextPaqueteria = async (stringSearch: string, newAddress: Destination) => {

        setLoadingApi(true)

        let postalCodes: postalCode[] | undefined = [];

        if (newAddress.address.placeId.length > 0) {
            postalCodes = await getPostalCodeManually({ strings: splitTextBySpaces("*" + newAddress.address.codigoPostal), country: countrySelected })
        } else {
            if (stringSearch.includes("*")) {
                postalCodes = await getPostalCodeManually({ strings: splitTextBySpaces(stringSearch), country: countrySelected })
            }
        }

        setPostalCodesGetted([])
        setTextToModal("")
        setAddressToModal(newAddress)
        setModalOpened(false)

        if (postalCodes && postalCodes.length === 1) {
            await setAddress(stringSearch, newAddress, postalCodes[0], false)
        } else {
            if (postalCodes && postalCodes.length > 1) {
                setPostalCodesGetted(postalCodes)
                setTextToModal(stringSearch)
                setModalOpened(true)
            }
        }

        setLoadingApi(false)
    }

    const setAddress = async (stringSearch: string, newAddress: Destination, postalCode: postalCode, fromModal: boolean) => {
        const addressToReturn: Destination = {
            ...newAddress,
            address: {
                ...newAddress.address,
                calle: newAddress.address.placeId.length > 0 ? getCalle(newAddress, postalCode) : replaceAnyDirectionFieldsOnStreet(stringSearch, postalCode),
                codigoPostal: postalCode.cp,
                provincia: postalCode.provincia,
                localidad: postalCode.poblacion,
                pais: postalCode.pais,
                cpMongo: postalCode
            }
        }
        setSearchText(formatedDirection(addressToReturn.address))
        setAddressSelected(addressToReturn)
        props.onClick(addressToReturn, fromModal)
        setCountrySelected(addressToReturn.address.cpMongo.idPais)

        /* para que no deje seguir escribiendo */
        setFocusGoogleDest(false)
        setFocusClientsDest(false)
        setfocusInput(false)
        setGoogleOrClientsDests(clientsOrGoogle.none)
        setModalOpened(false)
    }

    const [goApi, setGoApi] = useState('')
    useEffect(() => {
        if (searchText.includes('*')) {
            const timeoutDuration = 1000;
            const timeout = setTimeout(() => {
                saveSearchAddressTextPaqueteria(searchText, initDestination)
            }, timeoutDuration)
            return () => clearTimeout(timeout)
        }
    }, [goApi])


    /* PROHIBIMOS COPIAR Y PEGAR */
    const containerRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const handlePaste = (event: ClipboardEvent): void => {
            event.preventDefault();
            event.stopPropagation();
        };
        const container = containerRef.current;
        if (container) {
            container.addEventListener('paste', handlePaste);
        }
        return () => {
            if (container) {
                container.removeEventListener('paste', handlePaste);
            }
        };
    }, []);

    return (
        <Fragment>

            {props.isEditing === addingOrEditing.edditing ?
                <div ref={containerRef}>

                    <div className="input-group mb-2"
                        style={{ flex: 1 }}
                        onMouseDown={(event) => {
                            if (focusClientsDest || focusGoogleDest) {
                                //para que deje clickar en los destinos. si no, realiza el onblur
                                //(mouseDown se ejecuta antes que onBlur)
                                event.preventDefault()
                            }
                        }}
                        onBlur={(event) => {

                            if (props.onBlur) {
                                props.onBlur()
                            }

                            //en onBlur ponemos todos los inputs focus a false
                            setFocusGoogleDest(false)
                            setFocusClientsDest(false)
                            setfocusInput(false)

                            //cuando se hace onblur ponemos el input en el inputText 
                            //para que vuelva a hacer focus si es necesario
                            setGoogleOrClientsDests(clientsOrGoogle.none)

                            // esto para que cuando haga onBlur, si existe direccion, la vuelva a poner
                            setSearchText(formatedDirection(props.initialAddress))
                        }}
                        onFocus={() => {
                            if (props.onFocus) {
                                props.onFocus()
                            }

                            // esto para que cuando haga click en el input, se borre la direccion
                            // hay que borrarla porque si escribe lo que quiera se complica la gestion del texto
                            if (searchText.length > 1) {
                                setSearchText("")
                            }
                        }}
                    >

                        <div className="input-group-prepend"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setModalCountry(true)
                            }}
                        >
                            <div
                                className="input-group-text"
                                style={{
                                    paddingLeft: "2px !important",
                                    paddingTop: "0px !important",
                                    paddingBottom: "0px !important",
                                    paddingRight: "0px !important"
                                }}
                            >
                                {countrySelected}
                            </div>
                        </div>


                        {googleOrClientsDests === clientsOrGoogle.none &&
                            <input
                                type="text"
                                value={searchText}
                                ref={refFocus}
                                autoComplete="off"
                                autoFocus={props.autoFocus === true ? props.autoFocus : focusInput}
                                placeholder={props.placeholder}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                    setSearchText(value)
                                }}
                                className={props.setErrorForm ? validateFormEmpty(searchText, 5) : "form-control undefined"}
                                id="searchPlaceString"
                            />
                        }

                        {googleOrClientsDests === clientsOrGoogle.clients &&
                            <ClientsDestinationsAutocomplete
                                country={countrySelected}
                                clientID={props.clientID ? props.clientID : ""}
                                textToSearch={searchText}
                                saveTextToSearch={(value) => {
                                    setSearchText(value)
                                }}
                                autoFocus={focusClientsDest}
                                onSelectAddress={(dest) => {
                                    setCountrySelected(dest.address.cpMongo.idPais)
                                    props.onClick(dest, false)
                                    setAddressSelected(dest)
                                    setSearchText(formatedDirection(dest.address))
                                }}
                            />
                        }

                        {googleOrClientsDests === clientsOrGoogle.google &&
                            <GoogleAutocomplete
                                country={countrySelected}
                                textToSearch={searchText}
                                saveTextToSearch={(value) => {
                                    setSearchText(value)
                                    setGoApi(searchText)
                                }}
                                autoFocus={focusGoogleDest}
                                onSelectAddress={async (destination) => {
                                    var allDir = destination.address.calle + " ";
                                    allDir += destination.address.codigoPostal + " ";
                                    allDir += destination.address.localidad + " ";
                                    allDir += destination.address.formatedAddress + " ";
                                    allDir += destination.address.otherName + " ";
                                    allDir += destination.address.vicinity + " ";
                                    allDir = quitarAcentosyMayusculas(allDir);
                                    await saveSearchAddressTextPaqueteria(allDir, destination)
                                }}
                            />
                        }

                        {loadingApi === true ?
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <span className="spinner-border spinner-border-sm"></span>
                                </div>
                            </div>
                            : props.initialAddress.url.length > 0 ?
                                <div
                                    className="input-group-prepend"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        openURL(props.initialAddress.url)
                                    }}
                                >
                                    <div className="input-group-text">
                                        <i className="fa-brands fa-google"></i>
                                    </div>
                                </div>
                                :
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <span style={{ width: 16 }}></span>
                                    </div>
                                </div>
                        }


                        {/* MODAL PARA CUANDO EXISTE MÁS DE UN LUGAR CON MISMO CP */}
                        <Modal show={modalOpened} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Seleccione lugar correcto</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row align-items-center m-3">
                                    <table className="table-sm table table-hover" style={{ width: "100%" }}>
                                        <thead>
                                        </thead>
                                        <tbody>
                                            {postalCodesGetted.map((postalCode, index: number) => {
                                                return (
                                                    <tr key={index}
                                                        className={`table-row`}
                                                        onClick={async () => {
                                                            await setAddress(textToModal, addressToModal, postalCode, true)
                                                        }}
                                                    >
                                                        <td>
                                                            {postalCode.cp + " " + postalCode.poblacion + " " + postalCode.provincia + " " + postalCode.pais}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                            </Modal.Footer>
                        </Modal>


                        {/* MODAL PARA CAMBIAR DE PAIS */}
                        <CountryModal
                            setCountrySelected={setCountrySelected}
                            modalOpened={modalCountry}
                            setModalOpened={setModalCountry}
                        />


                    </div>

                </div>

                :

                <div
                    className="input-group mb-2"
                    style={{ flex: 1 }}
                >
                    {props.initialAddress.url.length > 0 &&
                        <div
                            className="input-group-prepend"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                openURL(props.initialAddress.url)
                            }}
                        >
                            <div className="input-group-text">
                                <i className="fa-brands fa-google"></i>
                            </div>
                        </div>
                    }
                    <input
                        name="googleAddress"
                        disabled={true}
                        type="text"
                        value={props.valueNoEditing}
                        className="form-control undefined"
                    />
                </div>
            }

        </Fragment>
    );
};

export default GoogleAndClientsSuggestions;