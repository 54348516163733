import React, { Fragment } from "react";
import CookieConsent from "react-cookie-consent";
import { allAppRoutes } from "../commonInterfaces/allAppRoutes";

const CookiesAccept: React.FC = () => {
    return (
        <Fragment>
            <CookieConsent
                location="bottom"
                buttonText="Entendido"
                cookieName="MyCookie"
                style={{
                    background: "#013246",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "20px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    maxWidth: "400px",
                    margin: "0 auto",
                    position: "fixed",
                    bottom: "20px",
                    right: "0%",
                    left: "100% !important",
                }}
                buttonStyle={{
                    background: "#c3f0ff",
                    color: "#000000",
                    fontSize: "16px",
                    borderRadius: "8px",
                    padding: "10px 20px",
                }}
                contentStyle={{
                    textAlign: "center",
                    color: "#ffffff",
                }}
                expires={31}
            >
                <div style={{fontSize: "16px", fontWeight: "bold"}}>
                    ¡Esta aplicación utiliza cookies!
                </div>

                <div style={{fontSize: "14px", marginTop: "10px"}}>
                    Usamos cookies para mejorar tu experiencia.
                    <br></br>
                    Al continuar, aceptas su uso.
                    <br></br>
                    <br></br>
                    Consulta nuestra
                    <a
                        href={allAppRoutes.politicaCookies}
                        target="_blank"
                        style={{color: "#58cfff", textDecoration: "none", fontWeight: "bold", marginLeft: "5px"}}
                    >
                        Política de cookies
                    </a>
                    .
                </div>
            </CookieConsent>
        </Fragment>
    );
};

export default CookiesAccept;
