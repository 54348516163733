import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useState, ReactElement } from "react";
import { ApplicationState } from '../../ApplicationState';
import { paqueteria, showListAgrupa, localities, postalCodes, provinces, countries } from "aseguisShared";

enum agrupaShow {
    none = 0,
    localidad = 1,
    provincia = 2,
    postalCode = 3,
    country = 4
}

interface infoToPaqueteriaRutasList {
    allPaqueterias: paqueteria[]
    showPaqueteriaList: (number: number) => void
}
function PaqueteriaRutasList(props: infoToPaqueteriaRutasList): ReactElement {

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);

    const [allLocalities, setAllLocalities] = useState<localities[]>([])
    const [allCP, setAllCP] = useState<postalCodes[]>([])
    const [allProvinces, setAllProvinces] = useState<provinces[]>([])
    const [allCountries, setAllCountries] = useState<countries[]>([])

    const [whatAgrupaShow, setWhatAgrupaShow] = useState<number>(agrupaShow.none)

    function calculateLocalities(): void {
        var localities: localities[] = []
        props.allPaqueterias.forEach((paqueteria) => {
            /******** LOCALIDAD ********/
            var localityFound = false
            localities.map(locality => {
                if (locality.locality === paqueteria.datosTransporte.address.localidad) {
                    locality.paqueterias.push(paqueteria)
                    localityFound = true
                }
                return locality;
            })
            if (localityFound === false) {
                localities.push({
                    locality: paqueteria.datosTransporte.address.localidad,
                    paqueterias: [{ ...paqueteria }]
                })
            }
        })
        setAllLocalities(localities)
        setWhatAgrupaShow(agrupaShow.localidad)
    }
    function calculatePostalCodes(): void {
        var postalCodes: postalCodes[] = []
        props.allPaqueterias.forEach((paqueteria) => {
            /******** CODIGO POSTAL *******/
            var postalCodeFound = false
            postalCodes.map(cp => {
                if (cp.postalCode === paqueteria.datosTransporte.address.codigoPostal) {
                    cp.paqueterias.push(paqueteria)
                    postalCodeFound = true
                }
                return cp;
            })
            if (postalCodeFound === false) {
                postalCodes.push({
                    postalCode: paqueteria.datosTransporte.address.codigoPostal,
                    paqueterias: [{ ...paqueteria }]
                })
            }
        })
        setAllCP(postalCodes)
        setWhatAgrupaShow(agrupaShow.postalCode)
    }
    function calculateProvinces(): void {
        var provinces: provinces[] = []
        props.allPaqueterias.forEach((paqueteria) => {
            /******** PROVINCIA ********/
            var provinceFound = false
            provinces.map(province => {
                if (province.province === paqueteria.datosTransporte.address.provincia) {
                    province.paqueterias.push(paqueteria)
                    provinceFound = true
                }
                return province;
            })
            if (provinceFound === false) {
                provinces.push({
                    province: paqueteria.datosTransporte.address.provincia,
                    paqueterias: [{ ...paqueteria }]
                })
            }
        })
        setAllProvinces(provinces)
        setWhatAgrupaShow(agrupaShow.provincia)
    }
    function calculateCountries(): void {
        var countries: countries[] = []
        props.allPaqueterias.forEach((paqueteria) => {
            /******** PAIS ********/
            var countryFound = false
            countries.map(country => {
                if (country.country === paqueteria.datosTransporte.address.pais) {
                    country.paqueterias.push(paqueteria)
                    countryFound = true
                }
                return country;
            })
            if (countryFound === false) {
                countries.push({
                    country: paqueteria.datosTransporte.address.pais,
                    paqueterias: [{ ...paqueteria }]
                })
            }
        })
        setAllCountries(countries)
        setWhatAgrupaShow(agrupaShow.country)
    }

    const localitiesList: JSX.Element[] = allLocalities.map((locality) => {
        return (
            <table className="table" key={locality.locality}>
                <thead className="thead-light">
                    <tr key={locality.locality}>
                        <th scope="col">{locality.locality}</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {locality.paqueterias.map((paqueteria, index) => {
                        return (
                            <tr key={index}>
                                <td>{paqueteria.datosTransporte.address.localidad}</td>
                                <td>{paqueteria.datosTransporte.address.codigoPostal}</td>
                                <td>{paqueteria.datosTransporte.address.pais}</td>
                                <td>{paqueteria.datosTransporte.address.provincia}</td>
                            </tr>
                        )
                    })}
                    <tr className={'table-row'}>
                        <td>{""}</td>
                        <td>{""}</td>
                        <td>{""}</td>
                        <td>{""}</td>
                    </tr>
                    <tr className={'table-row'}>
                        <th scope="row">{"Total:"}</th>
                        <th>{""}</th>
                        <th scope="row">{"Bultos:"}</th>
                        <th scope="row">{"Peso:"}</th>
                    </tr>
                </tbody>
            </table>
        );
    });

    const postalCodeList: JSX.Element[] = allCP.map((cp) => {
        return (
            <table className="table" key={cp.postalCode}>
                <thead className="thead-light">
                    <tr key={cp.postalCode}>
                        <th scope="col">{cp.postalCode}</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {cp.paqueterias.map((paqueteria, index) => {
                        return (
                            <tr key={index}>
                                <td>{paqueteria.datosTransporte.address.codigoPostal}</td>
                                <td>{paqueteria.datosTransporte.address.localidad}</td>
                                <td>{paqueteria.datosTransporte.address.pais}</td>
                                <td>{paqueteria.datosTransporte.address.provincia}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    });

    const provincesList: JSX.Element[] = allProvinces.map((province) => {
        return (
            <table className="table" key={province.province}>
                <thead className="thead-light">
                    <tr key={province.province}>
                        <th scope="col">{province.province}</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {province.paqueterias.map((paqueteria, index) => {
                        return (
                            <tr key={index}>
                                <td>{paqueteria.datosTransporte.address.provincia}</td>
                                <td>{paqueteria.datosTransporte.address.codigoPostal}</td>
                                <td>{paqueteria.datosTransporte.address.localidad}</td>
                                <td>{paqueteria.datosTransporte.address.pais}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    });

    const countriesList: JSX.Element[] = allCountries.map((country) => {
        return (
            <table className="table" key={country.country}>
                <thead className="thead-light">
                    <tr key={country.country}>
                        <th scope="col">{country.country}</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {country.paqueterias.map((paqueteria, index) => {
                        return (
                            <tr key={index}>
                                <td>{paqueteria.datosTransporte.address.pais}</td>
                                <td>{paqueteria.datosTransporte.address.provincia}</td>
                                <td>{paqueteria.datosTransporte.address.codigoPostal}</td>
                                <td>{paqueteria.datosTransporte.address.localidad}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    });

    return (

        <Fragment>

            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        <div className="card-header">
                            <h6 className="m-0 font-weight-bold text-green">Agrupaciones</h6>

                            <div className="header-buttons">
                                <button className="btn btn-info left-margin btn-sm" onClick={calculateLocalities}>
                                    Por Localidad
                                </button>
                                <button className="btn btn-info left-margin btn-sm" onClick={calculatePostalCodes}>
                                    Por C�digo Postal
                                </button>
                                <button className="btn btn-info left-margin btn-sm" onClick={calculateProvinces}>
                                    Por Provincia
                                </button>
                                <button className="btn btn-info left-margin btn-sm" onClick={calculateCountries}>
                                    Por Pa�s
                                </button>
                                <button
                                    className="btn btn-danger left-margin btn-sm"
                                    onClick={() => {
                                        props.showPaqueteriaList(showListAgrupa.list)
                                    }}>
                                    Volver
                                </button>
                            </div>
                        </div>

                        {(isAppLoading === false) ?
                            <div className="card-body">
                                <div className="table-responsive table-hover portlet-600">

                                    {whatAgrupaShow === agrupaShow.localidad && localitiesList}
                                    {whatAgrupaShow === agrupaShow.provincia && provincesList}
                                    {whatAgrupaShow === agrupaShow.postalCode && postalCodeList}
                                    {whatAgrupaShow === agrupaShow.country && countriesList}

                                    {whatAgrupaShow === agrupaShow.none && <div>Seleccione agrupacion...</div>}

                                </div>
                            </div>

                            : <div className="alert alert-success" style={{ display: 'flex', justifyContent: 'center' }}>
                                <span className="spinner-border spinner-border-sm mr-4"></span>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </Fragment >

    );
};

export default PaqueteriaRutasList;
