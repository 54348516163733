"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initIncidenceType = exports.initialParametrizacion = void 0;
exports.initialParametrizacion = {
    vehiclesTypes: [],
    vehiclesControlesTypes: [],
    primaryColor: "",
    secondColor: "",
    remesaIdentifier: "",
    companyLogo: "",
    incidencesTypes: [],
    companySign: ""
};
exports.initIncidenceType = {
    id: 0,
    fecAlta: new Date(),
    description: "",
    observaciones: ""
};
