import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, ReactElement, useState } from "react";
import { ApplicationState } from '../../ApplicationState';
import { exportAbonosToExcel, requestAllAbonos, } from './AbonosStore';
import AbonosListFilter from "./AbonosListFilter";
import { filterAbonoTableTH } from "../../common/commonInterfaces/thFilters";
import { GetSuscriptionDataFromLocalStorage, Pagination } from "../../common/commonInterfaces/commonFunctions";
import { ButtonEditarPrivate } from "../../common/Buttons/ButtonEditarPrivate";
import { abono, getFormattedDateAndHour, searchFilterList, suscriptionData } from "aseguisShared";
import NumberFormat from "react-number-format";
import AddButtonList from "../../common/Buttons/AddButtonList";

interface infoToAbonosList {
    onClickAbono: (abono: abono) => void
    addAbono?: () => void
    searchAbonosFilter: searchFilterList
    saveSearchAbonosFilter: (filter: searchFilterList) => void
}

function AbonosList(props: infoToAbonosList): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();
    const isModifyEnabled = ButtonEditarPrivate().isModifyEnabled();

    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const allAbonos: abono[] = useSelector((state: ApplicationState) => state.abonos.allAbonos);
    const countAllAbonos: number = useSelector((state: ApplicationState) => state.abonos.totalAbonosList);
    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const numPage: number = useSelector((state: ApplicationState) => state.abonos.actualPageAbono);
    const totalPages: number = useSelector((state: ApplicationState) => state.abonos.totalPagesAbono);
    const employeEditForm: abono = useSelector((state: ApplicationState) => state.abonos.abonoEditForm);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    useEffect(() => {
        if (props.searchAbonosFilter.withTimeout) {
            const timeout = setTimeout(() => {
                dispatch(requestAllAbonos(props.searchAbonosFilter));
            }, 600)
            return () => clearTimeout(timeout)
        } else {
            dispatch(requestAllAbonos(props.searchAbonosFilter));
        }
    }, [props.searchAbonosFilter, employeEditForm]);


    const abonosList: JSX.Element[] = allAbonos.map(abono => {
        return (
            <tr className={`table-row`}
                onClick={() => {
                    props.onClickAbono(abono)
                }}
                key={abono.id}>
                <td><strong>{abono.id}</strong></td>
                <td>{getFormattedDateAndHour(abono.fecAbono)}</td>
                <td>{abono.client.nombre}</td>
                <td>{abono.cargasCompletas.length}</td>

                {(suscriptionData.paqueteria) &&
                    <td>{abono.paqueterias.length}</td>
                }

                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={true}
                        value={abono.datosEconomicosAbono.subTotal}
                        className="form-control undefined"
                        renderText={value => <div>{value}</div>}
                        suffix={" €"}
                    />
                </td>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={true}
                        value={abono.datosEconomicosAbono.iva}
                        className="form-control undefined"
                        renderText={value => <div>{value}</div>}
                        suffix={" €"}
                    />
                </td>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={true}
                        value={abono.datosEconomicosAbono.total}
                        className="form-control undefined"
                        renderText={value => <div>{value}</div>}
                        suffix={" €"}
                    />
                </td>
            </tr>
        );
    });

    return (

        <Fragment>

            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        {/*anadir button*/}
                        {(props.addAbono && isModifyEnabled) &&
                            <div className="header-buttons">
                                <button
                                    className="btn btn-info left-margin btn-sm"
                                    onClick={() => {
                                        if (allAbonos.length > 0) {
                                            dispatch(
                                                exportAbonosToExcel({
                                                    ...props.searchAbonosFilter,
                                                    getAll: true
                                                })
                                            )
                                        }
                                    }}>
                                    Exportar a Excel
                                </button>

                                <AddButtonList
                                    addCallback={props.addAbono}
                                />

                            </div>
                        }

                        <div className="card-header">

                            <div className="form-inline">

                                <h6 className="m-0 font-weight-bold text-green">Lista de abonos {"(" + countAllAbonos + ")"}</h6>

                                {(isAppLoading === true) &&
                                    <span className="spinner-border spinner-border-sm ml-4"></span>
                                }

                            </div>

                            <br></br>

                            <AbonosListFilter
                                searchFilterAbonos={props.searchAbonosFilter}
                                saveSearchFilterAbonos={props.saveSearchAbonosFilter}
                            />

                        </div>


                        <div className="card-body">
                            <div className="table-responsive table-hover">
                                <table className="table table-sm">
                                    <thead className="thead-light">
                                        <tr>
                                            {filterAbonoTableTH(props.saveSearchAbonosFilter, props.searchAbonosFilter, "#")}
                                            {filterAbonoTableTH(props.saveSearchAbonosFilter, props.searchAbonosFilter, "Fecha abono")}
                                            {filterAbonoTableTH(props.saveSearchAbonosFilter, props.searchAbonosFilter, "Cliente")}

                                            <th scope="col"> {"Núm Cargas"} </th>

                                            {(suscriptionData.paqueteria) &&
                                                <th scope="col"> {"Núm Paqueterías"}</th>
                                            }

                                            {filterAbonoTableTH(props.saveSearchAbonosFilter, props.searchAbonosFilter, "SubTotal")}
                                            {filterAbonoTableTH(props.saveSearchAbonosFilter, props.searchAbonosFilter, "IVA")}
                                            {filterAbonoTableTH(props.saveSearchAbonosFilter, props.searchAbonosFilter, "Total")}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {abonosList}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* paginacion */}
                        {Pagination(numPage, totalPages, props.searchAbonosFilter, props.saveSearchAbonosFilter)}

                    </div>
                </div>
            </div>
        </Fragment >

    );
};

export default AbonosList;