import {useSelector} from "react-redux";
import React, {Fragment, useState, ReactElement, useEffect} from "react";
import {ApplicationState} from '../../ApplicationState';
import {Button, Modal} from "react-bootstrap";
import NumberFormat, {NumberFormatValues} from "react-number-format";
import {client, inicialiceClient, validateFormEmpty, validateNumberFormEmpty} from "aseguisShared";
import ClientsAutocomplete from "../../components/Clients/ClientsAutocomplete";

interface infoToModalPrintAlbaranes {
    modalOpened: boolean,
    setModalOpened: (openModal: boolean) => void
    printAlbaranesById: (fromID: number, untilID: number, client: client | null) => void
    printAlbaranesByCartaPorte: ((cartaPorte: string) => void) | (null)
}

function modalPrintAlbaranes(props: infoToModalPrintAlbaranes): ReactElement {

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const [byIDsOrCartaPorte, setByIDsOrCartaPorte] = useState("")
    const handleClose = () => props.setModalOpened(false)
    
    const [client, setClient] = useState(inicialiceClient)
    const [untilID, setUntilID] = useState(0)
    const [fromID, setFromID] = useState(0)
    const [cartaPorte, setCartaPorte] = useState("")

    useEffect(() => {
        restartModal()
    }, [props.modalOpened])
    
    
    const restartModal = () => {
        setClient(inicialiceClient)
        setUntilID(0)
        setFromID(0)
        setCartaPorte("")
    }

    return (

        <Fragment>

            <Modal show={props.modalOpened} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Imprimir albaranes
                        {(isAppLoading === true) &&
                            <span className="spinner-border spinner-border-sm ml-4"></span>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {(props.printAlbaranesByCartaPorte != null) &&
                        <div className="d-flex justify-content-center">
                            <div className="m-3">
                                <input
                                    type="radio"
                                    id="byID"
                                    value="byID"
                                    checked={byIDsOrCartaPorte === 'byID'}
                                    onChange={(e) => {
                                        restartModal()
                                        setByIDsOrCartaPorte(e.target.value);
                                    }}
                                    className="mr-2"

                                />
                                <label htmlFor="byID">Por ID</label>
                            </div>
                            <div className="m-3">
                                <input
                                    type="radio"
                                    id="byCARTA"
                                    value="byCARTA"
                                    checked={byIDsOrCartaPorte === 'byCARTA'}
                                    onChange={(e) => {
                                        restartModal()
                                        setByIDsOrCartaPorte(e.target.value);
                                    }}
                                    className="mr-2"

                                />
                                <label htmlFor="byCARTA">Por carta de porte</label>
                            </div>
                        </div>
                    }

                    {(byIDsOrCartaPorte === "byCARTA" && props.printAlbaranesByCartaPorte != null) && 
                        <div>                            
                            {/**** POR CARTA DE PORTE ***/}
                            <div className="row align-items-center">
                                <span className="ml-4 mr-2">Carta de porte: </span>
                                <input
                                    className={validateFormEmpty(cartaPorte, 1) + " col-8"}
                                    type="text"
                                    autoComplete={"off"}
                                    value={cartaPorte}
                                    onChange={(event) => {
                                        restartModal()
                                        var value = event.target.value
                                        setCartaPorte(value)
                                    }}
                                />
                            </div>
                        </div>
                    }

                    {(byIDsOrCartaPorte === "byID" || props.printAlbaranesByCartaPorte === null) &&
                        <div>
                            {/**** POR ID Y CLIENTE ***/}
                            <div className="row align-items-center">
                                <span className="ml-4 mr-2">Desde ID: </span>
                                <NumberFormat
                                    value={fromID}
                                    className={untilID < fromID ? "form-control is-invalid col-md-3" : validateNumberFormEmpty(fromID) + " col-md-3"}
                                    onValueChange={(number: NumberFormatValues) => {
                                        setFromID(number.floatValue ? number.floatValue : 0)
                                    }}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                />
                                <span className="ml-2 mr-2">Hasta ID: </span>
                                <NumberFormat
                                    value={untilID}
                                    className={validateNumberFormEmpty(untilID) + " col-md-3"}
                                    onValueChange={(number: NumberFormatValues) => {
                                        setUntilID(number.floatValue ? number.floatValue : 0)
                                    }}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                />

                            </div>
                            <div className="row align-items-center mt-4">
                                <span className="ml-4 mr-2">Cliente: </span>
                                <div className="col-9">
                                    <ClientsAutocomplete
                                        clientName={client.nombre}
                                        setClient={(client) => {
                                            setClient(client)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    }


                </Modal.Body>
                <Modal.Footer>
                    

                    {/**** POR ID Y CLIENTE *****/}
                    {(byIDsOrCartaPorte === "byID" || props.printAlbaranesByCartaPorte === null) &&
                        <Button
                            disabled={untilID === 0 || fromID === 0 || untilID < fromID}
                            variant="primary"
                            onClick={() => {
                                if (!isAppLoading) {
                                    if (untilID > 0 && fromID > 0 && untilID >= fromID) {
                                        props.printAlbaranesById(fromID, untilID, client)
                                    }
                                }
                            }}
                        >
                            <span>Imprimir</span>
                        </Button>
                    }

                    {/**** POR CARTA DE PORTE *****/}
                    {(byIDsOrCartaPorte === "byCARTA" && props.printAlbaranesByCartaPorte != null) &&
                        <Button
                            disabled={cartaPorte.length === 0}
                            variant="primary"
                            onClick={() => {
                                if (!isAppLoading) {
                                    if (cartaPorte.length > 0 && props.printAlbaranesByCartaPorte != null) {
                                        props.printAlbaranesByCartaPorte(cartaPorte)
                                    }
                                }
                            }}
                        >
                            <span>Imprimir</span>
                        </Button>
                    }


                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Cerrar
                    </Button>


                </Modal.Footer>
            </Modal>


        </Fragment>

    );
};

export default modalPrintAlbaranes;
