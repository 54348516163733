import React, { Fragment, ReactElement, } from "react";
import { Button, Modal } from "react-bootstrap";

interface infoToClientDestinationsAutocomplete {
    setCountrySelected: (country: string) => void
    modalOpened: boolean
    setModalOpened: (opened: boolean) => void
}

function CountryModal(props: infoToClientDestinationsAutocomplete): ReactElement {

    const closeModalCountry = () => {
        props.setModalOpened(false)
    }

    return (
        <Fragment>
            <Modal show={props.modalOpened} onHide={closeModalCountry}>
                <Modal.Header closeButton>
                    <Modal.Title>Seleccione un país</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row align-items-center m-3 table-responsive portlet-700">
                        <table className="table-sm table table-hover" style={{ width: "100%" }}>
                            <thead>

                            </thead>

                            <tbody >

                                {getPaisesTable({
                                    setCountry: props.setCountrySelected,
                                    closeModal: props.setModalOpened
                                })}


                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModalCountry}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment >
    );
};

export default CountryModal;


interface infoTogetPaisesTable {
    setCountry: (country: string) => void
    closeModal: (state: boolean) => void
}
const getPaisesTable = ({ setCountry, closeModal }: infoTogetPaisesTable) => {
    return (
        <Fragment>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("ES");
                    closeModal(false);
                }}
            >
                <td>
                    {"España"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("AD");
                    closeModal(false);
                }}
            >
                <td>
                    {"Andorra"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("FR");
                    closeModal(false);
                }}
            >
                <td>
                    {"Francia"}
                </td>
            </tr>

            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("PT");
                    closeModal(false);
                }}
            >
                <td>
                    {"Portugal"}
                </td>
            </tr>

            <br></br>

            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("DE");
                    closeModal(false);
                }}
            >
                <td>
                    {"Alemania"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("AT");
                    closeModal(false);
                }}
            >
                <td>
                    {"Austria"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("BE");
                    closeModal(false);
                }}
            >
                <td>
                    {"Bélgica"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("BY");
                    closeModal(false);
                }}
            >
                <td>
                    {"Bielorussia"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("BG");
                    closeModal(false);
                }}
            >
                <td>
                    {"Bulgaria"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("BG");
                    closeModal(false);
                }}
            >
                <td>
                    {"Bulgaria"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("VA");
                    closeModal(false);
                }}
            >
                <td>
                    {"Ciudad del Vaticano"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("HR");
                    closeModal(false);
                }}
            >
                <td>
                    {"Croacia"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("DK");
                    closeModal(false);
                }}
            >
                <td>
                    {"Dinamarca"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("SK");
                    closeModal(false);
                }}
            >
                <td>
                    {"Eslovaquia"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("SI");
                    closeModal(false);
                }}
            >
                <td>
                    {"Eslovenia"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("EE");
                    closeModal(false);
                }}
            >
                <td>
                    {"Estonia"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("FI");
                    closeModal(false);
                }}
            >
                <td>
                    {"Finlandia"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("NL");
                    closeModal(false);
                }}
            >
                <td>
                    {"Holanda"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("HU");
                    closeModal(false);
                }}
            >
                <td>
                    {"Hungría"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("IE");
                    closeModal(false);
                }}
            >
                <td>
                    {"Irlanda"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("IS");
                    closeModal(false);
                }}
            >
                <td>
                    {"Islandia"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("IT");
                    closeModal(false);
                }}
            >
                <td>
                    {"Italia"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("LV");
                    closeModal(false);
                }}
            >
                <td>
                    {"Letonia"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("LI");
                    closeModal(false);
                }}
            >
                <td>
                    {"Liechtenstein"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("LT");
                    closeModal(false);
                }}
            >
                <td>
                    {"Lituania"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("LU");
                    closeModal(false);
                }}
            >
                <td>
                    {"Luxemburgo"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("MK");
                    closeModal(false);
                }}
            >
                <td>
                    {"Macedonia"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("MT");
                    closeModal(false);
                }}
            >
                <td>
                    {"Malta"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("MD");
                    closeModal(false);
                }}
            >
                <td>
                    {"Moldavia"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("MC");
                    closeModal(false);
                }}
            >
                <td>
                    {"Mónaco"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("NO");
                    closeModal(false);
                }}
            >
                <td>
                    {"Noruega"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("PL");
                    closeModal(false);
                }}
            >
                <td>
                    {"Polonia"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("GB");
                    closeModal(false);
                }}
            >
                <td>
                    {"Reino Unido"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("CZ");
                    closeModal(false);
                }}
            >
                <td>
                    {"República Checa"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("RO");
                    closeModal(false);
                }}
            >
                <td>
                    {"Rumanía"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("SM");
                    closeModal(false);
                }}
            >
                <td>
                    {"San Marino"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("RS");
                    closeModal(false);
                }}
            >
                <td>
                    {"Serbia y Montenegro"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("SE");
                    closeModal(false);
                }}
            >
                <td>
                    {"Suecia"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("CH");
                    closeModal(false);
                }}
            >
                <td>
                    {"Suiza"}
                </td>
            </tr>
            <tr
                className={`table-row`}
                onClick={async () => {
                    setCountry("UA");
                    closeModal(false);
                }}
            >
                <td>
                    {"Ucrania"}
                </td>
            </tr>
        </Fragment>
    )
}