import { Action, Dispatch } from 'redux';
import axios from 'axios';
import { address, inicializePostalCode } from 'aseguisShared';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface registerState {
    stripeProducts: IStripeProducts;
}

export interface IStripeProducts {
    products: IStripeProduct[];
    includedProducts: IStripeProduct[];
    optionalProducts: IStripeProduct[];
}

export interface IStripeProduct {
    productId: string;
    name: string;
    description: string;
    priceId: string;
    price: number;
    metadata: { [key: string]: string };
}

export interface IRegisterData {
    ciaNif: string;
    ciaName: string;
    ciaAddress: address;
    primaryColor: string;
    secondColor: string;
    imageBase64: string;
    userName: string;
    userApe1: string;
    userNif: string;
    userEmail: string;
    userPassword1: string;
    userPassword2: string;
    captcha: string;
    prices: string[];
}

export const initRegisterData: IRegisterData = {
    ciaNif: "A12345678",
    ciaName: "Nombre de la compañía",
    ciaAddress: {
        calle: "Dirección de la compañía",
        numero: "",
        puerta: "",
        localidad: "",
        codigoPostal: "",
        provincia: "",
        pais: "",
        vicinity: "",
        url: "",
        placeId: "",
        otherName: "",
        formatedAddress: "",
        cpMongo: inicializePostalCode
    },
    primaryColor: "#013246",
    secondColor: "#DBF3FD",
    imageBase64: "",
    userName: "",
    userApe1: "",
    userNif: "12345678A",
    userEmail: "",
    userPassword1: "",
    userPassword2: "",
    captcha: "",
    prices: []
}

export const initStripeProducts: IStripeProducts = {
    products: [],
    includedProducts: [],
    optionalProducts: []
}

export const initStripeProduct: IStripeProduct = {
    price: 0,
    description: "",
    metadata: { "": "" },
    name: "",
    priceId: "",
    productId: ""
}

export const initialState: registerState = {
    stripeProducts: initStripeProducts,
};

export const stripeProductsColors = ['#add8e6', '#90ee90', '#ffd700', '#ffa500'];

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface SetStripeProducts {
    type: 'SET_STRIPE_PRODUCTS',
    products: IStripeProducts
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetStripeProducts


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export function setStripeProducts(stripeProducts: IStripeProducts): SetStripeProducts {
    return {
        type: 'SET_STRIPE_PRODUCTS',
        products: stripeProducts
    };
}

export function getStripeProducts() {
    return async (dispatch: Dispatch) => {
        return axios.get<IStripeProducts>('api/stripe/getProducts')
            .then((response) => {
                if (response.data) {
                    dispatch(setStripeProducts(response.data))
                }
            })
            .catch((error) => {
            })
    }
}


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
//export const reducer: Reducer<LoginState> = (state: LoginState | undefined, incomingAction: Action): LoginState => {
function registerReducer(state: registerState = initialState, incomingAction: Action): registerState {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_STRIPE_PRODUCTS':
            return {
                ...state,
                stripeProducts: action.products
            };
        default:
            return state;
    }
};


export default registerReducer;