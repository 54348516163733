import { Action, Dispatch } from 'redux';
import { ApplicationState } from '../../ApplicationState';
import axios from 'axios';
import {
    abono, initAbono, searchedAbonos, loading,
    addingOrEditing, inicialiceSearchFilterList, searchFilterList
} from "aseguisShared";
import { Notify } from '../../common/Toastify/Notify';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface abonosState {
    allAbonos: abono[];
    addOrEditAbono: number,
    abonoForm: abono;
    abonoEditForm: abono;
    isLoadingAbonos: number,
    searchFilterAbonos: searchFilterList,
    totalAbonosList: number,
    actualPageAbono: number
    totalPagesAbono: number
    activateCreateAbono: boolean
}

export const initialState: abonosState = {
    allAbonos: [],
    abonoForm: initAbono,
    abonoEditForm: initAbono,
    addOrEditAbono: addingOrEditing.none,
    isLoadingAbonos: loading.pending,
    searchFilterAbonos: inicialiceSearchFilterList,
    totalAbonosList: 0,
    actualPageAbono: 0,
    totalPagesAbono: 0,
    activateCreateAbono: false
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface addEditAbonoAction {
    type: 'CHANGE_ADD_EDIT_ABONO',
    addOrEdit: number
}
export interface saveFormAbonoAction {
    type: 'SAVE_FORM_ABONO',
    abono: abono
}
export interface addReceivedAbonoAction {
    type: 'ADD_RECEIVED_ABONO',
    abono: abono
}
export interface saveEditReceivedAbonoAction {
    type: 'SAVE_EDIT_RECEIVED_ABONO',
    abono: abono
}
export interface RequestAbonosAction {
    type: 'REQUEST_ABONOS'
}
export interface ReceivedAllAbonosAction {
    type: 'RECEIVED_ALL_ABONOS',
    abonos: searchedAbonos
}
export interface saveSearchAbonoAction {
    type: 'SAVE_SEARCH_FILTER_ABONO',
    searchFilterAbono: searchFilterList
}
export interface setBothFormsAbonoAction {
    type: 'SET_BOTH_FORMS_ABONO',
    abono: abono
}
export interface setActivateCreateAbonoAction {
    type: 'SET_ACTIVATE_CREATE_ABONO',
    value: boolean
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = addEditAbonoAction | saveFormAbonoAction | addReceivedAbonoAction | saveEditReceivedAbonoAction | RequestAbonosAction |
    ReceivedAllAbonosAction | saveSearchAbonoAction | setBothFormsAbonoAction | setActivateCreateAbonoAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export function activateRequestAbonos(): RequestAbonosAction {
    return {
        type: 'REQUEST_ABONOS',
    };
}
export function receivedAllAbonosFromSearch(allAbonos: searchedAbonos): ReceivedAllAbonosAction {
    return {
        type: 'RECEIVED_ALL_ABONOS',
        abonos: allAbonos
    };
}
export function addReceivedAbono(abono: abono): addReceivedAbonoAction {
    return {
        type: 'ADD_RECEIVED_ABONO',
        abono: abono
    };
}
export function saveReceivedAbono(abono: abono): saveEditReceivedAbonoAction {
    return {
        type: 'SAVE_EDIT_RECEIVED_ABONO',
        abono: abono
    };
}
export function setFormsAbono(abono: abono): setBothFormsAbonoAction {
    return {
        type: 'SET_BOTH_FORMS_ABONO',
        abono: abono
    };
}
export function addOrEditAbono(addOrEdit: number): addEditAbonoAction {
    return {
        type: 'CHANGE_ADD_EDIT_ABONO',
        addOrEdit: addOrEdit
    };
}
export function saveFormAbono(abono: abono): saveFormAbonoAction {
    return {
        type: 'SAVE_FORM_ABONO',
        abono: abono
    };
}
export function saveSearchFilterAbono(searchFilterAbono: searchFilterList): saveSearchAbonoAction {
    return {
        type: 'SAVE_SEARCH_FILTER_ABONO',
        searchFilterAbono: searchFilterAbono
    };
}
export function setActivateCreateBill(value: boolean): setActivateCreateAbonoAction {
    return {
        type: 'SET_ACTIVATE_CREATE_ABONO',
        value: value
    };
}

/**************************************************************
 **************************************************************
 *
 * @param Bill_OPERATIONS
 *
 * ************************************************************
 **************************************************************/
export function addAbono(abono: abono) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(activateRequestAbonos());
        return axios.post<abono>('api/abonos/', abono)
            .then((data) => {
                Notify("Abono creado", "success")
                dispatch(addReceivedAbono(data.data));
                dispatch(createAbonoPDF([data.data]))
            })
            .catch((error) => {
            })
    }
}

export function editAbono(abono: abono) {
    return async (dispatch: Dispatch) => {
        dispatch(activateRequestAbonos());
        return axios.put<abono>('api/abonos', abono)
            .then((data) => {
                Notify("Abono modificado", "success")
                dispatch(saveReceivedAbono(data.data))
            })
            .catch((error) => {
            })
    }
}

export function requestAllAbonos(searchAbonosFilter: searchFilterList) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        dispatch(activateRequestAbonos());
        return axios.post<searchedAbonos>('api/abonos/searchFilter/', searchAbonosFilter)
            .then((data) => {
                dispatch(receivedAllAbonosFromSearch(data.data))
            })
            .catch((error) => {
            })
    }
}

export const requestOneAbono = async (idAbono: string) => {
    let searchFilter = { ...inicialiceSearchFilterList, idMongo: idAbono }
    return await axios.post<searchedAbonos>('api/abonos/searchFilter/', searchFilter)
}

export function exportAbonosToExcel(searchFilter: searchFilterList) {
    return async (dispatch: Dispatch) => {
        return axios({ method: 'post', responseType: 'blob', url: 'api/abonos/createExcel/getList/', data: searchFilter })
            .then((data) => {
                const blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Aseguis_abonos.xlsx";
                link.click();
            })
            .catch((error) => {
            })
    }
}

export function createAbonoPDF(abonos: abono[]) {
    return async (dispatch: Dispatch) => {
        return axios({ method: 'post', responseType: 'blob', url: 'api/abonos/createPDF/', data: abonos })
            .then((data) => {
                window.open(URL.createObjectURL(data.data));
                dispatch(setActivateCreateBill(true));
            })
            .catch((error) => {
            })
    }
}

function abonosReducer(state: abonosState = initialState, incomingAction: Action): abonosState {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_BOTH_FORMS_ABONO': {
            return {
                ...state,
                abonoForm: action.abono,
                abonoEditForm: action.abono,
            }
        }
        case 'SAVE_FORM_ABONO': {
            return {
                ...state,
                abonoEditForm: action.abono
            };
        }
        case 'CHANGE_ADD_EDIT_ABONO': {
            return {
                ...state,
                addOrEditAbono: action.addOrEdit
            }
        }
        case 'REQUEST_ABONOS': {
            return {
                ...state,
                isLoadingAbonos: loading.pending
            };
        }
        case 'ADD_RECEIVED_ABONO': {
            return {
                ...state,
                abonoForm: action.abono,
                abonoEditForm: action.abono,
                addOrEditAbono: addingOrEditing.edditing
            };
        }
        case 'SAVE_EDIT_RECEIVED_ABONO': {
            return {
                ...state,
                abonoForm: action.abono,
                abonoEditForm: action.abono
            };
        }
        case 'RECEIVED_ALL_ABONOS': {
            return {
                ...state,
                isLoadingAbonos: loading.done,
                allAbonos: action.abonos.abonosResults,
                totalAbonosList: action.abonos.totalResults,
                actualPageAbono: action.abonos.numPage,
                totalPagesAbono: action.abonos.totalPages
            };
        }
        case 'SAVE_SEARCH_FILTER_ABONO': {
            return {
                ...state,
                searchFilterAbonos: action.searchFilterAbono,
                isLoadingAbonos: loading.pending
            };
        }
        case 'SET_ACTIVATE_CREATE_ABONO': {
            return {
                ...state,
                activateCreateAbono: action.value
            };
        }
        default:
            return state;
    }
};

export default abonosReducer;