import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, ReactElement, useState } from "react";
import { ApplicationState } from '../../ApplicationState';
import { requestAllBills, exportBillsToExcel } from './BillsStore';
import BillsListFilter from "./BillsListFilter";
import { filterBillsTableTH } from "../../common/commonInterfaces/thFilters";
import { GetSuscriptionDataFromLocalStorage, Pagination } from "../../common/commonInterfaces/commonFunctions";
import { ButtonEditarPrivate } from "../../common/Buttons/ButtonEditarPrivate";
import NumberFormat from "react-number-format";
import { bill, usersTypes, searchFilterList, loginUsers, getFormattedDate, suscriptionData } from "aseguisShared";
import AddButtonList from "../../common/Buttons/AddButtonList";
//import FacturacionMensual from "./BillsGraph";//GRAFICO GRÁFICO GRAPH

interface infoToBillsList {
    onClick: (bill: bill) => void
    searchBillsFilter: searchFilterList
    addBill?: () => void
    saveSearchBillsFilter: (filter: searchFilterList) => void
}
function BillsList(props: infoToBillsList): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();

    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()
    const isModifyEnabled = ButtonEditarPrivate().isModifyEnabled();

    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);
    const allBills: bill[] = useSelector((state: ApplicationState) => state.bills.allBills) || [];
    const numPage: number = useSelector((state: ApplicationState) => state.bills.actualPage);
    const totalPages: number = useSelector((state: ApplicationState) => state.bills.totalPages);
    const countAllBills: number = useSelector((state: ApplicationState) => state.bills.totalBillsList);
    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const billsForm: bill = useSelector((state: ApplicationState) => state.bills.billEditForm);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    useEffect(() => {
        if (props.searchBillsFilter.withTimeout) {
            const timeout = setTimeout(() => {
                dispatch(requestAllBills(props.searchBillsFilter));
            }, 600)
            return () => clearTimeout(timeout)
        } else {
            dispatch(requestAllBills(props.searchBillsFilter));
        }
    }, [props.searchBillsFilter, billsForm]);

    const getRowColor = (bill: bill) => {
        if (userLogged.userType === usersTypes.employee) {
            if (bill.cobrada === true) {
                return ("#CCFFCE")
            }
            if (new Date(bill.fecVencimiento) < new Date()) {
                return ("#FFD9D9")
            }
        }
        return ("#FFFFFF")
    }

    const billList: JSX.Element[] = allBills.map((bill, index) => {
        return (
            <tr className={`table-row`}
                style={{
                    backgroundColor: getRowColor(bill)
                }}
                onClick={() => {
                    props.onClick(bill)
                }}
                key={bill.id}>
                <td><strong>{bill.id}</strong></td>
                <td>{getFormattedDate(bill.fecFactur)}</td>
                <td>{getFormattedDate(bill.fecVencimiento)}</td>
                <td>{bill.client.nombre}</td>
                <td>{(bill.cargasCompletas.allCargasCompletas && bill.cargasCompletas.allCargasCompletas.length > 0) ? bill.cargasCompletas.allCargasCompletas.length : "-"}</td>
                {(suscriptionData.paqueteria) &&
                    <td>{(bill.paqueterias.allPaqueterias && bill.paqueterias.allPaqueterias.length > 0) ? bill.paqueterias.allPaqueterias.length : "-"}</td>
                }
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        value={bill.datosEconomicosTotales.subTotal}
                        className="form-control undefined"
                        renderText={value => <div>{value}</div>}
                        suffix={" €"}
                    />
                </td>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        value={bill.datosEconomicosTotales.iva}
                        className="form-control undefined"
                        renderText={value => <div>{value}</div>}
                        suffix={" €"}
                    />
                </td>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        value={bill.datosEconomicosTotales.total}
                        className="form-control undefined"
                        renderText={value => <div>{value}</div>}
                        suffix={" €"}
                    />
                </td>
                <td>{bill.client.datosEconomicos.formaPago}</td>
                {userLogged.userType === usersTypes.employee &&
                    <td>{bill.cobrada ? "Si" : "No"}</td>
                }
            </tr >
        );
    });

    return (

        <Fragment>

            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        {(props.addBill && isModifyEnabled && userLogged.userType === usersTypes.employee) &&
                            <div className="header-buttons">

                                <button
                                    className="btn btn-info btn-sm left-margin"
                                    onClick={() => {
                                        if (allBills.length > 0) {
                                            dispatch(
                                                exportBillsToExcel({
                                                    ...props.searchBillsFilter,
                                                    getAll: true
                                                })
                                            )
                                        }
                                    }}>
                                    Exportar a Excel
                                </button>

                                <AddButtonList
                                    addCallback={props.addBill}
                                />

                            </div>
                        }

                        <div className="card-header">

                            <div className="form-inline">

                                <h6 className="m-0 font-weight-bold text-green">Lista de Facturas  {"(" + countAllBills + ")"}</h6>

                                {(isAppLoading === true) &&
                                    <span className="spinner-border spinner-border-sm ml-4"></span>
                                }

                            </div>

                            <br></br>

                            <BillsListFilter
                                searchFilterBills={props.searchBillsFilter}
                                saveSearchFilterBills={props.saveSearchBillsFilter}
                            />

                        </div>

                        <div className="card-body">
                            <div className="table-responsive table-hover">

                                <table className="table table-sm">
                                    <thead className="thead-light">
                                        <tr>
                                            {filterBillsTableTH(props.saveSearchBillsFilter, props.searchBillsFilter, "#")}
                                            {filterBillsTableTH(props.saveSearchBillsFilter, props.searchBillsFilter, "Fecha factura")}
                                            {filterBillsTableTH(props.saveSearchBillsFilter, props.searchBillsFilter, "Fecha vencimiento")}
                                            {filterBillsTableTH(props.saveSearchBillsFilter, props.searchBillsFilter, "Cliente")}

                                            <th scope="col"> {"Núm Cargas"} </th>

                                            {(suscriptionData.paqueteria) &&
                                                <th scope="col"> {"Núm Paqueterías"}</th>
                                            }

                                            {filterBillsTableTH(props.saveSearchBillsFilter, props.searchBillsFilter, "SubTotal")}
                                            {filterBillsTableTH(props.saveSearchBillsFilter, props.searchBillsFilter, "IVA")}
                                            {filterBillsTableTH(props.saveSearchBillsFilter, props.searchBillsFilter, "Total")}
                                            {filterBillsTableTH(props.saveSearchBillsFilter, props.searchBillsFilter, "Forma de pago")}

                                            {userLogged.userType === usersTypes.employee &&
                                                <th scope="col"
                                                    style={{ minWidth: "55px" }}
                                                >
                                                    Cobrada
                                                </th>
                                            }

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {billList}

                                    </tbody>

                                </table>
                            </div>
                        </div>

                        {/* paginacion */}
                        {Pagination(numPage, totalPages, props.searchBillsFilter, props.saveSearchBillsFilter)}

                    </div>
                </div>
            </div>

        </Fragment >

    );
};

export default BillsList;
