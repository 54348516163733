import { Action, Dispatch } from 'redux';
import { ApplicationState } from '../../ApplicationState';
import axios from 'axios';
import {
    bill, inicialiceBill, searchedBills, albaranesToBill,
    loading, addingOrEditing, inicialiceSearchFilterList, searchFilterList
} from "aseguisShared";
import { Notify } from '../../common/Toastify/Notify';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface billsState {
    allBills: bill[];
    addOrEditBill: number,
    billForm: bill;
    billEditForm: bill;
    isLoadingBills: number,
    searchFilterBills: searchFilterList,
    totalBillsList: number,
    actualPage: number
    totalPages: number
    activateCreateBill: boolean
    resetForm: number
}

export const initialState: billsState = {
    allBills: [],
    billForm: inicialiceBill,
    billEditForm: inicialiceBill,
    addOrEditBill: addingOrEditing.none,
    isLoadingBills: loading.pending,
    searchFilterBills: inicialiceSearchFilterList,
    totalBillsList: 0,
    actualPage: 0,
    totalPages: 0,
    activateCreateBill: false,
    resetForm: 0
};


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
/*export interface ShowFormBillAction {
    type: 'SHOW_FORM_BILL'
}*/

export interface addEditBillAction {
    type: 'CHANGE_ADD_EDIT_BILL',
    addOrEdit: number
}

export interface saveFormBillAction {
    type: 'SAVE_FORM_BILL',
    bill: bill
}

export interface saveEditReceivedBillAction {
    type: 'SAVE_EDIT_RECEIVED_BILL',
    bill: bill
}

export interface RequestBillsAction {
    type: 'REQUEST_BILLS'
}

export interface ReceivedAllBillAction {
    type: 'RECEIVED_ALL_BILL',
    bills: searchedBills
}

export interface saveSearchBillAction {
    type: 'SAVE_SEARCH_FILTER_BILL',
    searchFilterBill: searchFilterList
}

export interface setBothFormsBillAction {
    type: 'SET_BOTH_FORMS_BILL',
    bill: bill
}

export interface setActivateCreateBillAction {
    type: 'SET_ACTIVATE_CREATE_BILL',
    value: boolean
}

export interface sumResetBillAction {
    type: 'SUM_RESET_ADDFORM_BILL'
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = saveFormBillAction | sumResetBillAction | saveEditReceivedBillAction | RequestBillsAction |
    ReceivedAllBillAction | saveSearchBillAction | setBothFormsBillAction | addEditBillAction | setActivateCreateBillAction;


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export function setFormsBill(bill: bill): setBothFormsBillAction {
    return {
        type: 'SET_BOTH_FORMS_BILL',
        bill: bill
    };
}

export function addOrEditBill(addOrEdit: number): addEditBillAction {
    return {
        type: 'CHANGE_ADD_EDIT_BILL',
        addOrEdit: addOrEdit
    };
}

export function saveFormBill(bill: bill): saveFormBillAction {
    return {
        type: 'SAVE_FORM_BILL',
        bill: bill
    };
}

export function saveSearchFilterBill(searchFilterBill: searchFilterList): saveSearchBillAction {
    return {
        type: 'SAVE_SEARCH_FILTER_BILL',
        searchFilterBill: searchFilterBill
    };
}

export function setActivateCreateBill(value: boolean): setActivateCreateBillAction {
    return {
        type: 'SET_ACTIVATE_CREATE_BILL',
        value: value
    };
}

export function sumResetBill(): sumResetBillAction {
    return {
        type: 'SUM_RESET_ADDFORM_BILL'
    };
}


/**************************************************************
 **************************************************************
 *
 * @param Bill_OPERATIONS
 *
 * ************************************************************
 **************************************************************/

export function addBill(albaranesToBill: albaranesToBill) {
    return async (dispatch: Dispatch<any>) => {
        dispatch({ type: 'REQUEST_BILLS' });
        return axios.post<bill[]>('api/bills/create/create/', albaranesToBill)
            .then((data) => {                
                dispatch(createBillPDF(data.data, true)) //creamos PDF
            })
            .catch((error) => {
            })
    }
}

export function previewBill(albaranesToBill: albaranesToBill) {
    return async (dispatch: Dispatch<any>) => {
        dispatch({ type: 'REQUEST_BILLS' });
        return axios.post<bill[]>('api/bills/create/preview/', albaranesToBill)
            .then((data) => {
                if (data.data && data.data.length && data.data.length > 0) {                   
                    dispatch(createBillPDF(data.data, false)) //creamos PDF
                } else {                    
                    Notify("No se han encontrado albaranes para facturar", "warn")
                }
            })
            .catch((error) => {
            })
    }
}

export function editBill(bill: bill) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: 'REQUEST_BILLS' });
        return axios.put('api/bills/', bill)
            .then((data) => {
                Notify("Factura modificada", "success")
                dispatch({ type: 'SAVE_EDIT_RECEIVED_BILL', bill: data.data });
            })
            .catch((error) => {
                if (error.response.status) {
                    if (error.response.status = 401) {
                        dispatch({ type: 'SAVE_EDIT_RECEIVED_BILL', bill: bill });
                    }
                }
            })
    }
}

export function requestAllBills(searcBillsFilter: searchFilterList) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        dispatch({ type: 'REQUEST_BILLS' });
        return axios.post<searchedBills>('api/bills/searchFilter/', searcBillsFilter)
            .then((data) => {
                dispatch({ type: 'RECEIVED_ALL_BILL', bills: data.data });
            })
            .catch((error) => {
            })
    }
}

export const requestOneBill = async (idBill: string) => {
    let searchFilter: searchFilterList = {
        ...inicialiceSearchFilterList,
        idMongo: idBill,
        estaCobrada: true,
    }
    return await axios.post<searchedBills>('api/bills/searchFilter/', searchFilter)
}

export const calculateBill = async (bill: bill) => {    
    return await axios.put<bill>('api/bills/calculateBill/', bill)
}

export function createBillPDF(bills: bill[], resetForm: boolean) {
    return async (dispatch: Dispatch) => {
        return axios({ method: 'post', responseType: 'blob', url: 'api/bills/createPDF/', data: bills })
            .then((data) => {               
                window.open(URL.createObjectURL(data.data));
                dispatch(setActivateCreateBill(true));
                if (resetForm === true) {
                    dispatch(sumResetBill());
                }
            })
            .catch((error) => {
            })
    }
}

export function exportBillsToExcel(searchPaqueteriaFilter: searchFilterList) {
    return async (dispatch: Dispatch) => {
        return axios({ method: 'post', responseType: 'blob', url: 'api/bills/createExcel/getList/', data: searchPaqueteriaFilter })
            .then((data) => {
                const blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Aseguis_facturas.xlsx";
                link.click();
            })
            .catch((error) => {
            })
    }
}


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
function BillReducer(state: billsState = initialState, incomingAction: Action): billsState {

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_BOTH_FORMS_BILL': {
            return {
                ...state,
                billForm: action.bill,
                billEditForm: action.bill,
            }
        }
        case 'SAVE_FORM_BILL': {
            return {
                ...state,
                billEditForm: action.bill,
            };
        }
        case 'CHANGE_ADD_EDIT_BILL': {
            return {
                ...state,
                addOrEditBill: action.addOrEdit
            }
        }
        case 'REQUEST_BILLS': {
            return {
                ...state,
                isLoadingBills: loading.pending
            };
        }
        case 'SAVE_EDIT_RECEIVED_BILL': {
            return {
                ...state,
                billForm: action.bill,
                billEditForm: action.bill
            };
        }
        case 'RECEIVED_ALL_BILL': {
            return {
                ...state,
                isLoadingBills: loading.done,
                allBills: action.bills.billsResults,
                totalBillsList: action.bills.totalResults,
                actualPage: action.bills.numPage,
                totalPages: action.bills.totalPages
            };
        }
        case 'SAVE_SEARCH_FILTER_BILL': {
            return {
                ...state,
                searchFilterBills: action.searchFilterBill,
                isLoadingBills: loading.pending
            };
        }
        case 'SET_ACTIVATE_CREATE_BILL': {
            return {
                ...state,
                activateCreateBill: action.value
            };
        }
        case 'SUM_RESET_ADDFORM_BILL': {
            return {
                ...state,
                resetForm: state.resetForm + 1
            };
        }
        default:
            return state;
    }
};

export default BillReducer;