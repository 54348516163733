import React, { Fragment, ReactElement } from "react";
import InputText from "../../common/Inputs/InputText";
import InputDate from "../../common/Inputs/InputDate";
import { validateAlphaNumeric, addingOrEditing, inicialiceSearchFilterList, searchFilterList } from "aseguisShared";

interface infoToVehiclesControlListFilter {
    searchFilterControlVehicles: searchFilterList
    saveSearchFilterControlVehicles: (filter: searchFilterList) => void
}
function VehiclesControlListFilter(props: infoToVehiclesControlListFilter): ReactElement {

    return (

        <Fragment>

            <div className="form-row" style={{ marginBottom: "-25px" }}>

                <InputText
                    small={true}
                    colMesure="3"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="Matrícula vehículo"
                    autoComplete={false}
                    valueEdit={props.searchFilterControlVehicles.plateVehicle}
                    value={props.searchFilterControlVehicles.plateVehicle}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                        value = value.trimStart()
                        props.saveSearchFilterControlVehicles({
                            ...props.searchFilterControlVehicles,
                            pageNum: 1,
                            plateVehicle: value,
                            withTimeout: true
                        })
                    }}
                />

                <InputText
                    small={true}
                    colMesure="3"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="Marca / modelo vehículo"
                    valueEdit={props.searchFilterControlVehicles.marcaVehicle}
                    autoComplete={false}
                    value={props.searchFilterControlVehicles.marcaVehicle}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilterControlVehicles({
                            ...props.searchFilterControlVehicles,
                            pageNum: 1,
                            marcaVehicle: value,
                            withTimeout: true
                        })
                    }}
                />

                <InputText
                    small={true}
                    colMesure="3"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    autoComplete={false}
                    name="Tipo de vehículo"
                    valueEdit={props.searchFilterControlVehicles.typeVehicle}
                    value={props.searchFilterControlVehicles.typeVehicle}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilterControlVehicles({
                            ...props.searchFilterControlVehicles,
                            pageNum: 1,
                            typeVehicle: value,
                            withTimeout: true
                        })
                    }}
                />

            </div>

            <div className="form-row" style={{ marginBottom: "-30px" }}>

                <InputText
                    small={true}
                    colMesure="3"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    autoComplete={false}
                    name="Empleado"
                    valueEdit={props.searchFilterControlVehicles.employeeName}
                    value={props.searchFilterControlVehicles.employeeName}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilterControlVehicles({
                            ...props.searchFilterControlVehicles,
                            pageNum: 1,
                            employeeName: value,
                            withTimeout: true
                        })
                    }}
                />

                <InputDate
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.edditing}
                    name="Fecha desde"
                    valueEdit={props.searchFilterControlVehicles.fechaDesde}
                    value={props.searchFilterControlVehicles.fechaDesde}
                    onChange={(date) => {
                        props.saveSearchFilterControlVehicles({
                            ...props.searchFilterControlVehicles,
                            pageNum: 1,
                            fechaDesde: date,
                            withTimeout: true
                        })
                    }}
                />

                <InputDate
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.edditing}
                    name="Fecha hasta"
                    valueEdit={props.searchFilterControlVehicles.fechaHasta}
                    value={props.searchFilterControlVehicles.fechaHasta}
                    onChange={(date) => {
                        props.saveSearchFilterControlVehicles({
                            ...props.searchFilterControlVehicles,
                            pageNum: 1,
                            fechaHasta: date,
                            withTimeout: true
                        })
                    }}
                />
                
                <div className="form-group col-md-2">
                    <br></br>
                    <button
                        className="btn btn-info btn-sm mr-3 mt-2"
                        onClick={() => {
                            props.saveSearchFilterControlVehicles({ ...props.searchFilterControlVehicles, withTimeout: false })
                        }}
                    >
                        <i className="fas fa-sync"></i>
                    </button>
                    <button className="btn btn-secondary btn-sm mt-2"
                        onClick={() => {
                            props.saveSearchFilterControlVehicles(inicialiceSearchFilterList)                       
                        }}>
                        Limpiar filtro
                        
                    </button>
                </div>

            </div>

        </Fragment >

    );
    
};

export default VehiclesControlListFilter;
