import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, ReactElement, useState, ChangeEvent } from "react";
import { ApplicationState } from '../../ApplicationState';
import { GetSuscriptionDataFromLocalStorage, Pagination } from "../../common/commonInterfaces/commonFunctions";
import NumberFormat from "react-number-format";
import {
    paqueteria, searchedPaqueterias, bill, cargaCompleta, searchedCargasCompletas,
    addingOrEditing, getFormattedDateShort, inicialiceSearchFilterList, searchFilterList,
    suscriptionData
} from "aseguisShared";
import axios from "axios";
import Swal from "sweetalert2";
import { exportToExcel as ExportExcelCarga } from "../CargaCompleta/CargaCompletaStore";
import { exportToExcel as ExportExcelPaq } from "../Paqueteria/PaqueteriaStore";

interface infoToBillsCargasList {
    isEdditing: number;
    billForm: bill
    saveFormBill: (bill: bill) => void
}

function BillsListCargasAndPaqs(props: infoToBillsCargasList): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();
    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [searchFilterCarga, setSearchFilterCarga] = useState<searchFilterList>(inicialiceSearchFilterList)
    const [cargas, setCargas] = useState<cargaCompleta[]>([])
    const [totalPagesCarga, setTotalPagesCarga] = useState(0)
    const [searchFilterPaq, setSearchFilterPaq] = useState<searchFilterList>(inicialiceSearchFilterList)
    const [paqs, setPaqs] = useState<paqueteria[]>([])
    const [totalPagesPaq, setTotalPagePaq] = useState(0)
    const [allCargas, setAllCargas] = useState<string[]>([])
    const [allPaqs, setAllPaqs] = useState<string[]>([])
    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    useEffect(() => {
        if (props.billForm.cargasCompletas && props.billForm.cargasCompletas.allCargasCompletas) {
            setAllCargas(props.billForm.cargasCompletas.allCargasCompletas)
        } else {
            setAllCargas([])
        }
    }, [props.billForm.cargasCompletas]);

    useEffect(() => {
        if (props.billForm.paqueterias && props.billForm.paqueterias.allPaqueterias) {
            setAllPaqs(props.billForm.paqueterias.allPaqueterias)
        } else {
            setAllPaqs([])
        }
    }, [props.billForm.paqueterias]);

    useEffect(() => {
        if (allCargas.length > 0) {
            axios.post<searchedCargasCompletas>('api/cargaCompleta/searchFilter/', {
                ...searchFilterCarga,
                idsMongo: allCargas
            }).then((data) => {
                setCargas(data.data.cargasResults)
                setSearchFilterCarga({
                    ...searchFilterCarga,
                    pageNum: data.data.numPage,
                })
                setTotalPagesCarga(data.data.totalPages)
            }).catch((error) => {
            })
        }
    }, [searchFilterCarga.idsMongo, searchFilterCarga.pageNum, searchFilterCarga.idString, allCargas]);


    useEffect(() => {
        if (allPaqs.length > 0) {
            axios.post<searchedPaqueterias>('api/paqueteria/searchFilter/', {
                ...searchFilterPaq,
                idsMongo: allPaqs
            }).then((data) => {
                setPaqs(data.data.paqueteriasResults)
                setSearchFilterPaq({
                    ...searchFilterPaq,
                    pageNum: data.data.numPage
                })
                setTotalPagePaq(data.data.totalPages)
            }).catch((error) => {
            })
        }
    }, [searchFilterPaq.idsMongo, searchFilterPaq.pageNum, searchFilterPaq.idString, allPaqs]);


    const cargasList: JSX.Element[] = cargas.map((cargaCompleta, index) => {
        return (
            <Fragment key={index}>
                <tr className={`table-row`} key={index}>
                    <td scope="row"><b>{cargaCompleta.id}</b></td>
                    <td style={{ whiteSpace: "nowrap" }}>{getFormattedDateShort(cargaCompleta.datosGenerales.recogida)}</td>
                    <td style={{ whiteSpace: "nowrap" }}>{getFormattedDateShort(cargaCompleta.datosGenerales.entrega)}</td>
                    <td>{cargaCompleta.datosGenerales.referenciaString}</td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                        <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            decimalScale={0}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={cargaCompleta.datosGenerales.bultos}
                            className="form-control undefined"
                            renderText={value => <div>{value + " bultos"}</div>}
                        />
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                        <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={cargaCompleta.datosGenerales.kilos}
                            className="form-control undefined"
                            renderText={value => <div>{value + " kg"}</div>}
                        />
                    </td>
                    <td>{cargaCompleta.datosEntrega.qrIdReaded.length > 0 ? "Si" : "-"}</td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                        <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={true}
                            value={cargaCompleta.datosEconomicos.subTotal}
                            className="form-control undefined"
                            renderText={value => <div>{value}</div>}
                            suffix={" \u20AC"} />
                    </td>
                    {props.isEdditing === addingOrEditing.edditing &&
                        <td>
                            <button
                                className="btn btn-secondary btn-sm"
                                onClick={() => {
                                    if (cargaCompleta._id) {
                                        props.saveFormBill({
                                            ...props.billForm,
                                            cargasCompletas: {
                                                ...props.billForm.cargasCompletas,
                                                allCargasCompletas: props.billForm.cargasCompletas.allCargasCompletas.filter(carga => carga !== cargaCompleta._id)
                                            }
                                        });
                                    }
                                }}
                            >
                                <i className="fas fa fa-times"></i>
                            </button>
                        </td>
                    }
                </tr>
            </Fragment>
        )
    });

    const paqueteriaList: JSX.Element[] = paqs.map((paqueteria, index) => {
        return (
            <Fragment key={index}>
                <tr className={"table-row"} key={index}>
                    <td scope="row"><b>{paqueteria.id}</b></td>
                    <td>{paqueteria.datosGenerales.entradaSalida}</td>
                    <td style={{ whiteSpace: "nowrap" }}> {getFormattedDateShort(paqueteria.datosGenerales.recogida)}</td>
                    <td style={{ whiteSpace: "nowrap" }}> {getFormattedDateShort(paqueteria.datosGenerales.entrega)}</td>
                    <td>{paqueteria.datosTransporte.referenciaString}</td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                        <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            decimalScale={0}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={paqueteria.datosTransporte.bultos}
                            className="form-control undefined"
                            renderText={value => <div>{value + " bultos"}</div>}
                        />
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                        <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={paqueteria.datosTransporte.kilos}
                            className="form-control undefined"
                            renderText={value => <div>{value + " kg"}</div>}
                        />
                    </td>
                    <td>{paqueteria.datosEntrega.qrIdReaded.length > 0 ? "Si" : "-"}</td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                        <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={true}
                            value={paqueteria.datosEconomicos.subTotal}
                            className="form-control undefined"
                            renderText={value => <div>{value}</div>}
                            suffix={" \u20AC"}
                        />
                    </td>
                    {props.isEdditing === addingOrEditing.edditing &&
                        <td>
                            <button
                                className="btn btn-secondary btn-sm"
                                onClick={() => {
                                    if (paqueteria._id) {
                                        props.saveFormBill({
                                            ...props.billForm,
                                            paqueterias: {
                                                ...props.billForm.paqueterias,
                                                allPaqueterias: props.billForm.paqueterias.allPaqueterias.filter(paq => paq !== paqueteria._id)
                                            }
                                        });
                                    }
                                }}
                            >
                                <i className="fas fa fa-times"></i>
                            </button>
                        </td>
                    }
                </tr>
            </Fragment>
        )
    });

    return (

        <Fragment>

            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header">

                            <div className="header-buttons">

                                <button
                                    className="btn btn-success btn-sm mr-2"
                                    onClick={() => {
                                        dispatch(ExportExcelCarga({ ...searchFilterCarga, idsMongo: allCargas, getAll: true }))
                                    }}>
                                    <i className="fa-regular fa-file-excel" ></i> &nbsp; Exportar
                                </button>

                                {props.isEdditing === addingOrEditing.edditing &&
                                    <button
                                        className="btn btn-secondary btn-sm"
                                        onClick={() => {
                                            Swal.fire({
                                                title: "ID de carga completa a añadir",
                                                input: "number",
                                                inputAttributes: {
                                                    min: "0"
                                                },
                                                showCancelButton: true,
                                                confirmButtonText: "Añadir",
                                                confirmButtonColor: "#28a745",
                                                showLoaderOnConfirm: true,
                                                preConfirm: (cargaID) => {
                                                    return { cargaID: cargaID };
                                                },
                                                allowOutsideClick: () => !Swal.isLoading()
                                            }).then((result) => {
                                                if (result.isConfirmed && result.value) {
                                                    axios.post<searchedCargasCompletas>('api/cargaCompleta/searchFilter/', {
                                                        ...searchFilterCarga,
                                                        id: result.value.cargaID,
                                                        clientsIDsMongo: [props.billForm.client._id],
                                                        notBilled: true
                                                    }).then((data) => {
                                                        if (data.data.cargasResults.length === 1 && data.data.cargasResults[0]._id) {
                                                            const cargaExistente = allCargas.includes(data.data.cargasResults[0]._id)
                                                            if (cargaExistente === false) {
                                                                props.saveFormBill({
                                                                    ...props.billForm,
                                                                    cargasCompletas: {
                                                                        ...props.billForm.cargasCompletas,
                                                                        allCargasCompletas: [...allCargas, data.data.cargasResults[0]._id]
                                                                    }
                                                                });
                                                                return Swal.fire({
                                                                    title: "El albarán de carga completa con ID " + result.value.cargaID + " ha sido añadido",
                                                                    icon: "success"
                                                                });
                                                            } else {
                                                                return Swal.fire({
                                                                    title: "El albarán de carga completa con ID " + result.value.cargaID + " ya existe en la factura",
                                                                    icon: "warning"
                                                                });
                                                            }
                                                        }
                                                        return Swal.fire({
                                                            title: "El albarán de carga completa con ID " + result.value.cargaID + " no se puede añadir",
                                                            icon: "warning"
                                                        });
                                                    }).catch((error) => {
                                                    })
                                                }
                                            });
                                        }}>
                                        <i className="fas fa fa-plus"></i> &nbsp; Añadir carga
                                    </button>
                                }
                            </div>

                            <div className="form-inline">
                                <h6 className="m-0 font-weight-bold text-green">
                                    Albaranes carga completa incluidos en la factura {"(" + allCargas.length.toLocaleString() + ")"}
                                </h6>
                                <input
                                    type="text"
                                    className="form-control ml-5 mr-5"
                                    placeholder="Buscar ID"
                                    value={searchFilterCarga.idString}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setSearchFilterCarga({
                                            ...searchFilterCarga,
                                            idString: event.target.value,
                                            pageNum: 1
                                        })
                                    }}
                                />
                                {(isAppLoading === true) &&
                                    <span className="spinner-border spinner-border-sm ml-4"></span>
                                }
                            </div>
                        </div>
                        {allCargas.length > 0 &&
                            <>
                                <div className="card-body">
                                    <div className="table-responsive table-hover">
                                        <table className="table table-sm">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>ID#</th>
                                                    <th>Fecha alb</th>
                                                    <th>Entr prevista</th>
                                                    <th>Referencia/s</th>
                                                    <th>Bultos</th>
                                                    <th>Kilos</th>
                                                    <th>Entregado</th>
                                                    <th>Subtotal</th>
                                                    {props.isEdditing === addingOrEditing.edditing &&
                                                        <th>Eliminar</th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cargasList}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {
                                    Pagination(
                                        searchFilterCarga.pageNum,
                                        totalPagesCarga,
                                        searchFilterCarga,
                                        (carga) => {
                                            setSearchFilterCarga(carga)
                                        }
                                    )
                                }
                            </>
                        }
                    </div>
                </div>
            </div>

            {(suscriptionData.paqueteria) &&
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card shadow mb-4">

                            <div className="header-buttons">

                                <button
                                    className="btn btn-success btn-sm mr-2"
                                    onClick={() => {
                                        dispatch(ExportExcelPaq({ ...searchFilterPaq, idsMongo: allPaqs, getAll: true }))
                                    }}>
                                    <i className="fa-regular fa-file-excel" ></i> &nbsp; Exportar
                                </button>

                                {props.isEdditing === addingOrEditing.edditing &&
                                    <button
                                        className="btn btn-secondary btn-sm"
                                        onClick={() => {
                                            Swal.fire({
                                                title: "ID de paquetería a añadir",
                                                input: "number",
                                                inputAttributes: {
                                                    min: "0"
                                                },
                                                showCancelButton: true,
                                                confirmButtonText: "Añadir",
                                                confirmButtonColor: "#28a745",
                                                showLoaderOnConfirm: true,
                                                preConfirm: (paqID) => {
                                                    return { paqID: paqID };
                                                },
                                                allowOutsideClick: () => !Swal.isLoading()
                                            }).then((result) => {
                                                if (result.isConfirmed && result.value) {
                                                    axios.post<searchedPaqueterias>('api/paqueteria/searchFilter/', {
                                                        ...searchFilterPaq,
                                                        id: result.value.paqID,
                                                        clientsIDsMongo: [props.billForm.client._id],
                                                        notBilled: true
                                                    }).then((data) => {
                                                        if (data.data.paqueteriasResults.length === 1 && data.data.paqueteriasResults[0]._id) {
                                                            const paqExistente = allPaqs.includes(data.data.paqueteriasResults[0]._id)
                                                            if (paqExistente === false) {
                                                                props.saveFormBill({
                                                                    ...props.billForm,
                                                                    paqueterias: {
                                                                        ...props.billForm.paqueterias,
                                                                        allPaqueterias: [...allPaqs, data.data.paqueteriasResults[0]._id]
                                                                    }
                                                                });
                                                                return Swal.fire({
                                                                    title: "El albarán de paquetería con ID " + result.value.paqID + " ha sido añadido",
                                                                    icon: "success"
                                                                });
                                                            } else {
                                                                return Swal.fire({
                                                                    title: "El albarán de paquetería con ID " + result.value.paqID + " ya existe en la factura",
                                                                    icon: "warning"
                                                                });
                                                            }
                                                        }
                                                        return Swal.fire({
                                                            title: "El albarán de paquetería con ID " + result.value.paqID + " no se puede añadir",
                                                            icon: "warning"
                                                        });
                                                    }).catch((error) => {
                                                    })
                                                }
                                            });
                                        }}>
                                        <i className="fas fa fa-plus"></i> &nbsp; Añadir paquetería
                                    </button>
                                }
                            </div>

                            <div className="card-header">
                                <div className="form-inline">
                                    <h6 className="m-0 font-weight-bold text-green">
                                        Albaranes paquetería incluidos en la factura {"(" + allPaqs.length.toLocaleString() + ")"}
                                    </h6>
                                    <input
                                        type="text"
                                        className="form-control ml-5 mr-5"
                                        placeholder="Buscar ID"
                                        value={searchFilterPaq.idString}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            setSearchFilterPaq({
                                                ...searchFilterPaq,
                                                idString: event.target.value,
                                                pageNum: 1
                                            })
                                        }}
                                    />
                                    {(isAppLoading === true) &&
                                        <span className="spinner-border spinner-border-sm ml-4"></span>
                                    }
                                </div>
                            </div>
                            {allPaqs.length > 0 &&
                                <>
                                    <div className="card-body">
                                        <div className="table-responsive table-hover">
                                            <table className="table table-sm">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>ID#</th>
                                                        <th>E/S</th>
                                                        <th>Fecha alb</th>
                                                        <th>Entrega</th>
                                                        <th>Referencia/s</th>
                                                        <th>Bultos</th>
                                                        <th>Kilos</th>
                                                        <th>Entregado</th>
                                                        <th>SubTotal</th>
                                                        {props.isEdditing === addingOrEditing.edditing &&
                                                            <th>Eliminar</th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {paqueteriaList}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    {
                                        Pagination(
                                            searchFilterPaq.pageNum,
                                            totalPagesPaq,
                                            searchFilterPaq,
                                            (paqFilter) => {
                                                setSearchFilterPaq({ ...paqFilter })
                                            }
                                        )
                                    }

                                </>
                            }

                        </div>
                    </div>
                </div>
            }

        </Fragment >

    );
};

export default BillsListCargasAndPaqs;
