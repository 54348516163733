"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initAbono = exports.initLineaAbono = void 0;
const billsFunctions_1 = require("./billsFunctions");
const cargaCompletaFunctions_1 = require("./cargaCompletaFunctions");
exports.initLineaAbono = {
    subTotal: 0,
    concepto: "",
};
exports.initAbono = {
    _id: null,
    id: 0,
    client: cargaCompletaFunctions_1.inicialiceCargaCompleta.datosGenerales.client,
    fecAbono: new Date(),
    fecAlta: new Date(),
    cargasCompletas: [],
    paqueterias: [],
    lineasAbono: [],
    datosEconomicosAbono: billsFunctions_1.initDatosEconomicosBill,
};
