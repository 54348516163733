import React, { Fragment, ReactElement } from "react";
import InputText from "../../common/Inputs/InputText";
import {
    addingOrEditing, usersTypes, loginUsers, validateAlphaNumeric,
    inicialiceSearchFilterList, searchFilterList
} from "aseguisShared";
import InputDate from "../../common/Inputs/InputDate";
import { ApplicationState } from "../../ApplicationState";
import { useSelector } from "react-redux";
import { GetSuscriptionDataFromLocalStorage } from "../../common/commonInterfaces/commonFunctions";

interface infoToCargaListFilter {
    searchFilterCarga: searchFilterList
    saveSearchFilterCarga: (filter: searchFilterList) => void
}

function CargaListFilter(props: infoToCargaListFilter): ReactElement {

    const suscriptionData = GetSuscriptionDataFromLocalStorage()

    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);

    return (

        <Fragment>

            <div className="form-row" style={{marginBottom: "-25px"}}>

                <InputText
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="ID albarán"
                    valueEdit={props.searchFilterCarga.idString}
                    value={props.searchFilterCarga.idString}
                    autoComplete={false}
                    onChange={(event) => {
                        let value = validateAlphaNumeric(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilterCarga({
                            ...props.searchFilterCarga,
                            pageNum: 1,
                            idString: value,
                            fueraDePlazo: false,
                            withTimeout: true
                        })
                    }}
                />

                <InputText
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="Referencia"
                    autoComplete={false}
                    valueEdit={props.searchFilterCarga.referencia}
                    value={props.searchFilterCarga.referencia}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                        value = value.trimStart()
                        props.saveSearchFilterCarga({
                            ...props.searchFilterCarga,
                            pageNum: 1,
                            referencia: value,
                            fueraDePlazo: false,
                            withTimeout: true
                        })
                    }}
                />

                {userLogged.userType === usersTypes.employee &&
                    <InputText
                        small={true}
                        colMesure="2"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.none}
                        name="Cliente"
                        valueEdit={props.searchFilterCarga.clientName}
                        autoComplete={false}
                        value={props.searchFilterCarga.clientName}
                        onChange={(event) => {
                            var value = validateAlphaNumeric(event.target.value)
                            value = value.trimStart()
                            props.saveSearchFilterCarga({
                                ...props.searchFilterCarga,
                                pageNum: 1,
                                clientName: value,
                                fueraDePlazo: false,
                                withTimeout: true
                            })
                        }}
                    />
                }

                {(userLogged.userType === usersTypes.employee && suscriptionData.proveedoresTransportes) &&
                    <InputText
                        small={true}
                        colMesure="2"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.none}
                        name="Transportista"
                        autoComplete={false}
                        valueEdit={props.searchFilterCarga.supplierName}
                        value={props.searchFilterCarga.supplierName}
                        onChange={(event) => {
                            var value = validateAlphaNumeric(event.target.value)
                            value = value.trimStart()
                            props.saveSearchFilterCarga({
                                ...props.searchFilterCarga,
                                pageNum: 1,
                                supplierName: value,
                                fueraDePlazo: false,
                                withTimeout: true
                            })
                        }}
                    />
                }

                {(userLogged.userType === usersTypes.employee && suscriptionData.empleados) &&
                    <InputText
                        small={true}
                        colMesure="2"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.none}
                        autoComplete={false}
                        name="Conductor"
                        valueEdit={props.searchFilterCarga.driverName}
                        value={props.searchFilterCarga.driverName}
                        onChange={(event) => {
                            var value = validateAlphaNumeric(event.target.value)
                            value = value.trimStart()
                            props.saveSearchFilterCarga({
                                ...props.searchFilterCarga,
                                pageNum: 1,
                                driverName: value,
                                fueraDePlazo: false,
                                withTimeout: true
                            })
                        }}
                    />
                }

                {userLogged.userType === usersTypes.employee &&
                    <InputText
                        small={true}
                        colMesure="2"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.none}
                        autoComplete={false}
                        name="Matrícula vehículo"
                        valueEdit={props.searchFilterCarga.plateVehicle}
                        value={props.searchFilterCarga.plateVehicle}
                        onChange={(event) => {
                            var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                            value = value.trimStart()
                            props.saveSearchFilterCarga({
                                ...props.searchFilterCarga,
                                pageNum: 1,
                                plateVehicle: value,
                                fueraDePlazo: false,
                                withTimeout: true
                            })
                        }}
                    />
                }

            </div>

            <div className="form-row" style={{ marginBottom: "-5px" }}>

                {userLogged.userType === usersTypes.employee &&
                    <InputDate
                        small={true}
                        colMesure="2"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.edditing}
                        name="Fecha alta desde"
                        valueEdit={props.searchFilterCarga.fechaDesde}
                        value={props.searchFilterCarga.fechaDesde}
                        onChange={(date) => {
                            props.saveSearchFilterCarga({
                                ...props.searchFilterCarga,
                                pageNum: 1,
                                fechaDesde: date!,
                                fueraDePlazo: false,
                                withTimeout: true
                            })
                        }}
                    />
                }

                {userLogged.userType === usersTypes.employee &&
                    <InputDate
                        small={true}
                        colMesure="2"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.edditing}
                        name="Fecha alta hasta"
                        valueEdit={props.searchFilterCarga.fechaHasta}
                        value={props.searchFilterCarga.fechaHasta}
                        onChange={(date) => {
                            props.saveSearchFilterCarga({
                                ...props.searchFilterCarga,
                                pageNum: 1,
                                fechaHasta: date!,
                                fueraDePlazo: false,
                                withTimeout: true
                            })
                        }}
                    />
                }

                <InputDate
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.edditing}
                    name="Fecha albarán desde"
                    valueEdit={props.searchFilterCarga.recogidaDesde}
                    value={props.searchFilterCarga.recogidaDesde}
                    onChange={(date) => {
                        props.saveSearchFilterCarga({
                            ...props.searchFilterCarga,
                            pageNum: 1,
                            recogidaDesde: date!,
                            fueraDePlazo: false,
                            withTimeout: true
                        })
                    }}
                />

                <InputDate
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.edditing}
                    name="Fecha albarán hasta"
                    valueEdit={props.searchFilterCarga.recogidaHasta}
                    value={props.searchFilterCarga.recogidaHasta}
                    onChange={(date) => {
                        props.saveSearchFilterCarga({
                            ...props.searchFilterCarga,
                            pageNum: 1,
                            recogidaHasta: date!,
                            fueraDePlazo: false,
                            withTimeout: true
                        })
                    }}
                />

                {userLogged.userType === usersTypes.employee &&
                    <InputDate
                        small={true}
                        colMesure="2"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.edditing}
                        name="Fecha entrega desde"
                        valueEdit={props.searchFilterCarga.entregaDesde}
                        value={props.searchFilterCarga.entregaDesde}
                        onChange={(date) => {
                            props.saveSearchFilterCarga({
                                ...props.searchFilterCarga,
                                pageNum: 1,
                                entregaDesde: date!,
                                fueraDePlazo: false,
                                withTimeout: true
                            })
                        }}
                    />
                }

                {userLogged.userType === usersTypes.employee &&
                    <InputDate
                        small={true}
                        colMesure="2"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.edditing}
                        name="Fecha entrega hasta"
                        valueEdit={props.searchFilterCarga.entregaHasta}
                        value={props.searchFilterCarga.entregaHasta}
                        onChange={(date) => {
                            props.saveSearchFilterCarga({
                                ...props.searchFilterCarga,
                                pageNum: 1,
                                entregaHasta: date!,
                                fueraDePlazo: false,
                                withTimeout: true
                            })
                        }}
                    />
                }

            </div>

            <div className="form-row" style={{ marginBottom: "-30px" }}>

                <InputText
                    small={true}
                    colMesure="4"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="Destino"
                    autoComplete={false}
                    valueEdit={props.searchFilterCarga.address}
                    value={props.searchFilterCarga.address}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilterCarga({
                            ...props.searchFilterCarga,
                            pageNum: 1,
                            address: value,
                            fueraDePlazo: false,
                            withTimeout: true
                        })
                    }}
                />

                <InputText
                    small={true}
                    colMesure="4"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="Concepto"
                    autoComplete={false}
                    valueEdit={props.searchFilterCarga.concepto}
                    value={props.searchFilterCarga.concepto}
                    onChange={(event) => {
                        let value = validateAlphaNumeric(event.target.value);
                        value = value.trimStart()
                        props.saveSearchFilterCarga({
                            ...props.searchFilterCarga,
                            pageNum: 1,
                            concepto: value,
                            fueraDePlazo: false,
                            withTimeout: true
                        })
                    }}
                />

                <div className="form-group col-md-2">
                    <br></br>
                    <button
                        className="btn btn-info btn-sm mr-3 mt-2"
                        onClick={() => {
                            props.saveSearchFilterCarga({ ...props.searchFilterCarga, withTimeout: false })
                        }}
                    >
                        <i className="fas fa-sync"></i>
                    </button>
                    <button className="btn btn-secondary btn-sm mt-2"
                        onClick={() => {
                            props.saveSearchFilterCarga({ ...inicialiceSearchFilterList })
                        }}>
                        Limpiar filtro
                    </button>
                </div>

                {userLogged.userType === usersTypes.employee &&
                    <div className="form-group col-md-2">
                        <br></br>
                        <button
                            className={props.searchFilterCarga.fueraDePlazo ? "btn btn-warning btn-sm mt-2" : "btn btn-warning btn-sm mt-2 disabled"}
                            onClick={() => {
                                props.saveSearchFilterCarga({
                                    ...props.searchFilterCarga,
                                    fueraDePlazo: !props.searchFilterCarga.fueraDePlazo,
                                    withTimeout: false
                                })
                            }}>
                            Vencidos
                        </button>
                    </div>
                }

            </div>

        </Fragment >

    );

};

export default CargaListFilter;
