import React, { ReactElement } from "react";
import { UserPermissions, inicialiceLoginUser, initSuscriptionData, loginUsers, searchFilterList, suscriptionData } from "aseguisShared";
import { allAppRoutes } from "./allAppRoutes";
import { RiInformation2Line } from "react-icons/ri";

export const trucoTipDirecciones = () => {
    const text = "Empiece escribiendo con una letra para buscar direcciones en Google o empiece con un número para buscar direcciones previamente introducidas para el cliente seleccionado. Si no encuentra la dirección, escríbala incluyendo el código postal seguido de un asterisco (ej: manuel falla 08206*)"
    return (
        <RiInformation2Line
            className="fa fa-info-circle"
            style={{ marginLeft: '5px', fontSize: '0.9em', marginBottom: "1px" }}
            data-tip={text}
            data-class="register-support-custom-tooltip"
        />
    )
}

export const openURL = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
};

export function Pagination(
    numPage: number,
    totalPages: number,
    searchFilter: searchFilterList,
    saveSearchFilter: (searchFilter: searchFilterList) => void): ReactElement {

    var newTotalPages: number[] = [];

    var numbersProxToSelected: number[] = [];
    var numbersPre: number[] = [];
    var numbersPost: number[] = [];

    var oneFound = false;
    var twoFound = false;
    var threeFound = false;
    var fourFound = false;

    var lastFound = false;
    var lastMinusOneFound = false;
    var lastMinusTwoFound = false;
    var lastMinusThreeFound = false;

    if (totalPages > 15) {

        for (let i = numPage - 4; i <= numPage + 4; i++) {
            if (i > 0 && i <= totalPages) {
                numbersProxToSelected.push(i)

                if (i === 1) oneFound = true
                if (i === 2) twoFound = true
                if (i === 3) threeFound = true
                if (i === 4) fourFound = true

                if (i === totalPages - 3) lastMinusThreeFound = true
                if (i === totalPages - 2) lastMinusTwoFound = true
                if (i === totalPages - 1) lastMinusOneFound = true
                if (i === totalPages) lastFound = true
            }
        }

        newTotalPages = numbersProxToSelected

        if (numbersProxToSelected.length > 0) {
            if (oneFound === false) numbersPre.push(1)
            if (twoFound === false) numbersPre.push(2)
            if (threeFound === false) numbersPre.push(3)
            if (fourFound === false) numbersPre.push(4)
        }

        if (numbersProxToSelected.length > 0) {
            if (lastMinusThreeFound === false) numbersPost.push(totalPages - 3)
            if (lastMinusTwoFound === false) numbersPost.push(totalPages - 2)
            if (lastMinusOneFound === false) numbersPost.push(totalPages - 1)
            if (lastFound === false) numbersPost.push(totalPages)
        }

    } else {

        for (let i = 1; i <= totalPages; i++) {
            newTotalPages.push(i)
        }
    }

    if (numPage > 0) {

        return (

            <nav aria-label="Page navigation example">

                <ul className="pagination justify-content-center">

                    <li
                        className={numPage === 1 ? "page-item disabled" : "page-item"}
                        onClick={(event) => {
                            if (numPage > 1) {
                                event.preventDefault()
                                saveSearchFilter({
                                    ...searchFilter,
                                    pageNum: searchFilter.pageNum - 1,
                                    withTimeout: false
                                })
                            }
                        }}
                    >
                        <a className="page-link" href="#" tabIndex={-1}>Anterior</a>
                    </li>

                    {numbersPre.map((pageNumber) =>
                        <li
                            key={pageNumber}
                            className={numPage === pageNumber ? "page-item active" : "page-item"}
                            onClick={(event) => {
                                event.preventDefault()
                                saveSearchFilter({
                                    ...searchFilter,
                                    pageNum: pageNumber,
                                    withTimeout: false
                                })
                            }}
                        >
                            <a className="page-link" href="#">{pageNumber}</a>
                        </li >
                    )}

                    {numbersPre.length > 0 && newTotalPages.length > 0 && numbersPre[numbersPre.length - 1] + 1 !== newTotalPages[0] &&
                        <li
                            className={"page-item"}
                            onClick={(event) => {
                                if (numbersPre[numbersPre.length - 1] + 1 === newTotalPages[0] - 1) {
                                    event.preventDefault()
                                    saveSearchFilter({
                                        ...searchFilter,
                                        pageNum: newTotalPages[0] - 1,
                                        withTimeout: false
                                    })
                                }
                            }}
                        >
                            {numbersPre[numbersPre.length - 1] + 1 === newTotalPages[0] - 1 ?
                                <a className="page-link" href="#">{newTotalPages[0] - 1}</a>
                                :
                                <a className="page-link">{"..."}</a>
                            }
                        </li >
                    }

                    {newTotalPages.map((pageNumber) =>
                        <li
                            key={pageNumber}
                            className={numPage === pageNumber ? "page-item active" : "page-item"}
                            onClick={(event) => {
                                event.preventDefault()
                                saveSearchFilter({
                                    ...searchFilter,
                                    pageNum: pageNumber,
                                    withTimeout: false
                                })
                            }}
                        >
                            <a className="page-link" href="#">{pageNumber}</a>
                        </li >
                    )}

                    {numbersPost.length > 0 && newTotalPages.length > 0 && numbersPost[0] !== newTotalPages[newTotalPages.length - 1] + 1 &&
                        <li
                            className={"page-item"}
                            onClick={(event) => {
                                if (numbersPost[0] - 1 === newTotalPages[newTotalPages.length - 1] + 1) {
                                    event.preventDefault()
                                    saveSearchFilter({
                                        ...searchFilter,
                                        pageNum: numbersPost[0] - 1,
                                        withTimeout: false
                                    })
                                }
                            }}
                        >
                            {numbersPost[0] - 1 === newTotalPages[newTotalPages.length - 1] + 1 ?
                                <a className="page-link" href="#">{numbersPost[0] - 1}</a>
                                :
                                <a className="page-link">{"..."}</a>
                            }
                        </li >
                    }

                    {numbersPost.map((pageNumber) =>
                        <li
                            key={pageNumber}
                            className={numPage === pageNumber ? "page-item active" : "page-item"}
                            onClick={(event) => {
                                event.preventDefault()
                                saveSearchFilter({
                                    ...searchFilter,
                                    pageNum: pageNumber,
                                    withTimeout: false
                                })
                            }}
                        >
                            <a className="page-link" href="#">{pageNumber}</a>
                        </li >
                    )}

                    <li
                        className={numPage === totalPages ? "page-item disabled" : "page-item"}
                        onClick={(event) => {
                            if (numPage < totalPages) {
                                event.preventDefault()
                                saveSearchFilter({
                                    ...searchFilter,
                                    pageNum: searchFilter.pageNum + 1,
                                    withTimeout: false
                                })
                            }
                        }}
                    >
                        <a className="page-link" href="#">Siguiente</a>
                    </li>

                </ul>

            </nav>
        )

    } else {

        return (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                    <div>No existen resultados</div>
                </ul>
            </nav>
        )

    }

}


export function GetFirstRouteEnabled(): string {

    let { permissions } = GetUserPermissionsFromLocalStorage()

    if (permissions.panel !== UserPermissions.deshabilitado) { return allAppRoutes.leftMenuPanel }
    if (permissions.compania !== UserPermissions.deshabilitado) { return allAppRoutes.leftMenuCompany }
    if (permissions.clients !== UserPermissions.deshabilitado) { return allAppRoutes.leftMenuClients }
    if (permissions.employees !== UserPermissions.deshabilitado) { return allAppRoutes.leftMenuEmployees }
    if (permissions.proveedores !== UserPermissions.deshabilitado) { return allAppRoutes.leftMenuSuppliers }
    if (permissions.vehicles !== UserPermissions.deshabilitado) { return allAppRoutes.leftMenuVehicles }
    if (permissions.vehiclesControls !== UserPermissions.deshabilitado) { return allAppRoutes.leftMenuVehiclesControls }
    if (permissions.vehiclesReviRepa !== UserPermissions.deshabilitado) { return allAppRoutes.leftMenuVehiclesReviRepa }
    if (permissions.grupajes !== UserPermissions.deshabilitado) { return allAppRoutes.leftMenuGrupajes }
    if (permissions.cargaCompleta !== UserPermissions.deshabilitado) { return allAppRoutes.leftMenuCargaCompleta }
    if (permissions.paqueteria !== UserPermissions.deshabilitado) { return allAppRoutes.leftMenuPaqueteria }
    if (permissions.bills !== UserPermissions.deshabilitado) { return allAppRoutes.leftMenuBills }

    localStorage.clear();
    return (allAppRoutes.login)
}

export const GetUserPermissionsFromLocalStorage = () => {
    const loggedUser = localStorage.getItem('loggedUser')
    let permissions = inicialiceLoginUser.permisosUsuario
    let userType = inicialiceLoginUser.userType
    if (loggedUser) {
        const parsedLoggedUser: loginUsers = JSON.parse(loggedUser);
        permissions = parsedLoggedUser.permisosUsuario
        userType = parsedLoggedUser.userType
    }
    return { permissions, userType }
}

export const GetSuscriptionDataFromLocalStorage = () => {
    const suscriptionData = localStorage.getItem('suscriptionData')
    let parsedSuscriptionData: suscriptionData = initSuscriptionData
    if (suscriptionData) {
        parsedSuscriptionData = JSON.parse(suscriptionData);
    }
    return parsedSuscriptionData
}