import { useSelector } from "react-redux";
import React, { Fragment, ReactElement } from "react";
import { ApplicationState } from '../../ApplicationState';
import InputText from "../../common/Inputs/InputText";
import { addingOrEditing, usersTypes, loginUsers, inicialiceSearchFilterList, searchFilterList } from "aseguisShared";
import InputNumber from "../../common/Inputs/InputNumber";
import InputDate from "../../common/Inputs/InputDate";

interface infoToBillsListFilter {
    searchFilterBills: searchFilterList
    saveSearchFilterBills: (filter: searchFilterList) => void
}
function BillsListFilter(props: infoToBillsListFilter): ReactElement {

    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);

    return (

        <Fragment>

            <div className="form-row" style={{ marginBottom: "-30px" }}>

                <InputNumber
                    small={true}
                    colMesure="2"
                    format="##########"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="ID factura"
                    valueEdit={props.searchFilterBills.id}
                    value={props.searchFilterBills.id}
                    onChange={(value) => {
                        props.saveSearchFilterBills({
                            ...props.searchFilterBills,
                            pageNum: 1,
                            id: value.floatValue ? value.floatValue : 0,
                            withTimeout: true
                        })
                    }}
                />

                {userLogged.userType === usersTypes.employee &&
                    <InputText
                        small={true}
                        colMesure="2"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.none}
                        name="Cliente"
                        valueEdit={props.searchFilterBills.clientName}
                        value={props.searchFilterBills.clientName}
                        onChange={(event) => {
                            props.saveSearchFilterBills({
                                ...props.searchFilterBills,
                                pageNum: 1,
                                clientName: event.target.value,
                                withTimeout: true
                            })
                        }}
                    />
                }

                <InputDate
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.edditing}
                    name="Fecha desde"
                    valueEdit={props.searchFilterBills.fechaDesde}
                    value={props.searchFilterBills.fechaDesde}
                    onChange={(date) => {
                        props.saveSearchFilterBills({
                            ...props.searchFilterBills,
                            pageNum: 1,
                            fechaDesde: date!,
                            withTimeout: true
                        })
                    }}
                />

                <InputDate
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.edditing}
                    name="Fecha hasta"
                    valueEdit={props.searchFilterBills.fechaHasta}
                    value={props.searchFilterBills.fechaHasta}
                    onChange={(date) => {
                        props.saveSearchFilterBills({
                            ...props.searchFilterBills,
                            pageNum: 1,
                            fechaHasta: date!,
                            withTimeout: true
                        })
                    }}
                />

                <div className="form-group col-md-2">
                    <br></br>
                    <button
                        className="btn btn-info btn-sm mr-3 mt-2"
                        onClick={() => {
                            props.saveSearchFilterBills({ ...props.searchFilterBills, withTimeout: false })
                        }}
                    >
                        <i className="fas fa-sync"></i>
                    </button>
                    <button className="btn btn-secondary btn-sm mt-2"
                        onClick={() => {
                            props.saveSearchFilterBills(inicialiceSearchFilterList)
                        }}>
                        Limpiar filtro
                    </button>
                </div>

                <div className="form-group col-md-2">
                    <br></br>
                    <button className="btn btn-warning btn-sm mt-2"
                        onClick={() => {
                            props.saveSearchFilterBills({ ...inicialiceSearchFilterList, estaVencida: true })
                        }}>
                        Mostrar Vencidas
                    </button>
                </div>

            </div>

        </Fragment >

    );

};

export default BillsListFilter;
