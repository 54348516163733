import React, { Fragment, ReactElement, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from '../../ApplicationState';
import {
    CapitalizefirstLetter, formatearMatricula, inicialiceDate, urlParmsForm,
    vehicle, addingOrEditing, controlVehicleTypes, vehicleValidateFieldsResult,
    tiposArticuladosVehiculos, validateAlphaNumeric, validateFormEmpty, CalculatelistVehiclesControl,
    getFormattedDate,
} from "aseguisShared"
import { saveFormVehicle, setValidateVehicleForm, validateIfExistsFields, addOrEditVehicle, setFormsVehicle, requestOneVehicle } from "./VehiclesStore";
import NumberFormat from 'react-number-format';
import VehiclesTypesAutocomplete from "./VehiclesSettings/VehiclesTypesAutocomplete";
import InputNumber from "../../common/Inputs/InputNumber";
import InputDate from "../../common/Inputs/InputDate";
import { useHistory, useParams } from "react-router-dom";
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";

interface infoToVehicleForm {
    isEdditing: number;
    errorForm: boolean,
    setErrorForm: (value: boolean) => void,
    setEdditing: (value: addingOrEditing) => void,
}

function VehicleForm(props: infoToVehicleForm): ReactElement {

    const debounceRef = useRef<NodeJS.Timeout | null>(null);
    const dispatch: React.Dispatch<any> = useDispatch();
    const vehicleForm: vehicle = useSelector((state: ApplicationState) => state.vehicles.vehicleForm);
    const vehicleEditForm: vehicle = useSelector((state: ApplicationState) => state.vehicles.vehicleEditForm);
    const controlsTypes: controlVehicleTypes[] = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm.vehiclesControlesTypes);
    const addingOrEditVehicle: addingOrEditing = useSelector((state: ApplicationState) => state.vehicles.addOrEditVehicles) || addingOrEditing.none;
    const validateFieldsForm: vehicleValidateFieldsResult = useSelector((state: ApplicationState) => state.vehicles.validateFieldsForm);

    const [errorVehicleTypeForm, setErrorVehicleTypeForm] = useState(false);
    const [goToValidateFields, setGoToValidateFields] = useState(false);

    useEffect(() => {
        if (
            (vehicleEditForm.matricula.length < 5) ||
            (vehicleEditForm.marca.length < 2) ||
            (vehicleEditForm.modelo.length < 2)
        ) {
            props.setErrorForm(true)
        } else {
            if (validateFieldsForm.anyFieldExist === true) {
                props.setErrorForm(true)
            } else {
                props.setErrorForm(errorVehicleTypeForm)
            }
        }
    }, [vehicleEditForm, validateFieldsForm.anyFieldExist, errorVehicleTypeForm])

    useEffect(() => {
        if (goToValidateFields === true) {
            dispatch(validateIfExistsFields(vehicleEditForm));
            setGoToValidateFields(false)
        }
    }, [goToValidateFields])

    const CalculatelistControlsEdit = CalculatelistVehiclesControl(controlsTypes, vehicleEditForm.lastControl.controlsList)
    const listVehiclesControlEditNoDone: JSX.Element[] = CalculatelistControlsEdit.noRealizados.map((controlType) => {
        return (
            <li key={controlType.id}>
                <b style={{ color: '#E23F3F' }}>{controlType.description + ": "}</b>  {" Control Pendiente. "}
                {(controlType.fecAlta !== inicialiceDate.year1000) ?
                    <>
                        <b> - Anterior control: </b> {(new Date(controlType.fecAlta).getTime() === new Date(inicialiceDate.year1000).getTime()) ? "Sin control previo" : getFormattedDate(controlType.fecAlta)}
                        {(controlType.observaciones !== "") && <><b> - Nota: </b> {" " + controlType.observaciones} </>}
                    </>
                    : <> - Sin control anterior</>
                }
            </li>
        )
    });
    const listVehiclesControlEditDone: JSX.Element[] = CalculatelistControlsEdit.realizados.map((controlType, index) => {
        return (
            <li key={controlType.id}>
                <b>{controlType.description + ": "}</b>
                {"Realizado el "} {getFormattedDate(controlType.fecAlta) + ". "}
                <b>{"Próximo control:"}</b> {" " + getFormattedDate(CalculatelistControlsEdit.proxDates[index]) + ". "}
                {(controlType.observaciones !== "") && <><b> Nota: </b> {" " + controlType.observaciones} </>}

                {props.isEdditing === addingOrEditing.edditing &&
                    <button className="btn btn-link btn-redsmall"
                        onClick={() => {
                            dispatch(
                                saveFormVehicle({
                                    ...vehicleEditForm,
                                    lastControl: {
                                        ...vehicleEditForm.lastControl,
                                        controlsList: vehicleEditForm.lastControl.controlsList.filter(control => control.description !== controlType.description),
                                    }
                                })
                            );
                        }}>
                        <i className="fas fa-times fa-lg"></i>
                    </button>
                }
            </li>
        )
    });

    const CalculatelistControlsOriginal = CalculatelistVehiclesControl(controlsTypes, vehicleForm.lastControl.controlsList)
    const listVehiclesControlOriginalNoDone: JSX.Element[] = CalculatelistControlsOriginal.noRealizados.map((controlType) => {
        return (
            <li key={controlType.id}>
                <b style={{ color: '#E23F3F' }}>{controlType.description + ": "}</b>  {" Control Pendiente. "}
                {(controlType.fecAlta !== inicialiceDate.year1000) ?
                    <>
                        <b> - Anterior control: </b> {(new Date(controlType.fecAlta).getTime() === new Date(inicialiceDate.year1000).getTime()) ? "Sin control previo" : getFormattedDate(controlType.fecAlta)}
                        {(controlType.observaciones !== "") && <><b> - Nota: </b> {" " + controlType.observaciones} </>}
                    </>
                    : <> - Sin control anterior</>
                }
            </li>
        )
    });
    const listVehiclesControlOriginalDone: JSX.Element[] = CalculatelistControlsOriginal.realizados.map((controlType, index) => {
        return (
            <li key={controlType.id}>
                <b>{controlType.description + ": "}</b>
                {"Realizado el "} {getFormattedDate(controlType.fecAlta) + ". "}
                <b>{"Próximo control:"}</b> {" " + getFormattedDate(CalculatelistControlsOriginal.proxDates[index]) + ". "}
                {(controlType.observaciones !== "") && <><b> Nota: </b> {" " + controlType.observaciones} </>}
            </li>
        )
    });

    //para tener siempre la ultima versión...
    let history = useHistory()
    let { id } = useParams<urlParmsForm>();
    useEffect(() => {
        if (id === "new") {
            dispatch(addOrEditVehicle(addingOrEditing.adding));
            props.setEdditing(addingOrEditing.edditing)
        } else {
            requestOneVehicle(id)
                .then((vehicle) => {
                    if (vehicle.data.vehiclesResults.length === 1) {
                        dispatch(addOrEditVehicle(addingOrEditing.edditing));
                        dispatch(setFormsVehicle(vehicle.data.vehiclesResults[0]))
                    } else {
                        history.push(allAppRoutes.vehiclesList)
                    }
                })
                .catch((error) => {
                    history.push(allAppRoutes.vehiclesList)
                })
        }
    }, [id]);
    //...para tener siempre la ultima versión

    return (
        <Fragment>

            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">
                        {(addingOrEditVehicle !== addingOrEditing.adding) &&
                            <span> {"# " + vehicleEditForm.id.toString()}</span>
                        }
                        {(addingOrEditVehicle === addingOrEditing.adding) &&
                            <span> {" Añadir Vehículo "}</span>
                        }
                    </h6>
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="fecAlta">Fecha de alta</label>
                                    <div>{getFormattedDate(vehicleEditForm.fecAlta)}</div>
                                </div>
                            </div>
                            <InputDate
                                colMesure="4"
                                editEnabled={props.isEdditing}
                                addOrEdit={addingOrEditVehicle}
                                name="Fecha matriculación"
                                valueEdit={vehicleEditForm.fecMatricula}
                                value={vehicleForm.fecMatricula}
                                onChange={(date) => {
                                    dispatch(saveFormVehicle({
                                        ...vehicleEditForm,
                                        fecMatricula: date,
                                        fecAlta: addingOrEditVehicle === addingOrEditing.adding ? new Date() : vehicleEditForm.fecAlta
                                    }));
                                }}
                            />
                        </div>

                        <div className="form-row" style={{ marginTop: "20px" }}>
                            <div className="form-group col-md-4">
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="name">Matrícula</label>
                                    {(props.isEdditing === addingOrEditing.edditing) ?
                                        <input
                                            type="text"
                                            value={vehicleEditForm.matricula}
                                            onChange={(event) => {
                                                var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                                                dispatch(saveFormVehicle({
                                                    ...vehicleEditForm,
                                                    matricula: value,
                                                    fecAlta: addingOrEditVehicle === addingOrEditing.adding ? new Date() : vehicleEditForm.fecAlta
                                                }));
                                                dispatch(setValidateVehicleForm({
                                                    ...validateFieldsForm,
                                                    matriculaExists: false
                                                }))
                                                if (debounceRef.current) {
                                                    clearTimeout(debounceRef.current);
                                                }
                                                debounceRef.current = setTimeout(() => {
                                                    setGoToValidateFields(true);
                                                }, 400);
                                            }}
                                            className={validateFormEmpty(vehicleEditForm.matricula, 5)}
                                            id="matricula" />
                                        :
                                        <input
                                            name="vehMatricula"
                                            disabled={true}
                                            type="text"
                                            value={formatearMatricula(vehicleForm.matricula)}
                                            className="form-control undefined"
                                        />
                                    }

                                    <div className="text-danger" style={{ height: "20px" }}>
                                        {(validateFieldsForm.matriculaExists) &&
                                            <small> MATRICULA ya asignada a otro vehículo</small>
                                        }
                                    </div>

                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="marca">Marca</label>
                                    {(props.isEdditing === addingOrEditing.edditing) ?
                                        <input
                                            type="text"
                                            value={vehicleEditForm.marca}
                                            onChange={(event) => {
                                                var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                                dispatch(saveFormVehicle({ ...vehicleEditForm, marca: value }));
                                            }}
                                            className={validateFormEmpty(vehicleEditForm.marca, 2)}
                                            id="marca" />
                                        :
                                        <input
                                            name="vehMarca"
                                            disabled={true}
                                            type="text"
                                            value={vehicleForm.marca}
                                            className="form-control undefined"
                                        />
                                    }
                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="Modelo">Modelo</label>
                                    {(props.isEdditing === addingOrEditing.edditing) ?
                                        <input
                                            type="text"
                                            value={vehicleEditForm.modelo}
                                            onChange={(event) => {
                                                var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                                dispatch(saveFormVehicle({ ...vehicleEditForm, modelo: value }));
                                            }}
                                            className={validateFormEmpty(vehicleEditForm.modelo, 2)}
                                            id="modelo" />
                                        :
                                        <input
                                            name="vehmodelo"
                                            disabled={true}
                                            type="text"
                                            value={vehicleForm.modelo}
                                            className="form-control undefined"
                                        />
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="mma">Tipo de vehículo</label>
                                    {(props.isEdditing === addingOrEditing.edditing) ?
                                        <VehiclesTypesAutocomplete
                                            whatShow="all"
                                            vehicleTypeSelected={vehicleEditForm.vehicleType}
                                            onClick={(vehiType) => {
                                                dispatch(
                                                    saveFormVehicle({
                                                        ...vehicleEditForm,
                                                        vehicleType: vehiType
                                                    })
                                                );
                                            }}
                                            setErrorForm={setErrorVehicleTypeForm}
                                        />
                                        :
                                        <input
                                            name="vehTytpeDesc"
                                            disabled={true}
                                            type="text"
                                            value={vehicleForm.vehicleType.description}
                                            className="form-control undefined"
                                        />
                                    }
                                </div>
                                <div className="text-danger" style={{ height: "20px" }}>
                                </div>
                            </div>

                            <InputDate
                                redOnError={true}
                                colMesure="4"
                                editEnabled={props.isEdditing}
                                addOrEdit={addingOrEditVehicle}
                                name="Fin ITV"
                                valueEdit={vehicleEditForm.finItv}
                                value={vehicleForm.finItv}
                                onChange={(date) => {
                                    dispatch(saveFormVehicle({
                                        ...vehicleEditForm,
                                        finItv: date
                                    }));
                                }}
                            />

                            {(
                                (props.isEdditing === addingOrEditing.edditing && vehicleEditForm.vehicleType.tieneTacografo === true) ||
                                (props.isEdditing !== addingOrEditing.edditing && vehicleForm.vehicleType.tieneTacografo === true)
                            ) &&
                                <InputDate
                                    redOnError={true}
                                    colMesure="4"
                                    editEnabled={props.isEdditing}
                                    addOrEdit={addingOrEditVehicle}
                                    name="Fin tacógrafo"
                                    valueEdit={vehicleEditForm.revTacografo}
                                    value={vehicleForm.revTacografo}
                                    onChange={(date) => {
                                        dispatch(saveFormVehicle({
                                            ...vehicleEditForm,
                                            revTacografo: date
                                        }));
                                    }}

                                />
                            }

                        </div>


                        {(
                            (props.isEdditing === addingOrEditing.edditing && vehicleEditForm.vehicleType.tractoraRemolque !== tiposArticuladosVehiculos.remolque) ||
                            (props.isEdditing !== addingOrEditing.edditing && vehicleForm.vehicleType.tractoraRemolque !== tiposArticuladosVehiculos.remolque)
                        ) &&
                            <div className="form-row">
                                <InputNumber
                                    colMesure="4"
                                    editEnabled={props.isEdditing}
                                    addOrEdit={addingOrEditVehicle}
                                    name="Km actuales"
                                    valueEdit={vehicleEditForm.kms}
                                    value={vehicleForm.kms}
                                    onChange={(value) => {
                                        dispatch(saveFormVehicle({
                                            ...vehicleEditForm,
                                            kms: value.floatValue ? value.floatValue : 0
                                        }));
                                    }}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    withoutformRow={true}
                                />

                                <InputNumber
                                    style={(
                                        ((vehicleEditForm.lastRevision.proxKm < vehicleEditForm.kms) && (props.isEdditing === addingOrEditing.edditing)) ||
                                        ((vehicleForm.lastRevision.proxKm < vehicleEditForm.kms) && (props.isEdditing !== addingOrEditing.edditing))
                                    ) ? { color: '#E23F3F' } : {}}
                                    colMesure="4"
                                    editEnabled={props.isEdditing}
                                    addOrEdit={addingOrEditVehicle}
                                    name="Km prox revisión"
                                    valueEdit={vehicleEditForm.lastRevision.proxKm}
                                    value={vehicleForm.lastRevision.proxKm}
                                    onChange={(value) => {
                                        dispatch(saveFormVehicle({
                                            ...vehicleEditForm,
                                            lastRevision: {
                                                ...vehicleEditForm.lastRevision,
                                                proxKm: value.floatValue ? value.floatValue : 0
                                            }
                                        }));
                                    }}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    withoutformRow={true}
                                />

                                <InputDate
                                    redOnError={true}
                                    colMesure="4"
                                    editEnabled={props.isEdditing}
                                    addOrEdit={addingOrEditVehicle}
                                    name="Fecha prox revisión"
                                    valueEdit={vehicleEditForm.lastRevision.fecProxRev}
                                    value={vehicleForm.lastRevision.fecProxRev}
                                    onChange={(date) => {
                                        dispatch(saveFormVehicle({
                                            ...vehicleEditForm,
                                            lastRevision: {
                                                ...vehicleEditForm.lastRevision,
                                                fecProxRev: date!
                                            }
                                        }));
                                    }}
                                />

                            </div>
                        }

                        <div className="form-row" style={{ marginTop: "20px" }}>
                            <div className="form-group col-md-12">
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="Modelo">Observaciones vehículo</label>
                                    {(props.isEdditing === addingOrEditing.edditing) ?
                                        <input
                                            type="text"
                                            value={vehicleEditForm.observaciones}
                                            onChange={(event) => {
                                                dispatch(saveFormVehicle({ ...vehicleEditForm, observaciones: event.target.value }));
                                            }}
                                            className="form-control undefined"
                                            id="modelo" />
                                        :
                                        <input
                                            name="vehobservaciones"
                                            disabled={true}
                                            type="text"
                                            value={vehicleForm.observaciones}
                                            className="form-control undefined"
                                        />
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="form-row">

                            {(addingOrEditVehicle === addingOrEditing.edditing && controlsTypes.length > 0) &&
                                <div className="form-group col-md-12">
                                    <div>
                                        <br></br>
                                        <label style={{ fontWeight: 'bold' }} htmlFor="name">Últimos controles</label>
                                        {(props.isEdditing === addingOrEditing.edditing) ?
                                            <div>
                                                {listVehiclesControlEditNoDone}
                                                {listVehiclesControlEditDone}
                                            </div>
                                            :
                                            <div>
                                                {listVehiclesControlOriginalNoDone}
                                                {listVehiclesControlOriginalDone}
                                            </div>
                                        }
                                    </div>
                                </div>}
                        </div>

                        <div className="form-row">
                            {addingOrEditVehicle === addingOrEditing.edditing &&
                                <div className="form-group col-md-12">
                                    <div>
                                        <br></br>
                                        <label style={{ fontWeight: 'bold' }} htmlFor="name">Última revisión</label>
                                        {(props.isEdditing === addingOrEditing.edditing) ?
                                            <div>
                                                {vehicleEditForm.lastRevision.observaciones !== "" ?
                                                    <div>
                                                        <div> <b style={{ fontWeight: 'bold' }}>Fecha:</b> {getFormattedDate(vehicleEditForm.lastRevision.fecha)}</div>
                                                        <NumberFormat
                                                            renderText={value => <div> <b style={{ fontWeight: 'bold' }}>Km:</b> {value}</div>}
                                                            displayType="text"
                                                            value={vehicleEditForm.lastRevision.km}
                                                            id={"km"}
                                                            isNumericString={true}
                                                            className="form-control undefined"
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            decimalScale={0}
                                                            allowNegative={false}
                                                            suffix={" km"}
                                                        />
                                                        <NumberFormat
                                                            renderText={value => <div> <b style={{ fontWeight: 'bold' }}>Próxima revisión:</b> {value}</div>}
                                                            displayType="text"
                                                            value={vehicleEditForm.lastRevision.proxKm}
                                                            id={"km"}
                                                            isNumericString={true}
                                                            className="form-control undefined"
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            decimalScale={0}
                                                            allowNegative={false}
                                                            suffix={" km"}
                                                        />
                                                        <div>
                                                            <b style={{ fontWeight: 'bold' }}>Tareas realizadas:</b>
                                                            <textarea
                                                                readOnly={true}
                                                                rows={5}
                                                                value={vehicleEditForm.lastRevision.observaciones}
                                                                className="form-control undefined"
                                                                id="observaciones"
                                                            />
                                                        </div>
                                                    </div> : <div> Sin revisiones </div>}
                                            </div>
                                            :
                                            <div>
                                                {vehicleForm.lastRevision.observaciones !== "" ?
                                                    <div>
                                                        <div><b style={{ fontWeight: 'bold' }}>Fecha:</b> {getFormattedDate(vehicleForm.lastRevision.fecha)}</div>
                                                        <NumberFormat
                                                            renderText={value => <div><b style={{ fontWeight: 'bold' }}>Km:</b> {value}</div>}
                                                            displayType="text"
                                                            value={vehicleForm.lastRevision.km}
                                                            id={"km"}
                                                            isNumericString={true}
                                                            className="form-control undefined"
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            decimalScale={0}
                                                            allowNegative={false}
                                                            suffix={" km"}
                                                        />
                                                        <NumberFormat
                                                            renderText={value => <div><b style={{ fontWeight: 'bold' }}>Próxima revisión:</b> {value}</div>}
                                                            displayType="text"
                                                            value={vehicleForm.lastRevision.proxKm}
                                                            id={"km"}
                                                            isNumericString={true}
                                                            className="form-control undefined"
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            decimalScale={0}
                                                            allowNegative={false}
                                                            suffix={" km"}
                                                        />
                                                        <div>
                                                            <b style={{ fontWeight: 'bold' }}>Tareas realizadas:</b>
                                                            <textarea
                                                                readOnly={true}
                                                                rows={5}
                                                                value={vehicleForm.lastRevision.observaciones}
                                                                className="form-control undefined"
                                                                id="observaciones"
                                                            />
                                                        </div>
                                                    </div> : <div> Sin revisiones </div>}
                                            </div>
                                        }
                                    </div>
                                </div>
                            }

                        </div>

                    </form>
                </div>
            </div>

        </Fragment>

    );
};

export default VehicleForm;
