import { Action, Dispatch } from 'redux';
import { ApplicationState } from '../../ApplicationState';
import axios from 'axios';
import {
    cargaCompleta, datosEconomicosCarga, datosGeneralesCarga, loading, addingOrEditing, inicialiceSearchFilterList
    , inicializeDatosEconomicosCarga, inicializeDatosGeneralesCarga, searchFilterList
} from 'aseguisShared';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface grupajeState {
    allGrupajes: grupaje[];
    grupajeForm: grupaje;
    grupajeEditForm: grupaje;
    addOrEditGrupaje: number,
    grupajeGetLoading: number,
    grupajeAddHistory: grupaje[]
    searchFilterGrupajes: searchFilterList,
    totalGrupajesList: number,
    actualPage: number,
    totalPages: number
}

export interface grupaje {
    _id: string | null;
    id: number;
    datosGenerales: datosGeneralesCarga;
    datosEconomicos: datosEconomicosCarga;
    cargasCompletas: cargaCompleta[];
}

export interface searchedGrupajes {
    grupajesResults: grupaje[];
    totalResults: number;
    numPage: number;
    totalPages: number;
}

export const inicializeGrupaje: grupaje = {
    _id: null,
    id: 0,
    datosGenerales: inicializeDatosGeneralesCarga,
    datosEconomicos: inicializeDatosEconomicosCarga,
    cargasCompletas: []
};

export const initialGrupaje: grupajeState = {
    allGrupajes: [],
    grupajeForm: inicializeGrupaje,
    grupajeEditForm: inicializeGrupaje,
    addOrEditGrupaje: addingOrEditing.none,
    grupajeGetLoading: loading.pending,
    actualPage: 0,
    totalPages: 0,
    grupajeAddHistory: [],
    searchFilterGrupajes: inicialiceSearchFilterList,
    totalGrupajesList: 0
};


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface saveFormGrupajeAction {
    type: 'SAVE_FORM_GRUPAJE',
    grupaje: grupaje
}

export interface addEditGrupajeAction {
    type: 'CHANGE_ADD_EDIT_GRUPAJE',
    addOrEdit: number
}

export interface addReceivedGrupajeAction {
    type: 'ADD_RECEIVED_GRUPAJE',
    grupaje: grupaje
}

export interface saveEditReceivedGrupajeAction {
    type: 'SAVE_EDIT_RECEIVED_GRUPAJE',
    grupaje: grupaje,
}

export interface RequestAllGrupajeAction {
    type: 'REQUEST_ALL_GRUPAJE'
}

export interface ReceivedAllGrupajeAction {
    type: 'RECEIVED_ALL_GRUPAJE',
    grupajes: searchedGrupajes
}

export interface ReceivedDeleteGrupajeAction {
    type: 'DELETE_RECEIVED_GRUPAJE'
}

export interface saveSearchGrupajeAction {
    type: 'SAVE_SEARCH_FILTER_GRUPAJE',
    searchFilterGrupaje: searchFilterList
}

export interface setBothFormsGrupajeAction {
    type: 'SET_BOTH_FORMS_GRUPAJE',
    grupaje: grupaje
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = saveFormGrupajeAction | ReceivedDeleteGrupajeAction | ReceivedAllGrupajeAction |
    addReceivedGrupajeAction | saveEditReceivedGrupajeAction | RequestAllGrupajeAction |
    addEditGrupajeAction | saveSearchGrupajeAction | setBothFormsGrupajeAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export function setFormsGrupaje(grupaje: grupaje): setBothFormsGrupajeAction {
    return {
        type: 'SET_BOTH_FORMS_GRUPAJE',
        grupaje: grupaje
    };
}

export function saveFormGrupaje(grupaje: grupaje): saveFormGrupajeAction {
    return {
        type: 'SAVE_FORM_GRUPAJE',
        grupaje: grupaje
    };
}

export function addOrEditGrupaje(addOrEdit: number): addEditGrupajeAction {
    return {
        type: 'CHANGE_ADD_EDIT_GRUPAJE',
        addOrEdit: addOrEdit
    };
}

export function saveSearchFilterGrupaje(searchGrupajesFilter: searchFilterList): saveSearchGrupajeAction {
    return {
        type: 'SAVE_SEARCH_FILTER_GRUPAJE',
        searchFilterGrupaje: searchGrupajesFilter
    };
}

/**************************************************************
 **************************************************************
 *
 * @param GRUPAJE_OPERATIONS
 *
 * ************************************************************
 **************************************************************/

export function addGrupaje(grupaje: grupaje) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: 'REQUEST_ALL_GRUPAJE' });
        return axios.post('api/grupajes/', grupaje)
            .then((data) => {
                dispatch({ type: 'ADD_RECEIVED_GRUPAJE', grupaje: data.data });
            })
            .catch((error) => {
                /*if (error.response.status) {
                    if (error.response.status === 401) {
                        dispatch({ type: 'ADD_RECEIVED_GRUPAJE', grupaje: grupaje });
                    }
                }*/
            })
    }
}

export function editGrupaje(grupaje: grupaje) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: 'REQUEST_ALL_GRUPAJE' });
        return axios.put('api/grupajes/', grupaje)
            .then((data) => {
                dispatch({ type: 'SAVE_EDIT_RECEIVED_GRUPAJE', grupaje: data.data });
            })
            .catch((error) => {
                /*if (error.response.status) {
                    if (error.response.status === 401) {
                        dispatch({ type: 'SAVE_EDIT_RECEIVED_GRUPAJE', grupaje: grupaje });
                    }
                }*/
            })
    }
}

export function requestAllGrupajes(searchGrupajesFilter: searchFilterList) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        dispatch({ type: 'REQUEST_ALL_GRUPAJE' });
        return axios.post<searchedGrupajes>('api/grupajes/searchFilter/', searchGrupajesFilter)
            .then((data) => {
                dispatch({ type: 'RECEIVED_ALL_GRUPAJE', grupajes: data.data });
            })
            .catch((error) => {
            })
    }
}

export const requestOneGrupaje = async (idGru: string) => {
    let searchFilter = { ...inicialiceSearchFilterList, idMongo: idGru }
    return await axios.post<searchedGrupajes>('api/grupajes/searchFilter/', searchFilter)
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
function GrupajeReducer(state: grupajeState = initialGrupaje, incomingAction: Action): grupajeState {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_BOTH_FORMS_GRUPAJE': {
            return {
                ...state,
                grupajeForm: action.grupaje,
                grupajeEditForm: action.grupaje,
            }
        }
        case 'SAVE_FORM_GRUPAJE': {
            return {
                ...state,
                grupajeEditForm: action.grupaje,
            };
        }
        case 'CHANGE_ADD_EDIT_GRUPAJE': {
            return {
                ...state,
                addOrEditGrupaje: action.addOrEdit
            }
        }
        case 'ADD_RECEIVED_GRUPAJE': {
            return {
                ...state,
                searchFilterGrupajes: {
                    ...inicialiceSearchFilterList,
                    id: action.grupaje.id
                },
                grupajeForm: action.grupaje,
                grupajeEditForm: action.grupaje,
                grupajeAddHistory: [...state.grupajeAddHistory, action.grupaje],
                addOrEditGrupaje: addingOrEditing.adding
            };
        }
        case 'SAVE_EDIT_RECEIVED_GRUPAJE': {
            return {
                ...state,
                searchFilterGrupajes: {
                    ...inicialiceSearchFilterList,
                    id: action.grupaje.id
                },
                grupajeForm: action.grupaje,
                grupajeEditForm: action.grupaje,
            };
        }
        case 'REQUEST_ALL_GRUPAJE': {
            return {
                ...state,
                grupajeGetLoading: loading.pending
            };
        }
        case 'RECEIVED_ALL_GRUPAJE': {
            return {
                ...state,
                grupajeGetLoading: loading.done,
                allGrupajes: action.grupajes.grupajesResults,
                totalGrupajesList: action.grupajes.totalResults,
                actualPage: action.grupajes.numPage,
                totalPages: action.grupajes.totalPages
            };
        }
        case 'SAVE_SEARCH_FILTER_GRUPAJE': {
            return {
                ...state,
                searchFilterGrupajes: action.searchFilterGrupaje,
                grupajeGetLoading: loading.pending
            };
        }

        default:
            return state;
    }
};

export default GrupajeReducer;