"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inicializeCargaCompletaTariff = exports.inicializePaqueteriaTariff = exports.inicialiceTariff = exports.inicialiceLocTariff = void 0;
const commonEnums_1 = require("../enums/commonEnums");
const commonFunctions_1 = require("./commonFunctions");
const postalCodeFunctions_1 = require("./postalCodeFunctions");
const vehiclesFunctions_1 = require("./vehiclesFunctions");
exports.inicialiceLocTariff = {
    //postalCodes: [],
    postalCode: postalCodeFunctions_1.inicializePostalCode,
    locationType: commonEnums_1.locationType.none,
    origin: {
        address: commonFunctions_1.inicialiceAddress,
        name: ""
    },
    destiny: {
        address: commonFunctions_1.inicialiceAddress,
        name: ""
    },
    value: 0,
    extraTariffs: []
};
exports.inicialiceTariff = {
    porBultos: [],
    porPeso: [],
    porVolumen: [],
    porDistancia: [],
    porHoras: [],
    porPoblacion: [],
    porTrayecto: [],
    //porConcepto: [],
};
exports.inicializePaqueteriaTariff = {
    id: 0,
    description: "",
    //postalCode: inicializePostalCode,
    //locationType: "",
    tariffLocations: [],
    minImport: 0,
    fecAlta: new Date(),
    tariffs: [],
    multiplicateValue: false
};
exports.inicializeCargaCompletaTariff = {
    id: 0,
    description: "",
    vehicleType: vehiclesFunctions_1.initialVehicleType,
    auxImport: 0,
    minImport: 0,
    fecAlta: new Date(),
    tariffs: [],
    locTariffs: [],
    multiplicateValue: false
};
