import React, { Fragment, ReactElement } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { addingOrEditing } from "aseguisShared"

interface infoToInputText {
    colMesure: string
    editEnabled: number;
    addOrEdit: number;
    name: string,
    value: string,
    valueEdit: string,
    onChange: (number: NumberFormatValues) => void

    autoFocus?: boolean
    onFocus?: () => void
    onBlur?: () => void
    format?: string,
    mask?: string,
    NoBoldName?: boolean
    thousandSeparator?: string,
    decimalSeparator?: string,
    decimalScale?: number,
    fixedDecimalScale?: boolean,
    allowNegative?: boolean,
    autoComplete?: false
}
function InputNumberString(props: infoToInputText): ReactElement {

    return (
        <Fragment>

            <div className={`form-group col-md-${props.colMesure}`}>
                <div>
                    {props.NoBoldName ?
                        <label htmlFor="name"> {props.name} </label>
                        :
                        <label style={{ fontWeight: 'bold' }} htmlFor="name"> {props.name} </label>
                    }
                    {(props.editEnabled === addingOrEditing.edditing) &&
                        <NumberFormat
                            format={props.format && props.format}
                            mask={props.mask && props.mask}
                            onFocus={props.onFocus}
                            onBlur={props.onBlur}
                            autoComplete={props.autoComplete === false ? "off" : "on"}
                            autoFocus={(props.autoFocus && props.valueEdit === "") && true}
                            value={props.valueEdit}
                            id={props.name}
                            isNumericString={true}
                            className="form-control undefined"
                            onValueChange={props.onChange}
                            thousandSeparator={props.thousandSeparator}
                            decimalSeparator={props.decimalSeparator}
                            decimalScale={props.decimalScale}
                            fixedDecimalScale={props.fixedDecimalScale}
                            allowNegative={props.allowNegative}
                        />
                    }

                    {(props.editEnabled !== addingOrEditing.edditing && props.addOrEdit === addingOrEditing.edditing) &&
                        <input
                            name="numberString"
                            disabled={true}
                            type="text"
                            value={props.value}
                            className="form-control undefined"
                        />
                    }

                    {(props.editEnabled !== addingOrEditing.edditing && props.addOrEdit === addingOrEditing.adding) &&
                        <input
                            name="numberString"
                            disabled={true}
                            type="text"
                            value={props.valueEdit}
                            className="form-control undefined"
                        />
                    }
                </div>
            </div>

        </Fragment>

    );
};

export default InputNumberString;