import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../ApplicationState";
import {
    getFormattedDate, reviRepa, reviRepaTypes, addingOrEditing,
    tiposArticuladosVehiculos, formatearMatricula, urlParmsForm,
    validateFormEmpty, validateNumberFormEmpty
} from "aseguisShared";
import InputNumber from "../../common/Inputs/InputNumber";
import { saveFormReviRepa, addOrEditReviRepa, setFormsReviRepa, requestOneReviRepa } from "./ReviRepaStore";
import { useHistory, useParams } from "react-router-dom";
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import InputDate from "../../common/Inputs/InputDate";
import VehicleAutocomplete from "../Vehicles/VehicleAutocomplete";

interface infoToReviRepaForm {
    isEdditing: number;
    errorForm: boolean,
    setErrorForm: (value: boolean) => void,
    setEdditing: (value: addingOrEditing) => void,
}

const ReviRepaFormulario = (props: infoToReviRepaForm) => {

    const dispatch = useDispatch();

    const reviRepaForm: reviRepa = useSelector((state: ApplicationState) => state.reviRepa.reviRepaForm);
    const reviRepaEditForm: reviRepa = useSelector((state: ApplicationState) => state.reviRepa.reviRepaEditForm);
    const addingOrEditReviRepa: addingOrEditing = useSelector((state: ApplicationState) => state.reviRepa.addOrEditReviRepa) || addingOrEditing.none;

    const [errorVehicleForm, setErrorVehicleForm] = useState(false);

    useEffect(() => {
        if (
            (
                reviRepaEditForm.observaciones.length < 10
            ) ||
            (
                reviRepaEditForm.kms < reviRepaEditForm.vehicle.kms &&
                reviRepaEditForm.vehicle.vehicleType.tractoraRemolque !== tiposArticuladosVehiculos.remolque
            ) ||
            (
                (reviRepaEditForm.kmProxRevision <= reviRepaEditForm.kms) &&
                (reviRepaEditForm.typeReviRepa === reviRepaTypes.revision) &&
                (reviRepaEditForm.vehicle.vehicleType.tractoraRemolque !== tiposArticuladosVehiculos.remolque)
            ) ||
            (
                reviRepaEditForm.import < 1
            )
        ) {
            props.setErrorForm(true)
        } else {
            props.setErrorForm(errorVehicleForm)
        }
    }, [reviRepaEditForm, errorVehicleForm])

    //para tener siempre la ultima versión...
    let history = useHistory()
    let { id } = useParams<urlParmsForm>();
    useEffect(() => {
        if (id === "new") {
            dispatch(addOrEditReviRepa(addingOrEditing.adding));
            props.setEdditing(addingOrEditing.edditing)
        } else {
            requestOneReviRepa(id)
                .then((ReviRepa) => {
                    if (ReviRepa.data.reviRepaResults.length === 1) {
                        dispatch(addOrEditReviRepa(addingOrEditing.edditing));
                        dispatch(setFormsReviRepa(ReviRepa.data.reviRepaResults[0]))
                    } else {
                        history.push(allAppRoutes.vehiclesReviRepaList)
                    }
                })
                .catch((error) => {
                    history.push(allAppRoutes.vehiclesReviRepaList)
                })
        }
    }, [id]);
    //...para tener siempre la ultima versión

    return (
        <Fragment>

            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">
                        {(addingOrEditReviRepa !== addingOrEditing.adding) &&
                            <span> {"# " + reviRepaEditForm.id.toString()}</span>
                        }
                        {(addingOrEditReviRepa === addingOrEditing.adding) &&
                            <span> {" Añadir Revisión / Reparación"}</span>
                        }
                    </h6>
                </div>
                <div className="card-body">
                    <form>

                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="fecAlta">Fecha de alta</label>
                                    <div>{getFormattedDate(reviRepaEditForm.fecAlta)}</div>
                                </div>
                            </div>

                            <InputDate
                                colMesure="4"
                                editEnabled={props.isEdditing}
                                addOrEdit={addingOrEditReviRepa}
                                name="Fecha revisión"
                                valueEdit={reviRepaEditForm.fecRevision}
                                value={reviRepaForm.fecRevision}
                                onChange={(date) => {
                                    dispatch(
                                        saveFormReviRepa({
                                            ...reviRepaEditForm,
                                            fecRevision: date
                                        })
                                    );
                                }}
                            />

                            {(props.isEdditing !== addingOrEditing.edditing) &&
                                <div className="form-group col-md-4">
                                    <div>
                                        <label style={{ fontWeight: 'bold' }} htmlFor="fecAlta">Insertado por</label>
                                        <input
                                            name="reviRepaInsertBy"
                                            disabled={true}
                                            type="text"
                                            value={reviRepaEditForm.user.name + " " + reviRepaEditForm.user.apellido1 + " " + reviRepaEditForm.user.apellido2 + " "}
                                            className="form-control undefined"
                                        />
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-4">
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="name">Vehículo o Remolque</label>
                                    {(props.isEdditing === addingOrEditing.edditing) ? // && isAppLoading == false) ?
                                        <VehicleAutocomplete
                                            vehicleName={reviRepaEditForm.vehicle.matricula}
                                            setVehicle={(vehicle) => {
                                                dispatch(
                                                    saveFormReviRepa({
                                                        ...reviRepaEditForm,
                                                        vehicle: vehicle,
                                                        fecAlta: addingOrEditReviRepa === addingOrEditing.adding ? new Date() : reviRepaEditForm.fecAlta
                                                    })
                                                );
                                            }}
                                            setErrorForm={setErrorVehicleForm}
                                            whatShow={"all"}
                                        />
                                        :
                                        <input
                                            name="vehConRemolque"
                                            disabled={true}
                                            type="text"
                                            value={formatearMatricula(reviRepaForm.vehicle.matricula)}
                                            className="form-control undefined"
                                        />
                                    }
                                </div>
                            </div>

                            {(
                                (props.isEdditing === addingOrEditing.edditing && reviRepaEditForm.vehicle.matricula !== "") ||
                                (props.isEdditing !== addingOrEditing.edditing && reviRepaForm.vehicle.matricula !== "")
                            ) &&
                                <div className="form-group col-md-4">
                                    <div>
                                        <label style={{ fontWeight: 'bold' }} htmlFor="name">Tipo</label>
                                        {(props.isEdditing === addingOrEditing.edditing) ?
                                            <select
                                                value={reviRepaEditForm.typeReviRepa}
                                                className="form-control undefined"
                                                id="tipo"
                                                onChange={(value) => {
                                                    dispatch(
                                                        saveFormReviRepa({
                                                            ...reviRepaEditForm,
                                                            typeReviRepa: value.target.value
                                                        })
                                                    );
                                                }}>
                                                <option value={reviRepaTypes.reparacion}>{reviRepaTypes.reparacion}</option>
                                                <option value={reviRepaTypes.revision}>{reviRepaTypes.revision}</option>
                                            </select>
                                            :
                                            <input
                                                name="reviRepaTipo"
                                                disabled={true}
                                                type="text"
                                                value={reviRepaForm.typeReviRepa}
                                                className="form-control undefined"
                                            />
                                        }

                                    </div>

                                </div>
                            }

                        </div>

                        {(
                            (props.isEdditing === addingOrEditing.edditing && reviRepaEditForm.vehicle.matricula !== "") ||
                            (props.isEdditing !== addingOrEditing.edditing && reviRepaForm.vehicle.matricula !== "")
                        ) &&
                            <div>

                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <div>
                                            <label style={{ fontWeight: 'bold' }} htmlFor="name">Tareas realizadas</label>
                                            {(props.isEdditing === addingOrEditing.edditing /*&& isAppLoading === false*/) ?
                                                <textarea
                                                    value={reviRepaEditForm.observaciones}
                                                    rows={5}
                                                    onChange={(event) => {
                                                        dispatch(
                                                            saveFormReviRepa({
                                                                ...reviRepaEditForm,
                                                                observaciones: event.target.value.trimStart()
                                                            })
                                                        );
                                                    }}
                                                    className={validateFormEmpty(reviRepaEditForm.observaciones, 10)}
                                                    id="observaciones" />
                                                :
                                                <textarea
                                                    readOnly={true}
                                                    rows={5}
                                                    value={reviRepaForm.observaciones}
                                                    className="form-control undefined"
                                                    id="observaciones" />
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="form-row">

                                    {(reviRepaEditForm.vehicle.vehicleType.tractoraRemolque !== tiposArticuladosVehiculos.remolque) &&
                                        <InputNumber
                                            colMesure="3"
                                            editEnabled={props.isEdditing}
                                            addOrEdit={addingOrEditReviRepa}
                                            name="Km actuales"
                                            valueEdit={reviRepaEditForm.kms}
                                            value={reviRepaForm.kms}
                                            onChange={(value) => {
                                                dispatch(
                                                    saveFormReviRepa({
                                                        ...reviRepaEditForm,
                                                        kms: value.floatValue ? value.floatValue : 0
                                                    })
                                                );
                                            }}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={0}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            withoutformRow={true}
                                            className={validateNumberFormEmpty(reviRepaEditForm.kms, reviRepaEditForm.vehicle.kms)}
                                        />
                                    }

                                    {(
                                        (props.isEdditing === addingOrEditing.edditing && reviRepaEditForm.typeReviRepa === reviRepaTypes.revision) ||
                                        (props.isEdditing !== addingOrEditing.edditing && reviRepaForm.typeReviRepa === reviRepaTypes.revision)
                                    ) &&
                                        (
                                            (reviRepaEditForm.vehicle.vehicleType.tractoraRemolque !== tiposArticuladosVehiculos.remolque)
                                        ) &&
                                        <InputNumber
                                            colMesure="3"
                                            editEnabled={props.isEdditing}
                                            addOrEdit={addingOrEditReviRepa}
                                            name="Km próxima revisión"
                                            valueEdit={reviRepaEditForm.kmProxRevision}
                                            value={reviRepaForm.kmProxRevision}
                                            onChange={(value) => {
                                                dispatch(
                                                    saveFormReviRepa({
                                                        ...reviRepaEditForm,
                                                        kmProxRevision: value.floatValue ? value.floatValue : 0
                                                    })
                                                );
                                            }}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={0}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            withoutformRow={true}
                                            className={validateNumberFormEmpty(reviRepaEditForm.kmProxRevision, reviRepaEditForm.kms + 1)}
                                        />
                                    }

                                    {(
                                        (props.isEdditing === addingOrEditing.edditing && reviRepaEditForm.typeReviRepa === reviRepaTypes.revision) ||
                                        (props.isEdditing !== addingOrEditing.edditing && reviRepaForm.typeReviRepa === reviRepaTypes.revision)
                                    ) &&
                                        <InputDate
                                            colMesure="3"
                                            editEnabled={props.isEdditing}
                                            addOrEdit={addingOrEditReviRepa}
                                            name="Fecha próxima revisión"
                                            valueEdit={reviRepaEditForm.fecProxRevision}
                                            value={reviRepaForm.fecProxRevision}
                                            onChange={(date) => {
                                                dispatch(
                                                    saveFormReviRepa({
                                                        ...reviRepaEditForm,
                                                        fecProxRevision: date
                                                    })
                                                );
                                            }}
                                        />
                                    }

                                    <InputNumber
                                        colMesure="3"
                                        editEnabled={props.isEdditing}
                                        addOrEdit={addingOrEditReviRepa}
                                        name="Importe (sin IVA)"
                                        valueEdit={reviRepaEditForm.import}
                                        value={reviRepaForm.import}
                                        onChange={(value) => {
                                            dispatch(
                                                saveFormReviRepa({
                                                    ...reviRepaEditForm,
                                                    import: value.floatValue ? value.floatValue : 0
                                                })
                                            );
                                        }}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        withoutformRow={true}
                                        suffix={" \u20AC"}
                                        className={validateNumberFormEmpty(reviRepaEditForm.import)}
                                    />
                                </div>

                            </div>
                        }

                    </form>
                </div>
            </div>

        </Fragment>

    );
};

export default ReviRepaFormulario;
