import { Action, Dispatch } from 'redux';
import { ApplicationState } from '../../ApplicationState';
import axios from 'axios';
import { loading, parametrizacion, initialParametrizacion } from "aseguisShared";
import { Notify } from '../../common/Toastify/Notify';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface parametrizacionState {
    parametrizacionForm: parametrizacion,
    parametrizacionEditForm: parametrizacion,
    isloadingParametrizacion: number,
}

export const initialStateParametrizacion: parametrizacionState = {
    parametrizacionForm: initialParametrizacion,
    parametrizacionEditForm: initialParametrizacion,
    isloadingParametrizacion: loading.pending
};


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface saveParametrizacionRequestAction {
    type: 'SAVE_PARAMETRIZACION_REQUEST'
}

export interface saveParametrizacionReceivedAction {
    type: 'SAVE_PARAMETRIZACION_RECEIVED',
    parametrizacion: parametrizacion
}

export interface RequestParametrizacionAction {
    type: 'REQUEST_PARAMETRIZACION'
}

export interface ReceivedParametrizacionAction {
    type: 'RECEIVED_PARAMETRIZACION',
    parametrizacion: parametrizacion
}

export interface saveParametrizacionEditFormAction {
    type: 'SAVE_PARAMETRIZACION_EDIT_FORM',
    parametrizacion: parametrizacion
}

export interface saveParametrizacionFormsAction {
    type: 'SAVE_PARAMETRIZACION_FORMS',
    parametrizacion: parametrizacion
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = saveParametrizacionRequestAction | saveParametrizacionReceivedAction | RequestParametrizacionAction |
    ReceivedParametrizacionAction | saveParametrizacionEditFormAction | saveParametrizacionFormsAction;


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export function saveParamEditForm(parametrizacion: parametrizacion): saveParametrizacionEditFormAction {
    return {
        type: 'SAVE_PARAMETRIZACION_EDIT_FORM',
        parametrizacion: parametrizacion
    };
}

export function saveParametrizacionForms(parametrizacion: parametrizacion): saveParametrizacionFormsAction {
    return {
        type: 'SAVE_PARAMETRIZACION_FORMS',
        parametrizacion: parametrizacion
    };
}


/**************************************************************
 **************************************************************
 *
 * @param PARAMETRIZACION_OPERATIONS
 *
 * ************************************************************
 **************************************************************/

export function saveParametrizacion(parametrizacion: parametrizacion) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: 'SAVE_PARAMETRIZACION_REQUEST' });
        return axios.put('api/parametrizacion/', parametrizacion)
            .then((data) => {
                Notify("Ajustes modificados correctamente", "success")
                dispatch({ type: 'SAVE_PARAMETRIZACION_RECEIVED', parametrizacion: data.data });
            })
            .catch((error) => {
                /*if (error.response.status) {
                    if (error.response.status === 401) {
                        dispatch({ type: 'SAVE_PARAMETRIZACION_RECEIVED', parametrizacion: parametrizacion });
                    }
                }*/
            })
    }
}

export function requestParametrizacion() {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        dispatch({ type: 'REQUEST_PARAMETRIZACION' });
        return axios.get('api/parametrizacion')
            .then((data) => {
                dispatch({ type: 'RECEIVED_PARAMETRIZACION', parametrizacion: data.data });
            })
            .catch((error) => {
            })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
function parametrizacionReducer(state: parametrizacionState = initialStateParametrizacion, incomingAction: Action): parametrizacionState {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SAVE_PARAMETRIZACION_REQUEST': {
            return {
                ...state,
                isloadingParametrizacion: loading.pending
            };
        }
        case 'SAVE_PARAMETRIZACION_RECEIVED': {
            return {
                ...state,
                isloadingParametrizacion: loading.done,
                parametrizacionForm: action.parametrizacion,
                parametrizacionEditForm: action.parametrizacion
            };
        }
        case 'REQUEST_PARAMETRIZACION': {
            return {
                ...state,
                isloadingParametrizacion: loading.pending
            };
        }
        case 'RECEIVED_PARAMETRIZACION': {
            localStorage.setItem('firstColor', action.parametrizacion.primaryColor);
            localStorage.setItem('secondColor', action.parametrizacion.secondColor);
            return {
                ...state,
                isloadingParametrizacion: loading.done,
                parametrizacionForm: action.parametrizacion,
                parametrizacionEditForm: action.parametrizacion
            };
        }
        case 'SAVE_PARAMETRIZACION_EDIT_FORM': {
            return {
                ...state,
                parametrizacionEditForm: action.parametrizacion
            };
        }
        case 'SAVE_PARAMETRIZACION_FORMS': {
            return {
                ...state,
                parametrizacionEditForm: action.parametrizacion,
                parametrizacionForm: action.parametrizacion
            };
        }
        default:
            return state;
    }
};

export default parametrizacionReducer;