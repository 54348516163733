import { Action, Dispatch } from 'redux';
import { ApplicationState } from '../../ApplicationState';
import axios from 'axios';
import {
    clientValidateFieldsResult, client, inicialiceClient, inicialiceClientValidateFieldsResult,
    inicialiceSearchFilterList, loading, addingOrEditing, searchFilterList, searchedClients
} from 'aseguisShared';
import { Notify } from '../../common/Toastify/Notify';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface clientsState {
    clients: client[],
    addOrEditClients: number,
    clientForm: client,
    clientEditForm: client,
    isloadingClients: number,
    actualPage: number,
    totalPages: number,
    searchFilterClients: searchFilterList,
    totalClientsList: number,
    validateFieldsForm: clientValidateFieldsResult
}

export const initialState: clientsState = {
    clients: [],
    clientForm: inicialiceClient,
    clientEditForm: inicialiceClient,
    addOrEditClients: addingOrEditing.none,
    isloadingClients: loading.pending,
    actualPage: 0,
    totalPages: 0,
    searchFilterClients: inicialiceSearchFilterList,
    totalClientsList: 0,
    validateFieldsForm: inicialiceClientValidateFieldsResult
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface addEditClientAction {
    type: 'CHANGE_ADD_EDIT_CLIENT',
    addOrEdit: number
}

export interface saveEditFormClientAction {
    type: 'SAVE_EDIT_FORM_CLIENT',
    client: client
}

export interface addClientReceivedAction {
    type: 'ADD_CLIENT_RECEIVED',
    client: client
}

export interface saveEditClientReceivedAction {
    type: 'SAVE_EDIT_CLIENT_RECEIVED',
    client: client
}

export interface RequestClientsAction {
    type: 'REQUEST_CLIENTS'
}

export interface ReceivedClientsAction {
    type: 'RECEIVED_CLIENTS',
    clients: searchedClients
}

export interface saveSearchClientTextAction {
    type: 'SAVE_SEARCH_FILTER_CLIENT',
    searchFilterClients: searchFilterList
}

export interface setBothFormsClientAction {
    type: 'SET_BOTH_FORMS_CLIENT',
    client: client
}

export interface setValidateFormClientAction {
    type: 'SET_VALIDATE_FORM_CLIENT',
    form: clientValidateFieldsResult
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestClientsAction | addEditClientAction | ReceivedClientsAction |
    addClientReceivedAction | saveEditFormClientAction | saveEditClientReceivedAction |
    saveSearchClientTextAction | setBothFormsClientAction | setValidateFormClientAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export function setFormsClient(client: client): setBothFormsClientAction {
    return {
        type: 'SET_BOTH_FORMS_CLIENT',
        client: client
    };
}

export function addOrEditClient(addOrEdit: number): addEditClientAction {
    return {
        type: 'CHANGE_ADD_EDIT_CLIENT',
        addOrEdit: addOrEdit
    };
}

export function saveClientEditForm(client: client): saveEditFormClientAction {
    return {
        type: 'SAVE_EDIT_FORM_CLIENT',
        client: client
    };
}

export function saveSearchFilterClient(searchClientsFilter: searchFilterList): saveSearchClientTextAction {
    return {
        type: 'SAVE_SEARCH_FILTER_CLIENT',
        searchFilterClients: searchClientsFilter
    };
}

export function setValidateClientForm(form: clientValidateFieldsResult): setValidateFormClientAction {
    return {
        type: 'SET_VALIDATE_FORM_CLIENT',
        form: form
    };
}

/**************************************************************
 **************************************************************
 *
 * @param CLIENTS_OPERATIONS
 *
 * ************************************************************
 **************************************************************/

export function addClient(client: client) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        return axios.post('api/clients', client)
            .then((data) => {
                const state = getState()
                const clientEditForm = state.clients.clientEditForm
                if (clientEditForm.permisosUsuario.accessAppMvEnabled || clientEditForm.permisosUsuario.accessEnabled) {
                    Notify("Cliente creado. Se ha enviado un email al destinatario con las instrucciones para iniciar sesión", "success")
                } else {
                    Notify("Cliente creado", "success")
                }
                dispatch({ type: 'ADD_CLIENT_RECEIVED', client: data.data });
            })
            .catch((error) => {
            })
    }
}

export function editClient(client: client) {
    return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
        return axios.put('api/clients/', client)
            .then((data) => {
                const state = getState();
                const clientEditForm = state.clients.clientEditForm
                const clientForm = state.clients.clientForm
                const accessPermissions = clientEditForm.permisosUsuario.accessAppMvEnabled || clientEditForm.permisosUsuario.accessEnabled;
                const prevAccessPermissions = !clientForm.permisosUsuario.accessAppMvEnabled && !clientForm.permisosUsuario.accessEnabled;
                if (accessPermissions && prevAccessPermissions) {
                    Notify("Cliente modificado. Se ha enviado un email al destinatario con las instrucciones para iniciar sesión", "success");
                } else {
                    Notify("Cliente modificado", "success")
                }
                dispatch({ type: 'SAVE_EDIT_CLIENT_RECEIVED', client: data.data });
            })
            .catch((error) => {
            })
    }
}

export function requestAllClients(searchClientsFilter: searchFilterList) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        return axios.post<searchedClients>('api/clients/searchFilter/', searchClientsFilter)
            .then((data) => {
                dispatch({ type: 'RECEIVED_CLIENTS', clients: data.data });
            })
            .catch((error) => {
            })
    }
}

export const requestOneClient = async (idCli: string) => {
    let searchFilter = { ...inicialiceSearchFilterList, idMongo: idCli }
    return await axios.post<searchedClients>('api/clients/searchFilter/', searchFilter)
}

export function validateIfExistsFields(client: client) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        return axios.post('api/clients/validateExists/', client)
            .then((data) => {
                return dispatch({ type: 'SET_VALIDATE_FORM_CLIENT', form: data.data });
            })
            .catch((error) => {
            })
    }
}

export function exportClientsToExcel(searchFilter: searchFilterList) {
    return async (dispatch: Dispatch) => {
        return axios({ method: 'post', responseType: 'blob', url: 'api/clients/createExcel/getList/', data: searchFilter })
            .then((data) => {
                const blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Aseguis_clientes.xlsx";
                link.click();
            })
            .catch((error) => {
            })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
function clientsReducer(state: clientsState = initialState, incomingAction: Action): clientsState {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_BOTH_FORMS_CLIENT': {
            return {
                ...state,
                clientForm: action.client,
                clientEditForm: action.client,
                validateFieldsForm: inicialiceClientValidateFieldsResult
            }
        }
        case 'CHANGE_ADD_EDIT_CLIENT': {
            return {
                ...state,
                addOrEditClients: action.addOrEdit
            }
        }
        case 'SAVE_EDIT_FORM_CLIENT': {
            return {
                ...state,
                clientEditForm: action.client
            };
        }
        case 'ADD_CLIENT_RECEIVED': {
            return {
                ...state,
                clientForm: action.client,
                clientEditForm: action.client,
                addOrEditClients: addingOrEditing.edditing
            };
        }
        case 'SAVE_EDIT_CLIENT_RECEIVED': {
            return {
                ...state,
                clientForm: action.client,
                clientEditForm: action.client
            };
        }
        case 'REQUEST_CLIENTS': {
            return {
                ...state,
                isloadingClients: loading.pending
            };
        }
        case 'RECEIVED_CLIENTS': {
            return {
                ...state,
                isloadingClients: loading.done,
                clients: action.clients.clientsResults,
                totalClientsList: action.clients.totalResults,
                actualPage: action.clients.numPage,
                totalPages: action.clients.totalPages
            };
        }
        case 'SAVE_SEARCH_FILTER_CLIENT': {
            return {
                ...state,
                searchFilterClients: action.searchFilterClients,
                isloadingClients: loading.pending
            };
        }
        case 'SET_VALIDATE_FORM_CLIENT': {
            return {
                ...state,
                validateFieldsForm: action.form
            };
        }
        default:
            return state;
    }
};

export default clientsReducer;