import React, { Fragment, ReactElement } from "react";
import NumberFormat from "react-number-format";
import { useSelector, } from "react-redux";
import { cargaCompleta, addingOrEditing, getFormattedDateShort } from "aseguisShared";
import { ApplicationState } from "../../ApplicationState";

interface infoToCargaCompletaHistoryList {
    isEdditing: number;
    addOrEdit: number;
    cargaCompletaForm: cargaCompleta;
    cargaCompletaEditForm: cargaCompleta;
}

function CargaCompletaHistoryList(props: infoToCargaCompletaHistoryList): ReactElement {

    const addedHistory: cargaCompleta[] = useSelector((state: ApplicationState) => state.cargaCompleta.historyAddList);

    const listEditCargas: JSX.Element[] = addedHistory.map((carga, index) => {
        var generalConcept = "";
        for (var lineaViaje of carga.lineasViaje) {
            generalConcept = generalConcept + lineaViaje.concepto
            if (lineaViaje.id !== carga.lineasViaje[carga.lineasViaje.length - 1].id) {
                generalConcept = generalConcept + ", "
            }
        }
        return (
            <tr className={`table-row ${(props.cargaCompletaEditForm.id === carga.id) ? "selected" : ""}`}
                key={index}>
                <td onClick={() => { }}>
                    {carga.id}
                </td>
                <td onClick={() => { }}>
                    {carga.datosGenerales.client.nombre}
                </td>
                <td style={{ whiteSpace: "nowrap" }} onClick={() => { }}>
                    {getFormattedDateShort(carga.datosGenerales.recogida)}
                </td>
                <td style={{ whiteSpace: "nowrap" }} onClick={() => { }}>
                    {getFormattedDateShort(carga.datosGenerales.entrega)}
                </td>
                <td onClick={() => { }}>
                    {generalConcept}
                </td>
                <td onClick={() => { }}>
                    {carga.datosGenerales.vehicle.matricula + " - " + carga.datosGenerales.vehicle.marca + " " + carga.datosGenerales.vehicle.modelo}
                </td>
                <td onClick={() => { }}>
                    {carga.datosGenerales.typeVehicle.description}
                </td>
                <td onClick={() => { }}>
                    {carga.datosGenerales.driver.name.length > 0 ? (carga.datosGenerales.driver.name + " " + carga.datosGenerales.driver.apellido1 + " " + carga.datosGenerales.driver.apellido2) : "-"}
                </td>
                <td onClick={() => { }}>
                    {carga.datosGenerales.referenciaString}
                </td>
                <td onClick={() => { }}>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={true}
                        value={carga.datosEconomicos.subTotal}
                        className="form-control undefined"
                        renderText={value => <div>{value}</div>}
                        suffix={" \u20AC"} />
                </td>
            </tr>
        );
    });

    return (
        <Fragment>
            <div className="table-responsive table-hover portlet-600" key={props.isEdditing}>
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Cliente</th>
                            <th scope="col" style={{ whiteSpace: "nowrap" }}>Fec albarán</th>
                            <th scope="col" style={{ whiteSpace: "nowrap" }}>Entr prevista</th>
                            <th scope="col">Concepto/s</th>
                            <th scope="col">Vehículo</th>
                            <th scope="col">Tipo vehículo</th>
                            <th scope="col">Conductor</th>
                            <th scope="col">Referencia/s</th>
                            <th scope="col">Importe</th>
                        </tr>
                    </thead>
                    {(props.addOrEdit === addingOrEditing.adding) &&
                        <tbody>
                            {listEditCargas}
                        </tbody>
                    }
                </table>
            </div>
        </Fragment>
    );

};

export default CargaCompletaHistoryList;
