import React, { Fragment, ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from '../../../ApplicationState';
import {addingOrEditing, controlVehicleTypes, initialControlVehicleType } from "aseguisShared";
import ParamVehiclesControlsTypesList from "./VehiclesParamControlsTypesList";

interface infoToParamVehiclesControlsTypes {
    isEdditing: number;
    allControlTypes: controlVehicleTypes[];

    addControlType: (controlType: controlVehicleTypes) => void
    saveExistingControlType: (formControlType: controlVehicleTypes) => void
    deleteControlType: (formControlType: controlVehicleTypes) => void

    errorForm: boolean,
    setErrorForm: (value: boolean) => void,
}

function ParamVehiclesControlsTypes(props: infoToParamVehiclesControlsTypes): ReactElement {

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);

    const [formControlType, setFormControlType] = React.useState<controlVehicleTypes>(initialControlVehicleType);
    const [formState, setFormState] = React.useState(addingOrEditing.none);

    useEffect(() => {
        if (
            ( (formState !== addingOrEditing.none) && (props.isEdditing !== addingOrEditing.none) ) &&
            (
                (formControlType.description.length < 2)
            )
        ) {
            props.setErrorForm(true)
        } else {
            props.setErrorForm(false)
        }
    }, [formControlType.description.length])

    useEffect(() => {
        //para cuando se hace click en cancelar       
        if (props.isEdditing === addingOrEditing.none) {
            setFormControlType(initialControlVehicleType)
            setFormState(addingOrEditing.none)
        }
    })

    return (
        <Fragment>
         
                <div className="card shadow mb-4">

                    <div className="card-header">
                        <h6 className="m-0 font-weight-bold text-green">Tipos de controles</h6>

                        {isAppLoading === true &&
                            <div className="header-buttons">
                                <span className="spinner-border spinner-border-sm mr-4"></span>
                            </div>
                        }

                        {((isAppLoading === false)) &&

                            <div>
                                {(formState === addingOrEditing.none) && (props.isEdditing === addingOrEditing.edditing) &&
                                    <div className="header-buttons">

                                        {/* AÑADIR BOTON*/}
                                    <button className="btn btn-success btn-green btn-sm"
                                            onClick={() => {
                                                setFormState(addingOrEditing.adding)

                                                var maxId = 0
                                                for (var controlType of props.allControlTypes) {
                                                    if (controlType.id > maxId) {
                                                        maxId = controlType.id
                                                    }
                                                }
                                                maxId = maxId + 1
                                                setFormControlType({
                                                    ...initialControlVehicleType,
                                                    id: maxId
                                                })
                                            }}>
                                            <i className="fas fa fa-plus"></i>
                                        </button>

                                        {/*edit button*/}
                                        {formControlType.description !== "" &&
                                            <button className="btn btn-success btn-blue btn-sm"
                                                onClick={() => {
                                                    setFormState(addingOrEditing.edditing)
                                                }}>
                                                <i className="fas fa fa-pen"></i>
                                            </button>
                                        }

                                        {/*delete button*/}
                                        {formControlType.description !== "" &&
                                            <button className="btn btn-success btn-red btn-sm"
                                                onClick={() => {
                                                    props.deleteControlType(formControlType)
                                                }}>
                                                <i className="fas fa fa-times"></i>
                                            </button>
                                        }
                                    </div>

                                }
                            </div>

                        }

                    {(props.isEdditing === addingOrEditing.edditing) &&
                        <div className="header-buttons">

                            {/* ESTADO AÑADIR */}
                            {formState === addingOrEditing.adding &&
                                <button className={props.errorForm === false ? "btn btn-success left-margin btn-sm" : "btn btn-success btn-sm left-margin disabled"}
                                    onClick={() => {
                                        if (props.errorForm === false) {
                                            setFormState(addingOrEditing.none)
                                            props.addControlType(formControlType)
                                            setFormControlType(initialControlVehicleType)
                                        }
                                    }}>
                                    Añadir control
                                    </button>
                            }

                            {/* ESTADO EDITAR */}
                            {formState === addingOrEditing.edditing &&
                                <button className={props.errorForm === false ? "btn btn-success btn-sm left-margin" : "btn btn-success btn-sm left-margin disabled"}
                                    onClick={() => {
                                        if (props.errorForm === false) {
                                            setFormState(addingOrEditing.none)
                                            props.saveExistingControlType(formControlType)
                                            setFormControlType(initialControlVehicleType)
                                        }
                                    }}>
                                    Guardar control
                                    </button>
                            }

                            {/* ESTADO AÑADIR O EDITAR */}
                            {(formState === addingOrEditing.adding || formState === addingOrEditing.edditing) &&
                                <button className="btn btn-danger left-margin btn-sm"
                                    onClick={() => {
                                        setFormControlType(initialControlVehicleType)
                                        setFormState(addingOrEditing.none)
                                    }}>
                                    Cancelar
                                    </button>
                            }

                        </div>
                    }

                    </div>

                    <ParamVehiclesControlsTypesList
                        allControlsTypes={props.allControlTypes}
                        formControlType={formControlType}
                        setFormControlType={(controlType) => {
                            setFormControlType(controlType)
                        }}
                        formState={formState}
                        isEdditing={props.isEdditing}
                    />

                </div>        

        </Fragment>

    );
};

export default ParamVehiclesControlsTypes;
