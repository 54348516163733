import React from 'react';

interface CMRImageProps {
    imageCmr: any,
    indexLinea: number;
    indexDestination: number;
    onCopyCMRInfo: (indexLineaOrigen: number, indexDestiOrigen: number, indexLineaDestino: number, indexDestiDestino: number) => void;
    onClick: () => void;
}

const ImageCmrDrag: React.FC<CMRImageProps> = ({imageCmr, indexLinea, indexDestination, onCopyCMRInfo, onClick}) => {

    const handleDragStart = (event: React.DragEvent<HTMLImageElement>) => {
        event.dataTransfer.setData("application/json", JSON.stringify({indexLinea, indexDestination}));
        document.body.style.cursor = "copy"; // Cambia el cursor a 'copy'
    };

    const handleDragOver = (event: React.DragEvent<HTMLImageElement>) => {
        event.preventDefault();
        event.currentTarget.style.border = "2px dashed #000"; // Efecto visual para indicar el target
    };

    const handleDrop = (event: React.DragEvent<HTMLImageElement>) => {
        event.preventDefault();
        
        const draggedCMRInfo = JSON.parse(event.dataTransfer.getData("application/json"));
        onCopyCMRInfo(draggedCMRInfo.indexLinea, draggedCMRInfo.indexDestination, indexLinea, indexDestination);
        
        document.body.style.cursor = "default"; // Restablece el cursor
        event.currentTarget.style.border = "none"; // Restablece el estilo del target
    };

    const handleDragEnd = (event: React.DragEvent<HTMLImageElement>) => {
        document.body.style.cursor = "default"; // Asegura que el cursor se restablezca si el arrastre termina sin soltar
    };

    const handleDragLeave = (event: React.DragEvent<HTMLImageElement>) => {
        event.currentTarget.style.border = "none"; // Restablece el estilo si el arrastrable deja el target sin soltar
    };

    return (

        <img
            src={imageCmr}
            style={{
                width: '32px',
                height: '40px',
                marginRight: '10px',
                cursor: "pointer"
            }}
            alt="Icono"
            onClick={(event) => {
                onClick()
            }}
            draggable="true"
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragEnd={handleDragEnd}
            onDragLeave={handleDragLeave}
        />
        
    );
};

export default ImageCmrDrag;

