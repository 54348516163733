import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { loginUsers, usersTypes, suscriptionData } from "aseguisShared"
import { ButtonEditarPrivate } from "./ButtonEditarPrivate";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../ApplicationState";
import { GetSuscriptionDataFromLocalStorage } from "../commonInterfaces/commonFunctions";

export interface infoToButtonAdd {
    addCallback?: () => void
}

function AddButtonList(props: infoToButtonAdd): ReactElement {

    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const isModifyEnabled = ButtonEditarPrivate().isModifyEnabled();
    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    return (
        <Fragment>
            {(props.addCallback && isModifyEnabled && userLogged.userType === usersTypes.employee && suscriptionData.isSuscriptionActive) &&
                <button
                    className="btn btn-success btn-sm btn-green left-margin"
                    onClick={props.addCallback}
                >
                    <i className="fas fa fa-plus"></i>
                </button>
            }
        </Fragment>
    );
};

export default AddButtonList;
