import axios from "axios";
import { useEffect, useState } from "react";
import { postalCode } from "aseguisShared";

interface postalCodeRequest {
    strings: string[]
    country: string;
}
const inicialiceRequest: postalCodeRequest = {
    strings: [],
    country: ""
};

export const PostalCodeApi = () => {

    const [cpToSearch, setCpToSearch] = useState<postalCodeRequest>(inicialiceRequest);
    const [postalCodesGetted, setPostalCodesGetted] = useState<postalCode[]>([]);
    const [isLoadingCPs, setIsLoadingCPs] = useState(false);

    const getPostalCode = async () => {
        setPostalCodesGetted([])
        if (cpToSearch.strings.length > 0) {
            setIsLoadingCPs(true)
            await axios.post<postalCode[]>('api/localities/getPostalCodes/', cpToSearch)
                .then((data) => {
                    setPostalCodesGetted(data.data)
                })
                .catch((error) => {
                })
            setIsLoadingCPs(false)
        }
    }

    const getPostalCodeManually = async (postalCodeRequest: postalCodeRequest): Promise<postalCode[]> => {
        if (postalCodeRequest.strings.length > 0) {
            try {
                setIsLoadingCPs(true);
                const response = await axios.post<postalCode[]>('api/localities/getPostalCodes/', postalCodeRequest);
                setIsLoadingCPs(false);
                return response.data as postalCode[];
            } catch (error) {
                setIsLoadingCPs(false);
                console.error("Error fetching postal codes:", error);
                return [];
            }
        } else {
            return [];
        }
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            getPostalCode()
        }, 500);
        return () => { clearTimeout(timeout) }
    }, [cpToSearch])

    return {
        isLoadingCPs,

        cpToSearch,
        setCpToSearch,

        setPostalCodesGetted,
        postalCodesGetted,

        getPostalCodeManually
    }
}
