import { useDispatch, useSelector } from "react-redux";
import React, { Fragment } from "react";
import { ApplicationState } from '../../ApplicationState';
import SuppliersList from "./SuppliersList";
import SupplierForm from "./SuppliersForm";
import {
    initialState, editSupplier, saveSupplierEditForm, deleteSupplier,
    addSupplier, addOrEditSupplier, setFormsSupplier, saveSearchFilterSupplier
} from './SuppliersStore';
import { GetUserPermissionsFromLocalStorage } from "../../common/commonInterfaces/commonFunctions";
import { addingOrEditing, UserPermissions, supplier, searchFilterList, inicialicePermisosUsuario } from "aseguisShared";
import Buttons from "../../common/Buttons/Buttons";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";

const Suppliers: React.FC = () => {

    const dispatch: React.Dispatch<any> = useDispatch();
    let { permissions } = GetUserPermissionsFromLocalStorage()

    const supplierForm: supplier = useSelector((state: ApplicationState) => state.suppliers.supplierForm);
    const supplierEditForm: supplier = useSelector((state: ApplicationState) => state.suppliers.supplierEditForm);
    const addingOrEdit: addingOrEditing = useSelector((state: ApplicationState) => state.suppliers.addOrEditSuppliers) || addingOrEditing.none;
    const searchFilterSuppliers: searchFilterList = useSelector((state: ApplicationState) => state.suppliers.searchFilterSuppliers);

    const [editEnabled, setEditEnabled] = React.useState(addingOrEditing.none);
    const [errorOnForm, setErrorOnForm] = React.useState(false);

    function editUser(supplier: supplier): void {
        setEditEnabled(addingOrEditing.none);
        dispatch(setFormsSupplier(supplier));
        dispatch(addOrEditSupplier(addingOrEditing.edditing));
    }

    function addsupplierForm(): void {
        setEditEnabled(addingOrEditing.edditing);
        dispatch(setFormsSupplier(initialState.supplierForm));
        dispatch(addOrEditSupplier(addingOrEditing.adding));
    }

    function addNewSupplier(): void {
        if (errorOnForm === false) {
            setEditEnabled(addingOrEditing.none);
            dispatch(addSupplier(supplierEditForm));
        }
    }

    function saveEditedSupplier(): void {
        if (errorOnForm === false) {
            setEditEnabled(addingOrEditing.none);
            if (supplierEditForm.email.length === 0) {
                dispatch(editSupplier({
                    ...supplierEditForm,
                    permisosUsuario: {
                        ...inicialicePermisosUsuario,
                        idAssociated: supplierEditForm.permisosUsuario.idAssociated
                    }
                }));
            } else {
                dispatch(editSupplier(supplierEditForm));
            }            
        }
    }

    function restoreSupplierEdit(): void {
        dispatch(saveSupplierEditForm(supplierForm));
    }

    const usePathname = useLocation().pathname;
    const usePath = useHistory()

    return (

        <Fragment>

            <Switch>
                {(permissions.proveedores !== UserPermissions.deshabilitado) &&
                    <Route exact path={allAppRoutes.suppliersList}>
                        <SuppliersList
                            onClick={(supplier) => {
                                editUser(supplier)
                                usePath.push(allAppRoutes.suppliersForm + supplier._id);
                            }}
                            addSupplier={() => {
                                addsupplierForm()
                                usePath.push(allAppRoutes.suppliersForm + "new");
                            }}
                            searchSuppliersFilter={searchFilterSuppliers}
                            saveSearchSuppliersFilter={(searchFilterClients) => {
                                dispatch(saveSearchFilterSupplier(searchFilterClients));
                            }}
                        />
                    </Route>
                }
                {permissions.proveedores !== UserPermissions.deshabilitado &&
                    <Route exact path={allAppRoutes.suppliersForm + ":id"}>
                        <SupplierForm
                            isEdditing={editEnabled}
                            setEdditing={setEditEnabled}
                            setErrorForm={setErrorOnForm}
                            errorForm={errorOnForm}
                        />
                    </Route>
                }
                <Redirect from="*" to={allAppRoutes.suppliersList} />
            </Switch>

            {/*BOTONES*/}
            {usePathname !== allAppRoutes.suppliersList &&
                <Buttons
                    addOrEdit={addingOrEdit}
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                    cancel={() => {
                        setEditEnabled(addingOrEditing.none);
                        usePath.replace(allAppRoutes.suppliersList);
                    }}
                    add={addNewSupplier}
                    save={saveEditedSupplier}
                    from="proveedor"
                    thereIsList={true}
                    restoreForm={restoreSupplierEdit}
                    delete={() => {
                        dispatch(deleteSupplier(supplierEditForm));
                        setEditEnabled(addingOrEditing.none);
                        usePath.replace(allAppRoutes.suppliersList);
                    }}
                    deleteText={supplierEditForm.nif}
                    disableSaveIfError={(errorOnForm === true) ? true : false}
                />
            }

        </Fragment >

    );
};

export default Suppliers;
