import React, { ReactElement } from "react";
import { allAppRoutes } from "../commonInterfaces/allAppRoutes";


function AvisoLegal(): ReactElement {


    return (

        <div className="container"> {/*//super importante el jodido key, sino no recarga*/}
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h5 className="text-gray-900 mb-4" style={{ fontWeight: "bold" }}>Aseguis</h5>
                                            <h1 className="h1 text-gray-900 mb-4" style={{ fontWeight: "bold" }}>Términos y condiciones</h1>
                                        </div>

                                        {/*************************************************************************************************************************/}

                                        <br></br>
                                        <h3 className="h3 text-gray-900 mb-4">1. Condiciones de uso</h3>
                                        Aseguis SL (en adelante Aseguis), es un servicio online para gestionar diferentes aspectos de un negocio dedicado
                                        al transporte de mercancía. Las presentes condiciones exponen los derechos y obligaciones del usuario y de Aseguis
                                        como proveedor del servicio.
                                        <br></br><br></br>
                                        Es importante leerlo atentamente, ya que Aseguis supondrá que cualquier usuario que utilice el servicio ha leído y aceptado,
                                        sin reservas, las condiciones que siguen. Además, Aseguis se reserva el derecho de actualizar y modificar los Términos y
                                        Condiciones de uso sin ningún tipo de aviso previo, estando estos disponibles siempre en la dirección web www.aseguis.es.
                                        <br></br><br></br>
                                        Aseguis {/*, con NIF xxxxxxxxxx y domicilio en xxxxxxxxxxxxxx, 1o 8o, 08206 de Sabadell, Barcelona, inscrita en el Registro Mercantil
                                        de Barcelona, Tomo 44911, Folio 150, Página 471120, inscripción 1ª, */}proporciona el sitio web Aseguis y sus servicios relacionados
                                        de acuerdo a los términos establecidos a continuación.
                                        <br></br><br></br>
                                        El incumplimiento de cualquiera de las Condiciones de uso podrá ser causa de anulación de la cuenta.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">2. Condiciones de la cuenta</h3>
                                        Para acceder al servicio es imprescindible ser mayor de dieciséis años.
                                        <br></br><br></br>
                                        Se deberá proporcionar un nombre legal completo, una dirección de correo electrónico, y toda la información requerida por Aseguis
                                        en el proceso de creación de la cuenta.
                                        <br></br><br></br>
                                        Es responsabilidad del usuario proporcionar información veraz. Aseguis se reserva el derecho de eliminar cualquier cuenta si se
                                        sospecha de su veracidad o pudiera incumplir alguna de las normas de uso.
                                        <br></br><br></br>
                                        El usuario es responsable de mantener la privacidad de su cuenta. Aseguis no se hace responsable de cualquier daño o pérdida que
                                        pueda ser consecuencia de un error del usuario al proteger su información de acceso.
                                        <br></br><br></br>
                                        Las partes aceptan equiparar jurídicamente la firma autógrafa del cliente a la realizada mediante cualquier otro tipo de clave,
                                        código o elemento de seguridad identificativo. Sin perjuicio de lo anterior, Aseguis podrá exigir, cuando lo considere necesario,
                                        confirmación por escrito al cliente.

                                        {/*************************************************************************************************************************/}

                                        {/*<br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">3. Tipos de contrato</h3>
                                        La contratación de un plan de pago de Aseguis se inicia en el momento en el que el usuario introduce los datos de la tarjeta y realiza un primer pago.
                                        Los límites de cada plan se especifican en la sección de precios de aseguis.es.
                                        Si el usuario supera cualquiera de los límites de su tarifa, tendrá que aumentar la tasa.
                                        Aseguis se reserva el derecho de modificar las tarifas unilateralmente y en cualquier momento,
                                        sin conceder a dicho cambio ningún derecho de compensación hacia los usuarios.*/}

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">3. Pago y acceso</h3>
                                        Para realizar los pagos, {/*es necesario introducir en la cuenta una tarjeta bancaria válida*/}
                                        Aseguis cobrará periódicamente al usuario una tarifa recurrente {/*dependiendo del tipo de cuenta contratada*/}.
                                        <br></br><br></br>
                                        El servicio será cobrado cada período por adelantado y no es reembolsable. No habrá reembolsos o créditos por meses parciales del servicio,
                                        ni habrá reembolsos en caso de que el usuario no utilice el servicio durante el período de tiempo en que la cuenta esté abierta.
                                        En ningún caso se harán excepciones, salvo en los supuestos que se especifican en la política de devoluciones que se encuentra al final de este documento.
                                        <br></br><br></br>
                                        El usuario acepta que no será necesario confirmar la recepción de la aceptación del contrato cuando se haya celebrado exclusivamente mediante comunicación electrónica o verbal.
                                        <br></br><br></br>
                                        En caso de que el usuario llegue al límite de cualquiera de los elementos contratados no se le permitirá seguir añadiendo elementos, a no ser que haga una upgrade de su cuenta.
                                        A partir de entonces, la próxima facturación se cargará a su tarjeta por el nuevo monto, a menos que se cancele la cuenta.
                                        <br></br><br></br>
                                        En caso de impago o devolución del recibo, se notificará al usuario del incumplimiento y perderá automáticamente la capacidad de acceder al Servicio.
                                        Los datos se eliminarán en un plazo de treinta (30) días a partir de la fecha de incumplimiento.
                                        <br></br><br></br>
                                        Ninguna tarifa incluye los impuestos o derechos requeridos por las autoridades gubernamentales. El usuario es responsable del pago de estos impuestos u obligaciones.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">4. Modificaciones del servicio y de los planes</h3>
                                        Aseguis se reserva el derecho de modificar o suspender, temporal o permanentemente,
                                        el servicio en cualquier momento y por cualquier motivo si lo considera conveniente, con o sin previo aviso.
                                        <br></br><br></br>
                                        Aseguis se reserva el derecho de cambiar las cuotas mensuales con un aviso de 15 días.
                                        La notificación de los cambios de cuota se publicará en el sitio web de Aseguis y por escrito.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">5. Cancelación y rescisión</h3>
                                        El usuario es responsable de la correcta cancelación de su cuenta. La cuenta puede ser cancelada en cualquier momento
                                        {/*través del menú de perfil.*/} contactando con Aseguis en el correo: aseguis@aseguis.es o en el teléfono 624 088 293
                                        <br></br><br></br>
                                        Si la cuenta es eliminada, que no cancelada, todo su contenido se eliminará después de treinta días.
                                        <br></br><br></br>
                                        El usuario puede cancelar su cuenta en cualquier momento, pero será responsable de todos los cargos realizados hasta ese momento,
                                        incluyendo el cargo mensual completo por el mes en el que suspenda el servicio. A partir de entonces, no se le cobrará.
                                        <br></br><br></br>
                                        Aseguis se reserva el derecho de cancelar una cuenta o impedir el uso del Software a aquellos que no cumplan con las presentes condiciones de uso.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">6. Derecho de copia y propiedad intelectual</h3>
                                        Aseguis es titular de todos los derechos de la propiedad intelectual de todos y cualquiera de los componentes del servicio que puedan protegerse,
                                        incluyendo, pero no limitándose, al nombre del servicio, material gráfico, bases de datos, a todo el software asociado con el servicio y los elementos de la
                                        interfaz de usuario contenidos en el servicio, muchas de las características individuales y la documentación relacionada.
                                        <br></br><br></br>
                                        El usuario se compromete a no copiar, adaptar, reproducir, distribuir, aplicar ingeniería inversa, descompilar o disimular cualquier faceta del servicio que Aseguis posea.
                                        <br></br><br></br>
                                        El usuario también acepta y se obliga a no utilizar robots, spiders y otros aparatos automatizados o procesos manuales para controlar o copiar cualquier contenido del servicio.
                                        <br></br><br></br>
                                        Aseguis no reclamará derechos sobre la propiedad intelectual del contenido que el usuario suba o proporcione al servicio. Sin embargo,
                                        utilizando el servicio para enviar contenido, el usuario acepta que terceros puedan ver y compartir este contenido enviado.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">7. Condiciones generales</h3>
                                        El usuario es completamente responsable del acceso y correcto uso de Aseguis con sujeción a la legalidad vigente, sea nacional o internacional,
                                        así como a los principios de buena fe, la moral, buenas costumbres y orden público. Y específicamente, adquiere el compromiso de observar
                                        diligentemente las presentes Condiciones generales de uso.
                                        <br></br><br></br>
                                        El usuario acepta no revender, duplicar, reproducir o explotar cualquier parte del servicio sin el expreso consentimiento escrito de Aseguis.
                                        El usuario no puede usar el servicio para almacenar, alojar, o enviar correo electrónico dañino o no solicitado (spam).
                                        <br></br><br></br>
                                        El usuario entiende que el servicio puede utilizarse para la transmisión de su contenido y que, durante este proceso, su contenido
                                        (incluidas facturas, avisos de pago, y mensajes personales) puede transferirse sin encriptar a través de Internet. El usuario no puede utilizar
                                        el servicio para transmitir ningún virus, gusano, o cualquier tipo de contenido malicioso o dañino.
                                        <br></br><br></br>
                                        Aseguis no da garantías respecto a la capacidad del usuario para usar el servicio, su satisfacción con el mismo, que esté disponible en todo momento,
                                        ininterrumpidamente y sin errores, la precisión de los cálculos matemáticos llevados a cabo por el servicio y la corrección de los errores del servicio.
                                        <br></br><br></br>
                                        Ni Aseguis, ni sus socios, ni sus patrocinadores son responsables de cualquier daño directo, indirecto, secundario, consiguiente, especial, ejemplar,
                                        punitivo o de cualquier otro tipo que surja o que esté relacionado de cualquier forma con el uso que el usuario haga del servicio.
                                        El usuario solo puede solucionar su insatisfacción con el servicio dejando de utilizarlo y cancelando su cuenta.
                                        <br></br><br></br>
                                        Si cualquier condición de las aquí descritas queda invalidada o no puede aplicarse, la aplicación de cualquiera de las restantes no debe en ningún caso verse afectada.
                                        <br></br><br></br>
                                        Cualquier pregunta con respecto a las Condiciones de uso deberá dirigirse al soporte técnico de Holded: aseguis@aseguis.es o al teléfono 624 088 293
                                        <br></br><br></br>
                                        Las Condiciones de uso establecen un entendimiento absoluto entre el usuario y Aseguis con respecto al servicio y prevalecen ante cualquier acuerdo
                                        previo alcanzado entre el usuario y Aseguis.
                                        <br></br><br></br>
                                        Las Condiciones de uso se regirán por las leyes de España. El usuario y Aseguis aceptan someterse a la jurisdicción exclusiva de los tribunales de Barcelona
                                        para resolver cualquier cuestión jurídica en relación con las Condiciones.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">8. Seguridad y privacidad</h3>
                                        La empresa Aseguis se encuentra debidamente inscrita en la Agencia Española de Protección de Datos, cumpliendo así la normativa vigente sobre
                                        confidencialidad y seguridad de los datos confidenciales.
                                        <br></br><br></br>
                                        Los datos que los usuarios introducen en Aseguis se encuentran alojados en servidores privados contratados en la nube a la empresa Adam, en España,
                                        garantizando así tanto la normativa en alojamiento de datos como los más altos niveles de seguridad y accesibilidad del mercado.
                                        <br></br><br></br>
                                        Aseguis tiene acceso a los datos de las cuentas con el objetivo de poder mejorar el servicio, así como de ofrecer un buen soporte.
                                        <br></br><br></br>
                                        Todos los empleados y socios de Aseguis tienen firmado un contrato de confidencialidad en cumplimiento con las normativas de privacidad, inclusive la GDPR.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">9. Política de devoluciones</h3>
                                        Suscripciones mensuales: si durante los primeros 30 días utilizando Aseguis el usuario no está satisfecho con el servicio, debe contactar a través de
                                        aseguis@aseguis.es. Aseguis se compromete a encontrar una solución a sus necesidades, y si no fuera posible, se procederá a la devolución del dinero.
                                        Si el usuario no está satisfecho, se le ofrecerá un reembolso completo.
                                        <br></br><br></br>
                                        Suscripciones anuales: si durante los primeros 30 días utilizando Aseguis el usuario no está satisfecho con el servicio, debe contactar a través de
                                        aseguis@aseguis.es. Aseguis se compromete a encontrar una solución a sus necesidades, y si no fuera posible, se procederá a la devolución del dinero.
                                        Si el usuario no está satisfecho, se le ofrecerá un reembolso completo.
                                        <br></br><br></br>
                                        Renovación de las suscripciones: las suscripciones se renuevan automáticamente hasta su cancelación manual.
                                        Para cancelar una suscripción, el usuario debe hacerlo a través de la configuración- ‘Cuenta’ en su cuenta.
                                        Se realizará un reembolso completo por causa de cancelación hasta siete días hábiles después de la fecha de renovación automática.
                                        <br></br><br></br>
                                        Excepción de la política de devoluciones de Aseguis: no se aceptarán solicitudes de devolución de usuarios a los que se les haya suspendido o resuelto
                                        el servicio por el incumplimiento de los términos y condiciones.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">10. Política de privacidad</h3>
                                        Consulte la <a href={allAppRoutes.politicaPrivacidad} target="_blank" >política de privacidad</a>

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">11. Política de cookies</h3>
                                        Consulte la <a href={allAppRoutes.politicaCookies} target="_blank" >política de cookies</a>

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">12. Delegado de protección de datos</h3>
                                        Para cualquier cuestión relacionada con el tratamiento que Aseguis hace de sus datos, las personas interesadas pueden ponerse en contacto
                                        con el delegado de protección de datos:
                                        <br></br>
                                        Email: aseguis@aseguis.es
                                        <br></br>
                                        Dirección postal: C/ Manuel de falla num.43 1o 8o Sabadell, Barcelona (08206), España


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>

    );
};


export default AvisoLegal;
