import React, { Fragment, useEffect } from "react";
import { ApplicationState } from '../../../ApplicationState';
import { useSelector } from "react-redux";
import {
    addingOrEditing, formStates, tiposArticuladosVehiculos, tiposEstructuraVehiculos,
    validateAlphaNumeric, validateFormEmpty, validateNumberFormEmpty,
    CapitalizefirstLetter, initialVehicleType, vehicleType,
} from "aseguisShared";
import NumberFormat from "react-number-format";
import { VehicleTypeValidateApi } from "./VehicleTypeValidateApi";

interface infoToVehiclesTypes {
    isEdditing: number,
    OriginalVehiclesTypes: vehicleType[]
    editVehiclesTypes: vehicleType[]
    setVehiclesTypes: (vehicleType: vehicleType[]) => void

    vehicleType: vehicleType,
    setVehicleType: (vehicleType: vehicleType) => void

    errorForm: boolean,
    setErrorForm: (value: boolean) => void,
}

const VehiclesTypes = (props: infoToVehiclesTypes) => {

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);

    const [formState, setFormState] = React.useState<number>(formStates.none);
    const [showErrorOnDeleteType, setShowErrorOnDeleteType] = React.useState(false)
    const [descriptionToModify, setDescriptionToModify] = React.useState("")

    const { isLoading, setIdVehicleType, existsVehicleType } = VehicleTypeValidateApi()

    useEffect(() => {
        setShowErrorOnDeleteType(false)
        if (props.isEdditing !== addingOrEditing.none) {
            setIdVehicleType(props.vehicleType.id)
        }
    }, [props.vehicleType.id]);

    useEffect(() => {
        if (props.isEdditing === addingOrEditing.none) {
            if (formState != formStates.none) {
                props.setVehicleType(initialVehicleType)
            }
            setFormState(formStates.none)
        }
    });

    useEffect(() => {
        if (props.isEdditing === addingOrEditing.none) {
            props.setVehiclesTypes(props.OriginalVehiclesTypes)
            props.setVehicleType(initialVehicleType)
        }
    }, [props.isEdditing]);

    function ExistDuplicate(desc: string): boolean {
        for (var type of props.editVehiclesTypes) {
            if (type.description.toLowerCase() === desc.toLowerCase()) {
                return true
            }
        }
        return false;
    }

    useEffect(() => {
        if (
            ((formState !== addingOrEditing.none) && (props.isEdditing !== addingOrEditing.none)) &&
            (
                (props.vehicleType.description.length < 2) ||
                (props.vehicleType.estructura.length < 2) ||

                (props.vehicleType.tractoraRemolque.length < 2 && props.vehicleType.estructura === tiposEstructuraVehiculos.articulado) ||

                (props.vehicleType.typeVehicle.length < 2 && props.vehicleType.tractoraRemolque !== tiposArticuladosVehiculos.tractora) ||
                (props.vehicleType.capacidadCarga < 1 && props.vehicleType.tractoraRemolque !== tiposArticuladosVehiculos.tractora)                
            )
            || ((ExistDuplicate(props.vehicleType.description)) && formState === addingOrEditing.adding)
        ) {
            props.setErrorForm(true)
        } else {
            props.setErrorForm(false)
        }
    }, [props.vehicleType, formState])

    const listVehiclesTypes: JSX.Element[] = [...props.editVehiclesTypes].reverse().map((vehiType, index) => {
        if (vehiType && props.editVehiclesTypes && descriptionToModify === vehiType.description && formState === formStates.edit) {
            return (
                <tr className="table-row" key={index}>
                    <td><input
                        type="text"
                        onChange={(event) => {
                            var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                            props.setVehicleType({
                                ...props.vehicleType,
                                description: value
                            });
                        }}
                        value={props.vehicleType.description}
                        className={validateFormEmpty(props.vehicleType.description, 2)}
                        id="description" />
                    </td>
                </tr>
            )
        }
        else {
            return (
                <tr className={`table-row ${(vehiType && props.vehicleType && vehiType.description === props.vehicleType.description) ? "selected" : ""}`}
                    onClick={() => {
                        if (formState === formStates.add) {
                            props.setVehicleType(initialVehicleType);
                            setDescriptionToModify("")
                        } else {
                            props.setVehicleType(vehiType);
                            setDescriptionToModify(vehiType.description)
                        }
                    }}
                    key={index}>
                    <td>{vehiType.description}</td>
                </tr>
            )
        }
    });

    return (
        <Fragment>
            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">Tipos de vehículo</h6>

                    {((props.isEdditing === addingOrEditing.edditing)) &&
                        <div>
                            {(formState === formStates.none) &&
                                <div className="header-buttons">

                                    {/* AÑADIR BOTON*/}
                                    <button className="btn btn-success btn-green btn-sm"
                                        onClick={() => {
                                            if (isAppLoading === false) {
                                                setShowErrorOnDeleteType(false)
                                                setFormState(formStates.add)
                                                var maxId = 0
                                                for (var vehicleType of props.editVehiclesTypes) {
                                                    if (vehicleType.id > maxId) {
                                                        maxId = vehicleType.id
                                                    }
                                                }
                                                maxId = maxId + 1
                                                props.setVehicleType({
                                                    ...initialVehicleType,
                                                    id: maxId
                                                })
                                            }
                                        }}>
                                        <i className="fas fa fa-plus"></i>
                                    </button>

                                    {/*edit button*/}
                                    {props.vehicleType.description &&
                                        <button className="btn btn-success btn-blue btn-sm"
                                            onClick={() => {
                                                if (isAppLoading === false) {
                                                    setFormState(formStates.edit)
                                                    setShowErrorOnDeleteType(false)
                                                }
                                            }}>
                                            <i className="fas fa fa-pen"></i>
                                        </button>
                                    }

                                    {/*delete button*/}
                                    {props.vehicleType.description &&
                                        <button className={(existsVehicleType === true) ? "btn btn-success btn-red btn-sm disabled" : "btn btn-success btn-sm btn-red"}
                                            onClick={() => {
                                                if (isAppLoading === false) {
                                                    if (existsVehicleType === false) {
                                                        props.setVehiclesTypes(
                                                            props.editVehiclesTypes.filter(vehiType => vehiType.description !== props.vehicleType.description)
                                                        );
                                                        props.setVehicleType(initialVehicleType);
                                                    } else {
                                                        setShowErrorOnDeleteType(true);
                                                    }
                                                }
                                            }}>
                                            <i className="fas fa fa-times"></i>
                                        </button>
                                    }

                                </div>
                            }
                        </div>
                    }

                    <div className="header-buttons">

                        {/* ESTADO AÑADIR */}
                        {(formState === formStates.add && props.isEdditing === addingOrEditing.edditing) &&
                            <button className={props.errorForm === false ? "btn btn-success btn-sm left-margin" : "btn btn-success btn-sm left-margin disabled"}
                                onClick={() => {
                                    if (props.errorForm === false) {
                                        setFormState(formStates.none)
                                        if (props.vehicleType.description) {
                                            props.setVehiclesTypes(
                                                [...props.editVehiclesTypes, { ...props.vehicleType }]
                                            )
                                        }
                                        setDescriptionToModify(props.vehicleType.description)
                                    }
                                }}>
                                Añadir tipo
                            </button>
                        }

                        {/* ESTADO EDITAR */}
                        {(formState === formStates.edit) &&
                            <button className={props.errorForm === false ? "btn btn-success btn-sm left-margin" : "btn btn-success btn-sm left-margin disabled"}
                                onClick={() => {
                                    if (props.errorForm === false) {
                                        setFormState(formStates.none)
                                        props.setVehiclesTypes(
                                            props.editVehiclesTypes.map((vehiType, index) => {
                                                if (vehiType.description !== descriptionToModify) {
                                                    return vehiType;
                                                }
                                                return props.vehicleType;
                                            }),
                                        );
                                        setDescriptionToModify(props.vehicleType.description)
                                    }
                                }}>
                                Guardar tipo
                            </button>
                        }

                        {/* ESTADO AÑADIR O EDITAR */}
                        {((formState === formStates.add || formState === formStates.edit) && props.isEdditing === addingOrEditing.edditing) &&
                            <button className="btn btn-danger btn-sm left-margin"
                                onClick={() => {
                                    props.setVehicleType(initialVehicleType);
                                    setFormState(formStates.none)
                                }}>
                                Cancelar
                            </button>
                        }

                    </div>

                </div>

                <div className="card-body">
                    <div className="row">
                        <div className="form-group col-md-4">
                            <h6 className="mb-2 font-weight-bold text-green">Historial</h6>
                            <div className="table-responsive portlet-600"
                            >

                                {(
                                    (formState === formStates.none && props.editVehiclesTypes.length > 0) ||
                                    (formState === formStates.edit) || (formState === formStates.add)
                                ) ?
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Descripción</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formState === formStates.add &&
                                                <tr className="table-row">
                                                    <td>
                                                        <input
                                                            type="text"
                                                            onChange={(event) => {
                                                                var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                                                props.setVehicleType({
                                                                    ...props.vehicleType,
                                                                    description: value
                                                                });
                                                            }}
                                                            value={props.vehicleType.description}
                                                            className={ExistDuplicate(props.vehicleType.description) === true ? "form-control is-invalid" : validateFormEmpty(props.vehicleType.description, 2)}
                                                            id="description"
                                                        />
                                                        {(ExistDuplicate(props.vehicleType.description) === true) &&
                                                            <div className="text-danger">
                                                                <small>DESCRIPCION duplicada</small>
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            }
                                            {listVehiclesTypes}
                                        </tbody>
                                    </table>
                                    : <div>No se ha dado de alta ningún tipo de vehículo</div>
                                }
                            </div>
                        </div>

                        {props.vehicleType.description &&
                            <div className="form-group col-md-8">
                                <div className="row">

                                    <div className="form-group col-md-4">
                                        <div>
                                            <label style={{ fontWeight: 'bold' }} htmlFor="name">Tipo de estructura</label>
                                            {(props.isEdditing === addingOrEditing.edditing && formState !== addingOrEditing.none) ?
                                                <select
                                                    value={props.vehicleType.estructura}
                                                    className={validateFormEmpty(props.vehicleType.estructura, 1)}
                                                    id="isChofer"
                                                    onChange={(value) => {
                                                        props.setVehicleType({
                                                            ...props.vehicleType,
                                                            estructura: value.target.value,
                                                            observaciones: "",
                                                            tractoraRemolque: "",
                                                            typeVehicle: "",
                                                            capacidadCarga: 0,
                                                            tieneTacografo: false
                                                        });
                                                    }}>
                                                    <option disabled value="">Seleccione un tipo</option>
                                                    <option value={tiposEstructuraVehiculos.rigido}>{tiposEstructuraVehiculos.rigido}</option>
                                                    <option value={tiposEstructuraVehiculos.articulado}>{tiposEstructuraVehiculos.articulado}</option>
                                                </select>
                                                : <div>{props.vehicleType.estructura}</div>}

                                        </div>
                                    </div>

                                    {props.vehicleType.estructura === tiposEstructuraVehiculos.articulado &&
                                        <div className="form-group col-md-4">
                                            <div>
                                                <label style={{ fontWeight: 'bold' }} htmlFor="name">Tractora o remolque</label>
                                                {(props.isEdditing === addingOrEditing.edditing && formState !== addingOrEditing.none) ?
                                                    <select
                                                        value={props.vehicleType.tractoraRemolque}
                                                        className={validateFormEmpty(props.vehicleType.tractoraRemolque, 1)}
                                                        id="isChofer"
                                                        onChange={(value) => {
                                                            props.setVehicleType({
                                                                ...props.vehicleType,
                                                                tractoraRemolque: value.target.value,
                                                                observaciones: "",
                                                                typeVehicle: "",
                                                                capacidadCarga: 0,
                                                                tieneTacografo: false
                                                            });
                                                        }}>
                                                        <option disabled value="">Seleccione un tipo</option>
                                                        <option value={tiposArticuladosVehiculos.tractora}>{tiposArticuladosVehiculos.tractora}</option>
                                                        <option value={tiposArticuladosVehiculos.remolque}>{tiposArticuladosVehiculos.remolque}</option>
                                                    </select>
                                                    : <div>{props.vehicleType.tractoraRemolque}</div>}

                                            </div>
                                        </div>
                                    }


                                    {(
                                        (props.vehicleType.estructura === tiposEstructuraVehiculos.rigido) ||
                                        (props.vehicleType.estructura === tiposEstructuraVehiculos.articulado && props.vehicleType.tractoraRemolque === tiposArticuladosVehiculos.remolque)
                                    ) &&
                                        <div className="form-group col-md-4">
                                            <label style={{ fontWeight: 'bold' }} htmlFor="input_name">Descripción tipo de vehículo</label>
                                            {(props.isEdditing === addingOrEditing.edditing && formState !== addingOrEditing.none) ?
                                                <input
                                                    type="text"
                                                    value={props.vehicleType.typeVehicle}
                                                    onChange={(event) => {
                                                        var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                                        props.setVehicleType({
                                                            ...props.vehicleType,
                                                            typeVehicle: value
                                                        });
                                                    }}
                                                    className={validateFormEmpty(props.vehicleType.typeVehicle, 1)}
                                                    id="estructura" />
                                                : <div>{props.vehicleType.typeVehicle}</div>}
                                        </div>
                                    }

                                    {(
                                        (props.vehicleType.estructura === tiposEstructuraVehiculos.rigido) ||
                                        (props.vehicleType.estructura === tiposEstructuraVehiculos.articulado && props.vehicleType.tractoraRemolque === tiposArticuladosVehiculos.remolque)
                                    ) &&
                                        <div className="form-group col-md-4">
                                            <label style={{ fontWeight: 'bold' }} htmlFor="input_name">Capacidad de carga (kg)</label>

                                            {(props.isEdditing === addingOrEditing.edditing && formState !== addingOrEditing.none) ?
                                                <NumberFormat
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    allowNegative={false}
                                                    suffix={" kg"}
                                                    value={props.vehicleType.capacidadCarga}
                                                    className={validateNumberFormEmpty(props.vehicleType.capacidadCarga)}
                                                    onValueChange={(value) => {
                                                        const valueTofloat = (value.floatValue && value.floatValue < 999999) ? value.floatValue : 0
                                                        props.setVehicleType({
                                                            ...props.vehicleType,
                                                            capacidadCarga: valueTofloat
                                                        });
                                                    }} />
                                                :
                                                <NumberFormat
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    displayType="text"
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    allowNegative={false}
                                                    value={props.vehicleType.capacidadCarga}
                                                    className="form-control undefined"
                                                    renderText={value => <div>{value}</div>}
                                                    suffix={" kg"}
                                                />
                                            }
                                        </div>}

                                    {(props.vehicleType.tractoraRemolque !== tiposArticuladosVehiculos.remolque) &&
                                        <div className="form-group col-md-4">
                                            <div>
                                                <label style={{ fontWeight: 'bold' }} htmlFor="name">Tiene tacógrafo</label>
                                                {(props.isEdditing === addingOrEditing.edditing && formState !== addingOrEditing.none) ?
                                                    <select
                                                        value={props.vehicleType.tieneTacografo === true ? "Si" : "No"}
                                                        className="form-control undefined"
                                                        id="tieneTacografo"
                                                        onChange={(value) => {
                                                            props.setVehicleType({
                                                                ...props.vehicleType,
                                                                tieneTacografo: (value.target.value === "Si") ? true : false
                                                            });
                                                        }}>
                                                        <option disabled value="">Seleccione un tipo</option>
                                                        <option value="Si">Si</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                    : <div>{props.vehicleType.tieneTacografo === true ? "Si" : "No"}</div>}

                                            </div>
                                        </div>
                                    }

                                    <div className="form-group col-md-6">
                                        <label style={{ fontWeight: 'bold' }} htmlFor="input_name">Observaciones</label>
                                        {(props.isEdditing === addingOrEditing.edditing && formState !== addingOrEditing.none) ?
                                            <input
                                                type="text"
                                                value={props.vehicleType.observaciones}
                                                onChange={(event) => {
                                                    var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                                    props.setVehicleType({
                                                        ...props.vehicleType,
                                                        observaciones: value
                                                    });
                                                }}
                                                className="form-control undefined"
                                                id="estructura" />
                                            : <div>{props.vehicleType.observaciones}</div>}
                                    </div>
                                </div>

                                {showErrorOnDeleteType === true &&
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <div className="text-danger">
                                                <small>TIPO DE VEHÍCULO no se puede eliminar. Actualmente asociado a vehículos y/o tarifas</small>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        }

                    </div>
                </div>

            </div>

        </Fragment>

    );

}
export default VehiclesTypes;