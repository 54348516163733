import React, { ReactElement, ReactNode } from "react";
import imageLogo from "../../assets/tsp/LogoCompleto3.png";
import './../Register/Register.css';

interface RegisterTemplateProps {
    children: ReactNode;
}

function TrackingTemplate({ children }: RegisterTemplateProps): ReactElement {
    return (
        <div id="register-page-container">
            <div id="register-side-bar" style={{ backgroundColor: "#F1FFF1" }}>
                <img src={imageLogo} alt="Logo" />
                <p style={{ fontWeight: "bold" }}>Transportes Sol i Padris</p>
                <p>Seguimiento de envíos</p>
            </div>
            <div id="register-form-container" style={{ alignItems: "center" }}>
                {children}
            </div>
        </div>
    );
}

export default TrackingTemplate;
