import React, { ReactElement } from "react";

function PoliticaCookies(): ReactElement {


    return (

        <div className="container"> {/*//super importante el jodido key, sino no recarga*/}
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h5 className="text-gray-900 mb-4" style={{ fontWeight: "bold" }}>Aseguis</h5>
                                            <h1 className="h1 text-gray-900 mb-4" style={{ fontWeight: "bold" }}>Política de cookies</h1>
                                        </div>


                                        {/* MAS ADELANTE, CUANDO PONGAMOS MAS COOKIES, ACTUALIZAR CON ESTE CONTENIDO --> https://www.holded.com/es/politica-de-cookies */}


                                        <br></br>

                                        En cumplimiento con el deber de información recogido en el apartado 2º del artículo 22 de la Ley 34/2002, de 11 de julio,
                                        de Servicios de la Sociedad de la Información y del Comercio Electrónico, la presente cláusula de cookies tiene por
                                        finalidad informar a los usuarios de manera clara y precisa sobre las cookies que utiliza el dominio web www.aseguis.es, 
                                        propiedad de Aseguis

                                        {/*************************************************************************************************************************/}
  
                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">1. Qu&eacute; son las galletas</h3>
                                        Las galletas o cookies son archivos de texto de pequeño tamaño que se descargan en el equipo terminal del usuario (ordenador,
                                        tableta, teléfono móvil, etc.) y que se guardan en la memoria de su navegador.
                                        Las cookies permiten que la página web almacene y recupere la información sobre la visita, como el usuario, el idioma, entre otras opciones,
                                        con el fin de mejorar los servicios que se ofrecen y contribuir a tener una mejor experiencia en la navegación para el usuario.
                                        <br></br> <br></br>
                                        En este portal web se utilizan diferentes tipos de cookies con distintas finalidades, a continuación se enumeran las cookies empleadas.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">2. Qué tipo de cookies se utilizan en Aseguis</h3>
                                        En este sitio web únicamente se almacenan cookies estrictamente necesarias.
                                        <br></br> <br></br>
                                        Las cookies estrictamente necesarias no requieren el tratamiento de datos personales, y en consecuencia, no están sujetas a la normativa de privacidad.
                                        Éstas cookies necesarias hacen que el sitio web sea utilizable al permitir funciones básicas como la navegación de la página y el acceso a áreas del sitio web.
                                        <br></br> <br></br>
                                        El sitio web no puede funcionar correctamente sin estas cookies y no se pueden desactivar.
                                        <br></br> <br></br>                                        
                                        No se utilizan ningún otro tipo de cookies, ni para fines estadísticos, ni analíticos, ni publicitarios, ni para cualquier otro fin
                                        que el descrito en anterioridad: el uso correcto del sitio web.
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>

    );
};


export default PoliticaCookies;
