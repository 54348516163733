import React, { Fragment, ReactElement, useEffect } from "react";
import { addingOrEditing, inicialiceDate, albaranesToBill, client, getFormattedDate } from "aseguisShared";

export interface BillAlbaranCard {
    albaranesToBill: albaranesToBill,

    fechaDesde: Date | null,
    fechaHasta: Date | null,

    deleteClient: (client: client) => void,

    isEdditing: number
    width?: string;
}

function BillAlbaranCard(props: BillAlbaranCard): ReactElement {

    var width = "col-xl-4 col-md-6 mb-4";

    if (props.width) {
        width = "col-xl-" + props.width + " col-md-6 mb-4";
    }

    const albaranesDates = () => {
        var todo = "Todo "
        var desde = ""
        var hasta = ""
        if (props.fechaDesde != null &&
            props.fechaDesde != inicialiceDate.year1000 &&
            new Date(props.fechaDesde).getFullYear() !== 0 &&
            new Date(props.fechaDesde).getFullYear() !== 1
        ) {
            desde = "Desde el " + getFormattedDate(props.fechaDesde) + " "
        }
        if (props.fechaHasta != null &&
            props.fechaHasta != inicialiceDate.year1000 &&
            new Date(props.fechaHasta).getFullYear() !== 0 &&
            new Date(props.fechaHasta).getFullYear() !== 1
        ) {
            hasta = "Hasta el " + getFormattedDate(props.fechaHasta)
        }

        if (desde === "" && hasta === "") {
            todo = ""
            desde = "Todo lo pendiente en cualquier fecha"
        }
        return ({ todo, desde, hasta })
    }

    return (

        <Fragment>

            {(props.albaranesToBill.albaranes.length > 0) ?
                props.albaranesToBill.albaranes.map((albaran) => {
                    return (
                        <div className={width} key={albaran.client.id}>
                            <div className={`card shadow h-100 py-2 `}>
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xl font-weight-bold text-green text-uppercase mb-1">{albaran.client.nombre}</div>

                                            {albaran.paqueterias.length > 0 && <div className="h5 mb-0 font-weight-bold text-gray-800">Albaranes paqueterias:</div>}
                                            {albaran.paqueterias.map((paqueteria) => {
                                                return (
                                                    <Fragment key={paqueteria.id}>
                                                        <div className="h6 mb-0 font-weight-bold text-gray-800">&nbsp;&nbsp;{"ID paqueteria: " + paqueteria.id}</div>
                                                        {paqueteria.datosTransporte.referencias.map((referencia) => {
                                                            return (
                                                                <div className="h6 mb-0 font-weight-bold text-gray-800" key={referencia}>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;{"Referencia: " + referencia}
                                                                </div>
                                                            )
                                                        })}
                                                    </Fragment>
                                                )
                                            })}

                                            {albaran.cargas.length > 0 && <div className="h5 mb-0 font-weight-bold text-gray-800">Albaranes cargas completas:</div>}
                                            {albaran.cargas.map((carga) => {
                                                return (
                                                    <Fragment key={carga.id}>
                                                        <div className="h6 mb-0 font-weight-bold text-gray-800">&nbsp;&nbsp;{"ID carga completa: " + carga.id}</div>
                                                        {carga.datosGenerales.referencias.map((referencia) => {
                                                            return (
                                                                <div className="h6 mb-0 font-weight-bold text-gray-800" key={referencia}>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;{"Referencia: " + referencia}
                                                                </div>
                                                            )
                                                        })}
                                                    </Fragment>
                                                )
                                            })}

                                            {(albaran.paqueterias.length === 0 && albaran.cargas.length === 0) &&
                                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                    {albaranesDates().todo} <br></br>
                                                    {albaranesDates().desde} <br></br>
                                                    {albaranesDates().hasta}
                                                </div>
                                            }

                                        </div>
                                        <div className="col-auto">
                                            <i className={`fas fa-truck fa-2x text-gray-300`}></i>

                                        </div>
                                    </div>

                                    {props.isEdditing === addingOrEditing.edditing &&
                                        <div>
                                            <br></br>
                                            <div className="header-buttons-bottom-right">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => {
                                                        props.deleteClient(albaran.client)
                                                    }}>
                                                    <i className="fa fa-times fa-1x"></i>
                                                </button>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    )
                })

                :

                <div className="col-xl-6 col-md-6 mb-4">
                    <div className={`card shadow h-100 py-2 `}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xl font-weight-bold text-green text-uppercase mb-1">Todos los clientes</div>
                                    <br></br>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                        {albaranesDates().todo} <br></br>
                                        {albaranesDates().desde} <br></br>
                                        {albaranesDates().hasta}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className={`fas fa-truck fa-2x text-gray-300`}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            }

        </Fragment>


    );
}


export default BillAlbaranCard;
