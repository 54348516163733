import React, { Fragment, useEffect, ReactElement, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from '../../ApplicationState';
import axios from "axios";
import AutoSuggest from "react-autosuggest";
import Match from 'autosuggest-highlight/match';
import Parse from 'autosuggest-highlight/parse';
import {
    client, inicialiceCargaCompleta, cargaCompleta, validateAlphaNumeric,
    CapitalizefirstLetter, getFormattedDate, inicialiceRefereRequest
} from "aseguisShared";
import {  } from "./CargaCompletaStore";
import { } from "../Clients/ClientsStore";

interface infoToCargaCompletaAutocomplete {
    setCargaCompleta: (cargaCompleta: cargaCompleta) => void
    fromDate?: Date
    untilDate?: Date
    filterClient?: client
    cargasToExclude?: cargaCompleta[]
    keepCargaCompleta?: boolean
    onlyFacturated?: boolean
    onlyDelivered?: boolean
    notShowFacturated?: boolean
}

function CargaCompletaAutocomplete(props: infoToCargaCompletaAutocomplete): ReactElement {

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const [goToApi, setGoToApi] = useState(false)
    const [textToSearch, setTextToSearch] = useState("")
    const [cargaCompletasSuggestions, setCargaCompletasSuggestions] = useState<cargaCompleta[]>([])
    const [selectedCargaCompleta, setSelectedCargaCompleta] = useState<cargaCompleta>(inicialiceCargaCompleta)
    const suggestionsContainerRef = useRef<HTMLSpanElement>(null);

    function onValueChanged(newValue: string): void {
        var value = CapitalizefirstLetter(validateAlphaNumeric(newValue))
        value = value.trimStart()
        setTextToSearch(value)
        if (value.length === 0) {
            setCargaCompletasSuggestions([])
        }
    }

    function renderSuggestion(suggestion: cargaCompleta, query: any): JSX.Element {
        const textToHigh = "" + query.query

        const referencia = suggestion.datosGenerales.referenciaString
        const matches = Match(referencia.toLowerCase(), textToHigh.toLowerCase(), { insideWords: true });
        const parts1 = Parse(referencia, matches)

        const idPaq = suggestion.id.toString()
        const matches2 = Match(idPaq.toLowerCase(), textToHigh.toLowerCase(), { insideWords: true });
        const parts2 = Parse(idPaq, matches2)

        return (
            <div>
                <div>
                    <b>{"Fecha albarán: "}</b>
                    {getFormattedDate(suggestion.datosGenerales.recogida)}
                </div>
                <div>
                    <b>{"ID carga completa: "}</b>
                    {parts2.map((part, index) => {
                        const className = part.highlight ? 'highlight' : '';
                        return (
                            <span className={className} key={index}>{part.text}</span>
                        );
                    })}
                </div>
                <div>
                    <b>{"Referencias: "}</b>
                    {parts1.map((part, index) => {
                        const className = part.highlight ? 'highlight' : '';
                        return (
                            <span className={className} key={index}>{part.text}</span>
                        );
                    })}
                </div>
                <div>
                    <b>{"Cliente: "}</b>
                    {suggestion.datosGenerales.client.nombre}
                </div>
            </div>
        );
    }

    useEffect(() => {
        if ((goToApi === true) && (isAppLoading === false) && textToSearch !== '') {

            const timeout2 = setTimeout(() => {

                const refereRequest = { ...inicialiceRefereRequest }

                refereRequest.refere = textToSearch
                refereRequest.idsToExclude = []

                if (props.cargasToExclude) {
                    for (var paqToExclude of props.cargasToExclude) {
                        refereRequest.idsToExclude.push(paqToExclude.id)
                    }
                }
                if (props.filterClient && props.filterClient._id && props.filterClient._id.length > 0) {
                    refereRequest.filterClientId = props.filterClient._id
                }
                if (props.fromDate) {
                    refereRequest.fromDate = props.fromDate
                }
                if (props.untilDate) {
                    refereRequest.untilDate = props.untilDate
                }
                if (props.onlyFacturated && props.onlyFacturated === true) {
                    refereRequest.onlyFacturated = true
                }
                if (props.onlyDelivered && props.onlyDelivered === true) {
                    refereRequest.onlyDelivered = true
                }
                if (props.notShowFacturated && props.notShowFacturated === true) {
                    refereRequest.notShowFacturated = true
                }

                axios
                    .post<cargaCompleta[]>('api/cargaCompleta/refereAutocomplete/', refereRequest)
                    .then((response) => {
                        if (response.data) {
                            setCargaCompletasSuggestions([])

                            if (response.data.length === 1) {
                                const oneCargaCompleta = response.data[response.data.length - 1]
                                setSelectedCargaCompleta(oneCargaCompleta)
                                props.setCargaCompleta(oneCargaCompleta)
                                if (props.keepCargaCompleta === false) {
                                    setTextToSearch("")
                                } else {
                                    setTextToSearch(oneCargaCompleta.datosGenerales.referenciaString)
                                }
                            } else {
                                setCargaCompletasSuggestions(response.data)
                            }
                        }
                        setGoToApi(false)
                    })
                    .catch((error) => {
                        setCargaCompletasSuggestions([])
                    })
            }, 200);
            return () => { clearTimeout(timeout2) }
        }
    }, [textToSearch])


    return (
        <Fragment>
            <span ref={suggestionsContainerRef} style={{ flex: "1" }}>
                <AutoSuggest
                    onSuggestionHighlighted={({ suggestion }) => {
                        if (suggestion && suggestionsContainerRef.current) {
                            const index = cargaCompletasSuggestions.findIndex(s => s === suggestion);
                            const suggestionElements = suggestionsContainerRef.current.querySelectorAll('.react-autosuggest__suggestion');
                            const highlightedElement = suggestionElements[index];
                            if (highlightedElement) {
                                highlightedElement.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'nearest'
                                });
                            }
                        }
                    }}
                    suggestions={cargaCompletasSuggestions}
                    onSuggestionsClearRequested={() =>
                        setCargaCompletasSuggestions([])
                    }
                    onSuggestionsFetchRequested={({ value }) => {
                        setGoToApi(true)
                    }}
                    onSuggestionSelected={(_, { suggestionValue, suggestion }) => {
                        setSelectedCargaCompleta(suggestion)
                        props.setCargaCompleta(suggestion)
                        if (props.keepCargaCompleta === false) {
                            setTextToSearch("")
                        }
                    }}
                    getSuggestionValue={(suggestion) =>
                        suggestion.idString
                    }
                    renderSuggestion={renderSuggestion}
                    inputProps={{
                        placeholder: "Escriba una ID o referencia para autocompletar...",
                        value: textToSearch,
                        onChange: (event, { newValue, method }) => {
                            onValueChanged(newValue)
                        },
                        type: "text",
                        name: "dest_search",
                        className: "form-control undefined",
                        autoComplete: "off",
                        onBlur: () => {
                            if (selectedCargaCompleta.id > 0) {
                                if (props.keepCargaCompleta === false) {
                                    setTextToSearch("")
                                } else {
                                    props.setCargaCompleta(selectedCargaCompleta);
                                }
                            } else {
                                setTextToSearch("")
                            }
                            setGoToApi(false)
                            setCargaCompletasSuggestions([])
                        },
                        onFocus: () => {
                            setTextToSearch("")
                        },
                    }}
                />
            </span>
        </Fragment >
    );
};

export default CargaCompletaAutocomplete;