import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, ReactElement, useState } from "react";
import './Register.css';
import { ApplicationState } from "../../ApplicationState";
import { IStripeProducts, getStripeProducts, stripeProductsColors } from "./RegisterStore";
import ReactTooltip from "react-tooltip";
import NumberFormat from "react-number-format";
import app from "../../App";

export interface RegisterPlansProps {
    from: "register" | "changePlan" | "newPlan"
    originalPrices: string[]
    callbackContinue?: (pricesIds: string[]) => void,
    callbackGoBack?: () => void,
}

function RegisterPlans(props: RegisterPlansProps): ReactElement {

    const dispatch = useDispatch();

    const appLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const stripeProducts: IStripeProducts = useSelector((state: ApplicationState) => state.register.stripeProducts);

    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [pricesId, setPricesId] = useState<string[]>(props.originalPrices)

    useEffect(() => {
        let total = 0;
        for (const priceId of pricesId) {
            for (const product of stripeProducts.products) {
                if (product.priceId === priceId) {
                    total = total + product.price
                }
            }
            for (const optionalProduct of stripeProducts.optionalProducts) {
                if (optionalProduct.priceId === priceId) {
                    total = total + optionalProduct.price
                }
            }
        }
        setTotalPrice(total)
    }, [pricesId])

    useEffect(() => {
        dispatch(getStripeProducts())
    }, [])

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [stripeProducts])

    useEffect(() => {
        const includedProductIds = stripeProducts.includedProducts.map(product => product.priceId);
        const newPricesId = includedProductIds.filter(id => !pricesId.includes(id));
        setPricesId(prevPricesId => [...prevPricesId, ...newPricesId]);
        const productsPriceIds = stripeProducts.products.map(product => product.priceId);
        const noProductPriceIdsInPricesId = productsPriceIds.every(id => !pricesId.includes(id));
        if (noProductPriceIdsInPricesId && stripeProducts.products.length > 1) {
            const priceIdToAdd = stripeProducts.products[1].priceId;
            setPricesId(prevPricesId => {
                const updatedPricesId = [...prevPricesId];
                if (priceIdToAdd && !updatedPricesId.includes(priceIdToAdd)) {
                    updatedPricesId.push(priceIdToAdd);
                }
                return updatedPricesId;
            });
        }
    }, [stripeProducts])

    const showProductsForResumen = () => {
        let productName = "";
        let productPrice = 0;
        for (const stripeProduct of stripeProducts.products) {
            for (const priceId of pricesId) {
                if (stripeProduct.priceId === priceId) {
                    productName = stripeProduct.name
                    productPrice = stripeProduct.price
                }
            }
        }
        if (productName.length === 0) {
            productName = "Seleccione un plan"
            productPrice = 0
        }
        return (
            <div className="register-plan">
                <span className="register-plan-name">{productName}</span>
                <span className="register-plan-price">
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        value={productPrice}
                        className="form-control undefined"
                        renderText={value =>
                            <div style={{ whiteSpace: "nowrap" }}>
                                {value}
                            </div>
                        }
                        suffix={" €"}
                    />
                </span>
            </div>
        )
    }

    const showOptionalProductsForResumen = () => {
        let productName = "";
        let productPrice = 0;
        for (const stripeProduct of stripeProducts.optionalProducts) {
            if (pricesId.includes(stripeProduct.priceId)) {
                productName = stripeProduct.name
                productPrice = stripeProduct.price
            } else {
                const alternativeName = stripeProduct.metadata?.alternative;
                productName = alternativeName || "producto opcional"
                productPrice = 0
            }
            return (
                <div className="register-plan">
                    <span className="register-plan-name">{productName}</span>
                    <span className="register-plan-price">
                        {productPrice > 0 ?
                            <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                value={productPrice}
                                className="form-control undefined"
                                renderText={value =>
                                    <div style={{ whiteSpace: "nowrap" }}>
                                        {value}
                                    </div>
                                }
                                suffix={" €"}
                            />
                            :
                            <span style={{ color: "green" }}>Gratis</span>
                        }

                    </span>
                </div>
            )
        }
    }

    const showOldProductsForChangePlan = () => {
        for (const stripeProduct of stripeProducts.products) {
            if (props.originalPrices.includes(stripeProduct.priceId) && !pricesId.includes(stripeProduct.priceId)) {
                return (
                    <div className="register-plan" style={{ textDecoration: "line-through" }}>
                        <span className="register-plan-name">{stripeProduct.name}</span>
                        <span className="register-plan-price">
                            {stripeProduct.price > 0 ?
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    displayType="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    value={stripeProduct.price}
                                    className="form-control undefined"
                                    renderText={value =>
                                        <div style={{ whiteSpace: "nowrap" }}>
                                            {value}
                                        </div>
                                    }
                                    suffix={" €"}
                                />
                                :
                                <span style={{ color: "green" }}>Gratis</span>
                            }
                        </span>
                    </div>
                )
            }
        }
    }

    const showOldOptionalProductsForChangePlan = () => {
        let productName = "";
        let productPrice = 0;
        for (const stripeProduct of stripeProducts.optionalProducts) {
            if (!pricesId.includes(stripeProduct.priceId) && props.originalPrices.includes(stripeProduct.priceId)) {
                productName = stripeProduct.name
                productPrice = stripeProduct.price
                return (
                    <div className="register-plan" style={{ textDecoration: "line-through" }}>
                        <span className="register-plan-name">{productName}</span>
                        <span className="register-plan-price">
                            {productPrice > 0 ?
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    displayType="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    value={productPrice}
                                    className="form-control undefined"
                                    renderText={value =>
                                        <div style={{ whiteSpace: "nowrap" }}>
                                            {value}
                                        </div>
                                    }
                                    suffix={" €"}
                                />
                                :
                                <span style={{ color: "green" }}>Gratis</span>
                            }
                        </span>
                    </div>
                )
            }
            if (pricesId.includes(stripeProduct.priceId) && !props.originalPrices.includes(stripeProduct.priceId)) {
                const alternativeName = stripeProduct.metadata?.alternative;
                productName = alternativeName || "producto opcional"
                productPrice = 0
                return (
                    <div className="register-plan" style={{ textDecoration: "line-through" }}>
                        <span className="register-plan-name">{productName}</span>
                        <span className="register-plan-price">
                            {productPrice > 0 ?
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    displayType="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    value={productPrice}
                                    className="form-control undefined"
                                    renderText={value =>
                                        <div style={{ whiteSpace: "nowrap" }}>
                                            {value}
                                        </div>
                                    }
                                    suffix={" €"}
                                />
                                :
                                <span style={{ color: "green" }}>Gratis</span>
                            }
                        </span>
                    </div>
                )
            }
        }
    }

    const buildPriceDescriptionForRegister = () => {
        let desc = "Tu plan se facturará mensualmente por un importe de "
        const formattedTotalPrice = totalPrice.toLocaleString('es-ES', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        desc = desc + formattedTotalPrice
        for (const stripeProduct of stripeProducts.includedProducts) {
            desc = desc + " más "
            const formattedIncludedPrice = stripeProduct.price.toLocaleString('es-ES', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            desc = desc + formattedIncludedPrice + " por albarán "
        }
        desc = desc + " (más IVA)."
        return desc
    }

    const buildPriceDescriptionForChangePlan = () => {
        let desc = buildPriceDescriptionForRegister()
        desc = desc + " Las diferencias de precio debido a los cambios en su suscripción se ajustarán mediante prorrateo. Para más información consulte 'Gestionar suscripción' después de actualizar el plan"
        return desc
    }

    return (

        <div style={{ flex: 1, minHeight: "1000px" }}>

            <div className="register-contact-sales">
                <p>¿No te decides? ¿Necesitas ayuda? Habla con nosotros:</p>
                <p>
                    <i className="fa fa-phone phone-icon" style={{ fontSize: "13px" }}></i>
                    <span style={{ marginLeft: "8px", marginRight: "8px" }}>+34 624 088 293</span>
                    <a href="https://wa.me/34624088293" target="_blank" rel="noopener noreferrer">
                        <i className="fa-brands fa-whatsapp" style={{ color: '#25D366', fontSize: "16px" }}></i>
                    </a>
                </p>
            </div>

            <p className="register-header-text">Precios adaptados a tus necesidades</p>

            {props.from === "register" &&
                <p className="register-subheader-text">
                    Todos los planes incluyen 30 días gratis. Empieza ahora, sin límites y sin tarjeta de crédito
                </p>
            }

            {(appLoading && stripeProducts.products.length === 0) ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
                    <span
                        className="spinner-border"
                        style={{
                            width: '3rem',
                            height: '3rem'
                        }}
                    >
                    </span>
                </div>
                :
                <div>
                    <div className="register-cards-container">
                        {stripeProducts?.products.map((modalidad, index) => {
                            const metadataKeys = Object.keys(modalidad.metadata);
                            return (
                                <div
                                    key={index}
                                    style={{ marginTop: "60px" }}
                                    className={"register-card " + (pricesId.includes(modalidad.priceId) ? 'selected' : '')}
                                    onClick={() => {
                                        const productPriceIds = stripeProducts.products.map(product => product.priceId);
                                        const filteredPricesId = pricesId.filter(item => !productPriceIds.includes(item));
                                        setPricesId([...filteredPricesId, modalidad.priceId])
                                    }}
                                >
                                    <div className="register-card-header">
                                        <h3 style={{ backgroundColor: stripeProductsColors[index % stripeProductsColors.length] }}>{modalidad.name}</h3>
                                    </div>
                                    <NumberFormat
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        displayType="text"
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        value={modalidad.price}
                                        renderText={value =>
                                            <p id="firstMetadataKey">
                                                {value}
                                            </p>
                                        }
                                        suffix={" € / mes"}
                                    />

                                    {stripeProducts?.includedProducts.map((includedProduct, index) => {
                                        const descMetadataKey = includedProduct.metadata["desc"] || null;
                                        return (
                                            <div key={index}>
                                                <NumberFormat
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    displayType="text"
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    allowNegative={false}
                                                    value={includedProduct.price}
                                                    renderText={value => (
                                                        <p id="secondMetadataKey">
                                                            {value}
                                                        </p>
                                                    )}
                                                    prefix={"+ "}
                                                    suffix={" € " + descMetadataKey}
                                                />
                                            </div>
                                        )
                                    })}


                                    <ul>
                                        {metadataKeys.map((key, idx) => {
                                            const value = modalidad.metadata[key];
                                            const [booleanPart, ...rest] = value.split(".");
                                            const isTrue = booleanPart === "true";
                                            const tooltipText = rest.join(".").trim();
                                            return (
                                                <li key={idx}>
                                                    <div
                                                        data-tip={tooltipText}
                                                        data-class="register-support-custom-tooltip"
                                                        style={{
                                                            color: isTrue ? "inherit" : "#ccc",
                                                            textDecoration: tooltipText ? "underline dotted" : "none",
                                                            display: "inline",
                                                            whiteSpace: "nowrap"
                                                        }}
                                                    >
                                                        {key}
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>

                    {stripeProducts?.optionalProducts.map((modalidad, index) => {
                        const title = modalidad.metadata?.title;
                        const alternative = modalidad.metadata?.alternative;
                        const icon = modalidad.metadata?.icon;
                        const iconAlternative = modalidad.metadata?.iconAlternative;
                        if (title && title.length > 0) {
                            return (
                                <div key={index}>
                                    <p className="register-support-text">{title}</p>
                                    <div className="register-cards-container-horizontal">
                                        <div
                                            key={`${index}-left`}
                                            className={
                                                "register-card-horizontal register-optional-left-card " +
                                                (!pricesId.includes(modalidad.priceId) ? 'selected' : '')
                                            }
                                            onClick={() => {
                                                setPricesId(pricesId.filter(id => id !== modalidad.priceId))
                                            }}
                                        >
                                            <div className="register-card-icon">
                                                <i className={iconAlternative}></i>
                                            </div>
                                            <div className="register-card-info">
                                                <h3>
                                                    {alternative}
                                                    <i
                                                        className="fa fa-info-circle"
                                                        style={{ marginLeft: '5px', fontSize: '0.8em' }}
                                                        data-tip="Recibe asistencia únicamente por email. Nuestro equipo de soporte resolverá tus dudas por orden de llegada."
                                                        data-class="register-support-custom-tooltip"
                                                    ></i>
                                                </h3>
                                                <p>Gratis</p>
                                            </div>
                                        </div>
                                        <div
                                            key={`${index}-right`}
                                            className={
                                                "register-card-horizontal register-optional-right-card " +
                                                (pricesId.includes(modalidad.priceId) ? 'selected' : '')
                                            }
                                            onClick={() => {
                                                if (!pricesId.includes(modalidad.priceId)) {
                                                    setPricesId([...pricesId, modalidad.priceId])
                                                }
                                            }}
                                        >
                                            <div className="register-card-icon">
                                                <i className={icon}></i>
                                            </div>
                                            <div className="register-card-info">
                                                <h3>
                                                    {modalidad.name}
                                                    <i
                                                        className="fa fa-info-circle"
                                                        style={{ marginLeft: '5px', fontSize: '0.8em' }}
                                                        data-tip="Recibe atención personalizada por email, teléfono y whatsapp. Nuestro equipo experto resolverá tus necesidades con prioridad exclusiva."
                                                        data-class="register-support-custom-tooltip"
                                                    ></i>
                                                </h3>
                                                <NumberFormat
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    displayType="text"
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    allowNegative={false}
                                                    value={modalidad.price}
                                                    className="form-control undefined"
                                                    renderText={value =>
                                                        <div style={{ whiteSpace: "nowrap" }}>
                                                            {value}
                                                        </div>
                                                    }
                                                    suffix={" € / mes"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })}


                    {props.from !== "changePlan" &&
                        <p className="register-possibilitiesText" style={{ color: '#6c757d' }}>
                            * Podrás modificar todas las opciones seleccionadas en cualquier momento.
                        </p>
                    }

                    <div className="register-pricing-summary-container">
                        <div className="register-pricing-summary">
                            {props.from === "register" ?
                                <div className="register-header">
                                    <span className="register-discount-badge">30 DÍAS GRATIS</span>
                                    <br></br>
                                    <span className="register-discount-badge">Sin tarjetas de crédito</span>
                                </div>
                                :
                                <div className="register-header">
                                    <span className="register-discount-badge">RESUMEN</span>
                                </div>
                            }
                            <div className="register-plan-details">
                                {props.from === "changePlan" &&
                                    <>
                                        {showOldProductsForChangePlan()}
                                        {showOldOptionalProductsForChangePlan()}
                                    </>
                                }
                                {showProductsForResumen()}
                                {showOptionalProductsForResumen()}
                            </div>
                            <div className="register-total">
                                <div className="register-total-price">
                                    <span>Total</span>
                                    <span>
                                        <NumberFormat
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            displayType="text"
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            value={totalPrice}
                                            className="form-control undefined"
                                            renderText={value =>
                                                <div style={{ whiteSpace: "nowrap" }}>
                                                    {value}
                                                </div>
                                            }
                                            suffix={" €"}
                                        />
                                    </span>
                                </div>

                                {stripeProducts?.includedProducts.map((productIncluded) => {
                                    return (
                                        <div
                                            className="register-total-price"
                                            key={productIncluded.priceId}
                                            style={{ textAlign: "end" }}
                                        >
                                            <span style={{ flex: 1 }}>
                                                <NumberFormat
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    displayType="text"
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    allowNegative={false}
                                                    value={productIncluded.price}
                                                    className="form-control undefined"
                                                    renderText={value =>
                                                        <div style={{ whiteSpace: "nowrap" }}>
                                                            {value}
                                                        </div>
                                                    }
                                                    suffix={" € por albarán"}
                                                    prefix={"+ "}
                                                />
                                            </span>
                                        </div>
                                    );
                                })}

                            </div>
                            <div className="register-footer">
                                {(props.from === "newPlan" || props.from === "register") ?
                                    <p>{buildPriceDescriptionForRegister()}</p>
                                    :
                                    <p>{buildPriceDescriptionForChangePlan()}</p>
                                }
                                <p>Si necesita ayuda contacte con soporte.</p>
                            </div>
                        </div>
                    </div>

                    {props.from === "changePlan" &&
                        <div className="changePlanText">
                            No podrá cambiar de plan en los próximos 14 días
                        </div>
                    }

                    {(props.callbackContinue) &&
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '100px',
                            marginTop: '-40px'
                        }}>
                            <button
                                className={"btn btn-primary btn-user btn-block register-button"}
                                disabled={(pricesId.length === 0)}
                                type="button"
                                onClick={() => {
                                    if (props.callbackContinue) {
                                        props.callbackContinue(pricesId)
                                    }
                                }}
                            >
                                <span>Aceptar</span>
                            </button>
                        </div>
                    }

                    {(props.callbackGoBack) &&
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '100px',
                            marginTop: '-40px'
                        }}>
                            <button
                                className={"btn btn-primary btn-user btn-block register-button"}
                                disabled={(pricesId.length === 0)}
                                type="button"
                                onClick={() => {
                                    if (props.callbackGoBack) {
                                        props.callbackGoBack()
                                    }
                                }}
                            >
                                <span>¡Probar!</span>
                            </button>
                        </div>
                    }
                </div>
            }

            <ReactTooltip />

        </div>

    );
}

export default RegisterPlans;