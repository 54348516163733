import React, { Fragment, ChangeEvent, ReactElement } from "react";
import {
    controlVehicleTypes, getFormattedDate, validateAlphaNumeric,
    validateFormEmpty, CapitalizefirstLetter, formStates, Periodicidades
} from "aseguisShared";

interface infoParamVehiclesControlsTypesList {
    allControlsTypes: controlVehicleTypes[]
    formControlType: controlVehicleTypes
    setFormControlType: (formControlType: controlVehicleTypes) => void

    formState: number
    isEdditing : number
}

function ParamVehiclesControlsTypesList(props: infoParamVehiclesControlsTypesList): ReactElement {

    const listControlTypes: JSX.Element[] = [...props.allControlsTypes].reverse().map((controlType) => {

        if (controlType && props.formControlType && controlType.id === props.formControlType.id && props.formState === formStates.edit) {
            return (
                <tr className="table-row" key={controlType.id}>
                    <td>
                        <label>{getFormattedDate(controlType.fecAlta)}</label>
                    </td>
                    <td><input
                        type="text"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                            props.setFormControlType({
                                ...props.formControlType,
                                description: value
                            });
                        }}
                        value={props.formControlType.description}
                        className={validateFormEmpty(props.formControlType.description, 2)}
                        name="description" />
                    </td>
                    <td>
                        <select
                            id="periodicidad"
                            className="form-control undefined"
                            value={props.formControlType.periodicidad}
                            onChange={(value) => {
                                props.setFormControlType({
                                    ...props.formControlType,
                                    periodicidad: value.target.value
                                });
                            }}
                        >
                            <option className="form-control undefined" value={Periodicidades.diaria}> {Periodicidades.diaria} </option>
                            <option className="form-control undefined" value={Periodicidades.semanal}> {Periodicidades.semanal} </option>
                            <option className="form-control undefined" value={Periodicidades.quincenal}> {Periodicidades.quincenal} </option>
                            <option className="form-control undefined" value={Periodicidades.mensual}> {Periodicidades.mensual} </option>
                            <option className="form-control undefined" value={Periodicidades.trimestral}> {Periodicidades.trimestral} </option>
                            <option className="form-control undefined" value={Periodicidades.semestral}> {Periodicidades.semestral} </option>
                            <option className="form-control undefined" value={Periodicidades.anual}> {Periodicidades.anual} </option>
                        </select>
                    </td>
                </tr>
            )
        }
        else {
            return (
                <tr className={`table-row ${(controlType && props.formControlType && controlType.id === props.formControlType.id) ? "selected" : ""}`}
                    onClick={() => {
                        props.setFormControlType(controlType);
                    }}
                    key={controlType.id}>
                    <td>{getFormattedDate(controlType.fecAlta)}</td>
                    <td scope="row"><b>{controlType.description}</b></td>
                    <td>{controlType.periodicidad}</td>
                </tr>
            )
        }
    });


    return (

        <Fragment>

            <div className="card-body">

                <div className="row">
                    
                    <div className="form-group col">
                        <h6 className="mb-2 font-weight-bold text-green">Historial</h6>

                        <div className="table-responsive portlet-600"
                        >

                            {(
                                (props.formState === formStates.none && props.allControlsTypes.length > 0) ||
                                (props.formState === formStates.edit) || (props.formState === formStates.add)
                            ) ?
                            <table className="table">

                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Fecha de alta</th>
                                        <th scope="col">Descripción</th>
                                        <th scope="col">Periodicidad</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {props.formState === formStates.add &&
                                        <tr className="table-row">
                                            <td>
                                                <label>{getFormattedDate()}</label>
                                            </td>
                                            <td><input
                                                type="text"
                                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                    var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                                    props.setFormControlType({
                                                        ...props.formControlType,
                                                        description: value
                                                    });
                                                }}
                                                value={props.formControlType.description}
                                                className={validateFormEmpty(props.formControlType.description, 2)}
                                                name="description" />
                                            </td>
                                            <td>
                                                <select
                                                    id="plazoEntrega"
                                                    className="form-control undefined"
                                                    value={props.formControlType.periodicidad}
                                                    onChange={(value) => {
                                                        props.setFormControlType({
                                                            ...props.formControlType,
                                                            periodicidad: value.target.value
                                                        });
                                                    }}
                                                >
                                                    <option className="form-control undefined" value={Periodicidades.diaria}> {Periodicidades.diaria} </option>
                                                    <option className="form-control undefined" value={Periodicidades.semanal}> {Periodicidades.semanal} </option>
                                                    <option className="form-control undefined" value={Periodicidades.quincenal}> {Periodicidades.quincenal} </option>
                                                    <option className="form-control undefined" value={Periodicidades.mensual}> {Periodicidades.mensual} </option>
                                                    <option className="form-control undefined" value={Periodicidades.trimestral}> {Periodicidades.trimestral} </option>
                                                    <option className="form-control undefined" value={Periodicidades.semestral}> {Periodicidades.semestral} </option>
                                                    <option className="form-control undefined" value={Periodicidades.anual}> {Periodicidades.anual} </option>
                                                </select>
                                            </td>
                                        </tr>
                                    }

                                    {listControlTypes}

                                </tbody>

                            </table>

                            : <div>No se han dado de alta tipos de controles</div>
                            }
                            
                        </div>
                    </div>
                   
                </div>

            </div>      

        </Fragment>

    );

}


export default ParamVehiclesControlsTypesList;