import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";
import { ApplicationState } from '../../ApplicationState';
import { addingOrEditing, parametrizacion, initialVehicleType, suscriptionData } from "aseguisShared"
import { GetSuscriptionDataFromLocalStorage, GetUserPermissionsFromLocalStorage } from "./../../common/commonInterfaces/commonFunctions"
import Buttons from "./../../common/Buttons/Buttons";
import {
    saveParametrizacion, saveParametrizacionForms,
    saveParamEditForm, requestParametrizacion
} from "./ParametrizacionStore";
import ParamVehiclesControlsTypes from "../../components/Vehicles/VehiclesSettings/VehiclesParamControlsTypes";
import VehiclesTypes from "../../components/Vehicles/VehiclesSettings/VehiclesTypes";
import { Redirect, Route, Switch } from "react-router-dom";
import { allAppRoutes } from "./../../common/commonInterfaces/allAppRoutes";
import CompanyLogo from "../../components/Company/CompanyLogo";
import IncidencesTypes from "./SettingsIncidences/IncidencesTypes";
import OptionButton from "../../common/Inputs/OptionButton";


const Parametrizacion: React.FC = () => {

    const dispatch: React.Dispatch<any> = useDispatch();

    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()
    let { permissions } = GetUserPermissionsFromLocalStorage()

    const paramForm: parametrizacion = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm);
    const paramEditForm: parametrizacion = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionEditForm);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);
    const [vehicleType, setVehicleType] = useState(initialVehicleType);
    const [editEnabled, setEditEnabled] = useState(addingOrEditing.none);
    const [errorOnVehiclesTypes, setErrorOnVehiclesTypes] = useState(false);
    const [errorOnVehiclesControlsTypes, setErrorOnVehiclesControlsTypes] = useState(false);

    function saveEditedparam(): void {
        if (errorOnVehiclesTypes === false && errorOnVehiclesControlsTypes === false) {
            setEditEnabled(addingOrEditing.none);
            dispatch(saveParametrizacion(paramEditForm));
        }
    }

    function restoreParamEdit(): void {
        dispatch(saveParametrizacionForms(paramForm));
        setVehicleType(initialVehicleType)
    }

    useEffect(() => {
        dispatch(requestParametrizacion());
    }, []);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    return (

        <Fragment>

            {permissions.isAdmin === true &&
                <div className="row">
                    <div className="form-group col-md-3">
                        <OptionButton
                            route={allAppRoutes.settingsVehicleTypes}
                            text={"Tipos de vehículos"}
                            linkTo={allAppRoutes.settingsVehicleTypes}
                        />
                    </div>

                    {(suscriptionData.controlesVehiculos) &&
                        < div className="form-group col-md-3">
                            <OptionButton
                                route={allAppRoutes.settingsVehicleControlsTypes}
                                text={"Tipos de controles vehículos"}
                                linkTo={allAppRoutes.settingsVehicleControlsTypes}
                            />
                        </div>
                    }

                    <div className="form-group col-md-3">
                        <OptionButton
                            route={allAppRoutes.settingsIncidencias}
                            text={"Tipos de incidencias"}
                            linkTo={allAppRoutes.settingsIncidencias}
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <OptionButton
                            route={allAppRoutes.settingsSuscription}
                            text={"Suscripción, Color y Logo"}
                            linkTo={allAppRoutes.settingsSuscription}
                        />
                    </div>
                </div>
            }

            {permissions.isAdmin === true &&
                <Switch>

                    <Route path={allAppRoutes.settingsVehicleTypes}>
                        <VehiclesTypes
                            setVehiclesTypes={(vehiclesTypes) => {
                                dispatch(saveParamEditForm({
                                    ...paramEditForm,
                                    vehiclesTypes: vehiclesTypes
                                }));
                            }}
                            editVehiclesTypes={paramEditForm.vehiclesTypes}
                            OriginalVehiclesTypes={paramForm.vehiclesTypes}
                            isEdditing={editEnabled}
                            vehicleType={vehicleType}
                            setVehicleType={setVehicleType}
                            setErrorForm={setErrorOnVehiclesTypes}
                            errorForm={errorOnVehiclesTypes}
                        />
                    </Route>

                    {(suscriptionData.controlesVehiculos) &&
                        <Route path={allAppRoutes.settingsVehicleControlsTypes}>
                            <ParamVehiclesControlsTypes
                                isEdditing={editEnabled}
                                allControlTypes={editEnabled === addingOrEditing.edditing ? paramEditForm.vehiclesControlesTypes : paramForm.vehiclesControlesTypes}
                                addControlType={(controlType) => {
                                    dispatch(saveParamEditForm({
                                        ...paramEditForm,
                                        vehiclesControlesTypes: [...paramEditForm.vehiclesControlesTypes, { ...controlType }]
                                    }));
                                }}
                                saveExistingControlType={(controlType) => {
                                    dispatch(saveParamEditForm({
                                        ...paramEditForm,
                                        vehiclesControlesTypes: paramEditForm.vehiclesControlesTypes.map((controlType2) => {
                                            if (controlType.id === controlType2.id) {
                                                return controlType
                                            }
                                            return controlType2;
                                        }),
                                    }));
                                }}
                                deleteControlType={(controlType) => {
                                    dispatch(saveParamEditForm({
                                        ...paramEditForm,
                                        vehiclesControlesTypes: paramEditForm.vehiclesControlesTypes.filter(control => control.id !== controlType.id)
                                    }));
                                }}
                                setErrorForm={setErrorOnVehiclesControlsTypes}
                                errorForm={errorOnVehiclesControlsTypes}
                            />
                        </Route>
                    }

                    <Route path={allAppRoutes.settingsIncidencias}>
                        <IncidencesTypes
                            isEdditing={editEnabled}
                            allIncidencesTypes={editEnabled === addingOrEditing.edditing ? paramEditForm.incidencesTypes : paramForm.incidencesTypes}
                            addIncidenceType={(incidenceType) => {
                                dispatch(saveParamEditForm({
                                    ...paramEditForm,
                                    incidencesTypes: [...paramEditForm.incidencesTypes, { ...incidenceType }]
                                }));
                            }}
                            saveExistingIncidenceType={(incidenceType) => {
                                dispatch(saveParamEditForm({
                                    ...paramEditForm,
                                    incidencesTypes: paramEditForm.incidencesTypes.map((incidenceType2) => {
                                        if (incidenceType.id === incidenceType2.id) {
                                            return incidenceType
                                        }
                                        return incidenceType2;
                                    }),
                                }));
                            }}
                            deleteIncidenceType={(incidenceType) => {
                                dispatch(saveParamEditForm({
                                    ...paramEditForm,
                                    incidencesTypes: paramEditForm.incidencesTypes.filter(incidence => incidence.id !== incidenceType.id)
                                }));
                            }}
                            setErrorForm={setErrorOnVehiclesControlsTypes}
                            errorForm={errorOnVehiclesControlsTypes}
                        />
                    </Route>

                    <Route exact path={allAppRoutes.settingsSuscription}>
                        <CompanyLogo
                            isEdditing={editEnabled}
                        />
                    </Route>

                    <Redirect from="*" to={allAppRoutes.settingsVehicleTypes} />

                </Switch>
            }

            {/*BOTONES*/}
            <Buttons
                addOrEdit={editEnabled}
                editEnabled={editEnabled}
                setEditEnabled={setEditEnabled}
                save={saveEditedparam}
                from="ajustes"
                thereIsList={false}
                restoreForm={restoreParamEdit}
                disableSaveIfError={(errorOnVehiclesTypes === true || errorOnVehiclesControlsTypes === true) ? true : false}
            />

        </Fragment >

    );
};

export default Parametrizacion;
