import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import TrackingTemplate from "./TrackingTemplate";
import { FaBox, FaTruck, FaShippingFast, FaCheck, FaExclamationCircle, FaFilePdf, FaSpinner } from 'react-icons/fa';
import { dateTimesEstado, estadoTrazaEnum } from "aseguisShared";
import imageLogo from "../../assets/tsp/TSP_logo.jpg";

interface trackingResponse {
    idMongo: string;
    fechaAlbaran: string;
    previsionEntrega: string;
    estado: number;
    direccionEntrega: string;
    nombreEntrega: string;
    referencias: string;
    bultos: string;
    kilos: string;
    observaciones: string;
    dateTimesEstadoTraza: dateTimesEstado[];
    hasDeliveries: boolean;
}

const TrackingShipment = () => {

    const { tenantId, clientId, shipmentID } = useParams<{ tenantId: string, clientId: string, shipmentID: string }>();

    const [isLoading, setIsLoading] = useState(true)
    const [trackingData, setTrackingData] = useState<trackingResponse | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await axios.post<trackingResponse>('api/tracking/getAlbaran/', {
                    tenantId: tenantId,
                    clientId: clientId,
                    shipmentNumber: shipmentID
                });
                //si está entregada y no hay timestamp en reparto, ponemos a reparto la fecha de entregada
                const data = response.data;
                if (data.estado === estadoTrazaEnum.entregada) {
                    const fechaEntregado = getEarliestDateForState(estadoTrazaEnum.entregada, data.dateTimesEstadoTraza);
                    const existeEnReparto = data.dateTimesEstadoTraza.some(item => item.estado === estadoTrazaEnum.enTransito);
                    if (fechaEntregado && !existeEnReparto) {
                        data.dateTimesEstadoTraza.push({
                            dateTime: fechaEntregado,
                            estado: estadoTrazaEnum.enTransito
                        });
                    }
                }
                setTrackingData(data);
            } catch (error) {
                console.error("Error fetching tracking data: ", error);
                setTrackingData(null);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData()
            .catch(console.error);
    }, [shipmentID, clientId, tenantId]);

    const DownloadAttached = async () => {
        try {
            const response = await axios.post('api/tracking/download/',
                {
                    tenantId: tenantId,
                    clientId: clientId,
                    shipmentNumber: shipmentID
                },
                {
                    responseType: 'blob'
                }
            );
            window.open(URL.createObjectURL(response.data));
        } catch (error) {
            console.error('Failed to download PDF:', error);
        }
    };

    const getStatusIcon = (status: number, currentState: estadoTrazaEnum) => {
        const isActive = status <= currentState;
        const iconStyle = {
            opacity: isActive ? 1 : 0.3,  // Más transparente si no es activo
        };
        switch (status) {
            case 0:
                return <FaTruck style={iconStyle} />;
            case estadoTrazaEnum.introducida:
                return <FaBox style={iconStyle} />;
            case estadoTrazaEnum.enTransito:
                return <FaShippingFast style={iconStyle} />;
            case estadoTrazaEnum.entregada:
                return <FaCheck style={iconStyle} />;
            default:
                return <FaExclamationCircle style={iconStyle} />;
        }
    };

    const getStatusTextWithStyle = (status: number, currentState: estadoTrazaEnum) => {
        const isActive = status <= currentState;
        const iconStyle = {
            opacity: isActive ? 1 : 0.3,  // Más transparente si no es activo
        };
        switch (status) {
            case 0:
                return <p style={iconStyle}>{getStatusText(status)}</p>;
            case estadoTrazaEnum.introducida:
                return <p style={iconStyle}>{getStatusText(status)}</p>;
            case estadoTrazaEnum.enTransito:
                return <p style={iconStyle}>{getStatusText(status)}</p>;
            case estadoTrazaEnum.entregada:
                return <p style={iconStyle}>{getStatusText(status)}</p>;
            case estadoTrazaEnum.enPause:
                return <p style={iconStyle}>{getStatusText(status)}</p>;
            default:
                return <p style={iconStyle}>-</p>;
        }
    };

    const getStatusText = (status: number) => {
        switch (status) {
            case 0:
                return <span>En camino</span>;
            case estadoTrazaEnum.introducida:
                return <span>Confirmado</span>;
            case estadoTrazaEnum.enTransito:
                return <span>En reparto</span>;
            case estadoTrazaEnum.entregada:
                return <span>Entregado</span>;
            case estadoTrazaEnum.enPause:
                return <span>En pause</span>;
            default:
                return <span>-</span>;
        }
    };

    const getProgressPercentage = (estado: estadoTrazaEnum) => {
        switch (estado) {
            case estadoTrazaEnum.none:
                return 0;
            case estadoTrazaEnum.introducida:
                return 50;
            case estadoTrazaEnum.enTransito:
                return 75;
            case estadoTrazaEnum.entregada:
                return 100;
            default:
                return 0;
        }
    };

    const getEarliestDateForState = (estado: number, dateTimesEstadoTraza: dateTimesEstado[]): Date | null => {
        const datesForState = dateTimesEstadoTraza
            .filter(item => item.estado === estado)
            .map(item => new Date(item.dateTime));

        if (datesForState.length === 0) {
            return null;
        }

        return datesForState.reduce((earliest, current) => {
            return current < earliest ? current : earliest;
        });
    };

    const formatDate = (date: Date | null): string => {
        if (!date) return '-';
        return date.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };


    return (

        <TrackingTemplate>
            <div style={{ display: 'flex', justifyContent: 'center', maxWidth: "1000px", fontFamily: 'Arial, sans-serif' }}>
                {!isLoading ? (
                    trackingData ? (
                        <div style={{ backgroundColor: 'white', marginTop: '20px', width: '100%' }}>

                            <img src={imageLogo} alt="Logo" className="register-logo" />

                            <div style={{ padding: '20px', marginTop: '10px' }}>
                                <p style={{ color: '#333', textAlign: 'center' }}><strong>Número de seguimiento:</strong> <a target="_blank" rel="noopener noreferrer" style={{ color: '#007bff', textDecoration: 'none' }}>{shipmentID}</a></p>
                            </div>
                            <div style={{ marginBottom: '30px', position: 'relative' }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    position: 'relative',
                                    padding: '0 20px'
                                }}>
                                    <div style={{ textAlign: 'center', position: 'relative', zIndex: 1 }}>
                                        {getStatusIcon(estadoTrazaEnum.introducida, trackingData.estado)}
                                        <div style={{ whiteSpace: "nowrap" }}>
                                            {getStatusTextWithStyle(estadoTrazaEnum.introducida, trackingData.estado)}
                                        </div>
                                        <p>{formatDate(getEarliestDateForState(estadoTrazaEnum.introducida, trackingData.dateTimesEstadoTraza))}</p>
                                    </div>
                                    <div style={{ textAlign: 'center', position: 'relative', zIndex: 1 }}>
                                        {getStatusIcon(0, trackingData.estado)}
                                        <div style={{ whiteSpace: "nowrap" }}>
                                            {getStatusTextWithStyle(0, trackingData.estado)}
                                        </div>
                                        <p>{formatDate(getEarliestDateForState(estadoTrazaEnum.introducida, trackingData.dateTimesEstadoTraza))}</p>
                                    </div>
                                    <div style={{ textAlign: 'center', position: 'relative', zIndex: 1 }}>
                                        {getStatusIcon(estadoTrazaEnum.enTransito, trackingData.estado)}
                                        <div style={{ whiteSpace: "nowrap" }}>
                                            {getStatusTextWithStyle(estadoTrazaEnum.enTransito, trackingData.estado)}
                                        </div>
                                        <p>{formatDate(getEarliestDateForState(estadoTrazaEnum.enTransito, trackingData.dateTimesEstadoTraza))}</p>
                                    </div>
                                    <div style={{ textAlign: 'center', position: 'relative', zIndex: 1 }}>
                                        {getStatusIcon(estadoTrazaEnum.entregada, trackingData.estado)}
                                        <div style={{ whiteSpace: "nowrap" }}>
                                            {getStatusTextWithStyle(estadoTrazaEnum.entregada, trackingData.estado)}
                                        </div>
                                        <p>{formatDate(getEarliestDateForState(estadoTrazaEnum.entregada, trackingData.dateTimesEstadoTraza))}</p>
                                    </div>
                                </div>
                                {/* Línea de tiempo */}
                                <div style={{
                                    position: 'absolute',
                                    top: '50%',
                                    /*left: '20px',*/
                                    right: '20px',
                                    height: '2px',
                                    backgroundColor: '#ddd',
                                    zIndex: 0,
                                    transform: 'translateY(-50%)'
                                }}></div>
                                {/* Línea activa */}
                                <div style={{
                                    position: 'absolute',
                                    top: '50%',
                                    /*left: '20px',*/
                                    width: `${getProgressPercentage(trackingData.estado)}%`,
                                    height: '2px',
                                    backgroundColor: '#4caf50',
                                    zIndex: 1,
                                    transform: 'translateY(-50%)',
                                    transition: 'width 0.4s ease'
                                }}></div>
                            </div>
                            <div style={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '5px', marginBottom: '20px' }}>
                                <p style={{ color: 'green', fontSize: '18px', display: 'flex', alignItems: 'center' }}>
                                    <strong>{"Estado: "}</strong> &nbsp; {getStatusText(trackingData.estado === estadoTrazaEnum.introducida ? 0 : trackingData.estado)} &nbsp; {getStatusIcon(trackingData.estado === estadoTrazaEnum.introducida ? 0 : trackingData.estado, trackingData.estado === estadoTrazaEnum.introducida ? 0 : trackingData.estado)}
                                    {(trackingData.hasDeliveries && trackingData.estado === estadoTrazaEnum.entregada && trackingData.idMongo != null && trackingData.idMongo.length > 0) &&
                                        <button
                                            style={{ display: 'flex', alignItems: 'center', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', color: '#d9534f', fontSize: '18px' }}
                                            onClick={() => {
                                                DownloadAttached()
                                            }}
                                        >
                                            <FaFilePdf style={{ marginLeft: '15px' }} />
                                        </button>
                                    }
                                </p>
                                <p style={{ color: '#333' }}><strong>Fecha de admisión:</strong> {trackingData.fechaAlbaran || "-"}</p>
                                <p style={{ color: '#333' }}><strong>Previsión de entrega:</strong> {trackingData.previsionEntrega || "-"}</p>
                                <p style={{ color: '#333' }}><strong>Lugar de entrega:</strong> {(trackingData.nombreEntrega + ", " + trackingData.direccionEntrega) || "-"}</p>
                                <p style={{ color: '#333' }}><strong>Referencia/s:</strong> {trackingData.referencias || "-"}</p>
                                <p style={{ color: '#333' }}><strong>Bultos:</strong> {trackingData.bultos || "-"}</p>
                                <p style={{ color: '#333' }}><strong>Kilos:</strong> {trackingData.kilos || "-"}</p>
                                <p style={{ color: '#333' }}><strong>Observaciones:</strong> {trackingData.observaciones || "-"}</p>
                            </div>
                        </div>
                    ) : (
                        <p style={{ color: 'red', textAlign: 'center', fontSize: '18px' }}>No hay datos de seguimiento disponibles.</p>
                    )
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '16px' }}>
                        Buscando envío, por favor espera... <FaSpinner style={{ marginLeft: '10px' }} />
                    </div>
                )}
            </div>

        </TrackingTemplate>
    );
}


export default TrackingShipment;
