"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inicialiceClientsAutocompleteRequest = exports.inicialiceClientValidateFieldsResult = exports.inicialiceClient = void 0;
const commonEnums_1 = require("../enums/commonEnums");
const commonFunctions_1 = require("./commonFunctions");
const postalCodeFunctions_1 = require("./postalCodeFunctions");
const tariffFunctions_1 = require("./tariffFunctions");
exports.inicialiceClient = {
    _id: null,
    fecAlta: new Date(),
    id: 0,
    nif: "",
    nombre: "",
    razonSocial: "",
    address: {
        calle: "",
        numero: "",
        puerta: "",
        localidad: "",
        codigoPostal: "",
        provincia: "",
        pais: "",
        vicinity: "",
        formatedAddress: "",
        otherName: "",
        placeId: "",
        url: "",
        cpMongo: postalCodeFunctions_1.inicializePostalCode
    },
    tel1: "",
    tel2: "",
    email: "",
    emailFacturacion: "",
    porIva: 0,
    tariff: tariffFunctions_1.inicialiceTariff,
    m3ToKg: 0,
    facturarCargaPaqJunto: true,
    permisosUsuario: commonFunctions_1.inicialicePermisosUsuario,
    plazoEntregaPaq: commonEnums_1.plazosEntrega.null,
    datosEconomicos: {
        ccc: "",
        formaPago: commonEnums_1.formasDePago.transferencia,
        vencimiento: commonEnums_1.vencimientosFacturas.d30,
        diaDePago: "",
        mesesDePagoExcluidos: [],
        descuentosCarga: [],
        descuentosPaqueteria: [],
        incrementosCarga: [],
        incrementosPaqueteria: []
    }
};
exports.inicialiceClientValidateFieldsResult = {
    nifExists: false,
    nombreExists: false,
    razonSocialExists: false,
    emailExists: false,
    tel1Exists: false,
    tel2Exists: false,
    anyFieldExist: false
};
exports.inicialiceClientsAutocompleteRequest = {
    clientText: "",
    clientsToExclude: []
};
