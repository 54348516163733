import React, { Fragment, ReactElement } from "react";
import InputText from "../../common/Inputs/InputText";
import { addingOrEditing, validateAlphaNumeric, validateEmail, validateNumeric, inicialiceSearchFilterList, searchFilterList } from "aseguisShared";
import InputNumber from "../../common/Inputs/InputNumber";

interface infoToSuppliersListFilter {
    searchFilterSuppliers: searchFilterList
    saveSearchFilterSuppliers: (filter: searchFilterList) => void
}
function SuppliersListFilter(props: infoToSuppliersListFilter): ReactElement {

    return (

        <Fragment>

            <div className="form-row" style={{ marginBottom: "-25px" }}>

                <InputNumber
                    small={true}
                    colMesure="2"
                    format="##########"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="ID Proveedor"
                    valueEdit={props.searchFilterSuppliers.id}
                    value={props.searchFilterSuppliers.id}
                    onChange={(value) => {
                        const valueNumber = value.floatValue ? value.floatValue : 0
                        props.saveSearchFilterSuppliers({
                            ...props.searchFilterSuppliers,
                            pageNum: 1,
                            id: valueNumber,
                            withTimeout: true
                        })
                    }}
                />

                <InputText
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="Nif"
                    autoComplete={false}
                    valueEdit={props.searchFilterSuppliers.nif}
                    value={props.searchFilterSuppliers.nif}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                        value = value.trimStart()
                        props.saveSearchFilterSuppliers({
                            ...props.searchFilterSuppliers,
                            pageNum: 1,
                            nif: value,
                            withTimeout: true
                        })
                    }}
                />

                <InputText
                    small={true}
                    colMesure="4"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    autoComplete={false}
                    name="Dirección"
                    valueEdit={props.searchFilterSuppliers.address}
                    value={props.searchFilterSuppliers.address}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilterSuppliers({
                            ...props.searchFilterSuppliers,
                            pageNum: 1,
                            address: value,
                            withTimeout: true
                        })
                    }}
                />

                <InputText
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    autoComplete={false}
                    name="Tel"
                    valueEdit={props.searchFilterSuppliers.tel}
                    value={props.searchFilterSuppliers.tel}
                    onChange={(event) => {
                        var value = validateNumeric(event.target.value)
                        props.saveSearchFilterSuppliers({
                            ...props.searchFilterSuppliers,
                            pageNum: 1,
                            tel: value,
                            withTimeout: true
                        })
                    }}
                />

            </div>

            <div className="form-row" style={{ marginBottom: "-30px" }}>

                <InputText
                    small={true}
                    colMesure="4"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    autoComplete={false}
                    name="Nombre / razón social"
                    valueEdit={props.searchFilterSuppliers.supplierName}
                    value={props.searchFilterSuppliers.supplierName}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilterSuppliers({
                            ...props.searchFilterSuppliers,
                            pageNum: 1,
                            supplierName: value,
                            withTimeout: true
                        })
                    }}
                />

                <InputText
                    small={true}
                    colMesure="4"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    autoComplete={false}
                    name="Email"
                    valueEdit={props.searchFilterSuppliers.email}
                    value={props.searchFilterSuppliers.email}
                    onChange={(event) => {
                        var value = validateEmail(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilterSuppliers({
                            ...props.searchFilterSuppliers,
                            pageNum: 1,
                            email: value,
                            withTimeout: true
                        })
                    }}
                />

                <div className="form-group col-md-2">
                    <br></br>
                    <button
                        className="btn btn-info btn-sm mr-3 mt-2"
                        onClick={() => {
                            props.saveSearchFilterSuppliers({ ...props.searchFilterSuppliers, withTimeout: false })
                        }}
                    >
                        <i className="fas fa-sync"></i>
                    </button>
                    <button className="btn btn-secondary btn-sm mt-2"
                        onClick={() => {
                            props.saveSearchFilterSuppliers(inicialiceSearchFilterList)
                        }}>
                        Limpiar filtro
                        
                    </button>
                </div>

            </div>

        </Fragment >

    );
    
};

export default SuppliersListFilter;
