import React, { Fragment, ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from '../../ApplicationState';
import { parametrizacion } from "aseguisShared";
import ReactTooltip from "react-tooltip";
import ColorPicker from "../../common/Inputs/ColorPicker";
import { saveParamEditForm } from "../Parametrizacion/ParametrizacionStore";
import UploadImage from "../../common/Inputs/UploadImage";
import { requestSuscriptionStatus } from "../../common/globalState/globalStateStore";
import SuscriptionModal from "../../common/Suscription/SuscriptionModal";

interface infoToCompanyForm {
    isEdditing: number,
}

function CompanyLogo(props: infoToCompanyForm): ReactElement {

    const dispatch = useDispatch();

    const paramForm: parametrizacion = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm);
    const paramEditForm: parametrizacion = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionEditForm);

    const [companyLogo64, setCompanyLogo64] = React.useState<string>(paramEditForm.companyLogo)
    const [companySign64, setCompanySign64] = React.useState<string>(paramEditForm.companySign ? paramEditForm.companySign : "")
    const [showSuscriptionModal, setShowSuscriptionModal] = React.useState<boolean>(false)

    useEffect(() => {
        setCompanyLogo64(paramEditForm.companyLogo)
    }, [paramEditForm.companyLogo])

    useEffect(() => {
        dispatch(requestSuscriptionStatus());
    }, [])

    return (
        <Fragment>

            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">Otros Datos</h6>
                </div>
                <div className="card-body">

                    <div className="form-row">

                        <div className="form-group col-md-2">
                            <label style={{ fontWeight: 'bold' }} htmlFor="color1">Color corporativo 1</label>
                            <ColorPicker
                                isEdditing={props.isEdditing}
                                colorForm={paramForm.primaryColor}
                                colorEditForm={paramEditForm.primaryColor}
                                setColor={(primaryColor) => {
                                    dispatch(saveParamEditForm({
                                        ...paramEditForm,
                                        primaryColor: primaryColor
                                    }));
                                }}
                            />
                        </div>

                        <div className="form-group col-md-2">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="input_name">Color corporativo 2</label>
                                <ColorPicker
                                    isEdditing={props.isEdditing}
                                    colorForm={paramForm.secondColor}
                                    colorEditForm={paramEditForm.secondColor}
                                    setColor={(secondColor) => {
                                        dispatch(saveParamEditForm({
                                            ...paramEditForm,
                                            secondColor: secondColor
                                        }));
                                    }}
                                />
                            </div>
                        </div>

                        <div className="form-group col-md-4">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="input_name">Logo corporativo</label>
                                <UploadImage
                                    isEdditing={props.isEdditing}
                                    base64Form={paramForm.companyLogo}
                                    base64EditForm={companyLogo64}
                                    colWidth="12"
                                    setBase64Image={(imageBase64) => {
                                        dispatch(saveParamEditForm({
                                            ...paramEditForm,
                                            companyLogo: imageBase64
                                        }));
                                        setCompanyLogo64(imageBase64)
                                    }}
                                />
                            </div>
                        </div>

                        <div className="form-group col-md-4">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="input_name">Firma y sello
                                    corporativo</label>
                                <UploadImage
                                    isEdditing={props.isEdditing}
                                    base64Form={paramForm.companySign ? paramForm.companySign : ""}
                                    base64EditForm={companySign64}
                                    colWidth="12"
                                    setBase64Image={(imageBase64) => {
                                        dispatch(saveParamEditForm({
                                            ...paramEditForm,
                                            companySign: imageBase64
                                        }));
                                        setCompanySign64(imageBase64)
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                    <label style={{ fontWeight: 'bold' }} htmlFor="input_name">Suscripción</label>

                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-primary btn btn-sm"
                                    onClick={() => {
                                        setShowSuscriptionModal(true)
                                    }}
                                >
                                    Gestionar suscripción
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <ReactTooltip />

            <SuscriptionModal
                showManageSuscriptionModal={showSuscriptionModal}
                setShowManageSuscriptionModal={setShowSuscriptionModal}
            />

        </Fragment>

    );
};

export default CompanyLogo;