import React, { Fragment, useEffect, ReactElement, useState, useRef, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from '../../ApplicationState';
import axios from "axios";
import AutoSuggest from "react-autosuggest";
import Match from 'autosuggest-highlight/match';
import Parse from 'autosuggest-highlight/parse';
import {
    tiposArticuladosVehiculos, initialVehicle, validateAlphaNumeric,
    validateFormEmpty, vehicle, inicialiceCargaCompleta
} from "aseguisShared";

interface infoToVehiclesAutocomplete {
    setVehicle: (vehicle: vehicle) => void
    vehicleName: string
    autoFocus?: boolean
    onBlur?: () => void
    onFocus?: () => void
    setErrorForm?: (value: boolean) => void,
    whatShow: "camiones" | "remolques" | "all"
}

function VehiclesAutocomplete(props: infoToVehiclesAutocomplete): ReactElement {

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const [goToApi, setGoToApi] = useState(false)
    const [inputFocus, setInputFocus] = useState(false)
    const [textToSearch, setTextToSearch] = useState("")
    const [vehiclesSuggestions, setVehiclesSuggestions] = useState<vehicle[]>([])
    const [selectedVehicle, setSelectedVehicle] = useState<vehicle>(inicialiceCargaCompleta.datosGenerales.vehicle)
    const suggestionsContainerRef = useRef<HTMLSpanElement>(null);

    const refFocus = useRef<HTMLInputElement>(null);
    useLayoutEffect(() => {
        if (null !== refFocus.current && props.autoFocus) {
            refFocus.current.focus()
        }
    })

    function onValueChanged(newValue: string): void {
        var value = validateAlphaNumeric(newValue);
        value = value.trimStart()
        setTextToSearch(value)
        if (value.length === 0) {
            setVehiclesSuggestions([]);
        }
    }

    function renderSuggestion(suggestion: vehicle, query: any): JSX.Element {
        const textToHigh = "" + query.query
        const matches = Match(suggestion.matricula.toLowerCase(), textToHigh.toLowerCase(), { insideWords: true });
        const parts = Parse(suggestion.matricula, matches)

        const textToHigh2 = "" + query.query
        const matches2 = Match(suggestion.marca.toLowerCase(), textToHigh2.toLowerCase(), { insideWords: true });
        const parts2 = Parse(suggestion.marca, matches2)

        const textToHigh3 = "" + query.query
        const matches3 = Match(suggestion.modelo.toLowerCase(), textToHigh3.toLowerCase(), { insideWords: true });
        const parts3 = Parse(suggestion.modelo, matches3)

        const textToHigh4 = "" + query.query
        const matches4 = Match(suggestion.vehicleType.description.toLowerCase(), textToHigh4.toLowerCase(), { insideWords: true });
        const parts4 = Parse(suggestion.vehicleType.description, matches4)

        return (
            <div>
                <div>
                    <b>{"Matrícula: "}</b>
                    {parts.map((part, index) => {
                        const className = part.highlight ? 'highlight' : '';
                        return (
                            <span className={className} key={index}>{part.text}</span>
                        );
                    })}
                </div>

                <div>
                    <b>{"Marca: "}</b>
                    {parts2.map((part, index) => {
                        const className = part.highlight ? 'highlight' : '';
                        return (
                            <span className={className} key={index}>{part.text}</span>
                        );
                    })}
                </div>

                <div>
                    <b>{"Modelo: "}</b>
                    {parts3.map((part, index) => {
                        const className = part.highlight ? 'highlight' : '';
                        return (
                            <span className={className} key={index}>{part.text}</span>
                        );
                    })}
                </div>

                <div>
                    <b>{"Tipo vehículo: "}</b>
                    {parts4.map((part, index) => {
                        const className = part.highlight ? 'highlight' : '';
                        return (
                            <span className={className} key={index}>{part.text}</span>
                        );
                    })}
                </div>
            </div>
        );
    }

    useEffect(() => {
        if ((goToApi === true) && (isAppLoading === false) && textToSearch !== '') {
            const timeout2 = setTimeout(() => {
                axios
                    .get<vehicle[]>('api/vehicles/getByString/' + textToSearch)
                    .then((response) => {
                        if (response.data) {
                            setVehiclesSuggestions([])
                            let filteredVehicles: vehicle[] = [];
                            if (props.whatShow === "all") {
                                filteredVehicles = response.data
                            } else {
                                for (var vehicle of response.data) {
                                    if (props.whatShow === "camiones" && vehicle.vehicleType.tractoraRemolque !== tiposArticuladosVehiculos.remolque) {
                                        filteredVehicles.push(vehicle)
                                    }
                                    if (props.whatShow === "remolques" && vehicle.vehicleType.tractoraRemolque === tiposArticuladosVehiculos.remolque) {
                                        filteredVehicles.push(vehicle)
                                    }
                                }
                            }
                            if (filteredVehicles.length === 1) {
                                const oneVehicle = filteredVehicles[filteredVehicles.length - 1]
                                props.setVehicle(oneVehicle)
                                setSelectedVehicle(oneVehicle)
                                setTextToSearch(oneVehicle.matricula)
                            } else {
                                setVehiclesSuggestions(filteredVehicles)
                            }

                        }
                        setGoToApi(false)
                    })
            }, 200);
            return () => { clearTimeout(timeout2) }
        }
    }, [textToSearch])

    useEffect(() => {
        if (inputFocus === false && props.vehicleName !== textToSearch) {
            setTextToSearch(props.vehicleName)
        }
    }, [inputFocus, props.vehicleName]);

    useEffect(() => {
        if (props.setErrorForm) {
            if (
                (props.vehicleName.length < 5)
            ) {
                props.setErrorForm(true)
            } else {
                props.setErrorForm(false)
            }
        }
    }, [props.vehicleName.length])

    return (
        <Fragment>

            <div className="input-group">

                <span ref={suggestionsContainerRef} style={{ flex: "1" }}>
                    <AutoSuggest
                        onSuggestionHighlighted={({ suggestion }) => {
                            if (suggestion && suggestionsContainerRef.current) {
                                const index = vehiclesSuggestions.findIndex(s => s === suggestion);
                                const suggestionElements = suggestionsContainerRef.current.querySelectorAll('.react-autosuggest__suggestion');
                                const highlightedElement = suggestionElements[index];
                                if (highlightedElement) {
                                    highlightedElement.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'nearest'
                                    });
                                }
                            }
                        }}
                        suggestions={vehiclesSuggestions}
                        onSuggestionsClearRequested={() =>
                            setVehiclesSuggestions([])
                        }
                        onSuggestionsFetchRequested={({ value }) => {
                            setGoToApi(true)
                        }}
                        onSuggestionSelected={(_, { suggestion, suggestionValue }) => {
                            setSelectedVehicle(suggestion)
                            props.setVehicle(suggestion)
                        }}
                        getSuggestionValue={(suggestion) =>
                            suggestion.matricula
                        }
                        renderSuggestion={(suggestion, query) =>                           
                            renderSuggestion(suggestion, query)
                        }
                        inputProps={{
                            placeholder: "Busque una matricula para autocompletar...",
                            value: textToSearch,
                            onChange: (event, { newValue, method }) => {
                                onValueChanged(newValue)
                            },
                            type: "text",
                            name: "dest_search",                            
                            className: ((props.setErrorForm) ? validateFormEmpty(props.vehicleName, 5) : "form-control undefined"),
                            autoComplete: "off",
                            onBlur: () => {
                                if (selectedVehicle.matricula.length > 0) {
                                    props.setVehicle(selectedVehicle);
                                } else {
                                    setTextToSearch("")
                                }

                                setInputFocus(false)
                                setGoToApi(false)
                                setVehiclesSuggestions([])

                                if (props.onBlur) {
                                    props.onBlur()
                                }
                            },
                            onFocus: () => {
                                setInputFocus(true)
                                setTextToSearch("")
                                if (props.onFocus) {
                                    props.onFocus()
                                }
                            },
                            ref: refFocus
                        }}
                    />
                </span>

                {props.vehicleName.trim().length > 0 &&
                    <div className="input-group-prepend"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setSelectedVehicle(initialVehicle)
                            props.setVehicle(initialVehicle)
                            setTextToSearch("")
                        }}
                    >
                        <div
                            className="input-group-text"
                            style={{
                                paddingLeft: "2px !important",
                                paddingTop: "0px !important",
                                paddingBottom: "0px !important",
                                paddingRight: "0px !important"
                            }}
                        >
                            <i className="fa-sharp fa-solid fa-delete-left"></i>
                        </div>
                    </div>
                }

            </div>

        </Fragment >
    );
};

export default VehiclesAutocomplete;