import { Action, Dispatch } from 'redux';
import { ApplicationState } from '../../ApplicationState';
import axios from 'axios';
import {
    supplier, inicialiceSupplierValidateFieldsResult, supplierValidateFieldsResult, inicializeSupplier
    , inicialiceSearchFilterList, searchFilterList, loading, addingOrEditing, searchedSuppliers
} from "aseguisShared";
import { Notify } from '../../common/Toastify/Notify';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface suppliersState {
    suppliers: supplier[],
    suppliersAutocomplete: supplier[],
    addOrEditSuppliers: number,
    supplierForm: supplier,
    supplierEditForm: supplier,
    isloadingSuppliers: number,
    searchFilterSuppliers: searchFilterList,
    totalSuppliersList: number
    validateFieldsForm: supplierValidateFieldsResult,
    actualPage: number,
    totalPages: number
}

export const initialState: suppliersState = {
    suppliers: [],
    suppliersAutocomplete: [],
    supplierForm: inicializeSupplier,
    supplierEditForm: inicializeSupplier,
    addOrEditSuppliers: addingOrEditing.none,
    isloadingSuppliers: loading.pending,
    actualPage: 0,
    totalPages: 0,
    searchFilterSuppliers: inicialiceSearchFilterList,
    totalSuppliersList: 0,
    validateFieldsForm: inicialiceSupplierValidateFieldsResult
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface addEditSupplierAction {
    type: 'CHANGE_ADD_EDIT_SUPPLIER',
    addOrEdit: number
}

export interface saveEditFormSupplierAction {
    type: 'SAVE_EDIT_FORM_SUPPLIER',
    supplier: supplier
}

export interface addSupplierReceivedAction {
    type: 'ADD_SUPPLIER_RECEIVED',
    supplier: supplier
}

export interface saveEditSupplierReceivedAction {
    type: 'SAVE_EDIT_SUPPLIER_RECEIVED',
    supplier: supplier
}

export interface RequestSuppliersAction {
    type: 'REQUEST_SUPPLIERS'
}

export interface ReceivedSuppliersAction {
    type: 'RECEIVED_SUPPLIERS',
    suppliers: searchedSuppliers
}

export interface ReceivedAutocompleteSuppliersAction {
    type: 'RECEIVED_AUTOCOMPLETE_SUPPLIER',
    suppliers: supplier[]
}

export interface clearAutocompleteSuppliersAction {
    type: 'CLEAR_AUTOCOMPLETE_SUPPLIER'
}

export interface saveSearchSupplierTextAction {
    type: 'SAVE_SEARCH_FILTER_SUPPLIER',
    searchFilterSupplier: searchFilterList
}

export interface setBothFormsSupplierAction {
    type: 'SET_BOTH_FORMS_SUPPLIER',
    supplier: supplier
}

export interface setValidateFormSupplierAction {
    type: 'SET_VALIDATE_FORM_SUPPLIER',
    form: supplierValidateFieldsResult
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestSuppliersAction | addEditSupplierAction | saveEditSupplierReceivedAction |
    ReceivedSuppliersAction | addSupplierReceivedAction | saveEditFormSupplierAction |
    ReceivedAutocompleteSuppliersAction | clearAutocompleteSuppliersAction | saveSearchSupplierTextAction |
    setBothFormsSupplierAction | setValidateFormSupplierAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export function setFormsSupplier(supplier: supplier): setBothFormsSupplierAction {
    return {
        type: 'SET_BOTH_FORMS_SUPPLIER',
        supplier: supplier
    };
}

export function addOrEditSupplier(addOrEdit: number): addEditSupplierAction {
    return {
        type: 'CHANGE_ADD_EDIT_SUPPLIER',
        addOrEdit: addOrEdit
    };
}

export function saveSupplierEditForm(supplier: supplier): saveEditFormSupplierAction {
    return {
        type: 'SAVE_EDIT_FORM_SUPPLIER',
        supplier: supplier
    };
}

export function clearAutocompleteSuppliers(): clearAutocompleteSuppliersAction {
    return {
        type: 'CLEAR_AUTOCOMPLETE_SUPPLIER'
    };
}

export function saveSearchFilterSupplier(searchClientsFilter: searchFilterList): saveSearchSupplierTextAction {
    return {
        type: 'SAVE_SEARCH_FILTER_SUPPLIER',
        searchFilterSupplier: searchClientsFilter
    };
}

export function setValidateSupplierForm(form: supplierValidateFieldsResult): setValidateFormSupplierAction {
    return {
        type: 'SET_VALIDATE_FORM_SUPPLIER',
        form: form
    };
}

/**************************************************************
 **************************************************************
 *
 * @param SUPPLIERS_OPERATIONS
 *
 * ************************************************************
 **************************************************************/

export function addSupplier(supplier: supplier) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        dispatch({ type: 'REQUEST_SUPPLIERS' });
        return axios.post('api/suppliers', supplier)
            .then((data) => {
                const state = getState()
                const supplierEditForm = state.suppliers.supplierEditForm
                if (supplierEditForm.permisosUsuario.accessAppMvEnabled || supplierEditForm.permisosUsuario.accessEnabled) {
                    Notify("Proveedor creado. Se ha enviado un email al destinatario con las instrucciones para iniciar sesión", "success")
                } else {
                    Notify("Proveedor creado", "success")
                }
                dispatch({ type: 'ADD_SUPPLIER_RECEIVED', supplier: data.data });
            })
            .catch((error) => {
            })
    }
}

export function editSupplier(supplier: supplier) {
    return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
        dispatch({ type: 'REQUEST_SUPPLIERS' });
        return axios.put('api/suppliers/', supplier)
            .then((data) => {
                const state = getState();
                const supplierEditForm = state.suppliers.supplierEditForm
                const supplierForm = state.suppliers.supplierForm
                const accessPermissions = supplierEditForm.permisosUsuario.accessAppMvEnabled || supplierEditForm.permisosUsuario.accessEnabled;
                const prevAccessPermissions = !supplierForm.permisosUsuario.accessAppMvEnabled && !supplierForm.permisosUsuario.accessEnabled;
                if (accessPermissions && prevAccessPermissions) {
                    Notify("Proveedor modificado. Se ha enviado un email al destinatario con las instrucciones para iniciar sesión", "success");
                } else {
                    Notify("Proveedor modificado", "success")
                }
                dispatch({ type: 'SAVE_EDIT_SUPPLIER_RECEIVED', supplier: data.data });
            })
            .catch((error) => {
            })
    }
}

export function requestAllSuppliers(searchSuppliersFilter: searchFilterList) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        dispatch({ type: 'REQUEST_SUPPLIERS' });
        return axios.post<searchedSuppliers>('api/suppliers/searchFilter/', searchSuppliersFilter)
            .then((data) => {
                dispatch({ type: 'RECEIVED_SUPPLIERS', suppliers: data.data });
            })
            .catch((error) => {
            })
    }
}

export const requestOneSupplier = async (idSup: string) => {
    let searchFilter = { ...inicialiceSearchFilterList, idMongo: idSup }
    return await axios.post<searchedSuppliers>('api/suppliers/searchFilter/', searchFilter)
}

export function deleteSupplier(supplier: supplier) {
    return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
        return axios.post('api/suppliers/delete/', supplier)
            .then(res => {
                const searchFilter = getState().suppliers.searchFilterSuppliers
                dispatch(requestAllSuppliers(searchFilter))
            })
            .catch((error) => {
            })
    }
}

export function validateIfExistsFields(supplier: supplier) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        return axios.post('api/suppliers/validateExists/', supplier)
            .then((data) => {
                return dispatch({ type: 'SET_VALIDATE_FORM_SUPPLIER', form: data.data });
            })
            .catch((error) => {
            })
    }
}

export function exportSuppliersToExcel(searchFilter: searchFilterList) {
    return async (dispatch: Dispatch) => {
        return axios({ method: 'post', responseType: 'blob', url: 'api/suppliers/createExcel/getList/', data: searchFilter })
            .then((data) => {
                const blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Aseguis_proveedores.xlsx";
                link.click();
            })
            .catch((error) => {
            })
    }
}


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
function suppliersReducer(state: suppliersState = initialState, incomingAction: Action): suppliersState {

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_BOTH_FORMS_SUPPLIER': {
            return {
                ...state,
                supplierForm: action.supplier,
                supplierEditForm: action.supplier,
                validateFieldsForm: inicialiceSupplierValidateFieldsResult
            }
        }
        case 'CHANGE_ADD_EDIT_SUPPLIER': {
            return {
                ...state,
                addOrEditSuppliers: action.addOrEdit
            }
        }
        case 'SAVE_EDIT_FORM_SUPPLIER': {
            return {
                ...state,
                supplierEditForm: action.supplier
            };
        }
        case 'ADD_SUPPLIER_RECEIVED': {
            return {
                ...state,                
                supplierForm: action.supplier,
                supplierEditForm: action.supplier,
                addOrEditSuppliers: addingOrEditing.edditing
            };
        }        
        case 'SAVE_EDIT_SUPPLIER_RECEIVED': {
            return {
                ...state,                
                supplierForm: action.supplier,
                supplierEditForm: action.supplier
            };
        }
        case 'REQUEST_SUPPLIERS': {
            return {
                ...state,
                isloadingSuppliers: loading.pending
            };
        }
        case 'RECEIVED_SUPPLIERS': {
            return {
                ...state,
                isloadingSuppliers: loading.done,
                suppliers: action.suppliers.suppliersResults,
                totalSuppliersList: action.suppliers.totalResults,
                actualPage: action.suppliers.numPage,
                totalPages: action.suppliers.totalPages
            };
        }
        case 'RECEIVED_AUTOCOMPLETE_SUPPLIER': {
            return {
                ...state,
                suppliersAutocomplete: action.suppliers
            };
        }
        case 'CLEAR_AUTOCOMPLETE_SUPPLIER': {
            return {
                ...state,
                suppliersAutocomplete: []
            };
        }
        case 'SAVE_SEARCH_FILTER_SUPPLIER': {
            return {
                ...state,
                searchFilterSuppliers: action.searchFilterSupplier,
                isloadingSuppliers: loading.pending
            };
        }
        case 'SET_VALIDATE_FORM_SUPPLIER': {
            return {
                ...state,
                validateFieldsForm: action.form
            };
        }
        default:
            return state;
    }
};

export default suppliersReducer;