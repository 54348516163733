import React, { Fragment, ReactElement, useEffect } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { addingOrEditing } from "aseguisShared"

interface infoToInputText {
    colMesure: string
    editEnabled: number;
    addOrEdit: number;
    name: string,
    value: number,
    valueEdit: number,
    onChange: (number: NumberFormatValues) => void
    autoFocus?: boolean
    onFocus?: () => void
    onBlur?: () => void
    format?: string,
    mask?: string,
    thousandSeparator?: string,
    decimalSeparator?: string,
    decimalScale?: number,
    fixedDecimalScale?: boolean,
    allowNegative?: boolean,
    renderText?: string
    withoutformRow?: boolean
    suffix?: string
    className?: string
    style?: React.CSSProperties
    autoComplete?: false
    small?: boolean
    showNumber0?: boolean
}
function InputNumber(props: infoToInputText): ReactElement {

    const setClassName = () => {
        if (props.small) {
            return "form-control form-control-sm"
        } else {
            if (props.className) {
                return props.className
            } else {
                return "form-control"
            }
        }
    }

    return (
        <Fragment>

            <div className={`form-group col-md-${props.colMesure}`}>

                {props.renderText ?
                    <div>
                        <NumberFormat
                            renderText={value => <div style={props.style} >{value}</div>}
                            displayType="text"
                            format={props.format && props.format}
                            mask={props.mask && props.mask}
                            onFocus={props.onFocus}
                            onBlur={props.onBlur}
                            autoComplete={props.autoComplete === false ? "off" : "on"}
                            autoFocus={(props.autoFocus && props.valueEdit === 0) && true}
                            value={props.valueEdit}
                            id={props.name}
                            isNumericString={false}
                            className={setClassName()}
                            onValueChange={props.onChange}
                            thousandSeparator={props.thousandSeparator}
                            decimalSeparator={props.decimalSeparator}
                            decimalScale={props.decimalScale}
                            fixedDecimalScale={props.fixedDecimalScale}
                            allowNegative={props.allowNegative}
                            suffix={props.suffix ? props.suffix : ""}
                        />
                    </div>
                    :
                    <div>
                        <label style={{ fontWeight: 'bold', ...props.style }} htmlFor="name"> {props.name} </label>
                        {(props.editEnabled === addingOrEditing.edditing) &&
                            <NumberFormat
                                format={props.format && props.format}
                                mask={props.mask && props.mask}
                                onFocus={props.onFocus}
                                onBlur={props.onBlur}
                                autoComplete={props.autoComplete === false ? "off" : "on"}
                                autoFocus={(props.autoFocus && props.valueEdit === 0) && true}
                                value={props.showNumber0 ? props.valueEdit : props.valueEdit > 0 ? props.valueEdit : ""}
                                id={props.name}
                                isNumericString={false}
                                className={setClassName()}
                                onValueChange={props.onChange}
                                thousandSeparator={props.thousandSeparator}
                                decimalSeparator={props.decimalSeparator}
                                decimalScale={props.decimalScale}
                                fixedDecimalScale={props.fixedDecimalScale}
                                allowNegative={props.allowNegative}
                                suffix={props.suffix ? props.suffix : ""}
                            />
                        }

                        {(props.editEnabled !== addingOrEditing.edditing && props.addOrEdit === addingOrEditing.edditing) &&
                            <div>
                                <NumberFormat
                                    renderText={value => {
                                        return (
                                            <input
                                                name="abonoCli"
                                                disabled={true}
                                                type="text"
                                                value={value}
                                                className="form-control undefined"
                                            />
                                        )
                                    }}
                                    displayType="text"
                                    format={props.format && props.format}
                                    mask={props.mask && props.mask}
                                    onFocus={props.onFocus}
                                    autoComplete={props.autoComplete === false ? "off" : "on"}
                                    onBlur={props.onBlur}
                                    autoFocus={(props.autoFocus && props.valueEdit === 0) && true}
                                    value={props.value}
                                    id={props.name}
                                    isNumericString={false}
                                    className={setClassName()}
                                    onValueChange={props.onChange}
                                    thousandSeparator={props.thousandSeparator}
                                    decimalSeparator={props.decimalSeparator}
                                    decimalScale={props.decimalScale}
                                    fixedDecimalScale={props.fixedDecimalScale}
                                    allowNegative={props.allowNegative}
                                    suffix={props.suffix ? props.suffix : ""}
                                />
                            </div>
                        }

                        {(props.editEnabled !== addingOrEditing.edditing && props.addOrEdit === addingOrEditing.adding) &&
                            <div>
                                <NumberFormat
                                    renderText={value => {
                                        return (
                                            <input
                                                name="abonoCli"
                                                disabled={true}
                                                type="text"
                                                value={value}
                                                className="form-control undefined"
                                            />
                                        )
                                    }}
                                    displayType="text"
                                    format={props.format && props.format}
                                    mask={props.mask && props.mask}
                                    autoComplete={props.autoComplete === false ? "off" : "on"}
                                    onFocus={props.onFocus}
                                    onBlur={props.onBlur}
                                    autoFocus={(props.autoFocus && props.valueEdit === 0) && true}
                                    value={props.valueEdit}
                                    id={props.name}
                                    isNumericString={false}
                                    className={setClassName()}
                                    onValueChange={props.onChange}
                                    thousandSeparator={props.thousandSeparator}
                                    decimalSeparator={props.decimalSeparator}
                                    decimalScale={props.decimalScale}
                                    fixedDecimalScale={props.fixedDecimalScale}
                                    allowNegative={props.allowNegative}
                                    suffix={props.suffix ? props.suffix : ""}
                                />
                            </div>
                        }
                    </div>
                }

            </div>

        </Fragment>

    );
};

export default InputNumber;