import React, { Fragment, ReactElement  } from "react";
import { validateNumberFormEmpty, validateNumericWithSum, validateNumericWithSumAndMultiplicate } from "aseguisShared";
import InputNumber from "./InputNumber";
import InputText from "./InputText";

interface infoToInputTextSum {
    isEdditing: number;
    addOrEdit: number,

    nameToShow: string,

    valueNumberEdit: number
    valueNumber: number

    valueTextEdit: string
    valueText: string

    coolMesure: string

    onChange: (valueNumber: number, valueText: string) => void

    decimalScale?: number
    withoutFormError?: boolean
    autoComplete?: false
    onFocus?: () => void
}

function InputNumberSum(props: infoToInputTextSum): ReactElement {

    const [isFocus, setFocus] = React.useState(false);

        return (

            <Fragment>
                
                {isFocus === true ?
                    <InputText
                        onFocus={() => {
                            setFocus(true)
                            if (props.onFocus) {
                                props.onFocus()
                            }
                        }}
                        onBlur={() => {
                            setFocus(false)
                        }}
                        className={ (props.withoutFormError && props.withoutFormError === true) ? "form-control undefined" : validateNumberFormEmpty(props.valueNumberEdit, 1)}
                        colMesure={props.coolMesure}
                        editEnabled={props.isEdditing}
                        autoComplete={props.autoComplete}
                        addOrEdit={props.addOrEdit}
                        name={props.nameToShow}
                        autoFocus={true}
                        valueEdit={props.valueTextEdit}
                        value={props.valueText}
                        onChange={(event) => {
                            let stringValue = validateNumericWithSumAndMultiplicate(event.target.value);
                            // Separamos la entrada en segmentos basados en el operador de suma (+).
                            const sumSegments = stringValue.split('+').map(segment => {
                                // Para cada segmento, comprobamos si contiene multiplicaciones.
                                if (segment.includes('*')) {
                                    // Separamos el segmento por el operador de multiplicaci�n (*) y evaluamos el resultado.
                                    const multiplicationResult = segment.split('*').reduce((acc, current) => {
                                        // Aqu� realizamos la sustituci�n de comas por puntos solo en el momento del c�lculo.
                                        const number = parseFloat(current.replace(/,/g, '.'));
                                        return acc * (isNaN(number) ? 1 : number); // Multiplicamos los n�meros, ignorando entradas no num�ricas.
                                    }, 1); // Iniciamos la acumulaci�n con 1 para la multiplicaci�n.
                                    return multiplicationResult;
                                } else {
                                    // Si no hay multiplicaci�n, simplemente convertimos el segmento a n�mero.
                                    // Tambi�n aqu� convertimos comas en puntos solo en el momento del c�lculo.
                                    const number = parseFloat(segment.replace(/,/g, '.'));
                                    return isNaN(number) ? 0 : number; // Convertimos el segmento a n�mero, tratando las entradas no v�lidas como 0.
                                }
                            });
                            // Finalmente, sumamos los resultados de los segmentos.
                            const valueToUpdate = sumSegments.reduce((acc, current) => acc + current, 0);
                            // Aplica l�mites si es necesario.
                            if (valueToUpdate > 9999999) {
                                stringValue = ""; // Resetea el string si el valor calculado es demasiado grande.
                            }
                            props.onChange(valueToUpdate, stringValue);
                        }}
                    />
                    :
                    <InputNumber
                        onFocus={() => {
                            setFocus(true)
                            if (props.onFocus) {
                                props.onFocus()
                            }
                        }}
                        onBlur={() => {
                            setFocus(false)
                        }}
                        className={(props.withoutFormError && props.withoutFormError === true) ? "form-control undefined" : validateNumberFormEmpty(props.valueNumberEdit, 1)}
                        colMesure={props.coolMesure}
                        autoComplete={props.autoComplete}
                        editEnabled={props.isEdditing}
                        addOrEdit={props.addOrEdit}
                        name={props.nameToShow}
                        valueEdit={props.valueNumberEdit}
                        value={props.valueNumber}
                        onChange={() => {
                        }}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={props.decimalScale ? props.decimalScale : 0}
                        fixedDecimalScale={true}
                        allowNegative={false}
                    />

                }                
                
            </Fragment>

        );

};

export default InputNumberSum;