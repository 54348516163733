import { Action, Dispatch } from 'redux';
import { ApplicationState } from '../../ApplicationState';
import axios from 'axios';
import { loginUsers } from 'aseguisShared';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface uploadScannedAlbaranesState {
    allFiles: File[];
    isUploadLoading: boolean,
    numberPendingFiles: number
}

export const initUploadScannedAlbaranesState: uploadScannedAlbaranesState = {
    allFiles: [],
    isUploadLoading: false,
    numberPendingFiles: 0
};

export interface base64Files {
    base64File: string
    fechaEntrega: Date
    fileName: string
    whoDeliver: loginUsers;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface setFilesUpload {
    type: 'SET_FILES_UPLOAD',
    files: File[]
}

export interface setLoadingUpload {
    type: 'SET_LOADING_UPLOAD',
    isLoading: boolean
}

export interface setNumberOfPendingFiles {
    type: 'SET_NUMBER_PENDING_FILES',
    numberFiles: number
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = setFilesUpload | setLoadingUpload | setNumberOfPendingFiles

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export function setFilesUpload(files: File[]): setFilesUpload {
    return {
        type: 'SET_FILES_UPLOAD',
        files: files
    };
}

export function setLoadingUpload(isLoading: boolean): setLoadingUpload {
    return {
        type: 'SET_LOADING_UPLOAD',
        isLoading: isLoading
    };
}

export function setNumberOfPendingFiles(numberFiles: number): setNumberOfPendingFiles {
    return {
        type: 'SET_NUMBER_PENDING_FILES',
        numberFiles: numberFiles
    };
}

/**************************************************************
 **************************************************************
 *
 * @param Upload_Operations
 *
 * ************************************************************
 **************************************************************/

export function uploadScannedAlbaranes(incomingFiles: base64Files[]) {
    return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
        return axios.post('api/GetAttachedsFromScanner/', incomingFiles)
            .then((data) => {
                dispatch(getNumberOfPendingFiles())
            })
            .catch((error) => {
            })
    }
}

// obtener numero de ficheros a procesar pendientes
export function getNumberOfPendingFiles() {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        await axios.get<number>('api/GetAttachedsFromScanner/')
            .then((data) => {
                dispatch(setNumberOfPendingFiles(data.data))
            })
            .catch((error) => {
                dispatch(setNumberOfPendingFiles(0))
            })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
function uploadScannedAlbaranesReducer(state: uploadScannedAlbaranesState = initUploadScannedAlbaranesState, incomingAction: Action): uploadScannedAlbaranesState {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_FILES_UPLOAD': {
            return {
                ...state,
                allFiles: action.files
            }
        }
        case 'SET_LOADING_UPLOAD': {
            return {
                ...state,
                isUploadLoading: action.isLoading
            };
        }
        case 'SET_NUMBER_PENDING_FILES': {
            return {
                ...state,
                numberPendingFiles: action.numberFiles
            };
        }
        default:
            return state;
    }
};

export default uploadScannedAlbaranesReducer;