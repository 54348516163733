import moment from "moment";
import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { addingOrEditing, DateIsNearVto, inicialiceDate, getFormattedDate, getFormattedDateAndHour } from "aseguisShared";

moment.locale('es');

interface infoToInputText {
    colMesure: string
    editEnabled: number;
    addOrEdit: number;
    name: string,

    value: Date,
    valueEdit: Date,

    onChange: (date: Date) => void

    NoBoldName?: boolean
    small?: boolean
    redOnError?: boolean
    errorOnFormBlank?: boolean
    dateWithHour?: boolean
}

function InputDate(props: infoToInputText): ReactElement {

    const [date, setDate] = useState(moment(new Date(props.valueEdit)).format('YYYY-MM-DD'));
    const [dateNoEdit, setDateNoEdit] = useState(moment(new Date(props.valueEdit)).format('YYYY-MM-DD'));
    const [onFocus, setOnFocus] = useState(false);

    useEffect(() => {
        if (onFocus === false) {
            if (new Date(props.valueEdit).getTime() === new Date(inicialiceDate.year1000).getTime()) {
                setDate("")
            } else {
                setDate(moment(new Date(props.valueEdit)).format('YYYY-MM-DD'))
            }
        }
    }, [props.valueEdit])

    useEffect(() => {
        setDateNoEdit(moment(new Date(props.value)).format('YYYY-MM-DD'))
    }, [props.value])

    const getClassName = () => {
        if (props.small) {
            if (
                props.errorOnFormBlank &&
                props.errorOnFormBlank === true && date === ""
            ) {
                return "form-control form-control-sm is-invalid"
            } else {
                return "form-control form-control-sm"
            }
        } else {
            if (props.errorOnFormBlank && props.errorOnFormBlank === true && date === "") {
                return "form-control is-invalid"
            } else {
                return "form-control"
            }
        }
    }

    const getDateFormate = (date: Date) => {
        if (props.dateWithHour && props.dateWithHour === true) {
            return getFormattedDateAndHour(date)
        }
        return getFormattedDate(date)
    }

    return (
        <Fragment>

            <div className={`form-group col-md-${props.colMesure}`}>
                <div>
                    {props.NoBoldName ?
                        <label htmlFor="name"> {props.name} </label>
                        :
                        <label style={{ fontWeight: 'bold' }} htmlFor="name"> {props.name} </label>
                    }

                    {(props.editEnabled === addingOrEditing.edditing) &&
                        <input
                            type="date"
                            min="2022-01-01"
                            max="2070-12-31"
                            className={getClassName()}
                            name="dateForm"
                            lang="es"
                            value={date}
                            onFocus={(event) => {
                                setOnFocus(true)
                            }}
                            onBlur={(event) => {
                                setOnFocus(false)
                            }}
                            onChange={(event) => {
                                const dateFromString = new Date(event.target.value);

                                if (event.target.value === "") {
                                    props.onChange(inicialiceDate.year1000)
                                } else {
                                    props.onChange(dateFromString)
                                }

                                setDate(event.target.value)

                            }}
                        />
                    }

                    {(props.editEnabled !== addingOrEditing.edditing) &&
                        <input
                            disabled={true}
                            style={props.redOnError ? { color: DateIsNearVto(props.value) } : {}}
                            type="date"
                            className={getClassName()}
                            lang="es"
                            value={dateNoEdit}
                        />
                    }

                    {/*(props.editEnabled !== addingOrEditing.edditing) &&
                        <div>
                            {props.redOnError ?
                                <div>
                                    <div style={{ color: DateIsNearVto(props.value) }}>{getDateFormate(props.value)}</div>
                                </div>
                                :
                                <div>{getDateFormate(props.value)}</div>
                            }
                        </div>
                    */}

                </div>
            </div>

        </Fragment>

    );
};

export default InputDate;