"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initAlbaranesToBill = exports.initAlbaran = exports.inicialiceBill = exports.initDatosEconomicosBill = void 0;
const commonEnums_1 = require("../enums/commonEnums");
const cargaCompletaFunctions_1 = require("./cargaCompletaFunctions");
const clientsFunctions_1 = require("./clientsFunctions");
const commonFunctions_1 = require("./commonFunctions");
const loginFunctions_1 = require("./loginFunctions");
exports.initDatosEconomicosBill = {
    total: 0,
    totalDescuento: 0,
    totalIncremento: 0,
    subTotal: 0,
    baseImponible: 0,
    iva: 0,
    porIva: 0
};
exports.inicialiceBill = {
    _id: null,
    id: 0,
    client: cargaCompletaFunctions_1.inicialiceCargaCompleta.datosGenerales.client,
    fecFactur: new Date(),
    fecAlta: new Date(),
    fecVencimiento: new Date(),
    cargasCompletas: {
        descuentosCarga: [],
        incrementosCarga: [],
        allCargasCompletas: [],
        datosEconomicosCargas: exports.initDatosEconomicosBill
    },
    paqueterias: {
        descuentosPaqueteria: [],
        incrementosPaqueteria: [],
        allPaqueterias: [],
        datosEconomicosPaqs: exports.initDatosEconomicosBill
    },
    datosEconomicosTotales: {
        formaPago: "",
        ccc: "",
        baseImponible: 0,
        totalDescuento: 0,
        totalIncremento: 0,
        subTotal: 0,
        porIva: 0,
        iva: 0,
        total: 0
    },
    remesaDownloaded: "",
    cobrada: false,
    user: loginFunctions_1.inicialiceLoginUser
};
exports.initAlbaran = {
    client: clientsFunctions_1.inicialiceClient,
    cargas: [],
    paqueterias: []
};
exports.initAlbaranesToBill = {
    queFacturar: commonEnums_1.queFacturarTipos.todo,
    fechaFactur: new Date(),
    fechaDesde: commonFunctions_1.inicialiceDate.year1000,
    fechaHasta: commonFunctions_1.inicialiceDate.year1000,
    albaranes: [],
    sendBillEmail: true
};
