import React, { Fragment, ReactElement, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector, } from "react-redux";
import { addingOrEditing, getFormattedDateShort, formatedDirWithName, mountRefere, inicialicePaqueteria, paqueteria } from "aseguisShared"
import { setHistoryAddListPaq, setOpenCloseModalEditPaq } from "./PaqueteriaStore";
import { Button, Modal } from "react-bootstrap";
import PaqueteriaFormData from "./PaqueteriaFormData";
import axios from "axios";
import { ApplicationState } from "../../ApplicationState";

interface infoToPaqueteriaHistoryList {
    isEdditing: number;
    addOrEdit: number;
    paqueteriaForm: paqueteria;
    paqueteriaEditForm: paqueteria;
}

function PaqueteriaHistoryList(props: infoToPaqueteriaHistoryList): ReactElement {

    const dispatch = useDispatch();

    const addedHistory: paqueteria[] = useSelector((state: ApplicationState) => state.paqueteria.historyAddList);
    const openClosedModalEdit: boolean = useSelector((state: ApplicationState) => state.paqueteria.openCloseEditPaqModal);

    //MODAL editar paqueteria
    const [errorForm, setErrorForm] = useState(false)
    const [paqToModalOem, setPaqToModalOem] = useState(inicialicePaqueteria)
    const [paqToModal, setPaqToModal] = useState(inicialicePaqueteria)

    function selectPaqToEdit(paqueteria: paqueteria): void {
        setPaqToModal(paqueteria)
        setPaqToModalOem(paqueteria)
        dispatch(setOpenCloseModalEditPaq(true))
    }

    const handleClose = () => {
        dispatch(setOpenCloseModalEditPaq(false))
    }

    const listEditPaqueterias: JSX.Element[] = addedHistory.map((paqueteria, index) => {
        return (
            <tr className={`table-row ${(props.paqueteriaEditForm.id === paqueteria.id) ? "selected" : ""}`}
                key={index}>

                <td onClick={() => {
                    selectPaqToEdit(paqueteria)
                }}>
                    {paqueteria.id}
                </td>

                <td onClick={() => {
                    selectPaqToEdit(paqueteria)
                }}>
                    {paqueteria.datosGenerales.client.nombre}
                </td>

                <td onClick={() => {
                    selectPaqToEdit(paqueteria)
                }}>
                    {paqueteria.datosGenerales.entradaSalida}
                </td>

                <td style={{ whiteSpace: "nowrap" }} onClick={() => {
                    selectPaqToEdit(paqueteria)
                }}>
                    {getFormattedDateShort(paqueteria.datosGenerales.recogida)}
                </td>

                <td style={{ whiteSpace: "nowrap" }} onClick={() => {
                    selectPaqToEdit(paqueteria)
                }}>
                    {getFormattedDateShort(paqueteria.datosGenerales.entrega)}
                </td>

                <td onClick={() => {
                    selectPaqToEdit(paqueteria)
                }}>
                    {mountRefere(paqueteria.datosTransporte.referencias)}
                </td>

                <td onClick={() => {
                    selectPaqToEdit(paqueteria)
                }}>
                    {paqueteria.datosTransporte.bultos}
                </td>

                <td onClick={() => {
                    selectPaqToEdit(paqueteria)
                }}>
                    {paqueteria.datosTransporte.kilos}
                </td>

                <td onClick={() => {
                    selectPaqToEdit(paqueteria)
                }}>
                    {paqueteria.datosTransporte.m3}
                </td>

                <td onClick={() => {
                    selectPaqToEdit(paqueteria)
                }}>
                    {formatedDirWithName(paqueteria.datosTransporte.address, paqueteria.datosTransporte.destName)}
                </td>

                <td onClick={() => {
                    selectPaqToEdit(paqueteria)
                }}>
                    {formatedDirWithName(paqueteria.datosTransporte.addressDos, paqueteria.datosTransporte.destNameDos)}
                </td>

                <td style={{ whiteSpace: "nowrap" }} onClick={() => {
                    selectPaqToEdit(paqueteria)
                }}>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        autoComplete="off"
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={true}
                        value={paqueteria.datosEconomicos.subTotal}
                        className="form-control undefined"
                        renderText={value => <div>{value}</div>}
                        suffix={" \u20AC"}
                    />
                </td>
            </tr>
        );
    });

    return (
        <Fragment>

            <div className="table-responsive table-hover portlet-600" key={props.isEdditing}>
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Cliente</th>
                            <th scope="col">Ent/Sal</th>
                            <th scope="col" style={{ whiteSpace: "nowrap" }}>Fec albarán</th>
                            <th scope="col" style={{ whiteSpace: "nowrap" }}>Entrega</th>
                            <th scope="col">Referencia</th>
                            <th scope="col">Bultos</th>
                            <th scope="col">Kilos</th>
                            <th scope="col">M3</th>
                            <th scope="col">Origen</th>
                            <th scope="col">Destino</th>
                            <th scope="col">Importe</th>
                        </tr>
                    </thead>
                    {(props.addOrEdit === addingOrEditing.adding) &&
                        <tbody>
                            {listEditPaqueterias}
                        </tbody>
                    }
                </table>
            </div>

            {/* MODAL PARA EDITAR PAQUETERIA */}
            <Modal
                show={openClosedModalEdit}
                onHide={handleClose}
                dialogClassName="modal-xl2"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Editar paquetería #{paqToModal.id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PaqueteriaFormData
                        isEdditing={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.edditing}
                        setErrorForm={setErrorForm}
                        errorForm={errorForm}
                        paqueteriaForm={paqToModalOem}
                        paqueteriaEditForm={paqToModal}
                        saveFormPaqueteria={setPaqToModal}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        disabled={errorForm}
                        onClick={() => {
                            axios.put<paqueteria>('api/paqueteria/', paqToModal)
                                .then((data) => {
                                    if (errorForm === false) {
                                        dispatch(setHistoryAddListPaq(
                                            addedHistory.map(paq => {
                                                if (paq.id !== paqToModal.id) {
                                                    return paq;
                                                } else {
                                                    return data.data
                                                }
                                            })
                                        ))
                                        handleClose()
                                    }
                                })
                                .catch((error) => {
                                    handleClose()
                                })
                        }}
                    >
                        Guardar Cambios
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment>

    );
};

export default PaqueteriaHistoryList;
