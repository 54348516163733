import React, { ReactElement, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import axios from "axios";
import RegisterTemplate from "../Register/RegisterTemplate";
import { PasswordRequirements, showPasswordRequirements } from "../Register/Register";
import { changePasswordRequest } from "aseguisShared";
import { Notify } from "../../common/Toastify/Notify";

function NewPassword(): ReactElement {

    const history = useHistory();

    const { forgotLink } = useParams<{ forgotLink: string }>();

    const [isLoading, setIsLoading] = useState(false)
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")

    const [passwordRequirements, setPasswordRequirements] = useState<PasswordRequirements>({
        minLength: false,
        hasUppercase: false,
        hasLowercase: false,
        hasNumber: false,
        passwordsMatch: false,
    });

    const passwordIsOK =
        passwordRequirements.minLength &&
        passwordRequirements.hasUppercase &&
        passwordRequirements.hasLowercase &&
        passwordRequirements.hasNumber &&
        passwordRequirements.passwordsMatch;

    const changePassword = () => {
        setIsLoading(true)
        const changeRequest: changePasswordRequest = {
            oldPassword: "",
            token: forgotLink,
            password1: password1,
            password2: password2,
        }
        axios.post('/api/restorePassword', changeRequest)
            .then((data) => {
                if (data.status === 200) {
                    Notify("La contraseña ha sido actualizada. Puede iniciar sesión", "success")
                } else {
                    Notify("No ha sido posible actualizar la contraseña", "error")
                }
                history.push(allAppRoutes.login)
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
                Notify("No ha sido posible actualizar la contraseña", "error")
            })
    }

    return (

        <RegisterTemplate>

            <div className="login-container">

                <div className="text-center mb-5">
                    <h1 className="h3 text-gray-900 mb-2">Establecer contraseña</h1>
                </div>

                <div className="form-group">
                    <input
                        id="register-input newPassword1"
                        name="newPassword1"
                        type="password"
                        placeholder={"Nueva contraseña"}
                        autoComplete="off"
                        className={"form-control undefined"}
                        value={password1}
                        required
                        onChange={(event) => {
                            const { value } = event.target;
                            setPassword1(value)
                            setPasswordRequirements(prevRequirements => ({
                                ...prevRequirements,
                                minLength: value.length >= 8,
                                hasUppercase: /[A-Z]/.test(value),
                                hasLowercase: /[a-z]/.test(value),
                                hasNumber: /[0-9]/.test(value),
                                passwordsMatch: value === password2 && value.length > 0,
                            }));
                        }}
                    />
                </div>

                <div className="form-group">
                    <input
                        id="register-input newPassword2"
                        name="newPassword2"
                        type="password"
                        className={"form-control undefined"}
                        placeholder={"Repita contraseña"}
                        value={password2}
                        onChange={(event) => {
                            const { value } = event.target;
                            setPassword2(value)
                            setPasswordRequirements(prevRequirements => ({
                                ...prevRequirements,
                                passwordsMatch: value === password1 && value.length > 0,
                            }));
                        }}
                    />
                </div>

                {showPasswordRequirements(passwordRequirements)}

                <div className="mt-4">
                    {(isLoading) ?
                        <button
                            className={"btn btn-primary btn-user btn-block"}
                            type="button"
                        >
                            <span className="spinner-border spinner-border-sm ml-2"></span>
                        </button>
                        :
                        <button
                            className={"btn btn-primary btn-user btn-block"}
                            type="button"
                            disabled={!passwordIsOK || forgotLink.length === 0}
                            onClick={() => {
                                changePassword()
                            }}
                        >
                            <span>Continuar</span>
                        </button>
                    }
                </div>

                <div>
                    <button
                        className={"btn btn-secondary btn-user btn-block mt-2"}
                        type="button"
                        onClick={() => {
                            history.push(allAppRoutes.login)
                        }}
                    >
                        <span>Cancelar</span>
                    </button>
                </div>

            </div>

        </RegisterTemplate>

    );
}


export default NewPassword;
