import React, { Fragment } from "react";
import InputText from "../../common/Inputs/InputText";
import { addingOrEditing, inicialiceSearchFilterList, reviRepaTypes, searchFilterList, validateAlphaNumeric } from "aseguisShared";
import InputDate from "../../common/Inputs/InputDate";

interface infoToReviRepaListFilter {
    searchFilter: searchFilterList
    saveSearchFilter: (filter: searchFilterList) => void
}

const ReviRepaListFilter = (props: infoToReviRepaListFilter) => {

    return (

        <Fragment>

            <div className="form-row" style={{ marginBottom: "-25px" }}>

                <InputText
                    small={true}
                    colMesure="3"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="Matrícula vehículo"
                    autoComplete={false}
                    valueEdit={props.searchFilter.plateVehicle}
                    value={props.searchFilter.plateVehicle}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                        value = value.trimStart()
                        props.saveSearchFilter({
                            ...props.searchFilter,
                            pageNum: 1,
                            plateVehicle: value,
                            withTimeout: true
                        })
                    }}
                />              

                <InputText
                    small={true}
                    colMesure="3"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="Marca / modelo vehículo"
                    autoComplete={false}
                    valueEdit={props.searchFilter.marcaVehicle}
                    value={props.searchFilter.marcaVehicle}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilter({
                            ...props.searchFilter,
                            pageNum: 1,
                            marcaVehicle: value,
                            withTimeout: true
                        })
                    }}
                />

                <InputText
                    small={true}
                    colMesure="3"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="Tipo de vehículo"
                    valueEdit={props.searchFilter.typeVehicle}
                    autoComplete={false}
                    value={props.searchFilter.typeVehicle}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilter({
                            ...props.searchFilter,
                            pageNum: 1,
                            typeVehicle: value,
                            withTimeout: true
                        })
                    }}
                />                

            </div>

            <div className="form-row" style={{ marginBottom: "-30px" }}>

                <InputText
                    small={true}
                    colMesure="3"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="Empleado"
                    autoComplete={false}
                    valueEdit={props.searchFilter.employeeName}
                    value={props.searchFilter.employeeName}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilter({
                            ...props.searchFilter,
                            pageNum: 1,
                            employeeName: value,
                            withTimeout: true
                        })
                    }}
                />

                <div className="form-group col-md-2">
                    <div>
                        <label style={{ fontWeight: 'bold' }} htmlFor="name">Tipo</label>
                        <select
                            value={props.searchFilter.typeOfReviRepa}
                            className="form-control form-control-sm"
                            id="typeReviRepa"
                            onChange={(value) => {
                                props.saveSearchFilter({
                                    ...props.searchFilter,
                                    pageNum: 1,
                                    typeOfReviRepa: value.target.value,
                                    withTimeout: true
                                })
                            }}>
                            <option value={""}>{""}</option>
                            <option value={reviRepaTypes.reparacion}>{reviRepaTypes.reparacion}</option>
                            <option value={reviRepaTypes.revision}>{reviRepaTypes.revision}</option>
                        </select>
                    </div>
                </div>
                
                <InputDate
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.edditing}
                    name="Fecha desde"
                    valueEdit={props.searchFilter.fechaDesde}
                    value={props.searchFilter.fechaDesde}
                    onChange={(date) => {
                        props.saveSearchFilter({
                            ...props.searchFilter,
                            pageNum: 1,
                            fechaDesde: date!,
                            withTimeout: true
                        })
                    }}
                />

                <InputDate
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.edditing}
                    name="Fecha hasta"
                    valueEdit={props.searchFilter.fechaHasta}
                    value={props.searchFilter.fechaHasta}
                    onChange={(date) => {
                        props.saveSearchFilter({
                            ...props.searchFilter,
                            pageNum: 1,
                            fechaHasta: date!,
                            withTimeout: true
                        })
                    }}
                />

                <div className="form-group col-md-2">
                    <br></br>
                    <button
                        className="btn btn-info btn-sm mr-3 mt-2"
                        onClick={() => {
                            props.saveSearchFilter({ ...props.searchFilter, withTimeout: false })
                        }}
                    >
                        <i className="fas fa-sync"></i>
                    </button>
                    <button className="btn btn-secondary btn-sm mt-2"
                        onClick={() => {
                            props.saveSearchFilter(inicialiceSearchFilterList)
                            
                        }}>
                        Limpiar filtro
                        
                    </button>
                </div>

            </div>

        </Fragment >

    );
    
};

export default ReviRepaListFilter;
