import React, { ReactElement, useState } from "react";
import reactCSS from 'reactcss';
import { ColorResult, SketchPicker } from 'react-color';
import { addingOrEditing } from "aseguisShared";

interface infoToCargaForm {
    setColor: (color: string) => void
    colorForm: string
    colorEditForm: string
    isEdditing: addingOrEditing
}

function ColorPicker({ colorForm, colorEditForm, setColor, isEdditing }: infoToCargaForm): ReactElement {

    const [displayColorPicker, setDisplayColorPicker] = useState(false)

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker)
        if (isEdditing !== addingOrEditing.edditing) {
            setDisplayColorPicker(false)
        }
    };

    const handleClose = () => {
        setDisplayColorPicker(false)
    };

    const handleChange = (color: ColorResult) => {
        setColor(color.hex)
    };

    const styles = reactCSS({
        'default': {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: (isEdditing === addingOrEditing.edditing) ? colorEditForm : colorForm,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '5px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                zIndex: '2',
            },
            cover: {
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    return (
        <div>
            <div style={styles.swatch} onClick={handleClick}>
                <div style={styles.color} />
            </div>

            {(displayColorPicker && isEdditing === addingOrEditing.edditing) &&
                <div style={{ ...styles.popover, position: "absolute" }}>
                    <div
                        style={{ ...styles.cover, position: "fixed" }}
                        onClick={handleClose}
                    />

                    <SketchPicker
                        disableAlpha={true}
                        color={(isEdditing === addingOrEditing.edditing) ? colorEditForm : colorForm}
                        onChangeComplete={handleChange}
                    />
                </div>
            }
        </div>
    )

};

export default ColorPicker;