import {useDispatch, useSelector} from "react-redux";
import React, {Fragment} from "react";
import {ApplicationState} from '../../ApplicationState';
import {
    addOrEditEmployee, saveSearchFilterEmployee, deleteEmployee,
    addEmployee, editEmployee, saveFormEmployee, setFormsEmployee
} from './EmployeesStore';
import EmployeesList from "./EmployeesList";
import EmployeeForm from "./EmployeeForm";
import Buttons from "../../common/Buttons/Buttons";
import {
    searchFilterList,
    employee,
    addingOrEditing,
    UserPermissions,
    inicialiceEmployee,
    inicialicePermisosUsuario
} from "aseguisShared";
import {GetUserPermissionsFromLocalStorage} from "../../common/commonInterfaces/commonFunctions";
import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import {allAppRoutes} from "../../common/commonInterfaces/allAppRoutes";

const Employees: React.FC = () => {

    const dispatch: React.Dispatch<any> = useDispatch();
    const usePathname = useLocation().pathname;
    const usePath = useHistory()

    let {permissions} = GetUserPermissionsFromLocalStorage()

    const employeeForm: employee = useSelector((state: ApplicationState) => state.employees.employeeForm);
    const employeeEditForm: employee = useSelector((state: ApplicationState) => state.employees.employeeEditForm);
    const addingOrEditEmployee: addingOrEditing = useSelector((state: ApplicationState) => state.employees.addOrEditEmployee) || addingOrEditing.none;
    const searchFilterEmployees: searchFilterList = useSelector((state: ApplicationState) => state.employees.searchFilterEmployees);

    const [editEnabled, setEditEnabled] = React.useState(addingOrEditing.none);
    const [errorOnForm, setErrorOnForm] = React.useState(false);

    function enableEditEmployee(employee: employee): void {
        setEditEnabled(addingOrEditing.none);
        dispatch(setFormsEmployee(employee));
        dispatch(addOrEditEmployee(addingOrEditing.edditing));
    }

    function addFormEmployee(): void {
        setEditEnabled(addingOrEditing.edditing);
        dispatch(setFormsEmployee(inicialiceEmployee));
        dispatch(addOrEditEmployee(addingOrEditing.adding));
    }

    function addNewEmployee(): void {
        if (errorOnForm === false) {
            setEditEnabled(addingOrEditing.none);
            dispatch(addEmployee(employeeEditForm, usePath));
        }
    }

    function saveEditedEmployee(): void {
        if (errorOnForm === false) {
            setEditEnabled(addingOrEditing.none);
            if (employeeEditForm.email.length === 0) {
                dispatch(editEmployee({
                    ...employeeEditForm,
                    permisosUsuario: {
                        ...inicialicePermisosUsuario,
                        idAssociated: employeeEditForm.permisosUsuario.idAssociated
                    }
                }));
            } else {
                dispatch(editEmployee(employeeEditForm));
            }
        }
    }

    function restoreEmployeeEdit(): void {
        dispatch(saveFormEmployee(employeeForm));
    }

    return (

        <Fragment>

            <Switch>

                {(permissions.employees !== UserPermissions.deshabilitado) &&
                    <Route exact path={allAppRoutes.employeesList}>
                        <EmployeesList
                            onClickEmployee={(employeeToUpdate) => {
                                enableEditEmployee(employeeToUpdate)
                                usePath.push(allAppRoutes.employeesForm + employeeToUpdate._id);
                            }}
                            addEmployee={() => {
                                addFormEmployee()
                                usePath.push(allAppRoutes.employeesForm + "new");
                            }}
                            searchEmployeesFilter={searchFilterEmployees}
                            saveSearchEmployeesFilter={(searchFilterEmployees) => {
                                dispatch(saveSearchFilterEmployee(searchFilterEmployees));
                            }}
                        />
                    </Route>
                }

                {permissions.employees !== UserPermissions.deshabilitado &&
                    <Route exact path={allAppRoutes.employeesForm + ":id"}>
                        <EmployeeForm
                            isEdditing={editEnabled}
                            setEdditing={setEditEnabled}
                            setErrorForm={setErrorOnForm}
                            errorForm={errorOnForm}
                        />
                    </Route>
                }

                <Redirect from="*" to={allAppRoutes.employeesList}/>

            </Switch>

            {/*BOTONES*/}
            {usePathname !== allAppRoutes.employeesList &&
                <Buttons
                    addOrEdit={addingOrEditEmployee}
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                    cancel={() => {
                        setEditEnabled(addingOrEditing.none);
                        usePath.replace(allAppRoutes.employeesList);
                    }}
                    add={addNewEmployee}
                    save={saveEditedEmployee}
                    from="empleado"
                    thereIsList={true}
                    restoreForm={restoreEmployeeEdit}
                    delete={() => {
                        dispatch(deleteEmployee(employeeEditForm));
                        setEditEnabled(addingOrEditing.none);
                        usePath.replace(allAppRoutes.employeesList);
                    }}
                    deleteText={employeeEditForm.name}
                    disableSaveIfError={(errorOnForm === true) ? true : false}
                />
            }

        </Fragment>

    );
};

export default Employees;