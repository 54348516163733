import { useDispatch, useSelector } from "react-redux";
import React, { Fragment } from "react";
import { ApplicationState } from '../../ApplicationState';
import ClientsList from "./ClientsList";
import ClientForm from "./ClientForm";
import Tariff from "../../common/Tariff/Tariff";
import {
    editClient, saveClientEditForm, addClient,
    addOrEditClient, setFormsClient, saveSearchFilterClient, requestOneClient
} from './ClientsStore';
import { tariff, searchFilterList, client, addingOrEditing, UserPermissions, inicialiceClient, inicialicePermisosUsuario } from "aseguisShared";
import { GetUserPermissionsFromLocalStorage } from "../../common/commonInterfaces/commonFunctions";
import Buttons from "../../common/Buttons/Buttons";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import OptionButton from "../../common/Inputs/OptionButton";

const Clients: React.FC = () => {

    const dispatch: React.Dispatch<any> = useDispatch();
    const usePathname = useLocation().pathname;
    const usePath = useHistory()

    let { permissions } = GetUserPermissionsFromLocalStorage()

    const client: client = useSelector((state: ApplicationState) => state.clients.clientForm);
    const clientEditForm: client = useSelector((state: ApplicationState) => state.clients.clientEditForm);
    const addingOrEdit: addingOrEditing = useSelector((state: ApplicationState) => state.clients.addOrEditClients) || addingOrEditing.none;
    const searchFilterClients: searchFilterList = useSelector((state: ApplicationState) => state.clients.searchFilterClients);

    const [editEnabled, setEditEnabled] = React.useState(addingOrEditing.none);
    const [errorOnForm, setErrorOnForm] = React.useState(false);
    const [errorOnTariffs, setErrorOnTariffs] = React.useState(false);

    function saveTariff(tariff: tariff): void {
        dispatch(saveClientEditForm({ ...clientEditForm, tariff: tariff }));
    }

    function editUser(client: client): void {
        setEditEnabled(addingOrEditing.none);
        dispatch(setFormsClient(client));
        dispatch(addOrEditClient(addingOrEditing.edditing));
    }

    function addclientForm(): void {
        setEditEnabled(addingOrEditing.edditing);
        dispatch(setFormsClient(inicialiceClient));
        dispatch(addOrEditClient(addingOrEditing.adding));
    }

    function addNewClient(): void {
        if (errorOnForm === false && errorOnTariffs === false) {
            setEditEnabled(addingOrEditing.none);
            dispatch(addClient(clientEditForm));
        }
    }

    function saveEditedClient(): void {
        if (errorOnForm === false && errorOnTariffs === false) {
            setEditEnabled(addingOrEditing.none);
            if (clientEditForm.email.length === 0) {
                dispatch(editClient({
                    ...clientEditForm,
                    permisosUsuario: {
                        ...inicialicePermisosUsuario,
                        idAssociated: clientEditForm.permisosUsuario.idAssociated
                    }
                }));
            } else {
                dispatch(editClient(clientEditForm));
            }
        }
    }

    function restoreClientEdit(): void {
        dispatch(saveClientEditForm(client));
    }

    //para tener siempre la ultima versión...
    const getClient = async (idToSearch: string) => {
        if (idToSearch === "new") {
            setEditEnabled(addingOrEditing.edditing)
            dispatch(addOrEditClient(addingOrEditing.adding));
        } else {
            await requestOneClient(idToSearch)
                .then((client) => {
                    if (client.data.clientsResults.length === 1) {
                        dispatch(addOrEditClient(addingOrEditing.edditing));
                        dispatch(setFormsClient(client.data.clientsResults[0]))
                    } else {
                        usePath.push(allAppRoutes.clientsList)
                    }
                })
                .catch((error) => {
                    usePath.push(allAppRoutes.clientsList)
                })
        }
    }
    //...para tener siempre la ultima versión

    return (

        <Fragment>

            {(usePathname !== allAppRoutes.clientsList && permissions.clients !== UserPermissions.deshabilitado && permissions.clientsTariffs !== UserPermissions.deshabilitado) &&
                <div className="row">
                    <div className="form-group col-md-6">
                        <OptionButton
                            route={allAppRoutes.clientsForm}
                            text={"Información general"}
                            linkTo={allAppRoutes.clientsForm + (clientEditForm._id ? clientEditForm._id : "new")}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <OptionButton
                            route={allAppRoutes.clientsTariffs}
                            text={"Tarifas"}
                            linkTo={allAppRoutes.clientsTariffs + (clientEditForm._id ? clientEditForm._id : "new")}
                        />
                    </div>
                </div>
            }

            <Switch>

                {(permissions.clients !== UserPermissions.deshabilitado) &&
                    <Route exact path={allAppRoutes.clientsList}>
                        <ClientsList
                            onClick={(clientToUpdate) => {
                                editUser(clientToUpdate)
                                usePath.push(allAppRoutes.clientsForm + clientToUpdate._id);
                            }}
                            addClient={() => {
                                addclientForm()
                                usePath.push(allAppRoutes.clientsForm + "new");
                            }}
                            searchClientsFilter={searchFilterClients}
                            saveSearchClientsFilter={(searchFilterClients) => {
                                dispatch(saveSearchFilterClient(searchFilterClients));
                            }}
                        />
                    </Route>
                }

                {permissions.clients !== UserPermissions.deshabilitado &&
                    <Route exact path={allAppRoutes.clientsForm + ":id"}>
                        <ClientForm
                            isEdditing={editEnabled}
                            setEdditing={setEditEnabled}
                            setErrorForm={setErrorOnForm}
                            errorForm={errorOnForm}
                            doOnRender={getClient}
                        />
                    </Route>
                }

                {permissions.clientsTariffs !== UserPermissions.deshabilitado &&
                    <Route exact path={allAppRoutes.clientsTariffs + ":id"}>
                        <Tariff
                            originalTariff={client.tariff}
                            cardText={addingOrEdit !== addingOrEditing.adding ? ("# " + clientEditForm.id) : "Añadir Cliente"}
                            editTariff={clientEditForm.tariff}
                            setEditTariff={saveTariff}
                            isEdditing={editEnabled}
                            setErrorForm={setErrorOnTariffs}
                            errorForm={errorOnTariffs}
                            doOnRender={getClient}
                        />
                    </Route>
                }

                <Redirect from="*" to={allAppRoutes.clientsList} />

            </Switch>


            {/*BOTONES*/}
            {usePathname !== allAppRoutes.clientsList &&
                <Buttons
                    addOrEdit={addingOrEdit}
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                    cancel={() => {
                        setEditEnabled(addingOrEditing.none);
                        usePath.replace(allAppRoutes.clientsList);
                    }}
                    add={addNewClient}
                    save={saveEditedClient}
                    from="cliente"
                    thereIsList={true}
                    restoreForm={restoreClientEdit}
                    disableSaveIfError={(errorOnForm === true || errorOnTariffs === true) ? true : false}
                />
            }


        </Fragment >

    );
};

export default Clients;
