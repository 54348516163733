import React, { Fragment, ReactElement, useEffect, useState, } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../ApplicationState";
import {
    incidenceType, addingOrEditing, formStates, getFormattedDate,
    incidenciasEntregas, initIncidenciasEntregas
} from "aseguisShared";
import InputNumber from "../../common/Inputs/InputNumber";

interface infoToPaqueteriaAddIncidenceModal {
    from: "paq" | "cargas"
    addOrEdit: formStates
    modalOpened: boolean
    setModalOpened: (opened: boolean) => void
    addIncidence: (incidencia: incidenciasEntregas) => void
    incidenceToEdit: incidenciasEntregas
    saveIncidence: (incidencia: incidenciasEntregas) => void
}

function PaqueteriaAddIncidenceModal(props: infoToPaqueteriaAddIncidenceModal): ReactElement {

    const IncidencesTypes: incidenceType[] = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm.incidencesTypes);

    const [incidenceToAddOrEdit, setIncidenceToAddOrEdit] = useState(initIncidenciasEntregas)

    const closeModal = () => {
        props.setModalOpened(false)
        setIncidenceToAddOrEdit(initIncidenciasEntregas)
    }

    useEffect(() => {
        setIncidenceToAddOrEdit(props.incidenceToEdit)
    }, [props.incidenceToEdit]);

    return (
        <Fragment>

            <Modal show={props.modalOpened} onHide={closeModal}>

                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.addOrEdit === formStates.add && "Añadir incidencia"}
                        {props.addOrEdit === formStates.edit && "Editar incidencia"}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="form-group col-md-12">
                        <label style={{ fontWeight: 'bold' }} htmlFor="fecAlta">Fecha de alta </label>
                        <div>{getFormattedDate(incidenceToAddOrEdit.datosIncidencia.deliveredTime)}</div>
                    </div>

                    <div className="form-group col-md-12">
                        <label style={{ fontWeight: 'bold' }} htmlFor="fecAlta">Tipo de Incidencia</label>
                        <select
                            value={incidenceToAddOrEdit.tipoIncidencia.description}
                            className={incidenceToAddOrEdit.tipoIncidencia.id > 0 ? "form-control mt-2" : "form-control is-invalid mt-2"}
                            id="panelDeControl"
                            onChange={(event) => {
                                for (var inType of IncidencesTypes) {
                                    if (inType.description === event.target.value) {
                                        setIncidenceToAddOrEdit({
                                            ...incidenceToAddOrEdit,
                                            tipoIncidencia: inType
                                        })
                                    }
                                }
                            }}>
                            <option defaultChecked={true} value="-">-</option>
                            {IncidencesTypes.map((incidenceType) => (
                                <option key={incidenceType.id} value={incidenceType.description}>
                                    {incidenceType.description}
                                </option>
                            ))}
                        </select>
                    </div>

                    {props.from === "paq" &&
                        <InputNumber
                            className="form-control undefined"
                            colMesure="12"
                            editEnabled={addingOrEditing.edditing}
                            addOrEdit={addingOrEditing.edditing}
                            name={"Valor a incrementar por la incidencia (€)"}
                            valueEdit={incidenceToAddOrEdit.value}
                            value={incidenceToAddOrEdit.value}
                            onChange={(value) => {
                                const valueTo = value.floatValue ? value.floatValue : 0
                                setIncidenceToAddOrEdit({
                                    ...incidenceToAddOrEdit,
                                    value: valueTo
                                })
                            }}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            showNumber0={true}
                        />
                    }

                    <div className="form-group col-md-12">
                        <label className="mt-2" style={{ fontWeight: 'bold' }} htmlFor="fecAlta">Observaciones</label>
                        <input
                            className={"form-control undefined"}
                            type="text"
                            value={incidenceToAddOrEdit.tipoIncidencia.observaciones}
                            onChange={(event) => {
                                const observaciones = event.target.value
                                setIncidenceToAddOrEdit({
                                    ...incidenceToAddOrEdit,
                                    tipoIncidencia: {
                                        ...incidenceToAddOrEdit.tipoIncidencia,
                                        observaciones: observaciones
                                    }
                                })
                            }}
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        disabled={incidenceToAddOrEdit.tipoIncidencia.id > 0 ? false : true}
                        variant={"success"}
                        onClick={() => {
                            if (props.addOrEdit === formStates.add) {
                                props.addIncidence(incidenceToAddOrEdit)
                            }
                            if (props.addOrEdit === formStates.edit) {
                                props.saveIncidence(incidenceToAddOrEdit)
                            }
                            closeModal()
                        }}
                    >
                        {props.addOrEdit === formStates.add && "Añadir"}
                        {props.addOrEdit === formStates.edit && "Guardar cambios"}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            closeModal()
                        }}
                    >
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment >
    );
};

export default PaqueteriaAddIncidenceModal;