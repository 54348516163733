import { Action, Dispatch } from 'redux';
import { ApplicationState } from '../../ApplicationState';
import axios from 'axios';
import {
    inicialiceSearchFilterList, loading, addingOrEditing, searchFilterList, datosEntregaRequest, inicialiceAddress,
    OrderArraybyPropertiesOf, paqueteria, inicialicePaqueteria, searchedPaqueterias, client,
} from "aseguisShared";
import { Destination } from '../../common/PlacesAutocomplete/ClientsDestinationsAutocomplete';
import { Notify } from '../../common/Toastify/Notify';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface paqueteriaState {
    allPaqueterias: paqueteria[];
    paqueteriaForm: paqueteria;
    paqueteriaEditForm: paqueteria;
    addOrEditPaqueteria: number,
    paqueteriaGetLoading: number,
    historyAddList: paqueteria[]
    searchFilterPaqueteria: searchFilterList,
    totalPaqueteriaList: number,
    actualPage: number
    totalPages: number
    openCloseUploadModal: boolean
    openCloseEditPaqModal: boolean
    autoFocusActivated: boolean
}

export const initialState: paqueteriaState = {
    allPaqueterias: [],
    paqueteriaForm: inicialicePaqueteria,
    paqueteriaEditForm: inicialicePaqueteria,
    addOrEditPaqueteria: addingOrEditing.none,
    paqueteriaGetLoading: loading.pending,
    historyAddList: [],
    searchFilterPaqueteria: inicialiceSearchFilterList,
    totalPaqueteriaList: 0,
    actualPage: 0,
    totalPages: 0,
    openCloseUploadModal: false,
    openCloseEditPaqModal: false,
    autoFocusActivated: true,
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface saveFormPaqueteriaAction {
    type: 'SAVE_FORM_PAQUETERIA',
    paqueteria: paqueteria
}

export interface addEditPaqueteriaAction {
    type: 'CHANGE_ADD_EDIT_PAQUETERIA',
    addOrEdit: number
}

export interface cancelFormPaqueteriaAction {
    type: 'CANCEL_FORM_PAQUETERIA'
}

export interface addReceivedPaqueteriaAction {
    type: 'ADD_RECEIVED_PAQUETERIA',
    paqueteria: paqueteria
}

export interface saveEditReceivedPaqueteriaAction {
    type: 'SAVE_EDIT_RECEIVED_PAQUETERIA',
    paqueteria: paqueteria
}

export interface RequestAllPaqueteriaAction {
    type: 'REQUEST_ALL_PAQUETERIA'
}

export interface ReceivedAllPaqueteriaAction {
    type: 'RECEIVED_ALL_PAQUETERIA',
    paqueterias: searchedPaqueterias
}

export interface ReceivedDeletePaqueteriaAction {
    type: 'DELETE_RECEIVED_PAQUETERIA'
}

export interface saveSearchPaqueteriaAction {
    type: 'SAVE_SEARCH_FILTER_PAQUETERIA',
    searchFilterPaqueteria: searchFilterList
}

export interface setBothFormsPaqueteriaAction {
    type: 'SET_BOTH_FORMS_PAQUETERIA',
    paqueteria: paqueteria
}

export interface setOpenCloseModalUploadFileAction {
    type: 'SET_OPEN_CLOSE_MODAL_PAQ',
    openClose: boolean
}

export interface setOpenCloseModalEditPaqAction {
    type: 'SET_OPEN_CLOSE_MODAL_EDIT_PAQ',
    openClose: boolean
}

export interface setHistoryAddPaqueteriaAction {
    type: 'SET_HISTORY_ADD_PAQ',
    historyAddList: paqueteria[]
}

export interface setAutoFocusActivated {
    type: 'SET_AUTOFOCUS_PAQ_ACTIVATED',
    value: boolean
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = setOpenCloseModalEditPaqAction | saveFormPaqueteriaAction | cancelFormPaqueteriaAction |
    addReceivedPaqueteriaAction | setAutoFocusActivated | saveEditReceivedPaqueteriaAction | RequestAllPaqueteriaAction |
    setHistoryAddPaqueteriaAction | ReceivedAllPaqueteriaAction | setBothFormsPaqueteriaAction |
    addEditPaqueteriaAction | saveSearchPaqueteriaAction | setOpenCloseModalUploadFileAction;


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export function setFormsPaqueteria(paqueteria: paqueteria): setBothFormsPaqueteriaAction {
    return {
        type: 'SET_BOTH_FORMS_PAQUETERIA',
        paqueteria: paqueteria
    };
}

export function saveFormPaqueteria(paqueteria: paqueteria): saveFormPaqueteriaAction {
    return {
        type: 'SAVE_FORM_PAQUETERIA',
        paqueteria: paqueteria
    };
}

export function cancelFormPaqueteria(): cancelFormPaqueteriaAction {
    return {
        type: 'CANCEL_FORM_PAQUETERIA'
    };
}

export function addOrEditPaqueteria(addOrEdit: number): addEditPaqueteriaAction {
    return {
        type: 'CHANGE_ADD_EDIT_PAQUETERIA',
        addOrEdit: addOrEdit
    };
}

export function saveSearchFilterPaqueteria(searchFilterPaqueteria: searchFilterList): saveSearchPaqueteriaAction {
    return {
        type: 'SAVE_SEARCH_FILTER_PAQUETERIA',
        searchFilterPaqueteria: searchFilterPaqueteria
    };
}

export function setOpenCloseModalUploadFile(openClose: boolean): setOpenCloseModalUploadFileAction {
    return {
        type: 'SET_OPEN_CLOSE_MODAL_PAQ',
        openClose: openClose
    };
}

export function setOpenCloseModalEditPaq(openClose: boolean): setOpenCloseModalEditPaqAction {
    return {
        type: 'SET_OPEN_CLOSE_MODAL_EDIT_PAQ',
        openClose: openClose
    };
}

export function setHistoryAddListPaq(historyList: paqueteria[]): setHistoryAddPaqueteriaAction {
    return {
        type: 'SET_HISTORY_ADD_PAQ',
        historyAddList: historyList
    };
}

export function setAutoFocusPaq(value: boolean): setAutoFocusActivated {
    return {
        type: 'SET_AUTOFOCUS_PAQ_ACTIVATED',
        value: value
    };
}

/**************************************************************
 **************************************************************
 *
 * @param PAQUETERIA_OPERATIONS
 *
 * ************************************************************
 **************************************************************/

export function addPaqueteria(paqueteria: paqueteria) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: 'REQUEST_ALL_PAQUETERIA' });
        return axios.post('api/paqueteria/', paqueteria)
            .then((data) => {
                Notify("Albarán paquetería creado", "success")
                dispatch({ type: 'ADD_RECEIVED_PAQUETERIA', paqueteria: data.data });
            })
            .catch((error) => {
            })
    }
}

export function editPaqueteria(paqueteria: paqueteria) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: 'REQUEST_ALL_PAQUETERIA' });
        return axios.put('api/paqueteria/', paqueteria)
            .then((data) => {
                Notify("Albarán paquetería modificado", "success")
                dispatch({ type: 'SAVE_EDIT_RECEIVED_PAQUETERIA', paqueteria: data.data });
            })
            .catch((error) => {
            })
    }
}

export function pausePaqueteria(paqueteria: paqueteria) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: 'REQUEST_ALL_PAQUETERIA' });
        return axios.post('api/paqueteria/pauseResume/', paqueteria)
            .then((data) => {
                Notify("Albarán paquetería pausado", "success")
                dispatch({ type: 'SAVE_EDIT_RECEIVED_PAQUETERIA', paqueteria: data.data });
            })
            .catch((error) => {
            })
    }
}

export function resumePaqueteria(paqueteria: paqueteria) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: 'REQUEST_ALL_PAQUETERIA' });
        return axios.put('api/paqueteria/pauseResume/', paqueteria)
            .then((data) => {
                Notify("Albarán paquetería reanudado", "success")
                dispatch({ type: 'SAVE_EDIT_RECEIVED_PAQUETERIA', paqueteria: data.data });
            })
            .catch((error) => {
            })
    }
}

export function deleteOnePaqueteria(paqueteria: paqueteria) {
    return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
        return axios.post('api/paqueteria/delete', paqueteria)
            .then(res => {
                Notify("Albarán paquetería eliminado", "success")
                dispatch({ type: 'SAVE_EDIT_RECEIVED_PAQUETERIA', paqueteria: inicialicePaqueteria });
                const searchFilter = getState().paqueteria.searchFilterPaqueteria
                dispatch(requestAllPaqueterias(searchFilter))
            })
            .catch((error) => {
            })
    }
}

export function requestAllPaqueterias(searchPaqueteriaFilter: searchFilterList) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        dispatch({ type: 'REQUEST_ALL_PAQUETERIA' });
        return axios.post<searchedPaqueterias>('api/paqueteria/searchFilter/', searchPaqueteriaFilter)
            .then((data) => {
                dispatch({ type: 'RECEIVED_ALL_PAQUETERIA', paqueterias: data.data });
            })
            .catch((error) => {
            })
    }
}

export const requestOnePaqueteria = async (idPaq: string) => {
    let searchFilter = { ...inicialiceSearchFilterList, idMongo: idPaq }
    return await axios.post<searchedPaqueterias>('api/paqueteria/searchFilter/', searchFilter)
}

export function downloadPaqueteriaPDF(paqueterias: number[], client: client | null, cartaPorte: string) {
    let newFilter = inicialiceSearchFilterList
    if (cartaPorte.length > 0) {
        newFilter = { ...newFilter, cartaPorteExactly: cartaPorte, getAll: true }
    } else {
        newFilter = { ...newFilter, ids: paqueterias, getAll: true }
        if (client && client._id) {
            newFilter = { ...newFilter, clientsIDsMongo: [client._id] }
        }
    }
    return async (dispatch: Dispatch) => {
        return axios({ method: 'post', responseType: 'blob', url: 'api/paqueteria/downloadPDF/', data: newFilter })
            .then((data) => {
                window.open(URL.createObjectURL(data.data));
            })
            .catch((error) => {
            })
    }
}

export function downloadAttached(paqueteriaId: string) {
    return async (dispatch: Dispatch) => {
        return axios({ method: 'get', responseType: 'blob', url: 'api/paqueteria/downloadPDF/' + paqueteriaId })
            .then((data) => {
                window.open(URL.createObjectURL(data.data));
            })
            .catch((error) => {
            })
    }
}

export function exportToExcel(searchPaqueteriaFilter: searchFilterList) {
    return async (dispatch: Dispatch) => {
        return axios({ method: 'post', responseType: 'blob', url: 'api/paqueteria/createExcel/getList/', data: searchPaqueteriaFilter })
            .then((data) => {
                const blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Aseguis_albaranes_paqueteria.xlsx";
                link.click();
            })
            .catch((error) => {
            })
    }
}

export function addFilesToPaqueteria(datosEntrega: datosEntregaRequest) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        return axios.put('api/paqueteria/deliver/', datosEntrega)
            .then((data) => {
                dispatch(setFormsPaqueteria(data.data))
                dispatch(setOpenCloseModalUploadFile(false))
            })
            .catch((error) => {
            })
    }
}


export function markPaqAsDelivered(datosEntrega: datosEntregaRequest) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        return axios.post('api/paqueteria/deliver/', datosEntrega)
            .then((data) => {
                requestOnePaqueteria(datosEntrega.qrIdReaded[0])
                    .then((paq) => {
                        if (paq.data.paqueteriasResults.length === 1) {
                            dispatch(setFormsPaqueteria(paq.data.paqueteriasResults[0]))
                        }
                    })
                    .catch((error) => {
                    })
            })
            .catch((error) => {
            })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
function PaqueteriaReducer(state: paqueteriaState = initialState, incomingAction: Action): paqueteriaState {

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_BOTH_FORMS_PAQUETERIA': {
            return {
                ...state,
                paqueteriaForm: action.paqueteria,
                paqueteriaEditForm: action.paqueteria,
            }
        }
        case 'SAVE_FORM_PAQUETERIA': {
            return {
                ...state,
                paqueteriaEditForm: action.paqueteria,
            };
        }
        case 'CHANGE_ADD_EDIT_PAQUETERIA': {
            return {
                ...state,
                addOrEditPaqueteria: action.addOrEdit
            }
        }
        case 'CANCEL_FORM_PAQUETERIA': {
            return {
                ...state,
                historyAddList: []
            };
        }
        case 'SET_HISTORY_ADD_PAQ': {
            return {
                ...state,
                historyAddList: action.historyAddList
            };
        }
        case 'ADD_RECEIVED_PAQUETERIA': {
            let newPaqueteria: paqueteria = {
                ...action.paqueteria,
                datosGenerales: {
                    ...action.paqueteria.datosGenerales,
                    client: state.paqueteriaEditForm.datosGenerales.client,
                    entradaSalida: state.paqueteriaEditForm.datosGenerales.entradaSalida,
                },
                datosTransporte: {
                    ...action.paqueteria.datosTransporte,
                    cartaPorte: state.paqueteriaEditForm.datosTransporte.cartaPorte,
                }
            }
            let Origin: Destination = {
                address: state.paqueteriaEditForm.datosTransporte.address,
                name: state.paqueteriaEditForm.datosTransporte.destName,
                tel: state.paqueteriaEditForm.datosTransporte.tel
            }
            let Destiny: Destination = {
                address: state.paqueteriaEditForm.datosTransporte.addressDos,
                name: state.paqueteriaEditForm.datosTransporte.destNameDos,
                tel: state.paqueteriaEditForm.datosTransporte.telDos
            }
            if (state.paqueteriaEditForm.datosGenerales.entradaSalida === "Entrada") {
                Origin.address = inicialiceAddress
                Origin.name = ""
                Origin.tel = ""
                Destiny.address = state.paqueteriaEditForm.datosTransporte.addressDos
                Destiny.name = state.paqueteriaEditForm.datosTransporte.destNameDos
                Destiny.tel = state.paqueteriaEditForm.datosTransporte.telDos
            }
            if (state.paqueteriaEditForm.datosGenerales.entradaSalida === "Salida") {
                Origin.address = state.paqueteriaEditForm.datosTransporte.address
                Origin.name = state.paqueteriaEditForm.datosTransporte.destName
                Origin.tel = state.paqueteriaEditForm.datosTransporte.tel
                Destiny.address = inicialiceAddress
                Destiny.name = ""
                Destiny.tel = ""
            }
            return {
                ...state,
                paqueteriaForm: {
                    ...inicialicePaqueteria,
                    datosGenerales: {
                        ...inicialicePaqueteria.datosGenerales,
                        client: state.paqueteriaEditForm.datosGenerales.client,
                        entradaSalida: state.paqueteriaEditForm.datosGenerales.entradaSalida,
                        entrega: state.paqueteriaEditForm.datosGenerales.entrega,
                        recogida: state.paqueteriaEditForm.datosGenerales.recogida
                    },
                    datosTransporte: {
                        ...inicialicePaqueteria.datosTransporte,
                        address: Origin.address,
                        destName: Origin.name,
                        tel: Origin.tel,
                        addressDos: Destiny.address,
                        destNameDos: Destiny.name,
                        telDos: Destiny.tel,
                        cartaPorte: state.paqueteriaEditForm.datosTransporte.cartaPorte,
                    }
                },
                paqueteriaEditForm: {
                    ...inicialicePaqueteria,
                    datosGenerales: {
                        ...inicialicePaqueteria.datosGenerales,
                        client: state.paqueteriaEditForm.datosGenerales.client,
                        entradaSalida: state.paqueteriaEditForm.datosGenerales.entradaSalida,
                        entrega: state.paqueteriaEditForm.datosGenerales.entrega,
                        recogida: state.paqueteriaEditForm.datosGenerales.recogida
                    },
                    datosTransporte: {
                        ...inicialicePaqueteria.datosTransporte,
                        address: Origin.address,
                        destName: Origin.name,
                        tel: Origin.tel,
                        addressDos: Destiny.address,
                        destNameDos: Destiny.name,
                        telDos: Destiny.tel,
                        cartaPorte: state.paqueteriaEditForm.datosTransporte.cartaPorte,
                    }
                },
                historyAddList: [...state.historyAddList, newPaqueteria].sort(OrderArraybyPropertiesOf<paqueteria>(['-id'])),
                addOrEditPaqueteria: addingOrEditing.adding
            };
        }
        case 'SAVE_EDIT_RECEIVED_PAQUETERIA': {
            return {
                ...state,
                paqueteriaForm: {
                    ...action.paqueteria,
                    datosGenerales: {
                        ...action.paqueteria.datosGenerales,
                        client: state.paqueteriaEditForm.datosGenerales.client
                    },
                },
                paqueteriaEditForm: {
                    ...action.paqueteria,
                    datosGenerales: {
                        ...action.paqueteria.datosGenerales,
                        client: state.paqueteriaEditForm.datosGenerales.client
                    },
                },
            };
        }
        case 'REQUEST_ALL_PAQUETERIA': {
            return {
                ...state,
                paqueteriaGetLoading: loading.pending
            };
        }
        case 'RECEIVED_ALL_PAQUETERIA': {
            return {
                ...state,
                paqueteriaGetLoading: loading.done,
                allPaqueterias: action.paqueterias.paqueteriasResults,
                totalPaqueteriaList: action.paqueterias.totalResults,
                actualPage: action.paqueterias.numPage,
                totalPages: action.paqueterias.totalPages
            };
        }
        case 'SAVE_SEARCH_FILTER_PAQUETERIA': {
            return {
                ...state,
                searchFilterPaqueteria: action.searchFilterPaqueteria,
                paqueteriaGetLoading: loading.pending
            };
        }
        case 'SET_OPEN_CLOSE_MODAL_PAQ': {
            return {
                ...state,
                openCloseUploadModal: action.openClose
            };
        }
        case 'SET_OPEN_CLOSE_MODAL_EDIT_PAQ': {
            return {
                ...state,
                openCloseEditPaqModal: action.openClose
            };
        }
        case 'SET_AUTOFOCUS_PAQ_ACTIVATED': {
            return {
                ...state,
                autoFocusActivated: action.value
            };
        }
        default:
            return state;
    }
};

export default PaqueteriaReducer;