import { useSelector } from "react-redux";
import React, { Fragment, useEffect, ReactElement, useState, useRef } from "react";
import { ApplicationState } from '../../ApplicationState';
import { postalCode, postalCodeList, poblations, getValueLoc, locationType, validateAlphaNumeric, validateFormEmpty, CapitalizefirstLetter } from 'aseguisShared';
import axios from 'axios';
import Match from 'autosuggest-highlight/match';
import Parse from 'autosuggest-highlight/parse';
import AutoSuggest from "react-autosuggest";
import CountryModal from "../PlacesAutocomplete/CountryModal";

interface infoToPostalCodeAutocomplete {
    onClick: (postalCode: postalCode, locationType: locationType) => void
    location: string
    Suggestionswidth: "100" | "200"
    country: string
}

function PostalCodesAutocomplete(props: infoToPostalCodeAutocomplete): ReactElement {

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    
    const [valueToSearch, setValueToSearch] = useState(props.location)
    const [searchLoc, setSearchLoc] = useState(false)
    const [inputFocus, setInputFocus] = useState(false)
    const [placesToSuggest, setPlacesToSuggest] = useState<poblations[]>([])
    const [selectedPlace, setSelectedPlace] = useState(props.location)

    //MODAL seleccionar pais
    const [countrySelected, setCountrySelected] = useState(props.country || "ES")
    const [modalCountry, setModalCountry] = useState(false)

    const suggestionsContainerRef = useRef<HTMLSpanElement>(null);
   
    function addAllPlaces(postalCodeList: postalCodeList): poblations[] {
        var allPlaces = postalCodeList.cpList
        for (var poblation of postalCodeList.poblacionList) {
            allPlaces.push(poblation)
        }
        for (var provincia of postalCodeList.provinciaList) {
            allPlaces.push(provincia)
        }
        return allPlaces
    }

    useEffect(() => {
        if ((searchLoc === true) && (isAppLoading === false) && valueToSearch.length > 2) {
            const timeout = setTimeout(() => {
                axios
                    .post<postalCodeList>('api/localities/getLocalities/', { StringSearch: valueToSearch, Country: countrySelected })
                    .then((response) => {
                        if (response.data) {
                            setPlacesToSuggest(addAllPlaces(response.data));
                        }
                        setSearchLoc(false)
                    })

            }, 500);
            setSearchLoc(false)
            return () => { clearTimeout(timeout) }
        }
    }, [valueToSearch])


    useEffect(() => {
        /* para cuando se hace click en restablecer, que se restablezca el valor */
        if (props.location !== valueToSearch) {
            setValueToSearch(props.location)
        }

    }, [props.location])


    useEffect(() => {
        if (inputFocus === false && props.location) {
            setValueToSearch(props.location)
        }
    }, [inputFocus, props.location]);

    function renderSuggestion(suggestion: poblations, query: any): JSX.Element {
        const textToHigh = "" + query.query
        const place = suggestion.namePobl
        const matches = Match(place, textToHigh, { insideWords: true });
        const parts = Parse(place, matches)
        return (
            <div>
                <div>
                    <b>{suggestion.typeLoc + ": "}</b>
                    {parts.map((part, index) => {
                        const className = part.highlight ? 'highlight' : '';
                        return (
                            <span className={className} key={index}>{part.text}</span>
                        );
                    })}
                    {((suggestion.typeLoc === locationType.cp) && (suggestion.cps.length > 0)) &&
                        (
                            " (" +
                            suggestion.cps[0].poblacion +
                            ((suggestion.cps[0].provincia.length > 0) ? (" " + suggestion.cps[0].provincia + "") : ("")) +
                            " " + suggestion.cps[0].pais.substring(0, 2) +
                            ")"
                        )
                    }
                    {((suggestion.typeLoc === locationType.localidad) && (suggestion.cps.length > 0)) &&
                        (
                            " (" +
                            suggestion.cps[0].provincia + " " +
                            suggestion.cps[0].pais.substring(0, 2) +
                            ")"
                        )
                    }
                    {((suggestion.typeLoc === locationType.provincia) && (suggestion.cps.length > 0)) &&
                        (" (" + suggestion.cps[0].pais + ")")
                    }
                </div>
            </div>
        );
    }

    return (

        <Fragment>

            <div className="input-group">

                <div className="input-group-prepend"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        setModalCountry(true)
                    }}
                >
                    <div
                        className="input-group-text"
                        style={{
                            paddingLeft: "2px !important",
                            paddingTop: "0px !important",
                            paddingBottom: "0px !important",
                            paddingRight: "0px !important"
                        }}
                    >
                        {countrySelected}
                    </div>
                </div>

                {/* MODAL PARA CAMBIAR DE PAIS */}
                <CountryModal
                    setCountrySelected={setCountrySelected}
                    modalOpened={modalCountry}
                    setModalOpened={setModalCountry}
                />

                <span ref={suggestionsContainerRef} style={{ flex: "1" }}>
                    <AutoSuggest
                        theme={{
                            suggestionsContainerOpen:
                                (props.Suggestionswidth === "200" ? 'react-autosuggest__suggestions-container--open-200' : 'react-autosuggest__suggestions-container--open-100'),
                            container: 'react-autosuggest__container',
                            containerOpen: 'react-autosuggest__container--open',
                            input: 'react-autosuggest__input',
                            inputOpen: 'react-autosuggest__input--open',
                            inputFocused: 'react-autosuggest__input--focused',
                            suggestionsContainer: 'react-autosuggest__suggestions-container',
                            suggestionsList: 'react-autosuggest__suggestions-list',
                            suggestion: 'react-autosuggest__suggestion',
                            suggestionFirst: 'react-autosuggest__suggestion--first',
                            suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
                            sectionContainer: 'react-autosuggest__section-container',
                            sectionContainerFirst: 'react-autosuggest__section-container--first',
                            sectionTitle: 'react-autosuggest__section-title'
                        }}
                        onSuggestionHighlighted={({ suggestion }) => {
                            if (suggestion && suggestionsContainerRef.current) {
                                const index = placesToSuggest.findIndex(s => s === suggestion);
                                const suggestionElements = suggestionsContainerRef.current.querySelectorAll('.react-autosuggest__suggestion');
                                const highlightedElement = suggestionElements[index];
                                if (highlightedElement) {
                                    highlightedElement.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'nearest'
                                    });
                                }
                            }
                        }}
                        suggestions={placesToSuggest}
                        onSuggestionsClearRequested={() =>
                            setPlacesToSuggest([])
                        }
                        onSuggestionsFetchRequested={({ value }) => {
                            setSearchLoc(true)
                        }}
                        onSuggestionSelected={(_, { suggestionValue, suggestion }) => {                            
                            props.onClick(suggestion.cps[suggestion.cps.length - 1], suggestion.typeLoc)
                            setSelectedPlace(suggestionValue)
                        }}
                        getSuggestionValue={(suggestion) =>                           
                            getValueLoc(suggestion.typeLoc, suggestion.cps[suggestion.cps.length - 1])
                        }
                        renderSuggestion={renderSuggestion}
                        inputProps={{
                            placeholder: "Escriba un lugar",
                            value: valueToSearch,
                            onChange: (event, { newValue, method }) => {
                                var value = CapitalizefirstLetter(validateAlphaNumeric(newValue))
                                value = value.trimStart()
                                setValueToSearch(value)
                                if (value.length === 0) {
                                    setPlacesToSuggest([]);
                                }
                            },
                            type: "text",
                            name: "dest_search",
                            className: validateFormEmpty(selectedPlace, 2),
                            autoComplete: "off",
                            onBlur: () => {                               
                                setInputFocus(false)
                                setSearchLoc(false)
                                setPlacesToSuggest([])
                                if (selectedPlace !== "") {
                                    setValueToSearch(selectedPlace)
                                } else {
                                    setValueToSearch("")
                                }
                            },
                            onFocus: () => {
                                setInputFocus(true)
                                setValueToSearch("")
                            },
                        }}
                    />
                </span>
                
            </div>

        </Fragment >

    );
};

export default PostalCodesAutocomplete;