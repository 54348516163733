import React, { Fragment, useEffect, ReactElement, useState, useRef, useLayoutEffect } from "react";
import PoweredByGoogle from "./../../assets/powered_by_google/desktop/powered_by_google_on_white.png";
import { validateFormEmpty } from "aseguisShared";
import axios from "axios";
import AutoSuggest from "react-autosuggest";
import Match from 'autosuggest-highlight/match';
import Parse from 'autosuggest-highlight/parse';
import { Destination } from "./ClientsDestinationsAutocomplete";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../ApplicationState";

export interface autocompleteResponse {
    predictions: predictionAutocomplete[];
}

export interface predictionAutocomplete {
    description: string;
    place_id: string
}

interface infoToGoogleAutocomplete {
    textToSearch: string
    saveTextToSearch: (address: string) => void
    onSelectAddress: (dest: Destination) => void
    country: string

    autoFocus?: boolean
    onBlur?: () => void
}

function GoogleAutocomplete(props: infoToGoogleAutocomplete): ReactElement {

    const tokenGooglePlaces: string = useSelector((state: ApplicationState) => state.globalState.sessionTokenForGooglePlaces);

    const suggestionsContainerRef = useRef<HTMLSpanElement>(null);
    const [goToApi, setGoToApi] = useState(true)
    const [googleSuggestions, setGoogleSuggestions] = useState<predictionAutocomplete[]>([])

    const refFocus = useRef<HTMLInputElement>(null);
    useLayoutEffect(() => {
        if (null !== refFocus.current && props.autoFocus) {
            refFocus.current.focus()
        }
    })

    useEffect(() => {
        if ((goToApi === true) && props.textToSearch.length > 0 && tokenGooglePlaces.length > 0) {
            const timeout2 = setTimeout(() => {
                const newRequest = {
                    InputString: props.textToSearch,
                    SessionToken: tokenGooglePlaces,
                    Country: props.country
                }
                axios
                    .post<autocompleteResponse>('api/googleAutocomplete/', newRequest)
                    .then((response) => {
                        if (response.data) {
                            setGoogleSuggestions(response.data.predictions)
                        }
                        setGoToApi(false)
                    })
                    .catch((error) => {
                        setGoogleSuggestions([])
                    })
            }, 200);
            return () => { clearTimeout(timeout2) }
        }
    }, [props.textToSearch])

    function onValueChanged(newValue: string): void {
        props.saveTextToSearch(newValue)
        if (newValue.length === 0) {
            setGoogleSuggestions([])
        }
    }

    function renderSuggestion(suggestion: predictionAutocomplete, query: any): JSX.Element {
        const textToHigh = "" + query.query
        const direction = suggestion.description
        const matches = Match(direction, textToHigh, { insideWords: true });
        const parts = Parse(direction, matches)
        return (
            <Fragment>

                <span>
                    {parts.map((part, index) => {
                        const className = part.highlight ? 'highlight' : '';
                        return (
                            <span className={className} key={index}>{part.text}</span>
                        );
                    })}
                </span>

                {suggestion.place_id === googleSuggestions[googleSuggestions.length - 1].place_id &&
                    <span style={{ marginTop: 32, display: 'inline-block', float: 'right' }}>
                        <img src={PoweredByGoogle} alt="Powered by Google" />
                    </span>
                }

            </Fragment>
        );
    }

    return (
        <Fragment>
            <span ref={suggestionsContainerRef} style={{ flex: "1" }}>
                <AutoSuggest
                    onSuggestionHighlighted={({ suggestion }) => {
                        if (suggestion && suggestionsContainerRef.current) {
                            const index = googleSuggestions.findIndex(s => s === suggestion);
                            const suggestionElements = suggestionsContainerRef.current.querySelectorAll('.react-autosuggest__suggestion');
                            const highlightedElement = suggestionElements[index];
                            if (highlightedElement) {
                                highlightedElement.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'nearest'
                                });
                            }
                        }
                    }}
                    suggestions={googleSuggestions}
                    onSuggestionsClearRequested={() => {
                        setGoogleSuggestions([])
                    }}
                    onSuggestionsFetchRequested={({ value }) => {
                        setGoToApi(true)
                        setGoogleSuggestions([])
                    }}
                    onSuggestionSelected={async (event, { suggestionValue, suggestion }) => {
                        if (tokenGooglePlaces.length > 0) {
                            const newRequest = {
                                PlaceId: suggestion.place_id,
                                SessionToken: tokenGooglePlaces,
                            }
                            const resp = await axios.post<Destination>('api/googlePlaceDetails/', newRequest)
                            if (resp) {
                                const destination = resp.data
                                props.onSelectAddress(destination)
                            }
                        }
                    }}
                    getSuggestionValue={(suggestion) =>
                        suggestion.description
                    }
                    renderSuggestion={renderSuggestion}
                    inputProps={{
                        placeholder: "Escriba un lugar para autocompletar...",
                        value: props.textToSearch,
                        onChange: (event, { newValue, method }) => {
                            onValueChanged(newValue)
                        },
                        type: "text",
                        name: "dest_search",
                        className: validateFormEmpty(props.textToSearch, 2),
                        autoComplete: "off",
                        onBlur: () => {
                            setGoToApi(false)
                            setGoogleSuggestions([])

                            if (props.onBlur) {
                                props.onBlur()
                            }
                        },
                        onFocus: () => {
                        },
                        ref: refFocus
                    }}
                />
            </span>
        </Fragment >
    );

};

export default GoogleAutocomplete;