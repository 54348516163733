import React, { Fragment, ReactElement, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../ApplicationState";
import UploadMultipleFiles from "../../common/Inputs/UploadMultipleFiles";
import { getNumberOfPendingFiles, uploadScannedAlbaranes, base64Files } from "./UploadScannedAlbaranesStore";

function UploadScannedAlbaranes(): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();
    const numberPendingFiles: number = useSelector((state: ApplicationState) => state.uploadScannedAlbaranes.numberPendingFiles);

    const [base64Files, setBase64Files] = useState<base64Files[]>([])
    const [allBase64Files, setAllBase64Files] = useState<base64Files[]>([])

    useEffect(() => {
        //obtenemos el número de ficheros pendientes a procesar
        dispatch(getNumberOfPendingFiles());
    }, []);

    const onFileUpload = () => {
        if (allBase64Files.length > 0) {
            dispatch(uploadScannedAlbaranes(allBase64Files));
            setAllBase64Files([])
            setBase64Files([])
        }
    }

    const addMoreFiles = () => {
        if (base64Files.length > 0) {
            setAllBase64Files([...allBase64Files, ...base64Files])
            setBase64Files([])
        }
    }

    const deleteFiles = () => {
        setAllBase64Files([])
        setBase64Files([])
    }

    const deleteFile = () => {
        setBase64Files([])
    }

    return (
        <Fragment>

            <div className="card shadow mb-4">

                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">Escoja los archivos escaneados (*.pdf, *.tif)</h6>
                </div>

                <UploadMultipleFiles
                    onFileUpload={onFileUpload}
                    allFilesCount={allBase64Files}
                    selectedFilesCount={base64Files}
                    addMoreFiles={addMoreFiles}
                    setFilesOnBase64={setBase64Files}
                    deleteAllFiles={deleteFiles}
                    deleteSelectedFile={deleteFile}
                />

            </div>

            <div className="card shadow mb-4">

                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">Ficheros Pendientes de procesar</h6>
                </div>

                <div className="card-body">

                    <div className="form-row pt-3">
                        
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <span style={{ fontWeight: "bold", marginRight: "8px" }}>{numberPendingFiles}</span>                              
                            <span>{"Ficheros pendientes"}</span>                              
                        </div>
                        <div className="form-group col-md-12">
                            <button
                                style={{ marginTop: "30px" }}
                                className="btn btn-info mb-1"
                                onClick={() => {
                                    dispatch(getNumberOfPendingFiles())
                                }}
                            >
                                Actualizar
                            </button>
                        </div>
                    </div>

                </div>

            </div>

        </Fragment>

    );
};

export default UploadScannedAlbaranes;