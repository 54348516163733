import axios from 'axios';
import { Action, Dispatch } from 'redux';
import { ApplicationState } from '../../ApplicationState';
import {
    reviRepa, initialReviRepa, inicialiceSearchFilterList, searchFilterList,
    addingOrEditing, loading, searchedReviRepa
} from 'aseguisShared';
import { Notify } from '../../common/Toastify/Notify';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface reviRepaState {
    reviRepaList: reviRepa[];
    reviRepaForm: reviRepa,
    reviRepaEditForm: reviRepa,
    addOrEditReviRepa: number,
    isLoadingReviRepa: number,
    searchFilterReviRepa: searchFilterList,
    totalReviRepaList: number,
    actualPage: number,
    totalPages: number
}

export const initialReviRepaState: reviRepaState = {
    reviRepaList: [],
    reviRepaForm: initialReviRepa,
    reviRepaEditForm: initialReviRepa,
    addOrEditReviRepa: 0,
    isLoadingReviRepa: 0,
    searchFilterReviRepa: inicialiceSearchFilterList,
    totalReviRepaList: 0,
    actualPage: 0,
    totalPages: 0
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface saveFormReviRepaAction {
    type: 'SAVE_FORM_REVIREPA',
    reviRepa: reviRepa
}

export interface addEditReviRepaAction {
    type: 'CHANGE_ADD_EDIT_REVIREPA',
    addOrEdit: number
}

export interface addReceivedReviRepaAction {
    type: 'ADD_RECEIVED_REVIREPA',
    reviRepa: reviRepa
}

export interface saveEditReceivedReviRepaAction {
    type: 'SAVE_EDIT_RECEIVED_REVIREPA',
    reviRepa: reviRepa
}

export interface ReceivedAllReviRepaAction {
    type: 'RECEIVED_ALL_REVIREPA',
    reviRepas: searchedReviRepa
}

export interface saveSearchReviRepaTextAction {
    type: 'SAVE_SEARCH_FILTER_REVIREPA',
    searchFilterReviRepa: searchFilterList
}

export interface setBothFormsReviRepaAction {
    type: 'SET_BOTH_FORMS_REVIREPA',
    reviRepa: reviRepa
}

export interface RequestAllReviRepaAction {
    type: 'REQUEST_ALL_REVIREPA'
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = saveFormReviRepaAction | addEditReviRepaAction |
    addReceivedReviRepaAction | saveEditReceivedReviRepaAction | ReceivedAllReviRepaAction |
    saveSearchReviRepaTextAction | setBothFormsReviRepaAction | RequestAllReviRepaAction;


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export function setFormsReviRepa(reviRepa: reviRepa): setBothFormsReviRepaAction {
    return {
        type: 'SET_BOTH_FORMS_REVIREPA',
        reviRepa: reviRepa
    };
}

export function saveFormReviRepa(reviRepa: reviRepa): saveFormReviRepaAction {
    return {
        type: 'SAVE_FORM_REVIREPA',
        reviRepa: reviRepa
    };
}

export function addOrEditReviRepa(addOrEdit: number): addEditReviRepaAction {
    return {
        type: 'CHANGE_ADD_EDIT_REVIREPA',
        addOrEdit: addOrEdit
    };
}

export function saveSearchFilterReviRepa(searchReviRepaFilter: searchFilterList): saveSearchReviRepaTextAction {
    return {
        type: 'SAVE_SEARCH_FILTER_REVIREPA',
        searchFilterReviRepa: searchReviRepaFilter
    };
}

export function addReviRepa(reviRepa: reviRepa) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: 'REQUEST_ALL_REVIREPA' });
        return axios.post('api/reviRepa/', reviRepa)
            .then((data) => {
                Notify(reviRepa.typeReviRepa + " creada", "success")
                dispatch({ type: 'ADD_RECEIVED_REVIREPA', reviRepa: data.data });
            })
            .catch((error) => {
            })
    }
}

export function editReviRepa(reviRepa: reviRepa) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: 'REQUEST_ALL_REVIREPA' });
        return axios.put('api/reviRepa/', reviRepa)
            .then((data) => {
                Notify(reviRepa.typeReviRepa + " modificada", "success")
                dispatch({ type: 'SAVE_EDIT_RECEIVED_REVIREPA', reviRepa: data.data });
            })
            .catch((error) => {
            })
    }
}

export function requestAllReviRepas(searchReviRepaFilter: searchFilterList) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        dispatch({ type: 'REQUEST_ALL_REVIREPA' });
        return axios.post<searchedReviRepa>('api/reviRepa/searchFilter/', searchReviRepaFilter)
            .then((data) => {
                dispatch({ type: 'RECEIVED_ALL_REVIREPA', reviRepas: data.data });
            })
            .catch((error) => {
            })
    }
}

export const requestOneReviRepa = async (idReviRepa: string) => {
    let searchFilter = { ...inicialiceSearchFilterList, idMongo: idReviRepa }
    return await axios.post<searchedReviRepa>('api/reviRepa/searchFilter/', searchFilter)
}

export function exportReviRepaToExcel(searchFilter: searchFilterList) {
    return async (dispatch: Dispatch) => {
        return axios({ method: 'post', responseType: 'blob', url: 'api/revirepa/createExcel/getList/', data: searchFilter })
            .then((data) => {
                const blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Aseguis_revisiones_reparaciones.xlsx";
                link.click();
            })
            .catch((error) => {
            })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
function reviRepaReducer(state: reviRepaState = initialReviRepaState, incomingAction: Action): reviRepaState {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_BOTH_FORMS_REVIREPA': {
            return {
                ...state,
                reviRepaForm: action.reviRepa,
                reviRepaEditForm: action.reviRepa,
            }
        }
        case 'SAVE_FORM_REVIREPA': {
            return {
                ...state,
                reviRepaEditForm: action.reviRepa,
            };
        }
        case 'CHANGE_ADD_EDIT_REVIREPA': {
            return {
                ...state,
                addOrEditReviRepa: action.addOrEdit
            }
        }
        case 'ADD_RECEIVED_REVIREPA': {
            return {
                ...state,
                reviRepaForm: action.reviRepa,
                reviRepaEditForm: action.reviRepa,
                addOrEditReviRepa: addingOrEditing.edditing
            };
        }
        case 'SAVE_EDIT_RECEIVED_REVIREPA': {
            return {
                ...state,
                reviRepaForm: action.reviRepa,
                reviRepaEditForm: action.reviRepa
            };
        }
        case 'REQUEST_ALL_REVIREPA': {
            return {
                ...state,
                isLoadingReviRepa: loading.pending
            };
        }
        case 'RECEIVED_ALL_REVIREPA': {
            return {
                ...state,
                isLoadingReviRepa: loading.done,
                reviRepaList: action.reviRepas.reviRepaResults,
                totalReviRepaList: action.reviRepas.totalResults,
                actualPage: action.reviRepas.numPage,
                totalPages: action.reviRepas.totalPages
            };
        }
        case 'SAVE_SEARCH_FILTER_REVIREPA': {
            return {
                ...state,
                searchFilterReviRepa: action.searchFilterReviRepa,
                isLoadingReviRepa: loading.pending
            };
        }
        default:
            return state;
    }
};

export default reviRepaReducer;