import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from '../../ApplicationState';
import { setActivateCreateBill } from "./BillsStore";
import ClientsAutocomplete from "../Clients/ClientsAutocomplete";
import PaqueteriaAutocomplete from "../Paqueteria/PaqueteriaAutocomplete";
import {
    albaranesToBill, albaranToBill, paqueteria, company, getFormattedDate, suscriptionData,
    inicialiceDate, cargaCompleta, client, addingOrEditing, queFacturarTipos,
} from "aseguisShared";
import CargaCompletaAutocomplete from "../CargaCompleta/CargaCompletaAutocomplete";
import BillAlbaranCard from "./BillAlbaranCard";
import InputDate from "../../common/Inputs/InputDate";
import { GetSuscriptionDataFromLocalStorage } from "../../common/commonInterfaces/commonFunctions";

interface infoToBillAddForm {
    isEdditing: number;
    albaranesToBill: albaranesToBill,
    setAlbaranesToBill: (albaranes: albaranesToBill) => void
    doOnRender?: () => void,
}
function BillsAddForm(props: infoToBillAddForm): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();

    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const company: company = useSelector((state: ApplicationState) => state.company.company);
    const numResetForm: number = useSelector((state: ApplicationState) => state.bills.resetForm);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [queFacturar, setQueFacturar] = React.useState<string>(queFacturarTipos.todo);
    const [fechaFactura, setFechaFactura] = React.useState(new Date());
    const [fechaDesde, setFechaDesde] = React.useState<Date>(inicialiceDate.year1000);
    const [fechaHasta, setFechaHasta] = React.useState<Date>(inicialiceDate.year1000);
    const [clientsToBill, setClientsToBill] = React.useState<client[]>([]);
    const [paqueteriasToBill, setPaqueteriasToBill] = React.useState<paqueteria[]>([]);
    const [cargasToBill, setCargasToBill] = React.useState<cargaCompleta[]>([]);
    const [sendEmail, setSendEmail] = React.useState(true);
    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    useEffect(() => {
        setQueFacturar(queFacturarTipos.todo)
        setFechaDesde(inicialiceDate.year1000)
        setFechaHasta(inicialiceDate.year1000)
        setClientsToBill([])
        setPaqueteriasToBill([])
        setCargasToBill([])
    }, [numResetForm]);

    const organizarAlbaranes = () => {

        var albaranes: albaranToBill[] = []

        for (var client of clientsToBill) {
            albaranes.push({
                client,
                cargas: [],
                paqueterias: []
            })
        }

        for (var carga of cargasToBill) {
            var found = false
            for (var albaran of albaranes) {
                if (albaran.client.id == carga.datosGenerales.client.id) {
                    albaran.cargas.push(carga)
                    found = true
                }
            }
            if (found === false) {
                albaranes.push({
                    client: carga.datosGenerales.client,
                    cargas: [carga],
                    paqueterias: []
                })
            }
        }

        for (var paqueteria of paqueteriasToBill) {
            var found = false
            for (var albaran of albaranes) {
                if (albaran.client.id == paqueteria.datosGenerales.client.id) {
                    albaran.paqueterias.push(paqueteria)
                    found = true
                }
            }
            if (found === false) {
                albaranes.push({
                    client: paqueteria.datosGenerales.client,
                    cargas: [],
                    paqueterias: [paqueteria]
                })
            }
        }

        props.setAlbaranesToBill({
            ...props.albaranesToBill,
            queFacturar: queFacturar,
            fechaFactur: fechaFactura,
            fechaDesde: fechaDesde != null ? fechaDesde : inicialiceDate.year1000,
            fechaHasta: fechaHasta != null ? fechaHasta : inicialiceDate.year1000,
            albaranes: albaranes,
            sendBillEmail: sendEmail
        })
    }

    useEffect(() => {
        organizarAlbaranes()
    }, [
        queFacturar, fechaFactura, fechaDesde, fechaHasta, clientsToBill, clientsToBill.length,
        cargasToBill, cargasToBill.length, paqueteriasToBill, paqueteriasToBill.length, sendEmail
    ]);


    useEffect(() => {
        if (props.doOnRender) {
            props.doOnRender()
        }
    }, []);

    useEffect(() => {
        //desactivamos el crear al renderizar
        dispatch(setActivateCreateBill(false));
    }, []);


    return (
        <Fragment>

            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">Crear factura</h6>
                </div>

                <div className="card-body">

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label style={{ fontWeight: 'bold' }} htmlFor="fecAlta">Fecha de alta</label>
                            <div>{getFormattedDate()}</div>
                        </div>

                        <InputDate
                            colMesure="4"
                            editEnabled={addingOrEditing.edditing}
                            addOrEdit={addingOrEditing.edditing}
                            name="Fecha de factura"
                            valueEdit={fechaFactura}
                            value={fechaFactura}
                            onChange={(date) => {
                                setFechaFactura(date)
                            }}
                        />

                        <div className="form-group col-md-4">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="name">Enviar facturas a cada cliente por email</label>

                                <select
                                    value={sendEmail === true ? "Si" : "No"}
                                    className="form-control undefined"
                                    id="panelDeControl"
                                    onChange={(event) => {
                                        var result = false;
                                        if (event.currentTarget.value === "Si") {
                                            var result = true;
                                        }
                                        setSendEmail(result)
                                    }}>
                                    <option value="Si">Si</option>
                                    <option value="No">No</option>
                                </select>

                            </div>
                        </div>

                    </div>

                    <div className="form-row" style={{ marginTop: "20px" }}>

                        <InputDate
                            colMesure="4"
                            editEnabled={addingOrEditing.edditing}
                            addOrEdit={addingOrEditing.edditing}
                            name="Facturar desde"
                            valueEdit={fechaDesde}
                            value={fechaDesde}
                            onChange={(date) => {
                                setFechaDesde(date)
                                props.setAlbaranesToBill({
                                    ...props.albaranesToBill,
                                    fechaDesde: date
                                })
                            }}
                        />

                        <InputDate
                            colMesure="4"
                            editEnabled={addingOrEditing.edditing}
                            addOrEdit={addingOrEditing.edditing}
                            name="Facturar hasta"
                            valueEdit={fechaHasta}
                            value={fechaHasta}
                            onChange={(date) => {
                                setFechaHasta(date)
                                props.setAlbaranesToBill({
                                    ...props.albaranesToBill,
                                    fechaHasta: date
                                })
                            }}
                        />

                        {(suscriptionData.paqueteria) &&
                            <div className="form-group col-md-4">
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="name">Que facturar</label>
                                    <select
                                        value={queFacturar}
                                        className={"form-control undefined"}
                                        onChange={(value) => {
                                            setQueFacturar(value.currentTarget.value)
                                            props.setAlbaranesToBill({
                                                ...props.albaranesToBill,
                                                queFacturar: queFacturar
                                            })
                                        }}>
                                        <option value={queFacturarTipos.todo}>{queFacturarTipos.todo}</option>
                                        <option value={queFacturarTipos.paqueteria}>{queFacturarTipos.paqueteria}</option>
                                        <option value={queFacturarTipos.carga}>{queFacturarTipos.carga}</option>
                                    </select>
                                </div>
                            </div>
                        }

                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="name">Añadir clientes</label>

                                <ClientsAutocomplete
                                    clientName={""}
                                    setClient={(client) => {
                                        setClientsToBill([...clientsToBill, { ...client }])
                                    }}
                                    clientsToExclude={clientsToBill}
                                    keepClient={false}
                                />

                            </div>
                        </div>

                        {suscriptionData.paqueteria &&
                            <div className="form-group col-md-4">
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="name">Añadir paquetería</label>
                                    <PaqueteriaAutocomplete
                                        setPaqueteria={(paqueteria) => {
                                            setPaqueteriasToBill([...paqueteriasToBill, { ...paqueteria, idPaqRender: null }])
                                        }}
                                        keepPaqueteria={false}
                                        PaqueteriasToExclude={paqueteriasToBill}
                                        fromDate={props.albaranesToBill.fechaDesde}
                                        untilDate={props.albaranesToBill.fechaHasta}
                                        onlyDelivered={company.billNotDelivered == true ? false : true}
                                        notShowFacturated={true}
                                    />

                                </div>
                            </div>
                        }

                        <div className="form-group col-md-4">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="name">Añadir carga completa</label>

                                <CargaCompletaAutocomplete
                                    setCargaCompleta={(carga) => {
                                        setCargasToBill([...cargasToBill, { ...carga, idCargaRender: null }])
                                    }}
                                    keepCargaCompleta={false}
                                    cargasToExclude={cargasToBill}
                                    fromDate={props.albaranesToBill.fechaDesde}
                                    untilDate={props.albaranesToBill.fechaHasta}
                                    onlyDelivered={company.billNotDelivered == true ? false : true}
                                    notShowFacturated={true}
                                />

                            </div>
                        </div>

                    </div>

                    <button
                        style={{ marginTop: 10, marginBottom: 30 }}
                        className="btn btn-secondary btn-sm"
                        onClick={() => {
                            setQueFacturar(queFacturarTipos.todo)
                            setFechaDesde(inicialiceDate.year1000)
                            setFechaHasta(inicialiceDate.year1000)
                        }}>
                        Borrar filtro
                    </button>

                    <br></br>

                    <label style={{ fontWeight: 'bold', marginTop: "20px" }} htmlFor="name">Albaranes a facturar</label>
                    &nbsp;&nbsp;&nbsp;

                    {company.billNotDelivered == true ?
                        <label style={{ fontSize: '12px' }}> {" *Se facturarán todos los albaranes, aunque no hayan sido entregados"}</label>
                        :
                        <label style={{ fontSize: '12px' }}> {" *Solo se facturarán los albaranes entregados"}</label>
                    }

                    <div className="form-row">
                        <BillAlbaranCard
                            albaranesToBill={props.albaranesToBill}
                            fechaDesde={fechaDesde}
                            fechaHasta={fechaHasta}
                            isEdditing={props.isEdditing}
                            deleteClient={(clientToDelete) => {
                                setClientsToBill(
                                    clientsToBill.filter(cli => cli.id !== clientToDelete.id)
                                );
                                setPaqueteriasToBill(
                                    paqueteriasToBill.filter(paq => paq.datosGenerales.client.id !== clientToDelete.id)
                                );
                                setCargasToBill(
                                    cargasToBill.filter(car => car.datosGenerales.client.id !== clientToDelete.id)
                                );
                            }}
                        />

                    </div>

                </div>
            </div>

        </Fragment>

    );

};

export default BillsAddForm;