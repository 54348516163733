import React, {Fragment, ReactElement, useEffect, useState,} from "react";
import {Button, Modal} from "react-bootstrap";
import {infoToCreateCMR, initinfoLugarToCreateCMR} from "aseguisshared";

interface infoToModalCmr {
    modalOpened: boolean
    setModalOpened: (opened: boolean) => void
    cmrInfo: infoToCreateCMR
    setCmrInfo: (infoToCreateCMR: infoToCreateCMR) => void
    reInitCmr: () => void
}

const ModalCmr = (props: infoToModalCmr) => {

    const [localCmrInfo, setLocalCmrInfo] = React.useState(props.cmrInfo);

    useEffect(() => {
        setLocalCmrInfo(props.cmrInfo)
    }, [props.cmrInfo]);

    const closeModal = () => {
        props.setModalOpened(false)
    }

    return (
        <Fragment>

            <Modal show={props.modalOpened} onHide={closeModal} dialogClassName="modal-xl">
                <Modal.Header closeButton>
                    <Modal.Title>

                        Editar CMR

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {localCmrInfo &&

                        <div className="card-body">

                            <div className="container">

                                <div className="row">
                                    <div className="" style={{
                                        width: 'calc(49.5%)',
                                        border: '1px solid #ccc',
                                        borderRadius: '0.25rem',
                                        padding: '1rem',
                                        paddingBottom: '0',
                                        marginBottom: '1rem',
                                        backgroundColor: '#f8f9fa',
                                    }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label style={{fontWeight: 'bold'}}>Num albarán (NR CMR)</label>
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.numAlbaran}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            numAlbaran: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="" style={{
                                        width: 'calc(49.3%)',
                                        border: '1px solid #ccc',
                                        borderRadius: '0.25rem',
                                        padding: '1rem',
                                        paddingBottom: '0',
                                        marginBottom: '1rem',
                                        backgroundColor: '#f8f9fa',
                                    }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label style={{fontWeight: 'bold'}}>Remitente</label>
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                Nombre:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.remitenteCliente.nombre}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            remitenteCliente: {
                                                                ...localCmrInfo.remitenteCliente,
                                                                nombre: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                Calle y número:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.remitenteCliente.calleNumero}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            remitenteCliente: {
                                                                ...localCmrInfo.remitenteCliente,
                                                                calleNumero: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                CP y localidad:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.remitenteCliente.cpLocalidad}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            remitenteCliente: {
                                                                ...localCmrInfo.remitenteCliente,
                                                                cpLocalidad: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                Provincia y país:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.remitenteCliente.provinciaPais}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            remitenteCliente: {
                                                                ...localCmrInfo.remitenteCliente,
                                                                provinciaPais: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="" style={{
                                        width: 'calc(49.3%)',
                                        border: '1px solid #ccc',
                                        borderRadius: '0.25rem',
                                        padding: '1rem',
                                        paddingBottom: '0',
                                        marginBottom: '1rem',
                                        backgroundColor: '#f8f9fa',
                                        marginLeft: '14px',
                                    }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label style={{fontWeight: 'bold'}}>Consignatario</label>
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                Nombre:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.consignatarioDestinatario.nombre}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            consignatarioDestinatario: {
                                                                ...localCmrInfo.consignatarioDestinatario,
                                                                nombre: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                Calle y número:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.consignatarioDestinatario.calleNumero}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            consignatarioDestinatario: {
                                                                ...localCmrInfo.consignatarioDestinatario,
                                                                calleNumero: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                CP y localidad:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.consignatarioDestinatario.cpLocalidad}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            consignatarioDestinatario: {
                                                                ...localCmrInfo.consignatarioDestinatario,
                                                                cpLocalidad: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                Provincia y país:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.consignatarioDestinatario.provinciaPais}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            consignatarioDestinatario: {
                                                                ...localCmrInfo.consignatarioDestinatario,
                                                                provinciaPais: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="" style={{
                                        width: 'calc(49.3%)',
                                        border: '1px solid #ccc',
                                        borderRadius: '0.25rem',
                                        padding: '1rem',
                                        paddingBottom: '0',
                                        marginBottom: '1rem',
                                        backgroundColor: '#f8f9fa',
                                    }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label style={{fontWeight: 'bold'}}>Transportista</label>
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                Nombre:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.transportista.nombre}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            transportista: {
                                                                ...localCmrInfo.transportista,
                                                                nombre: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                Calle y número:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.transportista.calleNumero}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            transportista: {
                                                                ...localCmrInfo.transportista,
                                                                calleNumero: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                CP y localidad:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.transportista.cpLocalidad}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            transportista: {
                                                                ...localCmrInfo.transportista,
                                                                cpLocalidad: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                Provincia y país:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.transportista.provinciaPais}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            transportista: {
                                                                ...localCmrInfo.transportista,
                                                                provinciaPais: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="" style={{
                                        width: 'calc(49.3%)',
                                        border: '1px solid #ccc',
                                        borderRadius: '0.25rem',
                                        padding: '1rem',
                                        paddingBottom: '0',
                                        marginBottom: '1rem',
                                        backgroundColor: '#f8f9fa',
                                        marginLeft: '14px',
                                    }}>
                                        <div>
                                            <label style={{fontWeight: 'bold'}}>
                                                Reservas y observaciones del transportista:
                                            </label>
                                            <input
                                                className="form-control"
                                                name="Remitente"
                                                type="text"
                                                autoComplete="off"
                                                value={localCmrInfo.reservasObservacionesTransportista}
                                                onChange={(event) => {
                                                    setLocalCmrInfo({
                                                        ...localCmrInfo,
                                                        reservasObservacionesTransportista: event.target.value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div style={{marginTop: "18px"}}>
                                            <label style={{fontWeight: 'bold'}}>
                                                Documentos adjuntos:
                                            </label>
                                            <input
                                                className="form-control"
                                                name="Remitente"
                                                type="text"
                                                autoComplete="off"
                                                value={localCmrInfo.documentosAdjuntos}
                                                onChange={(event) => {
                                                    setLocalCmrInfo({
                                                        ...localCmrInfo,
                                                        documentosAdjuntos: event.target.value
                                                    })
                                                }}
                                            />
                                        </div>

                                        <div style={{marginTop: "18px"}}>
                                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                                <div style={{display: "flex", flexDirection: "column", width: "48%"}}>
                                                    <label style={{fontWeight: 'bold'}}>
                                                        Vehículo:
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        name="vehiculo1"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={localCmrInfo.vehiculo}
                                                        onChange={(event) => {
                                                            setLocalCmrInfo({
                                                                ...localCmrInfo,
                                                                vehiculo: event.target.value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div style={{display: "flex", flexDirection: "column", width: "48%"}}>
                                                    <label style={{fontWeight: 'bold'}}>
                                                        Remolque:
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        name="vehiculo2"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={localCmrInfo.remolque}
                                                        onChange={(event) => {
                                                            setLocalCmrInfo({
                                                                ...localCmrInfo,
                                                                remolque: event.target.value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>


                                <div className="row">
                                    <div className="" style={{
                                        width: 'calc(100%)',
                                        border: '1px solid #ccc',
                                        borderRadius: '0.25rem',
                                        padding: '1rem',
                                        paddingBottom: '0',
                                        marginBottom: '1rem',
                                        backgroundColor: '#f8f9fa',
                                    }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label style={{fontWeight: 'bold', marginTop: "10px"}}>
                                                    Transportistas sucesivos
                                                </label>
                                                <button
                                                    disabled={localCmrInfo.transportistasSucesivos.length >= 3}
                                                    className="btn btn-info btn-sm"
                                                    style={{
                                                        marginLeft: "10px",
                                                        borderRadius: '50px'
                                                    }}
                                                    onClick={() => {
                                                        if (localCmrInfo.transportistasSucesivos.length < 3) {
                                                            setLocalCmrInfo({
                                                                ...localCmrInfo,
                                                                transportistasSucesivos: [...localCmrInfo.transportistasSucesivos, initinfoLugarToCreateCMR],
                                                            })
                                                        }
                                                    }}>
                                                    <i className="fas fa-plus"></i>
                                                </button>
                                                {localCmrInfo.transportistasSucesivos.length > 0 &&
                                                    <button
                                                        className="btn btn-warning btn-sm"
                                                        style={{
                                                            marginLeft: "10px",
                                                            borderRadius: '50px'
                                                        }}
                                                        onClick={() => {
                                                            if (localCmrInfo.transportistasSucesivos.length > 0) {
                                                                setLocalCmrInfo({
                                                                    ...localCmrInfo,
                                                                    transportistasSucesivos: localCmrInfo.transportistasSucesivos.slice(0, -1)
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <i className="fas fa-minus"></i>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                        {localCmrInfo.transportistasSucesivos.map((transportista, index) => (
                                            <div className="form-row" key={index}>
                                                <div className="form-group col-md-3">
                                                    <label>Nombre: </label>
                                                    <input
                                                        className="form-control"
                                                        name="Remitente"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={transportista.nombre}
                                                        onChange={(event) => {
                                                            setLocalCmrInfo({
                                                                ...localCmrInfo,
                                                                transportistasSucesivos: localCmrInfo.transportistasSucesivos.map((lugar, idx) => {
                                                                    if (idx === index) {
                                                                        return {...lugar, nombre: event.target.value};
                                                                    }
                                                                    return lugar;
                                                                })
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>Calle y número: </label>
                                                    <input
                                                        className="form-control"
                                                        name="Remitente"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={transportista.calleNumero}
                                                        onChange={(event) => {
                                                            setLocalCmrInfo({
                                                                ...localCmrInfo,
                                                                transportistasSucesivos: localCmrInfo.transportistasSucesivos.map((lugar, idx) => {
                                                                    if (idx === index) {
                                                                        return {
                                                                            ...lugar,
                                                                            calleNumero: event.target.value
                                                                        };
                                                                    }
                                                                    return lugar;
                                                                })
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>CP y localidad:</label>
                                                    <input
                                                        className="form-control"
                                                        name="Remitente"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={transportista.cpLocalidad}
                                                        onChange={(event) => {
                                                            setLocalCmrInfo({
                                                                ...localCmrInfo,
                                                                transportistasSucesivos: localCmrInfo.transportistasSucesivos.map((lugar, idx) => {
                                                                    if (idx === index) {
                                                                        return {
                                                                            ...lugar,
                                                                            cpLocalidad: event.target.value
                                                                        };
                                                                    }
                                                                    return lugar;
                                                                })
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>Provincia y país: </label>
                                                    <input
                                                        className="form-control"
                                                        name="Remitente"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={transportista.provinciaPais}
                                                        onChange={(event) => {
                                                            setLocalCmrInfo({
                                                                ...localCmrInfo,
                                                                transportistasSucesivos: localCmrInfo.transportistasSucesivos.map((lugar, idx) => {
                                                                    if (idx === index) {
                                                                        return {
                                                                            ...lugar,
                                                                            provinciaPais: event.target.value
                                                                        };
                                                                    }
                                                                    return lugar;
                                                                })
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="" style={{
                                        width: 'calc(49.3%)',
                                        border: '1px solid #ccc',
                                        borderRadius: '0.25rem',
                                        padding: '1rem',
                                        paddingBottom: '0',
                                        marginBottom: '1rem',
                                        backgroundColor: '#f8f9fa',
                                    }}>
                                        <div className="form-row">
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                alignItems: "center",
                                                marginBottom: '1rem'
                                            }}>
                                                <label style={{paddingLeft: "5px", fontWeight: 'bold'}}>Lugar y fecha de
                                                    carga</label>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <label style={{marginRight: '1rem', marginTop: "4px"}}>
                                                        Fecha:
                                                    </label>
                                                    <input
                                                        style={{marginRight: "5px"}}
                                                        className="form-control"
                                                        name="fechaCarga"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={localCmrInfo.cargaMercancia.fecha}
                                                        onChange={(event) => {
                                                            setLocalCmrInfo({
                                                                ...localCmrInfo,
                                                                cargaMercancia: {
                                                                    ...localCmrInfo.cargaMercancia,
                                                                    fecha: event.target.value
                                                                }
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                Nombre:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.cargaMercancia.nombre}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            cargaMercancia: {
                                                                ...localCmrInfo.cargaMercancia,
                                                                nombre: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                Calle y número:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.cargaMercancia.calleNumero}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            cargaMercancia: {
                                                                ...localCmrInfo.cargaMercancia,
                                                                calleNumero: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                CP y localidad:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.cargaMercancia.cpLocalidad}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            cargaMercancia: {
                                                                ...localCmrInfo.cargaMercancia,
                                                                cpLocalidad: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                Provincia y país:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.cargaMercancia.provinciaPais}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            cargaMercancia: {
                                                                ...localCmrInfo.cargaMercancia,
                                                                provinciaPais: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="" style={{
                                        width: 'calc(49.3%)',
                                        border: '1px solid #ccc',
                                        borderRadius: '0.25rem',
                                        padding: '1rem',
                                        paddingBottom: '0',
                                        marginBottom: '1rem',
                                        backgroundColor: '#f8f9fa',
                                        marginLeft: '14px',
                                    }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label style={{fontWeight: 'bold'}}>Lugar de entrega</label>
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                Nombre:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.destinoMercancia.nombre}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            destinoMercancia: {
                                                                ...localCmrInfo.destinoMercancia,
                                                                nombre: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                Calle y número:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.destinoMercancia.calleNumero}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            destinoMercancia: {
                                                                ...localCmrInfo.destinoMercancia,
                                                                calleNumero: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                CP y localidad:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.destinoMercancia.cpLocalidad}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            destinoMercancia: {
                                                                ...localCmrInfo.destinoMercancia,
                                                                cpLocalidad: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                Provincia y país:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.destinoMercancia.provinciaPais}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            destinoMercancia: {
                                                                ...localCmrInfo.destinoMercancia,
                                                                provinciaPais: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="" style={{
                                        width: 'calc(100%)',
                                        border: '1px solid #ccc',
                                        borderRadius: '0.25rem',
                                        padding: '1rem',
                                        paddingBottom: '0',
                                        marginBottom: '1rem',
                                        backgroundColor: '#f8f9fa',
                                    }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-3">
                                                <label style={{fontWeight: 'bold'}}>
                                                    Marcas y números:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.marcasNumeros}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            marcasNumeros: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label style={{fontWeight: 'bold'}}>
                                                    Número estadístico:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.numeroEstadistico}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            numeroEstadistico: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label style={{fontWeight: 'bold'}}>
                                                    Clase de embalaje
                                                </label>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.claseEmbalaje}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            claseEmbalaje: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label style={{fontWeight: 'bold'}}>
                                                    Naturaleza mercancía
                                                </label>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.naturalezaMercancia}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            naturalezaMercancia: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="" style={{
                                        width: 'calc(100%)',
                                        border: '1px solid #ccc',
                                        borderRadius: '0.25rem',
                                        padding: '1rem',
                                        paddingBottom: '0',
                                        marginBottom: '1rem',
                                        backgroundColor: '#f8f9fa',
                                    }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label style={{fontWeight: 'bold'}}>
                                                    Bultos:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.bultos}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            bultos: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label style={{fontWeight: 'bold'}}>
                                                    Peso bruto (kg):
                                                </label>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.kilos}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            kilos: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label style={{fontWeight: 'bold'}}>
                                                    volumen (m3):
                                                </label>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.m3}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            m3: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="" style={{
                                        width: 'calc(100%)',
                                        border: '1px solid #ccc',
                                        borderRadius: '0.25rem',
                                        padding: '1rem',
                                        paddingBottom: '0',
                                        marginBottom: '1rem',
                                        backgroundColor: '#f8f9fa',
                                    }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label style={{fontWeight: 'bold'}}>
                                                    Instrucciones del remitente:
                                                </label>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.instruccionesRemitente}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            instruccionesRemitente: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label style={{fontWeight: 'bold'}}>
                                                    Forma de pago (Pagados / Debidos):
                                                </label>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.formaDePago}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            formaDePago: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label style={{fontWeight: 'bold'}}>Estipulaciones particulares:</label>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.estipulacionesParticulares}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            estipulacionesParticulares: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="" style={{
                                        width: 'calc(49.3%)',
                                        border: '1px solid #ccc',
                                        borderRadius: '0.25rem',
                                        padding: '1rem',
                                        paddingBottom: '0',
                                        marginBottom: '1rem',
                                        backgroundColor: '#f8f9fa',
                                    }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label style={{fontWeight: 'bold'}}>
                                                    A pagar por remitente
                                                </label>
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px'}}>
                                                Precio transp:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.precioTransporteRemitente}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            precioTransporteRemitente: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <label style={{minWidth: '115px', marginLeft: '15px'}}>
                                                Descuentos:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.descuentosRemitente}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            descuentosRemitente: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px',}}>
                                                Balance:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.balanceRemitente}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            balanceRemitente: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <label style={{minWidth: '115px', marginLeft: '15px'}}>
                                                Suplementos:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.suplementosRemitente}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            suplementosRemitente: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px'}}>
                                                Gastos accs:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.gastosAccesoriosRemitente}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            gastosAccesoriosRemitente: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <label style={{minWidth: '115px', marginLeft: '15px'}}>
                                                Seguro:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.seguroRemitente}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            seguroRemitente: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px'}}>
                                                Total a pagar:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.totalPagarRemitente}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            totalPagarRemitente: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <label style={{minWidth: '115px', marginLeft: '15px'}}>
                                                Moneda:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.monedaRemitente}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            monedaRemitente: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="" style={{
                                        width: 'calc(49.3%)',
                                        border: '1px solid #ccc',
                                        borderRadius: '0.25rem',
                                        padding: '1rem',
                                        paddingBottom: '0',
                                        marginBottom: '1rem',
                                        backgroundColor: '#f8f9fa',
                                        marginLeft: '14px',
                                    }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label style={{fontWeight: 'bold'}}>
                                                    A pagar por consignatario
                                                </label>
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px'}}>
                                                Precio transp:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.precioTransporteConsignatario}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            precioTransporteConsignatario: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <label style={{minWidth: '115px', marginLeft: '15px'}}>
                                                Descuentos:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.descuentosConsignatario}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            descuentosConsignatario: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px',}}>
                                                Balance:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.balanceConsignatario}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            balanceConsignatario: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <label style={{minWidth: '115px', marginLeft: '15px'}}>
                                                Suplementos:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.suplementosConsignatario}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            suplementosConsignatario: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px'}}>
                                                Gastos accs:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.gastosAccesoriosConsignatario}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            gastosAccesoriosConsignatario: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <label style={{minWidth: '115px', marginLeft: '15px'}}>
                                                Seguro:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.seguroConsignatario}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            seguroConsignatario: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px'}}>
                                                Total a pagar:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.totalPagarConsignatario}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            totalPagarConsignatario: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <label style={{minWidth: '115px', marginLeft: '15px'}}>
                                                Moneda:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.monedaConsignatario}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            monedaConsignatario: event.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="" style={{
                                        width: 'calc(49.3%)',
                                        border: '1px solid #ccc',
                                        borderRadius: '0.25rem',
                                        padding: '1rem',
                                        paddingBottom: '0',
                                        marginBottom: '1rem',
                                        backgroundColor: '#f8f9fa',
                                    }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label style={{fontWeight: 'bold'}}>
                                                    Lugar y fecha de formalización
                                                </label>
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                CP y localidad:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.lugarFormalizacion.cpLocalidad}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            lugarFormalizacion: {
                                                                ...localCmrInfo.lugarFormalizacion,
                                                                cpLocalidad: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                Provincia y país:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.lugarFormalizacion.provinciaPais}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            lugarFormalizacion: {
                                                                ...localCmrInfo.lugarFormalizacion,
                                                                provinciaPais: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                            <label style={{minWidth: '115px', marginRight: '1rem'}}>
                                                Fecha:
                                            </label>
                                            <div style={{flex: 1}}>
                                                <input
                                                    className="form-control"
                                                    name="Remitente"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={localCmrInfo.lugarFormalizacion.fecha}
                                                    onChange={(event) => {
                                                        setLocalCmrInfo({
                                                            ...localCmrInfo,
                                                            lugarFormalizacion: {
                                                                ...localCmrInfo.lugarFormalizacion,
                                                                fecha: event.target.value
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="" style={{
                                        width: 'calc(49.3%)',
                                        border: '1px solid #ccc',
                                        borderRadius: '0.25rem',
                                        padding: '1rem',
                                        paddingBottom: '0',
                                        marginBottom: '1rem',
                                        backgroundColor: '#f8f9fa',
                                        marginLeft: '14px',
                                    }}>
                                        <label style={{fontWeight: 'bold'}}>Reembolso:</label>
                                        <input
                                            className="form-control"
                                            name="Remitente"
                                            type="text"
                                            autoComplete="off"
                                            value={localCmrInfo.reembolso}
                                            onChange={(event) => {
                                                setLocalCmrInfo({
                                                    ...localCmrInfo,
                                                    reembolso: event.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                    }

                </Modal.Body>
                <Modal.Footer>

                    <Button
                        variant="primary"
                        onClick={() => {
                            props.setCmrInfo(localCmrInfo)
                            props.setModalOpened(false)
                        }}
                    >
                        Guardar
                    </Button>

                    <Button
                        variant="secondary"
                        onClick={() => {
                            props.reInitCmr()
                        }}
                    >
                        Inicializar
                    </Button>

                    <Button
                        variant="secondary"
                        onClick={() => {
                            props.setModalOpened(false)
                        }}
                    >
                        Cerrar
                    </Button>

                </Modal.Footer>
            </Modal>

        </Fragment>
    )
        ;
};

export default ModalCmr;