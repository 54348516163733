import React, { Fragment, useEffect, ReactElement, useState, useRef, useLayoutEffect } from "react";
import {
    validateAlphaNumeric, validateFormEmpty, address,
    CapitalizefirstLetter, formatedDirection, formatedDirWithName, inicialiceAddress
} from "aseguisShared";
import axios from "axios";
import AutoSuggest from "react-autosuggest";
import Match from 'autosuggest-highlight/match';
import Parse from 'autosuggest-highlight/parse';

export interface ClientDestinationsRequest {
    idClient: string;
    textSearch: string
}

export interface ClientDestinationsDeleteRequest {
    idDirection: string;
    idClient: string;
    textSearch: string
}

export interface ClientDestinations {
    _id: string | null;
    idClient: string;
    destination: Destination
}

export interface Destination {
    name: string;
    tel: string;
    address: address;
}

export const initDestination: Destination = {
    name: "",
    tel: "",
    address: inicialiceAddress
}

export const initDestinationsClient: ClientDestinations = {
    _id: null,
    idClient: "",
    destination: initDestination
}

interface infoToClientDestinationsAutocomplete {
    textToSearch: string
    saveTextToSearch: (address: string) => void
    onSelectAddress: (destination: Destination) => void
    country: string

    autoFocus?: boolean
    onBlur?: () => void

    suggestionsWidth?: string
    setErrorForm?: (value: boolean) => void
    clientID: string
}

function ClientsDestinationsAutocomplete(props: infoToClientDestinationsAutocomplete): ReactElement {

    const [goToApi, setGoToApi] = useState(true)
    const [clientSuggestionDests, setClientSuggestionDests] = useState<ClientDestinations[]>([])
    const [selectActivated, setSelectActivated] = useState(true)
    const suggestionsContainerRef = useRef<HTMLSpanElement>(null);

    const refFocus = useRef<HTMLInputElement>(null);
    useLayoutEffect(() => {
        if (null !== refFocus.current && props.autoFocus) {
            refFocus.current.focus()
        }
    })

    useEffect(() => {
        if ((goToApi === true) && props.textToSearch.length > 0 && props.clientID.length > 0) {
            const timeout2 = setTimeout(() => {
                let clientDestination: ClientDestinationsRequest = {
                    idClient: props.clientID,
                    textSearch: props.textToSearch
                }
                axios
                    .post<ClientDestinations[]>('api/destinationClients', clientDestination)
                    .then((response) => {
                        if (response.data) {
                            setClientSuggestionDests(response.data)
                        }
                        setGoToApi(false)
                    })
                    .catch((error) => {
                        setClientSuggestionDests([])
                    })
            }, 200);
            return () => { clearTimeout(timeout2) }
        }

    }, [props.textToSearch])

    const deleteAddress = async (id: string) => {
        let clientDestination: ClientDestinationsDeleteRequest = {
            idDirection: id,
            idClient: props.clientID,
            textSearch: props.textToSearch
        }
        await axios
            .put<ClientDestinations[]>('api/destinationClients/', clientDestination)
            .then((response) => {
                setClientSuggestionDests(response.data)
            })
            .catch((error) => {
            })
    }

    function onValueChanged(newValue: string): void {
        if (selectActivated) {
            var value = CapitalizefirstLetter(validateAlphaNumeric(newValue))
            props.saveTextToSearch(value)
            if (value.length === 0) {
                setClientSuggestionDests([])
            }
        }
    }

    useEffect(() => {
        if (props.setErrorForm) {
            if (props.textToSearch.length < 5) {
                props.setErrorForm(true)
            } else {
                props.setErrorForm(false)
            }
        }
    }, [props.textToSearch.length])

    function renderSuggestion(suggestion: ClientDestinations, query: any): JSX.Element {
        const textToHigh = "" + query.query
        const direction = formatedDirWithName(suggestion.destination.address, suggestion.destination.name, true)
        const matches = Match(direction, textToHigh, { insideWords: true });
        const parts = Parse(direction, matches)
        return (
            <Fragment>
                <span
                    style={{ marginRight: "20px" }}
                    onMouseMove={() => {
                        setSelectActivated(false)
                    }}
                    onMouseLeave={() => {
                        setSelectActivated(true)
                    }}
                    onClick={async () => {
                        if (suggestion._id) {
                            await deleteAddress(suggestion._id)
                        }
                    }}
                >
                    <i className="fa-solid fa-x"></i>
                </span>
                <span>
                    {parts.map((part, index) => {
                        const className = part.highlight ? 'highlight' : '';
                        return (
                            <span className={className} key={index}>{part.text}</span>
                        );
                    })}
                </span>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <span ref={suggestionsContainerRef} style={{ flex: "1" }}>
                <AutoSuggest
                    onSuggestionHighlighted={({ suggestion }) => {
                        if (suggestion && suggestionsContainerRef.current) {
                            const index = clientSuggestionDests.findIndex(s => s === suggestion);
                            const suggestionElements = suggestionsContainerRef.current.querySelectorAll('.react-autosuggest__suggestion');
                            const highlightedElement = suggestionElements[index];
                            if (highlightedElement) {
                                highlightedElement.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'nearest'
                                });
                            }
                        }
                    }}
                    suggestions={clientSuggestionDests}
                    onSuggestionsClearRequested={() => {
                        if (selectActivated) {
                            setClientSuggestionDests([])
                        }
                    }}
                    onSuggestionsFetchRequested={({ value }) => {
                        setGoToApi(true)
                        setClientSuggestionDests([])
                    }}
                    onSuggestionSelected={(event, { suggestionValue, suggestion }) => {
                        if (selectActivated) {
                            props.onSelectAddress(suggestion.destination)
                            props.saveTextToSearch(formatedDirection(suggestion.destination.address))
                        } else {
                            event.preventDefault()
                            event.stopPropagation()
                        }
                    }}
                    getSuggestionValue={(suggestion) =>
                        formatedDirWithName(suggestion.destination.address, suggestion.destination.name && suggestion.destination.name)
                    }
                    renderSuggestion={renderSuggestion}
                    inputProps={{
                        placeholder: "Escriba un lugar para autocompletar...",
                        value: props.textToSearch,
                        onChange: (event, { newValue, method }) => {
                            onValueChanged(newValue)
                        },
                        type: "text",
                        name: "dest_search",
                        className: validateFormEmpty(props.textToSearch, 2),
                        autoComplete: "off",
                        onBlur: () => {
                            setGoToApi(false)
                            setClientSuggestionDests([])

                            if (props.onBlur) {
                                props.onBlur()
                            }
                        },
                        onFocus: () => {
                        },
                        ref: refFocus
                    }}
                />
            </span>
        </Fragment >
    );
};

export default ClientsDestinationsAutocomplete;