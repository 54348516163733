import React, {useCallback} from 'react';
import axios from 'axios';
import {addingOrEditing} from 'aseguisShared';

export const CustomerPortalButton = () => {
    
    const openCustomerPortal = useCallback(async () => {
        try {
            const response = await axios.post('/api/stripe/portalClient/')
            const {url} = response.data;
            window.location.href = url;
        } catch (error) {
            console.error(error);
        }
    }, []);
    
    return (
        <button
            type="button"
            className={"btn btn-primary btn btn-sm"}
            onClick={() => {
                openCustomerPortal()
            }}
        >
            Portal de suscripción
        </button>
    );
};