import { useDispatch, useSelector } from "react-redux";
import { loginState, logout } from '../../components/Login/LoginStore';
import React, { Fragment, useEffect, useState } from 'react';
import { ApplicationState } from '../../ApplicationState';
import { useHistory } from 'react-router-dom';
import { allAppRoutes } from "../commonInterfaces/allAppRoutes";
import useComponentVisible from "../commonInterfaces/useComponentVisible";
import { company, invertColor, suscriptionData, usersTypes } from "aseguisShared";
import SuscriptionModal from "../Suscription/SuscriptionModal";
import { GetSuscriptionDataFromLocalStorage, GetUserPermissionsFromLocalStorage } from "../commonInterfaces/commonFunctions";

const TopMenu: React.FC = () => {

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const dispatch = useDispatch()
    const history = useHistory();

    const { userType, permissions } = GetUserPermissionsFromLocalStorage()
    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const company: company = useSelector((state: ApplicationState) => state.company.company);
    const loginState: loginState = useSelector((state: ApplicationState) => state.login);
    const primaryColorState: string = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm.primaryColor);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [showSuscriptionModal, setShowSuscriptionModal] = React.useState<boolean>(false)
    const [primaryColor, setPrimaryColor] = useState(localStorage.getItem('firstColor') || "");
    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (primaryColorState && primaryColorState.length > 0) {
            setPrimaryColor(primaryColorState)
        }
    }, [primaryColorState])

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    function logOff(): void {
        setIsComponentVisible(false);
        dispatch(logout());
        history.push(allAppRoutes.login);
    }

    return (

        <Fragment>

            <nav
                className="navbar navbar-expand navbar-light topbar static-top"
                style={{ backgroundColor: primaryColor }}
            >
                <ul className="navbar-nav ml-auto">

                    {(userType === usersTypes.employee && permissions.isAdmin) &&
                        <>
                            {(suscriptionData.suscriptionStatus === "active") &&
                                <>
                                    <li className="nav-item dropdown no-arrow">
                                        <a className="nav-link">
                                            <span
                                                className="d-none d-lg-inline small cadet"
                                                style={{ fontSize: "11px", color: invertColor(primaryColor, false), }}
                                            >
                                                {"Suscripción activa: " + suscriptionData.suscriptionName}
                                            </span>
                                        </a>
                                    </li>
                                    <div className="topbar-divider d-none d-sm-block"></div>
                                </>
                            }

                            {(suscriptionData.suscriptionStatus === "trialing") &&
                                <>
                                    <li className="nav-item dropdown no-arrow">
                                        <a className="nav-link">
                                            <span
                                                className="d-none d-lg-inline small cadet"
                                                style={{ fontSize: "11px", color: invertColor(primaryColor, false), }}
                                            >
                                                <span className="mr-2">
                                                    {"Suscripción en periodo de prueba hasta el:"}
                                                </span>
                                                <span style={{ fontWeight: 600 }}>
                                                    {suscriptionData.trialPeriodEnds}
                                                </span>
                                            </span>
                                        </a>
                                    </li>
                                    <div className="topbar-divider d-none d-sm-block"></div>
                                </>
                            }

                            {(suscriptionData.suscriptionStatus === "incomplete") &&
                                <>
                                    <li className="nav-item dropdown no-arrow">
                                        <a className="nav-link">
                                            <span
                                                className="d-none d-lg-inline small cadet"
                                                style={{ fontSize: "11px", color: invertColor(primaryColor, false) }}
                                            >
                                                <div className="sidebar-brand-text mx-3">
                                                    {"Suscripción incompleta. "}
                                                    <span
                                                        style={{
                                                            textDecoration: "underline",
                                                            fontWeight: 600,
                                                            cursor: "pointer"

                                                        }}
                                                        onClick={() => {
                                                            setShowSuscriptionModal(true)
                                                        }}
                                                    >
                                                        {"Gestione la suscripción"}
                                                    </span>
                                                </div>
                                            </span>
                                        </a>
                                    </li>
                                    <div className="topbar-divider d-none d-sm-block"></div>
                                </>
                            }

                            {(suscriptionData.suscriptionStatus === "past_due" || suscriptionData.suscriptionStatus === "unpaid") &&
                                <>
                                    <li className="nav-item dropdown no-arrow">
                                        <a className="nav-link">
                                            <span
                                                className="d-none d-lg-inline small cadet"
                                                style={{ fontSize: "11px", color: invertColor(primaryColor, false) }}
                                            >
                                                <div className="sidebar-brand-text mx-3">
                                                    {"Suscripción vencida. "}
                                                    <span
                                                        style={{
                                                            textDecoration: "underline",
                                                            fontWeight: 600,
                                                            cursor: "pointer"

                                                        }}
                                                        onClick={() => {
                                                            setShowSuscriptionModal(true)
                                                        }}
                                                    >
                                                        {"Gestione la suscripción"}
                                                    </span>
                                                    {" para reactivarla"}
                                                </div>
                                            </span>
                                        </a>
                                    </li>
                                    <div className="topbar-divider d-none d-sm-block"></div>
                                </>
                            }

                            {(suscriptionData.suscriptionStatus === "canceled") &&
                                <>
                                    <li className="nav-item dropdown no-arrow">
                                        <a className="nav-link">
                                            <span
                                                className="d-none d-lg-inline small cadet"
                                                style={{ fontSize: "11px", color: invertColor(primaryColor, false) }}
                                            >
                                                {"Sin suscripción activa. "}
                                                <span
                                                    style={{
                                                        textDecoration: "underline",
                                                        fontWeight: 600,
                                                        cursor: "pointer"

                                                    }}
                                                    onClick={() => {
                                                        setShowSuscriptionModal(true)
                                                    }}
                                                >
                                                    {"Gestione la suscripción"}
                                                </span>
                                                {" para activarla"}
                                            </span>
                                        </a>
                                    </li>
                                    <div className="topbar-divider d-none d-sm-block"></div>
                                </>
                            }

                            <SuscriptionModal
                                showManageSuscriptionModal={showSuscriptionModal}
                                setShowManageSuscriptionModal={setShowSuscriptionModal}
                            />
                        </>
                    }

                    <li className="nav-item dropdown no-arrow">
                        <div className="nav-link">
                            <span
                                className="d-none d-lg-inline small cadet"
                                style={{ color: invertColor(primaryColor, false) }}
                            >
                                <div
                                    className="sidebar-brand-text mx-3"
                                    style={{
                                        color: invertColor(primaryColor, false),
                                        //textTransform: "uppercase",
                                        fontWeight: 600
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '11px' }}>

                                        {(userType === usersTypes.employee) &&
                                            <>
                                                <span className="mr-3">{"Soporte: "}</span>
                                                {suscriptionData.soportePremium &&
                                                    <a
                                                        href="https://wa.me/34624088293"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            textDecoration: 'none',
                                                            color: 'inherit',
                                                            marginRight: '20px'
                                                        }}
                                                    >
                                                        <i
                                                            className="fa-solid fa-phone mr-2"
                                                            style={{ color: '#25D366', fontSize: '15px' }}
                                                        ></i>
                                                        <span>624 088 293</span>
                                                        <i
                                                            className="fa-brands fa-whatsapp ml-2"
                                                            style={{ color: '#25D366', fontSize: '15px' }}
                                                        ></i>
                                                    </a>
                                                }
                                                <a
                                                    href="mailto:aseguis@aseguis.es"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        textDecoration: 'none',
                                                        color: 'inherit'
                                                    }}
                                                >
                                                    <i
                                                        className="fa-solid fa-envelope"
                                                        style={{ color: '#25D366', fontSize: '15px', marginRight: '5px' }}
                                                    ></i>
                                                    <span>aseguis@aseguis.es</span>
                                                </a>
                                            </>
                                        }

                                    </div>
                                </div>
                            </span>
                        </div>
                    </li>

                    <div className="topbar-divider d-none d-sm-block"></div>

                    <li className="nav-item dropdown no-arrow">
                        <a className="nav-link">
                            <span
                                className="d-none d-lg-inline small cadet"
                                style={{ color: invertColor(primaryColor, false) }}
                            >
                                <div
                                    className="sidebar-brand-text mx-3"
                                    style={{
                                        color: invertColor(primaryColor, false),
                                        textTransform: "uppercase",
                                        fontWeight: 600
                                    }}
                                >
                                    {company.nombre}
                                </div>
                            </span>
                        </a>
                    </li>

                    <div className="topbar-divider d-none d-sm-block"></div>

                    <li className="nav-item dropdown no-arrow">
                        <a className="nav-link dropdown-toggle"
                            onClick={() => {
                                setIsComponentVisible(!isComponentVisible);
                            }}
                            id="userDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">

                            <span
                                className="ml-2 mr-2 d-none d-lg-inline small cadet"
                                style={{ color: invertColor(primaryColor, false) }}
                            >
                                {(loginState && loginState.userLogged && loginState.userLogged.name) ? loginState.userLogged.name + " " + loginState.userLogged.apellido1 + " " + loginState.userLogged.apellido2 : ""}
                            </span>

                            <img className="img-profile rounded-circle" alt=""
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/48px-User_icon_2.svg.png" />
                        </a>

                        <div
                            className={`dropdown-menu dropdown-menu-right shadow animated--grow-in ${(isComponentVisible) ? "show" : ""}`}
                            aria-labelledby="userDropdown"
                            ref={ref}
                        >
                            <a className="dropdown-item"
                                onClick={() => {
                                    setIsComponentVisible(false);
                                    history.push(allAppRoutes.myUser);
                                }}
                                data-toggle="modal"
                                data-target="#logoutModal">
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                Mis datos
                            </a>
                            <a className="dropdown-item"
                                onClick={logOff}
                                data-toggle="modal"
                                data-target="#logoutModal">
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Salir
                            </a>
                        </div>

                    </li>

                </ul>
            </nav>

        </Fragment>
    );
};

export default TopMenu;
