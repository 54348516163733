import React, { useEffect, ReactElement, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import axios from "axios";
import RegisterTemplate from "./RegisterTemplate";
import './../Register/Register.css';

function RegisterActivated(): ReactElement {

    const history = useHistory();

    const { enableLink } = useParams<{ enableLink: string }>();

    const [isLoading, setIsLoading] = useState(true)
    const [message1, setMessage1] = useState("")
    const [message2, setMessage2] = useState("")

    useEffect(() => {
        const fetchData = async () => {

            interface sendToken {
                token: string;
            }

            let tokenToSend: sendToken = { token: enableLink }

            let enableTenant = await axios.post('api/enableTenant/', tokenToSend)

            setMessage1("¡Tu cuenta ha sido activada con éxito!")
            setMessage2("Ahora puedes iniciar sesión y comenzar a usar Aseguis :)")

            if (enableTenant.data === "nok") {
                setMessage1("La cuenta no ha podido ser activada")
                setMessage2(":(")
            }

            setIsLoading(false)
        }

        fetchData()
            .catch(console.error);

    }, [enableLink])


    return (

        <RegisterTemplate>

            {!isLoading ?
                <div className="login-container">
                    <div className="text-center">
                        <div style={{ marginTop: "11px", marginBottom: "28px" }}>
                            <div className="h3 text-gray-900 mb-2 login-title">
                                {message1}
                            </div>
                            <div className="h4 text-gray-900 login-subtitle" style={{ marginTop: "20px" }}>
                                {message2}
                            </div>
                        </div>
                        <button
                            className={"btn btn-primary btn-user btn-block"}
                            type="button"
                            disabled={isLoading}
                            onClick={() => {
                                history.replace(allAppRoutes.login);
                            }}
                        >
                            <span>Aceptar</span>
                        </button>
                    </div>
                </div>
                :
                <div className="login-container text-center">
                    <span className="h3 text-gray-900 mb-2 login-title">{"Creando cuenta, un momento... "}</span>
                    <br></br>
                    <br></br>
                    <span className="spinner-border spinner-border-sm mr-4 h4 login-subtitle"></span>
                </div>
            }

        </RegisterTemplate>

    );
}


export default RegisterActivated;
