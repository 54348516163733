import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { ApplicationState } from "../../ApplicationState";
import Buttons from "../../common/Buttons/Buttons";
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import {
    addingOrEditing, UserPermissions, loginUsers, searchFilterList,
    initialReviRepa, reviRepa, formatearMatricula
} from "aseguisShared";
import { GetUserPermissionsFromLocalStorage } from "../../common/commonInterfaces/commonFunctions";
import ReviRepaFormulario from "./ReviRepaFormulario";
import ReviRepaList from "./ReviRepaList";
import {
    addOrEditReviRepa, addReviRepa,editReviRepa,
    saveFormReviRepa, saveSearchFilterReviRepa, setFormsReviRepa
} from "./ReviRepaStore";

const ReviRepa: React.FC = () => {

    const dispatch = useDispatch();

    let { permissions } = GetUserPermissionsFromLocalStorage()

    const reviRepaForm: reviRepa = useSelector((state: ApplicationState) => state.reviRepa.reviRepaForm);
    const reviRepaEditForm: reviRepa = useSelector((state: ApplicationState) => state.reviRepa.reviRepaEditForm);
    const addingOrEditReviRepa: addingOrEditing = useSelector((state: ApplicationState) => state.reviRepa.addOrEditReviRepa) || addingOrEditing.none;
    const searchFilterReviRepa: searchFilterList = useSelector((state: ApplicationState) => state.reviRepa.searchFilterReviRepa);
    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);

    const [editEnabled, setEditEnabled] = useState(addingOrEditing.none);
    const [errorOnForm, setErrorOnForm] = React.useState(false);

    function enableEditReviRepa(reviRepa: reviRepa): void {
        setEditEnabled(addingOrEditing.none);
        dispatch(setFormsReviRepa(reviRepa));
        dispatch(addOrEditReviRepa(addingOrEditing.edditing));
    }

    function addReviRepaForm(): void {
        setEditEnabled(addingOrEditing.edditing);
        dispatch(setFormsReviRepa(initialReviRepa));
        dispatch(addOrEditReviRepa(addingOrEditing.adding));
    }

    function addNewReviRepa(): void {
        if (errorOnForm === false) {
            setEditEnabled(addingOrEditing.none);
            dispatch(addReviRepa({
                ...reviRepaEditForm,
                user: userLogged
            }));
        }
    }

    function saveEditedReviRepa(): void {
        if (errorOnForm === false) {
            setEditEnabled(addingOrEditing.none);
            dispatch(editReviRepa({
                ...reviRepaEditForm,
                user: userLogged
            }));
        }
    }

    function restoreReviRepaEdit(): void {
        dispatch(saveFormReviRepa(reviRepaForm));
    }

    const usePathname = useLocation().pathname;
    const usePath = useHistory()

    return (

        <Fragment>

            <Switch>

                {(permissions.vehiclesReviRepa !== UserPermissions.deshabilitado) &&
                    <Route exact path={allAppRoutes.vehiclesReviRepaList}>
                        <ReviRepaList
                            onClickReviRepa={(ReviRepaUpdate) => {
                                enableEditReviRepa(ReviRepaUpdate)
                                usePath.push(allAppRoutes.vehiclesReviRepaForm + ReviRepaUpdate._id );
                            }}
                            addReviRepa={() => {
                                addReviRepaForm()
                                usePath.push(allAppRoutes.vehiclesReviRepaForm + "new");
                            }}
                            searchFilterReviRepa={searchFilterReviRepa}
                            saveSearchFilterReviRepa={(searchFilterVehicles) => {
                                dispatch(saveSearchFilterReviRepa(searchFilterVehicles));
                            }}
                        />
                    </Route>
                }

                {permissions.vehiclesReviRepa !== UserPermissions.deshabilitado &&
                    <Route exact path={allAppRoutes.vehiclesReviRepaForm + ":id"}>
                        <ReviRepaFormulario
                            isEdditing={editEnabled}
                            setEdditing={setEditEnabled}
                            setErrorForm={setErrorOnForm}
                            errorForm={errorOnForm}
                        />
                    </Route>
                }

                <Redirect from="*" to={allAppRoutes.vehiclesReviRepaList} />

            </Switch>

            {/*BOTONES*/}
            {usePathname !== allAppRoutes.vehiclesReviRepaList &&
                <Buttons
                    addOrEdit={addingOrEditReviRepa}
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                    cancel={() => {
                        setEditEnabled(addingOrEditing.none);
                        usePath.replace(allAppRoutes.vehiclesReviRepaList);
                    }}
                    add={addNewReviRepa}
                    save={saveEditedReviRepa}
                    from="revisión / reparación"
                    thereIsList={true}
                    restoreForm={restoreReviRepaEdit}
                    disableSaveIfError={(errorOnForm === true) ? true : false}
                />
            }

        </Fragment >

    );
};

export default ReviRepa;
