import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from '../../ApplicationState';
import {
    saveFormBill, createBillPDF, addOrEditBill, setFormsBill,
    requestOneBill, calculateBill
} from "./BillsStore";
import {
    bill, addingOrEditing, usersTypes, loginUsers,
    formatedDirection, urlParmsForm, suscriptionData
} from "aseguisShared";
import NumberFormat from "react-number-format";
import { useHistory, useParams } from "react-router-dom";
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import ClientIncrSupl from "../Clients/ClientIncrSupl";
import BillsListCargasAndPaqs from "./BillsListCargasAndPaqs";
import InputDate from "../../common/Inputs/InputDate";
import { GetSuscriptionDataFromLocalStorage } from "../../common/commonInterfaces/commonFunctions";

interface infoToBillsForm {
    isEdditing: number;
    setEdditing: (value: addingOrEditing) => void,
}

function BillsForm(props: infoToBillsForm): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();
    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const billsForm: bill = useSelector((state: ApplicationState) => state.bills.billForm);
    const billsEditForm: bill = useSelector((state: ApplicationState) => state.bills.billEditForm);
    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [goToCalculateBill, setGoToCalculateBill] = useState(false);
    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    //para tener siempre la ultima versión...
    let history = useHistory()
    let { id } = useParams<urlParmsForm>();
    useEffect(() => {
        if (id === "new") {
        } else {
            requestOneBill(id)
                .then((bill) => {
                    if (bill.data.billsResults.length === 1) {
                        dispatch(addOrEditBill(addingOrEditing.edditing));
                        dispatch(setFormsBill(bill.data.billsResults[0]))
                    } else {
                        history.push(allAppRoutes.billsList)
                    }
                })
                .catch((error) => {
                    history.push(allAppRoutes.billsList)
                })
        }
    }, [id]);
    //...para tener siempre la ultima versión

    useEffect(() => {
        if (goToCalculateBill === true) {
            calculateBill(billsEditForm)
                .then((bill) => {
                    dispatch(saveFormBill(bill.data))
                })
                .catch((error) => {
                })
        }
        setGoToCalculateBill(false)
    }, [goToCalculateBill]);

    return (
        <Fragment>

            <div className="card shadow mb-4">

                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">
                        <span> {"# " + billsEditForm.id.toString()}</span>
                    </h6>
                </div>

                <div className="header-buttons">
                    {isAppLoading === true &&
                        <span className="spinner-border spinner-border-sm mr-4"></span>
                    }
                    <button
                        className="btn btn-info left-margin btn-sm"
                        onClick={() => {
                            dispatch(createBillPDF([billsEditForm], false))
                        }}>
                        Descargar factura
                    </button>
                </div>

                <div className="card-body">

                    <div className="form-row">

                        <div className="form-group col-md-4">
                            <div>
                                <label style={{ fontWeight: 'bold' }} >Cliente</label>
                                <div>{billsEditForm.client.nombre}</div>
                                <div>{billsEditForm.client.nif}</div>
                                <div>{formatedDirection(billsEditForm.client.address)}</div>
                                <div>{billsEditForm.client.email}</div>
                                <div>{billsEditForm.client.tel1}</div>
                            </div>
                        </div>

                        <InputDate
                            errorOnFormBlank={true}
                            colMesure="2"
                            editEnabled={props.isEdditing}
                            addOrEdit={addingOrEditing.edditing}
                            name="Fecha factura"
                            valueEdit={billsEditForm.fecFactur}
                            value={billsForm.fecFactur}
                            onChange={(value) => {
                                dispatch(saveFormBill({
                                    ...billsEditForm,
                                    fecFactur: value
                                }));
                            }}
                        />

                        <InputDate
                            errorOnFormBlank={true}
                            colMesure="2"
                            editEnabled={props.isEdditing}
                            addOrEdit={addingOrEditing.edditing}
                            name="Fecha vencimiento"
                            valueEdit={billsEditForm.fecVencimiento}
                            value={billsForm.fecVencimiento}
                            onChange={(value) => {
                                dispatch(saveFormBill({
                                    ...billsEditForm,
                                    fecVencimiento: value
                                }));
                            }}
                        />

                        <div className="form-group col-md-2">
                            <div>
                                <label style={{ fontWeight: 'bold' }} >ID factura</label>
                                <input
                                    name="idBill"
                                    disabled={true}
                                    type="text"
                                    value={"# " + billsEditForm.id}
                                    className="form-control undefined"
                                />
                            </div>
                        </div>

                        {userLogged.userType === usersTypes.employee &&
                            <div className="form-group col-md-2">
                                <div>
                                    <label style={{ fontWeight: 'bold' }} >Cobrada</label>

                                    {(props.isEdditing === addingOrEditing.edditing) ?
                                        <select
                                            value={billsEditForm.cobrada === true ? "Si" : "No"}
                                            className="form-control undefined"
                                            onChange={(event) => {
                                                var result = false;
                                                if (event.currentTarget.value === "Si") {
                                                    var result = true;
                                                }
                                                dispatch(saveFormBill({
                                                    ...billsEditForm,
                                                    cobrada: result
                                                }));
                                            }}>
                                            <option value="Si">Si</option>
                                            <option value="No">No</option>
                                        </select>
                                        :
                                        <input
                                            name="idBill"
                                            disabled={true}
                                            type="text"
                                            value={billsEditForm.cobrada ? "Si" : "No"}
                                            className="form-control undefined"
                                        />
                                    }
                                </div>
                            </div>
                        }

                    </div>

                    <BillsListCargasAndPaqs
                        isEdditing={props.isEdditing}
                        billForm={props.isEdditing === addingOrEditing.edditing ? billsEditForm : billsForm}
                        saveFormBill={(billToSave) => {
                            if (props.isEdditing) {
                                dispatch(saveFormBill(billToSave));
                                setGoToCalculateBill(true)
                            }
                        }}
                    />

                    <div className="form-group row">

                        {(suscriptionData.paqueteria) &&
                            <div className="col-sm-6">
                                <div className="card shadow mb-4" style={(props.isEdditing === addingOrEditing.edditing) ? { minHeight: "560px" } : { minHeight: "375px" }}>
                                    <div className="card-header">
                                        <h6 className="m-0 font-weight-bold text-green">Incrs y supls paquetería</h6>
                                    </div>
                                    <div className="card-body">

                                        <ClientIncrSupl
                                            width={12}
                                            textToShow="Descuentos Paquetería (%)"
                                            isEdditing={props.isEdditing}
                                            DescIncr={billsEditForm.paqueterias.descuentosPaqueteria}
                                            deleteDescIncr={(descIncr) => {
                                                dispatch(saveFormBill({
                                                    ...billsEditForm,
                                                    paqueterias: {
                                                        ...billsEditForm.paqueterias,
                                                        descuentosPaqueteria: billsEditForm.paqueterias.descuentosPaqueteria.filter(desc => desc.concepto !== descIncr.concepto)
                                                    }
                                                }));
                                                setGoToCalculateBill(true)
                                            }}
                                            saveDescIncr={(descIncr) => {
                                                dispatch(saveFormBill({
                                                    ...billsEditForm,
                                                    paqueterias: {
                                                        ...billsEditForm.paqueterias,
                                                        descuentosPaqueteria: [...billsEditForm.paqueterias.descuentosPaqueteria, { concepto: descIncr.concepto, porcentaje: descIncr.porcentaje, value: descIncr.value }]
                                                    }
                                                }));
                                                setGoToCalculateBill(true)
                                            }}
                                        />
                                        <ClientIncrSupl
                                            width={12}
                                            textToShow="Incrementos Paquetería (%)"
                                            isEdditing={props.isEdditing}
                                            DescIncr={billsEditForm.paqueterias.incrementosPaqueteria}
                                            deleteDescIncr={(descIncr) => {
                                                dispatch(saveFormBill({
                                                    ...billsEditForm,
                                                    paqueterias: {
                                                        ...billsEditForm.paqueterias,
                                                        incrementosPaqueteria: billsEditForm.paqueterias.incrementosPaqueteria.filter(desc => desc.concepto !== descIncr.concepto)
                                                    }
                                                }));
                                                setGoToCalculateBill(true)
                                            }}
                                            saveDescIncr={(descIncr) => {
                                                dispatch(saveFormBill({
                                                    ...billsEditForm,
                                                    paqueterias: {
                                                        ...billsEditForm.paqueterias,
                                                        incrementosPaqueteria: [...billsEditForm.paqueterias.incrementosPaqueteria, { concepto: descIncr.concepto, porcentaje: descIncr.porcentaje, value: descIncr.value }]
                                                    }
                                                }));
                                                setGoToCalculateBill(true)
                                            }}
                                        />

                                    </div>
                                </div>
                            </div>
                        }

                        <div className="col-sm-6">
                            <div className="card shadow mb-4" style={(props.isEdditing === addingOrEditing.edditing) ? { minHeight: "560px" } : { minHeight: "375px" }}>
                                <div className="card-header">
                                    <h6 className="m-0 font-weight-bold text-green">Incrs y supls carga completa</h6>
                                </div>
                                <div className="card-body">
                                    <ClientIncrSupl
                                        width={12}
                                        textToShow="Descuentos Carga Completa (%)"
                                        isEdditing={props.isEdditing}
                                        DescIncr={billsEditForm.cargasCompletas.descuentosCarga}
                                        deleteDescIncr={(descIncr) => {
                                            dispatch(saveFormBill({
                                                ...billsEditForm,
                                                cargasCompletas: {
                                                    ...billsEditForm.cargasCompletas,
                                                    descuentosCarga: billsEditForm.cargasCompletas.descuentosCarga.filter(desc => desc.concepto !== descIncr.concepto)
                                                }
                                            }));
                                            setGoToCalculateBill(true)
                                        }}
                                        saveDescIncr={(descIncr) => {
                                            dispatch(saveFormBill({
                                                ...billsEditForm,
                                                cargasCompletas: {
                                                    ...billsEditForm.cargasCompletas,
                                                    descuentosCarga: [...billsEditForm.cargasCompletas.descuentosCarga, { concepto: descIncr.concepto, porcentaje: descIncr.porcentaje, value: descIncr.value }]
                                                }
                                            }));
                                            setGoToCalculateBill(true)
                                        }}
                                    />
                                    <ClientIncrSupl
                                        width={12}
                                        textToShow="Incrementos Carga Completa (%)"
                                        isEdditing={props.isEdditing}
                                        DescIncr={billsEditForm.cargasCompletas.incrementosCarga}
                                        deleteDescIncr={(descIncr) => {
                                            dispatch(saveFormBill({
                                                ...billsEditForm,
                                                cargasCompletas: {
                                                    ...billsEditForm.cargasCompletas,
                                                    incrementosCarga: billsEditForm.cargasCompletas.incrementosCarga.filter(desc => desc.concepto !== descIncr.concepto)
                                                }
                                            }));
                                            setGoToCalculateBill(true)
                                        }}
                                        saveDescIncr={(descIncr) => {
                                            dispatch(saveFormBill({
                                                ...billsEditForm,
                                                cargasCompletas: {
                                                    ...billsEditForm.cargasCompletas,
                                                    incrementosCarga: [...billsEditForm.cargasCompletas.incrementosCarga, { concepto: descIncr.concepto, porcentaje: descIncr.porcentaje, value: descIncr.value }]
                                                }
                                            }));
                                            setGoToCalculateBill(true)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="form-group col-md-2 mt-3 mb-4">
                        <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={billsEditForm.datosEconomicosTotales.baseImponible}
                            className="form-control undefined"
                            renderText={value => <div><span style={{ fontWeight: 'bold' }}>{"Base Imponible:  "}</span>{value}</div>}
                            suffix={" €"}
                        />
                    </div>

                    <div className="form-group col-md-2 mt-3 mb-4">
                        <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={billsEditForm.datosEconomicosTotales.subTotal}
                            className="form-control undefined"
                            renderText={value => <div><span style={{ fontWeight: 'bold' }}>{"Subtotal:  "}</span>{value}</div>}
                            suffix={" €"}
                        />
                    </div>

                    {(billsEditForm.client.address.cpMongo.idPais === "ES") &&
                        <div className="form-group col-md-2 mt-3 mb-4">
                            <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                value={billsEditForm.datosEconomicosTotales.iva}
                                className="form-control undefined"
                                renderText={value => <div><span style={{ fontWeight: 'bold' }}>{"IVA:  "}</span>{value}</div>}
                                suffix={" €"}
                            />
                        </div>
                    }

                    <div className="form-group col-md-2 mt-3 mb-4">
                        <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={billsEditForm.datosEconomicosTotales.total}
                            className="form-control undefined"
                            renderText={value => <div><span style={{ fontWeight: 'bold' }}>{"Total:  "}</span>{value}</div>}
                            suffix={" €"}
                        />
                    </div>

                </div>
            </div>

        </Fragment>

    );
};

export default BillsForm;