import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { ApplicationState } from '../../ApplicationState';
import { useSelector } from "react-redux";
import {
    tariffPaqueteria, tariffCargaCompleta, getFormattedDate, tariffsTypes, tariff, OrderArraybyPropertiesOf, urlParmsForm,
    inicializeCargaCompletaTariff, addingOrEditing, formStates, inicializePaqueteriaTariff, tariffValuesPaq, suscriptionData
} from "aseguisShared"
import PaqueteriaTariffs from "./PaqueteriaTariffs";
import CargaTariffs from "./CargaTariffs";
import CargaPoblationsTariffs from "./CargaPoblationsTariffs";
import CargaTrayectoTariffs from "./CargaTrayectoTariffs";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useParams } from "react-router-dom";
import { GetSuscriptionDataFromLocalStorage } from "../commonInterfaces/commonFunctions";

interface infoToTariff {
    isEdditing: number,
    cardText: string
    originalTariff: tariff;
    editTariff: tariff;
    setEditTariff: (tariff: tariff) => void
    errorForm: boolean,
    setErrorForm: (value: boolean) => void,
    doOnRender?: (id: string) => void,
}

function Tariff(props: infoToTariff): ReactElement {

    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);
    const [formState, setFormState] = useState(0);
    const [whatTariffShow, setWhatTariffShow] = useState(tariffsTypes.bultos);
    const [errorForm, setErrorForm] = useState(false);
    const [tariffBultos, setTariffBultos] = useState<tariffPaqueteria>(inicializePaqueteriaTariff);
    const [tariffPeso, setTariffPeso] = useState<tariffPaqueteria>(inicializePaqueteriaTariff);
    const [tariffVolumen, setTariffVolumen] = useState<tariffPaqueteria>(inicializePaqueteriaTariff);
    const [tariffHoras, setTariffHoras] = useState<tariffCargaCompleta>(inicializeCargaCompletaTariff);
    const [tariffDistancia, setTariffDistancia] = useState<tariffCargaCompleta>(inicializeCargaCompletaTariff);
    const [tariffPoblacion, setTariffPoblacion] = useState<tariffCargaCompleta>(inicializeCargaCompletaTariff);
    const [tariffTrayecto, setTariffTrayecto] = useState<tariffCargaCompleta>(inicializeCargaCompletaTariff);
    const [errorOnBultos, setErrorOnBultos] = useState(false);
    const [errorOnPeso, setErrorOnPeso] = useState(false);
    const [errorOnVolumen, setErrorOnVolumen] = useState(false);
    const [errorOnHoras, setErrorOnHoras] = useState(false);
    const [errorOnDistancia, setErrorOnDistancia] = useState(false);
    const [errorOnPoblacion, setErrorOnPoblacion] = useState(false);
    const [errorOnTrayecto, setErrorOnTrayecto] = useState(false);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    let { id } = useParams<urlParmsForm>();
    useEffect(() => {
        if (props.doOnRender && id) {
            props.doOnRender(id)
        }
    }, []);

    function restoreEditOriginal(): void {
        switch (whatTariffShow) {
            case tariffsTypes.bultos: {
                props.editTariff.porBultos.map(tariff => {
                    if (tariff.id === tariffBultos.id) {
                        setTariffBultos(tariff)
                    }
                })
                break;
            }
            case tariffsTypes.peso: {
                props.editTariff.porPeso.map(tariff => {
                    if (tariff.id === tariffPeso.id) {
                        setTariffPeso(tariff)
                    }
                })
                break;
            }
            case tariffsTypes.volumen: {
                props.editTariff.porVolumen.map(tariff => {
                    if (tariff.id === tariffVolumen.id) {
                        setTariffVolumen(tariff)
                    }
                })
                break;
            }

            case tariffsTypes.horas: {
                props.editTariff.porHoras.map(tariff => {
                    if (tariff.id === tariffHoras.id) {
                        setTariffHoras(tariff)
                    }
                })
                break;
            }
            case tariffsTypes.distancia: {
                props.editTariff.porDistancia.map(tariff => {
                    if (tariff.id === tariffDistancia.id) {
                        setTariffDistancia(tariff)
                    }
                })
                break;
            }
            case tariffsTypes.poblacion: {
                props.editTariff.porPoblacion.map(tariff => {
                    if (tariff.id === tariffPoblacion.id) {
                        setTariffPoblacion(tariff)
                    }
                })
                break;
            }
            case tariffsTypes.trayecto: {
                props.editTariff.porTrayecto.map(tariff => {
                    if (tariff.id === tariffTrayecto.id) {
                        setTariffTrayecto(tariff)
                    }
                })
                break;
            }

            default: break;
        }
    }

    function restoreOriginal(): void {
        switch (whatTariffShow) {
            case tariffsTypes.bultos: {
                props.originalTariff.porBultos.map(tariff => {
                    if (tariff.id === tariffBultos.id) {
                        setTariffBultos(tariff)
                    }
                })
                break;
            }
            case tariffsTypes.peso: {
                props.originalTariff.porPeso.map(tariff => {
                    if (tariff.id === tariffPeso.id) {
                        setTariffPeso(tariff)
                    }
                })
                break;
            }
            case tariffsTypes.volumen: {
                props.originalTariff.porVolumen.map(tariff => {
                    if (tariff.id === tariffVolumen.id) {
                        setTariffVolumen(tariff)
                    }
                })
                break;
            }

            case tariffsTypes.horas: {
                props.originalTariff.porHoras.map(tariff => {
                    if (tariff.id === tariffHoras.id) {
                        setTariffHoras(tariff)
                    }
                })
                break;
            }
            case tariffsTypes.distancia: {
                props.originalTariff.porDistancia.map(tariff => {
                    if (tariff.id === tariffDistancia.id) {
                        setTariffDistancia(tariff)
                    }
                })
                break;
            }
            case tariffsTypes.poblacion: {
                props.originalTariff.porPoblacion.map(tariff => {
                    if (tariff.id === tariffPoblacion.id) {
                        setTariffPoblacion(tariff)
                    }
                })
                break;
            }
            case tariffsTypes.trayecto: {
                props.originalTariff.porTrayecto.map(tariff => {
                    if (tariff.id === tariffTrayecto.id) {
                        setTariffTrayecto(tariff)
                    }
                })
                break;
            }

            default: break;
        }
    }

    useEffect(() => {
        /* para que, cuando se hace click en restablecer original, se restablezcan tarifas */
        if (props.isEdditing === addingOrEditing.edditing) {
            restoreEditOriginal()
        }

        /* para que cuando se haga click en cancelar form, vuelva a las tarifas originales */
        if (props.isEdditing !== addingOrEditing.edditing /*&& formState == formStates.none && goToRestore == true*/) {
            restoreOriginal()
        }

        if (props.isEdditing !== addingOrEditing.edditing) {
            if (formState === formStates.add) {
                setTariffPeso(inicializePaqueteriaTariff)
                setTariffBultos(inicializePaqueteriaTariff)
                setTariffVolumen(inicializePaqueteriaTariff)
                setTariffDistancia(inicializeCargaCompletaTariff)
                setTariffPoblacion(inicializeCargaCompletaTariff)
                setTariffHoras(inicializeCargaCompletaTariff)
                setTariffTrayecto(inicializeCargaCompletaTariff)
            }
            setFormState(formStates.none)
        }

        if (
            (whatTariffShow === tariffsTypes.bultos && errorOnBultos === true) ||
            (whatTariffShow === tariffsTypes.horas && errorOnHoras === true) ||
            (whatTariffShow === tariffsTypes.distancia && errorOnDistancia === true) ||
            (whatTariffShow === tariffsTypes.volumen && errorOnVolumen === true) ||
            (whatTariffShow === tariffsTypes.peso && errorOnPeso === true) ||
            (whatTariffShow === tariffsTypes.poblacion && errorOnPoblacion === true) ||
            (whatTariffShow === tariffsTypes.trayecto && errorOnTrayecto === true)
        ) {
            setErrorForm(true)
            props.setErrorForm(true)
        } else {
            setErrorForm(false)
            props.setErrorForm(false)

            //para que, si está añadiendo o editando, no deje darle a "guardar compañia, cliente, o lo que sea"
            if ((formState !== formStates.none)) {
                props.setErrorForm(true)
            }
        }
    });


    return (

        <Fragment>

            <div className="row">

                {/*************** TABLA DE TARIFAS ********************/}
                {/*************** TABLA DE TARIFAS ********************/}
                {/*************** TABLA DE TARIFAS ********************/}
                {/*************** TABLA DE TARIFAS ********************/}

                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">


                        <div className="card-header">


                            <h6 className="m-0 font-weight-bold text-green">
                                <span> {props.cardText}</span>
                            </h6>

                            {isAppLoading === true &&
                                <div className="header-buttons">
                                    <span className="spinner-border spinner-border-sm mr-4"></span>
                                </div>
                            }

                            {((isAppLoading === false) && (props.isEdditing === addingOrEditing.edditing)) &&

                                <div>
                                    {(formState === formStates.none) &&
                                        <div className="header-buttons">

                                            {/* AÑADIR BOTON*/}
                                            <button className="btn btn-success btn-green btn-sm"
                                                onClick={() => {
                                                    setFormState(formStates.add)

                                                    setTariffBultos({ ...inicializePaqueteriaTariff, id: props.editTariff.porBultos.length > 0 ? props.editTariff.porBultos[props.editTariff.porBultos.length - 1].id + 1 : 1 })
                                                    setTariffPeso({ ...inicializePaqueteriaTariff, id: props.editTariff.porPeso.length > 0 ? props.editTariff.porPeso[props.editTariff.porPeso.length - 1].id + 1 : 1 })
                                                    setTariffVolumen({ ...inicializePaqueteriaTariff, id: props.editTariff.porVolumen.length > 0 ? props.editTariff.porVolumen[props.editTariff.porVolumen.length - 1].id + 1 : 1 })

                                                    setTariffHoras({ ...inicializeCargaCompletaTariff, id: props.editTariff.porHoras.length > 0 ? props.editTariff.porHoras[props.editTariff.porHoras.length - 1].id + 1 : 1 })
                                                    setTariffDistancia({ ...inicializeCargaCompletaTariff, id: props.editTariff.porDistancia.length > 0 ? props.editTariff.porDistancia[props.editTariff.porDistancia.length - 1].id + 1 : 1 })
                                                    setTariffPoblacion({ ...inicializeCargaCompletaTariff, id: props.editTariff.porPoblacion.length > 0 ? props.editTariff.porPoblacion[props.editTariff.porPoblacion.length - 1].id + 1 : 1 })
                                                    setTariffTrayecto({ ...inicializeCargaCompletaTariff, id: props.editTariff.porTrayecto.length > 0 ? props.editTariff.porTrayecto[props.editTariff.porTrayecto.length - 1].id + 1 : 1 })
                                                }}>
                                                <i className="fa fa fa-plus"></i>
                                            </button>

                                            {/*COPY button*/}
                                            {(
                                                (whatTariffShow === tariffsTypes.bultos && tariffBultos.id > 0) ||
                                                (whatTariffShow === tariffsTypes.horas && tariffHoras.id > 0) ||
                                                (whatTariffShow === tariffsTypes.distancia && tariffDistancia.id > 0) ||
                                                (whatTariffShow === tariffsTypes.volumen && tariffVolumen.id > 0) ||
                                                (whatTariffShow === tariffsTypes.peso && tariffPeso.id > 0) ||
                                                (whatTariffShow === tariffsTypes.poblacion && tariffPoblacion.id > 0) ||
                                                (whatTariffShow === tariffsTypes.trayecto && tariffTrayecto.id > 0)
                                            ) &&
                                                <button className="btn btn-success btn-green btn-sm"
                                                    onClick={() => {
                                                        setFormState(formStates.add)
                                                        switch (whatTariffShow) {

                                                            case tariffsTypes.bultos: {
                                                                setTariffBultos({
                                                                    ...inicializePaqueteriaTariff,
                                                                    tariffs: tariffBultos.tariffs,
                                                                    tariffLocations: tariffBultos.tariffLocations,
                                                                    multiplicateValue: tariffBultos.multiplicateValue,
                                                                    minImport: tariffBultos.minImport,
                                                                    id: props.editTariff.porBultos.length > 0 ? props.editTariff.porBultos[props.editTariff.porBultos.length - 1].id + 1 : 1
                                                                })
                                                                break;
                                                            }
                                                            case tariffsTypes.volumen: {
                                                                setTariffVolumen({
                                                                    ...inicializePaqueteriaTariff,
                                                                    tariffs: tariffVolumen.tariffs,
                                                                    tariffLocations: tariffVolumen.tariffLocations,
                                                                    multiplicateValue: tariffVolumen.multiplicateValue,
                                                                    minImport: tariffVolumen.minImport,
                                                                    id: props.editTariff.porVolumen.length > 0 ? props.editTariff.porVolumen[props.editTariff.porVolumen.length - 1].id + 1 : 1
                                                                })
                                                                break;
                                                            }
                                                            case tariffsTypes.peso: {
                                                                setTariffPeso({
                                                                    ...inicializePaqueteriaTariff,
                                                                    tariffs: tariffPeso.tariffs,
                                                                    multiplicateValue: tariffPeso.multiplicateValue,
                                                                    tariffLocations: tariffPeso.tariffLocations,
                                                                    minImport: tariffPeso.minImport,
                                                                    id: props.editTariff.porPeso.length > 0 ? props.editTariff.porPeso[props.editTariff.porPeso.length - 1].id + 1 : 1
                                                                })
                                                                break;
                                                            }

                                                            case tariffsTypes.horas: {
                                                                setTariffHoras({
                                                                    ...inicializeCargaCompletaTariff,
                                                                    tariffs: tariffHoras.tariffs,
                                                                    locTariffs: tariffHoras.locTariffs,
                                                                    auxImport: tariffHoras.auxImport,
                                                                    minImport: tariffHoras.minImport,
                                                                    multiplicateValue: tariffHoras.multiplicateValue,
                                                                    id: props.editTariff.porHoras.length > 0 ? props.editTariff.porHoras[props.editTariff.porHoras.length - 1].id + 1 : 1
                                                                })
                                                                break;
                                                            }
                                                            case tariffsTypes.distancia: {
                                                                setTariffDistancia({
                                                                    ...inicializeCargaCompletaTariff,
                                                                    tariffs: tariffDistancia.tariffs,
                                                                    locTariffs: tariffDistancia.locTariffs,
                                                                    auxImport: tariffDistancia.auxImport,
                                                                    minImport: tariffDistancia.minImport,
                                                                    multiplicateValue: tariffDistancia.multiplicateValue,
                                                                    id: props.editTariff.porDistancia.length > 0 ? props.editTariff.porDistancia[props.editTariff.porDistancia.length - 1].id + 1 : 1
                                                                })
                                                                break;
                                                            }
                                                            case tariffsTypes.poblacion: {
                                                                setTariffPoblacion({
                                                                    ...inicializeCargaCompletaTariff,
                                                                    tariffs: tariffPoblacion.tariffs,
                                                                    locTariffs: tariffPoblacion.locTariffs,
                                                                    auxImport: tariffPoblacion.auxImport,
                                                                    minImport: tariffPoblacion.minImport,
                                                                    multiplicateValue: tariffPoblacion.multiplicateValue,
                                                                    id: props.editTariff.porPoblacion.length > 0 ? props.editTariff.porPoblacion[props.editTariff.porPoblacion.length - 1].id + 1 : 1
                                                                })
                                                                break;
                                                            }
                                                            case tariffsTypes.trayecto: {
                                                                setTariffTrayecto({
                                                                    ...inicializeCargaCompletaTariff,
                                                                    tariffs: tariffTrayecto.tariffs,
                                                                    locTariffs: tariffTrayecto.locTariffs,
                                                                    auxImport: tariffTrayecto.auxImport,
                                                                    minImport: tariffTrayecto.minImport,
                                                                    multiplicateValue: tariffTrayecto.multiplicateValue,
                                                                    id: props.editTariff.porTrayecto.length > 0 ? props.editTariff.porTrayecto[props.editTariff.porTrayecto.length - 1].id + 1 : 1
                                                                })
                                                                break;
                                                            }
                                                            default: break;
                                                        }
                                                    }}>
                                                    <i className="fas fa fa-copy"></i>
                                                </button>
                                            }


                                            {/*edit button*/}
                                            {(
                                                (whatTariffShow === tariffsTypes.bultos && tariffBultos.id > 0) ||
                                                (whatTariffShow === tariffsTypes.horas && tariffHoras.id > 0) ||
                                                (whatTariffShow === tariffsTypes.distancia && tariffDistancia.id > 0) ||
                                                (whatTariffShow === tariffsTypes.volumen && tariffVolumen.id > 0) ||
                                                (whatTariffShow === tariffsTypes.peso && tariffPeso.id > 0) ||
                                                (whatTariffShow === tariffsTypes.poblacion && tariffPoblacion.id > 0) ||
                                                (whatTariffShow === tariffsTypes.trayecto && tariffTrayecto.id > 0)
                                            ) &&
                                                <button className="btn btn-success btn-blue btn-sm"
                                                    onClick={() => {
                                                        setFormState(formStates.edit)
                                                    }}>
                                                    <i className="fas fa fa-pen"></i>
                                                </button>
                                            }

                                            {/*delete button*/}
                                            {(
                                                (whatTariffShow === tariffsTypes.bultos && tariffBultos.id > 0) ||
                                                (whatTariffShow === tariffsTypes.horas && tariffHoras.id > 0) ||
                                                (whatTariffShow === tariffsTypes.distancia && tariffDistancia.id > 0) ||
                                                (whatTariffShow === tariffsTypes.volumen && tariffVolumen.id > 0) ||
                                                (whatTariffShow === tariffsTypes.peso && tariffPeso.id > 0) ||
                                                (whatTariffShow === tariffsTypes.poblacion && tariffPoblacion.id > 0) ||
                                                (whatTariffShow === tariffsTypes.trayecto && tariffTrayecto.id > 0)
                                            ) &&
                                                <button className="btn btn-success btn-red btn-sm"
                                                    onClick={() => {

                                                        switch (whatTariffShow) {
                                                            case tariffsTypes.bultos: {
                                                                props.setEditTariff({
                                                                    ...props.editTariff,
                                                                    porBultos: props.editTariff.porBultos.filter(tariff => tariff.id !== tariffBultos.id)
                                                                });
                                                                setTariffBultos(inicializePaqueteriaTariff);
                                                                break;
                                                            }
                                                            case tariffsTypes.horas: {
                                                                props.setEditTariff({
                                                                    ...props.editTariff,
                                                                    porHoras: props.editTariff.porHoras.filter(tariff => tariff.id !== tariffHoras.id)
                                                                });
                                                                setTariffHoras(inicializeCargaCompletaTariff);
                                                                break;
                                                            }
                                                            case tariffsTypes.distancia: {
                                                                props.setEditTariff({
                                                                    ...props.editTariff,
                                                                    porDistancia: props.editTariff.porDistancia.filter(tariff => tariff.id !== tariffDistancia.id)
                                                                });
                                                                setTariffDistancia(inicializeCargaCompletaTariff);
                                                                break;
                                                            }
                                                            case tariffsTypes.volumen: {
                                                                props.setEditTariff({
                                                                    ...props.editTariff,
                                                                    porVolumen: props.editTariff.porVolumen.filter(tariff => tariff.id !== tariffVolumen.id)
                                                                });
                                                                setTariffVolumen(inicializePaqueteriaTariff);
                                                                break;
                                                            }
                                                            case tariffsTypes.peso: {
                                                                props.setEditTariff({
                                                                    ...props.editTariff,
                                                                    porPeso: props.editTariff.porPeso.filter(tariff => tariff.id !== tariffPeso.id)
                                                                });
                                                                setTariffPeso(inicializePaqueteriaTariff);
                                                                break;
                                                            }
                                                            case tariffsTypes.poblacion: {
                                                                props.setEditTariff({
                                                                    ...props.editTariff,
                                                                    porPoblacion: props.editTariff.porPoblacion.filter(tariff => tariff.id !== tariffPoblacion.id)
                                                                });
                                                                setTariffPoblacion(inicializeCargaCompletaTariff);
                                                                break;
                                                            }
                                                            case tariffsTypes.trayecto: {
                                                                props.setEditTariff({
                                                                    ...props.editTariff,
                                                                    porTrayecto: props.editTariff.porTrayecto.filter(tariff => tariff.id !== tariffTrayecto.id)
                                                                });
                                                                setTariffTrayecto(inicializeCargaCompletaTariff);
                                                                break;
                                                            }
                                                            default: break;
                                                        }

                                                    }}>
                                                    <i className="fas fa fa-times"></i>
                                                </button>
                                            }
                                        </div>

                                    }
                                </div>

                            }


                            <div className="header-buttons">

                                {/* ESTADO AÑADIR */}
                                {(formState === formStates.add && props.isEdditing === addingOrEditing.edditing) &&
                                    <button className={errorForm === false ? "btn btn-success left-margin btn-sm" : "btn btn-success left-margin btn-sm disabled"}
                                        onClick={() => {
                                            if (errorForm === false) {
                                                setFormState(formStates.none)
                                                switch (whatTariffShow) {
                                                    case tariffsTypes.bultos: {
                                                        props.setEditTariff({
                                                            ...props.editTariff,
                                                            porBultos: [
                                                                ...props.editTariff.porBultos,
                                                                {
                                                                    ...tariffBultos,
                                                                    tariffs: tariffBultos.tariffs.sort(OrderArraybyPropertiesOf<tariffValuesPaq>(['hasta']))
                                                                }
                                                            ]
                                                        })
                                                        break;
                                                    }
                                                    case tariffsTypes.peso: {
                                                        props.setEditTariff({
                                                            ...props.editTariff,
                                                            porPeso: [
                                                                ...props.editTariff.porPeso,
                                                                {
                                                                    ...tariffPeso,
                                                                    tariffs: tariffPeso.tariffs.sort(OrderArraybyPropertiesOf<tariffValuesPaq>(['hasta']))
                                                                }
                                                            ]
                                                        })
                                                        break;
                                                    }
                                                    case tariffsTypes.volumen: {
                                                        props.setEditTariff({
                                                            ...props.editTariff,
                                                            porVolumen: [
                                                                ...props.editTariff.porVolumen,
                                                                {
                                                                    ...tariffVolumen,
                                                                    tariffs: tariffVolumen.tariffs.sort(OrderArraybyPropertiesOf<tariffValuesPaq>(['hasta']))
                                                                }
                                                            ]
                                                        })
                                                        break;
                                                    }

                                                    case tariffsTypes.horas: {
                                                        props.setEditTariff({
                                                            ...props.editTariff,
                                                            porHoras: [
                                                                ...props.editTariff.porHoras,
                                                                {
                                                                    ...tariffHoras,
                                                                    tariffs: tariffHoras.tariffs.sort(OrderArraybyPropertiesOf<tariffValuesPaq>(['hasta']))
                                                                }
                                                            ]
                                                        })
                                                        break;
                                                    }
                                                    case tariffsTypes.distancia: {
                                                        props.setEditTariff({
                                                            ...props.editTariff,
                                                            porDistancia: [
                                                                ...props.editTariff.porDistancia,
                                                                {
                                                                    ...tariffDistancia,
                                                                    tariffs: tariffDistancia.tariffs.sort(OrderArraybyPropertiesOf<tariffValuesPaq>(['hasta']))
                                                                }
                                                            ]
                                                        })
                                                        break;
                                                    }

                                                    case tariffsTypes.poblacion: {
                                                        props.setEditTariff({
                                                            ...props.editTariff,
                                                            porPoblacion: [...props.editTariff.porPoblacion, { ...tariffPoblacion }]
                                                        })
                                                        break;
                                                    }

                                                    case tariffsTypes.trayecto: {
                                                        props.setEditTariff({
                                                            ...props.editTariff,
                                                            porTrayecto: [...props.editTariff.porTrayecto, { ...tariffTrayecto }]
                                                        })
                                                        break;
                                                    }
                                                    default: break;
                                                }
                                            }
                                        }}>
                                        Añadir tarifa
                                    </button>
                                }

                                {/* ESTADO EDITAR */}
                                {(formState === formStates.edit) &&
                                    <button className={errorForm === false ? "btn btn-success left-margin btn-sm" : "btn btn-success left-margin btn-sm disabled"}
                                        onClick={() => {
                                            if (errorForm === false) {
                                                setFormState(formStates.none)
                                                switch (whatTariffShow) {
                                                    case tariffsTypes.bultos: {
                                                        props.setEditTariff({
                                                            ...props.editTariff,
                                                            porBultos: props.editTariff.porBultos.map(tariff => {
                                                                if (tariff.id !== tariffBultos.id) {
                                                                    return tariff;
                                                                }
                                                                return {
                                                                    ...tariffBultos,
                                                                    tariffs: tariffBultos.tariffs.sort(OrderArraybyPropertiesOf<tariffValuesPaq>(['hasta']))
                                                                };
                                                            }),
                                                        });
                                                        break;
                                                    }
                                                    case tariffsTypes.volumen: {
                                                        props.setEditTariff({
                                                            ...props.editTariff,
                                                            porVolumen: props.editTariff.porVolumen.map(tariff => {
                                                                if (tariff.id !== tariffVolumen.id) {
                                                                    return tariff;
                                                                }
                                                                return {
                                                                    ...tariffVolumen,
                                                                    tariffs: tariffVolumen.tariffs.sort(OrderArraybyPropertiesOf<tariffValuesPaq>(['hasta']))
                                                                };
                                                            }),
                                                        });
                                                        break;
                                                    }
                                                    case tariffsTypes.peso: {
                                                        props.setEditTariff({
                                                            ...props.editTariff,
                                                            porPeso: props.editTariff.porPeso.map(tariff => {
                                                                if (tariff.id !== tariffPeso.id) {
                                                                    return tariff;
                                                                }
                                                                return {
                                                                    ...tariffPeso,
                                                                    tariffs: tariffPeso.tariffs.sort(OrderArraybyPropertiesOf<tariffValuesPaq>(['hasta']))
                                                                };
                                                            }),
                                                        });
                                                        break;
                                                    }
                                                    case tariffsTypes.horas: {
                                                        props.setEditTariff({
                                                            ...props.editTariff,
                                                            porHoras: props.editTariff.porHoras.map(tariff => {
                                                                if (tariff.id !== tariffHoras.id) {
                                                                    return tariff;
                                                                }
                                                                return {
                                                                    ...tariffHoras,
                                                                    tariffs: tariffHoras.tariffs.sort(OrderArraybyPropertiesOf<tariffValuesPaq>(['hasta']))
                                                                };
                                                            }),
                                                        });
                                                        break;
                                                    }
                                                    case tariffsTypes.distancia: {
                                                        props.setEditTariff({
                                                            ...props.editTariff,
                                                            porDistancia: props.editTariff.porDistancia.map(tariff => {
                                                                if (tariff.id !== tariffDistancia.id) {
                                                                    return tariff;
                                                                }
                                                                return {
                                                                    ...tariffDistancia,
                                                                    tariffs: tariffDistancia.tariffs.sort(OrderArraybyPropertiesOf<tariffValuesPaq>(['hasta']))
                                                                };
                                                            }),
                                                        });
                                                        break;
                                                    }
                                                    case tariffsTypes.poblacion: {
                                                        props.setEditTariff({
                                                            ...props.editTariff,
                                                            porPoblacion: props.editTariff.porPoblacion.map(tariff => {
                                                                if (tariff.id !== tariffPoblacion.id) {
                                                                    return tariff;
                                                                }
                                                                return tariffPoblacion;
                                                            }),
                                                        });
                                                        break;
                                                    }
                                                    case tariffsTypes.trayecto: {
                                                        props.setEditTariff({
                                                            ...props.editTariff,
                                                            porTrayecto: props.editTariff.porTrayecto.map(tariff => {
                                                                if (tariff.id !== tariffTrayecto.id) {
                                                                    return tariff;
                                                                }
                                                                return tariffTrayecto;
                                                            }),
                                                        });
                                                        break;
                                                    }
                                                    default: break;
                                                }
                                            }
                                        }}>
                                        Hecho
                                    </button>
                                }

                                {/* CANCELAR PARA ESTADO AÑADIR */}
                                {(formState === formStates.add && props.isEdditing === addingOrEditing.edditing) &&
                                    <button className="btn btn-danger left-margin btn-sm"
                                        onClick={() => {
                                            setTariffPeso(inicializePaqueteriaTariff)
                                            setTariffBultos(inicializePaqueteriaTariff)
                                            setTariffVolumen(inicializePaqueteriaTariff)
                                            setTariffDistancia(inicializeCargaCompletaTariff)
                                            setTariffPoblacion(inicializeCargaCompletaTariff)
                                            setTariffTrayecto(inicializeCargaCompletaTariff)
                                            setTariffHoras(inicializeCargaCompletaTariff)
                                            setFormState(formStates.none)
                                        }}>
                                        Cancelar
                                    </button>
                                }


                                {/* RESTABLECER PARA ESTADO EDITAR - funciona pero lo comento porque es confuso. */}
                                {/*((formState === formStates.edit) && props.isEdditing === addingOrEditing.edditing) &&
                                    <button className="btn btn-info left-margin"
                                        onClick={() => {
                                            //setFormState(formStates.none)
                                            switch (whatTariffShow) {
                                                case tariffsTypes.bultos: {
                                                    props.originalTariff.porBultos.map(tariff => {
                                                        if (tariff.id === tariffBultos.id) {
                                                            props.setEditTariff({
                                                                ...props.editTariff,
                                                                porBultos: props.editTariff.porBultos.map(tariff2 => {
                                                                    if (tariff2.id === tariff.id) {
                                                                        return tariff
                                                                    }
                                                                    return tariff2
                                                                })
                                                            })
                                                        }
                                                    })
                                                    break;
                                                }
                                                case tariffsTypes.horas: {
                                                    props.originalTariff.porHoras.map(tariff => {
                                                        if (tariff.id === tariffHoras.id) {
                                                            props.setEditTariff({
                                                                ...props.editTariff,
                                                                porHoras: props.editTariff.porHoras.map(tariff2 => {
                                                                    if (tariff2.id === tariff.id) {
                                                                        return tariff
                                                                    }
                                                                    return tariff2
                                                                })
                                                            })
                                                        }
                                                    })
                                                    break;
                                                }
                                                case tariffsTypes.distancia: {
                                                    props.originalTariff.porDistancia.map(tariff => {
                                                        if (tariff.id === tariffDistancia.id) {
                                                            props.setEditTariff({
                                                                ...props.editTariff,
                                                                porDistancia: props.editTariff.porDistancia.map(tariff2 => {
                                                                    if (tariff2.id === tariff.id) {
                                                                        return tariff
                                                                    }
                                                                    return tariff2
                                                                })
                                                            })
                                                        }
                                                    })
                                                    break;
                                                }
                                                case tariffsTypes.volumen: {
                                                    props.originalTariff.porVolumen.map(tariff => {
                                                        if (tariff.id === tariffVolumen.id) {
                                                            props.setEditTariff({
                                                                ...props.editTariff,
                                                                porVolumen: props.editTariff.porVolumen.map(tariff2 => {
                                                                    if (tariff2.id === tariff.id) {
                                                                        return tariff
                                                                    }
                                                                    return tariff2
                                                                })
                                                            })
                                                        }
                                                    })
                                                    break;
                                                }
                                                case tariffsTypes.peso: {
                                                    props.originalTariff.porPeso.map(tariff => {
                                                        if (tariff.id === tariffPeso.id) {
                                                            props.setEditTariff({
                                                                ...props.editTariff,
                                                                porPeso: props.editTariff.porPeso.map(tariff2 => {
                                                                    if (tariff2.id === tariff.id) {
                                                                        return tariff
                                                                    }
                                                                    return tariff2
                                                                })
                                                            })
                                                        }
                                                    })
                                                    break;
                                                }
                                                case tariffsTypes.poblacion: {
                                                    props.originalTariff.porPoblacion.map(tariff => {
                                                        if (tariff.id === tariffPoblacion.id) {
                                                            props.setEditTariff({
                                                                ...props.editTariff,
                                                                porPoblacion: props.editTariff.porPoblacion.map(tariff2 => {
                                                                    if (tariff2.id === tariff.id) {
                                                                        return tariff
                                                                    }
                                                                    return tariff2
                                                                })
                                                            })
                                                        }
                                                    })
                                                    break;
                                                }
                                                case tariffsTypes.trayecto: {
                                                    props.originalTariff.porTrayecto.map(tariff => {
                                                        if (tariff.id === tariffTrayecto.id) {
                                                            props.setEditTariff({
                                                                ...props.editTariff,
                                                                porTrayecto: props.editTariff.porTrayecto.map(tariff2 => {
                                                                    if (tariff2.id === tariff.id) {
                                                                        return tariff
                                                                    }
                                                                    return tariff2
                                                                })
                                                            })
                                                        }
                                                    })
                                                    break;
                                                }
                                                default: break;
                                            }
                                        }}>
                                        Restablecer
                                    </button>
                                */}

                            </div>

                        </div>



                        {/***************************************************************** TABS ******************************************************************************/}

                        <div className="p-2">

                            <Tabs
                                defaultIndex={0}
                            >
                                <TabList style={{ fontWeight: "bold", backgroundColor: "#f7f7f7" }}>
                                    {suscriptionData.paqueteria &&
                                        <Tab
                                            onClick={() => {
                                                setFormState(formStates.none)
                                                setWhatTariffShow(tariffsTypes.bultos)
                                            }}
                                        >
                                            Paquetería
                                        </Tab>
                                    }
                                    <Tab
                                        onClick={() => {
                                            setFormState(formStates.none)
                                            setWhatTariffShow(tariffsTypes.horas)
                                        }}
                                    >
                                        Carga Completa
                                    </Tab>
                                </TabList>
                                <TabPanel>
                                    <Tabs
                                    >
                                        <TabList style={{ fontWeight: "bold", backgroundColor: "#f7f7f7" }}>
                                            <Tab
                                                onClick={() => {
                                                    setFormState(formStates.none)
                                                    setTariffBultos(inicializePaqueteriaTariff)
                                                    setWhatTariffShow(tariffsTypes.bultos)
                                                }}
                                            >
                                                Por bultos
                                            </Tab>
                                            <Tab
                                                onClick={() => {
                                                    setFormState(formStates.none)
                                                    setTariffPeso(inicializePaqueteriaTariff)
                                                    setWhatTariffShow(tariffsTypes.peso)
                                                }}
                                            >
                                                Por peso
                                            </Tab>
                                            <Tab
                                                onClick={() => {
                                                    setFormState(formStates.none)
                                                    setTariffVolumen(inicializePaqueteriaTariff)
                                                    setWhatTariffShow(tariffsTypes.volumen)
                                                }}
                                            >
                                                Por volumen
                                            </Tab>
                                        </TabList>
                                        <TabPanel>
                                            <PaqueteriaTariffs
                                                allTariff={props.isEdditing === addingOrEditing.edditing ? props.editTariff.porBultos : props.originalTariff.porBultos}
                                                formTariff={tariffBultos}
                                                setFormTariff={(porBultos) => {
                                                    props.setEditTariff({
                                                        ...props.editTariff,
                                                        porBultos: props.editTariff.porBultos.map(tariff => {
                                                            if (tariff.id === porBultos.id) {
                                                                return porBultos;
                                                            }
                                                            return tariff;
                                                        }),
                                                    });
                                                    setTariffBultos(porBultos)
                                                }}
                                                formState={formState}
                                                from="bultos"
                                                setErrorForm={setErrorOnBultos}
                                            />
                                        </TabPanel>
                                        <TabPanel>
                                            <PaqueteriaTariffs
                                                allTariff={props.isEdditing === addingOrEditing.edditing ? props.editTariff.porPeso : props.originalTariff.porPeso}
                                                formTariff={tariffPeso}
                                                setFormTariff={(porPeso) => {
                                                    props.setEditTariff({
                                                        ...props.editTariff,
                                                        porPeso: props.editTariff.porPeso.map(tariff => {
                                                            if (tariff.id === porPeso.id) {
                                                                return porPeso;
                                                            }
                                                            return tariff;
                                                        }),
                                                    });
                                                    setTariffPeso(porPeso)
                                                }}
                                                formState={formState}
                                                from="kg"
                                                setErrorForm={setErrorOnPeso}
                                            />
                                        </TabPanel>
                                        <TabPanel>
                                            <PaqueteriaTariffs
                                                allTariff={props.isEdditing === addingOrEditing.edditing ? props.editTariff.porVolumen : props.originalTariff.porVolumen}
                                                formTariff={tariffVolumen}
                                                setFormTariff={(porMetros) => {
                                                    props.setEditTariff({
                                                        ...props.editTariff,
                                                        porVolumen: props.editTariff.porVolumen.map(tariff => {
                                                            if (tariff.id === porMetros.id) {
                                                                return porMetros;
                                                            }
                                                            return tariff;
                                                        }),
                                                    });
                                                    setTariffVolumen(porMetros)
                                                }}
                                                formState={formState}
                                                from="m3"
                                                setErrorForm={setErrorOnVolumen}
                                            />
                                        </TabPanel>
                                    </Tabs>
                                </TabPanel>
                                <TabPanel>
                                    <Tabs
                                    >
                                        <TabList style={{ fontWeight: "bold", backgroundColor: "#f7f7f7" }}>
                                            <Tab
                                                onClick={() => {
                                                    setFormState(formStates.none)
                                                    setTariffHoras(inicializeCargaCompletaTariff)
                                                    setWhatTariffShow(tariffsTypes.horas)
                                                }}
                                            >
                                                Por horas
                                            </Tab>
                                            <Tab
                                                onClick={() => {
                                                    setFormState(formStates.none)
                                                    setTariffDistancia(inicializeCargaCompletaTariff)
                                                    setWhatTariffShow(tariffsTypes.distancia)
                                                }}
                                            >
                                                Por distancia recorrida
                                            </Tab>
                                            <Tab
                                                onClick={() => {
                                                    setFormState(formStates.none)
                                                    setTariffPoblacion(inicializeCargaCompletaTariff)
                                                    setWhatTariffShow(tariffsTypes.poblacion)
                                                }}
                                            >
                                                Por población destino
                                            </Tab>
                                            <Tab
                                                onClick={() => {
                                                    setFormState(formStates.none)
                                                    setTariffTrayecto(inicializeCargaCompletaTariff)
                                                    setWhatTariffShow(tariffsTypes.trayecto)
                                                }}
                                            >
                                                Trayectos guardados
                                            </Tab>
                                        </TabList>
                                        <TabPanel>
                                            <CargaTariffs
                                                allTariff={props.isEdditing === addingOrEditing.edditing ? props.editTariff.porHoras : props.originalTariff.porHoras}
                                                formTariff={tariffHoras}
                                                setFormTariff={(porHoras) => {
                                                    props.setEditTariff({
                                                        ...props.editTariff,
                                                        porHoras: props.editTariff.porHoras.map(tariff => {
                                                            if (tariff.id === porHoras.id) {
                                                                return porHoras;
                                                            }
                                                            return tariff;
                                                        }),
                                                    });
                                                    setTariffHoras(porHoras)
                                                }}
                                                formState={formState}
                                                timestamp={getFormattedDate()}
                                                from={whatTariffShow}
                                                setErrorForm={setErrorOnHoras}
                                            />
                                        </TabPanel>
                                        <TabPanel>
                                            <CargaTariffs
                                                allTariff={props.isEdditing === addingOrEditing.edditing ? props.editTariff.porDistancia : props.originalTariff.porDistancia}
                                                formTariff={tariffDistancia}
                                                setFormTariff={(porDistancia) => {
                                                    props.setEditTariff({
                                                        ...props.editTariff,
                                                        porDistancia: props.editTariff.porDistancia.map(tariff => {
                                                            if (tariff.id === porDistancia.id) {
                                                                return porDistancia;
                                                            }
                                                            return tariff;
                                                        }),
                                                    });
                                                    setTariffDistancia(porDistancia)
                                                }}
                                                formState={formState}
                                                timestamp={getFormattedDate()}
                                                from="km"
                                                setErrorForm={setErrorOnDistancia}
                                            />
                                        </TabPanel>
                                        <TabPanel>
                                            <CargaPoblationsTariffs
                                                allTariff={props.isEdditing === addingOrEditing.edditing ? props.editTariff.porPoblacion : props.originalTariff.porPoblacion}
                                                formTariff={tariffPoblacion}
                                                setFormTariff={(porPoblacion) => {
                                                    props.setEditTariff({
                                                        ...props.editTariff,
                                                        porPoblacion: props.editTariff.porPoblacion.map(tariff => {
                                                            if (tariff.id === porPoblacion.id) {
                                                                return porPoblacion;
                                                            }
                                                            return tariff;
                                                        }),
                                                    });
                                                    setTariffPoblacion(porPoblacion)
                                                }}
                                                formState={formState}
                                                timestamp={getFormattedDate()}
                                                setErrorForm={setErrorOnPoblacion}
                                            />
                                        </TabPanel>
                                        <TabPanel>
                                            <CargaTrayectoTariffs
                                                allTariff={props.isEdditing === addingOrEditing.edditing ? props.editTariff.porTrayecto : props.originalTariff.porTrayecto}
                                                formTariff={tariffTrayecto}
                                                setFormTariff={(porTrayecto) => {
                                                    props.setEditTariff({
                                                        ...props.editTariff,
                                                        porTrayecto: props.editTariff.porTrayecto.map(tariff => {
                                                            if (tariff.id === porTrayecto.id) {
                                                                return porTrayecto;
                                                            }
                                                            return tariff;
                                                        }),
                                                    });
                                                    setTariffTrayecto(porTrayecto)
                                                }}
                                                formState={formState}
                                                timestamp={getFormattedDate()}
                                                setErrorForm={setErrorOnTrayecto}
                                            />
                                        </TabPanel>
                                    </Tabs>
                                </TabPanel>
                            </Tabs>

                        </div>

                        {/***************************************************************** TABS ******************************************************************************/}

                    </div>

                </div>
            </div>

        </Fragment>

    );

}


export default Tariff;