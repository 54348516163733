import React, { Fragment, ReactElement, useState } from "react";
import {  } from './ClientsStore';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { addingOrEditing, descuentoIncremento } from "aseguisShared";

interface infoToClientIncrSupl {
    textToShow: string;
    isEdditing: number,
    DescIncr: descuentoIncremento[] | null,
    saveDescIncr?: (values: descuentoIncremento) => void,
    deleteDescIncr?: (values: descuentoIncremento) => void,
    width: 6 | 12
}

function ClientIncrSupl({ textToShow, isEdditing, DescIncr, saveDescIncr, deleteDescIncr, width }: infoToClientIncrSupl): ReactElement {

    const [descIncr, setDescIncr] = useState<descuentoIncremento>({ concepto: "", porcentaje: 0, value: 0 });
    const [errorConcepto, setErrorConcepto] = useState("");

    const validarConcepto = (value: string) => {
        let exists = false
        if (DescIncr) {
            for (var val of DescIncr) {
                if (val.concepto === value) {
                    exists = true
                }
            }
        }
        return exists
    }

    const AddDescuentosIncrementos = () => {
        return (
            <Fragment>
                {isEdditing === addingOrEditing.edditing &&
                    <div className="form-row">
                        <div className={width === 6 ? "form-group col-md-3" : "form-group col-md-6"}>
                            <label> Concepto: </label>
                            <input
                                type="text"
                                value={descIncr.concepto}
                                onChange={(event) => {
                                    setDescIncr({ concepto: event.target.value, porcentaje: descIncr.porcentaje, value: descIncr.value })
                                }}
                                className="form-control undefined"
                            />
                        </div>

                        <div className={width === 6 ? "form-group col-md-1" : "form-group col-md-2"}>
                            <label> Valor: </label>
                            <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                suffix={" %"}
                                value={descIncr.porcentaje}
                                className="form-control undefined"
                                isAllowed={(value: NumberFormatValues) => {
                                    const { floatValue } = value;
                                    const numberValue = floatValue ? floatValue : 0
                                    if (numberValue) {
                                        return numberValue < 100;
                                    } else return true
                                }}
                                onValueChange={(value: NumberFormatValues) => {
                                    const { floatValue } = value;
                                    const numberValue = floatValue ? floatValue : 0
                                    setDescIncr({ concepto: descIncr.concepto, porcentaje: numberValue, value: descIncr.value })
                                }}
                            />
                        </div>

                        <div className={width === 6 ? "form-group col-md-2" : "form-group col-md-4"}>
                            <div style={{ position: "absolute", bottom: "0" }}>
                                <button
                                    className="btn btn-info left-margin"
                                    onClick={() => {
                                        if (validarConcepto) {
                                            if (descIncr.porcentaje > 0 && descIncr.concepto.length > 0) {
                                                if (validarConcepto(descIncr.concepto) === false) {
                                                    if (saveDescIncr) {
                                                        saveDescIncr(descIncr)
                                                    }
                                                    setDescIncr({ concepto: "", porcentaje: 0, value: 0 })
                                                    setErrorConcepto("")
                                                } else {
                                                    setErrorConcepto("Concepto duplicado")
                                                    const timeout1 = setTimeout(() => {
                                                        setErrorConcepto("")
                                                    }, 3000);
                                                    return () => { clearTimeout(timeout1) }
                                                }
                                            } else {
                                                setErrorConcepto("Es obligatorio informar concepto y porcentaje")
                                                const timeout2 = setTimeout(() => {
                                                    setErrorConcepto("")
                                                }, 3000);
                                                return () => { clearTimeout(timeout2) }
                                            }
                                        }
                                    }}>
                                    <i className="fas fa fa-plus"></i>
                                </button>
                            </div>
                        </div>

                        {(errorConcepto.length > 0) &&
                            <div className={width === 6 ? "form-group col-md-6" : "form-group col-md-12"}>
                                <div className="text-danger">
                                    <small>{errorConcepto}</small>
                                </div>
                            </div>
                        }

                    </div>
                }
            </Fragment>
        );
    }

    const listarDescuentosIncrementos = (descuentoOincremento: descuentoIncremento[]) => {

        var moreThanZero = false
        for (var descIncr of descuentoOincremento) {
            if (descIncr.value > 0) {
                moreThanZero = true
            }
        }

        return (
            <table className="table-bordered">
                <thead>
                    <tr>
                        <th style={{ whiteSpace: 'nowrap' }} scope="col" className="w-50 p-3">Concepto</th>
                        <th style={{ whiteSpace: 'nowrap' }} scope="col" className="w-25 p-3">Porcentaje</th>
                        {moreThanZero &&
                            <th style={{ whiteSpace: 'nowrap' }} scope="col" className="w-25 p-3">Importe</th>
                        }
                        {(isEdditing === addingOrEditing.edditing) &&
                            <th style={{ whiteSpace: 'nowrap' }} scope="col" className="w-25 p-3">Eliminar</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {descuentoOincremento.map((descOrInc, index: number) => {
                        return (
                            <tr key={index}>
                                <td style={{ whiteSpace: 'nowrap' }} className="align-middle p-3">
                                    {descOrInc.concepto}
                                </td>
                                <td style={{ whiteSpace: 'nowrap' }} className="align-middle p-3">
                                    <NumberFormat
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        displayType="text"
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        allowNegative={false}
                                        value={descOrInc.porcentaje}
                                        className="form-control undefined"
                                        renderText={value => <div>{value}</div>}
                                        suffix={" %"}
                                    />
                                </td>
                                {moreThanZero &&
                                    <td style={{ whiteSpace: 'nowrap' }} className="align-middle p-3">
                                        <NumberFormat
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            displayType="text"
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            value={descOrInc.value}
                                            className="form-control undefined"
                                            renderText={value => <div>{value}</div>}
                                            suffix={" €"}
                                        />
                                    </td>
                                }
                                {(isEdditing === addingOrEditing.edditing) &&
                                    <td style={{ whiteSpace: 'nowrap' }}>
                                        <button
                                            className="btn btn-success btn-red"
                                            onClick={() => {
                                                if (deleteDescIncr) {
                                                    deleteDescIncr(descOrInc)
                                                }
                                            }}>
                                            <i className="fas fa fa-times"></i>
                                        </button>
                                    </td>
                                }
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    }

    return (

        <Fragment>

            <div className="form-group col-md-12 mt-3">
                <div>
                    <label style={{ fontWeight: 'bold' }} htmlFor="input_descuento">{textToShow}</label>

                    {saveDescIncr && AddDescuentosIncrementos()}

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            {DescIncr && DescIncr.length > 0 ?
                                listarDescuentosIncrementos(DescIncr)
                                :
                                <div>-</div>
                            }
                        </div>
                    </div>

                </div>
            </div>

        </Fragment>

    );
};

export default ClientIncrSupl;