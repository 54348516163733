import React, { Fragment, ReactElement, useState, } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../ApplicationState";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import ClientsAutocomplete from "../Clients/ClientsAutocomplete";
import axios from "axios";
import {
    inicialiceClient, searchedClients, paqueteria, searchedPaqueterias, searchFilterList,
    validateFormEmpty, validateNumberFormEmpty, inicialiceSearchFilterList,
    formatedDirWithName, company, getAlbaranesAgrupados
} from "aseguisShared";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, TableHead, makeStyles, createStyles } from '@material-ui/core';
import { Notify } from "../../common/Toastify/Notify";

interface infoToPaqueteriaAgrupaciónModal {
    modalOpened: boolean
    setModalOpened: (opened: boolean) => void
    callbackOnAgrupar: () => void
}

function PaqueteriaAgrupacionModal(props: infoToPaqueteriaAgrupaciónModal): ReactElement {

    const company: company = useSelector((state: ApplicationState) => state.company.company);

    const [loadingCalculatedAgrupacion, setLoadingCalculateAgrupacion] = useState(false)
    const [byIDsOrCartaPorte, setByIDsOrCartaPorte] = useState("")
    const [count, setCount] = useState(0)
    const [paqsOriginales, setPaqsOriginales] = useState<paqueteria[]>([])
    const [paqsSoloModificadas, setPaqsSoloModificadas] = useState<paqueteria[]>([])
    /* POR IDS Y CLIENTE */
    const [client, setClient] = useState(inicialiceClient)
    const [clientError, setClientError] = useState(false)
    const [untilID, setUntilID] = useState(0)
    const [fromID, setFromID] = useState(0)
    /* POR CARTA DE PORTE */
    const [cartaPorte, setCartaPorte] = useState("")

    const closeModal = () => {
        props.setModalOpened(false)
        setByIDsOrCartaPorte("")
        restartModal()
        setLoadingCalculateAgrupacion(false)
    }

    const restartModal = () => {
        setClient(inicialiceClient)
        setClientError(false)
        setUntilID(0)
        setFromID(0)
        setCartaPorte("")
        setCount(0)
        setPaqsOriginales([])
        setLoadingCalculateAgrupacion(false)
    }

    const savePaqsToBd = async () => {
        setLoadingCalculateAgrupacion(true)
        for (const paqueteria of paqsSoloModificadas) {
            await axios.put('api/paqueteria/', { ...paqueteria, idPaqRender: null })
        }
        setLoadingCalculateAgrupacion(false)
        Notify("Albaranes agrupados", "success")
        props.callbackOnAgrupar()
        closeModal()
    }

    const agruparEntregas = async () => {
        setLoadingCalculateAgrupacion(true)
        /***** POR CARTA DE PORTE *****/
        if (cartaPorte.length > 0 && byIDsOrCartaPorte === "byCARTA") {
            const filter: searchFilterList = {
                ...inicialiceSearchFilterList,
                cartaPorteExactly: cartaPorte,
                pageSize: 200
            }
            const albaranesAgrupados = await axios.post<searchedPaqueterias>('api/paqueteria/searchFilter/', filter)
            await tratarAlbaranes(albaranesAgrupados.data.paqueteriasResults)
        }
        /***** POR IDS Y CLIENTE*****/
        if (client._id && client._id.length > 0 && byIDsOrCartaPorte === "byID") {
            var allIds: number[] = []
            for (let i = fromID; i <= untilID; i++) {
                allIds.push(i)
            }
            const filter: searchFilterList = {
                ...inicialiceSearchFilterList,
                ids: allIds,
                clientsIDsMongo: [client._id],
                pageSize: 200
            }
            const albaranesAgrupados = await axios.post<searchedPaqueterias>('api/paqueteria/searchFilter/', filter)
            await tratarAlbaranes(albaranesAgrupados.data.paqueteriasResults)
        }
        setLoadingCalculateAgrupacion(false)
    }

    const tratarAlbaranes = async (albaranes: paqueteria[]) => {
        if (albaranes.length > 1) {
            if (validarUnicoCliente(albaranes) && albaranes[0].datosGenerales.client._id!.length > 0) {
                const filterClient: searchFilterList = {
                    ...inicialiceSearchFilterList,
                    idMongo: albaranes[0].datosGenerales.client._id!
                }
                const clientAlbaran = await axios.post<searchedClients>('api/clients/searchFilter/', filterClient)
                const albaranesOriginales = albaranes.map(albaran => {
                    albaran.datosGenerales.client = clientAlbaran.data.clientsResults[0]
                    return albaran;
                });
                setPaqsOriginales(albaranesOriginales)
                const albaranesAgrupados = getAlbaranesAgrupados(albaranesOriginales, company)
                setPaqsSoloModificadas(albaranesAgrupados)
                if (albaranesAgrupados.length > 0) {
                    setCount(count + 1)
                }
                if (albaranesAgrupados.length === 0) {
                    Notify("No se han encontrado albaranes que se puedan agrupar", "warn")
                }
            }
            else {
                Notify("Todos los albaranes deben permanecer al mismo cliente", "warn")
            }
        } else {
            Notify("No se han encontrado albaranes que se puedan agrupar", "warn")
        }
    }

    const validarUnicoCliente = (albaranes: paqueteria[]): boolean => {
        if (albaranes.length === 0) {
            return false;
        }
        const primerCliente = albaranes[0].datosGenerales.client._id;
        return albaranes.every(albaran => albaran.datosGenerales.client._id === primerCliente);
    };

    interface TablaPaqueteriasProps {
        paqueteriasOriginales: paqueteria[];
        paqueteriasActualizadas: paqueteria[];
    }

    const useStyles = makeStyles(() =>
        createStyles({
            rowDiferente: {
                backgroundColor: '#D5F5E3',
            },
        }),
    );
    const TablaPaqueterias: React.FC<TablaPaqueteriasProps> = ({ paqueteriasOriginales, paqueteriasActualizadas }) => {

        const classes = useStyles();

        // Ordena los arrays por dirección
        paqueteriasOriginales.sort((a, b) => a.datosTransporte.addressDos.calle.localeCompare(b.datosTransporte.addressDos.calle));
        paqueteriasActualizadas.sort((a, b) => a.datosTransporte.addressDos.calle.localeCompare(b.datosTransporte.addressDos.calle));

        let totalPriceDifference = 0
        paqueteriasOriginales.map((paqueteOriginal, index) => {
            const paqueteActualizado = paqueteriasActualizadas.find(paq => paq._id === paqueteOriginal._id);
            if (paqueteActualizado) {
                let diferenciaPrecio = paqueteOriginal.datosEconomicos.subTotal - paqueteActualizado.datosEconomicos.subTotal;
                diferenciaPrecio = diferenciaPrecio * -1
                totalPriceDifference = totalPriceDifference + diferenciaPrecio
            }
        })

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Destino</TableCell>
                            <TableCell>Referencia/s </TableCell>
                            <TableCell>Precio Original</TableCell>
                            <TableCell>Precio Actualizado</TableCell>
                            <TableCell>Diferencia</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paqueteriasOriginales.map((paqueteOriginal, index) => {

                            const paqueteActualizado = paqueteriasActualizadas.find(paq => paq._id === paqueteOriginal._id);

                            if (paqueteActualizado) {

                                let diferenciaPrecio = paqueteOriginal.datosEconomicos.subTotal - paqueteActualizado.datosEconomicos.subTotal;
                                diferenciaPrecio = diferenciaPrecio * -1

                                return (
                                    <TableRow key={paqueteOriginal._id} className={classes.rowDiferente}>
                                        <TableCell>
                                            {formatedDirWithName(paqueteActualizado.datosTransporte.addressDos, paqueteActualizado.datosTransporte.destNameDos)}
                                        </TableCell>
                                        <TableCell>{paqueteOriginal.datosTransporte.referenciaString}</TableCell>
                                        <TableCell>
                                            <NumberFormat
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                displayType="text"
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                allowNegative={true}
                                                value={paqueteOriginal.datosEconomicos.subTotal}
                                                className="form-control undefined"
                                                renderText={value => <div>{value}</div>}
                                                suffix={" €"}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                displayType="text"
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                allowNegative={true}
                                                value={paqueteActualizado.datosEconomicos.subTotal}
                                                className="form-control undefined"
                                                renderText={value => <div>{value}</div>}
                                                suffix={" €"}
                                            />
                                        </TableCell>
                                        <TableCell style={diferenciaPrecio > 0 ? { color: '#187100' } : { color: '#FF5555' }}>
                                            <NumberFormat
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                displayType="text"
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                allowNegative={true}
                                                value={diferenciaPrecio}
                                                className="form-control undefined"
                                                renderText={value => <div>{diferenciaPrecio > 0 ? `+${value}` : value}</div>} // Agrega el símbolo + si es positivo
                                                suffix={" €"}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                            return null;
                        })}

                        <TableRow className={classes.rowDiferente}>
                            <TableCell>
                                { }
                            </TableCell>
                            <TableCell>
                                { }
                            </TableCell>
                            <TableCell>
                                { }
                            </TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>
                                {"Total:"}
                            </TableCell>
                            <TableCell style={totalPriceDifference > 0 ? { color: '#187100', fontWeight: 'bold' } : { color: '#FF5555', fontWeight: 'bold' }}>
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    displayType="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    allowNegative={true}
                                    value={totalPriceDifference}
                                    className="form-control undefined"
                                    renderText={value => <div>{totalPriceDifference > 0 ? `+${value}` : value}</div>} // Agrega el símbolo + si es positivo
                                    suffix={" €"}
                                />
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    return (
        <Fragment>

            <Modal show={props.modalOpened} onHide={closeModal} dialogClassName="modal-lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Agrupar albaranes por destino
                        {loadingCalculatedAgrupacion === true &&
                            <div className="spinner-border spinner-border-sm ml-4" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="mt-3 mb-3">
                        <div style={{ fontSize: "12px", textAlign: "center", justifyContent: "center" }}>
                            Se agruparán todos los albaranes entre la selección que tengan definido el mismo destino. Se aplicará, si aplica, la reexpedicion de la tarifa<br />
                            Se determinará el importe medio a través de la suma total de kg, m³ y bultos, dividido por el número de albaranes.<br />
                            Este importe se asignará a cada albarán involucrado. <br />
                            Los albaranes agrupados perderán descuentos, reexpediciones e incidencias previas con importe. 
                        </div>
                    </div>

                    <div className="d-flex justify-content-center">
                        <div className="m-3">
                            <input
                                type="radio"
                                id="byID"
                                value="byID"
                                checked={byIDsOrCartaPorte === 'byID'}
                                onChange={(e) => {
                                    restartModal()
                                    setByIDsOrCartaPorte(e.target.value);
                                }}
                                className="mr-2"

                            />
                            <label htmlFor="byID">Por ID</label>
                        </div>

                        <div className="m-3">
                            <input
                                type="radio"
                                id="byCARTA"
                                value="byCARTA"
                                checked={byIDsOrCartaPorte === 'byCARTA'}
                                onChange={(e) => {
                                    restartModal()
                                    setByIDsOrCartaPorte(e.target.value);
                                }}
                                className="mr-2"

                            />
                            <label htmlFor="byCARTA">Por carta de porte</label>
                        </div>
                    </div>


                    {byIDsOrCartaPorte === "byCARTA" &&
                        <div>
                            {/**** POR CARTA DE PORTE ***/}
                            <div className="row align-items-center">
                                <span className="ml-4 mr-2">Carta de porte: </span>
                                <input
                                    className={validateFormEmpty(cartaPorte, 1) + " col-8"}
                                    type="text"
                                    autoComplete={"off"}
                                    value={cartaPorte}
                                    onChange={(event) => {
                                        restartModal()
                                        var value = event.target.value
                                        setCartaPorte(value)
                                    }}
                                />

                            </div>
                        </div>
                    }

                    {byIDsOrCartaPorte === "byID" &&
                        <div>
                            {/**** POR ID Y CLIENTE ***/}
                            <div className="row align-items-center">
                                <span className="ml-4 mr-2">Desde ID: </span>
                                <NumberFormat
                                    value={fromID}
                                    className={untilID < fromID ? "form-control is-invalid col-md-3" : validateNumberFormEmpty(fromID) + " col-md-3"}
                                    onValueChange={(number: NumberFormatValues) => {
                                        setCount(0)
                                        setFromID(number.floatValue ? number.floatValue : 0)
                                    }}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                />

                                <span className="ml-2 mr-2">Hasta ID: </span>
                                <NumberFormat
                                    value={untilID}
                                    className={validateNumberFormEmpty(untilID) + " col-md-3"}
                                    onValueChange={(number: NumberFormatValues) => {
                                        setCount(0)
                                        setUntilID(number.floatValue ? number.floatValue : 0)
                                    }}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                />

                            </div>
                            <div className="row align-items-center mt-4">
                                <span className="ml-4 mr-2">Cliente: </span>
                                <div className="col-9">
                                    <ClientsAutocomplete
                                        clientName={client.nombre}
                                        setClient={(client) => {
                                            setCount(0)
                                            setClient(client)
                                        }}
                                        setErrorForm={setClientError}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    {paqsOriginales.length > 0 && paqsSoloModificadas.length > 0 &&
                        <div className="mt-4 mb-2">
                            < TablaPaqueterias
                                paqueteriasOriginales={paqsOriginales}
                                paqueteriasActualizadas={paqsSoloModificadas}
                            />
                        </div>
                    }

                </Modal.Body>
                <Modal.Footer>

                    {/**** POR ID Y CLIENTE *****/}
                    {byIDsOrCartaPorte === "byID" &&
                        <Button
                            disabled={loadingCalculatedAgrupacion || untilID === 0 || fromID === 0 || clientError || untilID < fromID}
                            variant={count === 0 ? "primary" : "success"}
                            onClick={() => {

                                if (loadingCalculatedAgrupacion === false) {
                                    if (untilID > 0 && fromID > 0 && clientError === false && untilID >= fromID && count === 0) {
                                        agruparEntregas()
                                    }
                                    if (count === 1) {
                                        savePaqsToBd()
                                    }
                                }
                            }}
                        >
                            {count === 0 &&
                                <span>Previsualizar</span>
                            }
                            {count === 1 &&
                                <span>Actualizar</span>
                            }
                        </Button>
                    }

                    {/**** POR CARTA DE PORTE *****/}
                    {byIDsOrCartaPorte === "byCARTA" &&
                        <Button
                            disabled={loadingCalculatedAgrupacion || cartaPorte.length === 0}
                            variant={count === 0 ? "primary" : "success"}
                            onClick={() => {
                                if (loadingCalculatedAgrupacion === false) {
                                    if (cartaPorte.length > 0 && count === 0) {
                                        agruparEntregas()
                                    }
                                    if (count === 1) {
                                        savePaqsToBd()
                                    }
                                }
                            }}
                        >
                            {count === 0 &&
                                <span>Previsualizar</span>
                            }
                            {count === 1 &&
                                <span>Actualizar</span>
                            }
                        </Button>
                    }

                    <Button
                        variant="secondary"
                        onClick={() => {
                            closeModal()
                        }}
                    >
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment >
    );
};

export default PaqueteriaAgrupacionModal;