"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inicialiceDestinationsLinea = exports.inicialiceCargaCompleta = exports.inicializeDatosEconomicosCarga = exports.inicializeDatosGeneralesCarga = exports.inicializeLineaViajeCarga = exports.inicialiceCargaCompletaValidateFieldsResult = void 0;
const commonEnums_1 = require("../enums/commonEnums");
const commonFunctions_1 = require("./commonFunctions");
const employeesFunctions_1 = require("./employeesFunctions");
const loginFunctions_1 = require("./loginFunctions");
const postalCodeFunctions_1 = require("./postalCodeFunctions");
const suppliersFunctions_1 = require("./suppliersFunctions");
const tariffFunctions_1 = require("./tariffFunctions");
const vehiclesFunctions_1 = require("./vehiclesFunctions");
const infoToCMRFunctions_1 = require("./infoToCMRFunctions");
exports.inicialiceCargaCompletaValidateFieldsResult = {
    referenciaExists: false,
    anyFieldExist: false
};
exports.inicializeLineaViajeCarga = {
    id: 0,
    destinations: [],
    destinationsDistances: [],
    totalDistance: 0,
    importe: 0,
    descuento: 0,
    total: 0,
    iva: 0,
    importeOriginal: 0,
    concepto: "",
    lineType: "",
    horas: 0,
    eurosHora: 0,
    desdeHoras: "",
    hastaHoras: "",
    cobrarRetorno: commonEnums_1.cobrarRetornoTypes.no,
    tariffCarga: tariffFunctions_1.inicializeCargaCompletaTariff,
    centroDistanceValue: 0,
    centroDistanceText: "",
    centroIncrementValue: 0,
};
exports.inicializeDatosGeneralesCarga = {
    //client: inicialiceClient,  --> no se porque, si utilizo inicialiceClient, lo guarda como null en BBDD.
    client: {
        _id: null,
        id: 0,
        nif: "",
        nombre: "",
        razonSocial: "",
        address: {
            calle: "",
            numero: "",
            puerta: "",
            localidad: "",
            codigoPostal: "",
            provincia: "",
            pais: "",
            vicinity: "",
            formatedAddress: "",
            otherName: "",
            placeId: "",
            url: "",
            cpMongo: postalCodeFunctions_1.inicializePostalCode
        },
        tel1: "",
        tel2: "",
        email: "",
        emailFacturacion: "",
        porIva: 0,
        fecAlta: new Date(),
        tariff: tariffFunctions_1.inicialiceTariff,
        m3ToKg: 0,
        facturarCargaPaqJunto: false,
        permisosUsuario: commonFunctions_1.inicialicePermisosUsuario,
        plazoEntregaPaq: commonEnums_1.plazosEntrega.null,
        datosEconomicos: {
            ccc: "",
            formaPago: "",
            mesesDePagoExcluidos: [],
            vencimiento: "",
            diaDePago: "",
            descuentosCarga: [],
            descuentosPaqueteria: [],
            incrementosCarga: [],
            incrementosPaqueteria: []
        },
    },
    vehicle: vehiclesFunctions_1.initialVehicle,
    remolque: vehiclesFunctions_1.initialVehicle,
    typeVehicle: vehiclesFunctions_1.initialVehicleType,
    supplier: suppliersFunctions_1.inicializeSupplier,
    driver: employeesFunctions_1.inicialiceEmployee,
    recogida: new Date(),
    entrega: (() => {
        const entregaDate = new Date();
        entregaDate.setHours(23);
        entregaDate.setMinutes(59);
        return entregaDate;
    })(),
    fecAlta: new Date(),
    referenciaString: "",
    referencias: [],
    bultos: 0,
    bultosString: "",
    kilos: 0,
    kilosString: "",
    m3: 0,
    m3String: "",
    observaciones: "",
    user: loginFunctions_1.inicialiceLoginUser
};
exports.inicializeDatosEconomicosCarga = {
    descuento: 0,
    subTotal: 0,
    //baseImp: 0,
    //porIva: 0,
    //iva: 0,
    //total: 0,
};
exports.inicialiceCargaCompleta = {
    _id: null,
    id: 0,
    idString: "",
    datosGenerales: exports.inicializeDatosGeneralesCarga,
    datosEconomicos: exports.inicializeDatosEconomicosCarga,
    lineasViaje: [],
    datosEntrega: {
        fileRelation: [],
        deliveredTime: new Date(),
        whoDeliver: loginFunctions_1.inicialiceLoginUser,
        geoPosition: commonFunctions_1.initGeoPosition,
        qrIdReaded: ""
    },
    incidenciasEntregas: [],
    idBill: 0,
    idCargaRender: null,
    idAbono: ""
};
exports.inicialiceDestinationsLinea = {
    id: 0,
    tel: "",
    cargaDescarga: "",
    address: {
        calle: "",
        numero: "",
        puerta: "",
        localidad: "",
        codigoPostal: "",
        provincia: "",
        pais: "",
        vicinity: "",
        formatedAddress: "",
        otherName: "",
        placeId: "",
        url: "",
        cpMongo: postalCodeFunctions_1.inicializePostalCode
    },
    destName: "",
    cmrInfo: infoToCMRFunctions_1.initInfoToCreateCMR
};
