import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, ReactElement } from "react";
import { ApplicationState } from '../../ApplicationState';
import { exportVehiclesControlsToExcel, requestAllVehiclesControls } from './VehiclesControlsStore';
import { filterControlsVehiclesTableTH } from "../../common/commonInterfaces/thFilters";
import { searchFilterList, getFormattedDateAndHour, controlVehicle, formatearMatricula } from "aseguisShared";
import { Pagination } from "../../common/commonInterfaces/commonFunctions";
import VehiclesControlsListFilter from "./VehiclesControlsListFilter";
import { requestParametrizacion } from "../../components/Parametrizacion/ParametrizacionStore";
import { ButtonEditarPrivate } from "../../common/Buttons/ButtonEditarPrivate";
import NumberFormat from "react-number-format";
import AddButtonList from "../../common/Buttons/AddButtonList";

interface infoToVehicleControlList {
    onClickVehicleControl: (controlVehicle: controlVehicle) => void
    addVehicleControl?: () => void
    searchFilterVehicleControl: searchFilterList
    saveSearchFilterVehicleControl: (filter: searchFilterList) => void
}

function VehiclesControlList(props: infoToVehicleControlList): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();

    const allControlVehicles: controlVehicle[] = useSelector((state: ApplicationState) => state.vehiclesControls.allVehiclesControls);
    const countAllControlVehicles: number = useSelector((state: ApplicationState) => state.vehiclesControls.totalControlVehiclesList);
    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const numPage: number = useSelector((state: ApplicationState) => state.vehiclesControls.actualPage);
    const totalPages: number = useSelector((state: ApplicationState) => state.vehiclesControls.totalPages);
    const vehicleControlForm: controlVehicle = useSelector((state: ApplicationState) => state.vehiclesControls.controlvehicleEditForm);

    const isModifyEnabled = ButtonEditarPrivate().isModifyEnabled();

    useEffect(() => {
        if (props.searchFilterVehicleControl.withTimeout) {
            const timeout = setTimeout(() => {
                dispatch(requestAllVehiclesControls(props.searchFilterVehicleControl));
            }, 600)
            return () => clearTimeout(timeout)
        } else {
            dispatch(requestAllVehiclesControls(props.searchFilterVehicleControl));
        }
    }, [props.searchFilterVehicleControl, vehicleControlForm]);

    useEffect(() => {
        dispatch(requestParametrizacion());
    }, []);

    const vehiclesControlList: JSX.Element[] = allControlVehicles.map((vehicleControl, index) => {
        return (
            <tr className={`table-row`}
                onClick={() => {
                    props.onClickVehicleControl(vehicleControl)
                }}
                key={index}>
                <td scope="row"><b>{vehicleControl.id}</b></td>
                <td>{getFormattedDateAndHour(vehicleControl.fecAlta)}</td>
                <td>{formatearMatricula(vehicleControl.vehicle.matricula) + " - " + vehicleControl.vehicle.marca + " " + vehicleControl.vehicle.modelo}</td>
                <td>{(vehicleControl.remolque.matricula.length > 0 ? (formatearMatricula(vehicleControl.remolque.matricula) + " - " + vehicleControl.remolque.marca + " " + vehicleControl.remolque.modelo) : "n/a")}</td>
                <td>
                    <NumberFormat
                        renderText={(value) => value}
                        displayType="text"
                        value={vehicleControl.kms}
                        className="form-control undefined"
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={0}
                        fixedDecimalScale={true}
                        suffix={" km"}
                    />
                </td>
                <td>{vehicleControl.user.name + " " + vehicleControl.user.apellido1 + " " + vehicleControl.user.apellido2}</td>
                <td>{vehicleControl.controlList.length}</td>
            </tr>
        );
    });

    return (

        <Fragment>

            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        {/*anadir button*/}
                        {(props.addVehicleControl && isModifyEnabled) &&
                            <div className="header-buttons">
                                <button
                                    className="btn btn-info btn-sm left-margin"
                                    onClick={() => {
                                        if (allControlVehicles.length > 0) {
                                            dispatch(exportVehiclesControlsToExcel({
                                                ...props.searchFilterVehicleControl,
                                                getAll: true
                                            }))
                                        }
                                    }}>
                                    Exportar a Excel

                                </button>

                                <AddButtonList
                                    addCallback={props.addVehicleControl}
                                />
                                
                            </div>
                        }

                        <div className="card-header">
                            <div className="form-inline">
                                <h6 className="m-0 font-weight-bold text-green">Lista de controles vehículos {"(" + countAllControlVehicles + ")"}</h6>
                                {(isAppLoading === true) &&
                                    <span className="spinner-border spinner-border-sm ml-4"></span>
                                }
                            </div>
                            <br></br>
                            <VehiclesControlsListFilter
                                searchFilterControlVehicles={props.searchFilterVehicleControl}
                                saveSearchFilterControlVehicles={props.saveSearchFilterVehicleControl}
                            />
                        </div>

                        <div className="card-body">
                            <div className="table-responsive table-hover">

                                <table className="table table-sm">
                                    <thead className="thead-light">
                                        <tr>
                                            {filterControlsVehiclesTableTH(props.saveSearchFilterVehicleControl, props.searchFilterVehicleControl, "#")}
                                            {filterControlsVehiclesTableTH(props.saveSearchFilterVehicleControl, props.searchFilterVehicleControl, "Fecha")}
                                            {filterControlsVehiclesTableTH(props.saveSearchFilterVehicleControl, props.searchFilterVehicleControl, "Vehículo")}
                                            {filterControlsVehiclesTableTH(props.saveSearchFilterVehicleControl, props.searchFilterVehicleControl, "Remolque")}
                                            {filterControlsVehiclesTableTH(props.saveSearchFilterVehicleControl, props.searchFilterVehicleControl, "Km")}
                                            {filterControlsVehiclesTableTH(props.saveSearchFilterVehicleControl, props.searchFilterVehicleControl, "Empleado")}
                                            <th scope="col">Núm Controles</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {vehiclesControlList}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* paginacion */}
                        {Pagination(numPage, totalPages, props.searchFilterVehicleControl, props.saveSearchFilterVehicleControl)}

                    </div>

                </div>
            </div>

        </Fragment >

    );
};

export default VehiclesControlList;
