"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateDateLteTomorrow = exports.isManyEmailValidate = exports.isEmailValidate = exports.validateManyEmails = exports.validateFormEmail = exports.validateNumberFormEmpty = exports.validateFormEmpty = exports.toCapitalLetters = exports.validateEmail = exports.validateNumericWithSumAndMultiplicate = exports.validateNumericWithSum = exports.validateNumeric = exports.validateAlphaNumeric = void 0;
{ /* ********************************************************************************************************************************************************************************** */ }
{ /* ********************************************************************************************************************************************************************************** */ }
{ /* VALIDAMOS EL CONTENIDO DEL CAMPO FORMULARIO */ }
{ /* VALIDAMOS EL CONTENIDO DEL CAMPO FORMULARIO */ }
{ /* VALIDAMOS EL CONTENIDO DEL CAMPO FORMULARIO */ }
function validateAlphaNumeric(value, withoutSpaces, withAccents) {
    var alphaNumeric = /([^a-zA-Z0-9\u00D1\u00F1\u00C7\u00E7\ ]+)/; //ACEPTAMOS DE A-Z, a-z, 0-9 y los caracteres �,�,�,� (caracteres unicode)
    if (withoutSpaces && withoutSpaces === true) {
        alphaNumeric = /([^a-zA-Z0-9\u00D1\u00F1\u00C7\u00E7]+)/; //quitamos el espacio como valido
    }
    //if (withAccents && withAccents === true) {
    if (withoutSpaces && withoutSpaces === true) {
        alphaNumeric = /([^a-zA-Z0-9\u00D1\u00F1\u00C7\u00E7\u00e1\u00e0\u00e8\u00ec\u00f2\u00f9\u00e9\u00ed\u00f3\u0027\u0026\u2019\u00fa\u002E\u00c1\u00c9\u005F\u002D\u00cd\u002C\u00d3\u00da]+)/; //quitamos el espacio como valido y aceptamos lo de abajo
    }
    else {
        alphaNumeric = /([^a-zA-Z0-9\u00D1\u00F1\u00C7\u00E7\u00e1\u00e0\u00e8\u00ec\u00f2\u00f9\u00e9\u00ed\u00f3\u0027\u0026\u2019\u00fa\u002E\u00c1\u00c9\u005F\u002D\u00cd\u002C\u00d3\u00da\ ]+)/; //ACEPTAMOS DE A-Z, a-z, 0-9, los caracteres �,�,�,�, acento, punto, coma, guion, guion bajo, apostrofe y ampersand (caracteres unicode)
    }
    //}
    var ReturnAlphaNumeric = value.replace(alphaNumeric, '');
    ReturnAlphaNumeric = ReturnAlphaNumeric.trimStart();
    return ReturnAlphaNumeric;
    /*  /^[a-z0-9]+/i
        ^         Start of string
        [a-z0-9]  a or b or c or ... z or 0 or 1 or ... 9
        +         one or more times (change to * to allow empty string)
        $         end of string
        /i        case-insensitive
    if you need to this regexp supports universal character you can find list of unicode characters here: https://en.wikipedia.org/wiki/List_of_Unicode_characters
    for example: /^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 _.-]+)/
    this will support persian.
    regexp wiki: http://w3.unpocodetodo.info/utiles/regex.php  */
}
exports.validateAlphaNumeric = validateAlphaNumeric;
function validateNumeric(value) {
    var regexNumeric = /([^0-9]+)/; //0-9
    var ReturnNumeric = value.replace(regexNumeric, '');
    ReturnNumeric = ReturnNumeric.trimStart();
    return ReturnNumeric;
}
exports.validateNumeric = validateNumeric;
function validateNumericWithSum(value) {
    var regexNumeric = /([^0-9\u002B\u002C]+)/; //0-9
    var ReturnNumeric = value.replace(regexNumeric, '');
    ReturnNumeric = ReturnNumeric.trimStart();
    return ReturnNumeric;
}
exports.validateNumericWithSum = validateNumericWithSum;
function validateNumericWithSumAndMultiplicate(value) {
    var regexNumeric = /([^0-9\u002B\u002C\u002A\u002E]+)/; //0-9
    var ReturnNumeric = value.replace(regexNumeric, '');
    ReturnNumeric = ReturnNumeric.trimStart();
    return ReturnNumeric;
}
exports.validateNumericWithSumAndMultiplicate = validateNumericWithSumAndMultiplicate;
function validateEmail(value) {
    var regexEmail = /([^a-zA-Z0-9\u00D1\u00F1\u00C7\u00E7\u0040\u002E\u002D\u005F]+)/; /* alfanumerico mas punto, _, -, y @*/
    var email = value.replace(regexEmail, '');
    email = email.toLowerCase().trimStart();
    return email;
}
exports.validateEmail = validateEmail;
/*export function validateText(value: string): string {
    var regexEmail = /([^a-zA-Z0-9\u00D1\u00F1\u00C7\u00E7\u0040\u002E\u002D\u005F\u002C]+)/   //email mas coma
    var email = value.replace(regexEmail, '')
    return email
}*/
function toCapitalLetters(value) {
    return value.toUpperCase();
}
exports.toCapitalLetters = toCapitalLetters;
{ /* ********************************************************************************************************************************************************************************** */ }
{ /* ********************************************************************************************************************************************************************************** */ }
{ /* VALIDAMOS EL TIPO DEL CAMPO FORMULARIO */ }
{ /* VALIDAMOS EL TIPO DEL CAMPO FORMULARIO */ }
{ /* VALIDAMOS EL TIPO DEL CAMPO FORMULARIO */ }
function validateFormEmpty(value, valueLenghtMin) {
    if (value !== undefined && value.length !== undefined && valueLenghtMin !== undefined) {
        if (value.length < valueLenghtMin) {
            return "form-control is-invalid";
        }
        else {
            return "form-control undefined";
        }
    }
    else {
        return "form-control undefined";
    }
}
exports.validateFormEmpty = validateFormEmpty;
function validateNumberFormEmpty(value, valueToBeMin) {
    if (valueToBeMin && valueToBeMin > 0) {
        if (value < valueToBeMin) {
            return "form-control is-invalid";
        }
        else {
            return "form-control undefined";
        }
    }
    else {
        if (value === 0) {
            return "form-control is-invalid";
        }
        else {
            return "form-control undefined";
        }
    }
}
exports.validateNumberFormEmpty = validateNumberFormEmpty;
function validateFormEmail(value) {
    if (isEmailValidate(value) === false) {
        return "form-control is-invalid";
    }
    else {
        return "form-control undefined";
    }
}
exports.validateFormEmail = validateFormEmail;
function validateManyEmails(emailsInput) {
    const emails = emailsInput.split(" ");
    for (var email of emails) {
        if (isEmailValidate(email) === false) {
            return "form-control is-invalid";
        }
    }
    return "form-control undefined";
}
exports.validateManyEmails = validateManyEmails;
function isEmailValidate(search) {
    var serchfind;
    let regexp2 = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/);
    serchfind = regexp2.test(search);
    return serchfind;
}
exports.isEmailValidate = isEmailValidate;
function isManyEmailValidate(search) {
    const emails = search.split(/\s+/);
    let regexp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/);
    for (let email of emails) {
        if (!regexp.test(email)) {
            return false;
        }
    }
    return true;
}
exports.isManyEmailValidate = isManyEmailValidate;
function validateDateLteTomorrow(date) {
    var today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth();
    var day = today.getDate();
    var tomorrow = new Date(year, month, day + 1);
    if (date <= tomorrow) {
        return "form-control is-invalid";
    }
    else {
        return "form-control";
    }
}
exports.validateDateLteTomorrow = validateDateLteTomorrow;
