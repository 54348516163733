import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, ReactElement, useState } from "react";
import { ApplicationState } from '../../ApplicationState';
import { exportEmployeesToExcel, requestAllEmployees, } from './EmployeesStore';
import { searchFilterList, employee, suscriptionData } from 'aseguisShared';
import EmployeesListFilter from "./EmployeesListFilter";
import { filterEmployeeTableTH } from "../../common/commonInterfaces/thFilters";
import { GetSuscriptionDataFromLocalStorage, Pagination } from "../../common/commonInterfaces/commonFunctions";
import { ButtonEditarPrivate } from "../../common/Buttons/ButtonEditarPrivate";
import AddButtonList from "../../common/Buttons/AddButtonList";

interface infoToEmployeesList {
    onClickEmployee: (employee: employee) => void
    addEmployee?: () => void
    searchEmployeesFilter: searchFilterList
    saveSearchEmployeesFilter: (filter: searchFilterList) => void
}

function EmployeesList(props: infoToEmployeesList): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();

    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()
    const isModifyEnabled = ButtonEditarPrivate().isModifyEnabled();

    const allEmployees: employee[] = useSelector((state: ApplicationState) => state.employees.employees);
    const countAllEmployees: number = useSelector((state: ApplicationState) => state.employees.totalEmployeesList);
    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const numPage: number = useSelector((state: ApplicationState) => state.employees.actualPage);
    const totalPages: number = useSelector((state: ApplicationState) => state.employees.totalPages);
    const employeEditForm: employee = useSelector((state: ApplicationState) => state.employees.employeeEditForm);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    useEffect(() => {
        if (props.searchEmployeesFilter.withTimeout) {
            const timeout = setTimeout(() => {
                dispatch(requestAllEmployees(props.searchEmployeesFilter));
            }, 600)
            return () => clearTimeout(timeout)
        } else {
            dispatch(requestAllEmployees(props.searchEmployeesFilter));
        }
    }, [props.searchEmployeesFilter, employeEditForm]);

    const employeesList: JSX.Element[] = allEmployees.map((employee, index) => {
        return (
            <tr className={`table-row`}
                onClick={() => {
                    props.onClickEmployee(employee)
                }}
                key={index}>
                <td scope="row"><b>{employee.id}</b></td>
                <td>{employee.name + " " + employee.apellido1 + " " + employee.apellido2}</td>
                <td>{employee.email}</td>
                <td>{employee.tel1}</td>
                <td>{employee.nif}</td>
                <td>{employee.address.localidad + " " + employee.address.provincia}</td>
                <td>{employee.isChofer === true ? "Si" : "No"}</td>
            </tr>
        );
    });

    return (

        <Fragment>

            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        {/*anadir button*/}
                        {(props.addEmployee && isModifyEnabled) &&
                            <div className="header-buttons">
                                <button
                                    className="btn btn-info btn-sm left-margin"
                                    onClick={() => {
                                        if (allEmployees.length > 0) {
                                            dispatch(exportEmployeesToExcel({
                                                ...props.searchEmployeesFilter,
                                                getAll: true
                                            }))
                                        }
                                    }}>
                                    Exportar a Excel
                                </button>

                                {suscriptionData.empleados &&
                                    <AddButtonList
                                        addCallback={props.addEmployee}
                                    />
                                }

                            </div>
                        }

                        <div className="card-header">

                            <div className="form-inline">
                                <h6 className="m-0 font-weight-bold text-green">Lista de
                                    empleados {"(" + countAllEmployees + ")"}</h6>
                                {(isAppLoading === true) &&
                                    <span className="spinner-border spinner-border-sm ml-4"></span>
                                }
                            </div>
                            <br></br>

                            <EmployeesListFilter
                                searchFilterEmployees={props.searchEmployeesFilter}
                                saveSearchFilterEmployees={props.saveSearchEmployeesFilter}
                            />

                        </div>

                        <div className="card-body">
                            <div className="table-responsive table-hover">

                                <table className="table table-sm">
                                    <thead className="thead-light">
                                        <tr>
                                            {filterEmployeeTableTH(props.saveSearchEmployeesFilter, props.searchEmployeesFilter, "#")}
                                            {filterEmployeeTableTH(props.saveSearchEmployeesFilter, props.searchEmployeesFilter, "Nombre")}
                                            {filterEmployeeTableTH(props.saveSearchEmployeesFilter, props.searchEmployeesFilter, "Email")}
                                            {filterEmployeeTableTH(props.saveSearchEmployeesFilter, props.searchEmployeesFilter, "Tel")}
                                            {filterEmployeeTableTH(props.saveSearchEmployeesFilter, props.searchEmployeesFilter, "Nif")}
                                            {filterEmployeeTableTH(props.saveSearchEmployeesFilter, props.searchEmployeesFilter, "Localidad")}
                                            {filterEmployeeTableTH(props.saveSearchEmployeesFilter, props.searchEmployeesFilter, "Veh. pesado")}
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {employeesList}

                                    </tbody>

                                </table>
                            </div>
                        </div>

                        {/* paginacion */}
                        {Pagination(numPage, totalPages, props.searchEmployeesFilter, props.saveSearchEmployeesFilter)}

                    </div>
                </div>
            </div>
        </Fragment>

    );
};

export default EmployeesList;