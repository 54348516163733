import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from '../../ApplicationState';
import { changePasswordRequest, loginUsers } from "aseguisShared";
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import { Button, Modal } from "react-bootstrap";
import { PasswordRequirements, showPasswordRequirements } from "../Register/Register";
import axios from "axios";
import { Notify } from "../../common/Toastify/Notify";
import { useHistory } from "react-router-dom";
import { logout } from "../Login/LoginStore";

const MisDatosForm = () => {

    const history = useHistory();
    const dispatch: React.Dispatch<any> = useDispatch();

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);

    const [showModal, setShowModal] = useState(false)
    const closeModal = () => {
        setShowModal(false)
    }

    const [oldPassword, setOldPassword] = useState("")
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")

    const [passwordRequirements, setPasswordRequirements] = useState<PasswordRequirements>({
        minLength: false,
        hasUppercase: false,
        hasLowercase: false,
        hasNumber: false,
        passwordsMatch: false,
    });

    const passwordIsOK =
        passwordRequirements.minLength &&
        passwordRequirements.hasUppercase &&
        passwordRequirements.hasLowercase &&
        passwordRequirements.hasNumber &&
        passwordRequirements.passwordsMatch;

    const goToChangePassword = () => {
        const changeRequest: changePasswordRequest = {
            oldPassword: oldPassword,
            token: userLogged.email,
            password1: password1,
            password2: password2,
        }
        axios.post('/api/changePasswordByUser', changeRequest)
            .then((data) => {
                if (data.status === 200) {
                    Notify("La contraseña ha sido actualizada", "success")
                    setShowModal(false)
                    dispatch(logout())
                    history.push(allAppRoutes.login)
                } else {
                    Notify("No ha sido posible actualizar la contraseña. Asegurese de que la contraseña anterior es correcta", "error")
                }
            })
            .catch((error) => {
                Notify("No ha sido posible actualizar la contraseña", "error")
            })
    }

    return (
        <Fragment>
            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green"> Mis datos </h6>
                </div>
                <div className="card-body">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <div >
                                <label style={{ fontWeight: 'bold' }} htmlFor="name">Nombre</label>
                                <input
                                    className="form-control undefined"
                                    name="Nombre"
                                    disabled={true}
                                    type="text"
                                    value={userLogged.name + " " + userLogged.apellido1 + " " + userLogged.apellido2}
                                />
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="email">Email</label>
                                <input
                                    className="form-control undefined"
                                    name="email"
                                    disabled={true}
                                    type="text"
                                    value={userLogged.email}
                                />
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <div >
                                <label style={{ fontWeight: 'bold' }} htmlFor="name">Nif</label>
                                <input
                                    className="form-control undefined"
                                    name="email"
                                    disabled={true}
                                    type="text"
                                    value={userLogged.nif}
                                />
                            </div>
                        </div>
                    </div>


                    <button
                        className={"btn btn-primary btn-user btn-block mt-4"}
                        type="button"
                        style={{ width: "300px" }}
                        onClick={(event) => {
                            setShowModal(true)
                        }}
                    >
                        Cambiar contraseña
                    </button>

                    <Modal show={showModal} onHide={closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Cambiar contraseña</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group">
                                <input
                                    id="register-input oldPassword"
                                    name="oldPassword"
                                    type="password"
                                    placeholder={"Contraseña anterior"}
                                    autoComplete="off"
                                    className={"form-control undefined"}
                                    value={oldPassword}
                                    required
                                    onChange={(event) => {
                                        const { value } = event.target;
                                        setOldPassword(value)
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    id="register-input newPassword1"
                                    name="newPassword1"
                                    type="password"
                                    placeholder={"Contraseña nueva"}
                                    autoComplete="off"
                                    className={"form-control undefined"}
                                    value={password1}
                                    required
                                    onChange={(event) => {
                                        const { value } = event.target;
                                        setPassword1(value)
                                        setPasswordRequirements(prevRequirements => ({
                                            ...prevRequirements,
                                            minLength: value.length >= 8,
                                            hasUppercase: /[A-Z]/.test(value),
                                            hasLowercase: /[a-z]/.test(value),
                                            hasNumber: /[0-9]/.test(value),
                                            passwordsMatch: value === password2 && value.length > 0,
                                        }));
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    id="register-input newPassword2"
                                    name="newPassword2"
                                    type="password"
                                    className={"form-control undefined"}
                                    placeholder={"Repita contraseña"}
                                    value={password2}
                                    onChange={(event) => {
                                        const { value } = event.target;
                                        setPassword2(value)
                                        setPasswordRequirements(prevRequirements => ({
                                            ...prevRequirements,
                                            passwordsMatch: value === password1 && value.length > 0,
                                        }));
                                    }}
                                />
                            </div>
                            {showPasswordRequirements(passwordRequirements)}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="primary"
                                disabled={!passwordIsOK || oldPassword.length < 1}
                                onClick={() => {
                                    goToChangePassword()
                                }}
                            >
                                Cambiar contraseña
                            </Button>
                            <Button variant="secondary" onClick={closeModal}>
                                Cerrar
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
            </div>
            <a href={allAppRoutes.avisoLegal} target="_blank">
                Términos y Condiciones
            </a>
            <br></br>
            <a href={allAppRoutes.politicaPrivacidad} target="_blank">
                Política de privacidad
            </a>
            <br></br>
            <a href={allAppRoutes.politicaCookies} target="_blank">
                Política de cookies
            </a>
        </Fragment>
    );
};

export default MisDatosForm;
