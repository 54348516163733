import React, { Fragment, ReactElement, useEffect, useState } from "react";
import InputText from "../../common/Inputs/InputText";
import InputDate from "../../common/Inputs/InputDate";
import {
    inicialiceSearchFilterList, searchFilterList, validateAlphaNumeric,
    addingOrEditing, usersTypes, loginUsers, suscriptionData
} from "aseguisShared";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../ApplicationState";
import { GetSuscriptionDataFromLocalStorage } from "../../common/commonInterfaces/commonFunctions";

interface infoToPaqueteriaListFilter {
    searchFilterPaqueteria: searchFilterList
    saveSearchFilterPaqueteria: (filter: searchFilterList) => void
}

function PaqueteriaListFilter(props: infoToPaqueteriaListFilter): ReactElement {

    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])
    
    return (

        <Fragment>

            <div className="form-row" style={{ marginBottom: "-25px" }}>

                <InputText
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="ID Albarán"
                    valueEdit={props.searchFilterPaqueteria.idString}
                    value={props.searchFilterPaqueteria.idString}
                    autoComplete={false}
                    onChange={(event) => {
                        let value = validateAlphaNumeric(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilterPaqueteria({
                            ...props.searchFilterPaqueteria,
                            pageNum: 1,
                            idString: value,
                            fueraDePlazo: false,
                            withTimeout: true
                        })
                    }}
                />

                <InputText
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="Referencia"
                    autoComplete={false}
                    valueEdit={props.searchFilterPaqueteria.referencia}
                    value={props.searchFilterPaqueteria.referencia}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                        value = value.trimStart()
                        props.saveSearchFilterPaqueteria({
                            ...props.searchFilterPaqueteria,
                            pageNum: 1,
                            referencia: value,
                            fueraDePlazo: false,
                            withTimeout: true
                        })
                    }}
                />

                {userLogged.userType === usersTypes.employee &&
                    <InputText
                        small={true}
                        colMesure="2"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.none}
                        name="Cliente"
                        valueEdit={props.searchFilterPaqueteria.clientName}
                        autoComplete={false}
                        value={props.searchFilterPaqueteria.clientName}
                        onChange={(event) => {
                            var value = validateAlphaNumeric(event.target.value)
                            value = value.trimStart()
                            props.saveSearchFilterPaqueteria({
                                ...props.searchFilterPaqueteria,
                                pageNum: 1,
                                clientName: value,
                                fueraDePlazo: false,
                                withTimeout: true
                            })
                        }}
                    />
                }

                {(userLogged.userType === usersTypes.employee && suscriptionData.proveedoresTransportes) &&
                    <InputText
                        small={true}
                        colMesure="2"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.none}
                        name="Transportista"
                        valueEdit={props.searchFilterPaqueteria.supplierName}
                        value={props.searchFilterPaqueteria.supplierName}
                        autoComplete={false}
                        onChange={(event) => {
                            var value = validateAlphaNumeric(event.target.value)
                            value = value.trimStart()
                            props.saveSearchFilterPaqueteria({
                                ...props.searchFilterPaqueteria,
                                pageNum: 1,
                                supplierName: value,
                                fueraDePlazo: false,
                                withTimeout: true
                            })
                        }}
                    />
                }

                {userLogged.userType === usersTypes.employee &&
                    <InputText
                        small={true}

                        colMesure="2"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.none}
                        autoComplete={false}
                        name="Conductor"
                        valueEdit={props.searchFilterPaqueteria.driverName}
                        value={props.searchFilterPaqueteria.driverName}
                        onChange={(event) => {
                            var value = validateAlphaNumeric(event.target.value)
                            value = value.trimStart()
                            props.saveSearchFilterPaqueteria({
                                ...props.searchFilterPaqueteria,
                                pageNum: 1,
                                driverName: value,
                                fueraDePlazo: false,
                                withTimeout: true
                            })
                        }}
                    />
                }

                {userLogged.userType === usersTypes.employee &&
                    <InputText
                        small={true}
                        colMesure="2"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.none}
                        name="Matrícula vehículo"
                        valueEdit={props.searchFilterPaqueteria.plateVehicle}
                        autoComplete={false}
                        value={props.searchFilterPaqueteria.plateVehicle}
                        onChange={(event) => {
                            var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                            value = value.trimStart()
                            props.saveSearchFilterPaqueteria({
                                ...props.searchFilterPaqueteria,
                                pageNum: 1,
                                plateVehicle: value,
                                fueraDePlazo: false,
                                withTimeout: true
                            })
                        }}
                    />
                }

            </div>

            <div className="form-row" style={{ marginBottom: "-5px" }}>

                {userLogged.userType === usersTypes.employee &&
                    <InputDate
                        small={true}
                        colMesure="2"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.edditing}
                        name="Fecha alta desde"
                        valueEdit={props.searchFilterPaqueteria.fechaDesde}
                        value={props.searchFilterPaqueteria.fechaDesde}
                        onChange={(date) => {
                            props.saveSearchFilterPaqueteria({
                                ...props.searchFilterPaqueteria,
                                pageNum: 1,
                                fechaDesde: date!,
                                fueraDePlazo: false,
                                withTimeout: true
                            })
                        }}
                    />
                }

                {userLogged.userType === usersTypes.employee &&
                    <InputDate
                        small={true}
                        colMesure="2"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.edditing}
                        name="Fecha alta hasta"
                        valueEdit={props.searchFilterPaqueteria.fechaHasta}
                        value={props.searchFilterPaqueteria.fechaHasta}
                        onChange={(date) => {
                            props.saveSearchFilterPaqueteria({
                                ...props.searchFilterPaqueteria,
                                pageNum: 1,
                                fechaHasta: date!,
                                fueraDePlazo: false,
                                withTimeout: true
                            })
                        }}
                    />
                }

                <InputDate
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.edditing}
                    name="Fecha albarán desde"
                    valueEdit={props.searchFilterPaqueteria.recogidaDesde}
                    value={props.searchFilterPaqueteria.recogidaDesde}
                    onChange={(date) => {
                        props.saveSearchFilterPaqueteria({
                            ...props.searchFilterPaqueteria,
                            pageNum: 1,
                            recogidaDesde: date!,
                            fueraDePlazo: false,
                            withTimeout: true
                        })
                    }}
                />

                <InputDate
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.edditing}
                    name="Fecha albarán hasta"
                    valueEdit={props.searchFilterPaqueteria.recogidaHasta}
                    value={props.searchFilterPaqueteria.recogidaHasta}
                    onChange={(date) => {
                        props.saveSearchFilterPaqueteria({
                            ...props.searchFilterPaqueteria,
                            pageNum: 1,
                            recogidaHasta: date!,
                            fueraDePlazo: false,
                            withTimeout: true
                        })
                    }}
                />

                {userLogged.userType === usersTypes.employee &&
                    <InputDate
                        small={true}
                        colMesure="2"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.edditing}
                        name="Fecha entrega desde"
                        valueEdit={props.searchFilterPaqueteria.entregaDesde}
                        value={props.searchFilterPaqueteria.entregaDesde}
                        onChange={(date) => {
                            props.saveSearchFilterPaqueteria({
                                ...props.searchFilterPaqueteria,
                                pageNum: 1,
                                entregaDesde: date!,
                                fueraDePlazo: false,
                                withTimeout: true
                            })
                        }}
                    />
                }

                {userLogged.userType === usersTypes.employee &&
                    <InputDate
                        small={true}
                        colMesure="2"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.edditing}
                        name="Fecha entrega hasta"
                        valueEdit={props.searchFilterPaqueteria.entregaHasta}
                        value={props.searchFilterPaqueteria.entregaHasta}
                        onChange={(date) => {
                            props.saveSearchFilterPaqueteria({
                                ...props.searchFilterPaqueteria,
                                pageNum: 1,
                                entregaHasta: date!,
                                fueraDePlazo: false,
                                withTimeout: true
                            })
                        }}
                    />
                }

            </div>

            <div className="form-row" style={{ marginBottom: "-30px" }}>

                <InputText
                    small={true}
                    colMesure="4"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="Origen / Destino"
                    autoComplete={false}
                    valueEdit={props.searchFilterPaqueteria.address}
                    value={props.searchFilterPaqueteria.address}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilterPaqueteria({
                            ...props.searchFilterPaqueteria,
                            pageNum: 1,
                            address: value,
                            fueraDePlazo: false,
                            withTimeout: true
                        })
                    }}
                />

                <InputText
                    small={true}
                    colMesure="2"
                    editEnabled={addingOrEditing.edditing}
                    addOrEdit={addingOrEditing.none}
                    name="Carta de porte"
                    autoComplete={false}
                    valueEdit={props.searchFilterPaqueteria.cartaPorteRegex}
                    value={props.searchFilterPaqueteria.cartaPorteRegex}
                    onChange={(event) => {
                        var value = validateAlphaNumeric(event.target.value)
                        value = value.trimStart()
                        props.saveSearchFilterPaqueteria({
                            ...props.searchFilterPaqueteria,
                            pageNum: 1,
                            cartaPorteRegex: value,
                            fueraDePlazo: false,
                            withTimeout: true
                        })
                    }}
                />

                <div className="form-group col-2">
                    <br></br>
                    <button
                        className="btn btn-info btn-sm mr-3 mt-2"
                        onClick={() => {
                            props.saveSearchFilterPaqueteria({ ...props.searchFilterPaqueteria, withTimeout: false })
                        }}
                    >
                        <i className="fas fa-sync"></i>
                    </button>
                    <button className="btn btn-sm btn-secondary mt-2"
                        onClick={() => {
                            props.saveSearchFilterPaqueteria(inicialiceSearchFilterList)
                        }}>
                        Limpiar filtro

                    </button>
                </div>

                {userLogged.userType === usersTypes.employee &&
                    <div className="form-group col-md-1">
                        <br></br>
                        <button
                            className={props.searchFilterPaqueteria.fueraDePlazo ? "btn btn-warning btn-sm mt-2" : "btn btn-warning mt-2 btn-sm disabled"}
                            onClick={() => {
                                props.saveSearchFilterPaqueteria({
                                    ...props.searchFilterPaqueteria,
                                    fueraDePlazo: !props.searchFilterPaqueteria.fueraDePlazo,
                                    enPause: false,
                                })
                            }}>
                            Vencidos
                        </button>
                    </div>
                }

                {userLogged.userType === usersTypes.employee &&
                    <div className="form-group col-md-1">
                        <br></br>
                        <button
                            className={props.searchFilterPaqueteria.enReparto ? "btn btn-warning btn-sm mt-2" : "btn btn-warning btn-sm mt-2 disabled"}
                            onClick={() => {
                                props.saveSearchFilterPaqueteria({
                                    ...props.searchFilterPaqueteria,
                                    enReparto: !props.searchFilterPaqueteria.enReparto,
                                    enPause: false,
                                })
                            }}>
                            Reparto
                        </button>
                    </div>
                }

                {userLogged.userType === usersTypes.employee &&
                    <div className="form-group col-md-1">
                        <br></br>
                        <button
                            className={props.searchFilterPaqueteria.enPause ? "btn btn-warning btn-sm mt-2" : "btn btn-warning btn-sm mt-2 disabled"}
                            onClick={() => {
                                props.saveSearchFilterPaqueteria({
                                    ...props.searchFilterPaqueteria,
                                    enPause: !props.searchFilterPaqueteria.enPause,
                                    fueraDePlazo: false,
                                    enReparto: false
                                })
                            }}>
                            Pausados
                        </button>
                    </div>
                }

            </div>

        </Fragment >

    );

};

export default PaqueteriaListFilter;
