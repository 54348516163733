import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../ApplicationState";
import { loginUsers, addingOrEditing, getFormattedDate } from "aseguisShared";
import { base64Files } from "../../components/UploadScannedAlbaranes/UploadScannedAlbaranesStore";
import InputDate from "./InputDate";

interface infoToCargaForm {
    allFilesCount: base64Files[]
    selectedFilesCount: base64Files[]
    onFileUpload: () => void
    addMoreFiles: () => void
    setFilesOnBase64: (base64Files: base64Files[]) => void
    deleteAllFiles: () => void
    deleteSelectedFile: () => void
}

function UploadMultipleFiles({ setFilesOnBase64, onFileUpload, addMoreFiles, allFilesCount, deleteAllFiles, selectedFilesCount, deleteSelectedFile }: infoToCargaForm): ReactElement {

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const loggedUser: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);

    const ref = React.useRef<HTMLInputElement>(null);

    const [selectedFileList, setSelectedFileList] = useState<FileList>()
    const [selectedDate, setSelectedDate] = useState<Date>(new Date())
    const [isLoading, setIsLoading] = useState(false)
    const [errorText, setErrorText] = useState("")

    const isValidFileUploaded = (file: File) => {
        const validExtensions = ['pdf', 'tif', 'tiff', 'PDF', 'TIF', 'TIFF']
        const fileExtension = file.type.split('/')[1]
        return validExtensions.includes(fileExtension)
    }

    useEffect(() => {

        const fetchData = async () => {
            if (selectedFileList) {
                const Bases64: base64Files[] = []
                for (let i = 0; i < selectedFileList.length; i++) {
                    const base64 = await getBase64(selectedFileList[i]) as string
                    let newBase64 = base64.split(",")
                    if (newBase64.length === 2) {
                        var push = true;
                        for (var elementFile of allFilesCount) {
                            if (elementFile.fileName === selectedFileList[i].name && validated) {
                                push = false
                            }
                        }
                        if (push) {
                            Bases64.push({
                                base64File: newBase64[1],
                                fechaEntrega: selectedDate,
                                fileName: selectedFileList[i].name,
                                whoDeliver: loggedUser
                            })
                        }
                    }
                }
                setFilesOnBase64(Bases64)
            }
            setIsLoading(false)
        }

        var validated = true;

        if (selectedFileList) {
            for (let i = 0; i < selectedFileList.length; i++) {
                if (!isValidFileUploaded(selectedFileList[i]) && validated) {
                    validated = false
                    setErrorText("Algún fichero no es válido. Seleccione ficheros 'pdf' o 'tif' ")
                }
            }
        }

        if (validated) {
            fetchData()
                .catch(console.error);
        } else {
            setIsLoading(false)
            setSelectedFileList(undefined)
            setSelectedDate(new Date())
            if (ref.current) {
                ref.current.value = ""
            }
        }

    }, [selectedFileList, selectedDate])


    useEffect(() => {
        const timeout = setTimeout(async () => {
            setErrorText("")
        }, 5000);
        return () => { clearTimeout(timeout) }
    }, [errorText])


    const getBase64 = (file: Blob, cb?: any) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = (error) => {
            }
        })
    }


    const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrorText("")
        if (event.target.files) {
            setSelectedFileList(event.target.files);
        }
    }


    const onUpload = () => {
        setFilesOnBase64([])
        if (ref.current) {
            ref.current.value = ""
        }
        onFileUpload()
    }

    const formatListFiles: JSX.Element[] = allFilesCount.map((file, index) => {
        return (
            <Fragment key={index}>
                <tr className={`table-row`} key={index}>
                    <td> {file.fileName} </td>
                    <td> {getFormattedDate(file.fechaEntrega)} </td>
                </tr>
            </Fragment>
        )
    });


    return (
        <div>

            <div className="card-body">
                <div className="form-row pt-3">
                    <div className="form-group col-md-6" >
                        <label style={{ fontWeight: 'bold' }} htmlFor="name"> Selección </label>
                        <div className="custom-file" id="customFile">
                            <input
                                key={selectedFilesCount.length}
                                lang="es"
                                id="customFileLang"
                                aria-describedby="inputGroupFileAddon01"
                                className="custom-file-input"
                                ref={ref}
                                type="file"
                                onChange={onFileChange}
                                multiple={true}
                                onClick={deleteSelectedFile}
                            />
                            <label className="custom-file-label" htmlFor="customFileLang">{selectedFilesCount.length > 0 ? (selectedFilesCount.length + (selectedFilesCount.length === 1 ? " fichero seleccionado" : " ficheros seleccionados")) : "Seleccionar ficheros"}</label>
                        </div>
                    </div>

                    <InputDate
                        colMesure="3"
                        editEnabled={addingOrEditing.edditing}
                        addOrEdit={addingOrEditing.edditing}
                        name="Fecha entrega"
                        valueEdit={selectedDate}
                        value={selectedDate}
                        onChange={(date) => {
                            setSelectedDate(date!)
                        }}
                    />

                    <div className="form-group col-md-1" >
                        <div style={{ height: 32 }}></div>
                        <button
                            className={(selectedDate > new Date("2020-01-01") && selectedFileList && selectedFileList.length > 0) ? "btn btn-success btn-green" : "btn btn-success btn-green disabled"}
                            disabled={(selectedDate > new Date("2020-01-01") && selectedFileList && selectedFileList.length > 0) ? false : true}
                            onClick={() => {
                                if (selectedDate > new Date("2020-01-01") && selectedFileList && selectedFileList.length > 0) {
                                    addMoreFiles()
                                    setSelectedFileList(undefined)
                                    setSelectedDate(new Date())
                                }
                            }}>
                            <i className="fas fa fa-plus"></i>
                        </button>
                    </div>
                </div>

                <div className="form-row mt-4 ml-4">
                    <div className="form-group col-md-6">
                        <div>

                            <table className="table-sm table-bordered" style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}>
                                <thead>
                                    <tr>
                                        <th scope="col">Nombre fichero</th>
                                        <th scope="col">Fecha de entrega</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allFilesCount.length > 0 ?
                                        formatListFiles
                                        :
                                        <tr className={`table-row`}>
                                            <td>{"Añada ficheros a procesar"}</td>
                                            <td></td>
                                        </tr>
                                    }
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-md-6">
                        <div style={{ minHeight: '50px', display: 'flex'}}>
                            {(isAppLoading || isLoading) &&
                                <div>
                                    {"Procesando... "}
                                    <span className="spinner-border spinner-border-sm mr-4"></span>
                                </div>
                            }
                            {errorText.length > 0 &&
                                <div>
                                    <span style={{ color: "#E23F3F" }}>{errorText}</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-md-6">
                        <div>
                            <button
                                style={{ marginTop: "30px", marginRight: "10px" }}
                                className={(allFilesCount.length > 0) ? "btn btn-info mb-1" : "btn btn-info disabled mb-1"}
                                onClick={() => {
                                    if (allFilesCount.length > 0) {
                                        deleteAllFiles()
                                    }
                                }}
                                disabled={(allFilesCount.length <= 0)}
                            >
                                Limpiar
                            </button>
                            <button
                                style={{ marginTop: "30px" }}
                                className={(allFilesCount.length > 0) ? "btn btn-success mb-1" : "btn btn-success disabled mb-1"}
                                onClick={() => {
                                    if (allFilesCount.length > 0) {
                                        onUpload()
                                    }
                                }}
                                disabled={(allFilesCount.length <= 0)}
                            >
                                Subir ficheros
                            </button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );



};

export default UploadMultipleFiles;