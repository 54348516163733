import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState, reducers } from './ApplicationState';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';
import { withReduxStateSync } from 'redux-state-sync';

export default function configureStore(history: History, initialState?: ApplicationState) {

    const config = {
        /*blacklist: [
            'persist/PERSIST',
            'persist/REHYDRATE'
        ],*/
        whitelist: [
            'AUTHENTICATE_RECEIVED',
            'LOG_OUT',

            'SET_STRIPE_PRODUCTS',

            'SAVE_PARAMETRIZACION_RECEIVED',
            'RECEIVED_PARAMETRIZACION',

            'SAVE_COMPANY_RECEIVED',
            'RECEIVED_COMPANY',

            'SET_SUSCRIPTION_DATA'
        ],
    };

    const middleware = [
        thunk,
        routerMiddleware(history),
        createStateSyncMiddleware(config)
    ];

    const rootReducer = withReduxStateSync(
        combineReducers({
            ...reducers,
            router: connectRouter(history)
        })
    );

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const store = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    // Sincronizar el estado con el almacenamiento local
    initStateWithPrevTab(store);

    return store;
}






