import React, { Fragment, useEffect, ReactElement, useState, useRef, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from '../../../ApplicationState';
import AutoSuggest from "react-autosuggest";
import Match from 'autosuggest-highlight/match';
import Parse from 'autosuggest-highlight/parse';
import {
    tiposArticuladosVehiculos, tiposEstructuraVehiculos, vehicleType, CapitalizefirstLetter,
    validateAlphaNumeric, validateFormEmpty, quitarAcentosyMayusculas
} from "aseguisShared";
import NumberFormat from "react-number-format";
interface infoToVehiclesTypesAutocomplete {
    vehicleTypeSelected: vehicleType
    onClick: (vehicleType: vehicleType) => void

    autoFocus?: boolean
    onBlur?: () => void

    setErrorForm?: (value: boolean) => void,

    whatShow: "remolques" | "all"
}
function VehiclesTypesAutocomplete(props: infoToVehiclesTypesAutocomplete): ReactElement {

    const vehiclesTypes: vehicleType[] = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm.vehiclesTypes);

    const [inputFocus, setInputFocus] = useState(false)
    const [inputText, setInputText] = useState("")
    const [vehiclesTypesFiltered, setVehiclesTypesFiltered] = useState<vehicleType[]>(vehiclesTypes)
    const suggestionsContainerRef = useRef<HTMLSpanElement>(null);

    const refFocus = useRef<HTMLInputElement>(null);
    useLayoutEffect(() => {
        if (null !== refFocus.current && props.autoFocus) {
            refFocus.current.focus()
        }
    })

    useEffect(() => {
        if (props.setErrorForm) {
            if (
                (props.vehicleTypeSelected.description.length < 1)
            ) {
                props.setErrorForm(true)
            } else {
                props.setErrorForm(false)
            }
        }
    }, [props.vehicleTypeSelected.description])

    function onClick(vehicleType: vehicleType): void {
        props.onClick(vehicleType)
    }

    function renderSuggestion(suggestion: vehicleType, query: any): JSX.Element {
        const textToHigh = "" + query.query
        const vehicleType = suggestion.description
        const matches = Match(vehicleType, textToHigh, { insideWords: true });
        const parts = Parse(vehicleType, matches)
        if (suggestion.estructura === tiposEstructuraVehiculos.rigido) {
            return (
                <div>
                    <div>
                        <b>{"Descripción: "}</b>
                        {parts.map((part, index) => {
                            const className = part.highlight ? 'highlight' : '';
                            return (
                                <span className={className} key={index}>{part.text}</span>
                            );
                        })}
                    </div>
                    <div><b>{"Tipo de vehículo: "}</b> {suggestion.typeVehicle}</div>
                    <div><b>{"Capacidad carga: "}</b>
                        <NumberFormat
                            renderText={(value) => value}
                            displayType="text"
                            value={suggestion.capacidadCarga}
                            className="form-control undefined"
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={0}
                            fixedDecimalScale={true}
                            suffix={" kg"}
                        />
                    </div>
                </div>
            );
        }

        if (props.whatShow === "all") {
            if (suggestion.estructura === tiposEstructuraVehiculos.articulado && suggestion.tractoraRemolque === tiposArticuladosVehiculos.tractora) {
                return (
                    <div>
                        <div>
                            <b>{"Descripción: "}</b>
                            {parts.map((part, index) => {
                                const className = part.highlight ? 'highlight' : '';
                                return (
                                    <span className={className} key={index}>{part.text}</span>
                                );
                            })}
                        </div>
                        <div><b>{"Tipo de estructura: "}</b> {suggestion.estructura}</div>
                        <div><b>{"Tractora o remolque: "}</b> {suggestion.tractoraRemolque}</div>
                    </div>
                );
            }
        }

        if (suggestion.estructura === tiposEstructuraVehiculos.articulado && suggestion.tractoraRemolque === tiposArticuladosVehiculos.remolque) {
            return (
                <div>
                    <div>
                        <b>{"Descripción: "}</b>
                        {parts.map((part, index) => {
                            const className = part.highlight ? 'highlight' : '';
                            return (
                                <span className={className} key={index}>{part.text}</span>
                            );
                        })}
                    </div>
                    <div><b>{"Tipo de vehículo: "}</b> {suggestion.typeVehicle}</div>
                    <div><b>{"Capacidad carga: "}</b>
                        <NumberFormat
                            renderText={(value) => value}
                            displayType="text"
                            value={suggestion.capacidadCarga}
                            className="form-control undefined"
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={0}
                            fixedDecimalScale={true}
                            suffix={" kg"}
                        />
                    </div>
                </div>
            );
        }

        return (<div> </div>)

    }

    useEffect(() => {
        if (inputFocus === false && props.vehicleTypeSelected.description !== inputText) {
            setInputText(props.vehicleTypeSelected.description)
        }
    }, [inputFocus, props.vehicleTypeSelected.description]);

    return (
        <Fragment>
            <span ref={suggestionsContainerRef} style={{ flex: "1" }}>
                <AutoSuggest
                    theme={{
                        suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open-200',
                        container: 'react-autosuggest__container',
                        containerOpen: 'react-autosuggest__container--open',
                        input: 'react-autosuggest__input',
                        inputOpen: 'react-autosuggest__input--open',
                        inputFocused: 'react-autosuggest__input--focused',
                        suggestionsContainer: 'react-autosuggest__suggestions-container',
                        suggestionsList: 'react-autosuggest__suggestions-list',
                        suggestion: 'react-autosuggest__suggestion',
                        suggestionFirst: 'react-autosuggest__suggestion--first',
                        suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
                        sectionContainer: 'react-autosuggest__section-container',
                        sectionContainerFirst: 'react-autosuggest__section-container--first',
                        sectionTitle: 'react-autosuggest__section-title'
                    }}
                    onSuggestionHighlighted={({ suggestion }) => {
                        if (suggestion && suggestionsContainerRef.current) {
                            const index = vehiclesTypesFiltered.findIndex(s => s === suggestion);
                            const suggestionElements = suggestionsContainerRef.current.querySelectorAll('.react-autosuggest__suggestion');
                            const highlightedElement = suggestionElements[index];
                            if (highlightedElement) {
                                highlightedElement.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'nearest'
                                });
                            }
                        }
                    }}
                    suggestions={vehiclesTypesFiltered}
                    onSuggestionsClearRequested={() =>
                        setVehiclesTypesFiltered([])
                    }
                    onSuggestionsFetchRequested={({ value }) => {
                        setVehiclesTypesFiltered([])
                        if (value.length > 0) {
                            vehiclesTypes.map(vehiType => {
                                if (props.whatShow === "remolques") {
                                    if (vehiType.tractoraRemolque !== tiposArticuladosVehiculos.tractora) { //solo mostramos vehículos y remolques. tractoras no tiene sentido
                                        const valueWithoutAcentosYMayusculas = quitarAcentosyMayusculas(value);
                                        const descriptionWithoutAcentosYMayusculas = quitarAcentosyMayusculas(vehiType.description);
                                        if (descriptionWithoutAcentosYMayusculas.includes(valueWithoutAcentosYMayusculas)) {
                                            setVehiclesTypesFiltered(vehiclesTypes => [...vehiclesTypes, vehiType]);
                                        }
                                    }
                                } else {
                                    const valueWithoutAcentosYMayusculas = quitarAcentosyMayusculas(value);
                                    const descriptionWithoutAcentosYMayusculas = quitarAcentosyMayusculas(vehiType.description);
                                    if (descriptionWithoutAcentosYMayusculas.includes(valueWithoutAcentosYMayusculas)) {
                                        setVehiclesTypesFiltered(vehiclesTypes => [...vehiclesTypes, vehiType]);
                                    }
                                }
                            })
                        }
                    }}
                    onSuggestionSelected={(_, { suggestion, suggestionValue }) => {
                        onClick(suggestion)
                    }}
                    getSuggestionValue={(suggestion) =>
                        suggestion.description
                    }
                    renderSuggestion={renderSuggestion}
                    inputProps={{
                        placeholder: "Tipo vehículo",
                        value: inputText,
                        onChange: (event, { newValue, method }) => {
                            var value = CapitalizefirstLetter(validateAlphaNumeric(newValue))
                            value = value.trimStart()
                            setInputText(value)
                            if (value.length === 0) {
                                setVehiclesTypesFiltered([]);
                            }
                        },
                        type: "text",
                        name: "dest_search",
                        className: validateFormEmpty(props.vehicleTypeSelected.description, 2),
                        autoComplete: "off",
                        onBlur: () => {
                            if (props.vehicleTypeSelected.id === 0) {
                                setInputText("")
                            }
                            setInputFocus(false)
                            setVehiclesTypesFiltered([])
                            if (props.onBlur) {
                                props.onBlur()
                            }
                        },
                        onFocus: () => {
                            setInputFocus(true)
                            setInputText("")
                        },
                    }}
                />
            </span>
        </Fragment >
    );
};

export default VehiclesTypesAutocomplete;