import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../ApplicationState';
import VehiclesControlsStats from "../VehiclesControls/VehiclesControlsStats";
import EmployeesStats from "../Employees/EmployeesStats";
import { GetSuscriptionDataFromLocalStorage, GetUserPermissionsFromLocalStorage } from "../../common/commonInterfaces/commonFunctions";
import { loginUsers, UserPermissions, usersTypes, suscriptionData } from "aseguisShared";
import { } from "../Login/LoginStore";


function ControlPanel(): ReactElement {

    let { permissions } = GetUserPermissionsFromLocalStorage()
    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    return (

        <Fragment>

            {permissions.panel === UserPermissions.consultar ?

                <div>

                    {(userLogged.userType === usersTypes.employee) &&
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <EmployeesStats />
                            </div>
                        </div>
                    }

                    {(userLogged.userType === usersTypes.employee && suscriptionData.controlesVehiculos) &&
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <VehiclesControlsStats />
                            </div>
                        </div>
                    }

                    {/*<div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <ReviRepaStats />
                        </div>
                    </div>*/}

                </div>

                :

                <div></div>

            }

            {/*<Redirect to={GetFirstRouteEnabled()} /> --VA EN EL ELSE (:) PERO LO PONGO AQUI PORQUE NO SE PUEDE BORRAR EN EL ELSE-- */}

        </Fragment>

    );

};


export default ControlPanel;
