import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, ReactElement } from "react";
import { ApplicationState } from '../../ApplicationState';
import { exportClientsToExcel, requestAllClients } from './ClientsStore';
import ClientsListFilter from "./ClientsListFilter";
import { filterClientsTableTH } from "../../common/commonInterfaces/thFilters";
import { client, searchFilterList  } from "aseguisShared";
import { Pagination } from "../../common/commonInterfaces/commonFunctions";
import { ButtonEditarPrivate } from "../../common/Buttons/ButtonEditarPrivate";
import AddButtonList from "../../common/Buttons/AddButtonList";

interface infoToClientList {
    onClick: (client: client) => void
    addClient?: () => void
    searchClientsFilter: searchFilterList
    saveSearchClientsFilter: (filter: searchFilterList) => void
}

function ClientsList(props: infoToClientList): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();
    const allClients: client[] = useSelector((state: ApplicationState) => state.clients.clients) || [];
    const countAllClients: number = useSelector((state: ApplicationState) => state.clients.totalClientsList);
    const numPage: number = useSelector((state: ApplicationState) => state.clients.actualPage);
    const totalPages: number = useSelector((state: ApplicationState) => state.clients.totalPages);
    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const clientEditForm: client = useSelector((state: ApplicationState) => state.clients.clientEditForm);

    const isModifyEnabled = ButtonEditarPrivate().isModifyEnabled();

    useEffect(() => {
        if (props.searchClientsFilter.withTimeout) {
            const timeout = setTimeout(() => {
                dispatch(requestAllClients(props.searchClientsFilter));
            }, 600)
            return () => clearTimeout(timeout)
        } else {
            dispatch(requestAllClients(props.searchClientsFilter));
        }
    }, [props.searchClientsFilter, clientEditForm]);

    const clientsList: JSX.Element[] = allClients.map((client, index) => {
        return (
            <tr className={`table-row`}
                onClick={() => {
                    props.onClick(client)
                }}
                key={index}>
                <td scope="row"><b>{client.id}</b></td>
                <td>{client.nombre}</td>
                <td>{client.email}</td>
                <td>{client.nif}</td>
                <td>{client.address.localidad}</td>
                <td>{client.address.provincia}</td>
                <td>{client.tel1}</td>
            </tr>);
    });

    return (

        <Fragment>

            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        {/*aÑadir button*/}
                        {(props.addClient && isModifyEnabled) &&
                            <div className="header-buttons">

                                <button
                                    className="btn btn-info btn-sm left-margin"
                                    onClick={() => {
                                        if (allClients.length > 0) {
                                            dispatch(exportClientsToExcel({
                                                ...props.searchClientsFilter,
                                                getAll: true
                                            }))
                                        }
                                    }}>
                                    Exportar a Excel
                                </button>

                                <AddButtonList
                                    addCallback={props.addClient}
                                />

                            </div>
                        }

                        <div className="card-header">

                            <div className="form-inline">

                                <h6 className="m-0 font-weight-bold text-green">Lista de clientes {"(" + countAllClients + ")"}</h6>

                                {(isAppLoading === true) &&
                                    <span className="spinner-border spinner-border-sm ml-4"></span>
                                }

                            </div>

                            <br></br>

                            <ClientsListFilter
                                searchFilterClients={props.searchClientsFilter}
                                saveSearchFilterClients={props.saveSearchClientsFilter}
                            />

                        </div>

                        <div className="card-body">
                            <div className="table-responsive table-hover">

                                <table className="table table-sm">
                                    <thead className="thead-light">
                                        <tr>

                                            {filterClientsTableTH(props.saveSearchClientsFilter, props.searchClientsFilter, "#")}
                                            {filterClientsTableTH(props.saveSearchClientsFilter, props.searchClientsFilter, "Nombre")}
                                            {filterClientsTableTH(props.saveSearchClientsFilter, props.searchClientsFilter, "Email")}
                                            {filterClientsTableTH(props.saveSearchClientsFilter, props.searchClientsFilter, "Cif")}
                                            {filterClientsTableTH(props.saveSearchClientsFilter, props.searchClientsFilter, "Localidad")}
                                            {filterClientsTableTH(props.saveSearchClientsFilter, props.searchClientsFilter, "Provincia")}
                                            {filterClientsTableTH(props.saveSearchClientsFilter, props.searchClientsFilter, "Tel 1")}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {clientsList}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* paginacion */}
                        {Pagination(numPage, totalPages, props.searchClientsFilter, props.saveSearchClientsFilter)}

                    </div>
                </div>
            </div>
        </Fragment >

    );
};

export default ClientsList;