import React, { ChangeEvent, Fragment, ReactElement, useEffect, useRef } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedDate, parametrizacion, validateFormEmpty } from "aseguisShared";
import { GetXMLApi } from "./GetXMLApi";
import ReactTooltip from "react-tooltip";
import { saveParametrizacion } from "../Parametrizacion/ParametrizacionStore";
import { ApplicationState } from "../../ApplicationState";

function GetRemesaXML(): ReactElement {

    const dispatch = useDispatch();
    const timeoutRef = useRef<any>(null);
    const paramForm: parametrizacion = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm);
    const { error, billsToGetRemesa, setBillsToGetRemesa, getBillsByManyIds, getRemesaFromBills, getAllPending } = GetXMLApi();
    const [desde, setDesde] = React.useState<number>(0);
    const [hasta, setHasta] = React.useState<number>(0);
    const [remesaIdentity, setRemesaIdentity] = React.useState<string>(paramForm.remesaIdentifier ? paramForm.remesaIdentifier : "");

    useEffect(() => {
        if (paramForm.remesaIdentifier && paramForm.remesaIdentifier.length > 0 && remesaIdentity.length === 0) {
            setRemesaIdentity(paramForm.remesaIdentifier)
        }
    }, [paramForm.remesaIdentifier]);

    return (
        <Fragment>

            <div className="card shadow mb-4">

                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">
                        Únicamente se creará la remesa para las facturas que tengan el cliente configurado con forma de pago 'giro'
                    </h6>
                </div>

                <div className="card-body">

                    <div className="form-row" style={{ marginLeft: "10px" }}>

                        <div className="form-group col-md-3">
                            <div>
                                <label style={{ fontWeight: 'bold' }}>
                                    Identificador único:
                                    <span data-tip="El identificador único es un código que proporciona la entidad bancaria y que identifica de manera única a la organización"> (*)</span>
                                </label>
                                <input
                                    type="text"
                                    className={validateFormEmpty(remesaIdentity, 2)}
                                    placeholder="Introduzca el identificador"
                                    value={remesaIdentity}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        const currentValue = event.target.value;
                                        setRemesaIdentity(currentValue)
                                        if (timeoutRef.current) {
                                            clearTimeout(timeoutRef.current);
                                        }
                                        timeoutRef.current = setTimeout(() => {
                                            dispatch(saveParametrizacion({
                                                ...paramForm,
                                                remesaIdentifier: currentValue
                                            }));
                                        }, 500);
                                    }}
                                />

                            </div>
                        </div>
                    </div>

                    <br></br>

                    <div className="form-group row">

                        <div className="col-sm-6">
                            <div className="card shadow mb-4">
                                <div className="card-header">
                                    <h6 className="m-0 font-weight-bold text-green">Buscar por ID</h6>
                                </div>
                                <div className="card-body" style={{ height: '80px' }}>
                                    <div className="form-row align-items-center">
                                        {/* Desde ID */}
                                        <div className="form-group col-md-5 d-flex align-items-center">
                                            <label className="font-weight-bold mb-0 col-5 pl-0">Desde ID:</label>
                                            <div className="col-7 pl-0">
                                                <NumberFormat
                                                    autoComplete="off"
                                                    value={desde}
                                                    className="form-control"
                                                    onValueChange={(event) => {
                                                        setDesde(event.floatValue || 0)
                                                    }}
                                                    decimalScale={0}
                                                    allowNegative={false}
                                                />
                                            </div>
                                        </div>

                                        {/* Hasta ID */}
                                        <div className="form-group col-md-5 d-flex align-items-center">
                                            <label className="font-weight-bold mb-0 col-5 pl-0">Hasta ID:</label>
                                            <div className="col-7 pl-0">
                                                <NumberFormat
                                                    autoComplete="off"
                                                    value={hasta}
                                                    className="form-control"
                                                    onValueChange={(event) => {
                                                        setHasta(event.floatValue || 0)
                                                    }}
                                                    decimalScale={0}
                                                    allowNegative={false}
                                                />
                                            </div>
                                        </div>

                                        {/* Botón Añadir */}
                                        <div className="form-group col-md-2">
                                            <button
                                                className="btn btn-info btn-sm w-100"
                                                onClick={() => {
                                                    var list = [];
                                                    for (var i = desde; i <= hasta; i++) {
                                                        list.push(i);
                                                    }
                                                    getBillsByManyIds(list);
                                                }}
                                            >
                                                Añadir
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-sm-6">
                            <div className="card shadow mb-4">
                                <div className="card-header">
                                    <h6 className="m-0 font-weight-bold text-green">Remesas Pendientes</h6>
                                </div>
                                <div className="card-body" style={{ height: '80px' }}>
                                    <div className="form-row align-items-center">
                                        <div className="form-group col-md-2">
                                            <button
                                                className="btn btn-success btn-sm w-100"
                                                onClick={() => {
                                                    getAllPending()
                                                }}
                                            >
                                                Añadir
                                            </button>
                                        </div>
                                        <div className="form-group col-md-10 d-flex align-items-center">
                                            <label
                                                className="font-weight-bold w-100"
                                                htmlFor="name"
                                            >
                                                Facturas pendientes para las cuales nunca se ha generado una remesa </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    {billsToGetRemesa.length > 0 ?
                        <table className="table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" className="w-15 p-3">Id factura</th>
                                    <th scope="col" className="w-40 p-3">Cliente</th>
                                    <th scope="col" className="w-25 p-3">Fecha factura</th>
                                    <th scope="col" className="w-20 p-3">Importe (sin IVA)</th>
                                    <th scope="col" className="w-20 p-3">Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {billsToGetRemesa.map((bill, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td className="align-middle p-3">
                                                {bill.id}
                                            </td>
                                            <td className="align-middle p-3">
                                                {bill.client.nombre}
                                            </td>
                                            <td className="align-middle p-3">
                                                {getFormattedDate(bill.fecFactur)}
                                            </td>
                                            <td className="align-middle p-3">
                                                <NumberFormat
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    displayType="text"
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    allowNegative={false}
                                                    value={bill.datosEconomicosTotales.subTotal}
                                                    className="form-control undefined"
                                                    renderText={value => <div>{value}</div>}
                                                    suffix={" €"}
                                                />
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-secondary btn-sm"
                                                    onClick={() => {
                                                        setBillsToGetRemesa(billsToGetRemesa.filter(billRemesa => billRemesa.id !== bill.id));
                                                    }}
                                                >
                                                    <i className="fas fa fa-times"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        : "Añada facturas para crear la remesa"
                    }

                    <br></br><br></br>

                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <div>
                                <button
                                    disabled={billsToGetRemesa.length === 0 || remesaIdentity.length === 0}
                                    style={{ marginTop: "30px" }}
                                    className="btn btn-success mb-1"
                                    onClick={() => {
                                        if (billsToGetRemesa.length > 0) {
                                            getRemesaFromBills(billsToGetRemesa)
                                            setBillsToGetRemesa([])
                                            setDesde(0)
                                            setHasta(0)
                                        }
                                    }}
                                >
                                    Crear Remesa
                                </button>
                                <button
                                    style={{ marginTop: "30px", marginLeft: "10px" }}
                                    className="btn btn-info mb-1"
                                    onClick={() => {
                                        setBillsToGetRemesa([])
                                        setDesde(0)
                                        setHasta(0)
                                    }}
                                >
                                    Limpiar formulario
                                </button>
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <div>
                                {error}
                            </div>
                        </div>
                    </div>
                </div>

                <ReactTooltip /> {/*Para sacar notas*/}

            </div>


        </Fragment>

    );
};

export default GetRemesaXML;
