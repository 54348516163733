"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInfoToCmrForPaqueteria = exports.encontrarDescargaSiguiente = exports.encontrarCargaAnterior = exports.getInfoToCmrForCarga = exports.initInfoToCreateCMR = exports.initinfoLugarToCreateCMR = void 0;
const commonEnums_1 = require("../enums/commonEnums");
const commonFunctions_1 = require("./commonFunctions");
exports.initinfoLugarToCreateCMR = {
    nombre: "",
    calleNumero: "",
    cpLocalidad: "",
    provinciaPais: "",
    fecha: ""
};
exports.initInfoToCreateCMR = {
    remitenteCliente: exports.initinfoLugarToCreateCMR,
    numAlbaran: "",
    consignatarioDestinatario: exports.initinfoLugarToCreateCMR,
    transportista: exports.initinfoLugarToCreateCMR,
    destinoMercancia: exports.initinfoLugarToCreateCMR,
    transportistasSucesivos: [],
    cargaMercancia: exports.initinfoLugarToCreateCMR,
    reservasObservacionesTransportista: "",
    documentosAdjuntos: "",
    vehiculo: "",
    remolque: "",
    marcasNumeros: "",
    bultos: "",
    claseEmbalaje: "",
    naturalezaMercancia: "",
    numeroEstadistico: "",
    kilos: "",
    m3: "",
    instruccionesRemitente: "",
    formaDePago: "",
    lugarFormalizacion: exports.initinfoLugarToCreateCMR,
    estipulacionesParticulares: "",
    precioTransporteRemitente: "",
    descuentosRemitente: "",
    balanceRemitente: "",
    suplementosRemitente: "",
    gastosAccesoriosRemitente: "",
    seguroRemitente: "",
    totalPagarRemitente: "",
    monedaRemitente: "",
    monedaConsignatario: "",
    precioTransporteConsignatario: "",
    descuentosConsignatario: "",
    balanceConsignatario: "",
    suplementosConsignatario: "",
    gastosAccesoriosConsignatario: "",
    seguroConsignatario: "",
    totalPagarConsignatario: "",
    reembolso: "",
    remitenteSign: commonFunctions_1.initInfoSign,
    destinatarioSign: commonFunctions_1.initInfoSign
};
const getInfoToCmrForCarga = (cargaCompleta, lineaViaje, indexDestination, whatChange, company) => {
    const destination = lineaViaje.destinations[indexDestination];
    const cmrInfoOriginal = destination.cmrInfo || exports.initInfoToCreateCMR;
    const ahora = new Date(cargaCompleta.datosGenerales.recogida);
    const dia = `0${ahora.getDate()}`.slice(-2);
    const mes = `0${ahora.getMonth() + 1}`.slice(-2);
    const ano = ahora.getFullYear();
    const fecha = dia + "-" + mes + "-" + ano;
    const cargaAnterior = (0, exports.encontrarCargaAnterior)(indexDestination, lineaViaje);
    const descargaSiguiente = (0, exports.encontrarDescargaSiguiente)(indexDestination, lineaViaje);
    if (cargaAnterior || descargaSiguiente) {
        let cargaMercancia = Object.assign({}, exports.initinfoLugarToCreateCMR);
        let descargaMercancia = Object.assign({}, exports.initinfoLugarToCreateCMR);
        let consignatarioMercancia = Object.assign({}, exports.initinfoLugarToCreateCMR);
        let firmaRemitente = Object.assign({}, commonFunctions_1.initInfoSign);
        let firmaDestinatario = Object.assign({}, commonFunctions_1.initInfoSign);
        if ((destination.cargaDescarga === "Descarga" || destination.cargaDescarga === "Destino") && cargaAnterior) {
            firmaRemitente = (cargaAnterior.destination.cmrInfo && cargaAnterior.destination.cmrInfo.remitenteSign && cargaAnterior.destination.cmrInfo.remitenteSign.sign && cargaAnterior.destination.cmrInfo.remitenteSign.sign.length > 0) ? cargaAnterior.destination.cmrInfo.remitenteSign : Object.assign({}, commonFunctions_1.initInfoSign);
            firmaDestinatario = (destination.cmrInfo && destination.cmrInfo.remitenteSign && destination.cmrInfo.remitenteSign.sign && destination.cmrInfo.remitenteSign.sign.length > 0) ? destination.cmrInfo.remitenteSign : Object.assign({}, commonFunctions_1.initInfoSign);
            cargaMercancia = {
                nombre: cargaAnterior.destination.destName,
                calleNumero: cargaAnterior.destination.address.calle + " " + cargaAnterior.destination.address.numero,
                cpLocalidad: cargaAnterior.destination.address.codigoPostal + " " + cargaAnterior.destination.address.localidad,
                provinciaPais: cargaAnterior.destination.address.provincia + " " + cargaAnterior.destination.address.pais,
                fecha: fecha
            };
            descargaMercancia = {
                nombre: destination.destName,
                calleNumero: destination.address.calle + " " + destination.address.numero,
                cpLocalidad: destination.address.codigoPostal + " " + destination.address.localidad,
                provinciaPais: destination.address.provincia + " " + destination.address.pais,
                fecha: fecha
            };
            consignatarioMercancia = Object.assign({}, descargaMercancia);
        }
        else if (descargaSiguiente) {
            firmaRemitente = (destination.cmrInfo && destination.cmrInfo.remitenteSign && destination.cmrInfo.remitenteSign.sign && destination.cmrInfo.remitenteSign.sign.length > 0) ? destination.cmrInfo.remitenteSign : Object.assign({}, commonFunctions_1.initInfoSign);
            cargaMercancia = {
                nombre: destination.destName,
                calleNumero: destination.address.calle + " " + destination.address.numero,
                cpLocalidad: destination.address.codigoPostal + " " + destination.address.localidad,
                provinciaPais: destination.address.provincia + " " + destination.address.pais,
                fecha: fecha
            };
            descargaMercancia = {
                nombre: descargaSiguiente.destination.destName,
                calleNumero: descargaSiguiente.destination.address.calle + " " + descargaSiguiente.destination.address.numero,
                cpLocalidad: descargaSiguiente.destination.address.codigoPostal + " " + descargaSiguiente.destination.address.localidad,
                provinciaPais: descargaSiguiente.destination.address.provincia + " " + descargaSiguiente.destination.address.pais,
                fecha: fecha
            };
            consignatarioMercancia = Object.assign({}, descargaMercancia);
        }
        const newCmr = Object.assign(Object.assign({}, cmrInfoOriginal), { numAlbaran: cargaCompleta.idString, 
            //////////// DATOS PARA INICIALIZAR
            formaDePago: whatChange === "init" ? "Portes Pagados" : cmrInfoOriginal.formaDePago, lugarFormalizacion: whatChange === "init" ? {
                nombre: company ? (company.nombre) : "",
                calleNumero: company ? (company.address.calle + " " + company.address.numero) : "",
                cpLocalidad: company ? (company.address.codigoPostal + " " + company.address.localidad) : "",
                provinciaPais: company ? (company.address.provincia + " " + company.address.pais) : "",
                fecha: fecha
            } : cmrInfoOriginal.lugarFormalizacion, 
            //////////// DATOS QUE DEBEN ACTUALIZARSE SI CAMBIAN EN EL FORMULARIO DE CARGA            
            transportista: (whatChange === "init" || whatChange === "transportista") ? {
                nombre: (cargaCompleta.datosGenerales.supplier.nombre.length > 0) ? (cargaCompleta.datosGenerales.supplier.nombre) : company ? (company.nombre) : "",
                calleNumero: (cargaCompleta.datosGenerales.supplier.nombre.length > 0) ? (cargaCompleta.datosGenerales.supplier.address.calle + " " + cargaCompleta.datosGenerales.supplier.address.numero) : company ? (company.address.calle + " " + company.address.numero) : "",
                cpLocalidad: (cargaCompleta.datosGenerales.supplier.nombre.length > 0) ? (cargaCompleta.datosGenerales.supplier.address.codigoPostal + " " + cargaCompleta.datosGenerales.supplier.address.localidad) : company ? (company.address.codigoPostal + " " + company.address.localidad) : "",
                provinciaPais: (cargaCompleta.datosGenerales.supplier.nombre.length > 0) ? (cargaCompleta.datosGenerales.supplier.address.provincia + " " + cargaCompleta.datosGenerales.supplier.address.pais) : company ? (company.address.provincia + " " + company.address.pais) : "",
                fecha: fecha
            } : cmrInfoOriginal.transportista, reservasObservacionesTransportista: (whatChange === 'observaciones' || whatChange === 'init') ? cargaCompleta.datosGenerales.observaciones : cmrInfoOriginal.reservasObservacionesTransportista, vehiculo: (whatChange === 'vehicle' || whatChange === 'init') ? (cargaCompleta.datosGenerales.vehicle.matricula + " " + cargaCompleta.datosGenerales.vehicle.marca + " " + cargaCompleta.datosGenerales.vehicle.modelo) : cmrInfoOriginal.vehiculo, remolque: (whatChange === 'vehicle' || whatChange === 'init') ? (cargaCompleta.datosGenerales.remolque.matricula + " " + cargaCompleta.datosGenerales.remolque.marca + " " + cargaCompleta.datosGenerales.remolque.modelo) : cmrInfoOriginal.remolque, bultos: (whatChange === 'bultos' || whatChange === 'init') ? (cargaCompleta.datosGenerales.bultos > 0 ? (cargaCompleta.datosGenerales.bultos.toString() + " bulto/s") : "-") : cmrInfoOriginal.bultos, kilos: (whatChange === 'kilos' || whatChange === 'init') ? (cargaCompleta.datosGenerales.kilos > 0 ? (cargaCompleta.datosGenerales.kilos.toString() + " kg") : "-") : cmrInfoOriginal.kilos, m3: (whatChange === 'm3' || whatChange === 'init') ? (cargaCompleta.datosGenerales.m3 > 0 ? (cargaCompleta.datosGenerales.m3.toString() + " m3") : "-") : cmrInfoOriginal.m3, remitenteCliente: (whatChange === 'cliente' || whatChange === 'init') ? {
                nombre: cargaCompleta.datosGenerales.client.nombre,
                calleNumero: cargaCompleta.datosGenerales.client.address.calle + " " + cargaCompleta.datosGenerales.client.address.numero,
                cpLocalidad: cargaCompleta.datosGenerales.client.address.codigoPostal + " " + cargaCompleta.datosGenerales.client.address.localidad,
                provinciaPais: cargaCompleta.datosGenerales.client.address.provincia + " " + cargaCompleta.datosGenerales.client.address.pais,
                fecha: fecha
            } : cmrInfoOriginal.remitenteCliente, 
            //////////// LAS LINEAS DEBEN ACTUALIZARSE CUANDO SE ACTUALIZAN LAS LINEAS VIAJE EN EL FORMULARIO
            cargaMercancia: (whatChange === 'lineas' || whatChange === 'init') ? Object.assign({}, cargaMercancia) : cmrInfoOriginal.cargaMercancia, consignatarioDestinatario: (whatChange === 'lineas' || whatChange === 'init') ? Object.assign({}, consignatarioMercancia) : cmrInfoOriginal.consignatarioDestinatario, destinoMercancia: (whatChange === 'lineas' || whatChange === 'init') ? Object.assign({}, descargaMercancia) : cmrInfoOriginal.destinoMercancia, 
            //////////// FIRMAS
            remitenteSign: firmaRemitente, destinatarioSign: firmaDestinatario });
        return newCmr;
    }
    return exports.initInfoToCreateCMR;
};
exports.getInfoToCmrForCarga = getInfoToCmrForCarga;
const encontrarCargaAnterior = (currentIndex, lineaViaje) => {
    for (let i = currentIndex - 1; i >= 0; i--) {
        if ((lineaViaje.destinations[i].cargaDescarga !== "Descarga" && lineaViaje.destinations[i].cargaDescarga !== "Destino") &&
            (lineaViaje.lineType === commonEnums_1.tariffsTypes.distancia || lineaViaje.lineType === commonEnums_1.tariffsTypes.poblacion || lineaViaje.lineType === commonEnums_1.tariffsTypes.trayecto)) {
            return { destination: lineaViaje.destinations[i], indexCargaAnterior: i };
        }
    }
    return null;
};
exports.encontrarCargaAnterior = encontrarCargaAnterior;
const encontrarDescargaSiguiente = (currentIndex, lineaViaje) => {
    for (let i = currentIndex + 1; i < lineaViaje.destinations.length; i++) {
        if ((lineaViaje.destinations[i].cargaDescarga === "Descarga" || lineaViaje.destinations[i].cargaDescarga === "Destino") &&
            (lineaViaje.lineType === commonEnums_1.tariffsTypes.distancia || lineaViaje.lineType === commonEnums_1.tariffsTypes.poblacion || lineaViaje.lineType === commonEnums_1.tariffsTypes.trayecto)) {
            return { destination: lineaViaje.destinations[i], indexDescargaSiguiente: i };
        }
    }
    return null;
};
exports.encontrarDescargaSiguiente = encontrarDescargaSiguiente;
const getInfoToCmrForPaqueteria = (paqueteria, whatChange, company) => {
    const cmrInfoOriginal = paqueteria.datosTransporte.cmrInfo || exports.initInfoToCreateCMR;
    const ahora = new Date(paqueteria.datosGenerales.recogida);
    const dia = `0${ahora.getDate()}`.slice(-2);
    const mes = `0${ahora.getMonth() + 1}`.slice(-2);
    const ano = ahora.getFullYear();
    const fecha = dia + "-" + mes + "-" + ano;
    const cargaMercancia = {
        nombre: paqueteria.datosTransporte.destName,
        calleNumero: paqueteria.datosTransporte.address.calle + " " + paqueteria.datosTransporte.address.numero,
        cpLocalidad: paqueteria.datosTransporte.address.codigoPostal + " " + paqueteria.datosTransporte.address.localidad,
        provinciaPais: paqueteria.datosTransporte.address.provincia + " " + paqueteria.datosTransporte.address.pais,
        fecha: fecha
    };
    const descargaMercancia = {
        nombre: paqueteria.datosTransporte.destNameDos,
        calleNumero: paqueteria.datosTransporte.addressDos.calle + " " + paqueteria.datosTransporte.addressDos.numero,
        cpLocalidad: paqueteria.datosTransporte.addressDos.codigoPostal + " " + paqueteria.datosTransporte.addressDos.localidad,
        provinciaPais: paqueteria.datosTransporte.addressDos.provincia + " " + paqueteria.datosTransporte.addressDos.pais,
        fecha: fecha
    };
    const consignatarioMercancia = {
        nombre: paqueteria.datosTransporte.destNameDos,
        calleNumero: paqueteria.datosTransporte.addressDos.calle + " " + paqueteria.datosTransporte.addressDos.numero,
        cpLocalidad: paqueteria.datosTransporte.addressDos.codigoPostal + " " + paqueteria.datosTransporte.addressDos.localidad,
        provinciaPais: paqueteria.datosTransporte.addressDos.provincia + " " + paqueteria.datosTransporte.addressDos.pais,
        fecha: fecha
    };
    const newCmr = Object.assign(Object.assign({}, cmrInfoOriginal), { numAlbaran: paqueteria.idString, 
        //////////// DATOS PARA INICIALIZAR
        formaDePago: whatChange === "init" ? "Portes Pagados" : cmrInfoOriginal.formaDePago, lugarFormalizacion: whatChange === "init" ? {
            nombre: company ? (company.nombre) : "",
            calleNumero: company ? (company.address.calle + " " + company.address.numero) : "",
            cpLocalidad: company ? (company.address.codigoPostal + " " + company.address.localidad) : "",
            provinciaPais: company ? (company.address.provincia + " " + company.address.pais) : "",
            fecha: fecha
        } : cmrInfoOriginal.lugarFormalizacion, 
        //////////// DATOS QUE DEBEN ACTUALIZARSE SI CAMBIAN EN EL FORMULARIO DE CARGA            
        transportista: (whatChange === "init" || whatChange === "transportista") ? {
            nombre: (paqueteria.datosGenerales.supplier.nombre.length > 0) ? (paqueteria.datosGenerales.supplier.nombre) : company ? (company.nombre) : "",
            calleNumero: (paqueteria.datosGenerales.supplier.nombre.length > 0) ? (paqueteria.datosGenerales.supplier.address.calle + " " + paqueteria.datosGenerales.supplier.address.numero) : company ? (company.address.calle + " " + company.address.numero) : "",
            cpLocalidad: (paqueteria.datosGenerales.supplier.nombre.length > 0) ? (paqueteria.datosGenerales.supplier.address.codigoPostal + " " + paqueteria.datosGenerales.supplier.address.localidad) : company ? (company.address.codigoPostal + " " + company.address.localidad) : "",
            provinciaPais: (paqueteria.datosGenerales.supplier.nombre.length > 0) ? (paqueteria.datosGenerales.supplier.address.provincia + " " + paqueteria.datosGenerales.supplier.address.pais) : company ? (company.address.provincia + " " + company.address.pais) : "",
            fecha: fecha
        } : cmrInfoOriginal.transportista, reservasObservacionesTransportista: (whatChange === 'observaciones' || whatChange === 'init') ? paqueteria.datosGenerales.observaciones : cmrInfoOriginal.reservasObservacionesTransportista, vehiculo: (whatChange === 'vehicle' || whatChange === 'init') ? (paqueteria.datosGenerales.vehicle.matricula + " " + paqueteria.datosGenerales.vehicle.marca + " " + paqueteria.datosGenerales.vehicle.modelo) : cmrInfoOriginal.vehiculo, remolque: ((whatChange === 'vehicle' || whatChange === 'init') && paqueteria.datosGenerales.remolque) ? (paqueteria.datosGenerales.remolque.matricula + " " + paqueteria.datosGenerales.remolque.marca + " " + paqueteria.datosGenerales.remolque.modelo) : cmrInfoOriginal.remolque, bultos: (whatChange === 'bultos' || whatChange === 'init') ? (paqueteria.datosTransporte.bultos > 0 ? (paqueteria.datosTransporte.bultos.toString() + " bulto/s") : "-") : cmrInfoOriginal.bultos, kilos: (whatChange === 'kilos' || whatChange === 'm3' || whatChange === 'init') ? (paqueteria.datosTransporte.kilos > 0 ? (paqueteria.datosTransporte.kilos.toString() + " kg") : "-") : cmrInfoOriginal.kilos, m3: (whatChange === 'm3' || whatChange === 'init') ? (paqueteria.datosTransporte.m3 > 0 ? (paqueteria.datosTransporte.m3.toString() + " m3") : "-") : cmrInfoOriginal.m3, remitenteCliente: (whatChange === 'cliente' || whatChange === 'init') ? {
            nombre: paqueteria.datosGenerales.client.nombre,
            calleNumero: paqueteria.datosGenerales.client.address.calle + " " + paqueteria.datosGenerales.client.address.numero,
            cpLocalidad: paqueteria.datosGenerales.client.address.codigoPostal + " " + paqueteria.datosGenerales.client.address.localidad,
            provinciaPais: paqueteria.datosGenerales.client.address.provincia + " " + paqueteria.datosGenerales.client.address.pais,
            fecha: fecha
        } : cmrInfoOriginal.remitenteCliente, 
        //////////// LAS LINEAS DEBEN ACTUALIZARSE CUANDO SE ACTUALIZAN LAS LINEAS VIAJE EN EL FORMULARIO
        cargaMercancia: (whatChange === 'origen' || whatChange === 'init') ? Object.assign({}, cargaMercancia) : cmrInfoOriginal.cargaMercancia, consignatarioDestinatario: (whatChange === 'destino' || whatChange === 'init') ? Object.assign({}, consignatarioMercancia) : cmrInfoOriginal.consignatarioDestinatario, destinoMercancia: (whatChange === 'destino' || whatChange === 'init') ? Object.assign({}, descargaMercancia) : cmrInfoOriginal.destinoMercancia, 
        //////////// FIRMAS
        remitenteSign: (paqueteria.datosTransporte.cmrInfo && paqueteria.datosTransporte.cmrInfo.remitenteSign && paqueteria.datosTransporte.cmrInfo.remitenteSign.sign && paqueteria.datosTransporte.cmrInfo.remitenteSign.sign.length > 0) ? paqueteria.datosTransporte.cmrInfo.remitenteSign : Object.assign({}, commonFunctions_1.initInfoSign), destinatarioSign: (paqueteria.datosTransporte.cmrInfo && paqueteria.datosTransporte.cmrInfo.destinatarioSign && paqueteria.datosTransporte.cmrInfo.destinatarioSign.sign && paqueteria.datosTransporte.cmrInfo.destinatarioSign.sign.length > 0) ? paqueteria.datosTransporte.cmrInfo.destinatarioSign : Object.assign({}, commonFunctions_1.initInfoSign) });
    return newCmr;
};
exports.getInfoToCmrForPaqueteria = getInfoToCmrForPaqueteria;
