import React, { Fragment, useState, ReactElement, useEffect } from "react";
import { Link } from "react-router-dom";
import { ApplicationState } from "../../ApplicationState";
import { UserPermissions, usersTypes, invertColor, suscriptionData } from "aseguisShared";
import { GetSuscriptionDataFromLocalStorage, GetUserPermissionsFromLocalStorage } from "../commonInterfaces/commonFunctions";
import { allAppRoutes } from "../commonInterfaces/allAppRoutes";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import reactCSS from 'reactcss';

function LeftMenu(): ReactElement {

    const { permissions } = GetUserPermissionsFromLocalStorage()
    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);
    const primaryColorState: string = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm.primaryColor);
    const secondColorState: string = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm.secondColor);
    const companyLogo: string = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm.companyLogo);
    const userType: string = useSelector((state: ApplicationState) => state.login.userLogged.userType);

    const [primaryColor, setPrimaryColor] = useState(localStorage.getItem('firstColor') || "");
    const [secondColor, setSecondColor] = useState(localStorage.getItem('secondColor') || "");
    const [leftMenuVisibility, setLeftMenuVisibility] = useState(false);
    const [textColor, setTextColor] = useState("")
    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (primaryColorState && primaryColorState.length > 0) {
            setPrimaryColor(primaryColorState)
            const colorToText = invertColor(primaryColorState, false)
            setTextColor(colorToText)
        }
    }, [primaryColorState])
    useEffect(() => {
        if (secondColorState && secondColorState.length > 0) {
            setSecondColor(secondColorState)
        }
    }, [secondColorState])

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    function changeLeftMenuVisibility() {
        setLeftMenuVisibility(!leftMenuVisibility);
    }
    function isNavActive(actualRoute: string, navRoute: string): string {
        if (actualRoute.includes(navRoute)) {
            return "nav-item active"
        } else {
            return "nav-item"
        }
    }

    /******************** CARGAMOS COLOR BARRA SCROLL *************************/
    useEffect(() => {
        const styleSheet = document.styleSheets[0];
        styleSheet.insertRule(`
      #navMenu::-webkit-scrollbar {
        width: 3px;
        height: 5px;
      }
    `, styleSheet.cssRules.length);

        styleSheet.insertRule(`
      #navMenu::-webkit-scrollbar-track {
        background: transparent;
        padding: 2px;
      }
    `, styleSheet.cssRules.length);

        styleSheet.insertRule(`
      #navMenu::-webkit-scrollbar-thumb {
        background-color: ${secondColor};
      }
    `, styleSheet.cssRules.length);
    }, [secondColor]);
    /******************** CARGAMOS COLOR BARRA SCROLL *************************/

    function getCollapseClass() {
        return (leftMenuVisibility) ? "" : "collapsed";
    }

    const usePathname = useLocation().pathname;

    const [panelHover, setPanelHover] = useState(false)
    const [cargaHover, setCargaHover] = useState(false)
    const [paqueteriaHover, setPaqueteriaHover] = useState(false)
    const [entregasHover, setEntregasHover] = useState(false)
    const [companyHover, setCompanyHover] = useState(false)
    const [clientHover, setClientHover] = useState(false)
    const [employeeHover, setEmployeeHover] = useState(false)
    const [supplierHover, setSupplierHover] = useState(false)
    const [vehicleHover, setVehicleHover] = useState(false)
    const [controlVehicleHover, setControlHover] = useState(false)
    const [ReviRepaHover, setReviRepaHover] = useState(false)
    const [ajusVehiHover, setAjusVehiHover] = useState(false)
    const [billsHover, setBillsHover] = useState(false)
    const [remesasHover, setRemesasHover] = useState(false)
    const [abonosHover, setAbonosHover] = useState(false)

    const styles = reactCSS({
        'default': {
            active: {
                color: textColor,
                fontWeight: "900"
            },
            notActive: {
                color: textColor,
                opacity: "0.8"
            },
            mouseOver: {
                color: textColor,
                fontWeight: "700"
            },
            sideBarDivider: {
                backgroundColor: textColor,
                margin: "0 1rem 1rem",
                opacity: "0.4"
            },
            title: {
                color: textColor,
                opacity: "0.3"
            },
        },
    });

    return (
        <Fragment>

            <div className="toggle-area">
                <button className="btn btn-primary toggle-button" onClick={() => changeLeftMenuVisibility()}>
                    <i className="fas fa-bolt"></i>
                </button>
            </div>

            <ul
                className={`navbar-nav sidebar sidebar-dark accordion ${getCollapseClass()}`}
                style={{ backgroundColor: primaryColor, borderLeft: "1px solid " + primaryColor }}

                id="collapseMenu">
                <br></br>
                <a className="sidebar-brand d-flex align-items-center justify-content-center" style={{ marginBottom: "25px" }} >

                    <div className="sidebar-brand-icon icon-green">
                        <img
                            className="img-profile rounded"
                            width={197.5}
                            height={88.4}
                            src={`data:image/jpeg;base64,${companyLogo}`}
                            style={{
                                backgroundColor: "#EFEFEF",
                                padding: "6px"
                            }}
                        />
                    </div>
                </a>

                <nav id="navMenu">

                    {(permissions.panel !== UserPermissions.deshabilitado) &&
                        <div id="navMenuItem">
                            <hr style={styles.sideBarDivider} className="sidebar-divider my-0" />
                            <li className={isNavActive(usePathname, allAppRoutes.leftMenuPanel)}>
                                <Link
                                    className="nav-link"
                                    to={allAppRoutes.leftMenuPanel}
                                    style={isNavActive(usePathname, allAppRoutes.leftMenuPanel).includes("active") ? styles.active : (panelHover ? styles.mouseOver : styles.notActive)}
                                    onMouseLeave={() => { setPanelHover(false); }}
                                    onMouseEnter={() => { setPanelHover(true); }}
                                >
                                    <i className="fas fa-fw fa-tachometer-alt"
                                        style={isNavActive(usePathname, allAppRoutes.leftMenuPanel).includes("active") ? styles.active : (panelHover ? styles.mouseOver : styles.notActive)}
                                    />
                                    <span>
                                        Panel de control
                                    </span>
                                </Link>
                            </li>
                        </div>
                    }

                    {(permissions.grupajes !== UserPermissions.deshabilitado ||
                        permissions.cargaCompleta !== UserPermissions.deshabilitado ||
                        permissions.paqueteria !== UserPermissions.deshabilitado)
                        &&
                        <div id="navMenuItem">
                            <hr style={styles.sideBarDivider} className="sidebar-divider" />
                            <div className="sidebar-heading">
                                <span style={styles.title}>TRANSPORTES</span>
                            </div>

                            {permissions.cargaCompleta !== UserPermissions.deshabilitado &&
                                <li className={isNavActive(usePathname, allAppRoutes.leftMenuCargaCompleta)}>
                                    <Link
                                        className="nav-link"
                                        style={isNavActive(usePathname, allAppRoutes.leftMenuCargaCompleta).includes("active") ? styles.active : (cargaHover ? styles.mouseOver : styles.notActive)}
                                        to={allAppRoutes.leftMenuCargaCompleta}
                                        onMouseLeave={() => { setCargaHover(false); }}
                                        onMouseEnter={() => { setCargaHover(true); }}
                                    >
                                        <i
                                            className="fa-sharp fa-solid fa-truck-moving"
                                            style={isNavActive(usePathname, allAppRoutes.leftMenuCargaCompleta).includes("active") ? styles.active : (cargaHover ? styles.mouseOver : styles.notActive)}>
                                        </i>
                                        <span style={{ color: textColor }}>Carga Completa</span>
                                    </Link>
                                </li>
                            }

                            {(permissions.paqueteria !== UserPermissions.deshabilitado && suscriptionData.paqueteria) &&
                                <li className={isNavActive(usePathname, allAppRoutes.leftMenuPaqueteria)}>
                                    <Link
                                        className="nav-link"
                                        style={isNavActive(usePathname, allAppRoutes.leftMenuPaqueteria).includes("active") ? styles.active : (paqueteriaHover ? styles.mouseOver : styles.notActive)}
                                        to={allAppRoutes.leftMenuPaqueteria}
                                        onMouseLeave={() => { setPaqueteriaHover(false); }}
                                        onMouseEnter={() => { setPaqueteriaHover(true); }}
                                    >
                                        <i
                                            style={isNavActive(usePathname, allAppRoutes.leftMenuPaqueteria).includes("active") ? styles.active : (paqueteriaHover ? styles.mouseOver : styles.notActive)}
                                            className="fa-sharp fa-solid fa-truck-fast">
                                        </i>
                                        <span style={{ color: textColor }}>Paquetería</span>
                                    </Link>
                                </li>
                            }

                            {(permissions.paqueteria === UserPermissions.modificar && permissions.cargaCompleta === UserPermissions.modificar && userType === usersTypes.employee && suscriptionData.entregasMasivas) &&
                                <li className={isNavActive(usePathname, allAppRoutes.leftMenuScan)}>
                                    <Link
                                        className="nav-link"
                                        style={isNavActive(usePathname, allAppRoutes.leftMenuScan).includes("active") ? styles.active : (entregasHover ? styles.mouseOver : styles.notActive)}
                                        to={allAppRoutes.leftMenuScan}
                                        onMouseLeave={() => { setEntregasHover(false); }}
                                        onMouseEnter={() => { setEntregasHover(true); }}
                                    >
                                        <i
                                            style={isNavActive(usePathname, allAppRoutes.leftMenuScan).includes("active") ? styles.active : (entregasHover ? styles.mouseOver : styles.notActive)}
                                            className="fa-solid fa-envelope-circle-check ">
                                        </i>
                                        <span style={{ color: textColor }}>Entregas</span>
                                    </Link>
                                </li>
                            }

                        </div>
                    }

                    {(permissions.compania !== UserPermissions.deshabilitado ||
                        permissions.clients !== UserPermissions.deshabilitado ||
                        permissions.employees !== UserPermissions.deshabilitado ||
                        permissions.proveedores !== UserPermissions.deshabilitado) &&
                        <div id="navMenuItem">
                            <hr style={styles.sideBarDivider} className="sidebar-divider" />
                            <div style={styles.title} className="sidebar-heading">
                                GESTIÓN
                            </div>

                            {(permissions.compania !== UserPermissions.deshabilitado) &&
                                <li className={isNavActive(usePathname, allAppRoutes.leftMenuCompany)}>
                                    <Link
                                        className="nav-link"
                                        style={isNavActive(usePathname, allAppRoutes.leftMenuCompany).includes("active") ? styles.active : (companyHover ? styles.mouseOver : styles.notActive)}
                                        to={allAppRoutes.leftMenuCompany}
                                        onMouseLeave={() => { setCompanyHover(false); }}
                                        onMouseEnter={() => { setCompanyHover(true); }}
                                    >
                                        <i
                                            style={isNavActive(usePathname, allAppRoutes.leftMenuCompany).includes("active") ? styles.active : (companyHover ? styles.mouseOver : styles.notActive)}
                                            className="fas fa-regular fa-building ml-1">
                                        </i>
                                        <span className="ml-1">Compañía</span>
                                    </Link>
                                </li>
                            }

                            {(permissions.clients !== UserPermissions.deshabilitado) &&
                                <li className={isNavActive(usePathname, allAppRoutes.leftMenuClients)}>
                                    <Link
                                        className="nav-link"
                                        style={isNavActive(usePathname, allAppRoutes.leftMenuClients).includes("active") ? styles.active : (clientHover ? styles.mouseOver : styles.notActive)}
                                        to={allAppRoutes.leftMenuClients}
                                        onMouseLeave={() => { setClientHover(false); }}
                                        onMouseEnter={() => { setClientHover(true); }}
                                    >
                                        <i
                                            style={isNavActive(usePathname, allAppRoutes.leftMenuClients).includes("active") ? styles.active : (clientHover ? styles.mouseOver : styles.notActive)}
                                            className="fas fa-fw fa-user">
                                        </i>
                                        <span>Clientes</span>
                                    </Link>
                                </li>
                            }

                            {permissions.employees !== UserPermissions.deshabilitado &&
                                <li className={isNavActive(usePathname, allAppRoutes.leftMenuEmployees)}>
                                    <Link
                                        className="nav-link"
                                        style={isNavActive(usePathname, allAppRoutes.leftMenuEmployees).includes("active") ? styles.active : (employeeHover ? styles.mouseOver : styles.notActive)}
                                        to={allAppRoutes.leftMenuEmployees}
                                        onMouseLeave={() => { setEmployeeHover(false); }}
                                        onMouseEnter={() => { setEmployeeHover(true); }}
                                    >
                                        <i
                                            style={isNavActive(usePathname, allAppRoutes.leftMenuEmployees).includes("active") ? styles.active : (employeeHover ? styles.mouseOver : styles.notActive)}
                                            className="fas fa-fw fa-user">
                                        </i>
                                        <span>Empleados</span>
                                    </Link>
                                </li>
                            }

                            {(permissions.proveedores !== UserPermissions.deshabilitado && suscriptionData.proveedoresTransportes) &&
                                <li className={isNavActive(usePathname, allAppRoutes.leftMenuSuppliers)}>
                                    <Link
                                        className="nav-link"
                                        style={isNavActive(usePathname, allAppRoutes.leftMenuSuppliers).includes("active") ? styles.active : (supplierHover ? styles.mouseOver : styles.notActive)}
                                        to={allAppRoutes.leftMenuSuppliers}
                                        onMouseLeave={() => { setSupplierHover(false); }}
                                        onMouseEnter={() => { setSupplierHover(true); }}
                                    >
                                        <i
                                            style={isNavActive(usePathname, allAppRoutes.leftMenuSuppliers).includes("active") ? styles.active : (supplierHover ? styles.mouseOver : styles.notActive)}
                                            className="fas fa-fw fa-user">
                                        </i>
                                        <span>Proveedores</span>
                                    </Link>
                                </li>
                            }

                        </div>
                    }


                    {(
                        permissions.vehicles !== UserPermissions.deshabilitado ||
                        permissions.vehiclesControls !== UserPermissions.deshabilitado ||
                        permissions.vehiclesReviRepa !== UserPermissions.deshabilitado
                    ) &&
                        <div id="navMenuItem">
                            <hr style={styles.sideBarDivider} className="sidebar-divider" />
                            <div style={styles.title} className="sidebar-heading">
                                VEHÍCULOS
                            </div>

                            {permissions.vehicles !== UserPermissions.deshabilitado &&
                                <li className={isNavActive(usePathname, allAppRoutes.leftMenuVehicles)}>
                                    <Link
                                        className="nav-link"
                                        style={isNavActive(usePathname, allAppRoutes.leftMenuVehicles).includes("active") ? styles.active : (vehicleHover ? styles.mouseOver : styles.notActive)}
                                        to={allAppRoutes.leftMenuVehicles}
                                        onMouseLeave={() => { setVehicleHover(false); }}
                                        onMouseEnter={() => { setVehicleHover(true); }}
                                    >
                                        <i
                                            style={isNavActive(usePathname, allAppRoutes.leftMenuVehicles).includes("active") ? styles.active : (vehicleHover ? styles.mouseOver : styles.notActive)}
                                            className="fa-solid fa-truck">
                                        </i>
                                        <span>Vehículos</span>
                                    </Link>
                                </li>
                            }

                            {(permissions.vehiclesControls !== UserPermissions.deshabilitado && suscriptionData.controlesVehiculos) &&
                                <li className={isNavActive(usePathname, allAppRoutes.leftMenuVehiclesControls)}>
                                    <Link
                                        className="nav-link"
                                        style={isNavActive(usePathname, allAppRoutes.leftMenuVehiclesControls).includes("active") ? styles.active : (controlVehicleHover ? styles.mouseOver : styles.notActive)}
                                        to={allAppRoutes.leftMenuVehiclesControls}
                                        onMouseLeave={() => { setControlHover(false); }}
                                        onMouseEnter={() => { setControlHover(true); }}
                                    >
                                        <i
                                            style={isNavActive(usePathname, allAppRoutes.leftMenuVehiclesControls).includes("active") ? styles.active : (controlVehicleHover ? styles.mouseOver : styles.notActive)}
                                            className="fa-solid fa-file-signature">
                                        </i>
                                        <span>Controles</span>
                                    </Link>
                                </li>
                            }

                            {(permissions.vehiclesReviRepa !== UserPermissions.deshabilitado && suscriptionData.reviRepa) &&
                                <li className={isNavActive(usePathname, allAppRoutes.leftMenuVehiclesReviRepa)}>
                                    <Link
                                        className="nav-link"
                                        style={isNavActive(usePathname, allAppRoutes.leftMenuVehiclesReviRepa).includes("active") ? styles.active : (ReviRepaHover ? styles.mouseOver : styles.notActive)}
                                        to={allAppRoutes.leftMenuVehiclesReviRepa}
                                        onMouseLeave={() => { setReviRepaHover(false); }}
                                        onMouseEnter={() => { setReviRepaHover(true); }}
                                    >
                                        <i
                                            style={isNavActive(usePathname, allAppRoutes.leftMenuVehiclesReviRepa).includes("active") ? styles.active : (ReviRepaHover ? styles.mouseOver : styles.notActive)}
                                            className="fa-solid fa-screwdriver-wrench">
                                        </i>
                                        <span>Revisiones y reparaciones</span>
                                    </Link>
                                </li>
                            }

                        </div>
                    }


                    {(permissions.bills !== UserPermissions.deshabilitado && permissions.bills !== null) &&
                        <div id="navMenuItem">
                            <hr style={styles.sideBarDivider} className="sidebar-divider" />
                            <div style={styles.title} className="sidebar-heading">
                                CONTABILIDAD
                            </div>

                            <li className={isNavActive(usePathname, allAppRoutes.leftMenuBills)}>
                                <Link
                                    className="nav-link"
                                    style={isNavActive(usePathname, allAppRoutes.leftMenuBills).includes("active") ? styles.active : (billsHover ? styles.mouseOver : styles.notActive)}
                                    to={allAppRoutes.leftMenuBills}
                                    onMouseLeave={() => { setBillsHover(false); }}
                                    onMouseEnter={() => { setBillsHover(true); }}
                                >
                                    <i
                                        style={isNavActive(usePathname, allAppRoutes.leftMenuBills).includes("active") ? styles.active : (billsHover ? styles.mouseOver : styles.notActive)}
                                        className="fa-regular fa-file-lines">
                                    </i>
                                    <span>Facturas</span>
                                </Link>
                            </li>

                            {(userType === usersTypes.employee && suscriptionData.remesas) &&
                                <li className={isNavActive(usePathname, allAppRoutes.leftMenuRemesa)}>
                                    <Link
                                        className="nav-link"
                                        to={allAppRoutes.leftMenuRemesa}
                                        style={isNavActive(usePathname, allAppRoutes.leftMenuRemesa).includes("active") ? styles.active : (remesasHover ? styles.mouseOver : styles.notActive)}
                                        onMouseLeave={() => { setRemesasHover(false); }}
                                        onMouseEnter={() => { setRemesasHover(true); }}
                                    >
                                        <i
                                            style={isNavActive(usePathname, allAppRoutes.leftMenuRemesa).includes("active") ? styles.active : (remesasHover ? styles.mouseOver : styles.notActive)}
                                            className="fa-solid fa-receipt">
                                        </i>
                                        <span>Remesas</span>
                                    </Link>
                                </li>
                            }

                            {userType === usersTypes.employee &&
                                <li className={isNavActive(usePathname, allAppRoutes.leftMenuAbonos)}>
                                    <Link
                                        className="nav-link"
                                        style={isNavActive(usePathname, allAppRoutes.leftMenuAbonos).includes("active") ? styles.active : (abonosHover ? styles.mouseOver : styles.notActive)}
                                        to={allAppRoutes.leftMenuAbonos}
                                        onMouseLeave={() => { setAbonosHover(false); }}
                                        onMouseEnter={() => { setAbonosHover(true); }}
                                    >
                                        <i
                                            style={isNavActive(usePathname, allAppRoutes.leftMenuAbonos).includes("active") ? styles.active : (abonosHover ? styles.mouseOver : styles.notActive)}
                                            className="fa-regular fa-file-lines">
                                        </i>
                                        <span>Abonos</span>
                                    </Link>
                                </li>
                            }

                        </div>
                    }

                    {permissions.isAdmin === true &&
                        <div id="navMenuItem">
                            <hr style={styles.sideBarDivider} className="sidebar-divider" />
                            <div style={styles.title} className="sidebar-heading">
                                AJUSTES
                            </div>

                            <li className={isNavActive(usePathname, allAppRoutes.leftMenuSettings)}>
                                <Link
                                    className="nav-link"
                                    style={isNavActive(usePathname, allAppRoutes.leftMenuSettings).includes("active") ? styles.active : (ajusVehiHover ? styles.mouseOver : styles.notActive)}
                                    to={allAppRoutes.leftMenuSettings}
                                    onMouseLeave={() => { setAjusVehiHover(false); }}
                                    onMouseEnter={() => { setAjusVehiHover(true); }}
                                >
                                    <i
                                        style={isNavActive(usePathname, allAppRoutes.leftMenuSettings).includes("active") ? styles.active : (ajusVehiHover ? styles.mouseOver : styles.notActive)}
                                        className="fa-solid fa-gear">
                                    </i>
                                    <span>Ajustes y suscripción</span>
                                </Link>
                            </li>

                        </div>
                    }

                </nav>

            </ul>

        </Fragment>
    );
};

export default LeftMenu;