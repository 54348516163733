import React, { Fragment, ReactElement, useEffect, ChangeEvent, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from '../../../ApplicationState';
import {
    addingOrEditing, company, cargaCompleta, address, CapitalizefirstLetter, formatedDirection,
    inicialiceAddress, validateAlphaNumeric, validateFormEmpty, validateNumberFormEmpty,
    lineaViajeCarga, inicialiceDestinationsLinea, inicializeLineaViajeCarga, inicialiceCargaCompleta,
    cobrarRetornoTypes, vehicleType, locationType, distancesFromGoogle, calculateIva
} from "aseguisShared";
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { calculatePoblacionCarga } from "../CargaCompletaCalculateTariff";
import GoogleAndClientsSuggestions from "../../../common/PlacesAutocomplete/GoogleAndClientsSuggestions";
import axios from "axios";
import { UseDebounce } from "../../../common/UseDebounce/UseDebounce";
import { trucoTipDirecciones } from "../../../common/commonInterfaces/commonFunctions";
import ReactTooltip from "react-tooltip";

interface infoToFormPoblacion {
    isEdditing: number;
    addOrEdit: number,
    vehicleType: vehicleType,
    saveLineaPoblacion: (lineaDestino: lineaViajeCarga) => void
    lineaPoblacion: lineaViajeCarga
    saveExistingLinea: () => void
    addLinea: () => void
}

function FormPoblacion(props: infoToFormPoblacion): ReactElement {

    const company: company = useSelector((state: ApplicationState) => state.company.company);
    const cargaEditForm: cargaCompleta = useSelector((state: ApplicationState) => state.cargaCompleta.cargaCompletaEditForm || inicialiceCargaCompleta);

    const [tariffText, setTariffText] = useState("");
    const [errorForm, setErrorForm] = useState(false);
    const [errorOnOrigin, setErrorOnOrigin] = useState(false);
    const [errorOnDestiny, setErrorOnDestiny] = useState(false);

    const [tempValueDto, setTempValueDto] = useState(0);
    const debouncedValueDto = UseDebounce(tempValueDto.toString(), 400);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        calculateLinea({
            ...props.lineaPoblacion,
            descuento: tempValueDto
        }, false)
    }, [debouncedValueDto]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    const calculateLinea = async (newLinea: lineaViajeCarga, recalculateDistance: boolean) => {
        if (newLinea.destinations[1]) {
            let lineaCalculated = calculatePoblacionCarga(
                newLinea,
                cargaEditForm,
                company
            )
            const lineaViaje: lineaViajeCarga = {
                ...newLinea,
                importe: lineaCalculated.subTotal,
                importeOriginal: lineaCalculated.subTotalOriginal,
                descuento: lineaCalculated.descuento,
                iva: lineaCalculated.iva,
                total: lineaCalculated.total,
                tariffCarga: lineaCalculated.tariff,
                centroIncrementValue: lineaCalculated.incrementoAlCentro
            }
            // SI HAY QUE LLAMAR A DISTANCIA Y VOLVER A CALCULAR...          
            if (recalculateDistance === true && lineaCalculated.tariff.locTariffs[0]?.extraTariffs.length > 0) {
                const calculateDistance = await calculateDistanceToCentro(lineaViaje.destinations[1].address, lineaCalculated.type)
                let distanceToCentroText = calculateDistance?.distanceText || ""
                let distanceToCentro = calculateDistance?.distanceValue || 0
                lineaCalculated = calculatePoblacionCarga(
                    {
                        ...lineaViaje,
                        importe: lineaCalculated.subTotal,
                        importeOriginal: lineaCalculated.subTotalOriginal,
                        descuento: lineaCalculated.descuento,
                        iva: lineaCalculated.iva,
                        total: lineaCalculated.total,
                        tariffCarga: lineaCalculated.tariff,
                        centroIncrementValue: lineaCalculated.incrementoAlCentro,
                        centroDistanceText: distanceToCentroText,
                        centroDistanceValue: distanceToCentro,
                    },
                    cargaEditForm,
                    company
                )
                props.saveLineaPoblacion({
                    ...lineaViaje,
                    importe: lineaCalculated.subTotal,
                    importeOriginal: lineaCalculated.subTotalOriginal,
                    descuento: lineaCalculated.descuento,
                    iva: lineaCalculated.iva,
                    total: lineaCalculated.total,
                    tariffCarga: lineaCalculated.tariff,
                    centroIncrementValue: lineaCalculated.incrementoAlCentro,
                    centroDistanceText: distanceToCentroText,
                    centroDistanceValue: distanceToCentro
                })
                setTariffText(lineaCalculated.text)
            } else {
                props.saveLineaPoblacion(lineaViaje)
                setTariffText(lineaCalculated.text)
            }
        }
    }

    useEffect(() => {
        /* si la longitud es 0, creamos las dos instancias, para origen y destino */
        if (props.lineaPoblacion.destinations.length === 0) {
            props.saveLineaPoblacion({
                ...props.lineaPoblacion,
                destinations: [{ ...inicialiceDestinationsLinea }, { ...inicialiceDestinationsLinea }]
            })
        }
    }, [])

    useEffect(() => {
        //si hay error en algun sitio, grabamos error general
        if (props.lineaPoblacion.destinations.length === 2) {
            if (
                errorOnOrigin === true ||
                props.lineaPoblacion.destinations[0].destName.length < 2 ||
                errorOnDestiny === true ||
                props.lineaPoblacion.destinations[1].destName.length < 2 ||
                props.lineaPoblacion.importe <= 0
            ) {
                setErrorForm(true)
            } else {
                setErrorForm(false)
            }
        } else {
            setErrorForm(true)
        }
    }, [props.lineaPoblacion,
        errorOnDestiny,
        errorOnOrigin
    ]);

    const calculateDistanceToCentro = async (address: address, tariffSelected: locationType) => {
        let text = ""
        var centro = ""
        if (tariffSelected === locationType.localidad) {
            var centro =
                address.localidad +
                ", " +
                address.provincia +
                ", " +
                address.pais
        }
        if (tariffSelected === locationType.provincia) {
            var centro =
                address.provincia +
                ", " +
                address.pais
        }
        if (centro.length > 0) {
            return await axios.post<distancesFromGoogle>('api/googleSearchDistance/',
                {
                    originAddress: formatedDirection(address),
                    destinyAddress: centro
                })
                .then((response) => {
                    const elements = response.data.rows[0].elements[0];
                    if (elements.distance && elements.duration) {
                        text = text + response.data.rows[0].elements[0].distance.text
                        return {
                            distanceText: response.data.rows[0].elements[0].distance.text,
                            distanceValue: response.data.rows[0].elements[0].distance.value,
                            durationText: response.data.rows[0].elements[0].duration.text,
                            durationValue: response.data.rows[0].elements[0].duration.value,
                        }
                    }
                })
        }
    }

    const showDistances = () => {
        return (
            <Fragment>
                <div style={{ height: "20px" }}>
                    {
                        props.lineaPoblacion.destinations[1] &&
                        props.lineaPoblacion.destinations[1].address.provincia && props.lineaPoblacion.centroIncrementValue > 0 &&
                        props.lineaPoblacion.tariffCarga && props.lineaPoblacion.tariffCarga.locTariffs &&
                        props.lineaPoblacion.tariffCarga.locTariffs[0] &&
                        <>
                            {props.lineaPoblacion.tariffCarga.locTariffs[0].locationType === locationType.localidad &&
                                < small > Distancia al centro de {props.lineaPoblacion.destinations[1].address.localidad} : {props.lineaPoblacion.centroDistanceText + " (+" + (props.lineaPoblacion.centroIncrementValue) + "€)"}</small>
                            }

                            {props.lineaPoblacion.tariffCarga.locTariffs[0].locationType === locationType.provincia &&
                                < small > Distancia al centro de {props.lineaPoblacion.destinations[1].address.provincia} : {props.lineaPoblacion.centroDistanceText + " (+" + (props.lineaPoblacion.centroIncrementValue) + "€)"}</small>
                            }
                        </>
                    }
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>

            <div className="card-body">
                <div>
                    <div className="form-row">
                        <div className="form-group col-md-5">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="name">
                                    Origen
                                    {trucoTipDirecciones()}
                                    {(props.lineaPoblacion.destinations[0] && props.isEdditing === addingOrEditing.edditing) &&
                                        props.lineaPoblacion.destinations[0].address.codigoPostal.length === 0 &&
                                        <small style={{ color: '#de555d' }}>
                                            &nbsp;&nbsp;{"<CÓDIGO POSTAL no encontrado>"}
                                        </small>
                                    }
                                </label>
                                <GoogleAndClientsSuggestions
                                    isEditing={props.isEdditing}
                                    valueNoEditing={(props.lineaPoblacion.destinations[0] && props.lineaPoblacion.destinations[0].address) ? formatedDirection(props.lineaPoblacion.destinations[0].address) : ""}
                                    placeholder="Escriba un lugar para autocompletar..."
                                    key={props.lineaPoblacion.destinations[0] ? props.lineaPoblacion.destinations[0].address.calle : ""}
                                    clientID={cargaEditForm.datosGenerales.client._id ? cargaEditForm.datosGenerales.client._id : ""}
                                    initialAddress={props.lineaPoblacion.destinations[0] ? props.lineaPoblacion.destinations[0].address : inicialiceAddress}
                                    activateClientsDirs={true}
                                    onClick={(newAddress) => {
                                        props.saveLineaPoblacion({
                                            ...props.lineaPoblacion,
                                            destinations: props.lineaPoblacion.destinations.map((dest, index) => {
                                                if (index === 0) {
                                                    return {
                                                        ...dest,
                                                        address: newAddress.address,
                                                        destName: newAddress.name,
                                                        tel: newAddress.tel,
                                                        cargaDescarga: "Origen"
                                                    }
                                                }
                                                return dest;
                                            }),
                                        })

                                    }}
                                    setErrorForm={setErrorOnOrigin}
                                />
                            </div>
                        </div>
                        <div className="form-group col-md-2">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="name"> Piso/Pta </label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        name="puerta"
                                        type="text"
                                        value={props.lineaPoblacion.destinations[0] ? props.lineaPoblacion.destinations[0].address.puerta : ""}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                                            props.saveLineaPoblacion({
                                                ...props.lineaPoblacion,
                                                destinations: props.lineaPoblacion.destinations.map((dest, index) => {
                                                    if (index === 0) {
                                                        return {
                                                            ...dest,
                                                            address: {
                                                                ...dest.address,
                                                                puerta: value
                                                            }
                                                        }
                                                    }
                                                    return dest;
                                                }),
                                            })
                                        }}
                                        className="form-control undefined"
                                    />
                                    :
                                    <div></div>
                                }
                            </div>
                        </div>

                        <div className="form-group col-md-3">
                            <label style={{ fontWeight: 'bold' }} htmlFor="name"> Nombre </label>
                            {(props.isEdditing === addingOrEditing.edditing) ?
                                <input
                                    name="nombre"
                                    type="text"
                                    value={props.lineaPoblacion.destinations[0] ? props.lineaPoblacion.destinations[0].destName : ""}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                        props.saveLineaPoblacion({
                                            ...props.lineaPoblacion,
                                            destinations: props.lineaPoblacion.destinations.map((dest, index) => {
                                                if (index === 0) {
                                                    return {
                                                        ...dest,
                                                        destName: value
                                                    }
                                                }
                                                return dest;
                                            })
                                        })
                                    }}
                                    className={validateFormEmpty(props.lineaPoblacion.destinations[0] ? props.lineaPoblacion.destinations[0].destName : "", 2)}
                                />
                                :
                                <div></div>
                            }
                        </div>
                        <div className="form-group col-md-2">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="name"> Teléfono </label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <NumberFormat
                                        format="### ## ## ##"
                                        value={props.lineaPoblacion.destinations[0] ? props.lineaPoblacion.destinations[0].tel : ""}
                                        id="tel1"
                                        isNumericString={true}
                                        className="form-control undefined"
                                        onValueChange={(value) => {
                                            props.saveLineaPoblacion({
                                                ...props.lineaPoblacion,
                                                destinations: props.lineaPoblacion.destinations.map((dest, index) => {
                                                    if (index === 0) {
                                                        return {
                                                            ...dest,
                                                            tel: value.value
                                                        }
                                                    }
                                                    return dest;
                                                }),
                                            })
                                        }} />
                                    :
                                    <div>{props.lineaPoblacion.destinations[0].tel}</div>
                                }
                                <div style={{ display: "flex" }}>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row" style={{ marginTop: -20 }}>
                        <div className="form-group col-md-6">
                            <div style={{ display: "flex" }}>
                                <button
                                    className="btn btn-light btn-sm mr-1"
                                    onClick={() => {
                                        props.saveLineaPoblacion({
                                            ...props.lineaPoblacion,
                                            destinations: props.lineaPoblacion.destinations.map((dest, index) => {
                                                if (index === 0) {
                                                    return {
                                                        ...dest,
                                                        cargaDescarga: "Origen",
                                                        address: company.address,
                                                        destName: company.nombre,
                                                        tel: company.tel1
                                                    }
                                                }
                                                return dest;
                                            })
                                        })
                                    }}>
                                    Usar base
                                </button>
                                <button
                                    className="btn btn-light btn-sm"
                                    onClick={() => {
                                        props.saveLineaPoblacion({
                                            ...props.lineaPoblacion,
                                            destinations: props.lineaPoblacion.destinations.map((dest, index) => {
                                                if (index === 0) {
                                                    return {
                                                        ...dest,
                                                        cargaDescarga: "Origen",
                                                        address: cargaEditForm.datosGenerales.client.address,
                                                        destName: cargaEditForm.datosGenerales.client.nombre,
                                                        tel: cargaEditForm.datosGenerales.client.tel1
                                                    }
                                                }
                                                return dest;
                                            })
                                        })
                                    }}>
                                    Usar cliente
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-5">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="name">
                                    Destino
                                    {trucoTipDirecciones()}
                                    {(props.lineaPoblacion.destinations[1] && props.isEdditing === addingOrEditing.edditing) &&
                                        props.lineaPoblacion.destinations[1].address.codigoPostal.length === 0 &&
                                        <small style={{ color: '#de555d' }}>
                                            &nbsp;&nbsp;{"<CÓDIGO POSTAL no encontrado>"}
                                        </small>
                                    }
                                </label>
                                <GoogleAndClientsSuggestions
                                    isEditing={props.isEdditing}
                                    valueNoEditing={(props.lineaPoblacion.destinations[1] && props.lineaPoblacion.destinations[1].address) ? formatedDirection(props.lineaPoblacion.destinations[1].address) : ""}
                                    placeholder="Escriba un lugar para autocompletar..."
                                    key={props.lineaPoblacion.destinations[1] ? props.lineaPoblacion.destinations[1].address.calle : ""}
                                    clientID={cargaEditForm.datosGenerales.client._id ? cargaEditForm.datosGenerales.client._id : ""}
                                    initialAddress={props.lineaPoblacion.destinations[1] ? props.lineaPoblacion.destinations[1].address : inicialiceAddress}
                                    activateClientsDirs={true}
                                    onClick={async (newAddress) => {
                                        const newLinea: lineaViajeCarga = {
                                            ...props.lineaPoblacion,
                                            destinations: props.lineaPoblacion.destinations.map((dest, index) => {
                                                if (index === 1) {
                                                    return {
                                                        ...dest,
                                                        address: newAddress.address,
                                                        destName: newAddress.name,
                                                        tel: newAddress.tel,
                                                        cargaDescarga: "Destino"
                                                    }
                                                }
                                                return dest;
                                            }),
                                            tariffCarga: inicializeLineaViajeCarga.tariffCarga
                                        }
                                        calculateLinea(newLinea, true)
                                    }}
                                    setErrorForm={setErrorOnDestiny}
                                />
                                <div className="text-success">
                                    {showDistances()}
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-2">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="name"> Puerta </label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        name="puerta"
                                        type="text"
                                        value={props.lineaPoblacion.destinations[1] ? props.lineaPoblacion.destinations[1].address.puerta : ""}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                                            props.saveLineaPoblacion({
                                                ...props.lineaPoblacion,
                                                destinations: props.lineaPoblacion.destinations.map((dest, index) => {
                                                    if (index === 1) {
                                                        return {
                                                            ...dest,
                                                            address: {
                                                                ...dest.address,
                                                                puerta: value
                                                            }
                                                        }
                                                    }
                                                    return dest;
                                                }),
                                            })
                                        }}
                                        className="form-control undefined"
                                    />
                                    :
                                    <div></div>
                                }
                            </div>
                        </div>

                        <div className="form-group col-md-3">
                            <label style={{ fontWeight: 'bold' }} htmlFor="name"> Nombre </label>
                            {(props.isEdditing === addingOrEditing.edditing) ?
                                <input
                                    name="nombre"
                                    type="text"
                                    value={props.lineaPoblacion.destinations[1] ? props.lineaPoblacion.destinations[1].destName : ""}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                        props.saveLineaPoblacion({
                                            ...props.lineaPoblacion,
                                            destinations: props.lineaPoblacion.destinations.map((dest, index) => {
                                                if (index === 1) {
                                                    return {
                                                        ...dest,
                                                        destName: value
                                                    }
                                                }
                                                return dest;
                                            })
                                        })
                                    }}
                                    className={validateFormEmpty(props.lineaPoblacion.destinations[1] ? props.lineaPoblacion.destinations[1].destName : "", 2)}
                                />
                                :
                                <div></div>
                            }
                        </div>

                        <div className="form-group col-md-2">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="name"> Teléfono </label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <NumberFormat
                                        format="### ## ## ##"
                                        value={props.lineaPoblacion.destinations[1] ? props.lineaPoblacion.destinations[1].tel : ""}
                                        id="tel1"
                                        isNumericString={true}
                                        className="form-control undefined"
                                        onValueChange={(value) => {
                                            props.saveLineaPoblacion({
                                                ...props.lineaPoblacion,
                                                destinations: props.lineaPoblacion.destinations.map((dest, index) => {
                                                    if (index === 1) {
                                                        return {
                                                            ...dest,
                                                            tel: value.value
                                                        }
                                                    }
                                                    return dest;
                                                }),
                                            })
                                        }} />
                                    : <div>{props.lineaPoblacion.destinations[1].tel}</div>
                                }
                                <div style={{ display: "flex" }}>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row" style={{ marginTop: -40 }}>
                        <div className="form-group col-md-6">
                            <div style={{ display: "flex" }}>
                                <button
                                    className="btn btn-light btn-sm mr-1"
                                    onClick={() => {
                                        props.saveLineaPoblacion({
                                            ...props.lineaPoblacion,
                                            destinations: props.lineaPoblacion.destinations.map((dest, index) => {
                                                if (index === 1) {
                                                    return {
                                                        ...dest,
                                                        cargaDescarga: "Destino",
                                                        address: company.address,
                                                        destName: company.nombre,
                                                        tel: company.tel1
                                                    }
                                                }
                                                return dest;
                                            })
                                        })
                                    }}>
                                    Usar base
                                </button>
                                <button
                                    className="btn btn-light btn-sm"
                                    onClick={() => {
                                        props.saveLineaPoblacion({
                                            ...props.lineaPoblacion,
                                            destinations: props.lineaPoblacion.destinations.map((dest, index) => {
                                                if (index === 1) {
                                                    return {
                                                        ...dest,
                                                        cargaDescarga: "Destino",
                                                        address: cargaEditForm.datosGenerales.client.address,
                                                        destName: cargaEditForm.datosGenerales.client.nombre,
                                                        tel: cargaEditForm.datosGenerales.client.tel1
                                                    }
                                                }
                                                return dest;
                                            })
                                        })
                                    }}>
                                    Usar cliente
                                </button>
                            </div>
                        </div>
                    </div>

                    <br></br>

                    <div className="form-row">
                        <div className="form-group col-md-7">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="name"> Otros conceptos </label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <div className="input-group mb-2">
                                        <input
                                            name="concepto"
                                            type="text"
                                            value={props.lineaPoblacion.concepto}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                props.saveLineaPoblacion({
                                                    ...props.lineaPoblacion,
                                                    concepto: event.target.value.trimStart(),
                                                })
                                            }}
                                            className="form-control"
                                        />
                                    </div>
                                    : <div>{props.lineaPoblacion.concepto}</div>
                                }
                            </div>
                        </div>

                        <div className="form-group col-md-1" style={{ marginTop: '8px' }}>
                        </div>

                        <div className="form-group col-md-1" style={{ marginTop: '8px' }}>
                            <div> Descuento (%):</div>
                            <div style={{ marginTop: "14px" }}> Importe: </div>
                        </div>

                        <div className="form-group col-md-2">
                            <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                suffix={" %"}
                                value={props.lineaPoblacion.descuento}
                                className="form-control undefined"
                                isAllowed={(value: NumberFormatValues) => {
                                    const { floatValue } = value;
                                    const numberValue = floatValue ? floatValue : 0
                                    if (numberValue) {
                                        return numberValue < 100;
                                    } else return true
                                }}
                                onValueChange={(value: NumberFormatValues) => {
                                    const { floatValue } = value;
                                    const numberValue = floatValue ? floatValue : 0
                                    props.saveLineaPoblacion({
                                        ...props.lineaPoblacion,
                                        descuento: numberValue
                                    })
                                    setTempValueDto(numberValue)
                                }}
                            />
                            <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                onValueChange={(value) => {
                                    const porIva = cargaEditForm.datosGenerales.client.porIva > 0 ? cargaEditForm.datosGenerales.client.porIva : company.porIva
                                    let importe = value.floatValue ? value.floatValue : 0
                                    const importeIVA = calculateIva(importe, porIva)
                                    const total = importe + importeIVA
                                    props.saveLineaPoblacion({
                                        ...props.lineaPoblacion,
                                        importe: value.floatValue ? value.floatValue : 0,
                                        iva: importeIVA,
                                        total: total,
                                    })
                                }}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                value={props.lineaPoblacion.importe}
                                className={validateNumberFormEmpty(props.lineaPoblacion.importe, 1)}
                                suffix={" \u20AC"}
                            />
                        </div>

                        <div style={{ marginTop: "18px" }}>
                            <button className="btn btn-info btn-sm left-margin"
                                onClick={async () => {
                                    if (props.lineaPoblacion.destinations[1]) {
                                        calculateLinea({
                                            ...props.lineaPoblacion,
                                        }, true)
                                    }
                                }}>
                                Recalcular
                            </button>
                        </div>

                    </div>

                    <div className="form-row" style={{ marginTop: '20px' }}>
                        <div className="form-group col-md-1">
                            <span> Cobrar Retorno: </span>
                        </div>
                        <div className="form-group col-md-2">
                            {(props.isEdditing === addingOrEditing.edditing) &&
                                <div>
                                    <select
                                        value={props.lineaPoblacion.cobrarRetorno}
                                        className="form-control undefined"
                                        id="panelDeControl"
                                        onChange={(event) => {
                                            calculateLinea({
                                                ...props.lineaPoblacion,
                                                cobrarRetorno: event.target.value
                                            }, false)
                                        }}>
                                        <option defaultChecked={true} value={cobrarRetornoTypes.no}>{cobrarRetornoTypes.no}</option>
                                        <option value={cobrarRetornoTypes.medioViaje}>{cobrarRetornoTypes.medioViaje}</option>
                                        <option value={cobrarRetornoTypes.viajeCompleto}>{cobrarRetornoTypes.viajeCompleto}</option>
                                    </select>
                                </div>
                            }
                        </div>

                        <div className="form-group col-md-1" style={{ marginTop: '8px' }}>
                        </div>

                        <div className="form-group col-md-7">
                            <div>
                                {(props.isEdditing === addingOrEditing.edditing) &&
                                    <div>
                                        {tariffText}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <br></br>

                {props.isEdditing === addingOrEditing.edditing &&
                    <div className="form-row">

                        {/* LIMPIAR FORMULARIO LINEA VIAJE*/}
                        <div className="form-group col-md-6">
                            <button className="btn btn-info btn-sm"
                                onClick={() => {
                                    props.saveLineaPoblacion({
                                        ...inicializeLineaViajeCarga,
                                        destinations: [{ ...inicialiceDestinationsLinea }, { ...inicialiceDestinationsLinea }]
                                    })
                                }}>
                                Limpiar formulario
                            </button>
                        </div>

                        <div className="form-group col-md-6 right">
                            {/* GUARDAR FILA EDITADA A VIAJES*/}
                            {props.lineaPoblacion.id > 0 &&
                                <button className={errorForm === false ? "btn btn-info btn-sm left-margin" : "btn btn-info btn-sm left-margin disabled"}
                                    onClick={() => {
                                        if (errorForm === false) {
                                            props.saveExistingLinea()
                                            props.saveLineaPoblacion({
                                                ...inicializeLineaViajeCarga,
                                                destinations: [{ ...inicialiceDestinationsLinea }, { ...inicialiceDestinationsLinea }]
                                            })
                                        }
                                    }}>
                                    Aplicar cambios
                                </button>
                            }

                            {/* ANADIR FILA A VIAJES*/}
                            <button className={errorForm === false ? "btn btn-success btn-sm left-margin" : "btn btn-success btn-sm left-margin disabled"}
                                onClick={() => {
                                    if (errorForm === false) {
                                        props.addLinea()
                                        props.saveLineaPoblacion({
                                            ...inicializeLineaViajeCarga,
                                            destinations: [{ ...inicialiceDestinationsLinea }, { ...inicialiceDestinationsLinea }]
                                        })
                                    }
                                }}>
                                Añadir fila
                            </button>
                        </div>
                    </div>
                }

            </div>

        </Fragment>

    );
};

export default FormPoblacion;