import React, { Fragment, ReactElement, useEffect, ChangeEvent, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from '../../../ApplicationState';
import {
    cargaCompleta, lineaViajeCarga, inicialiceDestinationsLinea, company, vehicleType, validateAlphaNumeric,
    cargaDescargaEnum, CapitalizefirstLetter, formatedDirection, formatedDirWithName, inicialiceAddress,
    validateFormEmpty, validateNumberFormEmpty, inicializeLineaViajeCarga, inicialiceCargaCompleta,
    cobrarRetornoTypes, destinationsLinea, distancesFromGoogle, addingOrEditing, getInfoToCmrForCarga
} from "aseguisShared";
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import axios from "axios";
import GoogleAndClientsSuggestions from "../../../common/PlacesAutocomplete/GoogleAndClientsSuggestions";
import { UseDebounce } from "../../../common/UseDebounce/UseDebounce";
import { trucoTipDirecciones } from "../../../common/commonInterfaces/commonFunctions";
import ReactTooltip from "react-tooltip";

interface infoToFormDistancia {
    isEdditing: number;
    vehicleType: vehicleType;
    addOrEdit: number,
    saveLineaDistancia: (lineaDistancia: lineaViajeCarga, noCalculate?: boolean) => void
    lineaDistancia: lineaViajeCarga
    saveExistingLinea: () => void
    addLinea: () => void
    tariffText: string
}

function FormDistancia(props: infoToFormDistancia): ReactElement {

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const company: company = useSelector((state: ApplicationState) => state.company.company);
    const cargaEditForm: cargaCompleta = useSelector((state: ApplicationState) => state.cargaCompleta.cargaCompletaEditForm || inicialiceCargaCompleta);

    const [cargaDescarga, setCargaDescarga] = React.useState("");
    const [destinationLine, setDestinationLine] = React.useState(inicialiceDestinationsLinea);
    const [goToGetDistances, setGoToGetDistances] = React.useState(false)
    const [originDistance, setOriginDistance] = React.useState("")
    const [destinationDistance, setDestinationDistance] = React.useState("");
    const [errorForm, setErrorForm] = React.useState(false);
    const [errorLinea, setErrorLinea] = React.useState(false);
    const [errorOnAddress, setErrorOnAddress] = React.useState(false);
    const [eurosKm, setEurosKm] = React.useState(0);

    useEffect(() => {
        const eurosKm = props.lineaDistancia.importe / props.lineaDistancia.totalDistance
        setEurosKm(eurosKm)
    }, [props.lineaDistancia.importe, props.lineaDistancia.totalDistance]);

    const [tempValueDto, setTempValueDto] = useState(0);
    const debouncedValueDto = UseDebounce(tempValueDto.toString(), 400);
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        props.saveLineaDistancia({
            ...props.lineaDistancia,
            descuento: tempValueDto
        })
    }, [debouncedValueDto]);

    useEffect(() => {
        const tieneCarga = props.lineaDistancia.destinations.some(objeto => objeto.cargaDescarga === cargaDescargaEnum.carga);
        const tieneDescarga = props.lineaDistancia.destinations.some(objeto => objeto.cargaDescarga === cargaDescargaEnum.descarga);
        //si hay error en algun sitio, grabamos error general
        if (
            props.lineaDistancia.destinations.length < 2 ||
            props.lineaDistancia.importe <= 0 ||
            !tieneCarga ||
            !tieneDescarga
        ) {
            setErrorForm(true)
        } else {
            setErrorForm(false)
        }
        if (
            destinationLine.cargaDescarga.length < 2 ||
            destinationLine.address.codigoPostal.length === 0 ||
            errorOnAddress === true ||
            destinationLine.destName.length < 2
        ) {
            setErrorLinea(true)
        } else {
            setErrorLinea(false)
        }
    }, [props.lineaDistancia, destinationLine, errorOnAddress]);

    useEffect(() => {
        if (isAppLoading == false && destinationDistance != '' && goToGetDistances) {
            axios.post<distancesFromGoogle>('api/googleSearchDistance/', {
                originAddress: originDistance,
                destinyAddress: destinationDistance
            })
                .then((response) => {
                    if (response.data) {
                        props.saveLineaDistancia({
                            ...props.lineaDistancia,
                            destinationsDistances: [...props.lineaDistancia.destinationsDistances, { ...response.data }],
                            totalDistance: calculateAllDistances(props.lineaDistancia.destinationsDistances, response.data)
                        })
                    }
                })
            setGoToGetDistances(false)
            setOriginDistance("")
            setDestinationDistance("")
        }
    }, [goToGetDistances])

    function calculateAllDistances(distancesToCalculate: distancesFromGoogle[], newDistance?: distancesFromGoogle): number {
        var totalDistance = 0
        distancesToCalculate.map((line, index) => {
            line.rows.map((dist, index) => {
                if (dist.elements[0] && dist.elements[0].distance && dist.elements[0].distance.value) {
                    totalDistance = totalDistance + dist.elements[0].distance.value
                }
            })
        })
        if (newDistance) {
            newDistance.rows.map((newDist, index) => {
                if (newDist.elements[0] && newDist.elements[0].distance && newDist.elements[0]?.distance?.value) {
                    totalDistance = totalDistance + newDist.elements[0]?.distance?.value
                }
            })
        }
        return totalDistance / 1000
    }

    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    const listDestinations: JSX.Element[] = props.lineaDistancia.destinations.map((dest, index) => {
        return (
            <Fragment key={dest.id}>
                <tr className={`table-row`}
                    key={dest.id}>
                    <td onClick={() => {
                        setDestinationLine(dest)
                    }}>
                        {dest.cargaDescarga}
                    </td>
                    <td onClick={() => {
                        setDestinationLine(dest)
                    }}>
                        {formatedDirWithName(dest.address, dest.destName, true)}
                    </td>
                    {index > 0 && props.lineaDistancia.destinationsDistances[index - 1] ?
                        <td onClick={() => {
                            setDestinationLine(dest)
                        }}>
                            <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                value={(props.lineaDistancia.destinationsDistances[index - 1].rows[0]?.elements[0]?.distance?.value / 1000) || 0}
                                className="form-control undefined"
                                renderText={value => <div>{value}</div>}
                                suffix={" km"}
                            />
                        </td>
                        :
                        <td onClick={() => {
                            setDestinationLine(dest)
                        }}>
                        </td>
                    }
                    {index === props.lineaDistancia.destinations.length - 1 ?
                        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}
                            onClick={() => {
                                if (index === 0) {
                                    props.saveLineaDistancia({
                                        ...props.lineaDistancia,
                                        destinations: [],
                                        destinationsDistances: [],
                                        totalDistance: 0
                                    })
                                } else if (index > 0 && props.lineaDistancia.destinationsDistances.length > index - 1) {
                                    const previousDestinationDistance = props.lineaDistancia.destinationsDistances[index - 1];
                                    if (previousDestinationDistance && previousDestinationDistance.originAddresses) {
                                        let newDestDistances = props.lineaDistancia.destinationsDistances.filter(destDis => destDis.originAddresses !== props.lineaDistancia.destinationsDistances[index - 1].originAddresses)
                                        props.saveLineaDistancia({
                                            ...props.lineaDistancia,
                                            destinations: props.lineaDistancia.destinations.filter(destination => destination.id !== dest.id),
                                            destinationsDistances: newDestDistances,
                                            totalDistance: calculateAllDistances(newDestDistances)
                                        })
                                    }
                                } else {
                                    // Maneja el caso donde index - 1 está fuera de rango o el objeto no está definido
                                }
                            }}
                        >
                            <i className="fas fa fa-times"></i>
                        </td>
                        : <td></td>
                    }
                </tr>
            </Fragment>
        )
    });

    return (
        <Fragment>

            <div className="card-body">

                <div>

                    <div className="form-row">

                        <div className="form-group col-md-2">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="name"> Carga / Descarga </label>

                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <div className="input-group mb-2">
                                        <input
                                            name="cargaDescarga"
                                            type="text"
                                            autoComplete={"off"}
                                            value={cargaDescarga}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                const firstPosition = event.target.value.toLowerCase().substring(0, 1)
                                                if (firstPosition === "c") {
                                                    setDestinationLine({
                                                        ...destinationLine,
                                                        cargaDescarga: cargaDescargaEnum.carga
                                                    })
                                                    setCargaDescarga(cargaDescargaEnum.carga)
                                                }
                                                if (firstPosition === "d") {
                                                    setDestinationLine({
                                                        ...destinationLine,
                                                        cargaDescarga: cargaDescargaEnum.descarga
                                                    })
                                                    setCargaDescarga(cargaDescargaEnum.descarga)
                                                }
                                            }}
                                            onFocus={() => {
                                                setCargaDescarga("")
                                            }}
                                            onBlur={() => {
                                                setCargaDescarga(destinationLine.cargaDescarga)
                                            }}
                                            className={validateFormEmpty(destinationLine.cargaDescarga, 2)}
                                        />

                                        {destinationLine.cargaDescarga.length > 0 &&
                                            <div className="input-group-prepend"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setDestinationLine({
                                                        ...destinationLine,
                                                        cargaDescarga: ""
                                                    })
                                                    setCargaDescarga("")
                                                }}
                                            >
                                                <div
                                                    className="input-group-text"
                                                    style={{
                                                        paddingLeft: "2px !important",
                                                        paddingTop: "0px !important",
                                                        paddingBottom: "0px !important",
                                                        paddingRight: "0px !important"
                                                    }}
                                                >
                                                    <i className="fa-sharp fa-solid fa-delete-left"></i>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div></div>
                                }
                            </div>
                        </div>

                        <div className="form-group col-md-4">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="address">
                                    Destino
                                    {trucoTipDirecciones()}
                                    {(props.isEdditing === addingOrEditing.edditing && destinationLine.address.codigoPostal.length === 0) &&
                                        <small style={{ color: '#de555d' }}>
                                            &nbsp;&nbsp;{"<CÓDIGO POSTAL no encontrado>"}
                                        </small>
                                    }
                                </label>
                                <GoogleAndClientsSuggestions
                                    isEditing={props.isEdditing}
                                    valueNoEditing={formatedDirection(destinationLine.address)}
                                    placeholder="Escriba un lugar para autocompletar..."
                                    key={destinationLine.address.calle}
                                    clientID={cargaEditForm.datosGenerales.client._id ? cargaEditForm.datosGenerales.client._id : ""}
                                    initialAddress={destinationLine.address}
                                    activateClientsDirs={true}
                                    onClick={(newAddress) => {
                                        setDestinationLine({
                                            ...destinationLine,
                                            address: newAddress.address,
                                            destName: newAddress.name,
                                            tel: newAddress.tel
                                        })
                                    }}
                                    setErrorForm={setErrorOnAddress}
                                />

                            </div>

                        </div>

                        <div className="form-group col-md-1">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="name"> Piso/Pta </label>

                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        name="puerta"
                                        type="text"
                                        value={destinationLine.address ? destinationLine.address.puerta : ""}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                                            setDestinationLine({
                                                ...destinationLine,
                                                address: {
                                                    ...destinationLine.address,
                                                    puerta: value
                                                }
                                            })
                                        }}
                                        className="form-control undefined"
                                    />
                                    :
                                    <div></div>}

                            </div>
                        </div>

                        <div className="form-group col-md-3">

                            <label style={{ fontWeight: 'bold' }} htmlFor="name"> Nombre </label>

                            {(props.isEdditing === addingOrEditing.edditing) ?
                                <input
                                    name="nombre"
                                    type="text"
                                    value={destinationLine.destName}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                        setDestinationLine({
                                            ...destinationLine,
                                            destName: value
                                        })
                                    }}
                                    className={validateFormEmpty(destinationLine.destName, 2)}
                                />
                                :
                                <div></div>}

                        </div>

                        <div className="form-group col-md-2">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="name"> Teléfono </label>

                                <div style={{ display: "flex" }}>

                                    {(props.isEdditing === addingOrEditing.edditing) ?
                                        <NumberFormat
                                            format="### ## ## ##"
                                            value={destinationLine.tel}
                                            id="tel1"
                                            isNumericString={true}
                                            className="form-control undefined"
                                            onValueChange={(value) => {
                                                setDestinationLine({
                                                    ...destinationLine,
                                                    tel: value.value
                                                })
                                            }} />
                                        : <div>{destinationLine.tel}</div>
                                    }

                                    &nbsp;&nbsp;

                                    <button
                                        className={errorLinea === false ? "btn btn-success btn-green" : "btn btn-success btn-green disabled"}
                                        onClick={() => {
                                            if (errorLinea === false) {
                                                if (formatedDirection(destinationLine.address) !== "") {
                                                    const newLinea = {
                                                        ...props.lineaDistancia,
                                                        destinations: [
                                                            ...props.lineaDistancia.destinations,
                                                            {
                                                                ...destinationLine,
                                                                id: props.lineaDistancia.destinations.length === 0 ? 0 : props.lineaDistancia.destinations[props.lineaDistancia.destinations.length - 1].id + 1,
                                                            }
                                                        ],
                                                    };
                                                    const indexDest = newLinea.destinations.length - 1;
                                                    const cmrInfo = getInfoToCmrForCarga(cargaEditForm, newLinea, indexDest, "init", company);
                                                    newLinea.destinations[indexDest] = {
                                                        ...newLinea.destinations[indexDest],
                                                        cmrInfo: cmrInfo
                                                    };
                                                    props.saveLineaDistancia(newLinea);
                                                }
                                                if (props.lineaDistancia.destinations.length > 0) {
                                                    const originAddress = props.lineaDistancia.destinations[props.lineaDistancia.destinations.length - 1].address
                                                    const origin = formatedDirection(originAddress)
                                                    if (origin !== "") {
                                                        setOriginDistance(origin)
                                                    }
                                                    const destinationAddress = destinationLine.address
                                                    const destination = formatedDirection(destinationAddress)
                                                    if (origin !== "" && destination !== "") {
                                                        setDestinationDistance(destination)
                                                    }

                                                    setGoToGetDistances(true)
                                                }
                                                setDestinationLine({
                                                    ...destinationLine,
                                                    cargaDescarga: "",
                                                    destName: "",
                                                    address: inicialiceAddress,
                                                    tel: "",
                                                    id: 0
                                                })
                                                setCargaDescarga("")
                                            }
                                        }}>
                                        <i className="fas fa fa-plus"></i>
                                    </button>

                                </div>

                            </div>
                        </div>

                        <div className="form-group col-md-6">
                            <div style={{ display: "flex" }}>
                                <button
                                    className="btn btn-light btn-sm"
                                    onClick={() => {
                                        if (destinationLine.cargaDescarga.length > 0) {
                                            let insert = true;
                                            if (props.lineaDistancia.destinations.length > 1) {
                                                const lastAddress = formatedDirection(props.lineaDistancia.destinations[props.lineaDistancia.destinations.length - 1].address);
                                                const secondLastAddress = formatedDirection(props.lineaDistancia.destinations[props.lineaDistancia.destinations.length - 2].address);
                                                if (lastAddress === secondLastAddress && lastAddress === formatedDirection(company.address)) {
                                                    insert = false;
                                                }
                                            }
                                            if (formatedDirection(company.address).length > 0 && insert) {
                                                const newLineaFromBase: destinationsLinea = {
                                                    ...inicialiceDestinationsLinea,
                                                    address: company.address,
                                                    cargaDescarga: destinationLine.cargaDescarga,
                                                    destName: company.razonSocial,
                                                    id: props.lineaDistancia.destinations.length === 0 ? 0 : props.lineaDistancia.destinations[props.lineaDistancia.destinations.length - 1].id + 1,
                                                    tel: company.tel1,
                                                }
                                                props.saveLineaDistancia({
                                                    ...props.lineaDistancia,
                                                    destinations: [...props.lineaDistancia.destinations, { ...newLineaFromBase }],
                                                })
                                                if (props.lineaDistancia.destinations.length > 0) {
                                                    const originAddress = props.lineaDistancia.destinations[props.lineaDistancia.destinations.length - 1].address
                                                    const origin = formatedDirection(originAddress)
                                                    if (origin !== "") {
                                                        setOriginDistance(origin)
                                                    }
                                                    const destinationAddress = newLineaFromBase.address
                                                    const destination = formatedDirection(destinationAddress)
                                                    if (origin !== "" && destination !== "") {
                                                        setDestinationDistance(destination)
                                                    }
                                                    setGoToGetDistances(true)
                                                }
                                            }
                                        }
                                    }}>
                                    Añadir base
                                </button>
                                <button
                                    className="btn btn-light btn-sm ml-3"
                                    onClick={() => {
                                        if (destinationLine.cargaDescarga.length > 0) {
                                            let insert = true;
                                            if (props.lineaDistancia.destinations.length > 1) {
                                                const lastAddress = formatedDirection(props.lineaDistancia.destinations[props.lineaDistancia.destinations.length - 1].address);
                                                const secondLastAddress = formatedDirection(props.lineaDistancia.destinations[props.lineaDistancia.destinations.length - 2].address);
                                                if (lastAddress === secondLastAddress && lastAddress === formatedDirection(cargaEditForm.datosGenerales.client.address)) {
                                                    insert = false;
                                                }
                                            }
                                            if (formatedDirection(cargaEditForm.datosGenerales.client.address).length > 0 && insert) {
                                                const newLineaFromClient: destinationsLinea = {
                                                    ...inicialiceDestinationsLinea,
                                                    address: cargaEditForm.datosGenerales.client.address,
                                                    cargaDescarga: destinationLine.cargaDescarga,
                                                    destName: cargaEditForm.datosGenerales.client.razonSocial,
                                                    id: props.lineaDistancia.destinations.length === 0 ? 0 : props.lineaDistancia.destinations[props.lineaDistancia.destinations.length - 1].id + 1,
                                                    tel: cargaEditForm.datosGenerales.client.tel1,
                                                }
                                                props.saveLineaDistancia({
                                                    ...props.lineaDistancia,
                                                    destinations: [...props.lineaDistancia.destinations, { ...newLineaFromClient }],
                                                })
                                                if (props.lineaDistancia.destinations.length > 0) {
                                                    const originAddress = props.lineaDistancia.destinations[props.lineaDistancia.destinations.length - 1].address
                                                    const origin = formatedDirection(originAddress)
                                                    if (origin !== "") {
                                                        setOriginDistance(origin)
                                                    }
                                                    const destinationAddress = newLineaFromClient.address
                                                    const destination = formatedDirection(destinationAddress)
                                                    if (origin !== "" && destination !== "") {
                                                        setDestinationDistance(destination)
                                                    }
                                                    setGoToGetDistances(true)
                                                }
                                            }
                                        }
                                    }}>
                                    Añadir cliente
                                </button>
                            </div>
                        </div>

                    </div>


                    <div className="table-hover portlet-600 my-3" key={props.isEdditing} style={{ overflowY: "auto" }}>
                        <table className="table-sm table-bordered" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th scope="col">Carga / Descarga</th>
                                    <th scope="col">Dirección</th>
                                    <th scope="col">Distancia</th>
                                    <th style={{ textAlign: 'center', verticalAlign: 'middle' }} scope="col">Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.lineaDistancia.destinations.length > 0 ?
                                    listDestinations
                                    :
                                    <tr className={`table-row`}>
                                        <td>
                                            {"Inserte destinos"}
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                }
                                {props.lineaDistancia.destinations.length > 1 &&
                                    <tr className={`table-row`}>
                                        <td>
                                            {""}
                                        </td>
                                        <td scope="row">
                                            {"Total km:"}
                                        </td>
                                        <td scope="row">
                                            <NumberFormat
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                displayType="text"
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                allowNegative={false}
                                                value={props.lineaDistancia.totalDistance}
                                                className="form-control undefined"
                                                renderText={value => <div>{value}</div>}
                                                suffix={" km"}
                                            />
                                        </td>
                                        <td>
                                            {""}
                                        </td>
                                    </tr>
                                }
                            </tbody>

                        </table>

                    </div>


                    <br></br>

                    <div className="form-row">

                        <div className="form-group col-md-5">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="name"> Otros conceptos </label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <div className="input-group mb-2">
                                        <input
                                            name="concepto"
                                            type="text"
                                            value={props.lineaDistancia.concepto}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                props.saveLineaDistancia({
                                                    ...props.lineaDistancia,
                                                    concepto: event.target.value.trimStart()
                                                }, true)
                                            }}
                                            className="form-control"
                                        />
                                    </div>
                                    : <div>{props.lineaDistancia.concepto}</div>}
                            </div>
                        </div>

                        <div className="form-group col-md-1">
                        </div>


                        <div className="form-group col-md-1">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="name"> € / km </label>
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    displayType="text"
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    allowNegative={false}
                                    value={eurosKm}
                                    className="form-control undefined"
                                    renderText={value =>
                                        <div>
                                            <input
                                                className="form-control undefined"
                                                name="estado"
                                                disabled={true}
                                                type="text"
                                                value={value}
                                            />
                                        </div>
                                    }
                                />
                            </div>
                        </div>

                        <div className="form-group col-md-1">
                        </div>

                        <div className="form-group col-md-1" style={{ marginTop: '8px' }}>
                            <div> Descuento (%):</div>
                            <div style={{ marginTop: "14px" }}> Importe:</div>
                        </div>

                        <div className="form-group col-md-2">
                            <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                suffix={" %"}
                                value={props.lineaDistancia.descuento}
                                className="form-control undefined"
                                isAllowed={(value: NumberFormatValues) => {
                                    const { floatValue } = value;
                                    const numberValue = floatValue ? floatValue : 0
                                    if (numberValue) {
                                        return numberValue < 100;
                                    } else return true
                                }}
                                onValueChange={(value: NumberFormatValues) => {
                                    const { floatValue } = value;
                                    const numberValue = floatValue ? floatValue : 0
                                    props.saveLineaDistancia({
                                        ...props.lineaDistancia,
                                        descuento: numberValue
                                    }, true)
                                    setTempValueDto(numberValue)
                                }}
                            />
                            <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                onValueChange={(value) => {
                                    props.saveLineaDistancia({
                                        ...props.lineaDistancia,
                                        importe: value.floatValue ? value.floatValue : 0
                                    }, true)
                                }}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                value={props.lineaDistancia.importe}
                                className={validateNumberFormEmpty(props.lineaDistancia.importe, 1)}
                                suffix={" \u20AC"}
                            />
                        </div>

                        <div style={{ marginTop: "18px" }}>
                            <button className="btn btn-info left-margin btn-sm"
                                onClick={() => {
                                    props.saveLineaDistancia(props.lineaDistancia)
                                }}>
                                Recalcular
                            </button>
                        </div>
                    </div>


                    <div className="form-row" style={{ marginTop: '20px' }}>
                        <div className="form-group col-md-1" style={{ marginTop: '8px' }}>
                            <span> Cobrar Retorno: </span>
                        </div>
                        <div className="form-group col-md-2">
                            {(props.isEdditing === addingOrEditing.edditing) &&
                                <div>
                                    <select
                                        value={props.lineaDistancia.cobrarRetorno}
                                        className="form-control undefined"
                                        id="panelDeControl"
                                        onChange={(event) => {
                                            props.saveLineaDistancia({
                                                ...props.lineaDistancia,
                                                cobrarRetorno: event.target.value
                                            })
                                        }}>
                                        <option defaultChecked={true}
                                            value={cobrarRetornoTypes.no}>{cobrarRetornoTypes.no}</option>
                                        <option
                                            value={cobrarRetornoTypes.medioViaje}>{cobrarRetornoTypes.medioViaje}</option>
                                        <option
                                            value={cobrarRetornoTypes.viajeCompleto}>{cobrarRetornoTypes.viajeCompleto}</option>
                                    </select>
                                </div>
                            }
                        </div>

                        <div className="form-group col-md-2">
                        </div>

                        <div className="form-group col-md-6">
                            {(props.isEdditing === addingOrEditing.edditing) &&
                                <div>
                                    {props.tariffText}
                                </div>
                            }
                        </div>

                    </div>

                </div>

                <br></br>

                {props.isEdditing === addingOrEditing.edditing &&
                    <div className="form-row">

                        {/* LIMPIAR FORMULARIO LINEA VIAJE*/}
                        <div className="form-group col-md-6">
                            <button className="btn btn-info btn-sm"
                                onClick={() => {
                                    props.saveLineaDistancia({
                                        ...inicializeLineaViajeCarga,
                                        destinations: [],
                                        destinationsDistances: []
                                    })
                                    setDestinationLine(inicialiceDestinationsLinea)
                                }}>
                                Limpiar formulario
                            </button>
                        </div>

                        <div className="form-group col-md-6 right">
                            {/* GUARDAR FILA EDITADA A VIAJES*/}
                            {props.lineaDistancia.id > 0 &&
                                <button
                                    className={errorForm === false ? "btn btn-info btn-sm left-margin" : "btn btn-info btn-sm left-margin disabled"}
                                    onClick={() => {
                                        if (errorForm === false) {
                                            props.saveExistingLinea()
                                            setDestinationLine(inicialiceDestinationsLinea)
                                        }
                                    }}>
                                    Aplicar cambios
                                </button>
                            }

                            {/* ANADIR FILA A VIAJES*/}
                            <button
                                className={errorForm === false ? "btn btn-success btn-sm left-margin" : "btn btn-success btn-sm left-margin disabled"}
                                onClick={() => {
                                    if (errorForm === false) {
                                        props.addLinea()
                                        setDestinationLine(inicialiceDestinationsLinea)
                                    }
                                }}>
                                Añadir fila
                            </button>

                        </div>

                    </div>
                }

            </div>

        </Fragment>

    );
};


export const calculateConceptoDistancia = (line: lineaViajeCarga) => {
    const elements = [];
    let index = 0;
    for (const destination of line.destinations) {
        index += 1;
        const cargaDescarga = destination.cargaDescarga.length > 0 ? destination.cargaDescarga + " " : "";
        elements.push(
            <span key={index}>
                {"- " + cargaDescarga + formatedDirWithName(destination.address, destination.destName, false, true)}
            </span>
        );
        if (index < line.destinations.length) {
            elements.push(<br key={`br-${index}`} />);
        }
    }
    return elements;
};

export default FormDistancia;