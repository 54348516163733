import React, { ChangeEvent, Fragment, ReactElement, useEffect } from "react";
import {
    tariffCargaCompleta, initialVehicleType, getFormattedDate, CapitalizefirstLetter,
    tariffsTypes, formStates, validateAlphaNumeric, validateFormEmpty, validateNumberFormEmpty
} from "aseguisShared"
import NumberFormat from 'react-number-format';
import VehiclesTypesAutocomplete from "../../components/Vehicles/VehiclesSettings/VehiclesTypesAutocomplete";
import Swal from "sweetalert2";

interface infoToCargaTariff {
    allTariff: tariffCargaCompleta[]
    formTariff: tariffCargaCompleta
    setFormTariff: (tariff: tariffCargaCompleta) => void

    from: string
    formState: number
    timestamp: string

    setErrorForm: (value: boolean) => void,
}

function CargaTariffs(props: infoToCargaTariff): ReactElement {

    function onValueChanged(event: ChangeEvent<HTMLInputElement>): void {
        var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
        props.setFormTariff({
            ...props.formTariff,
            description: value
        });
    }

    function thereIsErrorOnTariffs(): boolean {
        for (var tariff of props.formTariff.tariffs) {
            if (tariff.hasta === 0 || tariff.value === 0) {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        if (
            ((props.formState !== formStates.none)) &&
            ((props.formTariff.vehicleType.description.length < 2) ||
                (props.formTariff.description.length < 2) ||
                (props.formTariff.tariffs.length === 0) ||
                (thereIsErrorOnTariffs() === true))
        ) {
            props.setErrorForm(true)
        } else {
            props.setErrorForm(false)
        }
    }, [props.formState, props.formTariff.vehicleType.description, props.formTariff.description, props.formTariff.tariffs.length, thereIsErrorOnTariffs])


    const listTariffs: JSX.Element[] = [...props.allTariff].reverse().map((tariff, index) => {

        if (tariff && props.formTariff && tariff.id === props.formTariff.id && props.formState === formStates.edit) {
            return (
                <tr className="table-row" key={tariff.id} style={{ height: "40px" }}>
                    <td>
                        <VehiclesTypesAutocomplete
                            whatShow="remolques"
                            vehicleTypeSelected={props.formTariff.vehicleType}
                            onClick={(vehiType) => {
                                props.setFormTariff({
                                    ...props.formTariff,
                                    vehicleType: vehiType,
                                })
                            }} />
                    </td>
                    <td><input
                        type="text"
                        onChange={onValueChanged}
                        value={props.formTariff.description}
                        className={validateFormEmpty(props.formTariff.description, 2)}
                        name="description" />
                    </td>
                    <td>
                        <label>{getFormattedDate()}</label>
                    </td>
                </tr>
            )
        } else {
            return (
                <tr className={`table-row ${(tariff && props.formTariff && tariff.id === props.formTariff.id) ? "selected" : ""}`}
                    style={{ height: "40px" }}
                    onClick={() => {
                        if (props.formState === formStates.add) {
                            props.setFormTariff({
                                ...tariff,
                                id: props.allTariff.length + 1,
                                description: "",
                                fecAlta: new Date(),
                                vehicleType: initialVehicleType
                            });
                        } else {
                            props.setFormTariff(tariff);
                        }
                    }}
                    key={tariff.id}>
                    <td scope="row"><b>{tariff.vehicleType.description}</b></td>
                    <td>{tariff.description}</td>
                    <td>{getFormattedDate()}</td>
                </tr>
            )
        }
    });

    const listTariffsForm: JSX.Element[] = [...props.formTariff.tariffs].map((tariff, index) => {
        return (
            <tr
                className="table-row" key={index} style={{ height: "40px" }}>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        decimalScale={0}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        value={tariff.hasta}
                        className="form-control undefined"
                        renderText={value => <div>{value}</div>}
                        suffix={""} />
                </td>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        decimalScale={8}
                        fixedDecimalScale={false}
                        //fixedDecimalScale={true}
                        allowNegative={false}
                        value={tariff.value}
                        className="form-control undefined"
                        renderText={value => <div>{value}</div>}
                        suffix={" \u20AC"} />
                </td>
            </tr>
        )
    });

    const addFormTariff: JSX.Element[] = [...props.formTariff.tariffs].map((tariff, index) => {
        return (
            <tr
                className="table-row"
                style={{ height: "40px" }}
                key={index}>
                <td>
                    <NumberFormat
                        placeholder="0"
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        suffix={" "}
                        value={tariff.hasta === 0 ? "" : tariff.hasta}
                        className={validateNumberFormEmpty(tariff.hasta)}
                        onValueChange={(value) => {
                            props.setFormTariff({
                                ...props.formTariff,
                                tariffs: props.formTariff.tariffs.map((tariff2, index2) => {
                                    if (index2 === index) {
                                        return {
                                            ...tariff2,
                                            hasta: value.floatValue || 0
                                        }
                                    }
                                    return tariff2;
                                }),
                            });
                        }} />
                </td>
                <td>
                    <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={8}
                        fixedDecimalScale={false}
                        allowNegative={false}
                        suffix={" \u20AC"}
                        value={tariff.value}
                        className={validateNumberFormEmpty(tariff.value)}
                        onValueChange={(value) => {
                            props.setFormTariff({
                                ...props.formTariff,
                                tariffs: props.formTariff.tariffs.map((tariff2, index2) => {
                                    if (index2 === index) {
                                        return {
                                            ...tariff2,
                                            value: value.floatValue || 0
                                        }
                                    }
                                    return tariff2;
                                }),
                            });
                        }} />
                </td>
                <td>
                    <button className="btn btn-success btn-red"
                        onClick={() => {
                            props.setFormTariff({
                                ...props.formTariff,
                                tariffs: props.formTariff.tariffs.filter((tariffForm, index2) => index2 !== index)
                            });
                        }}>
                        <i className="fas fa fa-times"></i>
                    </button>
                </td>

            </tr>
        )
    });


    return (

        <Fragment>

            <div className="card-body">
                <div className="row">
                    <div className="form-group col-md-8">
                        <h6 className="mb-2 font-weight-bold text-green">Historial</h6>

                        <div className="table-responsive portlet-700">

                            <table className="table" style={{ height: "684px" }}>

                                <thead className="thead-light">
                                    <tr style={{ height: "40px" }}>
                                        <th scope="col" style={{ zIndex: "2" }}>Tipo vehículo</th>
                                        <th scope="col" style={{ zIndex: "2" }}>Descripción</th>
                                        <th scope="col" style={{ zIndex: "2" }}>Fecha de alta</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {props.formState === formStates.add &&
                                        <tr className="table-row" style={{ height: "40px" }}>
                                            <td>
                                                <VehiclesTypesAutocomplete
                                                    whatShow="remolques"
                                                    vehicleTypeSelected={props.formTariff.vehicleType}
                                                    onClick={(vehiType) => {
                                                        props.setFormTariff({
                                                            ...props.formTariff,
                                                            vehicleType: vehiType,
                                                        })
                                                    }} />
                                            </td>
                                            <td><input
                                                type="text"
                                                onChange={onValueChanged}
                                                value={props.formTariff.description}
                                                className={validateFormEmpty(props.formTariff.description, 2)}
                                                name="description" />
                                            </td>
                                            <td>
                                                <label>{props.timestamp}</label>
                                            </td>
                                        </tr>
                                    }

                                    {(props.allTariff.length > 0 || props.formState === formStates.add || props.formState === formStates.edit) ?
                                        listTariffs
                                        :
                                        <tr className="mt-2">
                                            <td>No existen tarifas</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    }

                                    {/* Creamos una última fila vacía para que no descuadre el "high" del resto de filas */}
                                    <tr className="table-row" style={{ cursor: "default" }}>
                                        <td id="hiddenBorderBottomTD"></td>
                                        <td id="hiddenBorderBottomTD"></td>
                                        <td id="hiddenBorderBottomTD"></td>
                                    </tr>

                                </tbody>

                            </table>
                        </div>
                        <div className="table-responsive portlet-600">
                            <table className="table">
                                <thead className="thead-light">
                                    <tr style={{ height: "62px" }}>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>

                    {((props.formTariff.tariffs.length > 0) || (props.formState === formStates.add) || (props.formState === formStates.edit)) &&
                        <div className="form-group col">

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h6 className="mb-2 font-weight-bold text-green">Valores</h6>
                                {((props.formState === formStates.add || props.formState === formStates.edit) && props.formTariff.tariffs.length > 0) &&
                                    <button
                                        className="btn btn-secondary btn-sm"
                                        onClick={() => {
                                            Swal.fire({
                                                title: "Porcentaje a aumentar tarifa",
                                                input: "number",
                                                inputAttributes: {
                                                    min: "0", // Opcional: establece un mínimo
                                                    max: "100",
                                                    step: "0.01" // Permite decimales en el input
                                                },
                                                showCancelButton: true,
                                                confirmButtonText: "Aumentar",
                                                confirmButtonColor: "#28a745",
                                                showLoaderOnConfirm: true,
                                                preConfirm: (percentageIncrease) => {
                                                    if (percentageIncrease === null || percentageIncrease === "") {
                                                        Swal.showValidationMessage("Por favor ingrese un porcentaje válido");
                                                        return;
                                                    }
                                                    const increaseFactor = 1 + (parseFloat(percentageIncrease) / 100);
                                                    return { increaseFactor: increaseFactor };
                                                },
                                                allowOutsideClick: () => !Swal.isLoading()
                                            }).then((result) => {
                                                if (result.isConfirmed && result.value) {
                                                    props.setFormTariff({
                                                        ...props.formTariff,
                                                        tariffs: props.formTariff.tariffs.map((tariff, index) => {
                                                            return {
                                                                ...tariff,
                                                                value: Math.ceil((tariff.value * result.value.increaseFactor) * 100) / 100
                                                            }
                                                        }),
                                                        minImport: Math.ceil((props.formTariff.minImport * result.value.increaseFactor) * 100) / 100,
                                                        auxImport: Math.ceil((props.formTariff.auxImport * result.value.increaseFactor) * 100) / 100,
                                                    });
                                                    Swal.fire({
                                                        title: `Tarifas aumentadas en un ${result.value.increaseFactor * 100 - 100}%`,
                                                        icon: "success"
                                                    });
                                                }
                                            });
                                        }}
                                    >
                                        <i className="fa fa-plus"></i> Aumentar Tarifas
                                    </button>
                                }
                            </div>


                            <div className="table-responsive portlet-700">
                                <table className="table" style={{ height: "684px" }}>

                                    <thead className="thead-light">

                                        {/* VALOR MINIMO */}

                                        <tr>
                                            <th scope="col"
                                                style={{ verticalAlign: "baseline" }}
                                            >
                                                Valor Mínimo:
                                            </th>
                                            <th
                                                style={{ verticalAlign: "baseline" }}
                                                scope="col"
                                            >
                                                {props.formState !== formStates.none ?
                                                    <NumberFormat
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        allowNegative={false}
                                                        suffix={" \u20AC"}
                                                        value={props.formTariff.minImport}
                                                        className="form-control undefined"
                                                        onValueChange={(value) => {
                                                            props.setFormTariff({
                                                                ...props.formTariff,
                                                                minImport: value.floatValue ? value.floatValue : 0
                                                            });
                                                        }} />
                                                    :
                                                    <NumberFormat
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        displayType="text"
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        allowNegative={false}
                                                        value={props.formTariff.minImport}
                                                        className="form-control undefined"
                                                        renderText={value => <div>{value}</div>}
                                                        suffix={" \u20AC"} />
                                                }
                                            </th>
                                            {(props.formState === formStates.add || props.formState === formStates.edit) && props.formTariff && props.formTariff.tariffs &&
                                                <th scope="col" style={{ verticalAlign: "baseline" }}>
                                                </th>
                                            }
                                        </tr>

                                        <tr style={{ height: "40px" }}>
                                            <th scope="col"
                                                style={{ zIndex: "2" }}>{props.from === "poblacion" ? props.from : "Hasta " + props.from}</th>
                                            <th scope="col" style={{ zIndex: "2" }}>Valor</th>
                                            {(props.formState === formStates.add || props.formState === formStates.edit)
                                                && props.formTariff && props.formTariff.tariffs &&
                                                <th scope="col" style={{ zIndex: "2" }}>Eliminar</th>
                                            }
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {props.formState === formStates.none && props.formTariff && props.formTariff.tariffs &&
                                            listTariffsForm
                                        }

                                        {(props.formState === formStates.add || props.formState === formStates.edit)
                                            && props.formTariff && props.formTariff.tariffs &&
                                            addFormTariff
                                        }

                                        {/* Creamos una última fila vacía para que no descuadre el "high" del resto de filas */}

                                        <tr className="table-row" style={{ cursor: "default" }}>
                                            <td id="hiddenBorderBottomTD"></td>
                                            <td id="hiddenBorderBottomTD"></td>
                                        </tr>

                                    </tbody>

                                </table>

                            </div>


                            {props.from === "km" &&
                                <div className="table">
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr style={{ height: "62px" }}>
                                                <th scope="col">
                                                    <input
                                                        name="clientAddress"
                                                        type="checkbox"
                                                        checked={props.formTariff.multiplicateValue || false}
                                                        className="form-control"
                                                        onChange={() => {
                                                            if (props.formState === formStates.add || props.formState === formStates.edit) {
                                                                props.setFormTariff({
                                                                    ...props.formTariff,
                                                                    multiplicateValue: !props.formTariff.multiplicateValue
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </th>
                                                <th scope="col" style={{ verticalAlign: "inherit" }}>
                                                    <span>
                                                        {"Multiplicar valor por " + props.from}
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            }


                            <div className="table">
                                <table className="table">
                                    {(props.from == tariffsTypes.horas || props.from == 'km') ?
                                        <thead className="thead-light">
                                            <tr style={{ height: "62px" }}>
                                                {props.from === 'km' &&
                                                    <th scope="col">Después, Euro/km:</th>
                                                }

                                                {props.from === tariffsTypes.horas &&
                                                    <th scope="col">Después, Euros/hora:</th>
                                                }

                                                <th scope="col">
                                                    {props.formState !== formStates.none ?
                                                        <NumberFormat
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            allowNegative={false}
                                                            suffix={" \u20AC"}
                                                            value={props.formTariff.auxImport}
                                                            className="form-control undefined"
                                                            onValueChange={(value) => {
                                                                props.setFormTariff({
                                                                    ...props.formTariff,
                                                                    auxImport: value.floatValue ? value.floatValue : 0
                                                                });
                                                            }} />
                                                        :
                                                        <NumberFormat
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            displayType="text"
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            allowNegative={false}
                                                            value={props.formTariff.auxImport}
                                                            className="form-control undefined"
                                                            renderText={value => <div>{value}</div>}
                                                            suffix={" \u20AC"} />
                                                    }
                                                </th>

                                                {(props.formState === formStates.add || props.formState === formStates.edit)
                                                    && props.formTariff && props.formTariff.tariffs &&
                                                    <th scope="col">
                                                        {(props.formState === formStates.add || props.formState === formStates.edit) &&
                                                            <div className="right">
                                                                {/* AÑADIR FILA A TARIFA*/}
                                                                <button className="btn btn-success btn-green"
                                                                    onClick={() => {
                                                                        props.setFormTariff({
                                                                            ...props.formTariff,
                                                                            tariffs: [...props.formTariff.tariffs, {
                                                                                hasta: 0,
                                                                                value: 0
                                                                            }]
                                                                        })

                                                                    }}>
                                                                    <i className="fas fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        }
                                                    </th>
                                                }

                                            </tr>
                                        </thead>
                                        :
                                        <thead>
                                            <tr></tr>
                                        </thead>
                                    }
                                </table>
                            </div>


                        </div>
                    }

                </div>
            </div>


        </Fragment>

    );

}

export default CargaTariffs;