"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalculatelistVehiclesControl = void 0;
const commonFunctions_1 = require("./commonFunctions");
const CalculatelistVehiclesControl = (controlsParams, vehicleControls, remolqueControls) => {
    const vehiclesList = {
        realizados: [],
        noRealizados: [],
        proxDates: []
    };
    controlsParams.map((controlType) => {
        var realizado = false;
        var nextControlDate = new Date();
        var observaciones = "";
        var fecha = new Date(commonFunctions_1.inicialiceDate.year1000);
        vehicleControls.map((controlVehicle) => {
            if (controlVehicle.description === controlType.description) {
                nextControlDate = (0, commonFunctions_1.calculateNextControl)(controlVehicle.fecAlta, controlType.periodicidad);
                observaciones = controlVehicle.observaciones;
                fecha = controlVehicle.fecAlta;
                if (nextControlDate > new Date()) {
                    realizado = true;
                    //esten realizados
                }
            }
        });
        if (remolqueControls && realizado) {
            var realizadoEnRemolque = false;
            remolqueControls.map((controlRemolque) => {
                if (controlRemolque.description === controlType.description) {
                    nextControlDate = (0, commonFunctions_1.calculateNextControl)(controlRemolque.fecAlta, controlType.periodicidad);
                    //observaciones = controlRemolque.observaciones
                    fecha = controlRemolque.fecAlta;
                    if (nextControlDate > new Date()) {
                        realizadoEnRemolque = true;
                    }
                }
            });
            if (realizadoEnRemolque === false) {
                realizado = false;
            }
        }
        if (realizado === false) {
            vehiclesList.noRealizados.push(Object.assign(Object.assign({}, controlType), { observaciones: observaciones, fecAlta: fecha }));
        }
        else {
            vehiclesList.realizados.push(Object.assign(Object.assign({}, controlType), { observaciones: observaciones, fecAlta: fecha }));
            vehiclesList.proxDates.push(nextControlDate);
        }
    });
    return vehiclesList;
};
exports.CalculatelistVehiclesControl = CalculatelistVehiclesControl;
