import React, { Fragment, useEffect, ReactElement, useState, useRef, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from '../../ApplicationState';
import axios from "axios";
import AutoSuggest from "react-autosuggest";
import Match from 'autosuggest-highlight/match';
import Parse from 'autosuggest-highlight/parse';
import {
    inicialiceCargaCompleta, CapitalizefirstLetter, inicializeSupplier, searchedSuppliers,
    validateAlphaNumeric, validateFormEmpty, supplier, inicialiceSearchFilterList
} from 'aseguisShared';

interface infoToSupplierAutocomplete {
    setSupplier: (supplier: supplier) => void
    supplierName: string
    autoFocus?: boolean
    onBlur?: () => void
    onFocus?: () => void
    setErrorForm?: (value: boolean) => void,
}
function SuppliersAutocomplete(props: infoToSupplierAutocomplete): ReactElement {

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const [goToApi, setGoToApi] = useState(false)
    const [inputFocus, setInputFocus] = useState(false)
    const [textToSearch, setTextToSearch] = useState("")
    const [supplierSuggestions, setSupplierSuggestions] = useState<supplier[]>([])
    const [selectedSupplier, setSelectedSupplier] = useState<supplier>(inicialiceCargaCompleta.datosGenerales.supplier)
    const suggestionsContainerRef = useRef<HTMLSpanElement>(null);

    const refFocus = useRef<HTMLInputElement>(null);
    useLayoutEffect(() => {
        if (null !== refFocus.current && props.autoFocus) {
            refFocus.current.focus()
        }
    })

    function onValueChanged(newValue: string): void {
        var value = CapitalizefirstLetter(validateAlphaNumeric(newValue))
        value = value.trimStart()
        setTextToSearch(value)
        if (value.length === 0) {
            setSupplierSuggestions([])
        }
    }

    function renderSuggestion(suggestion: supplier, query: any): JSX.Element {
        const textToHigh = "" + query.query
        const matches = Match(suggestion.nombre.toLowerCase(), textToHigh.toLowerCase(), { insideWords: true });
        const parts = Parse(suggestion.nombre, matches)
        return (
            <span>
                {parts.map((part, index) => {
                    const className = part.highlight ? 'highlight' : '';
                    return (
                        <span className={className} key={index}>{part.text}</span>
                    );
                })}
            </span>
        );
    }

    useEffect(() => {
        if ((goToApi === true) && (isAppLoading === false) && textToSearch !== '') {
            const timeout2 = setTimeout(() => {
                const newFilter = { ...inicialiceSearchFilterList, supplierName: textToSearch }
                axios
                    .post<searchedSuppliers>('api/suppliers/searchFilter', newFilter)
                    .then((response) => {
                        if (response.data && response.data.suppliersResults.length > 0) {
                            setSupplierSuggestions([])
                            if (response.data.suppliersResults.length === 1) {
                                const oneSupplier = response.data.suppliersResults[0]
                                props.setSupplier(oneSupplier)
                                setSelectedSupplier(oneSupplier)
                                setTextToSearch(oneSupplier.nombre)
                            } else {
                                setSupplierSuggestions(response.data.suppliersResults)
                            }
                        }
                        setGoToApi(false)
                    })
                    .catch((error) => {
                        setSupplierSuggestions([])
                    })
            }, 200);
            return () => { clearTimeout(timeout2) }
        }
    }, [textToSearch])

    useEffect(() => {
        //para cuando se hace click en reestablecer
        if (inputFocus === false && props.supplierName !== textToSearch) {
            setTextToSearch(props.supplierName)
        }
    }, [inputFocus, props.supplierName]);

    useEffect(() => {
        if (props.setErrorForm) {
            if (
                (props.supplierName.length < 2)
            ) {
                props.setErrorForm(true)
            } else {
                props.setErrorForm(false)
            }
        }
    }, [props.supplierName])

    return (
        <Fragment>

            <div className="input-group">

                <span ref={suggestionsContainerRef} style={{ flex: "1" }}>
                    <AutoSuggest
                        onSuggestionHighlighted={({ suggestion }) => {
                            if (suggestion && suggestionsContainerRef.current) {
                                const index = supplierSuggestions.findIndex(s => s === suggestion);
                                const suggestionElements = suggestionsContainerRef.current.querySelectorAll('.react-autosuggest__suggestion');
                                const highlightedElement = suggestionElements[index];
                                if (highlightedElement) {
                                    highlightedElement.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'nearest'
                                    });
                                }
                            }
                        }}
                        suggestions={supplierSuggestions}
                        onSuggestionsClearRequested={() =>
                            setSupplierSuggestions([])
                        }
                        onSuggestionsFetchRequested={({ value }) => {
                            setGoToApi(true)
                        }}
                        onSuggestionSelected={(_, { suggestion, suggestionValue }) => {
                            setSelectedSupplier(suggestion)
                            props.setSupplier(suggestion)
                        }}
                        getSuggestionValue={(suggestion) =>
                            suggestion.nombre
                        }
                        renderSuggestion={renderSuggestion}
                        inputProps={{
                            placeholder: "Escriba un proveedor para autocompletar...",
                            value: textToSearch,
                            onChange: (event, { newValue, method }) => {
                                onValueChanged(newValue)
                            },
                            type: "text",
                            name: "dest_search",
                            className: props.setErrorForm ? validateFormEmpty(props.supplierName, 2) : "form-control",
                            autoComplete: "off",
                            onBlur: () => {
                                if (selectedSupplier.nombre.length > 0) {
                                    props.setSupplier(selectedSupplier);
                                } else {
                                    setTextToSearch("")
                                }

                                setInputFocus(false)
                                setGoToApi(false)
                                setSupplierSuggestions([])

                                if (props.onBlur) {
                                    props.onBlur()
                                }
                            },
                            onFocus: () => {
                                setInputFocus(true)
                                setTextToSearch("")
                                if (props.onFocus) {
                                    props.onFocus()
                                }
                            },
                            ref: refFocus
                        }}
                    />
                </span>

                {props.supplierName.trim().length > 0 &&
                    <div className="input-group-prepend"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setSelectedSupplier(inicializeSupplier)
                            props.setSupplier(inicializeSupplier)
                            setTextToSearch("")
                        }}
                    >
                        <div
                            className="input-group-text"
                            style={{
                                paddingLeft: "2px !important",
                                paddingTop: "0px !important",
                                paddingBottom: "0px !important",
                                paddingRight: "0px !important"
                            }}
                        >
                            <i className="fa-sharp fa-solid fa-delete-left"></i>
                        </div>
                    </div>
                }

            </div>

        </Fragment>
    );
};

export default SuppliersAutocomplete;