import React, { Fragment, ReactElement, ChangeEvent, useRef, useLayoutEffect, FocusEvent, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../ApplicationState";
import { invertColor } from "aseguisShared";
import { useLocation } from "react-router";
import { allAppRoutes } from "../commonInterfaces/allAppRoutes";
import { Link } from "react-router-dom";

interface infoToOptionButton {
    route: allAppRoutes;
    text: string;
    linkTo: string;
}
function OptionButton({ route, text, linkTo }: infoToOptionButton): ReactElement {

    const usePathname = useLocation().pathname;

    const primaryColorState: string = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm.primaryColor);
    let [primaryColor, setPrimaryColor] = useState(localStorage.getItem('firstColor') || "");
    const [textColor, setTextColor] = useState("")
    useEffect(() => {
        if (primaryColorState && primaryColorState.length > 0) {
            setPrimaryColor(primaryColorState)
            const colorToText = invertColor(primaryColorState, false)
            setTextColor(colorToText)
        }
    }, [primaryColorState])

    return (
        <div >
            <Link to={linkTo}>
                <button
                    style={{
                        width: '100%',
                        backgroundColor: primaryColor,
                        border: 'none',
                        color: 'white',
                        padding: '8px 20px',
                        textAlign: 'center',
                        textDecoration: 'none',
                        display: 'inline-block',
                        fontSize: '16px',
                        margin: '7px 2px',
                        cursor: 'pointer',
                        borderRadius: '12px',
                        transitionDuration: '0.4s',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    }}
                    className={usePathname.includes(route) ? "btn btn-sm btn-dark" : "btn btn-sm btn-dark disabled"}
                >
                    <span style={{ color: textColor }}> {text} </span>
                </button>
            </Link>
        </div>
    );
};

export default OptionButton;