import React, { ReactElement } from "react";
import { allAppRoutes } from "../commonInterfaces/allAppRoutes";


function PoliticaPrivacidad(): ReactElement {


    return (

        <div className="container"> {/*//super importante el jodido key, sino no recarga*/}
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h5 className="text-gray-900 mb-4" style={{ fontWeight: "bold" }}>Aseguis</h5>
                                            <h1 className="h1 text-gray-900 mb-4" style={{ fontWeight: "bold" }}>Política de privacidad</h1>
                                        </div>

                                        {/*************************************************************************************************************************/}

                                        <br></br>
                                        <h3 className="h3 text-gray-900 mb-4">1. Introducción</h3>
                                        En Aseguis se trabaja para ofrecer al usuario la mejor experiencia posible a través de nuestros productos y servicios.
                                        En algunos casos, puede ser necesario recopilar información para lograrlo.
                                        <br></br><br></br>
                                        Por ello, y a efectos de lo dispuesto en el REGLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO Y DEL CONSEJO de 27 de abril de 2016 (en adelante, "RGPD")
                                        relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos,
                                        y la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (en adelante, "LSSI"),
                                        Aseguis informa al usuario de que, como responsable del tratamiento, incorporará los datos personales facilitados por los usuarios en un fichero automatizado.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">2. Responsable del tratamiento</h3>
                                        Aseguis es la empresa creadora de esta página web, así como la responsable del tratamiento de los datos personales que el usuario comparte con nosotros.
                                        A continuación, se indica la información general de la Empresa:
                                        <br></br><br></br>
                                        <li>Identidad: Aseguis SL</li>
                                        <br></br>
                                        <li>Dirección: Calle manuel de falla 43 sabadell, 08206 Barcelona, España</li>
                                        <br></br>
                                        <li>NIF: 47161494V</li>
                                        <br></br>
                                        <li>Email: aseguis@aseguis.es</li>
                                        <br></br>
                                        Aseguis ha designado un delegado de protección de Datos en su organización.
                                        Si quieres hacer una consulta respecto al tratamiento de tus datos personales, puedes contactar a través del email indicado anteriormente.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">3. Finalidad del tratamiento</h3>
                                        Sus datos personales podrán ser tratados para las siguientes finalidades en atención a las finalidades
                                        concretas para los que usted nos los haya facilitado mediante la cumplimentación de formulario seleccionado
                                        en cada caso:
                                        <br></br><br></br>
                                        Prestar servicios a esta página web, ya sea en calidad de trabajador, proveedor, cliente o cualquier otro
                                        tipo de relación con Aseguis
                                        <br></br><br></br>
                                        Para el Acceso a este sitio web.
                                        La participación implica la asignación de un nombre de usuario invidivudal asociado a una cuenta.
                                        <br></br><br></br>
                                        La atención, en la que se facilitan y se ponen a disposición de Aseguis en los distintos canales de comunicación
                                        <br></br><br></br>
                                        La puesta a disposición de este servicio
                                        <br></br><br></br>
                                        La identificación del usuario para cualquier actividad que se realice en este sitio web.
                                        <br></br><br></br>
                                        Realizar las gestiones administrativas necesarias para el correcto funcionamiento de la organización
                                        <br></br><br></br>
                                        Gestionar cualquier tipo de comunicado de información en el ámbito que la organización crea conveniente.

                                        No trataremos sus datos personales con otra finalizad que las aquí descritas excepto por obligación legal o
                                        requerimiento judicial.

                                        {/*A continuación, se indica los datos personales que se pueden tratar en el sitio web, así como la base legal que justifica dicho
                                        tratamiento de datos.
                                        Formulario “Contacta con nosotros”. Para gestionar dicho formulario se tratarán datos tales como nombre y apellidos, correo electrónico,
                                        teléfono y el texto de mensaje que es opcional.
                                        Registro “ 14 days Free Trial”: para poder gestionar el registro de la plataforma en modalidad free trial, se tratarán datos personales
                                        como nombre y apellidos, teléfono, correo electrónico y contraseña. Existe la opción de realizar el registro, mediante proveedores como
                                        Google, Facebook, cuyos datos personales serán tratados por estos mismos.
                                        Comunicaciones comerciales. Para poder gestionar cualquier comunicación comercial y/o suscripción de newsletter de los productos y
                                        servicios de Aseguis, se tratará el correo electrónico, así como cualquier otro dato personal que sea requerido para poder gestionar
                                        dicho tratamiento.
                                        Atención al Cliente y/o Usuario mediante mensajería instantánea. Para gestionar cualquier tipo de soporte en caso de dudas relativas
                                        a los servicios ofrecidos se tratarán datos tales como el correo electrónico, así como cualquier otra información que decidas comunicar
                                        mediante el chat habilitado.
                                        Formulario de “trabaja con nosotros”. Para gestionar cualquier candidatura laboral se tratarán tus datos tales como nombre y apellidos,
                                        correo electrónico, número de teléfono, el curriculum vitae, así como, de forma opcional, tu fotografía. Existe la opción de gestionar
                                        tu candidatura mediante los proveedores Linkedin e Indeed, cuyos datos personales serán tratados por estos mismos.*/}

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">4. Base legal del tratamiento de tus datos</h3>
                                        La base legal que justifica el tratamiento de los datos mencionados anteriormente es:
                                        <br></br><br></br>
                                        Consentimiento del interesado para los formularios de solicitud de demo, altas en newsletters, para gestionar las candidaturas aplicadas,
                                        registro en los webinars, atención al usuario en el chat y el tratamiento de cookies opcionales aceptadas por el usuario.
                                        <br></br><br></br>
                                        Cumplimiento de obligaciones legales para prevención del fraude, comunicación con Autoridades públicas y reclamaciones de terceros.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">5. Conservación de tus datos personales</h3>
                                        Los datos personales recabados a través de este sitio web serán tratados por Aseguis para las finalidades
                                        en cada caso indicadas, para satisfacer tus necesidades, para cumplir con las obligaciones legales
                                        y, mientras sean necesarios, para gestionar y prestar los servicios solicitados en
                                        cada caso así como para el correcto funcionamiento del servicio. Y, en los supuestos en los que
                                        no haya prestado su previo y expreso consentimiento, hasta que usted nos indique que dejemos de hacerlo.
                                        <br></br><br></br>
                                        Los datos facilitados se conservan durante toda la vigencia de la relación con el servicio.
                                        Posteriormente, quedan bloqueados hasta que transcurran los plazos de conservación aplicables.
                                        <br></br><br></br>
                                        En lo que respecta a los datos que permiten el acceso al sitio web y al uso de las herramientas asociadas,
                                        se conservan mientras la persona interesada no solicite la cancelación de este derecho otorgado por Aseguis
                                        a sus personas relacionadas o hasta que transcurran los plazos de conservación aplicables.
                                        <br></br><br></br>
                                        En cuanto a la posible conservación de datos para el envío de información que a la organización le pueda parecer
                                        de interes, y de acuerdo al perfil generado en base a los intereses que nos haya comunicado, se conservarán
                                        mientras el interesado no revoque el mismo.
                                        <br></br><br></br>
                                        Cuando finalice el periodo indicado, Aseguis conservará los datos personales blqoueados durante el periodo en el que se
                                        puedan derivar responsabilidades legales.
                                        <br></br><br></br>
                                        Se pueden conservar algunos de los Datos Personales para cumplir con las obligaciones legales o regulatorias,
                                        así como para administrar los derechos de Aseguis (por ejemplo, para presentar demandas ante los tribunales) o para fines
                                        estadísticos o históricos.
                                        <br></br><br></br>
                                        Una vez transcurridos los periodos de conservación de tus Datos Personales por mandato legal,
                                        desde Aseguis se procederá a eliminarlos de los sistemas y registros o anonimizarlos para que el usuario ya no pueda ser identificado.
                                        <br></br><br></br>
                                        Aseguis dispone de una Política de Retención de Datos. Para más información, por favor, contacta a través del correo electrónico
                                        aseguis@aseguis.es.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">6. Legitimación del tratamiento, decisiones automatizadas y perfiles.</h3>
                                        Las bases legales para el tratamiento de los datos personales para las finalidades indicadas son las siguientes:
                                        <br></br><br></br>
                                        En lo que respecta a la finalidad relativa a la remisión de información, así como la realización de estadísticas
                                        y encuestas de satisfacción, la base legitimadora es el interés legítimo de Aseguis.
                                        <br></br><br></br>
                                        Para la realización de perfiles básicos cuya única consecuencia es la remisión de información personalizada
                                        en relación con el ámbito formativo que usted haya elegido o en el que se haya matriculado, la base legal
                                        es el interés legítimo de las partes en recibir información sobre lo que Aseguis considere de interés.
                                        Siempre que usted no se oponga a dicho tratamiento y perfilado a través de los canales dispuestos por Aseguis para
                                        tales efectos.
                                        <br></br><br></br>
                                        Para algunos servicios, podemos tratar sus datos utilizando medios automatizados.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">7. Destinatarios a los que se comunican tus datos.</h3>
                                        En algunos casos, solo cuando sea necesario, Aseguis proporcionará datos de los usuarios a terceros.
                                        Sin embargo, los datos nunca se venderán a terceros. Los proveedores de servicios externos con los que que cuenta Aseguis para
                                        proveer los servicios auxiliares para el tratamiento de los datos podrán tener acceso a estos de conformidad con las instrucciones
                                        indicadas por Aseguis y con la única finalidad de ejecutar correctamente la relación contractual que existe entre Aseguis y sus proveedores.
                                        <br></br><br></br>
                                        Para obtener información relativa a los proveedores que acceden a tus datos personales, puedes enviar un correo electrónico a aseguis@aseguis.es
                                        <br></br><br></br>
                                        Aseguis procura garantizar la seguridad de los datos personales cuando se envían fuera de la empresa y se asegura de que los terceros
                                        proveedores de servicios respeten la confidencialidad y dispongan de medidas adecuadas para proteger los datos personales. Estos
                                        terceros tienen la obligación de garantizar que la información sea tratada de acuerdo con la normativa sobre privacidad de datos.
                                        Para cumplir con estas salvaguardas, Aseguis firma con dichos proveedores contratos de encargado del tratamiento de conformidad con
                                        el artículo 28 del RGPD.
                                        <br></br><br></br>
                                        En algunos casos, la ley puede exigir que los datos personales sean revelados a organismos públicos u otras partes, solo se revelará
                                        lo estrictamente necesario para el cumplimiento de dichas obligaciones legales.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">8. Almacenamiento de tus datos personales.</h3>
                                        En general, la información que contiene Aseguis y los datos de carácter personal proporcionados se guardan en los servidores de Aseguis o de proveedores y
                                        empresas encargados del tratamiento de datos que tengan los servidores en el territorio de la Unión Europea.
                                        <br></br><br></br>
                                        No obstante, en caso de que el tratamiento de tus datos signifique una transferencia
                                        internacional de datos fuera del Espacio Económico Europeo (EEE), Aseguis se compromete a implementar las medidas de seguridad necesarias
                                        de acuerdo a las garantías legales previstas en la normativa en vigor y de acuerdo a lo que comuniquen las autoridades en materia de protección de datos en cada momento
                                        para asegurar un nivel adecuado de salvaguarda de dichas transferencias y se asegurará de que ofrecen un nivel de protección adecuado,
                                        ya sea porque cuentan con Normas Corporativas Vinculantes (BCR) o porque hemos suscrito las cláusulas modelo de la Comisión Europea.
                                        <br></br><br></br>
                                        Así mismo, Aseguis, de conformidad con lo dispuesto en el reglamento (UE) 2016/679, de 27 de abril, relativo a la protección
                                        de las personas físicas en lo que respecta al tratamiento de datos personales (en adelante, RGPD), y la Ley 34/2002, de
                                        11 de julio, de servicios de la sociedad de la información y del comercio electrónico (LSSICE), le informa de que
                                        ha implantado las medidas de seguridad necesarias, de índole técnica y organizativa, para garantizar y proteger la
                                        confidencialidad, la integridad y la disponibilidad de los datos tratados.
                                        <br></br><br></br>
                                        Para más información sobre los proveedores de servicios, contacta en el siguiente correo electrónico: aseguis@aseguis.es

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">9. Ejercicio de derechos y como puedes ejercerlos</h3>
                                        Derecho de información: puedes solicitar información sobre aquellos datos personales que se disponen acerca de ti.
                                        <br></br><br></br>
                                        Derecho de rectificación: puedes comunicar cualquier cambio en tus datos personales.
                                        <br></br><br></br>
                                        Derecho de supresión y al olvido: puedes solicitar el bloqueo previo de la supresión de los datos personales.
                                        <br></br><br></br>
                                        Derecho a la limitación del tratamiento: se trata de restringir el tratamiento de tus datos personales.
                                        <br></br><br></br>
                                        Derecho a la portabilidad de los datos: en algunos casos, puede solicitar una copia de los datos personales en un formato estructurado,
                                        de uso común y lectura mecánica para su transmisión a otro responsable del tratamiento.
                                        <br></br><br></br>
                                        Derecho de oposición y toma de decisiones individuales automatizadas: puedes solicitar que no se tomen decisiones basadas únicamente en
                                        el tratamiento automatizado, incluida la elaboración de perfiles, que produzcan efectos jurídicos o afecten significativamente al interesado.
                                        <br></br><br></br>
                                        Derecho a no ser objeto de decisiones individuales automatizadas respecto a las cuales el interesado no haya expresado su
                                        consentimiento (incluida la elaboración de perfiles).
                                        <br></br><br></br>
                                        Derecho a no ser objeto de una decisión basada en el tratamiento automatizado que produzca efectos o afecte significativamente.
                                        <br></br><br></br>
                                        Derecho a retirar el consentimiento prestado en cualquier momento.
                                        <br></br><br></br>
                                        En algunos casos, la solicitud puede ser denegada si pides que se supriman los datos necesarios para el cumplimiento de
                                        obligaciones legales. Asimismo, si tienes una queja sobre el tratamiento de datos, puedes presentar una reclamación
                                        ante la autoridad competente de protección de datos (Agencia Española de Protección de Datos o “AEPD”).

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">10. Responsable de la exactitud y veracidad de los datos facilitados</h3>
                                        El usuario es el único responsable de la veracidad y corrección de los datos incluidos, exonerando a Aseguis de cualquier
                                        responsabilidad al respecto. Los usuarios garantizan y responden, en cualquier caso, de la exactitud, vigencia y autenticidad
                                        de los datos personales facilitados, y se comprometen a mantenerlos debidamente actualizados. El usuario se compromete a
                                        facilitar información completa y correcta en el formulario de registro o suscripción Aseguis se reserva el derecho a rescindir
                                        los servicios contratados con los usuarios, en el caso de que los datos aportados sean falsos, incompletos, inexactos o no
                                        estén actualizados.
                                        <br></br><br></br>
                                        Aseguis no responde de la veracidad de las informaciones que no sean de elaboración propia y de las que se indique otra fuente,
                                        por lo que tampoco asume responsabilidad alguna en cuanto a hipotéticos perjuicios que pudieran originarse por el uso de dicha
                                        información.
                                        <br></br><br></br>
                                        Aseguis se reserva el derecho a actualizar, modificar o eliminar la información contenida en sus páginas web pudiendo incluso
                                        limitar o no permitir el acceso a dicha información a Aseguis de los demandantes sean exonerados.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">11. Medidas de seguridad aplicadas para proteger tus datos personales</h3>
                                        Aseguis, en respuesta a la confianza depositada en nosotros y teniendo en cuenta la importancia en materia de protección y
                                        confidencialidad que requieren los datos personales del usuario, le informamos de que Aseguis ha adoptado los niveles de
                                        seguridad de protección de datos personales legalmente requeridos, y procura instalar aquellos otros medios y medidas
                                        técnicas adicionales a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos
                                        personales facilitados
                                        <br></br><br></br>
                                        Aseguis no se hace responsable de los hipotéticos perjuicios que pudieran derivarse de interferencias, omisiones, interrupciones,
                                        virus informáticos, averías telefónicas o desconexiones en el funcionamiento operativo de este sistema electrónico,
                                        motivadas por causas ajenas a Aseguis de retrasos o bloqueos en el uso del presente sistema electrónico causados por deficiencias
                                        o sobrecargas de líneas telefónicas o sobrecargas en el Centro de Procesos de Datos, en el sistema de Internet o en otros sistemas
                                        electrónicos, así como de los daños que puedan ser causados por terceras personas mediante intromisiones ilegítimas fuera del control
                                        de Aseguis. No obstante, el usuario debe ser consciente de que las medidas de seguridad en Internet no son inexpugnables.
                                        <br></br><br></br>
                                        En particular, el sitio web está asegurado con un certificado TLS con cifrado RSA, lo que significa que toda la información enviada
                                        al sitio web está cifrada con un protocolo HTTPS.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">12. Contacto y modificación de la política de privacidad</h3>
                                        Aseguis se reserva el derecho a modificar la presente política para adaptarla a novedades legislativas o jurisprudenciales.
                                        <br></br><br></br>
                                        Por tanto, esta política de privacidad puede ser modificada. Se recomienda revisarla de vez en cuando.

                                        {/*************************************************************************************************************************/}

                                        <br></br><br></br>
                                        <h3 className="h3 text-gray-900 mb-4">13. Política de cookies</h3>
                                        Puedes consultar toda la información en relación al uso de esta tecnología en nuestra política de cookies:
                                        <p> <a href={allAppRoutes.politicaCookies} target="_blank" >Política de cookies</a> </p>

                                        {/*************************************************************************************************************************/}                                        

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>

    );
};


export default PoliticaPrivacidad;