import { useDispatch, useSelector } from "react-redux";
import React, { Fragment } from "react";
import { ApplicationState } from '../../ApplicationState';
import {
    addOrEditAbono, saveSearchFilterAbono, createAbonoPDF,
    addAbono, saveFormAbono, setFormsAbono, editAbono
} from './AbonosStore';
import AbonosList from "./AbonosList";
import AbonoForm from "./AbonoForm";
import Buttons from "../../common/Buttons/Buttons";
import { searchFilterList, addingOrEditing, UserPermissions, abono, initAbono } from "aseguisShared";
import { GetUserPermissionsFromLocalStorage } from "../../common/commonInterfaces/commonFunctions";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";

const Abonos: React.FC = () => {

    const usePathname = useLocation().pathname;
    const usePath = useHistory()

    const dispatch: React.Dispatch<any> = useDispatch();

    let { permissions } = GetUserPermissionsFromLocalStorage()

    const abonoForm: abono = useSelector((state: ApplicationState) => state.abonos.abonoForm);
    const abonoEditForm: abono = useSelector((state: ApplicationState) => state.abonos.abonoEditForm);
    const addingOrEditAbono: addingOrEditing = useSelector((state: ApplicationState) => state.abonos.addOrEditAbono) || addingOrEditing.none;
    const searchFilterAbonos: searchFilterList = useSelector((state: ApplicationState) => state.abonos.searchFilterAbonos);
    const createAbonoActivated: boolean = useSelector((state: ApplicationState) => state.abonos.activateCreateAbono);

    const [editEnabled, setEditEnabled] = React.useState(addingOrEditing.none);
    const [errorOnForm, setErrorOnForm] = React.useState(false);

    function enableEditAbono(abono: abono): void {
        setEditEnabled(addingOrEditing.none);
        dispatch(setFormsAbono(abono));
        dispatch(addOrEditAbono(addingOrEditing.edditing));
    }

    function addFormAbono(): void {
        setEditEnabled(addingOrEditing.edditing);
        dispatch(setFormsAbono(initAbono));
        dispatch(addOrEditAbono(addingOrEditing.adding));
    }

    function addNewAbono(): void {
        if (errorOnForm === false) {
            setEditEnabled(addingOrEditing.none);
            dispatch(addAbono(abonoEditForm));
        }
    }

    function saveEditedAbono(): void {
        if (errorOnForm === false) {
            setEditEnabled(addingOrEditing.none);
            dispatch(editAbono(abonoEditForm));
        }
    }

    function restoreAbonoEdit(): void {
        dispatch(saveFormAbono(abonoForm));
    }

    return (

        <Fragment>

            <Switch>
                {(permissions.bills !== UserPermissions.deshabilitado) &&
                    <Route exact path={allAppRoutes.abonosList}>
                        <AbonosList
                            onClickAbono={(abonoToUpdate) => {
                                enableEditAbono(abonoToUpdate)
                                usePath.push(allAppRoutes.abonosForm + abonoToUpdate._id);
                            }}
                            addAbono={() => {
                                addFormAbono()
                                usePath.push(allAppRoutes.abonosForm + "new");
                            }}
                            searchAbonosFilter={searchFilterAbonos}
                            saveSearchAbonosFilter={(searchFilterAbonos) => {
                                dispatch(saveSearchFilterAbono(searchFilterAbonos));
                            }}
                        />
                    </Route>
                }
                {permissions.bills !== UserPermissions.deshabilitado &&
                    <Route exact path={allAppRoutes.abonosForm + ":id"}>
                        <AbonoForm
                            isEdditing={editEnabled}
                            setEdditing={setEditEnabled}
                            setErrorForm={setErrorOnForm}
                            errorForm={errorOnForm}
                        />
                    </Route>
                }
                <Redirect from="*" to={allAppRoutes.abonosList} />
            </Switch>

            {/*BOTONES*/}
            {usePathname !== allAppRoutes.abonosList &&
                <Buttons
                    addOrEdit={addingOrEditAbono}
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                    cancel={() => {
                        setEditEnabled(addingOrEditing.none);
                        usePath.replace(allAppRoutes.abonosList);
                    }}
                    save={saveEditedAbono}
                    add={((usePathname.includes(allAppRoutes.abonosForm) && createAbonoActivated) ? addNewAbono : undefined)}
                    from="abono"
                    thereIsList={true}
                    restoreForm={restoreAbonoEdit}
                    disableSaveIfError={(errorOnForm === true) ? true : false}
                    previsualizar={() => {
                        if (errorOnForm === false) {
                            dispatch(createAbonoPDF([abonoEditForm]))
                        }
                    }}
                />
            }

        </Fragment >

    );
};

export default Abonos;