"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialCompany = void 0;
const commonEnums_1 = require("../enums/commonEnums");
const postalCodeFunctions_1 = require("./postalCodeFunctions");
const tariffFunctions_1 = require("./tariffFunctions");
exports.initialCompany = {
    _id: null,
    id: 0,
    nif: "",
    nombre: "",
    webPage: "",
    razonSocial: "",
    address: {
        calle: "",
        numero: "",
        puerta: "",
        localidad: "",
        codigoPostal: "",
        provincia: "",
        pais: "",
        vicinity: "",
        formatedAddress: "",
        otherName: "",
        placeId: "",
        url: "",
        cpMongo: postalCodeFunctions_1.inicializePostalCode
    },
    tel1: "",
    tel2: "",
    email: "",
    ccc: "",
    tariff: tariffFunctions_1.inicialiceTariff,
    porIva: 0,
    dosAlbaranesPorPag: false,
    albaranesPaqEntradaMostrar: commonEnums_1.albaranesPaqueteriaQueMostrar.origenDestino,
    albaranesPaqSalidaMostrar: commonEnums_1.albaranesPaqueteriaQueMostrar.origenDestino,
    billNotDelivered: false,
    showCargasIncidencesOnBills: false,
    showPaqsIncidencesOnBills: false,
    showPaqsReexpsOnBills: false,
    diasFestivos: []
};
