import { useDispatch, useSelector } from "react-redux";
import React, { Fragment } from "react";
import { ApplicationState } from '../../ApplicationState';
import {
    addingOrEditing, UserPermissions, paqueteria, company,
    searchFilterList, loginUsers, getInfoToCmrForPaqueteria, inicialicePaqueteria
} from "aseguisShared"
import {
    addOrEditPaqueteria, addPaqueteria, cancelFormPaqueteria, saveSearchFilterPaqueteria,
    downloadPaqueteriaPDF, editPaqueteria, saveFormPaqueteria,
    setFormsPaqueteria, deleteOnePaqueteria, setHistoryAddListPaq,
} from "./PaqueteriaStore";
import PaqueteriaForm from "./PaqueteriaForm";
import PaqueteriaList from "./PaqueteriaList";
import { GetUserPermissionsFromLocalStorage } from "../../common/commonInterfaces/commonFunctions";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import Buttons from "../../common/Buttons/Buttons";
import PaqueteriaHistoryList from "./PaqueteriaHistoryList";
import { requestOneClient } from "../Clients/ClientsStore";
import NumberFormat from "react-number-format";


const Paqueteria: React.FC = () => {

    const dispatch: React.Dispatch<any> = useDispatch();
    const usePathname = useLocation().pathname;
    const usePath = useHistory()

    let { permissions } = GetUserPermissionsFromLocalStorage()

    const company: company = useSelector((state: ApplicationState) => state.company.company);
    const addedHistory: paqueteria[] = useSelector((state: ApplicationState) => state.paqueteria.historyAddList);
    const PaquetForm: paqueteria = useSelector((state: ApplicationState) => state.paqueteria.paqueteriaForm);
    const PaquetEditForm: paqueteria = useSelector((state: ApplicationState) => state.paqueteria.paqueteriaEditForm);
    const addingOrEditPaqueteria: addingOrEditing = useSelector((state: ApplicationState) => state.paqueteria.addOrEditPaqueteria) || addingOrEditing.none;
    const searchFilterPaqueteria: searchFilterList = useSelector((state: ApplicationState) => state.paqueteria.searchFilterPaqueteria);
    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);

    const [editEnabled, setEditEnabled] = React.useState(addingOrEditing.none);
    const [errorForm, setErrorForm] = React.useState(false);

    const totalKg = addedHistory.reduce((total, item) => total + item.datosTransporte.kilos, 0);
    const totalBultos = addedHistory.reduce((total, item) => total + item.datosTransporte.bultos, 0);

    function EditPaqueteria(Paqueteria: paqueteria): void {
        dispatch(setFormsPaqueteria(Paqueteria));
        dispatch(addOrEditPaqueteria(addingOrEditing.edditing));
        setEditEnabled(addingOrEditing.none);
    }

    function addPaqueteriaForm(): void {
        setEditEnabled(addingOrEditing.edditing);
        dispatch(setFormsPaqueteria({
            ...inicialicePaqueteria,
            datosTransporte: {
                ...inicialicePaqueteria.datosTransporte,
                cmrInfo: getInfoToCmrForPaqueteria(inicialicePaqueteria, "init", company)
            }
        }));
        dispatch(addOrEditPaqueteria(addingOrEditing.adding));
    }

    function addNew(): void {
        if (!errorForm) {
            dispatch(addPaqueteria({
                ...PaquetEditForm,
                datosGenerales: {
                    ...PaquetEditForm.datosGenerales,
                    user: userLogged
                },
            }));
        }
    }

    function saveEdited(): void {
        if (!errorForm) {
            dispatch(editPaqueteria({
                ...PaquetEditForm,
                idPaqRender: null,
                datosEntrega: {
                    ...PaquetEditForm.datosEntrega,
                    fileRelation: []
                },
            }));
            setEditEnabled(addingOrEditing.none);
        }
    }

    function cancelForm(): void {
        setEditEnabled(addingOrEditing.none);
        dispatch(cancelFormPaqueteria());
    }

    function restorePaqueteriaEdit(): void {
        if (PaquetForm.datosGenerales.client._id) {
            requestOneClient(PaquetForm.datosGenerales.client._id)
                .then((client) => {
                    dispatch(saveFormPaqueteria({
                        ...PaquetForm,
                        datosGenerales: {
                            ...PaquetForm.datosGenerales,
                            client: client.data.clientsResults[0]
                        }
                    }));
                })
                .catch((error) => {
                    usePath.push(allAppRoutes.clientsList)
                })
        }
    }

    const deletePaqueteria = () => {
        dispatch(deleteOnePaqueteria(PaquetEditForm));
        setEditEnabled(addingOrEditing.none);
        usePath.replace(allAppRoutes.paqueteriaList);
    }

    return (

        <Fragment>

            <Switch>

                {(permissions.paqueteria !== UserPermissions.deshabilitado) &&
                    <Route exact path={allAppRoutes.paqueteriaList}>
                        <PaqueteriaList
                            onClick={(paqueteriaToUpdate) => {
                                EditPaqueteria(paqueteriaToUpdate)
                                usePath.push(allAppRoutes.paqueteriaForm + paqueteriaToUpdate._id);
                            }}
                            addPaqueteria={() => {
                                addPaqueteriaForm()
                                usePath.push(allAppRoutes.paqueteriaForm + "new");
                            }}
                            searchPaqueteriaFilter={searchFilterPaqueteria}
                            saveSearchPaqueteriaFilter={(searchFilterPaqueteria) => {
                                dispatch(saveSearchFilterPaqueteria(searchFilterPaqueteria));
                            }}
                        />
                    </Route>
                }

                {permissions.paqueteria !== UserPermissions.deshabilitado &&
                    <Route exact path={allAppRoutes.paqueteriaForm + ":id"}>
                        <PaqueteriaForm
                            isEdditing={editEnabled}
                            setEdditing={setEditEnabled}
                            addOrEdit={addingOrEditPaqueteria}
                            setErrorForm={setErrorForm}
                            errorForm={errorForm}
                            add={addNew}
                        />
                    </Route>
                }

                <Redirect from="*" to={allAppRoutes.paqueteriaList} />

            </Switch>


            {/*BOTONES*/}
            {usePathname !== allAppRoutes.paqueteriaList &&
                <Buttons
                    addOrEdit={addingOrEditPaqueteria}
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                    enableModify={true}
                    cancel={() => {
                        cancelForm()
                        usePath.replace(allAppRoutes.paqueteriaList);
                    }}
                    add={addNew}
                    delete={(PaquetEditForm.idBill === 0) ? deletePaqueteria : undefined}
                    deleteText={userLogged.name}
                    save={saveEdited}
                    from="Paquetería"
                    thereIsList={true}
                    restoreForm={restorePaqueteriaEdit}
                    disableSaveIfError={(errorForm === true) ? true : false}
                />
            }


            {(addedHistory.length > 0 && usePathname.includes(allAppRoutes.paqueteriaForm) && addingOrEditPaqueteria === addingOrEditing.adding) &&
                <div className="card shadow mb-4">
                    <div className="header-buttons">
                        <div className="mr-5">
                            <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                displayType="text"
                                decimalScale={8}
                                fixedDecimalScale={false}
                                allowNegative={false}
                                value={totalBultos}
                                className="form-control undefined"
                                renderText={value => <div>{"Total bultos: " + value}</div>}
                            />
                        </div>
                        <div className="mr-5">
                            <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                displayType="text"
                                decimalScale={8}
                                fixedDecimalScale={false}
                                allowNegative={false}
                                value={totalKg}
                                className="form-control undefined"
                                renderText={value => <div>{"Total Kg: " + value}</div>}
                            />
                        </div>
                        <button
                            className="btn btn-info left-margin btn-sm"
                            onClick={() => {
                                var paqueterias: number[] = []
                                for (var val of addedHistory) {
                                    if (val._id) {
                                        paqueterias.push(val.id)
                                    }
                                }
                                dispatch(downloadPaqueteriaPDF(paqueterias, null, ""))
                            }}>
                            Imprimir albaranes
                        </button>
                        <button
                            className="btn btn-secondary left-margin btn-sm"
                            onClick={() => {
                                dispatch(setHistoryAddListPaq([]))
                            }}>
                            Limpiar historial
                        </button>
                    </div>
                    <div className="card-header">
                        <h6 className="m-0 font-weight-bold text-green">{"Historial (" + addedHistory.length + ")"}</h6>
                    </div>
                    <div className="card-body">
                        <PaqueteriaHistoryList
                            isEdditing={editEnabled}
                            addOrEdit={addingOrEditPaqueteria}
                            paqueteriaForm={PaquetForm}
                            paqueteriaEditForm={PaquetEditForm}
                        />
                    </div>
                </div>
            }


        </Fragment >

    );
};

export default Paqueteria;
