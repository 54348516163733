import React, { Fragment, useEffect, ReactElement, useState, useRef, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from '../../ApplicationState';
import axios from "axios";
import AutoSuggest from "react-autosuggest";
import Match from 'autosuggest-highlight/match';
import Parse from 'autosuggest-highlight/parse';
import {
    inicialiceCargaCompleta, inicialiceSearchFilterList, employee, CapitalizefirstLetter,
    validateAlphaNumeric, validateFormEmpty, nombreCompleto, inicialiceEmployee, searchedEmployees
} from 'aseguisShared';

interface infoToEmployeeAutocomplete {
    setEmployee: (employee: employee) => void
    employeeName: string
    autoFocus?: boolean
    onBlur?: () => void
    onFocus?: () => void
    setErrorForm?: (value: boolean) => void,
}

function EmployeesAutocomplete(props: infoToEmployeeAutocomplete): ReactElement {

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const [goToApi, setGoToApi] = useState(false)
    const [inputFocus, setInputFocus] = useState(false)
    const [textToSearch, setTextToSearch] = useState("")
    const [employeesSuggestions, setEmployeesSuggestions] = useState<employee[]>([])
    const [selectedEmployee, setSelectedEmployee] = useState<employee>(inicialiceCargaCompleta.datosGenerales.driver)
    const suggestionsContainerRef = useRef<HTMLSpanElement>(null);

    const refFocus = useRef<HTMLInputElement>(null);
    useLayoutEffect(() => {
        if (null !== refFocus.current && props.autoFocus) {
            refFocus.current.focus()
        }
    })

    function onValueChanged(newValue: string): void {
        var value = CapitalizefirstLetter(validateAlphaNumeric(newValue))
        value = value.trimStart()
        setTextToSearch(value)
        if (value.length === 0) {
            setEmployeesSuggestions([])
        }
    }

    function renderSuggestion(suggestion: employee, query: any): JSX.Element {
        const textToHigh = "" + query.query

        const nombre = nombreCompleto(suggestion)
        const matches = Match(nombre.toLowerCase(), textToHigh.toLowerCase(), { insideWords: true });
        const parts = Parse(nombre, matches)

        return (
            <span>
                {parts.map((part, index) => {
                    const className = part.highlight ? 'highlight' : '';
                    return (
                        <span className={className} key={index}>{part.text}</span>
                    );
                })}
            </span>
        );
    }

    useEffect(() => {
        if ((goToApi === true) && (isAppLoading === false) && textToSearch !== '') {
            const timeout2 = setTimeout(() => {
                const newFilter = { ...inicialiceSearchFilterList, employeeName: textToSearch }
                axios
                    .post<searchedEmployees>('api/employees/searchFilter/', newFilter)
                    .then((response) => {
                        if (response.data) {
                            setEmployeesSuggestions([])
                            if (response.data.employeesResults.length === 1) {
                                const oneEmployee = response.data.employeesResults[0]
                                props.setEmployee(oneEmployee)
                                setSelectedEmployee(oneEmployee)
                                setTextToSearch(nombreCompleto(oneEmployee))
                            } else {
                                setEmployeesSuggestions(response.data.employeesResults)
                            }
                        }
                        setGoToApi(false)
                    })
                    .catch((error) => {
                        setEmployeesSuggestions([])
                    })
            }, 200);
            return () => { clearTimeout(timeout2) }
        }
    }, [textToSearch])

    useEffect(() => {
        //para cuando se hace click en reestablecer
        if (inputFocus === false && props.employeeName !== textToSearch) {
            setTextToSearch(props.employeeName)
        }
    }, [inputFocus, props.employeeName]);

    useEffect(() => {
        if (props.setErrorForm) {
            if (
                (props.employeeName.length < 2)
            ) {
                props.setErrorForm(true)
            } else {
                props.setErrorForm(false)
            }
        }
    }, [props.employeeName])

    return (
        <Fragment>

            <div className="input-group">

                <span ref={suggestionsContainerRef} style={{ flex: "1" }}>
                    <AutoSuggest
                        onSuggestionHighlighted={({ suggestion }) => {
                            if (suggestion && suggestionsContainerRef.current) {
                                const index = employeesSuggestions.findIndex(s => s === suggestion);
                                const suggestionElements = suggestionsContainerRef.current.querySelectorAll('.react-autosuggest__suggestion');
                                const highlightedElement = suggestionElements[index];
                                if (highlightedElement) {
                                    highlightedElement.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'nearest'
                                    });
                                }
                            }
                        }}
                        suggestions={employeesSuggestions}
                        onSuggestionsClearRequested={() =>
                            setEmployeesSuggestions([])
                        }
                        onSuggestionsFetchRequested={({ value }) => {
                            setGoToApi(true)
                        }}
                        onSuggestionSelected={(_, { suggestionValue, suggestion }) => {
                            setSelectedEmployee(suggestion)
                            props.setEmployee(suggestion)
                        }}
                        getSuggestionValue={(suggestion) =>
                            nombreCompleto(suggestion)
                        }
                        renderSuggestion={renderSuggestion}
                        inputProps={{
                            placeholder: "Escriba un empleado para autocompletar...",
                            value: textToSearch,
                            onChange: (event, { newValue, method }) => {
                                onValueChanged(newValue)
                            },
                            type: "text",
                            name: "dest_search",
                            className: props.setErrorForm ? validateFormEmpty(props.employeeName, 2) : "form-control",
                            autoComplete: "off",
                            onBlur: () => {
                                if (selectedEmployee.name.length > 0) {
                                    props.setEmployee(selectedEmployee);
                                } else {
                                    setTextToSearch("")
                                }

                                setInputFocus(false)
                                setGoToApi(false)
                                setEmployeesSuggestions([])

                                if (props.onBlur) {
                                    props.onBlur()
                                }
                            },
                            onFocus: () => {
                                setInputFocus(true)
                                setTextToSearch("")
                                if (props.onFocus) {
                                    props.onFocus()
                                }
                            },
                            ref: refFocus
                        }}
                    />
                </span>

                {props.employeeName.trim().length > 0 &&
                    <div className="input-group-prepend"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setSelectedEmployee(inicialiceEmployee)
                            props.setEmployee(inicialiceEmployee)
                            setTextToSearch("")
                        }}
                    >
                        <div
                            className="input-group-text"
                            style={{
                                paddingLeft: "2px !important",
                                paddingTop: "0px !important",
                                paddingBottom: "0px !important",
                                paddingRight: "0px !important"
                            }}
                        >
                            <i className="fa-sharp fa-solid fa-delete-left"></i>
                        </div>
                    </div>
                }

            </div>

        </Fragment >
    );
};

export default EmployeesAutocomplete;