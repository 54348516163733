import { useLocation } from "react-router-dom";
import { allAppRoutes } from "../commonInterfaces/allAppRoutes";
import { UserPermissions } from "aseguisShared";
import { GetUserPermissionsFromLocalStorage } from "../commonInterfaces/commonFunctions";


export const ButtonEditarPrivate = () => {

    let { permissions } = GetUserPermissionsFromLocalStorage()

    const pathName = useLocation().pathname

    const isModifyEnabled = () => {

        if (

            //compa�ia
            (pathName.includes(allAppRoutes.companyForm) && permissions.compania !== UserPermissions.modificar) ||
            (pathName.includes(allAppRoutes.companyTariffs) && permissions.companiaTariff !== UserPermissions.modificar) ||

            //clientes
            (pathName.includes(allAppRoutes.clientsForm) && permissions.clients !== UserPermissions.modificar) ||
            (pathName.includes(allAppRoutes.clientsList) && permissions.clients !== UserPermissions.modificar) ||
            (pathName.includes(allAppRoutes.clientsTariffs) && permissions.clientsTariffs !== UserPermissions.modificar) ||

            //empleados
            (pathName.includes(allAppRoutes.employeesForm) && permissions.employees !== UserPermissions.modificar) ||
            (pathName.includes(allAppRoutes.employeesList) && permissions.employees !== UserPermissions.modificar) ||

            //proveedores
            (pathName.includes(allAppRoutes.suppliersForm) && permissions.proveedores !== UserPermissions.modificar) ||
            (pathName.includes(allAppRoutes.suppliersList) && permissions.proveedores !== UserPermissions.modificar) ||

            //veh�culos
            (pathName.includes(allAppRoutes.vehiclesForm) && permissions.vehicles !== UserPermissions.modificar) ||
            (pathName.includes(allAppRoutes.vehiclesList) && permissions.vehicles !== UserPermissions.modificar) ||

            //controles veh�culos
            (pathName.includes(allAppRoutes.vehiclesControlsForm) && permissions.vehiclesControls !== UserPermissions.modificar) ||
            (pathName.includes(allAppRoutes.vehiclesControlsList) && permissions.vehiclesControls !== UserPermissions.modificar) ||

            //revisiones reparaciones veh�culos
            (pathName.includes(allAppRoutes.vehiclesReviRepaForm) && permissions.vehiclesReviRepa !== UserPermissions.modificar) ||
            (pathName.includes(allAppRoutes.vehiclesReviRepaList) && permissions.vehiclesReviRepa !== UserPermissions.modificar) ||

            //ajustes
            (pathName.includes(allAppRoutes.settingsVehicleControlsTypes) && permissions.isAdmin !== true) ||
            (pathName.includes(allAppRoutes.settingsVehicleTypes) && permissions.isAdmin !== true) ||
            (pathName.includes(allAppRoutes.settingsSuscription) && permissions.isAdmin !== true) ||

            //grupajes
            (pathName.includes(allAppRoutes.grupajesForm) && permissions.grupajes !== UserPermissions.modificar) ||
            (pathName.includes(allAppRoutes.grupajesList) && permissions.grupajes !== UserPermissions.modificar) ||

            //carga completa
            (pathName.includes(allAppRoutes.cargaCompletaForm) && permissions.cargaCompleta !== UserPermissions.modificar) ||
            (pathName.includes(allAppRoutes.cargaCompletaList) && permissions.cargaCompleta !== UserPermissions.modificar) ||

            //paqueteria
            (pathName.includes(allAppRoutes.paqueteriaForm) && permissions.paqueteria !== UserPermissions.modificar) ||
            (pathName.includes(allAppRoutes.paqueteriaList) && permissions.paqueteria !== UserPermissions.modificar) ||

            //facturas
            (pathName.includes(allAppRoutes.billsAddForm) && permissions.bills !== UserPermissions.modificar) ||
            (pathName.includes(allAppRoutes.billsForm) && permissions.bills !== UserPermissions.modificar) ||
            (pathName.includes(allAppRoutes.billsList) && permissions.bills !== UserPermissions.modificar) ||

            //abonos
            (pathName.includes(allAppRoutes.abonosForm) && permissions.bills !== UserPermissions.modificar) ||
            (pathName.includes(allAppRoutes.abonosList) && permissions.bills !== UserPermissions.modificar)

        ) {

            return false;

        }


        return true
    }


    return {
        isModifyEnabled
    }

}
