import React, { Fragment, ReactElement, useState } from "react";
import { useSelector, } from "react-redux";
import {
    loginUsers, initIncidenceType, initGeoPosition, addingOrEditing, formStates,
    getFormattedDateAndHour, datosEntregaRequest, incidenciasEntregas, initIncidenciasEntregas
} from "aseguisShared"
import { ApplicationState } from "../../ApplicationState";
import PaqueteriaAddIncidenceModal from "./PaqueteriaAddIncidenceModal";
import axios from "axios";
import NumberFormat from "react-number-format";

interface infoToPaqueteriaIncidencesList {
    from: "paq" | "cargas"
    isEdditing: number;
    idForm: string;
    listadoIncidencias: incidenciasEntregas[];
    setListadoIncidencias: (incidenciasEntregas: incidenciasEntregas[]) => void;
}

function PaqueteriaIncidencesList(props: infoToPaqueteriaIncidencesList): ReactElement {

    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);

    const [modalAddIncidence, setModalAddIncidence] = useState(false)
    const [addOrEdit, setAddOrEdit] = useState(formStates.add)
    const [incidenceToEdit, setIncidenceToEdit] = useState(initIncidenciasEntregas)
    const [indexIncidenceToEdit, setIndexIncidenceToEdit] = useState(0)

    const listIncidences: JSX.Element[] = props.listadoIncidencias.map((incidencia, index) => {
        return (
            <tr
                key={index}
                style={{ cursor: 'default' }}
            >
                <td>{getFormattedDateAndHour(incidencia.datosIncidencia.deliveredTime)}</td>
                <td>{incidencia.tipoIncidencia.description}</td>
                <td>{incidencia.tipoIncidencia.observaciones}</td>
                <td>{incidencia.datosIncidencia.whoDeliver.name + " " + incidencia.datosIncidencia.whoDeliver.apellido1}</td>
                {props.from === "paq" &&
                    <td>
                        <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={true}
                            value={incidencia.value}
                            className="form-control undefined"
                            renderText={value => <div>{value}</div>}
                            suffix={" €"}
                        />
                    </td>
                }
                <td>
                    {incidencia.datosIncidencia.fileRelation.length > 0 &&
                        <button className="btn ml-2"
                            onClick={() => {
                                let url = ""
                                if (props.from === "paq") {
                                    url = 'api/paqueteria/getIncidencesFiles/'
                                }
                                if (props.from === "cargas") {
                                    url = 'api/cargaCompleta/getIncidencesFiles/'
                                }
                                const datosEntrega: datosEntregaRequest = {
                                    deliveredTime: incidencia.datosIncidencia.deliveredTime,
                                    geoPosition: initGeoPosition,
                                    qrIdReaded: [incidencia.datosIncidencia.qrIdReaded],
                                    whoDeliver: userLogged,
                                    imagesBase64: [],
                                    markDelivered: false,
                                    tipoIncidencia: initIncidenceType
                                }
                                axios({ method: 'post', responseType: 'blob', url: url, data: { ...datosEntrega } })
                                    .then((data) => {
                                        window.open(URL.createObjectURL(data.data));
                                    })
                                    .catch((error) => {
                                    })
                            }}>
                            <i className="fa-solid fa-paperclip"></i>
                        </button>
                    }
                </td>
                {props.isEdditing === addingOrEditing.edditing &&
                    <td>
                        <button className="btn btn-success btn-blue btn-sm"
                            onClick={() => {
                                setModalAddIncidence(true)
                                setAddOrEdit(formStates.edit)
                                setIncidenceToEdit(incidencia)
                                setIndexIncidenceToEdit(index)
                            }}>
                            <i className="fas fa fa-pen"></i>
                        </button>
                        <button className="btn btn-success btn-red btn-sm"
                            onClick={() => {
                                props.setListadoIncidencias(props.listadoIncidencias.filter((incidence, index2) => index2 !== index));
                            }}>
                            <i className="fas fa fa-times"></i>
                        </button>
                    </td>
                }
            </tr>);
    });

    return (
        <Fragment>

            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                {props.listadoIncidencias.length > 0 &&
                    <div>
                        <label style={{ fontWeight: 'bold' }}>Incidencias</label>
                        {props.isEdditing === addingOrEditing.edditing &&
                            <button className="btn btn-success btn-green btn-sm ml-2"
                                onClick={() => {
                                    setModalAddIncidence(true)
                                    setIncidenceToEdit(initIncidenciasEntregas)
                                    setIndexIncidenceToEdit(0)
                                    setAddOrEdit(formStates.add)
                                }}>
                                <i className="fa fa fa-plus"></i>
                            </button>
                        }
                        <div className="mt-2" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <div key={props.isEdditing}>
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            {/*<th scope="col">Id</th>*/}
                                            <th scope="col">Fecha</th>
                                            <th scope="col">Incidencia</th>
                                            <th scope="col">Observaciones</th>
                                            <th scope="col">Empleado</th>
                                            {props.from === "paq" &&
                                                <th scope="col">Importe</th>
                                            }
                                            <th scope="col">Adjuntos</th>
                                            {props.isEdditing === addingOrEditing.edditing &&
                                                <th scope="col">Acciones</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listIncidences}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }

                {props.listadoIncidencias.length === 0 &&
                    <div>
                        <label style={{ fontWeight: 'bold' }}>No existen incidencias</label>
                        {props.isEdditing === addingOrEditing.edditing &&
                            <button className="btn btn-success btn-green btn-sm ml-2"
                                onClick={() => {
                                    setModalAddIncidence(true)
                                    setIncidenceToEdit(initIncidenciasEntregas)
                                    setIndexIncidenceToEdit(0)
                                    setAddOrEdit(formStates.add)
                                }}>
                                <i className="fa fa fa-plus"></i>
                            </button>
                        }
                    </div>
                }

                {/* MODAL PARA AÑADIR INCIDENCIAS */}
                <PaqueteriaAddIncidenceModal
                    addOrEdit={addOrEdit}
                    from={props.from}
                    modalOpened={modalAddIncidence}
                    setModalOpened={setModalAddIncidence}
                    addIncidence={(incidence) => {
                        const incidenceToAdd: incidenciasEntregas = {
                            ...incidence,
                            datosIncidencia: {
                                ...incidence.datosIncidencia,
                                qrIdReaded: props.idForm,
                                whoDeliver: userLogged
                            },
                        }
                        props.setListadoIncidencias([...props.listadoIncidencias, incidenceToAdd]);
                    }}
                    incidenceToEdit={incidenceToEdit}
                    saveIncidence={(incidence) => {
                        props.setListadoIncidencias(
                            props.listadoIncidencias.map((incidencia, index2) => {
                                if (index2 === indexIncidenceToEdit) {
                                    return incidence;
                                } 
                                return incidencia;
                            })
                        );
                    }}
                />

            </div>

        </Fragment>

    );
};

export default PaqueteriaIncidencesList;
