import { Action, Dispatch } from 'redux';
import { ApplicationState } from '../../ApplicationState';
import axios from 'axios';
import {
    addingOrEditing, initicializeControlVehicle, searchedControlsVehicles,
    controlVehicle, loading, inicialiceSearchFilterList, searchFilterList
} from 'aseguisShared';
import { Notify } from '../../common/Toastify/Notify';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface vehiclesControlState {
    allVehiclesControls: controlVehicle[];
    controlVehicleForm: controlVehicle,
    controlvehicleEditForm: controlVehicle,
    addOrEditControlVehicles: number,
    isloadingControlVehicles: number,
    searchFilterControlVehicles: searchFilterList,
    totalControlVehiclesList: number,
    actualPage: number
    totalPages: number
}

export const initicializeControlVehicleState: vehiclesControlState = {
    allVehiclesControls: [],
    controlVehicleForm: initicializeControlVehicle,
    controlvehicleEditForm: initicializeControlVehicle,
    isloadingControlVehicles: loading.pending,
    addOrEditControlVehicles: addingOrEditing.none,
    searchFilterControlVehicles: inicialiceSearchFilterList,
    totalControlVehiclesList: 0,
    actualPage: 0,
    totalPages: 0
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface saveFormVehicleControlAction {
    type: 'SAVE_FORM_VEHICLE_CONTROL',
    controlVehicle: controlVehicle
}

export interface addEditVehicleControlAction {
    type: 'CHANGE_ADD_EDIT_VEHICLE_CONTROL',
    addOrEdit: number
}

export interface RequestVehicleControlAction {
    type: 'REQUEST_VEHICLE_CONTROL',
}

export interface addReceivedVehicleControlAction {
    type: 'ADD_RECEIVED_VEHICLE_CONTROL',
    controlVehicle: controlVehicle
}

export interface ReceivedAllVehicleControlAction {
    type: 'RECEIVED_ALL_VEHICLE_CONTROL',
    controlVehicles: searchedControlsVehicles
}

export interface saveSearchVehicleControlAction {
    type: 'SAVE_SEARCH_FILTER_VEHICLE_CONTROL',
    searchFilterVehicleControl: searchFilterList
}

export interface setBothFormsVehicleControlAction {
    type: 'SET_BOTH_FORMS_VEHICLE_CONTROL',
    controlVehicles: controlVehicle
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = saveFormVehicleControlAction | RequestVehicleControlAction |
    addReceivedVehicleControlAction | setBothFormsVehicleControlAction |
    ReceivedAllVehicleControlAction | addEditVehicleControlAction | saveSearchVehicleControlAction;


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export function setFormsVehicleControl(controlVehicle: controlVehicle): setBothFormsVehicleControlAction {
    return {
        type: 'SET_BOTH_FORMS_VEHICLE_CONTROL',
        controlVehicles: controlVehicle
    };
}

export function saveFormVehicleControl(controlVehicle: controlVehicle): saveFormVehicleControlAction {
    return {
        type: 'SAVE_FORM_VEHICLE_CONTROL',
        controlVehicle: controlVehicle
    };
}

export function addOrEditVehicleControl(addOrEdit: number): addEditVehicleControlAction {
    return {
        type: 'CHANGE_ADD_EDIT_VEHICLE_CONTROL',
        addOrEdit: addOrEdit
    };
}

export function saveSearchFilterVehicleControl(searchFilterList: searchFilterList): saveSearchVehicleControlAction {
    return {
        type: 'SAVE_SEARCH_FILTER_VEHICLE_CONTROL',
        searchFilterVehicleControl: searchFilterList
    };
}

export function addVehicleControl(controlVehicle: controlVehicle) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: 'REQUEST_VEHICLE_CONTROL' });
        return axios.post('api/vehicles/ControlList/', controlVehicle)
            .then((data) => {
                Notify("Control vehículo creado", "success")
                dispatch({ type: 'ADD_RECEIVED_VEHICLE_CONTROL', controlVehicle: data.data });
            })
            .catch((error) => {
            })
    }
}

export function requestAllVehiclesControls(searchVehiclesControlsFilter: searchFilterList) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        dispatch({ type: 'REQUEST_VEHICLE_CONTROL' });
        return axios.post<searchedControlsVehicles>('api/vehicles/ControlList/SearchFilter/', searchVehiclesControlsFilter)
            .then((data) => {
                dispatch({ type: 'RECEIVED_ALL_VEHICLE_CONTROL', controlVehicles: data.data });
            })
            .catch((error) => {
            })
    }
}

export const requestOneVehicleControl = async (idControl: string) => {
    let searchFilter = { ...inicialiceSearchFilterList, idMongo: idControl }
    return await axios.post<searchedControlsVehicles>('api/vehicles/ControlList/SearchFilter/', searchFilter)
}

export function exportVehiclesControlsToExcel(searchFilter: searchFilterList) {
    return async (dispatch: Dispatch) => {
        return axios({ method: 'post', responseType: 'blob', url: 'api/vehicles/ControlList/createExcel/getList/', data: searchFilter })
            .then((data) => {
                const blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Aseguis_controles_vehiculos.xlsx";
                link.click();
            })
            .catch((error) => {
            })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
function vehiclesControlReducer(state: vehiclesControlState = initicializeControlVehicleState, incomingAction: Action): vehiclesControlState {

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_BOTH_FORMS_VEHICLE_CONTROL': {
            return {
                ...state,
                controlVehicleForm: action.controlVehicles,
                controlvehicleEditForm: action.controlVehicles,
            }
        }
        case 'SAVE_FORM_VEHICLE_CONTROL': {
            return {
                ...state,
                controlvehicleEditForm: action.controlVehicle,
            };
        }
        case 'CHANGE_ADD_EDIT_VEHICLE_CONTROL': {
            return {
                ...state,
                addOrEditControlVehicles: action.addOrEdit
            }
        }
        case 'REQUEST_VEHICLE_CONTROL': {
            return {
                ...state,
                isloadingControlVehicles: loading.pending
            };
        }
        case 'ADD_RECEIVED_VEHICLE_CONTROL': {
            return {
                ...state,
                controlVehicleForm: action.controlVehicle,
                controlvehicleEditForm: action.controlVehicle,
                addOrEditControlVehicles: addingOrEditing.edditing
            };
        }
        case 'RECEIVED_ALL_VEHICLE_CONTROL': {
            return {
                ...state,
                isloadingControlVehicles: loading.done,
                allVehiclesControls: action.controlVehicles.controlsVehiclesResults,
                totalControlVehiclesList: action.controlVehicles.totalResults,
                actualPage: action.controlVehicles.numPage,
                totalPages: action.controlVehicles.totalPages
            };
        }
        case 'SAVE_SEARCH_FILTER_VEHICLE_CONTROL': {
            return {
                ...state,
                searchFilterControlVehicles: action.searchFilterVehicleControl,
                isloadingControlVehicles: loading.pending
            };
        }
        default:
            return state;
    }
};

export default vehiclesControlReducer;