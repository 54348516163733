import React, { Fragment, ReactElement, useEffect, useState } from "react";
import {
    addingOrEditing, UserPermissions, usersTypes, inicialicePermisosUsuario,
    permisosUsuario, suscriptionData
} from "aseguisShared";
import Switch from "react-switch";
import { GetSuscriptionDataFromLocalStorage, GetUserPermissionsFromLocalStorage } from "./commonFunctions";
import ClientsAutocomplete from "../../components/Clients/ClientsAutocomplete";
import EmployeesAutocomplete from "../../components/Employees/EmployeesAutocomplete";
import SupplierAutocomplete from "../../components/Suppliers/SuppliersAutocomplete";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../ApplicationState";

interface infoToPermissions {
    isEdditing: number;
    permisosUsuario: permisosUsuario;
    permisosUsuarioEdit: permisosUsuario;

    associatedId: string;

    nif: string;

    setPermisosUsuarioEdit: (permisosUsuario: permisosUsuario) => void;

    userType: string

    userLoggedType: string
    userLogguedPermissions: permisosUsuario
}

function UserPermissionForm(props: infoToPermissions): ReactElement {

    let { permissions } = GetUserPermissionsFromLocalStorage()
    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);
    const [somethingChanged, setSomethingChanged] = useState(false);
    const [showForm, setShowForm] = useState(false)

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    const checkAllDisabled = () => {
        if (
            props.permisosUsuarioEdit.panel === UserPermissions.deshabilitado &&
            props.permisosUsuarioEdit.compania === UserPermissions.deshabilitado &&
            props.permisosUsuarioEdit.companiaTariff === UserPermissions.deshabilitado &&
            props.permisosUsuarioEdit.clients === UserPermissions.deshabilitado &&
            props.permisosUsuarioEdit.clientsTariffs === UserPermissions.deshabilitado &&
            props.permisosUsuarioEdit.employees === UserPermissions.deshabilitado &&
            props.permisosUsuarioEdit.proveedores === UserPermissions.deshabilitado &&
            props.permisosUsuarioEdit.vehicles === UserPermissions.deshabilitado &&
            props.permisosUsuarioEdit.vehiclesControls === UserPermissions.deshabilitado &&
            props.permisosUsuarioEdit.vehiclesReviRepa === UserPermissions.deshabilitado &&
            props.permisosUsuarioEdit.grupajes === UserPermissions.deshabilitado &&
            props.permisosUsuarioEdit.cargaCompleta === UserPermissions.deshabilitado &&
            props.permisosUsuarioEdit.paqueteria === UserPermissions.deshabilitado &&
            props.permisosUsuarioEdit.bills === UserPermissions.deshabilitado
        ) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if (checkAllDisabled()) {
            props.setPermisosUsuarioEdit({
                ...props.permisosUsuarioEdit,
                accessEnabled: false,
                accessAppMvEnabled: false,
                isAdmin: false
            })
        } else {
            props.setPermisosUsuarioEdit({
                ...props.permisosUsuarioEdit,
                accessEnabled: true,
                companiaTariff: validateCompanyTariff(),
                clientsTariffs: validateclientsTariffs()
            })
        }
        setSomethingChanged(false)
    }, [somethingChanged]);


    const validateCompanyTariff = () => {
        if (props.permisosUsuarioEdit.compania === UserPermissions.deshabilitado) {
            return UserPermissions.deshabilitado
        }
        if (props.permisosUsuarioEdit.compania === UserPermissions.consultar) {
            if (props.permisosUsuarioEdit.companiaTariff === UserPermissions.modificar) {
                return UserPermissions.consultar
            }
        }
        return props.permisosUsuarioEdit.companiaTariff
    }

    const validateclientsTariffs = () => {
        if (props.permisosUsuarioEdit.clients === UserPermissions.deshabilitado) {
            return UserPermissions.deshabilitado
        }
        if (props.permisosUsuarioEdit.clients === UserPermissions.consultar) {
            if (props.permisosUsuarioEdit.clientsTariffs === UserPermissions.modificar) {
                return UserPermissions.consultar
            }
        }
        return props.permisosUsuarioEdit.clientsTariffs
    }

    const getSpanishTranslate = (userType: string) => {
        switch (userType) {
            case usersTypes.client: {
                return "cliente"
            }
            case usersTypes.employee: {
                return "empleado"
            }
            case usersTypes.supplier: {
                return "proveedor"
            }
            default: {
                return ""
            }
        }
    }

    return (

        <Fragment>

            {permissions.isAdmin &&

                <Fragment>

                    {(props.isEdditing === addingOrEditing.edditing) &&

                        <div className="card shadow mb-4">


                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h6 className="m-0 font-weight-bold text-green">Acceso al programa</h6>
                                <div className="headerButtons">
                                    <button className="btn btn-info left-margin btn-sm"
                                        onClick={() => {
                                            setShowForm(!showForm)
                                        }}
                                    >
                                        {showForm ? "Ocultar" : "Mostrar"}
                                    </button>
                                </div>
                            </div>

                            {showForm &&

                                <div className="card-body">


                                    <div className="form-row">

                                        <div className="form-group col-md-6" style={{ alignSelf: 'center' }}>

                                            <label htmlFor="input_password">Copiar permisos de otro {getSpanishTranslate(props.userType)}: </label>

                                            {props.userType === usersTypes.employee &&
                                                <EmployeesAutocomplete
                                                    employeeName={""}
                                                    setEmployee={(employee) => {
                                                        props.setPermisosUsuarioEdit({ ...employee.permisosUsuario, idAssociated: props.permisosUsuarioEdit.idAssociated })
                                                        setSomethingChanged(true)
                                                    }}
                                                />
                                            }

                                            {props.userType === usersTypes.client &&
                                                <ClientsAutocomplete
                                                    clientName={""}
                                                    setClient={(client) => {
                                                        props.setPermisosUsuarioEdit({ ...client.permisosUsuario, idAssociated: props.permisosUsuarioEdit.idAssociated })
                                                        setSomethingChanged(true)
                                                    }}
                                                />
                                            }

                                            {props.userType === usersTypes.supplier &&
                                                <SupplierAutocomplete
                                                    //supplierName={copyPermissionsName}
                                                    supplierName={""}
                                                    setSupplier={(supplier) => {
                                                        props.setPermisosUsuarioEdit({ ...supplier.permisosUsuario, idAssociated: props.permisosUsuarioEdit.idAssociated })
                                                        setSomethingChanged(true)
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>

                                    <div className="form-row mt-2">
                                        <div className="form-group col-md-2">
                                            <div>
                                                <label style={{ fontWeight: 'bold' }} htmlFor="input_password">Acceso WEB</label>
                                                <div>
                                                    <Switch
                                                        onChange={() => {
                                                            if (props.permisosUsuarioEdit.accessEnabled) {
                                                                props.setPermisosUsuarioEdit({
                                                                    ...inicialicePermisosUsuario,
                                                                    accessAppMvEnabled: props.permisosUsuarioEdit.accessAppMvEnabled,
                                                                    // --> IMPORTANTE: si inicializamos el Id de asociado, luego la API no sabrá borrar el usuario al darlo de baja
                                                                    idAssociated: props.permisosUsuarioEdit.idAssociated
                                                                    // --> IMPORTANTE: si inicializamos el Id de asociado, luego la API no sabrá borrar el usuario al darlo de baja
                                                                })
                                                            }
                                                        }}
                                                        checked={props.permisosUsuarioEdit.accessEnabled}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {props.userType === usersTypes.employee &&
                                            <div className="form-group col-md-3">
                                                <div>
                                                    <label style={{ fontWeight: 'bold' }} htmlFor="input_password">Administrador &nbsp;</label>
                                                    <label htmlFor="input_password"> (puede definir permisos)</label>
                                                    <div>
                                                        <Switch
                                                            onChange={() => {
                                                                if (props.permisosUsuarioEdit.isAdmin) {
                                                                    props.setPermisosUsuarioEdit({
                                                                        ...props.permisosUsuarioEdit,
                                                                        isAdmin: false,
                                                                    })
                                                                } else {
                                                                    props.setPermisosUsuarioEdit({
                                                                        ...props.permisosUsuarioEdit,
                                                                        isAdmin: true,
                                                                        accessEnabled: true,
                                                                        accessAppMvEnabled: true,
                                                                        panel: UserPermissions.consultar,
                                                                        compania: UserPermissions.modificar,
                                                                        companiaTariff: UserPermissions.modificar,
                                                                        clients: UserPermissions.modificar,
                                                                        clientsTariffs: UserPermissions.modificar,
                                                                        employees: UserPermissions.modificar,
                                                                        proveedores: UserPermissions.modificar,
                                                                        vehicles: UserPermissions.modificar,
                                                                        vehiclesControls: UserPermissions.modificar,
                                                                        vehiclesReviRepa: UserPermissions.modificar,
                                                                        grupajes: UserPermissions.modificar,
                                                                        cargaCompleta: UserPermissions.modificar,
                                                                        paqueteria: UserPermissions.modificar,
                                                                        bills: UserPermissions.modificar,
                                                                    })
                                                                }
                                                                setSomethingChanged(true)
                                                            }}
                                                            checked={props.permisosUsuarioEdit.isAdmin}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {props.userType === usersTypes.employee &&
                                            <div className="form-group col-md-2">
                                                <div>
                                                    <label style={{ fontWeight: 'bold' }} htmlFor="input_password">Acceso APP movil</label>
                                                    <div>
                                                        <Switch
                                                            onChange={() => {
                                                                props.setPermisosUsuarioEdit({
                                                                    ...props.permisosUsuarioEdit,
                                                                    accessAppMvEnabled: !props.permisosUsuarioEdit.accessAppMvEnabled,
                                                                })
                                                            }}
                                                            checked={props.permisosUsuarioEdit.accessAppMvEnabled}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }


                                    </div>


                                    <label style={{ fontWeight: 'bold' }} htmlFor="input_password">Permisos WEB</label>

                                    {(props.userType === usersTypes.employee || props.userType === usersTypes.supplier) &&
                                        <div className="form-row">
                                            <div className="form-group col-md-2" style={{ textAlign: 'right', alignSelf: 'center' }}>
                                                <label htmlFor="input_password">Panel de control</label>
                                            </div>
                                            <div className="form-group col-md-2" style={{ alignSelf: 'center' }}>
                                                <select
                                                    value={props.permisosUsuarioEdit.panel}
                                                    className="form-control undefined"
                                                    id="panelDeControl"
                                                    onChange={(event) => {
                                                        props.setPermisosUsuarioEdit({
                                                            ...props.permisosUsuarioEdit,
                                                            panel: event.target.value
                                                        })
                                                        setSomethingChanged(true)
                                                    }}>
                                                    <option defaultChecked={true} value={UserPermissions.deshabilitado}>{UserPermissions.deshabilitado}</option>
                                                    <option value={UserPermissions.consultar}>{UserPermissions.consultar}</option>
                                                </select>
                                            </div>
                                        </div>
                                    }

                                    {props.userType === usersTypes.employee &&
                                        <div className="form-row">
                                            <div className="form-group col-md-2" style={{ textAlign: 'right', alignSelf: 'center' }}>
                                                <label htmlFor="input_password">Compañía: </label>
                                            </div>
                                            <div className="form-group col-md-2" style={{ alignSelf: 'center' }}>
                                                <select
                                                    value={props.permisosUsuarioEdit.compania}
                                                    className="form-control undefined"
                                                    id="Compania"
                                                    onChange={(event) => {
                                                        props.setPermisosUsuarioEdit({
                                                            ...props.permisosUsuarioEdit,
                                                            compania: event.target.value
                                                        })
                                                        setSomethingChanged(true)
                                                    }}>
                                                    <option defaultChecked={true} value={UserPermissions.deshabilitado}>{UserPermissions.deshabilitado}</option>
                                                    <option value={UserPermissions.consultar}>{UserPermissions.consultar}</option>
                                                    <option value={UserPermissions.modificar}>{UserPermissions.modificar}</option>
                                                </select>
                                            </div>

                                            <div className="form-group col-md-3" style={{ textAlign: 'right' }}>
                                                <label htmlFor="input_password">Compañía tarifas</label>
                                            </div>
                                            <div className="form-group col-md-2" style={{}}>
                                                <select
                                                    value={props.permisosUsuarioEdit.companiaTariff}
                                                    className="form-control undefined"
                                                    id="CompaniaTariff"
                                                    onChange={(event) => {
                                                        props.setPermisosUsuarioEdit({
                                                            ...props.permisosUsuarioEdit,
                                                            companiaTariff: event.target.value
                                                        })
                                                        setSomethingChanged(true)
                                                    }}>
                                                    <option defaultChecked={true} value={UserPermissions.deshabilitado}>{UserPermissions.deshabilitado}</option>
                                                    <option value={UserPermissions.consultar}>{UserPermissions.consultar}</option>
                                                    <option value={UserPermissions.modificar}>{UserPermissions.modificar}</option>
                                                </select>
                                            </div>

                                        </div>
                                    }

                                    {props.userType === usersTypes.employee &&
                                        <div className="form-row">

                                            <div className="form-group col-md-2" style={{ textAlign: 'right', alignSelf: 'center' }}>
                                                <label htmlFor="input_password">Clientes</label>
                                            </div>
                                            <div className="form-group col-md-2" style={{ alignSelf: 'center' }}>
                                                <select
                                                    value={props.permisosUsuarioEdit.clients}
                                                    className="form-control undefined"
                                                    id="Clientes"
                                                    onChange={(event) => {
                                                        props.setPermisosUsuarioEdit({
                                                            ...props.permisosUsuarioEdit,
                                                            clients: event.target.value
                                                        })
                                                        setSomethingChanged(true)
                                                    }}>
                                                    <option defaultChecked={true} value={UserPermissions.deshabilitado}>{UserPermissions.deshabilitado}</option>
                                                    <option value={UserPermissions.consultar}>{UserPermissions.consultar}</option>
                                                    <option value={UserPermissions.modificar}>{UserPermissions.modificar}</option>
                                                </select>
                                            </div>

                                            <div className="form-group col-md-3" style={{ textAlign: 'right', alignSelf: 'center' }}>
                                                <label htmlFor="input_password">Clientes tarifas</label>
                                            </div>
                                            <div className="form-group col-md-2" style={{ alignSelf: 'center' }}>
                                                <select
                                                    value={props.permisosUsuarioEdit.clientsTariffs}
                                                    className="form-control undefined"
                                                    id="ClientesTariff"
                                                    onChange={(event) => {
                                                        props.setPermisosUsuarioEdit({
                                                            ...props.permisosUsuarioEdit,
                                                            clientsTariffs: event.target.value
                                                        })
                                                        setSomethingChanged(true)
                                                    }}>
                                                    <option defaultChecked={true} value={UserPermissions.deshabilitado}>{UserPermissions.deshabilitado}</option>
                                                    <option value={UserPermissions.consultar}>{UserPermissions.consultar}</option>
                                                    <option value={UserPermissions.modificar}>{UserPermissions.modificar}</option>
                                                </select>
                                            </div>
                                        </div>
                                    }

                                    {props.userType === usersTypes.employee &&
                                        <div className="form-row">
                                            <div className="form-group col-md-2" style={{ textAlign: 'right', alignSelf: 'center' }}>
                                                <label htmlFor="input_password">Empleados</label>
                                            </div>
                                            <div className="form-group col-md-2" style={{ alignSelf: 'center' }}>
                                                <select
                                                    value={props.permisosUsuarioEdit.employees}
                                                    className="form-control undefined"
                                                    id="Empleados"
                                                    onChange={(event) => {
                                                        props.setPermisosUsuarioEdit({
                                                            ...props.permisosUsuarioEdit,
                                                            employees: event.target.value
                                                        })
                                                        setSomethingChanged(true)
                                                    }}>
                                                    <option defaultChecked={true} value={UserPermissions.deshabilitado}>{UserPermissions.deshabilitado}</option>
                                                    <option value={UserPermissions.consultar}>{UserPermissions.consultar}</option>
                                                    <option value={UserPermissions.modificar}>{UserPermissions.modificar}</option>
                                                </select>
                                            </div>
                                        </div>
                                    }

                                    {(props.userType === usersTypes.employee && suscriptionData.proveedoresTransportes) &&
                                        <div className="form-row">
                                            <div className="form-group col-md-2" style={{ textAlign: 'right', alignSelf: 'center' }}>
                                                <label htmlFor="input_password">Proveedores</label>
                                            </div>
                                            <div className="form-group col-md-2" style={{ alignSelf: 'center' }}>
                                                <select
                                                    value={props.permisosUsuarioEdit.proveedores}
                                                    className="form-control undefined"
                                                    id="Proveedores"
                                                    onChange={(event) => {
                                                        props.setPermisosUsuarioEdit({
                                                            ...props.permisosUsuarioEdit,
                                                            proveedores: event.target.value
                                                        })
                                                        setSomethingChanged(true)
                                                    }}>
                                                    <option defaultChecked={true} value={UserPermissions.deshabilitado}>{UserPermissions.deshabilitado}</option>
                                                    <option value={UserPermissions.consultar}>{UserPermissions.consultar}</option>
                                                    <option value={UserPermissions.modificar}>{UserPermissions.modificar}</option>
                                                </select>
                                            </div>
                                        </div>
                                    }

                                    {(props.userType === usersTypes.employee || props.userType === usersTypes.supplier) &&
                                        <div className="form-row">
                                            <div className="form-group col-md-2" style={{ textAlign: 'right', alignSelf: 'center' }}>
                                                <label htmlFor="input_password">Vehículos</label>
                                            </div>
                                            <div className="form-group col-md-2" style={{ alignSelf: 'center' }}>
                                                <select
                                                    value={props.permisosUsuarioEdit.vehicles}
                                                    className="form-control undefined"
                                                    id="Vehículos"
                                                    onChange={(event) => {
                                                        props.setPermisosUsuarioEdit({
                                                            ...props.permisosUsuarioEdit,
                                                            vehicles: event.target.value
                                                        })
                                                        setSomethingChanged(true)
                                                    }}>
                                                    <option defaultChecked={true} value={UserPermissions.deshabilitado}>{UserPermissions.deshabilitado}</option>
                                                    <option value={UserPermissions.consultar}>{UserPermissions.consultar}</option>
                                                    {props.userType === usersTypes.employee &&
                                                        <option value={UserPermissions.modificar}>{UserPermissions.modificar}</option>
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }

                                    {(props.userType === usersTypes.employee || props.userType === usersTypes.supplier) &&
                                        <div className="form-row">

                                            {(suscriptionData.controlesVehiculos) &&
                                                <>
                                                    <div className="form-group col-md-2" style={{ textAlign: 'right', alignSelf: 'center' }}>
                                                        <label htmlFor="input_password">Vehículos Controles</label>
                                                    </div>
                                                    <div className="form-group col-md-2" style={{ alignSelf: 'center' }}>
                                                        <select
                                                            value={props.permisosUsuarioEdit.vehiclesControls}
                                                            className="form-control undefined"
                                                            id="VehículosControles"
                                                            onChange={(event) => {
                                                                props.setPermisosUsuarioEdit({
                                                                    ...props.permisosUsuarioEdit,
                                                                    vehiclesControls: event.target.value
                                                                })
                                                                setSomethingChanged(true)
                                                            }}>
                                                            <option defaultChecked={true} value={UserPermissions.deshabilitado}>{UserPermissions.deshabilitado}</option>
                                                            <option value={UserPermissions.consultar}>{UserPermissions.consultar}</option>
                                                            <option value={UserPermissions.modificar}>{UserPermissions.modificar}</option>
                                                        </select>
                                                    </div>
                                                </>
                                            }

                                            {(suscriptionData.reviRepa) &&
                                                <>
                                                    <div className="form-group col-md-3" style={{ textAlign: 'right', alignSelf: 'center' }}>
                                                        <label htmlFor="input_password">Vehículos Revisiones y reparaciones</label>
                                                    </div>
                                                    <div className="form-group col-md-2" style={{ alignSelf: 'center' }}>
                                                        <select
                                                            value={props.permisosUsuarioEdit.vehiclesReviRepa}
                                                            className="form-control undefined"
                                                            id="VehículosRevisionesRepa"
                                                            onChange={(event) => {
                                                                props.setPermisosUsuarioEdit({
                                                                    ...props.permisosUsuarioEdit,
                                                                    vehiclesReviRepa: event.target.value
                                                                })
                                                                setSomethingChanged(true)
                                                            }}>
                                                            <option defaultChecked={true} value={UserPermissions.deshabilitado}>{UserPermissions.deshabilitado}</option>
                                                            <option value={UserPermissions.consultar}>{UserPermissions.consultar}</option>
                                                            <option value={UserPermissions.modificar}>{UserPermissions.modificar}</option>
                                                        </select>
                                                    </div>
                                                </>
                                            }

                                        </div>
                                    }

                                    <div className="form-row">
                                        <div className="form-group col-md-2" style={{ textAlign: 'right', alignSelf: 'center' }}>
                                            <label htmlFor="input_password">Carga Completa</label>
                                        </div>
                                        <div className="form-group col-md-2" style={{ alignSelf: 'center' }}>
                                            <select
                                                value={props.permisosUsuarioEdit.cargaCompleta}
                                                className="form-control undefined"
                                                id="CargaCompleta"
                                                onChange={(event) => {
                                                    props.setPermisosUsuarioEdit({
                                                        ...props.permisosUsuarioEdit,
                                                        cargaCompleta: event.target.value
                                                    })
                                                    setSomethingChanged(true)
                                                }}>
                                                <option defaultChecked={true} value={UserPermissions.deshabilitado}>{UserPermissions.deshabilitado}</option>
                                                <option value={UserPermissions.consultar}>{UserPermissions.consultar}</option>

                                                {props.userType === usersTypes.employee &&
                                                    <option value={UserPermissions.modificar}>{UserPermissions.modificar}</option>
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    {(suscriptionData.paqueteria) &&
                                        <div className="form-row">
                                            <div className="form-group col-md-2" style={{ textAlign: 'right', alignSelf: 'center' }}>
                                                <label htmlFor="input_password">Paquetería</label>
                                            </div>
                                            <div className="form-group col-md-2" style={{ alignSelf: 'center' }}>
                                                <select
                                                    value={props.permisosUsuarioEdit.paqueteria}
                                                    className="form-control undefined"
                                                    id="Paqueteria"
                                                    onChange={(event) => {
                                                        props.setPermisosUsuarioEdit({
                                                            ...props.permisosUsuarioEdit,
                                                            paqueteria: event.target.value
                                                        })
                                                        setSomethingChanged(true)
                                                    }}>
                                                    <option defaultChecked={true} value={UserPermissions.deshabilitado}>{UserPermissions.deshabilitado}</option>
                                                    <option value={UserPermissions.consultar}>{UserPermissions.consultar}</option>

                                                    {props.userType === usersTypes.employee &&
                                                        <option value={UserPermissions.modificar}>{UserPermissions.modificar}</option>
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }


                                    {(props.userType === usersTypes.employee || props.userType === usersTypes.client) &&
                                        <div className="form-row">
                                            <div className="form-group col-md-2" style={{ textAlign: 'right', alignSelf: 'center' }}>
                                                <label htmlFor="input_password">Facturas</label>
                                            </div>
                                            <div className="form-group col-md-2" style={{ alignSelf: 'center' }}>
                                                <select
                                                    value={props.permisosUsuarioEdit.bills}
                                                    className="form-control undefined"
                                                    id="Facturas"
                                                    onChange={(event) => {
                                                        props.setPermisosUsuarioEdit({
                                                            ...props.permisosUsuarioEdit,
                                                            bills: event.target.value
                                                        })
                                                        setSomethingChanged(true)
                                                    }}>
                                                    <option defaultChecked={true} value={UserPermissions.deshabilitado}>{UserPermissions.deshabilitado}</option>
                                                    <option value={UserPermissions.consultar}>{UserPermissions.consultar}</option>

                                                    {props.userType === usersTypes.employee &&
                                                        <option value={UserPermissions.modificar}>{UserPermissions.modificar}</option>
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }

                                </div>
                            }
                        </div>
                    }


                    { /******************************************************************************************************/}


                    {(props.isEdditing !== addingOrEditing.edditing) &&
                        <div className="card shadow mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h6 className="m-0 font-weight-bold text-green">Acceso al programa</h6>
                                <div className="headerButtons">
                                    <button className="btn btn-info left-margin btn-sm"
                                        onClick={() => {
                                            setShowForm(!showForm)
                                        }}
                                    >
                                        {showForm ? "Ocultar" : "Mostrar"}
                                    </button>
                                </div>
                            </div>

                            {showForm &&

                                <div className="card-body">

                                    <div className="form-row">

                                        <div className="form-group col-md-2">
                                            <div>
                                                <label style={{ fontWeight: 'bold' }} htmlFor="input_password">Acceso WEB</label>
                                                <div>{props.permisosUsuario.accessEnabled ? "Habilitado" : "Deshabilitado"}</div>
                                            </div>
                                        </div>

                                        {props.userType === usersTypes.employee &&
                                            <div className="form-group col-md-2">
                                                <div>
                                                    <label style={{ fontWeight: 'bold' }} htmlFor="input_password">Administrador</label>
                                                    <div>{props.permisosUsuario.isAdmin ? "Habilitado" : "Deshabilitado"}</div>
                                                </div>
                                            </div>
                                        }

                                        {props.userType === usersTypes.employee &&
                                            <div className="form-group col-md-2">
                                                <div>
                                                    <label style={{ fontWeight: 'bold' }} htmlFor="input_password">Acceso APP movil</label>
                                                    <div>{props.permisosUsuario.accessAppMvEnabled ? "Habilitado" : "Deshabilitado"}</div>
                                                </div>
                                            </div>
                                        }

                                    </div>

                                    <label style={{ fontWeight: 'bold' }} htmlFor="input_password">Permisos WEB</label>

                                    {(props.userType === usersTypes.employee || props.userType === usersTypes.supplier) &&
                                        <div className="form-row">
                                            <div className="form-group col-md-3">
                                                <div>Panel de control: {props.permisosUsuario.panel}</div>
                                            </div>
                                        </div>
                                    }

                                    {(props.userType === usersTypes.employee) &&
                                        <div className="form-row">
                                            <div className="form-group col-md-3">
                                                <div>Compañia: {props.permisosUsuario.compania}</div>
                                            </div>

                                            <div className="form-group col-md-3">
                                                <div>Compañia tarifas: {props.permisosUsuario.companiaTariff}</div>
                                            </div>
                                        </div>
                                    }

                                    {(props.userType === usersTypes.employee) &&
                                        <div className="form-row">
                                            <div className="form-group col-md-3">
                                                <div>Clientes: {props.permisosUsuario.clients}</div>
                                            </div>

                                            <div className="form-group col-md-3">
                                                <div>Clientes tarifas: {props.permisosUsuario.clientsTariffs}</div>
                                            </div>
                                        </div>
                                    }

                                    {(props.userType === usersTypes.employee) &&
                                        <div className="form-row">
                                            <div className="form-group col-md-3">
                                                <div>Empleados: {props.permisosUsuario.employees}</div>
                                            </div>
                                        </div>
                                    }

                                    {(props.userType === usersTypes.employee && suscriptionData.proveedoresTransportes) &&
                                        <div className="form-row">
                                            <div className="form-group col-md-3">
                                                <div>Proveedores: {props.permisosUsuario.proveedores}</div>
                                            </div>
                                        </div>
                                    }

                                    {(props.userType === usersTypes.employee || props.userType === usersTypes.supplier) &&
                                        <div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3">
                                                    <div>Vehículos: {props.permisosUsuario.vehicles}</div>
                                                </div>
                                            </div>
                                            <div className="form-row">

                                                {(suscriptionData.controlesVehiculos) &&
                                                    <div className="form-group col-md-3">
                                                        <div>Vehículos controles: {props.permisosUsuario.vehiclesControls}</div>
                                                    </div>
                                                }

                                                {(suscriptionData.reviRepa) &&
                                                    <div className="form-group col-md-4">
                                                        <div>Vehículos revisiones y reparaciones: {props.permisosUsuario.vehiclesReviRepa}</div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    }

                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <div>Carga completa: {props.permisosUsuario.cargaCompleta}</div>
                                        </div>
                                    </div>

                                    {(suscriptionData.paqueteria) &&
                                        <div className="form-row">
                                            <div className="form-group col-md-3">
                                                <div>Paquetería: {props.permisosUsuario.paqueteria}</div>
                                            </div>
                                        </div>
                                    }

                                    {(props.userType === usersTypes.employee || props.userType === usersTypes.client) &&
                                        <div className="form-row">
                                            <div className="form-group col-md-3">
                                                <div>Facturas: {props.permisosUsuario.bills}</div>
                                            </div>
                                        </div>
                                    }

                                </div>

                            }

                        </div>
                    }


                </Fragment>
            }


        </Fragment>

    );
};

export default UserPermissionForm;
