import { searchFilterList } from "aseguisShared";
import React from "react";

export const filterClientsTableTH = (
    saveSearchFilter: (searchFilter: searchFilterList) => void,
    searchFilter: searchFilterList,
    fieldValue: "#" | "Nombre" | "Email" | "Cif" | "Localidad" | "Provincia" | "Tel 1"
) => {
    return (
        <th scope="col"
            style={{ cursor: "pointer", minWidth: ((8 * fieldValue.length) + 55) + "px" }}
            onClick={() => {
                saveSearchFilter({
                    ...searchFilter,
                    sortClients: fieldValue,
                    sortDesc: searchFilter.sortClients === fieldValue ? !searchFilter.sortDesc : true,
                    withTimeout: false
                })
            }}
        >
            {setValueAndFilterArrows(searchFilter, searchFilter.sortClients, fieldValue)}
        </th>
    )
};

export const filterEmployeeTableTH = (
    saveSearchFilter: (searchFilter: searchFilterList) => void,
    searchFilter: searchFilterList,
    fieldValue: "#" | "Nombre" | "Email" | "Tel" | "Nif" | "Localidad" | "Veh. pesado"
) => {
    return (
        <th scope="col"
            style={{ cursor: "pointer", minWidth: ((8 * fieldValue.length) + 55) + "px" }}
            onClick={() => {
                saveSearchFilter({
                    ...searchFilter,
                    sortEmployees: fieldValue,
                    sortDesc: searchFilter.sortEmployees === fieldValue ? !searchFilter.sortDesc : true,
                    withTimeout: false
                })
            }}
        >
            {setValueAndFilterArrows(searchFilter, searchFilter.sortEmployees, fieldValue)}
        </th>
    )
};

export const filterSupplierTableTH = (
    saveSearchFilter: (searchFilter: searchFilterList) => void,
    searchFilter: searchFilterList,
    fieldValue: "#" | "Nombre" | "Email" | "Cif" | "Localidad" | "Provincia" | "Tel 1"
) => {
    return (
        <th scope="col"
            style={{ cursor: "pointer", minWidth: ((8 * fieldValue.length) + 55) + "px" }}
            onClick={() => {
                saveSearchFilter({
                    ...searchFilter,
                    sortSuppliers: fieldValue,
                    sortDesc: searchFilter.sortSuppliers === fieldValue ? !searchFilter.sortDesc : true,
                    withTimeout: false
                })
            }}
        >
            {setValueAndFilterArrows(searchFilter, searchFilter.sortSuppliers, fieldValue)}
        </th>
    )
};

export const filterVehiclesTableTH = (
    saveSearchFilter: (searchFilter: searchFilterList) => void,
    searchFilter: searchFilterList,
    fieldValue: "#" | "Matrícula" | "Marca" | "Modelo" | "Tipo" | "Fin ITV"
) => {
    return (
        <th scope="col"
            style={{ cursor: "pointer", minWidth: ((8 * fieldValue.length) + 55) + "px" }}
            onClick={() => {
                saveSearchFilter({
                    ...searchFilter,
                    sortVehicles: fieldValue,
                    sortDesc: searchFilter.sortVehicles === fieldValue ? !searchFilter.sortDesc : true,
                    withTimeout: false
                })
            }}
        >
            {setValueAndFilterArrows(searchFilter, searchFilter.sortVehicles, fieldValue)}
        </th>
    )
};

export const filterControlsVehiclesTableTH = (
    saveSearchFilter: (searchFilter: searchFilterList) => void,
    searchFilter: searchFilterList,
    fieldValue: "#" | "Fecha" | "Vehículo" | "Remolque" | "Km" | "Empleado" | "Num Controles"
) => {
    return (
        <th scope="col"
            style={{ cursor: "pointer", minWidth: ((8 * fieldValue.length) + 55) + "px" }}
            onClick={() => {
                saveSearchFilter({
                    ...searchFilter,
                    sortVehiclesControls: fieldValue,
                    sortDesc: searchFilter.sortVehiclesControls === fieldValue ? !searchFilter.sortDesc : true,
                    withTimeout: false
                })
            }}
        >
            {setValueAndFilterArrows(searchFilter, searchFilter.sortVehiclesControls, fieldValue)}
        </th>
    )
};

export const filterReviRepaTableTH = (
    saveSearchFilter: (searchFilter: searchFilterList) => void,
    searchFilter: searchFilterList,
    fieldValue: "#" | "Fecha" | "Matrícula" | "Km" | "Tipo" | "Tareas realizadas" | "SubTotal"
) => {
    return (
        <th scope="col"
            style={{ cursor: "pointer", minWidth: ((8 * fieldValue.length) + 55) + "px" }}
            onClick={() => {
                saveSearchFilter({
                    ...searchFilter,
                    sortReviRepa: fieldValue,
                    sortDesc: searchFilter.sortReviRepa === fieldValue ? !searchFilter.sortDesc : true,
                    withTimeout: false
                })
            }}
        >
            {setValueAndFilterArrows(searchFilter, searchFilter.sortReviRepa, fieldValue)}
        </th>
    )
};

export const filterGrupajesTableTH = (
    saveSearchFilter: (searchFilter: searchFilterList) => void,
    searchFilter: searchFilterList,
    fieldValue: "#" | "Fecha" | "Vehículo" | "Num viajes"
) => {
    return (
        <th scope="col"
            style={{ cursor: "pointer", minWidth: ((8 * fieldValue.length) + 55) + "px" }}
            onClick={() => {
                saveSearchFilter({
                    ...searchFilter,
                    sortGrupajes: fieldValue,
                    sortDesc: searchFilter.sortGrupajes === fieldValue ? !searchFilter.sortDesc : true,
                    withTimeout: false
                })
            }}
        >
            {setValueAndFilterArrows(searchFilter, searchFilter.sortGrupajes, fieldValue)}
        </th>
    )
};

export const filterCargaCompletaTableTH = (
    saveSearchFilter: (searchFilter: searchFilterList) => void,
    searchFilter: searchFilterList,
    fieldValue: "#" | "Cliente" | "Vehículo" | "Tipo vehículo" | "Conductor" | "Referencia/s" | "SubTotal" | "ID Factura" | "Entr prevista" | "Entregado" | "Fecha Albarán"
) => {
    return (
        <th scope="col"
            style={{
                cursor: "pointer",
                whiteSpace: "nowrap"
            }}
            onClick={() => {
                saveSearchFilter({
                    ...searchFilter,
                    sortCargaCompleta: fieldValue,
                    sortDesc: searchFilter.sortCargaCompleta === fieldValue ? !searchFilter.sortDesc : true,
                    withTimeout: false
                })
            }}
        >
            {setValueAndFilterArrows(searchFilter, searchFilter.sortCargaCompleta, fieldValue)}
        </th>
    )
};

export const filterPaqueteriaTableTH = (
    saveSearchFilter: (searchFilter: searchFilterList) => void,
    searchFilter: searchFilterList,
    fieldValue: "#" | "E/S" | "Carta Porte" | "Entregado" | "Cliente" | "Origen" | "Destino" | "Referencia/s" | "Bultos" | "Kilos" | "SubTotal" | "ID Factura" | "Entr prevista" | "Fecha Albarán"
) => {
    return (
        <th scope="col"
            style={{
                cursor: "pointer",
                whiteSpace: "nowrap"
            }}
            onClick={() => {
                saveSearchFilter({
                    ...searchFilter,
                    sortPaqueteria: fieldValue,
                    sortDesc: searchFilter.sortPaqueteria === fieldValue ? !searchFilter.sortDesc : true,
                    withTimeout: false
                })
            }}
        >
            {setValueAndFilterArrows(searchFilter, searchFilter.sortPaqueteria, fieldValue)}
        </th>
    )
};

export const filterBillsTableTH = (
    saveSearchFilter: (searchFilter: searchFilterList) => void,
    searchFilter: searchFilterList,
    fieldValue: "#" | "Fecha factura" | "Fecha vencimiento" | "Cliente" | "Núm Cargas" | "Núm Paqueterías" | "SubTotal" | "IVA" | "Total" | "Forma de pago"
) => {
    return (
        <th scope="col"
            style={{ cursor: "pointer", minWidth: ((8 * fieldValue.length) + 55) + "px" }}
            onClick={() => {
                saveSearchFilter({
                    ...searchFilter,
                    sortBills: fieldValue,
                    sortDesc: searchFilter.sortBills === fieldValue ? !searchFilter.sortDesc : true,
                    withTimeout: false
                })
            }}
        >
            {setValueAndFilterArrows(searchFilter, searchFilter.sortBills, fieldValue)}
        </th>
    )
};

export const filterAbonoTableTH = (
    saveSearchFilter: (searchFilter: searchFilterList) => void,
    searchFilter: searchFilterList,
    fieldValue: "#" | "Fecha abono" | "Cliente" | "Núm Cargas" | "Núm Paqueterías" | "SubTotal" | "IVA" | "Total"
) => {
    return (
        <th scope="col"
            style={{ cursor: "pointer", minWidth: ((8 * fieldValue.length) + 55) + "px" }}
            onClick={() => {
                saveSearchFilter({
                    ...searchFilter,
                    sortAbonos: fieldValue,
                    sortDesc: searchFilter.sortAbonos === fieldValue ? !searchFilter.sortDesc : true,
                    withTimeout: false
                })
            }}
        >
            {setValueAndFilterArrows(searchFilter, searchFilter.sortAbonos, fieldValue)}
        </th>
    )
};

const setValueAndFilterArrows = (
    searchFilter: searchFilterList,
    fieldFilter: string,
    fieldValue: string
) => {
    return (
        <>
            {fieldValue}
            {(fieldFilter === fieldValue && searchFilter.sortDesc === true) &&
                <i className="fa-solid fa-arrow-down ml-2"></i>
            }
            {(fieldFilter === fieldValue && searchFilter.sortDesc === false) &&
                <i className="fa-solid fa-arrow-up ml-2"></i>
            }
        </>
    )
};
