import React, { Fragment, ReactElement, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from '../../ApplicationState';
import {
    saveFormEmployee, setValidateEmployeeForm, validateIfExistsFields,
    addOrEditEmployee, setFormsEmployee, requestOneEmployee
} from './EmployeesStore';
import UserPermissionForm from "../../common/commonInterfaces/UserPermissionForm";
import NumberFormat from "react-number-format";
import {
    CapitalizefirstLetter, employee, formatedDirection, urlParmsForm, loginUsers,
    validateFormEmpty, addingOrEditing, usersTypes, isEmailValidate, validateAlphaNumeric,
    validateEmail, validateFormEmail, getFormattedDate, employeeValidateFieldsResult,
    suscriptionData
} from "aseguisShared";
import InputDate from "../../common/Inputs/InputDate";
import { } from "../Login/LoginStore";
import { useHistory, useParams } from "react-router-dom";
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import GoogleAndClientsSuggestions from "../../common/PlacesAutocomplete/GoogleAndClientsSuggestions";
import InputText from "../../common/Inputs/InputText";
import { GetSuscriptionDataFromLocalStorage } from "../../common/commonInterfaces/commonFunctions";

interface infoToEmployeeForm {
    isEdditing: number;
    errorForm: boolean,
    setErrorForm: (value: boolean) => void,
    setEdditing: (value: addingOrEditing) => void,
}

function EmployeeForm(props: infoToEmployeeForm): ReactElement {

    const debounceRef = useRef<NodeJS.Timeout | null>(null);
    const dispatch: React.Dispatch<any> = useDispatch();
    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);
    const employeeForm: employee = useSelector((state: ApplicationState) => state.employees.employeeForm);
    const employeeEditForm: employee = useSelector((state: ApplicationState) => state.employees.employeeEditForm);
    const addingOrEditEmployee: addingOrEditing = useSelector((state: ApplicationState) => state.employees.addOrEditEmployee) || addingOrEditing.none;
    const validateFieldsForm: employeeValidateFieldsResult = useSelector((state: ApplicationState) => state.employees.validateFieldsForm);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [goToValidateFields, setGoToValidateFields] = useState(false);
    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    useEffect(() => {
        if (
            (employeeEditForm.nif.length < 2) ||
            (employeeEditForm.name.length < 2) ||
            (employeeEditForm.apellido1.length < 2) ||
            (employeeEditForm.email.length > 0 && isEmailValidate(employeeEditForm.email) === false)
        ) {
            props.setErrorForm(true)
        } else {
            if (validateFieldsForm.anyFieldExist === true) {
                props.setErrorForm(true)
            } else {
                props.setErrorForm(false)
            }
        }
    }, [employeeEditForm, validateFieldsForm.anyFieldExist])

    useEffect(() => {
        if (goToValidateFields === true) {
            dispatch(validateIfExistsFields(employeeEditForm));
            setGoToValidateFields(false)
        }
    }, [goToValidateFields])

    //para tener siempre la ultima versión...
    let history = useHistory()
    let { id } = useParams<urlParmsForm>();
    useEffect(() => {
        if (id === "new") {
            dispatch(addOrEditEmployee(addingOrEditing.adding));
            props.setEdditing(addingOrEditing.edditing)
        } else {
            requestOneEmployee(id)
                .then((employee) => {
                    if (employee.data.employeesResults.length === 1) {
                        dispatch(addOrEditEmployee(addingOrEditing.edditing));
                        dispatch(setFormsEmployee(employee.data.employeesResults[0]))
                    } else {
                        history.push(allAppRoutes.employeesList)
                    }
                })
                .catch((error) => {
                    history.push(allAppRoutes.employeesList)
                })
        }
    }, [id]);
    //...para tener siempre la ultima versión

    return (
        <Fragment>

            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">
                        {(addingOrEditEmployee !== addingOrEditing.adding) &&
                            <span> {"# " + employeeEditForm.id.toString()}</span>
                        }
                        {(addingOrEditEmployee === addingOrEditing.adding) &&
                            <span> {"Añadir Empleado"}</span>
                        }
                    </h6>
                </div>
                <div className="card-body">

                    <div className="form-row">
                        <div className="form-group col-md-2">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="fecAlta">Fecha de alta</label>
                                <div>{getFormattedDate(employeeEditForm.fecAlta)}</div>
                            </div>
                        </div>

                        <InputDate
                            colMesure="2"
                            editEnabled={props.isEdditing}
                            addOrEdit={addingOrEditEmployee}
                            name="Antigüedad"
                            valueEdit={employeeEditForm.fecAntiguedad}
                            value={employeeForm.fecAntiguedad}
                            onChange={(date) => {
                                dispatch(saveFormEmployee({
                                    ...employeeEditForm,
                                    fecAntiguedad: date!
                                }));
                            }}
                        />

                        <InputDate
                            colMesure="2"
                            editEnabled={props.isEdditing}
                            addOrEdit={addingOrEditEmployee}
                            name="Fecha de nacimiento"
                            valueEdit={employeeEditForm.fecNacimi}
                            value={employeeForm.fecNacimi}
                            onChange={(date) => {
                                dispatch(saveFormEmployee({
                                    ...employeeEditForm,
                                    fecNacimi: date!
                                }));
                            }}
                        />

                    </div>

                    <div className="form-row" style={{ marginTop: "20px" }}>

                        <div className="form-group col-md-4">
                            <div >
                                <label style={{ fontWeight: 'bold' }} htmlFor="name">NIF</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={employeeEditForm.nif}
                                        onChange={(event) => {
                                            var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                                            dispatch(saveFormEmployee({
                                                ...employeeEditForm,
                                                nif: value,
                                                fecAlta: addingOrEditEmployee === addingOrEditing.adding ? new Date() : employeeEditForm.fecAlta
                                            }));
                                            dispatch(setValidateEmployeeForm({
                                                ...validateFieldsForm,
                                                nifExists: false,
                                            }))
                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);
                                        }}
                                        className={validateFormEmpty(employeeEditForm.nif, 2)}
                                        id="nif"
                                    />
                                    :
                                    <input
                                        name="empNif"
                                        disabled={true}
                                        type="text"
                                        value={employeeForm.nif}
                                        className="form-control undefined"
                                    />
                                }

                                <div className="text-danger" style={{ height: "20px" }}>
                                    {(validateFieldsForm.nifExists) &&
                                        <small>NIF ya asignado a otro empleado</small>
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="form-group col-md-6">
                            <label style={{ fontWeight: 'bold' }} htmlFor="input_tel2">Dirección</label>
                            <GoogleAndClientsSuggestions
                                isEditing={props.isEdditing}
                                valueNoEditing={formatedDirection(employeeForm.address)}
                                placeholder="Escriba un lugar para autocompletar..."
                                activateClientsDirs={false}
                                initialAddress={employeeEditForm.address}
                                onClick={(dest, fromModal) => {
                                    dispatch(saveFormEmployee({
                                        ...employeeEditForm,
                                        address: dest.address
                                    }));
                                }}
                            />
                        </div>

                        <InputText
                            className="form-control undefined"
                            colMesure="2"
                            autoComplete={false}
                            editEnabled={props.isEdditing}
                            addOrEdit={addingOrEditEmployee}
                            name="Piso/Pta"
                            valueEdit={employeeEditForm.address.puerta}
                            value={employeeForm.address.puerta}
                            onChange={(event) => {
                                var value = validateAlphaNumeric(event.target.value)
                                dispatch(saveFormEmployee({
                                    ...employeeEditForm,
                                    address: {
                                        ...employeeEditForm.address,
                                        puerta: value
                                    }
                                }));
                            }}
                        />

                    </div>
                    <div className="form-row">

                        <div className="form-group col-md-4">
                            <div >
                                <label style={{ fontWeight: 'bold' }} htmlFor="name">Nombre</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={employeeEditForm.name}
                                        onChange={(event) => {
                                            var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                            dispatch(saveFormEmployee({ ...employeeEditForm, name: value }));
                                        }}
                                        className={validateFormEmpty(employeeEditForm.name, 2)}
                                        id="name" />
                                    :
                                    <input
                                        name="empname"
                                        disabled={true}
                                        type="text"
                                        value={employeeForm.name}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>

                        <div className="form-group col-md-4">
                            <div >
                                <label style={{ fontWeight: 'bold' }} htmlFor="name">Apellido 1</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={employeeEditForm.apellido1}
                                        onChange={(event) => {
                                            var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                            dispatch(saveFormEmployee({ ...employeeEditForm, apellido1: value }));
                                        }}
                                        className={validateFormEmpty(employeeEditForm.apellido1, 2)}
                                        id="apellido1" />
                                    :
                                    <input
                                        name="empapellido1"
                                        disabled={true}
                                        type="text"
                                        value={employeeForm.apellido1}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>

                        <div className="form-group col-md-4">
                            <div >
                                <label style={{ fontWeight: 'bold' }} htmlFor="name">Apellido 2</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={employeeEditForm.apellido2}
                                        onChange={(event) => {
                                            var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                            dispatch(saveFormEmployee({ ...employeeEditForm, apellido2: value }));
                                        }}
                                        className="form-control undefined"
                                        id="apellido2" />
                                    :
                                    <input
                                        name="empapellido2"
                                        disabled={true}
                                        type="text"
                                        value={employeeForm.apellido2}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>
                    </div>

                    <div className="form-row" style={{ marginTop: "20px" }}>
                        <div className="form-group col-md-6">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="email">Email</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={employeeEditForm.email}
                                        onChange={(event) => {
                                            var value = validateEmail(event.target.value)
                                            dispatch(saveFormEmployee({ ...employeeEditForm, email: value }));
                                            dispatch(setValidateEmployeeForm({
                                                ...validateFieldsForm,
                                                emailExists: false
                                            }))
                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);
                                        }}
                                        className={employeeEditForm.email.length > 0 ? validateFormEmail(employeeEditForm.email) : "form-control undefined"}
                                        id="email"
                                    />
                                    :
                                    <input
                                        name="empEmail"
                                        disabled={true}
                                        type="text"
                                        value={employeeForm.email}
                                        className="form-control undefined"
                                    />
                                }

                                <div className="text-danger" style={{ height: "20px" }}>
                                    {(validateFieldsForm.emailExists) &&
                                        <small> EMAIL ya asignado a otro empleado</small>
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="form-group col-md-3">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="input_tel1">Tel. 1</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <NumberFormat
                                        format="### ## ## ## ## ## ##"
                                        value={employeeEditForm.tel1}
                                        id="tel1"
                                        isNumericString={true}
                                        className="form-control undefined"
                                        onValueChange={(value) => {
                                            dispatch(saveFormEmployee({ ...employeeEditForm, tel1: value.value }));
                                            dispatch(setValidateEmployeeForm({
                                                ...validateFieldsForm,
                                                tel1Exists: false
                                            }))
                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);
                                        }}
                                    />
                                    :
                                    <input
                                        name="emptel1"
                                        disabled={true}
                                        type="text"
                                        value={employeeForm.tel1}
                                        className="form-control undefined"
                                    />
                                }

                                <div className="text-danger" style={{ height: "20px" }}>
                                    {(validateFieldsForm.tel1Exists) &&
                                        <small>TEL ya asignado a otro empleado</small>
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="form-group col-md-3">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="input_tel2">Tel. 2</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <NumberFormat
                                        format="### ## ## ## ## ## ##"
                                        value={employeeEditForm.tel2}
                                        id="tel2"
                                        isNumericString={true}
                                        className="form-control undefined"
                                        onValueChange={(value) => {
                                            dispatch(saveFormEmployee({ ...employeeEditForm, tel2: value.value }));
                                            dispatch(setValidateEmployeeForm({
                                                ...validateFieldsForm,
                                                tel2Exists: false
                                            }))
                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);
                                        }}
                                    />
                                    :
                                    <input
                                        name="emptel2"
                                        disabled={true}
                                        type="text"
                                        value={employeeForm.tel2}
                                        className="form-control undefined"
                                    />
                                }

                                <div className="text-danger" style={{ height: "20px" }}>
                                    {(validateFieldsForm.tel2Exists) &&
                                        <small>TEL ya asignado a otro empleado</small>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                    </div>
                </div>
            </div>

            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green"> Otros datos</h6>
                </div>
                <div className="card-body">
                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <div >
                                <label style={{ fontWeight: 'bold' }} htmlFor="name">Núm seg social</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={employeeEditForm.numSegSocial}
                                        onChange={(event) => {
                                            var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                                            dispatch(saveFormEmployee({ ...employeeEditForm, numSegSocial: value }));
                                            dispatch(setValidateEmployeeForm({
                                                ...validateFieldsForm,
                                                numSegSocialExists: false
                                            }))
                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);
                                        }}
                                        className="form-control undefined"
                                        id="numSegSocial"
                                    />
                                    :
                                    <input
                                        name="numSegSocial"
                                        disabled={true}
                                        type="text"
                                        value={employeeForm.numSegSocial}
                                        className="form-control undefined"
                                    />
                                }

                                <div className="text-danger" style={{ height: "20px" }}>
                                    {(validateFieldsForm.numSegSocialExists) &&
                                        <small>NUM SEG SOCIAL ya asignado a otro empleado</small>
                                    }
                                </div>
                            </div>
                        </div>

                        <InputDate
                            redOnError={true}
                            colMesure="4"
                            editEnabled={props.isEdditing}
                            addOrEdit={addingOrEditEmployee}
                            name="Vencimiento rev. médica"
                            valueEdit={employeeEditForm.vtoRevMedica}
                            value={employeeForm.vtoRevMedica}
                            onChange={(date) => {
                                dispatch(saveFormEmployee({
                                    ...employeeEditForm,
                                    vtoRevMedica: date!
                                }));
                            }}
                        />

                        <InputDate
                            redOnError={true}
                            colMesure="4"
                            editEnabled={props.isEdditing}
                            addOrEdit={addingOrEditEmployee}
                            name="Vencimiento prevención"
                            valueEdit={employeeEditForm.vtoPrevencion}
                            value={employeeForm.vtoPrevencion}
                            onChange={(date) => {
                                dispatch(saveFormEmployee({
                                    ...employeeEditForm,
                                    vtoPrevencion: date!
                                }));
                            }}
                        />

                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="input_tipo">Chofer de vehículo pesado</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <select
                                        value={employeeEditForm.isChofer === true ? "Si" : "No"}
                                        className="form-control undefined"
                                        id="isChofer"
                                        onChange={(value) => {
                                            dispatch(saveFormEmployee({
                                                ...employeeEditForm,
                                                isChofer: value.target.value === "Si" ? true : false
                                            }));
                                        }}
                                    >
                                        <option value="Si">Si</option>
                                        <option value="No">No</option>
                                    </select>
                                    :
                                    <input
                                        name="isChofer"
                                        disabled={true}
                                        type="text"
                                        value={employeeForm.isChofer === true ? "Si" : "No"}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>

                        {(
                            (props.isEdditing === addingOrEditing.edditing && employeeEditForm.isChofer === true) ||
                            (props.isEdditing !== addingOrEditing.edditing && employeeForm.isChofer === true)
                        ) &&
                            <InputDate
                                redOnError={true}
                                colMesure="4"
                                editEnabled={props.isEdditing}
                                addOrEdit={addingOrEditEmployee}
                                name="Vencimiento CAP"
                                valueEdit={employeeEditForm.vtoCap}
                                value={employeeForm.vtoCap}
                                onChange={(date) => {
                                    dispatch(saveFormEmployee({
                                        ...employeeEditForm,
                                        vtoCap: date!
                                    }));
                                }}
                            />
                        }

                        {(
                            (props.isEdditing === addingOrEditing.edditing && employeeEditForm.isChofer === true) ||
                            (props.isEdditing !== addingOrEditing.edditing && employeeForm.isChofer === true)
                        ) &&
                            <InputDate
                                redOnError={true}
                                colMesure="4"
                                editEnabled={props.isEdditing}
                                addOrEdit={addingOrEditEmployee}
                                name="Vencimiento tacógrafo"
                                valueEdit={employeeEditForm.vtoTacografo}
                                value={employeeForm.vtoTacografo}
                                onChange={(date) => {
                                    dispatch(saveFormEmployee({
                                        ...employeeEditForm,
                                        vtoTacografo: date!
                                    }));
                                }}
                            />
                        }
                    </div>
                </div>
            </div>

            {(suscriptionData.empleados) &&
                <div>
                    {(
                        (props.isEdditing === addingOrEditing.edditing && employeeEditForm.email.length > 0) ||
                        (props.isEdditing !== addingOrEditing.edditing && employeeForm.email.length > 0)
                    ) &&
                        <UserPermissionForm
                            userType={usersTypes.employee}
                            userLoggedType={userLogged.userType}
                            userLogguedPermissions={userLogged.permisosUsuario}
                            isEdditing={props.isEdditing}
                            permisosUsuario={employeeForm.permisosUsuario}
                            permisosUsuarioEdit={employeeEditForm.permisosUsuario}
                            nif={employeeEditForm.nif}
                            associatedId={employeeEditForm.permisosUsuario.idAssociated}
                            setPermisosUsuarioEdit={(permisos) => {
                                dispatch(saveFormEmployee({
                                    ...employeeEditForm,
                                    permisosUsuario: permisos
                                }));
                            }}
                        />
                    }
                </div>
            }

        </Fragment>

    );
};

export default EmployeeForm;
