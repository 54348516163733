"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initicializeControlVehicle = exports.inicialiceVehiclesControlsStats = void 0;
const loginFunctions_1 = require("./loginFunctions");
const vehiclesFunctions_1 = require("./vehiclesFunctions");
exports.inicialiceVehiclesControlsStats = {
    vehicle: vehiclesFunctions_1.initialVehicle,
    withoutAssign: false,
    showAssociated: false
};
exports.initicializeControlVehicle = {
    _id: null,
    id: 0,
    controlList: [],
    //employee: inicialiceEmployee,
    user: loginFunctions_1.inicialiceLoginUser,
    fecAlta: new Date(),
    vehicle: vehiclesFunctions_1.initialVehicle,
    remolque: vehiclesFunctions_1.initialVehicle,
    observaciones: "",
    kms: 0
};
