import React, { Fragment, ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from '../../../ApplicationState';
import { incidenceType, addingOrEditing, initIncidenceType } from "aseguisShared";
import IncidencesTypesList from "./IncidencesTypesList";

interface infoToIncidencesTypes {
    isEdditing: number;
    allIncidencesTypes: incidenceType[];

    addIncidenceType: (incidenceType: incidenceType) => void
    saveExistingIncidenceType: (incidenceType: incidenceType) => void
    deleteIncidenceType: (incidenceType: incidenceType) => void

    errorForm: boolean,
    setErrorForm: (value: boolean) => void,
}

function IncidencesTypes(props: infoToIncidencesTypes): ReactElement {

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);

    const [incidenceType, setIncidenceType] = React.useState<incidenceType>(initIncidenceType);
    const [formState, setFormState] = React.useState(addingOrEditing.none);

    useEffect(() => {
        if (
            ((formState !== addingOrEditing.none) && (props.isEdditing !== addingOrEditing.none)) &&
            (
                (incidenceType.description.length < 2)
            )
        ) {
            props.setErrorForm(true)
        } else {
            props.setErrorForm(false)
        }
    }, [incidenceType.description.length])


    useEffect(() => {
        //para cuando se hace click en cancelar       
        if (props.isEdditing === addingOrEditing.none) {
            setIncidenceType(initIncidenceType)
            setFormState(addingOrEditing.none)
        }
    })

    return (
        <Fragment>

            <div className="card shadow mb-4">

                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">Tipos de incidencias</h6>

                    {isAppLoading === true &&
                        <div className="header-buttons">
                            <span className="spinner-border spinner-border-sm mr-4"></span>
                        </div>
                    }

                    {((isAppLoading === false)) &&

                        <div>
                            {(formState === addingOrEditing.none) && (props.isEdditing === addingOrEditing.edditing) &&
                                <div className="header-buttons">

                                    {/* AÑADIR BOTON*/}
                                    <button className="btn btn-success btn-green btn-sm"
                                        onClick={() => {
                                            setFormState(addingOrEditing.adding)

                                            var maxId = 0
                                            for (var incidenceType of props.allIncidencesTypes) {
                                                if (incidenceType.id > maxId) {
                                                    maxId = incidenceType.id
                                                }
                                            }
                                            maxId = maxId + 1
                                            setIncidenceType({
                                                ...initIncidenceType,
                                                id: maxId
                                            })
                                        }}>
                                        <i className="fas fa fa-plus"></i>
                                    </button>

                                    {/*edit button*/}
                                    {incidenceType.description !== "" &&
                                        <button className="btn btn-success btn-blue btn-sm"
                                            onClick={() => {
                                                setFormState(addingOrEditing.edditing)
                                            }}>
                                            <i className="fas fa fa-pen"></i>
                                        </button>
                                    }

                                    {/*delete button*/}
                                    {incidenceType.description !== "" &&
                                        <button className="btn btn-success btn-red btn-sm"
                                            onClick={() => {
                                                props.deleteIncidenceType(incidenceType)
                                            }}>
                                            <i className="fas fa fa-times"></i>
                                        </button>
                                    }
                                </div>

                            }
                        </div>

                    }

                    {(props.isEdditing === addingOrEditing.edditing) &&
                        <div className="header-buttons">

                            {/* ESTADO AÑADIR */}
                            {formState === addingOrEditing.adding &&
                                <button className={props.errorForm === false ? "btn btn-success left-margin btn-sm" : "btn btn-success left-margin btn-sm disabled"}
                                    onClick={() => {
                                        if (props.errorForm === false) {
                                            setFormState(addingOrEditing.none)
                                            props.addIncidenceType(incidenceType)
                                            setIncidenceType(initIncidenceType)
                                        }
                                    }}>
                                    Añadir tipo incidencia
                                </button>
                            }

                            {/* ESTADO EDITAR */}
                            {formState === addingOrEditing.edditing &&
                                <button className={props.errorForm === false ? "btn btn-success left-margin btn-sm" : "btn btn-success left-margin btn-sm disabled"}
                                    onClick={() => {
                                        if (props.errorForm === false) {
                                            setFormState(addingOrEditing.none)
                                            props.saveExistingIncidenceType(incidenceType)
                                            setIncidenceType(initIncidenceType)
                                        }
                                    }}>
                                    Guardar tipo incidencia
                                </button>
                            }

                            {/* ESTADO AÑADIR O EDITAR */}
                            {(formState === addingOrEditing.adding || formState === addingOrEditing.edditing) &&
                                <button className="btn btn-danger btn-sm left-margin"
                                    onClick={() => {
                                        setIncidenceType(initIncidenceType)
                                        setFormState(addingOrEditing.none)
                                    }}>
                                    Cancelar
                                </button>
                            }

                        </div>
                    }

                </div>

                <IncidencesTypesList
                    allIncidencesTypes={props.allIncidencesTypes}
                    formIncidenceType={incidenceType}
                    setIncidenceType={(controlType) => {
                        setIncidenceType(controlType)
                    }}
                    formState={formState}
                    isEdditing={props.isEdditing}
                />

            </div>

        </Fragment>

    );
};

export default IncidencesTypes;
