import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { CustomerPortalButton } from "../../components/Stripe/StripeSuscription";
import { requestSuscriptionStatus } from "../../common/globalState/globalStateStore";
import axios from "axios";
import { suscriptionData } from "aseguisShared";
import RegisterPlans from "../../components/Register/RegisterPlans";
import { Notify } from "../Toastify/Notify";
import { GetSuscriptionDataFromLocalStorage } from "../commonInterfaces/commonFunctions";
import { getStripeProducts } from "../../components/Register/RegisterStore";
import { ApplicationState } from "../../ApplicationState";

interface infoToSuscriptionModal {
    showManageSuscriptionModal: boolean,
    setShowManageSuscriptionModal: (openModal: boolean) => void
}

function SuscriptionModal({ showManageSuscriptionModal, setShowManageSuscriptionModal }: infoToSuscriptionModal): ReactElement {

    const dispatch = useDispatch();

    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [isLoading, setIsLoading] = React.useState(false)
    const [showPlanModal, setShowPlanModal] = React.useState(false)
    const [urlFacturasPendientes, setUrlFacturasPendientes] = React.useState("")
    const [fromChangeOrNewPlan, setFromChangeOrNewPlan] = React.useState<"register" | "newPlan" | "changePlan">("changePlan")
    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    const handlePlanModalClose = () => {
        setShowPlanModal(false)
    };

    const handleManageSuscriptionModalClose = () => {
        setShowManageSuscriptionModal(false)
    };

    useEffect(() => {
        dispatch(getStripeProducts())
    }, [])

    useEffect(() => {
        if (showManageSuscriptionModal === true) {
            setIsLoading(true)
            axios.post('api/stripe/payPendingInvoices/')
                .then((data) => {
                    const { url } = data.data
                    if (url && data.status === 200) {
                        setUrlFacturasPendientes(url)
                    }
                    if (data.status === 201) {
                    }
                    if (data.status === 202) {
                    }
                    setIsLoading(false)
                })
                .catch((error) => {
                })
        }
    }, [showManageSuscriptionModal])

    return (

        <Fragment>
            {showManageSuscriptionModal &&
                <div>
                    {/* MODAL GESTIONAR SUSCRIPCION */}
                    <Modal
                        show={showManageSuscriptionModal}
                        onHide={handleManageSuscriptionModalClose}
                        dialogClassName="modal-lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Gestionar suscripción</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(suscriptionData.customerId.length > 0) &&
                                <div>
                                    {!isLoading ?
                                        <div>
                                            {(suscriptionData.suscriptionStatus === "trialing") &&
                                                <div>
                                                    Suscripción en periodo de prueba
                                                    <br></br>
                                                    <br></br>
                                                    {"Fin de prueba: "}
                                                    <span
                                                        style={{
                                                            textDecoration: "underline",
                                                            fontWeight: 600
                                                        }}
                                                    >
                                                        {suscriptionData.trialPeriodEnds}
                                                    </span>
                                                </div>
                                            }

                                            {(suscriptionData.suscriptionStatus === "incomplete") &&
                                                <div>
                                                    Suscripción incompleta
                                                    <br></br>
                                                    <br></br>
                                                    {suscriptionData.suscriptionName}
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <CustomerPortalButton />
                                                </div>
                                            }

                                            {(suscriptionData.suscriptionStatus === "active") &&
                                                <div>
                                                    Suscripción activa
                                                    <br></br>
                                                    <br></br>
                                                    {suscriptionData.suscriptionName}
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <CustomerPortalButton />
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary btn btn-sm ml-2"
                                                        onClick={() => {
                                                            setShowPlanModal(true)
                                                            setFromChangeOrNewPlan("changePlan")
                                                        }}
                                                    >
                                                        Cambiar plan
                                                    </button>
                                                </div>
                                            }

                                            {(suscriptionData.suscriptionStatus === "past_due" || suscriptionData.suscriptionStatus === "unpaid") &&
                                                <div>
                                                    Suscripción vencida. Pago pendiente.
                                                    <br></br>
                                                    <br></br>
                                                    <span>Acceda al portal de suscripción para abonar el importe pendiente y reactivar la suscripción
                                                    </span>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <CustomerPortalButton />
                                                </div>
                                            }

                                            {(suscriptionData.suscriptionStatus === "canceled") &&
                                                <div>
                                                    Sin suscripción activa
                                                    <br></br>
                                                    <br></br>
                                                    {(urlFacturasPendientes.length > 0) ?
                                                        <Fragment>
                                                            <span>Tiene pagos pendientes.</span>
                                                            <br></br>
                                                            <br></br>
                                                            <span>Para poder crear una nueva suscripción debe abonar todos los pagos pendientes</span>
                                                            <br></br>
                                                            <br></br>
                                                            <a
                                                                href={urlFacturasPendientes}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <span>Pagar facturas</span>
                                                            </a>
                                                        </Fragment>
                                                        :
                                                        <Fragment>
                                                            <span>Elija un plan para continuar utilizando Aseguis</span>
                                                            <br></br>
                                                            <br></br>
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary btn btn-sm"
                                                                onClick={() => {
                                                                    setShowPlanModal(true)
                                                                    setFromChangeOrNewPlan("newPlan")
                                                                }}
                                                            >
                                                                Elegir plan
                                                            </button>
                                                        </Fragment>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        :
                                        <span className="spinner-border spinner-border-sm"></span>
                                    }
                                </div>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    handleManageSuscriptionModalClose()
                                }}
                            >
                                Cerrar
                            </Button>
                        </Modal.Footer>
                    </Modal>


                    {/* MODAL ELEGIR PLAN */}
                    <Modal
                        show={showPlanModal}
                        onHide={handlePlanModalClose}
                        dialogClassName="modal-xl"
                    >
                        <Modal.Body>
                            <RegisterPlans
                                from={fromChangeOrNewPlan}
                                originalPrices={suscriptionData.pricesId || []}
                                callbackContinue={(newPricesIds) => {
                                    setIsLoading(true)
                                    handlePlanModalClose()
                                    axios.post("api/stripe/updatePricesSuscription/", { pricesId: newPricesIds })
                                        .then((data) => {
                                            switch (data.status) {
                                                case 201: {
                                                    Notify("El plan se ha actualizado correctamente", "success");
                                                    break;
                                                }
                                                case 202: {
                                                    const { url } = data.data;
                                                    window.location.href = url;
                                                    break;
                                                }
                                                case 203: {
                                                    Notify("Los elementos seleccionados son los mismos que ya tiene contratados", "warn");
                                                    break;
                                                }
                                                case 230: {
                                                    const { url } = data.data;
                                                    Notify(url, "error");
                                                    break;
                                                }
                                                case 231: {
                                                    const { url } = data.data;
                                                    Notify(url, "error");
                                                    break;
                                                }
                                                default:
                                                    Notify("No ha sido posible cambiar el plan. Por favor, contacte con soporte", "error");
                                            }
                                            dispatch(requestSuscriptionStatus());
                                            setIsLoading(false)
                                            handleManageSuscriptionModalClose()
                                        })
                                        .catch((error) => {
                                            setIsLoading(false)
                                        })
                                }}
                            />
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </Fragment>

    );
};

export default SuscriptionModal;
