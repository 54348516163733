import { Action, Dispatch } from 'redux';
import axios from 'axios';
import {
    loginUsers, inicialiceLoginUser, loginResponse,
    logInRequest, ErrorMessagesTypes, loading
} from 'aseguisShared';
import { allAppRoutes } from '../../common/commonInterfaces/allAppRoutes';
import { Notify } from '../../common/Toastify/Notify';
import { requestSuscriptionStatus } from '../../common/globalState/globalStateStore';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface loginState {
    userLogged: loginUsers;
    loginLoaded: number;
    refreshLoaded: boolean;
    errorToShow: string;
    typeError: string;
    isAuthenticated: boolean;
}

export const initialState: loginState = {
    userLogged: inicialiceLoginUser,
    loginLoaded: loading.done,
    refreshLoaded: false,
    errorToShow: "",
    typeError: "",
    isAuthenticated: false,
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface LogInAction {
    type: 'LOG_IN_REQUEST'
}

export interface AuthenticateAction {
    type: 'AUTHENTICATE_RECEIVED',
    loginResponse: loginResponse
}

export interface LogOutAction {
    type: 'LOG_OUT'
}

export interface showErrorLogintAction {
    type: 'SHOW_ERROR_LOGIN',
    error: string,
    typeError: string
}

export interface clearLoginError {
    type: 'CLEAR_LOGUIN_ERROR'
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = LogInAction | LogOutAction | clearLoginError | AuthenticateAction | showErrorLogintAction;


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export function logout(): LogOutAction {
    return { type: 'LOG_OUT' };
}

export function logoutAndClearStorage(history: any) {
    return async (dispatch: Dispatch) => {
        localStorage.clear();
        history.push(allAppRoutes.login);
    };
}

export function clearLoginError(): clearLoginError {
    return {
        type: 'CLEAR_LOGUIN_ERROR',
    };
}


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export function login(email: string, password: string) {
    const logIn: logInRequest = {
        email: email,
        password: password,
        from: "web"
    };
    return async (dispatch: Dispatch<any>) => {
        dispatch({ type: 'LOG_IN_REQUEST' });
        return axios.post<loginResponse>('/api/login', logIn)
            .then(async (dataLogin) => {
                if (dataLogin.status === 200) {
                    if (dataLogin.data.userLogged.permisosUsuario.accessEnabled) {
                        await localStorage.setItem('accessToken', dataLogin.data.accessToken);
                        await localStorage.setItem('loggedUser', JSON.stringify(dataLogin.data.userLogged));
                        await localStorage.setItem('refreshToken', dataLogin.data.refreshToken);
                        await localStorage.setItem('loggedIn', Date.now().toString());
                        dispatch(requestSuscriptionStatus())
                        dispatch({ type: 'AUTHENTICATE_RECEIVED', loginResponse: dataLogin.data });
                    } else {
                        dispatch({ type: 'SHOW_ERROR_LOGIN', error: "Error al iniciar sesión", typeError: ErrorMessagesTypes.nok });
                    }
                } else {
                    dispatch({ type: 'SHOW_ERROR_LOGIN', error: "Error al iniciar sesión", typeError: ErrorMessagesTypes.nok });
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (email === "usuario" || password === "contraseña") {
                        dispatch({ type: 'SHOW_ERROR_LOGIN', error: "Inserte usuario y/o contraseña", typeError: ErrorMessagesTypes.nok });
                    } else {
                        dispatch({ type: 'SHOW_ERROR_LOGIN', error: "Error al iniciar sesión", typeError: ErrorMessagesTypes.nok });
                    }

                } else if (error.request) {
                    dispatch({ type: 'SHOW_ERROR_LOGIN', error: "Error de comunicación con el servidor", typeError: ErrorMessagesTypes.nok });
                } else {
                    dispatch({ type: 'SHOW_ERROR_LOGIN', error: "Error de comunicación con el servidor", typeError: ErrorMessagesTypes.nok });
                }
            })
    }
}

export function refreshLogin() {
    return async (dispatch: Dispatch<any>) => {
        try {
            const AccessToken = await localStorage.getItem('accessToken') || "";
            const RefreshToken = await localStorage.getItem('refreshToken') || "";
            const dataRefresh = await axios.post<loginResponse>('api/refresh', { AccessToken, RefreshToken });
            await localStorage.setItem('accessToken', dataRefresh.data.accessToken);
            await localStorage.setItem('loggedUser', JSON.stringify(dataRefresh.data.userLogged));
            await localStorage.setItem('refreshToken', dataRefresh.data.refreshToken);
            dispatch(requestSuscriptionStatus())
            dispatch({ type: 'AUTHENTICATE_RECEIVED', loginResponse: dataRefresh.data });

        } catch (error) {
            dispatch({ type: 'LOG_OUT' });
        }
    }
}

export function forgotPassword(logIn: logInRequest) {
    return (dispatch: Dispatch) => {
        return axios.post('/api/login/passwordForgot', logIn)
            .then((data) => {
                if (data.status === 200) {
                    Notify("¡Listo! Si tu email está registrado, pronto recibirás un correo electrónico con instrucciones para restablecer tu contraseña.", "success");
                } else {
                    Notify("¡Error! No ha sido posible enviar el correo electrónico de recuperación de contraseña.", "error");
                }
                return data; // Asegúrate de retornar aquí para que la promesa resuelva con datos útiles si necesitas
            })
            .catch((error) => {
                // Es posible que también desees manejar errores aquí o rechazar la promesa
                return null
            });
    }
}


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
//export const reducer: Reducer<LoginState> = (state: LoginState | undefined, incomingAction: Action): LoginState => {
function loginReducer(state: loginState = initialState, incomingAction: Action): loginState {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOG_IN_REQUEST':
            return {
                ...state,
                loginLoaded: loading.pending,
                errorToShow: "",
                typeError: ""
            };

        case 'AUTHENTICATE_RECEIVED':
            return {
                ...state,
                loginLoaded: loading.done,
                refreshLoaded: true,
                isAuthenticated: true,
                userLogged: action.loginResponse.userLogged,
                errorToShow: "",
                typeError: "",
            };

        case 'LOG_OUT':
            localStorage.clear();
            return {
                ...state,
                loginLoaded: loading.done,
                refreshLoaded: true,
                isAuthenticated: false,
                userLogged: inicialiceLoginUser,
                errorToShow: "",
                typeError: "",
            };

        case 'SHOW_ERROR_LOGIN':
            return {
                ...state,
                errorToShow: action.error,
                loginLoaded: loading.done,
                refreshLoaded: true,
                typeError: action.typeError,
            };

        case 'CLEAR_LOGUIN_ERROR':
            return {
                ...state,
                errorToShow: "",
                loginLoaded: loading.done,
                refreshLoaded: true,
                typeError: ""
            };

        default:
            return state;
    }
};

export default loginReducer;