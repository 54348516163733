import React from "react";
import { Route, RouteComponentProps, Redirect, useLocation } from "react-router-dom";
import { allAppRoutes } from "../commonInterfaces/allAppRoutes";

interface PrivateRouteProps {
    Component: React.FC<RouteComponentProps>
    path: string;
    exact?: boolean;
};

const PrivateRoute = ({ Component, path, exact = false }: PrivateRouteProps): JSX.Element => {

    const usePathname = useLocation().pathname;
    const isAuthenticated = !!localStorage.getItem('refreshToken');

    return (
        <Route
            exact={exact}
            path={path}
            render={(props: RouteComponentProps) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: allAppRoutes.login, state: { from: usePathname } }} />
                )
            }
        />
    );
};

export default PrivateRoute;
