import React, { Fragment, useEffect, ReactElement, useState, useRef, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from '../../ApplicationState';
import axios from "axios";
import AutoSuggest from "react-autosuggest";
import Match from 'autosuggest-highlight/match';
import Parse from 'autosuggest-highlight/parse';
import {
    CapitalizefirstLetter, inicialiceCargaCompleta, validateAlphaNumeric,
    validateFormEmpty, client, inicialiceClientsAutocompleteRequest
} from 'aseguisShared';

interface infoToClientAutocomplete {
    setClient: (client: client) => void
    clientName: string
    clientsToExclude?: client[]
    autoFocus?: boolean
    onBlur?: () => void
    onFocus?: () => void
    setErrorForm?: (value: boolean) => void,
    keepClient?: boolean
}

function ClientsAutocomplete(props: infoToClientAutocomplete): ReactElement {

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const [goToApi, setGoToApi] = useState(false)
    const [inputFocus, setInputFocus] = useState(false)
    const [textToSearch, setTextToSearch] = useState("")
    const [clientSuggestions, setClientSuggestions] = useState<client[]>([])
    const [selectedClient, setSelectedClient] = useState<client>(inicialiceCargaCompleta.datosGenerales.client)
    const suggestionsContainerRef = useRef<HTMLSpanElement>(null);

    const refFocus = useRef<HTMLInputElement>(null);
    useLayoutEffect(() => {
        if (null !== refFocus.current && props.autoFocus) {
            refFocus.current.focus()
        }
    })

    function onValueChanged(newValue: string): void {
        var value = CapitalizefirstLetter(validateAlphaNumeric(newValue))
        value = value.trimStart()
        setTextToSearch(value)
        if (value.length === 0) {
            setClientSuggestions([])
        }
    }

    function renderSuggestion(suggestion: client, query: any): JSX.Element {
        const textToHigh = "" + query.query
        const matches = Match(suggestion.nombre.toLowerCase(), textToHigh.toLowerCase(), { insideWords: true });
        const parts = Parse(suggestion.nombre, matches)
        return (
            <span>
                {parts.map((part, index) => {
                    const className = part.highlight ? 'highlight' : '';
                    return (
                        <span className={className} key={index}>{part.text}</span>
                    );
                })}
            </span>
        );
    }

    useEffect(() => {
        if ((goToApi === true) && (isAppLoading === false) && textToSearch !== '') {
            const timeout2 = setTimeout(() => {
                var clientsAutocompleteRequest = inicialiceClientsAutocompleteRequest
                clientsAutocompleteRequest.clientText = textToSearch
                if (props.clientsToExclude) {
                    clientsAutocompleteRequest.clientsToExclude = props.clientsToExclude
                }
                axios
                    .post<client[]>('api/clients/autocomplete/', clientsAutocompleteRequest)
                    .then((response) => {
                        if (response.data) {
                            setClientSuggestions([])
                            if (response.data.length === 1) {
                                const oneClient = response.data[response.data.length - 1]
                                setSelectedClient(oneClient)
                                props.setClient(oneClient)
                                if (props.keepClient === false) {
                                    setTextToSearch("")
                                } else {
                                    setTextToSearch(oneClient.nombre)
                                }
                            } else {
                                setClientSuggestions(response.data)
                            }
                        }
                        setGoToApi(false)
                    })
            }, 200);
            setGoToApi(false)
            return () => { clearTimeout(timeout2) }
        }
    }, [textToSearch])

    useEffect(() => {
        //para cuando se hace click en reestablecer
        if (inputFocus === false && props.clientName !== textToSearch) {
            setTextToSearch(props.clientName)
        }
    }, [inputFocus, props.clientName]);

    useEffect(() => {
        if (props.setErrorForm) {
            if (props.clientName.length < 2) {
                props.setErrorForm(true)
            } else {
                props.setErrorForm(false)
            }
        }
    }, [textToSearch, props.clientName])

    return (
        <Fragment>
            <span ref={suggestionsContainerRef} style={{ flex: "1" }}>
                <AutoSuggest
                    onSuggestionHighlighted={({ suggestion }) => {
                        if (suggestion && suggestionsContainerRef.current) {
                            const index = clientSuggestions.findIndex(s => s === suggestion);
                            const suggestionElements = suggestionsContainerRef.current.querySelectorAll('.react-autosuggest__suggestion');
                            const highlightedElement = suggestionElements[index];
                            if (highlightedElement) {
                                highlightedElement.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'nearest'
                                });
                            }
                        }
                    }}
                    suggestions={clientSuggestions}
                    onSuggestionsClearRequested={() =>
                        setClientSuggestions([])
                    }
                    onSuggestionsFetchRequested={({ value }) => {
                        setGoToApi(true)
                    }}
                    onSuggestionSelected={(_, { suggestionValue, suggestion }) => {
                        setSelectedClient(suggestion)
                        props.setClient(suggestion)
                        if (props.keepClient === false) {
                            setTextToSearch("")
                        }
                    }}
                    getSuggestionValue={(suggestion) =>
                        suggestion.nombre
                    }
                    renderSuggestion={renderSuggestion}
                    inputProps={{
                        placeholder: "Escriba un cliente para autocompletar...",
                        value: textToSearch,
                        onChange: (event, { newValue, method }) => {
                            onValueChanged(newValue)
                        },
                        type: "text",
                        name: "dest_search",
                        className: props.setErrorForm ? validateFormEmpty(props.clientName, 2) : "form-control undefined",
                        autoComplete: "off",
                        onBlur: () => {
                            if (selectedClient.nombre.length > 0) {
                                if (props.keepClient === false) {
                                    setTextToSearch("")
                                } else {
                                    props.setClient(selectedClient);
                                }
                            } else {
                                setTextToSearch("")
                            }

                            setInputFocus(false)
                            setGoToApi(false)
                            setClientSuggestions([])

                            if (props.onBlur) {
                                props.onBlur()
                            }
                        },
                        onFocus: () => {
                            setInputFocus(true)
                            setTextToSearch("")
                            if (props.onFocus) {
                                props.onFocus()
                            }
                        },
                        ref: refFocus
                    }}
                />
            </span>
        </Fragment >
    );
};

export default ClientsAutocomplete;