import notificationReducer, { NotificationState } from "./common/Notifications/notificationStore";
import loginReducer, { loginState } from "./components/Login/LoginStore";
import clientsReducer, { clientsState } from "./components/Clients/ClientsStore";
import vehiclesReducer, { vehiclesState } from "./components/Vehicles/VehiclesStore";
import companyReducer, { companyState } from "./components/Company/CompanyStore";
import employeesReducer, { employeesState } from "./components/Employees/EmployeesStore";
import cargaCompletaReducer, { cargaCompletaState } from "./components/CargaCompleta/CargaCompletaStore";
import paqueteriaReducer, { paqueteriaState } from "./components/Paqueteria/PaqueteriaStore";
import globalReducer, { globalState } from "./common/globalState/globalStateStore";
import billsReducer, { billsState } from "./components/Bills/BillsStore";
import suppliersReducer, { suppliersState } from "./components/Suppliers/SuppliersStore";
import grupajesReducer, { grupajeState } from "./components/Grupajes/GrupajesStore";
import parametrizacionReducer, { parametrizacionState } from "./components/Parametrizacion/ParametrizacionStore";
import vehiclesControlsReducer, { vehiclesControlState } from "./components/VehiclesControls/VehiclesControlsStore";
import reviRepaReducer, { reviRepaState } from "./components/VehiclesRevisionesReparaciones/ReviRepaStore";
import uploadScannedAlbaranesReducer, { uploadScannedAlbaranesState } from "./components/UploadScannedAlbaranes/UploadScannedAlbaranesStore";
import abonosReducer, { abonosState } from "./components/Abonos/AbonosStore";
import registerReducer, { registerState } from "./components/Register/RegisterStore";

export interface ApplicationState {
    notifications: NotificationState;
    login: loginState;
    clients: clientsState;
    vehicles: vehiclesState;
    company: companyState;
    employees: employeesState;
    cargaCompleta: cargaCompletaState;
    paqueteria: paqueteriaState;
    globalState: globalState;
    bills: billsState;
    suppliers: suppliersState;
    grupajes: grupajeState;
    parametrizacion: parametrizacionState;
    vehiclesControls: vehiclesControlState;
    reviRepa: reviRepaState;
    uploadScannedAlbaranes: uploadScannedAlbaranesState;
    abonos: abonosState;
    register: registerState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    login: loginReducer, 
    notifications: notificationReducer,
    clients: clientsReducer,
    vehicles: vehiclesReducer,
    company: companyReducer,
    employees: employeesReducer,
    cargaCompleta: cargaCompletaReducer,
    paqueteria: paqueteriaReducer,
    globalState: globalReducer,
    bills: billsReducer,
    suppliers: suppliersReducer,
    grupajes: grupajesReducer,
    parametrizacion: parametrizacionReducer,
    vehiclesControls: vehiclesControlsReducer,
    reviRepa: reviRepaReducer,
    uploadScannedAlbaranes: uploadScannedAlbaranesReducer,
    abonos: abonosReducer,
    register: registerReducer
};