import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { ApplicationState } from '../../ApplicationState';
import {
    addVehicleControl, setFormsVehicleControl, addOrEditVehicleControl,
    saveFormVehicleControl, saveSearchFilterVehicleControl,
} from './VehiclesControlsStore';
import {
    loginUsers, addingOrEditing, UserPermissions, controlVehicle,
    initicializeControlVehicle, searchFilterList, formatearMatricula
} from "aseguisShared"
import Buttons from "../../common/Buttons/Buttons";
import VehiclesControlsList from "./VehiclesControlsList";
import VehiclesControlsForm from "./VehiclesControlsForm";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import { GetUserPermissionsFromLocalStorage } from "../../common/commonInterfaces/commonFunctions";

const VehiclesControls: React.FC = () => {

    const dispatch: React.Dispatch<any> = useDispatch();

    let { permissions } = GetUserPermissionsFromLocalStorage()

    const vehicleControlForm: controlVehicle = useSelector((state: ApplicationState) => state.vehiclesControls.controlVehicleForm);
    const vehicleControlEditForm: controlVehicle = useSelector((state: ApplicationState) => state.vehiclesControls.controlvehicleEditForm);
    const addingOrEditVehicleControl: addingOrEditing = useSelector((state: ApplicationState) => state.vehiclesControls.addOrEditControlVehicles) || addingOrEditing.none;
    const searchFilterVehiclesControl: searchFilterList = useSelector((state: ApplicationState) => state.vehiclesControls.searchFilterControlVehicles);
    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);

    const [editEnabled, setEditEnabled] = React.useState(addingOrEditing.none);
    const [errorOnForm, setErrorOnForm] = React.useState(false);


    useEffect(() => {
        //en controles, si estamos añadiendo y no estamos en edit, vamos a lista
        if (addingOrEditVehicleControl === addingOrEditing.adding && editEnabled === addingOrEditing.none) {
            usePath.replace(allAppRoutes.vehiclesControlsList);
            dispatch(addOrEditVehicleControl(addingOrEditing.none));
        }
    }, []);

    function enableEditVehicleControl(controlVehicle: controlVehicle): void {
        setEditEnabled(addingOrEditing.none);
        dispatch(setFormsVehicleControl(controlVehicle));
        dispatch(addOrEditVehicleControl(addingOrEditing.edditing));
    }

    function addVehicleControlForm(): void {
        setEditEnabled(addingOrEditing.edditing);
        dispatch(setFormsVehicleControl(initicializeControlVehicle));
        dispatch(addOrEditVehicleControl(addingOrEditing.adding));
    }

    function addNewVehicleControl(): void {
        if (errorOnForm === false) {
            setEditEnabled(addingOrEditing.none);
            dispatch(addVehicleControl({
                ...vehicleControlEditForm,
                user: userLogged
            }));
        }
    }

    function restoreVehicleControlEdit(): void {
        dispatch(saveFormVehicleControl(vehicleControlForm));
    }

    const usePathname = useLocation().pathname;
    const usePath = useHistory()

    return (

        <Fragment>

            <Switch>

                {(permissions.vehiclesControls !== UserPermissions.deshabilitado) &&
                    <Route exact path={allAppRoutes.vehiclesControlsList}>
                        <VehiclesControlsList
                            onClickVehicleControl={(control) => {
                                enableEditVehicleControl(control)
                                usePath.push(allAppRoutes.vehiclesControlsForm + control._id );
                            }}
                            addVehicleControl={() => {
                                addVehicleControlForm()
                                usePath.push(allAppRoutes.vehiclesControlsForm + "new");
                            }}
                            searchFilterVehicleControl={searchFilterVehiclesControl}
                            saveSearchFilterVehicleControl={(searchFilterVehiclesControl) => {
                                dispatch(saveSearchFilterVehicleControl(searchFilterVehiclesControl));
                            }}
                        />
                    </Route>
                }

                {permissions.vehiclesControls !== UserPermissions.deshabilitado &&
                    <Route exact path={allAppRoutes.vehiclesControlsForm + ":id"}>
                        <VehiclesControlsForm
                            isEdditing={editEnabled}
                            setEdditing={setEditEnabled}
                            setErrorForm={setErrorOnForm}
                            errorForm={errorOnForm}
                        />
                    </Route>
                }

                <Redirect from="*" to={allAppRoutes.vehiclesControlsList} />

            </Switch>

            {/*BOTONES*/}
            {usePathname !== allAppRoutes.vehiclesControlsList &&
                <Buttons
                    addOrEdit={addingOrEditVehicleControl}
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                    cancel={() => {
                        setEditEnabled(addingOrEditing.none);
                        dispatch(addOrEditVehicleControl(addingOrEditing.none));
                        usePath.replace(allAppRoutes.vehiclesControlsList);
                    }}
                    add={addNewVehicleControl}
                    from="control vehículo"
                    thereIsList={true}
                    restoreForm={restoreVehicleControlEdit}
                    disableSaveIfError={(errorOnForm === true) ? true : false}
                />
            }

        </Fragment >

    );
};

export default VehiclesControls;

