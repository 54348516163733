import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { addingOrEditing } from "aseguisShared";

interface infoToUploadImage {
    setBase64Image: (imageBase64: string) => void
    base64Form: string

    base64EditForm: string


    colWidth?: string
    center?: boolean

    isEdditing: addingOrEditing
}

function UploadImage({ setBase64Image, base64Form, base64EditForm, center, colWidth, isEdditing }: infoToUploadImage): ReactElement {

    const ref = React.useRef<HTMLInputElement>(null);

    const [imageFile, setImageFile] = useState<File>()
    const [errorText, setErrorText] = useState("")

    const getWidth = () => {
        if (colWidth && colWidth.length > 0) {
            return "form-group col-md-" + colWidth
        } else {
            return "form-group col-md-6"
        }
    }

    const isValidFileUploaded = (file: File) => {
        const validExtensions = ['png', 'jpeg', 'jpg']
        const fileExtension = file.type.split('/')[1]
        return validExtensions.includes(fileExtension)
    }

    useEffect(() => {
        if (imageFile) {

            var correctTypes = true;

            if (!isValidFileUploaded(imageFile)) {
                correctTypes = false
            }

            const fetchData = async () => {

                const base64 = await getBase64(imageFile) as string
                let newBase64 = base64.split(",")
                if (newBase64.length === 2) {
                    setBase64Image(newBase64[1]);
                }
            }

            if (correctTypes) {
                fetchData()
                    .catch(console.error);
            } else {
                setImageFile(undefined)
                if (ref.current) {
                    ref.current.value = ""
                }
                setErrorText("Algún fichero no es válido. Seleccione ficheros 'png' o 'jpg' ")
            }
        }

    }, [imageFile])


    useEffect(() => {
        const timeout = setTimeout(async () => {
            setErrorText("")
        }, 5000);
        return () => { clearTimeout(timeout) }
    }, [errorText])


    const getBase64 = (file: Blob) => {
        return new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = (error) => {
            }
        })
    }

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length > 0) {
            setImageFile(acceptedFiles[0]);
        }
    }, [])

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg'],
        },
        onDrop: onDrop
    })

    return (
        <div>
            {(isEdditing === addingOrEditing.edditing) ?

                <div {...getRootProps()}>
                    <input className="drop-input " {...getInputProps()} />
                    <img
                        style={center ? { margin: "0 auto" } : { margin: "0" }}
                        id="image_branding"
                        className="image-branding"
                        src={`data:image/jpeg;base64,${base64EditForm}`}
                        alt=""
                    />
                    <p className="m-0 p-0">
                        <small>(.jpeg, .png)</small>
                    </p>
                </div>

                :

                <div>
                    <img
                        style={center ? { margin: "0 auto" } : { margin: "0" }}
                        id="image_branding"
                        className="image-branding"
                        src={`data:image/jpeg;base64,${base64Form}`}
                        alt=""
                    />
                    <p className="m-0 p-0">
                        <small>(.jpeg, .png)</small>
                    </p>
                </div>

            }
            <div className="form-row">

                <div className="form-group col-md-6">
                    <div>
                        {errorText.length > 0 &&
                            <div>
                                {errorText}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

};

export default UploadImage;