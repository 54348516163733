import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, ReactElement, useState } from "react";
import { ApplicationState } from '../../ApplicationState';
import axios from "axios";
import {
    inicialiceVehiclesControlsStats, vehiclesControlsStats, controlVehicleTypes,
    CalculatelistVehiclesControl, formatearMatricula, getFormattedDate, DateIsNearVto, inicialiceDate
} from "aseguisShared";
import { deleteNotesAndObservaciones } from "../Vehicles/VehiclesStore";

function VehiclesControlsStats(): ReactElement {

    const dispatch = useDispatch();

    const controlsTypes: controlVehicleTypes[] = useSelector((state: ApplicationState) => state.parametrizacion.parametrizacionForm.vehiclesControlesTypes);
    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);

    const [vehiclesControlsStats, setVehiclesControlsStats] = useState<vehiclesControlsStats[]>([inicialiceVehiclesControlsStats])
    const [goToSearchControlsStats, setGoToSearchControlsStats] = useState(true)
    const [mostrar, setMostrar] = useState(true)

    useEffect(() => {
        if (goToSearchControlsStats === true) {
            axios
                .get<vehiclesControlsStats[]>('api/vehicles/controlList/stats/')
                .then((response) => {
                    if (response.data) {
                        setVehiclesControlsStats(response.data);
                    }
                    setGoToSearchControlsStats(false)
                })
        }
    }, [goToSearchControlsStats])

    const vehiclesControlsStatesList: JSX.Element[] = vehiclesControlsStats.map(vehicleStats => {
        const CalculatelistControls = CalculatelistVehiclesControl(controlsTypes, vehicleStats.vehicle.lastControl.controlsList)
        var goToDelete = false
        if (vehicleStats.vehicle.lastControl.controlsObservaciones !== "") {
            goToDelete = true
        }
        if (goToDelete === false) {
            vehicleStats.vehicle.lastControl.controlsList.map(control => {
                if (control.observaciones !== "") {
                    goToDelete = true
                }
            })
        }
        return (
            <tr className={`table-row`}
                key={vehicleStats.vehicle.id}>
                <td scope="row">
                    <b>
                        <div>{formatearMatricula(vehicleStats.vehicle.matricula)}</div>
                        {vehicleStats.showAssociated === true &&
                            <div>{vehicleStats.vehicle.lastControl.associatedVehicle && formatearMatricula(vehicleStats.vehicle.lastControl.associatedVehicle?.matricula)}</div>
                        }
                    </b>
                </td>
                <td>
                    {vehicleStats.vehicle.marca + " " + vehicleStats.vehicle.modelo}
                    {vehicleStats.showAssociated === true &&
                        <div>{vehicleStats.vehicle.lastControl.associatedVehicle?.marca + " " + vehicleStats.vehicle.lastControl.associatedVehicle?.modelo}</div>
                    }
                </td>
                <td>
                    <div style={{ color: DateIsNearVto(vehicleStats.vehicle.finItv) }}>{getFormattedDate(vehicleStats.vehicle.finItv)}</div>
                    {(vehicleStats.showAssociated === true && vehicleStats.vehicle.lastControl.associatedVehicle) &&
                        <div style={{ color: DateIsNearVto(vehicleStats.vehicle.lastControl.associatedVehicle.finItv) }}>{getFormattedDate(vehicleStats.vehicle.lastControl.associatedVehicle.finItv)}</div>
                    }

                </td>
                <td>
                    {(vehicleStats.vehicle.vehicleType.tieneTacografo === false) ?
                        <div>n/a</div>
                        :
                        <div style={{ color: DateIsNearVto(vehicleStats.vehicle.revTacografo) }}>{getFormattedDate(vehicleStats.vehicle.revTacografo)}</div>
                    }
                    {(vehicleStats.vehicle.lastControl.associatedVehicle?.vehicleType.tieneTacografo === false) &&
                        <div>n/a</div>
                    }
                </td>
                <td>
                    {(vehicleStats.vehicle.lastControl.controlsObservaciones !== "" && vehicleStats.vehicle.lastControl.controlsObservaciones !== null) &&
                        <><b> Observaciones: </b> {" " + vehicleStats.vehicle.lastControl.controlsObservaciones} </>
                    }
                    {CalculatelistControls.noRealizados.map(control => {
                        return (
                            <li key={control.id} style={{ color: '#E23F3F' }}>
                                <b>{control.description}</b> {": Control Pendiente "}
                                {(control.fecAlta !== inicialiceDate.year1000) ?
                                    <>
                                        <b> - Anterior control: </b> {(new Date(control.fecAlta).getTime() === new Date(inicialiceDate.year1000).getTime()) ? "Sin control previo" : getFormattedDate(control.fecAlta)}
                                        {(control.observaciones !== "") && <><b> - Nota: </b> {" " + control.observaciones} </>}
                                    </>
                                    : <> - Sin control anterior</>
                                }
                            </li>
                        )
                    })}
                    {CalculatelistControls.realizados.map(control => {
                        return (
                            <li key={control.id}>
                                <b>{control.description}</b> {": Realizado el " + getFormattedDate(control.fecAlta)}
                                {(control.observaciones !== "") && <><b> - Nota: </b> {" " + control.observaciones} </>}
                            </li>
                        )
                    })}
                </td>
                <td>
                    {(goToDelete === true) &&
                        <button className="btn btn-success btn-info btn-sm"
                            onClick={() => {
                                if (goToDelete === true) {
                                    dispatch(deleteNotesAndObservaciones(vehicleStats.vehicle));
                                    setGoToSearchControlsStats(true)
                                }
                            }}>
                            <i className="fas fa-check"></i>
                        </button>
                    }
                </td>
            </tr>
        );
    });

    return (
        <Fragment>
            <div className="card shadow mb-4">
                <div className="header-buttons">
                    <button
                        className="btn btn-info left-margin btn-sm"
                        onClick={() => {
                            setMostrar(!mostrar)
                        }}>
                        {mostrar ? "Ocultar" : "Mostrar"}
                    </button>
                </div>
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">
                        Vehículos con controles pendientes
                        {(isAppLoading === true) && <span className="spinner-border spinner-border-sm ml-3"></span>}
                    </h6>
                </div>
                {mostrar &&
                    <div className="card-body">
                        {vehiclesControlsStats.length > 0 ?
                            <div className="table-responsive table-hover portlet-700">
                                {vehiclesControlsStats[0].vehicle.id > 0 ?
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Matrícula</th>
                                                <th scope="col">Marca y Modelo</th>
                                                <th scope="col">Fin ITV</th>
                                                <th scope="col">Fin tacógrafo </th>
                                                <th scope="col">Controles</th>
                                                <th scope="col">Borrar notas</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {vehiclesControlsStatesList}
                                        </tbody>
                                    </table>
                                    : "Todos los vehículos tienen los controles al dia"
                                }
                            </div>
                            : "Todos los vehículos tienen los controles al dia"
                        }

                    </div>
                }
            </div>
        </Fragment >
    );
};

export default VehiclesControlsStats;
