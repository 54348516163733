import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../ApplicationState";

interface infoToCargaForm {
    setFilesOnBase64: (base64Files: string[]) => void
    onFileUpload: () => void
    colWidth?: string
}

function UploadFile({ setFilesOnBase64, onFileUpload, colWidth }: infoToCargaForm): ReactElement {

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);

    const ref = React.useRef<HTMLInputElement>(null);

    const [fileList, setFileList] = useState<FileList>()
    const [isLoading, setIsLoading] = useState(false)
    const [files64, setfiles64] = useState(0)

    const [errorText, setErrorText] = useState("")

    const getWidth = () => {
        if (colWidth && colWidth.length > 0) {
            return "form-group col-md-" + colWidth
        } else {
            return "form-group col-md-6"
        }
    }

    const isValidFileUploaded = (file: File) => {
        const validExtensions = ['pdf', 'tif', 'tiff', 'PDF', 'TIF', 'TIFF']
        const fileExtension = file.type.split('/')[1]
        return validExtensions.includes(fileExtension)
    }

    useEffect(() => {

        var correctTypes = true;

        if (fileList) {
            for (let i = 0; i < fileList.length; i++) {
                if (!isValidFileUploaded(fileList[i])) {
                    correctTypes = false
                }
            }
        }

        const fetchData = async () => {
            if (fileList) {
                const Bases64: string[] = []
                for (let i = 0; i < fileList.length; i++) {
                    const base64 = await getBase64(fileList[i]) as string
                    let newBase64 = base64.split(",")
                    if (newBase64.length === 2) {
                        Bases64.push(newBase64[1]);
                    }
                }
                setFilesOnBase64(Bases64)
                setfiles64(Bases64.length)
            }
            setIsLoading(false)
        }

        if (correctTypes) {
            fetchData()
                .catch(console.error);
        } else {
            setIsLoading(false)
            setFileList(undefined)
            setfiles64(0)
            if (ref.current) {
                ref.current.value = ""
            }
            setErrorText("Algún fichero no es válido. Seleccione ficheros 'pdf' o 'tif' ")
        }

    }, [fileList])


    useEffect(() => {
        const timeout = setTimeout(async () => {
            setErrorText("")
        }, 5000);
        return () => { clearTimeout(timeout) }
    }, [errorText])


    const getBase64 = (file: Blob, cb?: any) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = (error) => {
            }
        })
    }

    const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true)
        setErrorText("")
        if (event.target.files) {
            setFileList(event.target.files);
        }
    }

    const onUpload = () => {
        setFilesOnBase64([])
        setfiles64(0)
        if (ref.current) {
            ref.current.value = ""
        }
        onFileUpload()
    }

    return (
        <div>

            <div className="card-body">

                <div className="form-row pt-3">
                    <div className={getWidth()} >
                        <div className="custom-file" id="customFile">
                            <input
                                lang="es"
                                id="customFileLang"
                                aria-describedby="inputGroupFileAddon01"
                                className="custom-file-input"
                                ref={ref}
                                type="file"
                                onChange={onFileChange}
                                multiple={true}
                            />
                            <label className="custom-file-label" htmlFor="customFileLang">{files64 > 0 ? (files64 + (files64 === 1 ? " fichero seleccionado" : " ficheros seleccionados")) : "Seleccionar ficheros"}</label>
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-md-6">
                        <div>

                            <button
                                style={{ marginTop: "30px" }}
                                className={(fileList) ? "btn btn-info mb-1" : "btn btn-info disabled mb-1"}
                                onClick={() => {
                                    if (fileList && fileList?.length > 0) {
                                        onUpload()
                                    }
                                }}
                                disabled={(fileList && fileList?.length <= 0) || (errorText.length > 0)}
                            >
                                Añadir ficheros
                            </button>

                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <div>
                            {(isAppLoading || isLoading) &&
                                <div>
                                    {"Procesando... "}
                                    <span className="spinner-border spinner-border-sm mr-4"></span>
                                </div>
                            }
                            {errorText.length > 0 &&
                                <div>
                                    {errorText}
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </div>


        </div>
    );



};

export default UploadFile;