import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { ApplicationState } from '../../ApplicationState';
import VehiclesList from "./VehiclesList";
import VehiclesForm from "./VehiclesForm";
import {
    addVehicle, setFormsVehicle, addOrEditVehicle, editVehicle,
    saveFormVehicle, saveSearchFilterVehicle, deleteVehicle
} from './VehiclesStore';
import { formatearMatricula, initialVehicle, addingOrEditing,searchFilterList, UserPermissions, vehicle } from "aseguisShared"
import { GetUserPermissionsFromLocalStorage } from "../../common/commonInterfaces/commonFunctions"
import Buttons from "../../common/Buttons/Buttons";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";


const Vehicles: React.FC = () => {

    const dispatch: React.Dispatch<any> = useDispatch();

    let { permissions } = GetUserPermissionsFromLocalStorage()

    const vehicleForm: vehicle = useSelector((state: ApplicationState) => state.vehicles.vehicleForm);
    const vehicleEditForm: vehicle = useSelector((state: ApplicationState) => state.vehicles.vehicleEditForm);
    const addingOrEditVehicle: addingOrEditing = useSelector((state: ApplicationState) => state.vehicles.addOrEditVehicles) || addingOrEditing.none;
    const searchFilterVehicles: searchFilterList = useSelector((state: ApplicationState) => state.vehicles.searchFilterVehicles);

    const [editEnabled, setEditEnabled] = React.useState(addingOrEditing.none);
    const [errorOnForm, setErrorOnForm] = React.useState(false);

    function enableEditVehicle(vehicle: vehicle): void {
        setEditEnabled(addingOrEditing.none);
        dispatch(setFormsVehicle(vehicle));
        dispatch(addOrEditVehicle(addingOrEditing.edditing));
    }

    function addVehicleForm(): void {
        setEditEnabled(addingOrEditing.edditing);
        dispatch(setFormsVehicle(initialVehicle));
        dispatch(addOrEditVehicle(addingOrEditing.adding));
    }

    function addNewVehicle(): void {
        if (errorOnForm === false) {
            setEditEnabled(addingOrEditing.none);
            dispatch(addVehicle(vehicleEditForm));
        }
    }

    function saveEditedVehicle(): void {
        if (errorOnForm === false) {
            setEditEnabled(addingOrEditing.none);
            dispatch(editVehicle(vehicleEditForm));
        }
    }

    function restoreVehicleEdit(): void {
        dispatch(saveFormVehicle(vehicleForm));
    }

    const usePathname = useLocation().pathname;
    const usePath = useHistory()

    return (

        <Fragment>

            <Switch>

                {permissions.vehicles !== UserPermissions.deshabilitado &&
                    <Route exact path={allAppRoutes.vehiclesList}>
                        <VehiclesList
                            onClickVehicle={(vehicleToUpdate) => {
                                enableEditVehicle(vehicleToUpdate)
                                usePath.push(allAppRoutes.vehiclesForm + vehicleToUpdate._id );
                            }}
                            addVehicle={() => {
                                addVehicleForm()
                                usePath.push(allAppRoutes.vehiclesForm + "new");
                            }}
                            searchFilterVehicle={searchFilterVehicles}
                            saveSearchFilterVehicle={(searchFilterVehicles) => {
                                dispatch(saveSearchFilterVehicle(searchFilterVehicles));
                            }}
                        />
                    </Route>
                }

                {permissions.vehicles !== UserPermissions.deshabilitado &&
                    <Route exact path={allAppRoutes.vehiclesForm + ":id"}>
                        <VehiclesForm
                            isEdditing={editEnabled}
                            setEdditing={setEditEnabled}
                            setErrorForm={setErrorOnForm}
                            errorForm={errorOnForm}
                        />
                    </Route>
                }

                <Redirect from="*" to={allAppRoutes.vehiclesList} />

            </Switch>

            {/*BOTONES*/}
            {usePathname !== allAppRoutes.vehiclesList &&
                <Buttons
                    addOrEdit={addingOrEditVehicle}
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                    cancel={() => {
                        setEditEnabled(addingOrEditing.none);
                        usePath.replace(allAppRoutes.vehiclesList);
                    }}
                    add={addNewVehicle}
                    save={saveEditedVehicle}
                    from="vehículo"
                    thereIsList={true}
                    restoreForm={restoreVehicleEdit}
                    delete={() => {
                        dispatch(deleteVehicle(vehicleEditForm));
                        setEditEnabled(addingOrEditing.none);
                        usePath.replace(allAppRoutes.vehiclesList);
                    }}
                    deleteText={vehicleEditForm.matricula}
                    disableSaveIfError={(errorOnForm === true) ? true : false}
                />
            }

        </Fragment >

    );
};

export default Vehicles;
