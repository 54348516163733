import { Action } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface NotificationState {
    notifications: notification[];
}

export interface notification {
    id: number,
    date: Date,
    title: string,
    text: string
}

const initialState: NotificationState = {
    notifications: [{ id: 1, date: new Date(), text: "prueba", title: "bienvenido" }]
};


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface addNotificationAction {
    type: 'ADD_NOTIFICATION',
    text: string,
    title: string
}
export interface removeNotificationAction {
    type: 'REMOVE_NOTIFICATION',
    id: number
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = addNotificationAction | removeNotificationAction;

// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
/* They don't directly mutate state, but they can have external side-effects (such as loading data).*/

export function addNotification(title: string, text: string): addNotificationAction {
    return { type: 'ADD_NOTIFICATION', text: text, title: title };
}

export function removeNotification(id: number): removeNotificationAction {
    return { type: 'REMOVE_NOTIFICATION', id: id };
}

// ----------------
// REDUCERS - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
//export const notificationReducer: Reducer<NotificationState> = (state: NotificationState | undefined, incomingAction: Action): NotificationState => {
function notificationReducer(state: NotificationState = initialState, incomingAction: Action): NotificationState {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ADD_NOTIFICATION': {
            let maxId: number = Math.max.apply(Math, state.notifications.map(o => o.id));
            if (maxId === -Infinity) { maxId = 0; }
            let newItem = {
                id: maxId + 1,
                date: new Date(),
                title: action.title,
                text: action.text
            };
            return { ...state, notifications: [...state.notifications, newItem] };
        }
        case 'REMOVE_NOTIFICATION': {
            return {
                ...state, notifications: state.notifications
                    .filter(NotificationState => NotificationState.id !== action.id)
            }
        }
        default:
            return state;
    }
}

export default notificationReducer;