import { Action, Dispatch } from 'redux';
import { ApplicationState } from '../../ApplicationState';
import axios from 'axios';
import { company, loading, initialCompany } from "aseguisShared";
import { Notify } from '../../common/Toastify/Notify';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface companyState {
    company: company
    companyEditForm: company
    isGetLoading: number,
    isSaveLoading: number,
}

export const initialState: companyState = {
    company: initialCompany,
    companyEditForm: initialCompany,
    isGetLoading: loading.pending,
    isSaveLoading: loading.done,
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface saveCompanyRequestAction {
    type: 'SAVE_COMPANY_REQUEST'
}

export interface saveCompanyReceivedAction {
    type: 'SAVE_COMPANY_RECEIVED',
    company: company
}

export interface RequestCompanyAction {
    type: 'REQUEST_COMPANY'
}

export interface ReceivedCompanyAction {
    type: 'RECEIVED_COMPANY',
    company: company
}

export interface saveCompanyFormAction {
    type: 'SAVE_COMPANY_FORM',
    company: company
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = saveCompanyRequestAction | saveCompanyReceivedAction |
    RequestCompanyAction | ReceivedCompanyAction | saveCompanyFormAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export function saveCompanyForm(company: company): saveCompanyFormAction {
    return {
        type: 'SAVE_COMPANY_FORM',
        company: company
    };
}

/**************************************************************
 **************************************************************
 *
 * @param COMPANY_OPERATIONS
 *
 * ************************************************************
 **************************************************************/

export function saveCompany(company: company) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: 'SAVE_COMPANY_REQUEST' });
        return axios.put('api/company/', company)
            .then((data) => {
                Notify("Compañía modificada", "success")
                dispatch({ type: 'SAVE_COMPANY_RECEIVED', company: data.data });
            })
            .catch((error) => {
            })
    }
}

export function requestCompany() {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        return axios.get('api/company')
            .then((data) => {
                dispatch({ type: 'RECEIVED_COMPANY', company: data.data });
            })
            .catch((error) => {
            })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
function companyReducer(state: companyState = initialState, incomingAction: Action): companyState {

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SAVE_COMPANY_REQUEST': {
            return {
                ...state,
                isSaveLoading: loading.pending
            };
        }
        case 'SAVE_COMPANY_RECEIVED': {
            return {
                ...state,
                isSaveLoading: loading.done,
                company: action.company,
                companyEditForm: action.company
            };
        }
        case 'REQUEST_COMPANY': {
            return {
                ...state,
                isGetLoading: loading.pending
            };
        }
        case 'RECEIVED_COMPANY': {
            return {
                ...state,
                isGetLoading: loading.done,
                company: action.company,
                companyEditForm: action.company
            };
        }
        case 'SAVE_COMPANY_FORM': {
            return {
                ...state,
                companyEditForm: action.company
            };
        }
        default:
            return state;
    }
};

export default companyReducer;