import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { ApplicationState } from '../../ApplicationState';
import CompanyForm from "./CompanyForm";
import Tariff from "../../common/Tariff/Tariff";
import { requestCompany, saveCompany, saveCompanyForm } from './CompanyStore';
import { tariff, company, addingOrEditing, UserPermissions, inicialiceTariff } from "aseguisShared";
import Buttons from "../../common/Buttons/Buttons";
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import { GetUserPermissionsFromLocalStorage } from "../../common/commonInterfaces/commonFunctions";
import OptionButton from "../../common/Inputs/OptionButton";

const Company: React.FC = () => {

    const dispatch: React.Dispatch<any> = useDispatch();
    const usePathname = useLocation().pathname

    let { permissions } = GetUserPermissionsFromLocalStorage()

    const company: company = useSelector((state: ApplicationState) => state.company.company);
    const companyEditForm: company = useSelector((state: ApplicationState) => state.company.companyEditForm);
    const editTariff: tariff = useSelector((state: ApplicationState) => state.company.companyEditForm.tariff) || inicialiceTariff;
    const originalTariff: tariff = useSelector((state: ApplicationState) => state.company.company.tariff) || inicialiceTariff;

    const [editEnabled, setEditEnabled] = React.useState(addingOrEditing.none);
    const [errorOnForm, setErrorOnForm] = React.useState(false);
    const [errorOnTariffs, setErrorOnTariffs] = React.useState(false);

    function saveEditTariff(tariff: tariff): void {
        dispatch(saveCompanyForm({ ...companyEditForm, tariff: tariff }));
    }

    function saveAllCompany(): void {
        if (errorOnForm === false && errorOnTariffs === false) {
            setEditEnabled(addingOrEditing.none);
            dispatch(saveCompany(companyEditForm));
        }
    }

    function restoreCompanyEdit(): void {
        dispatch(saveCompanyForm(company));
    }

    useEffect(() => {
        dispatch(requestCompany());
    }, [])

    return (

        <Fragment>

            {(permissions.compania !== UserPermissions.deshabilitado && permissions.companiaTariff !== UserPermissions.deshabilitado) &&
                <div className="row">
                    <div className="form-group col-md-6">
                        <OptionButton
                            route={allAppRoutes.companyForm}
                            text={"General"}
                            linkTo={allAppRoutes.companyForm}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <OptionButton
                            route={allAppRoutes.companyTariffs}
                            text={"Tarifas Generales"}
                            linkTo={allAppRoutes.companyTariffs}
                        />
                    </div>
                </div>
            }

            <Switch>
                {permissions.compania !== UserPermissions.deshabilitado &&
                    <Route exact path={allAppRoutes.companyForm}>
                        <CompanyForm
                            isEdditing={editEnabled}
                            setEdditing={setEditEnabled}
                            setErrorForm={setErrorOnForm}
                            errorForm={errorOnForm}
                        />
                    </Route>
                }
                {permissions.companiaTariff !== UserPermissions.deshabilitado &&
                    <Route exact path={allAppRoutes.companyTariffs}>
                        <Tariff
                            originalTariff={originalTariff}
                            cardText={"# " + companyEditForm.nombre}
                            editTariff={editTariff}
                            setEditTariff={saveEditTariff}
                            isEdditing={editEnabled}
                            setErrorForm={setErrorOnTariffs}
                            errorForm={errorOnTariffs}
                        />
                    </Route>
                }
                <Redirect from="*" to={allAppRoutes.companyForm} />
            </Switch>

            {/*BOTONES*/}
            <Buttons
                addOrEdit={editEnabled}
                editEnabled={editEnabled}
                setEditEnabled={setEditEnabled}
                save={saveAllCompany}
                restoreForm={restoreCompanyEdit}
                from="compañia"
                thereIsList={false}
                disableSaveIfError={(errorOnForm === true || errorOnTariffs === true) ? true : false}
            />

        </Fragment >

    );
};

export default Company;