import axios from "axios";
import { useEffect, useState } from "react";

export const VehicleTypeValidateApi = () => {

    const [idVehicleType, setIdVehicleType] = useState(0);
    const [existsVehicleType, setExistsVehicleType] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const validateVehicleType = async () => {

        if (idVehicleType > 0) {
            setIsLoading(true)

            const resp = await axios.get('api/parametrizacion/existVehicleType/' + idVehicleType)
                .then((data) => {
                    setExistsVehicleType(data.data)
                })
                .catch((error) => {
                    setExistsVehicleType(true)
                })

            setIsLoading(false)

        }
    }

    useEffect(() => {
        validateVehicleType()
    }, [])

    useEffect(() => {
        validateVehicleType()
    }, [idVehicleType])

    return {
        isLoading,
        setIdVehicleType,
        existsVehicleType,
    }

}
