import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, ReactElement } from "react";
import { ApplicationState } from '../../ApplicationState';
import { requestAllVehicles, exportVehiclesToExcel } from './VehiclesStore';
import VehiclesListFilter from "./VehiclesListFilter";
import { filterVehiclesTableTH } from "../../common/commonInterfaces/thFilters";
import { Pagination } from "../../common/commonInterfaces/commonFunctions";
import { formatearMatricula, vehicle, searchFilterList, getFormattedDate } from "aseguisShared";
import { ButtonEditarPrivate } from "../../common/Buttons/ButtonEditarPrivate";
import AddButtonList from "../../common/Buttons/AddButtonList";

interface infoToVehicleList {
    onClickVehicle: (vehicle: vehicle) => void
    addVehicle?: () => void
    searchFilterVehicle: searchFilterList
    saveSearchFilterVehicle: (filter: searchFilterList) => void
}

function VehiclesList(props: infoToVehicleList): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();

    const allVehicles: vehicle[] = useSelector((state: ApplicationState) => state.vehicles.vehicles);
    const countAllVehicles: number = useSelector((state: ApplicationState) => state.vehicles.totalVehiclesList);
    const numPage: number = useSelector((state: ApplicationState) => state.vehicles.actualPage);
    const totalPages: number = useSelector((state: ApplicationState) => state.vehicles.totalPages);
    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const vehicleEditForm: vehicle = useSelector((state: ApplicationState) => state.vehicles.vehicleEditForm);

    const isModifyEnabled = ButtonEditarPrivate().isModifyEnabled();

    useEffect(() => {
        if (props.searchFilterVehicle.withTimeout) {
            const timeout = setTimeout(() => {
                dispatch(requestAllVehicles(props.searchFilterVehicle));
            }, 600)
            return () => clearTimeout(timeout)
        } else {
            dispatch(requestAllVehicles(props.searchFilterVehicle));
        }
    }, [props.searchFilterVehicle, vehicleEditForm]);

    const vehiclesList: JSX.Element[] = allVehicles.map((vehicle, index) => {
        return (
            <tr className={`table-row`}
                onClick={() => {
                    props.onClickVehicle(vehicle)
                }}
                key={index}>
                <td scope="row"><b>{vehicle.id}</b></td>
                <td>{formatearMatricula(vehicle.matricula)}</td>
                <td>{vehicle.marca}</td>
                <td>{vehicle.modelo}</td>
                <td>{vehicle.vehicleType.description}</td>
                <td>{getFormattedDate(vehicle.finItv)}</td>
            </tr>
        );
    });

    return (

        <Fragment>

            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        {/*anadir button*/}
                        {(props.addVehicle && isModifyEnabled) &&
                            <div className="header-buttons">
                                <button
                                    className="btn btn-info left-margin btn-sm"
                                    onClick={() => {
                                        if (allVehicles.length > 0) {
                                            dispatch(exportVehiclesToExcel({
                                                ...props.searchFilterVehicle,
                                                getAll: true
                                            }))
                                        }
                                    }}>
                                    Exportar a Excel
                                </button>

                                <AddButtonList
                                    addCallback={props.addVehicle}
                                />
                                
                            </div>
                        }

                        <div className="card-header">
                            <div className="form-inline">
                                <h6 className="m-0 font-weight-bold text-green">Lista de vehículos {"(" + countAllVehicles + ")"}</h6>
                                {(isAppLoading === true) &&
                                    <span className="spinner-border spinner-border-sm ml-4"></span>
                                }
                            </div>
                            <br></br>
                            <VehiclesListFilter
                                searchFilterVehicles={props.searchFilterVehicle}
                                saveSearchFilterVehicles={props.saveSearchFilterVehicle}
                            />
                        </div>

                        <div className="card-body">
                            <div className="table-responsive table-hover">

                                <table className="table table-sm">
                                    <thead className="thead-light">
                                        <tr>
                                            {filterVehiclesTableTH(props.saveSearchFilterVehicle, props.searchFilterVehicle, "#")}
                                            {filterVehiclesTableTH(props.saveSearchFilterVehicle, props.searchFilterVehicle, "Matrícula")}
                                            {filterVehiclesTableTH(props.saveSearchFilterVehicle, props.searchFilterVehicle, "Marca")}
                                            {filterVehiclesTableTH(props.saveSearchFilterVehicle, props.searchFilterVehicle, "Modelo")}
                                            {filterVehiclesTableTH(props.saveSearchFilterVehicle, props.searchFilterVehicle, "Tipo")}
                                            {filterVehiclesTableTH(props.saveSearchFilterVehicle, props.searchFilterVehicle, "Fin ITV")}
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {vehiclesList}

                                    </tbody>

                                </table>
                            </div>
                        </div>

                        {/* paginacion */}
                        {Pagination(numPage, totalPages, props.searchFilterVehicle, props.saveSearchFilterVehicle)}

                    </div>
                </div>
            </div>

        </Fragment >

    );
};

export default VehiclesList;
