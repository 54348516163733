import React, {ReactElement, ReactNode} from "react";
import imageLogo from "../../assets/newLogo3.png";
import './Register.css';

interface RegisterTemplateProps {
    children: ReactNode;
}

function RegisterTemplate({children}: RegisterTemplateProps): ReactElement {
    return (
        <div id="register-page-container">
            <div id="register-side-bar">
                <img src={imageLogo} alt="Logo"/>
                <p style={{fontWeight: "bold"}}>El primer software de gestión en la nube diseñado para pymes de Transporte</p>
                <p>Todo lo que necesitas para gestionar tu empresa desde cualquier lugar</p>
            </div>
            <div id="register-form-container">
                {children}
            </div>
        </div>
    );
}

export default RegisterTemplate;
