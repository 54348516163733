import { useSelector } from "react-redux";
import React, { Fragment, useEffect, ReactElement, useState } from "react";
import axios from "axios";
import { ApplicationState } from "../../ApplicationState";
import { getFormattedDate, DateIsNearVto, employee, inicialiceEmployee } from "aseguisShared";

function EmployeesStats(): ReactElement {

    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);

    const [Employeestats, setEmployeesStats] = useState<employee[]>([inicialiceEmployee])
    const [mostrar, setMostrar] = useState(true)

    useEffect(() => {
        axios
            .get<employee[]>('api/employees/stats/')
            .then((response) => {
                if (response.data) {
                    setEmployeesStats(response.data);
                }
            })
            .catch((e) => {
            })
    }, [])

    const employeesStateList: JSX.Element[] = Employeestats.map(employee => {
        return (
            <tr className={`table-row`}
                key={employee.id}>
                <td scope="row"><b>{employee.name + " " + employee.apellido1 + " " + employee.apellido2}</b></td>
                <td style={{ color: DateIsNearVto(employee.vtoRevMedica) }}>{getFormattedDate(employee.vtoRevMedica)}</td>
                <td style={{ color: DateIsNearVto(employee.vtoPrevencion) }}>{getFormattedDate(employee.vtoPrevencion)}</td>
                {employee.isChofer === true ?
                    <td style={{ color: DateIsNearVto(employee.vtoCap) }}>{getFormattedDate(employee.vtoCap)}</td>
                    :
                    <td>n/a</td>
                }
                {employee.isChofer === true ?
                    <td style={{ color: DateIsNearVto(employee.vtoTacografo) }}>{getFormattedDate(employee.vtoTacografo)}</td>
                    :
                    <td>n/a</td>
                }
            </tr>
        );
    });

    return (
        <Fragment>
            <div className="card shadow mb-4">
                <div className="header-buttons">
                    <button
                        className="btn btn-info left-margin btn-sm"
                        onClick={() => {
                            setMostrar(!mostrar)
                        }}>
                        {mostrar ? "Ocultar" : "Mostrar"}
                    </button>
                </div>
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">
                        Vencimientos
                        {(isAppLoading === true) && <span className="spinner-border spinner-border-sm ml-3"></span>}
                    </h6>
                </div>
                {mostrar &&
                    <div className="card-body">
                        {Employeestats.length > 0 ?
                            <div className="table-responsive table-hover portlet-700">
                                {Employeestats[0].id > 0 ?
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Nombre</th>
                                                <th scope="col">Revisión Médica</th>
                                                <th scope="col">Prevención riesgos laborales</th>
                                                <th scope="col">CAP</th>
                                                <th scope="col">Tacógrafo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {employeesStateList}
                                        </tbody>

                                    </table>
                                    : "Ningún empleado con CAP vencido"
                                }

                            </div>
                            : "Ningún empleado con CAP vencido"
                        }
                    </div>
                }
            </div>
        </Fragment >
    );
};

export default EmployeesStats;
