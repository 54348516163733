import React, { Fragment, ChangeEvent, ReactElement } from "react";
import {
    validateAlphaNumeric, validateFormEmpty, getFormattedDate,
    formStates, CapitalizefirstLetter, incidenceType
} from "aseguisShared";

interface infoIncidencesTypesList {
    allIncidencesTypes: incidenceType[];
    formIncidenceType: incidenceType
    setIncidenceType: (formIncidence: incidenceType) => void

    formState: number
    isEdditing: number
}

function IncidencesTypesList(props: infoIncidencesTypesList): ReactElement {

    const listIncidencesTypes: JSX.Element[] = [...props.allIncidencesTypes].reverse().map((incidenceType) => {

        if (incidenceType && props.formIncidenceType && incidenceType.id === props.formIncidenceType.id && props.formState === formStates.edit) {
            return (
                <tr className="table-row" key={incidenceType.id}>
                    <td>
                        <label>{getFormattedDate(incidenceType.fecAlta)}</label>
                    </td>
                    <td><input
                        type="text"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                            props.setIncidenceType({
                                ...props.formIncidenceType,
                                description: value
                            });
                        }}
                        value={props.formIncidenceType.description}
                        className={validateFormEmpty(props.formIncidenceType.description, 2)}
                        name="description" />
                    </td>
                </tr>
            )
        }
        else {
            return (
                <tr className={`table-row ${(incidenceType && props.formIncidenceType && incidenceType.id === props.formIncidenceType.id) ? "selected" : ""}`}
                    onClick={() => {
                        props.setIncidenceType(incidenceType);
                    }}
                    key={incidenceType.id}>
                    <td>{getFormattedDate(incidenceType.fecAlta)}</td>
                    <td scope="row"><b>{incidenceType.description}</b></td>
                </tr>
            )
        }
    });


    return (

        <Fragment>

            <div className="card-body">

                <div className="row">

                    <div className="form-group col">
                        <h6 className="mb-2 font-weight-bold text-green">Historial</h6>

                        <div className="table-responsive portlet-600"
                        >

                            {(
                                (props.formState === formStates.none && props.allIncidencesTypes.length > 0) ||
                                (props.formState === formStates.edit) || (props.formState === formStates.add)
                            ) ?
                                <table className="table">

                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Fecha de alta</th>
                                            <th scope="col">Descripción</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {props.formState === formStates.add &&
                                            <tr className="table-row">
                                                <td>
                                                    <label>{getFormattedDate()}</label>
                                                </td>
                                                <td><input
                                                    type="text"
                                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                        var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                                        props.setIncidenceType({
                                                            ...props.formIncidenceType,
                                                            description: value
                                                        });
                                                    }}
                                                    value={props.formIncidenceType.description}
                                                    className={validateFormEmpty(props.formIncidenceType.description, 2)}
                                                    name="description" />
                                                </td>
                                            </tr>
                                        }

                                        {listIncidencesTypes}

                                    </tbody>

                                </table>

                                : <div>No se han dado de alta tipos de incidencias</div>
                            }

                        </div>
                    </div>

                </div>

            </div>

        </Fragment>

    );

}


export default IncidencesTypesList;