import moment from "moment";
import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { addingOrEditing } from "aseguisShared";

moment.locale('es');

interface infoToInputTime {
    colMesure: string
    editEnabled: number;
    addOrEdit: number;
    name: string,

    value: string,
    valueEdit: string,

    onChange: (time: string) => void

    NoBoldName?: boolean
    small?: boolean
    redOnError?: boolean
    errorOnFormBlank?: boolean
}

function InputTime(props: infoToInputTime): ReactElement {

    const [time, setTime] = useState(props.valueEdit);

    const [onFocus, setOnFocus] = useState(false);

    useEffect(() => {
        if (onFocus === false) {
            setTime(props.valueEdit)
        }
    }, [props.valueEdit])

    const getClassName = () => {
        if (props.small) {
            if (
                props.errorOnFormBlank &&
                props.errorOnFormBlank === true && time === ""
            ) {
                return "form-control form-control-sm is-invalid"
            } else {
                return "form-control form-control-sm"
            }
        } else {
            if (props.errorOnFormBlank && props.errorOnFormBlank === true && time === "") {
                return "form-control is-invalid"
            } else {
                return "form-control"
            }
        }
    }

    return (
        <Fragment>

            <div className={`form-group col-md-${props.colMesure}`}>

                {props.NoBoldName ?
                    <label htmlFor="name"> {props.name} </label>
                    :
                    <label style={{ fontWeight: 'bold' }} htmlFor="name"> {props.name} </label>
                }

                <div className="input-group mb-2">

                    {(props.editEnabled === addingOrEditing.edditing) &&
                        <input
                            type="time"
                            className={getClassName()}
                            lang="es"
                            value={time}
                            onFocus={(event) => {
                                setOnFocus(true)
                            }}
                            onBlur={(event) => {
                                setOnFocus(false)
                            }}
                            onChange={(event) => {
                                props.onChange(event.target.value)
                                setTime(event.target.value)
                            }}
                        />
                    }

                    {(props.editEnabled !== addingOrEditing.edditing) &&
                        <div>
                            {props.value}
                        </div>
                    }

                    {props.valueEdit.trim().length > 0 &&
                        <div className="input-group-prepend"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                props.onChange("")

                                setTime("")
                            }}
                        >
                            <div
                                className="input-group-text"
                                style={{
                                    paddingLeft: "2px !important",
                                    paddingTop: "0px !important",
                                    paddingBottom: "0px !important",
                                    paddingRight: "0px !important"
                                }}
                            >
                                <i className="fa-sharp fa-solid fa-delete-left"></i>
                            </div>
                        </div>
                    }

                </div>

            </div>

        </Fragment>

    );
};

export default InputTime;