import { Action, Dispatch } from 'redux';
import { ApplicationState } from '../../ApplicationState';
import axios from 'axios';
import {
    employee, employeeValidateFieldsResult, inicialiceEmployee, inicialiceEmployeeValidateFieldsResult,
    inicialiceSearchFilterList, searchFilterList, addingOrEditing, loading, searchedEmployees
} from 'aseguisShared';
import { Notify } from '../../common/Toastify/Notify';
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import { refreshLogin } from '../Login/LoginStore';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface employeesState {
    employees: employee[];
    employeesAutocomplete: employee[];
    employeeForm: employee,
    employeeEditForm: employee,
    loadingEmployees: number,
    actualPage: number,
    totalPages: number,
    addOrEditEmployee: number,
    searchFilterEmployees: searchFilterList,
    totalEmployeesList: number,
    validateFieldsForm: employeeValidateFieldsResult
}

export const initialState: employeesState = {
    employees: [],
    employeesAutocomplete: [],
    addOrEditEmployee: addingOrEditing.none,
    employeeForm: inicialiceEmployee,
    employeeEditForm: inicialiceEmployee,
    loadingEmployees: loading.pending,
    actualPage: 0,
    totalPages: 0,
    searchFilterEmployees: inicialiceSearchFilterList,
    totalEmployeesList: 0,
    validateFieldsForm: inicialiceEmployeeValidateFieldsResult
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface saveFormEmployeeAction {
    type: 'SAVE_FORM_EMPLOYEE',
    employee: employee
}

export interface addEditEmployeeAction {
    type: 'CHANGE_ADD_EDIT_EMPLOYEE',
    addOrEdit: number
}

export interface addRequestEmployeeAction {
    type: 'ADD_REQUEST_EMPLOYEE'
}

export interface addReceivedEmployeeAction {
    type: 'ADD_RECEIVED_EMPLOYEE',
    employee: employee
}

export interface saveEditRequestEmployeeAction {
    type: 'SAVE_EDIT_REQUEST_EMPLOYEE'
}

export interface saveEditReceivedEmployeeAction {
    type: 'SAVE_EDIT_RECEIVED_EMPLOYEE',
    employee: employee
}

export interface RequestAllEmployeeAction {
    type: 'REQUEST_ALL_EMPLOYEE'
}

export interface ReceivedAllEmployeeAction {
    type: 'RECEIVED_ALL_EMPLOYEE',
    employees: searchedEmployees
}

export interface saveSearchEmployeeTextAction {
    type: 'SAVE_SEARCH_FILTER_EMPLOYEE',
    searchFilterEmployee: searchFilterList
}

export interface setBothFormsEmployeeAction {
    type: 'SET_BOTH_FORMS_EMPLOYEE',
    employee: employee
}
export interface ReceivedAutocompleteEmployeesAction {
    type: 'RECEIVED_AUTOCOMPLETE_EMPLOYEE',
    employees: employee[]
}
export interface clearAutocompleteEmployeesAction {
    type: 'CLEAR_AUTOCOMPLETE_EMPLOYEES'
}

export interface setValidateFormEmployeeAction {
    type: 'SET_VALIDATE_FORM_EMPLOYEE',
    form: employeeValidateFieldsResult
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = | saveFormEmployeeAction | addRequestEmployeeAction | clearAutocompleteEmployeesAction |
    addReceivedEmployeeAction | saveEditRequestEmployeeAction | saveEditReceivedEmployeeAction | RequestAllEmployeeAction |
    ReceivedAllEmployeeAction | setBothFormsEmployeeAction | setValidateFormEmployeeAction |
    addEditEmployeeAction | saveSearchEmployeeTextAction | ReceivedAutocompleteEmployeesAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export function setFormsEmployee(employee: employee): setBothFormsEmployeeAction {
    return {
        type: 'SET_BOTH_FORMS_EMPLOYEE',
        employee: employee
    };
}

export function saveFormEmployee(employee: employee): saveFormEmployeeAction {
    return {
        type: 'SAVE_FORM_EMPLOYEE',
        employee: employee
    };
}

export function addOrEditEmployee(addOrEdit: number): addEditEmployeeAction {
    return {
        type: 'CHANGE_ADD_EDIT_EMPLOYEE',
        addOrEdit: addOrEdit
    };
}

export function saveSearchFilterEmployee(searchClientsFilter: searchFilterList): saveSearchEmployeeTextAction {
    return {
        type: 'SAVE_SEARCH_FILTER_EMPLOYEE',
        searchFilterEmployee: searchClientsFilter
    };
}
export function clearAutocompleteEmployees(): clearAutocompleteEmployeesAction {
    return {
        type: 'CLEAR_AUTOCOMPLETE_EMPLOYEES'
    };
}

export function setValidateEmployeeForm(form: employeeValidateFieldsResult): setValidateFormEmployeeAction {
    return {
        type: 'SET_VALIDATE_FORM_EMPLOYEE',
        form: form
    };
}

export function addEmployee(employee: employee, history: any) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        dispatch({ type: 'ADD_REQUEST_EMPLOYEE' });
        return axios.post('api/employees', employee)
            .then((data) => {
                if (data.status === 200) {
                    const state = getState()
                    const employeeEditForm = state.employees.employeeEditForm
                    if (employeeEditForm.permisosUsuario.accessAppMvEnabled || employeeEditForm.permisosUsuario.accessEnabled) {
                        Notify("Empleado creado. Se ha enviado un email al destinatario con las instrucciones para iniciar sesión", "success")
                    } else {
                        Notify("Empleado creado", "success")
                    }
                    dispatch({ type: 'ADD_RECEIVED_EMPLOYEE', employee: data.data });
                }
                if (data.status === 201) {
                    history.replace(allAppRoutes.employeesList);
                    Notify("Su plan no permite crear más empleados", "warn")
                }
            })
            .catch((error) => {
            })
    }
}

export function editEmployee(employee: employee) {
    return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
        dispatch({ type: 'SAVE_EDIT_REQUEST_EMPLOYEE' });
        return axios.put('api/employees', employee)
            .then((data) => {
                const state = getState();
                const employeeEditForm = state.employees.employeeEditForm
                const employeeForm = state.employees.employeeForm
                const accessPermissions = employeeEditForm.permisosUsuario.accessAppMvEnabled || employeeEditForm.permisosUsuario.accessEnabled;
                const prevAccessPermissions = !employeeForm.permisosUsuario.accessAppMvEnabled && !employeeForm.permisosUsuario.accessEnabled;
                if (accessPermissions && prevAccessPermissions) {
                    Notify("Empleado modificado. Se ha enviado un email al destinatario con las instrucciones para iniciar sesión", "success");
                } else {
                    Notify("Empleado modificado", "success")
                }
                dispatch({ type: 'SAVE_EDIT_RECEIVED_EMPLOYEE', employee: data.data });
                if (employee._id === state.login.userLogged.permisosUsuario.idAssociated) {
                    dispatch(refreshLogin());
                }
            })
            .catch((error) => {
            })
    }
}

export function requestAllEmployees(searchEmployeesFilter: searchFilterList) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        dispatch({ type: 'REQUEST_ALL_EMPLOYEE' });
        return axios.post<searchedEmployees>('api/employees/searchFilter/', searchEmployeesFilter)
            .then((data) => {
                dispatch({ type: 'RECEIVED_ALL_EMPLOYEE', employees: data.data });
            })
            .catch((error) => {
            })
    }
}

export const requestOneEmployee = async (idEmp: string) => {
    let searchFilter = { ...inicialiceSearchFilterList, idMongo: idEmp }
    return await axios.post<searchedEmployees>('api/employees/searchFilter/', searchFilter)
}

export function deleteEmployee(employee: employee) {
    return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
        return axios.post('api/employees/delete', employee)
            .then(res => {
                const searchFilter = getState().employees.searchFilterEmployees
                dispatch(requestAllEmployees(searchFilter))
            })
            .catch((error) => {
            })
    }
}

export function validateIfExistsFields(employee: employee) {
    return async (dispatch: Dispatch, getState: () => ApplicationState) => {
        return axios.post('api/employees/validateExists/', employee)
            .then((data) => {
                return dispatch({ type: 'SET_VALIDATE_FORM_EMPLOYEE', form: data.data });
            })
            .catch((error) => {
            })
    }
}

export function exportEmployeesToExcel(searchFilter: searchFilterList) {
    return async (dispatch: Dispatch) => {
        return axios({ method: 'post', responseType: 'blob', url: 'api/employees/createExcel/getList/', data: searchFilter })
            .then((data) => {
                const blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Aseguis_empleados.xlsx";
                link.click();
            })
            .catch((error) => {
            })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
function employeesReducer(state: employeesState = initialState, incomingAction: Action): employeesState {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_BOTH_FORMS_EMPLOYEE': {
            return {
                ...state,
                employeeForm: action.employee,
                employeeEditForm: action.employee,
                validateFieldsForm: inicialiceEmployeeValidateFieldsResult
            }
        }
        case 'SAVE_FORM_EMPLOYEE': {
            return {
                ...state,
                employeeEditForm: action.employee,
            };
        }
        case 'CHANGE_ADD_EDIT_EMPLOYEE': {
            return {
                ...state,
                addOrEditEmployee: action.addOrEdit
            }
        }
        case 'ADD_REQUEST_EMPLOYEE': {
            return {
                ...state,
                loadingEmployees: loading.pending
            };
        }
        case 'ADD_RECEIVED_EMPLOYEE': {
            return {
                ...state,
                employeeForm: action.employee,
                employeeEditForm: action.employee,
                addOrEditEmployee: addingOrEditing.edditing
            };
        }
        case 'SAVE_EDIT_REQUEST_EMPLOYEE': {
            return {
                ...state,
                loadingEmployees: loading.pending,
            };
        }
        case 'SAVE_EDIT_RECEIVED_EMPLOYEE': {
            return {
                ...state,
                employeeForm: action.employee,
                employeeEditForm: action.employee
            };
        }
        case 'REQUEST_ALL_EMPLOYEE': {
            return {
                ...state,
                loadingEmployees: loading.pending,
            };
        }
        case 'RECEIVED_ALL_EMPLOYEE': {
            return {
                ...state,
                loadingEmployees: loading.done,
                employees: action.employees.employeesResults,
                totalEmployeesList: action.employees.totalResults,
                actualPage: action.employees.numPage,
                totalPages: action.employees.totalPages
            };
        }
        case 'SAVE_SEARCH_FILTER_EMPLOYEE': {
            return {
                ...state,
                searchFilterEmployees: action.searchFilterEmployee,
                loadingEmployees: loading.pending
            };
        }
        case 'RECEIVED_AUTOCOMPLETE_EMPLOYEE': {
            return {
                ...state,
                employeesAutocomplete: action.employees
            };
        }
        case 'CLEAR_AUTOCOMPLETE_EMPLOYEES': {
            return {
                ...state,
                employeesAutocomplete: []
            };
        }
        case 'SET_VALIDATE_FORM_EMPLOYEE': {
            return {
                ...state,
                validateFieldsForm: action.form
            };
        }
        default:
            return state;
    }
}

export default employeesReducer;