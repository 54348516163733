import axios from "axios";
import { useState, useEffect } from "react";
import { bill } from "aseguisShared";
import { Notify } from "../../common/Toastify/Notify";

export const GetXMLApi = () => {

    const [error, setError] = useState("");

    const [billsToGetRemesa, setBillsToGetRemesa] = useState<bill[]>([]);

    const getRemesaFromBills = async (bills: bill[]) => {
        await axios({ method: 'post', responseType: 'blob', data: bills, url: '/api/getRemesaCobroXML' })
            .then((data) => {
                let fileSize = data.data.size
                if (fileSize > 0) {
                    const blob = new Blob([data.data], { type: 'application/xml' });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "remesa.xml";
                    link.click();
                } else {
                    setError("No existen remesas pendientes")
                }
            })
            .catch((error) => {
            })
    }

    const getBillsByManyIds = async (bills: number[]) => {
        await axios({ method: 'post', data: bills, url: '/api/bills/getFormaPagoGiroByIds' })
            .then((data) => {
                setBillsToGetRemesa((currentBills) => {
                    const newBills: bill[] = data.data
                    const combinedBills: bill[] = [...currentBills, ...newBills];
                    const uniqueBills: bill[] = combinedBills.reduce<bill[]>((acc, current) => {
                        const x = acc.find(item => item.id === current.id);
                        if (!x) {
                            return acc.concat([current]);
                        } else {
                            return acc;
                        }
                    }, []);
                    return uniqueBills;
                });
                if (data.data.length === null || data.data.length === undefined || data.data.length === 0) {
                    Notify("No se encuentran facturas para crear la remesa", "warn")
                }
            })
            .catch((error) => {
            })
    }

    const getAllPending = async () => {
        await axios({ method: 'get', url: '/api/bills/getFormaPagoGiroByIds' })
            .then((data) => {
                setBillsToGetRemesa((currentBills) => {
                    const newBills: bill[] = data.data
                    const combinedBills: bill[] = [...currentBills, ...newBills];
                    const uniqueBills: bill[] = combinedBills.reduce<bill[]>((acc, current) => {
                        const x = acc.find(item => item.id === current.id);
                        if (!x) {
                            return acc.concat([current]);
                        } else {
                            return acc;
                        }
                    }, []);
                    return uniqueBills;
                });
                if (data.data.length === null || data.data.length === undefined || data.data.length === 0) {
                    Notify("No se encuentran facturas para crear la remesa", "warn")
                }
            })
            .catch((error) => {
            })
    }

    useEffect(() => {
        const timeout = setTimeout(async () => {
            setError("")
        }, 5000);
        return () => { clearTimeout(timeout) }
    }, [error])


    return {
        setBillsToGetRemesa,
        getRemesaFromBills,

        getBillsByManyIds,
        getAllPending,
        billsToGetRemesa,

        error
    }
}
