import React, { ChangeEvent, Fragment, ReactElement, useEffect, useState } from "react";
import { addingOrEditing, suscriptionData } from "aseguisShared"
import { ApplicationState } from '../../ApplicationState';
import { useSelector } from "react-redux";
import { ButtonEditarPrivate } from "./ButtonEditarPrivate";
import { GetSuscriptionDataFromLocalStorage } from "../commonInterfaces/commonFunctions";
import ReactTooltip from "react-tooltip";
import { isChrome, isEdge } from "../../components/Admin/Admin";

export interface infoToButtons {
    addOrEdit: number,
    editEnabled: number,
    setEditEnabled: (number: number) => void,
    from: string,
    thereIsList: boolean,

    restoreForm?: () => void,
    previsualizar?: () => void,
    enableModify?: boolean,
    cancel?: () => void,
    add?: () => void,
    save?: () => void,
    delete?: () => void,
    deleteText?: string,
    disableSaveIfError?: boolean
}

function Buttons(props: infoToButtons): ReactElement {

    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);
    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);

    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);
    const [deleteEnabled, setDeleteEnabled] = React.useState(false);
    const [deleteText, setDeleteText] = React.useState("");

    const { isModifyEnabled } = ButtonEditarPrivate();

    function commonFunctions(): void {
        setDeleteEnabled(false)
    }

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    return (

        <Fragment>

            {/* MOSTRAMOS LOS BOTONES SIEMPRE QUE, O BIEN ESTÉ HABILITADO EL MODIFICAR, O BIEN ESTÉ DESHABILITADO PERO HAYA LISTA (DEBE HABER BOTONES PARA VOLVER ATRAS) */}
            {(
                (isModifyEnabled()) ||
                (!isModifyEnabled() && props.thereIsList)
            ) &&

                <Fragment>

                    <div className={"card shadow mb-4"}>

                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h6 className="m-0 font-weight-bold text-green">Acciones</h6>
                        </div>

                        <div className="card-body">

                            <div className="form-inline">

                                {((props.addOrEdit === addingOrEditing.edditing) && (props.editEnabled === addingOrEditing.edditing)) &&
                                    <button
                                        className="btn btn-warning mb-1"
                                        onClick={() => {
                                            if (isAppLoading === false) {
                                                props.setEditEnabled(addingOrEditing.none)
                                                commonFunctions()
                                            }
                                        }}
                                    >
                                        Cancelar
                                    </button>
                                }

                                {((props.addOrEdit === addingOrEditing.adding) && (props.editEnabled === addingOrEditing.edditing)) &&
                                    <button
                                        className="btn btn-warning mb-1"
                                        onClick={() => {
                                            if (isAppLoading === false) {
                                                if (props.cancel) {
                                                    props.cancel()
                                                }
                                                commonFunctions()
                                            }
                                        }}
                                    >
                                        Volver
                                    </button>
                                }

                                {((props.addOrEdit === addingOrEditing.adding) && (props.editEnabled === addingOrEditing.edditing) && isModifyEnabled() && suscriptionData.isSuscriptionActive) &&
                                    <Fragment>

                                        {props.previsualizar &&
                                            <button
                                                className={(props.disableSaveIfError && props.disableSaveIfError === true) ? "btn btn-info left-margin disabled" : "btn btn-info left-margin"}
                                                onClick={() => {
                                                    if (isAppLoading === false) {
                                                        if (props.previsualizar) {
                                                            props.previsualizar()
                                                        }
                                                        commonFunctions()
                                                    }
                                                }}
                                            >
                                                Previsualizar {" " + props.from}
                                            </button>
                                        }

                                        {(props.add && suscriptionData.isSuscriptionActive) &&
                                            <>
                                                <button
                                                    className={(props.disableSaveIfError && props.disableSaveIfError === true) ? "btn btn-success left-margin disabled" : "btn btn-success left-margin"}
                                                    onClick={() => {
                                                        if (isAppLoading === false) {
                                                            if (props.add) {
                                                                props.add()
                                                            }
                                                            commonFunctions()

                                                            //para paqueteria mostrar tooltip
                                                            if (props.from === "Paquetería" && (isChrome || isEdge)) {
                                                                localStorage.setItem('tooltipShown', 'true');
                                                            }
                                                        }
                                                    }}
                                                    //toolTip para paqueteria
                                                    data-tip={!localStorage.getItem('tooltipShown') && props.from === "Paquetería" && (isChrome || isEdge) ? "¡Pulsa ALT + M para añadir el albarán más rápido!" : null}
                                                >
                                                    Crear {" " + props.from}
                                                </button>
                                                <ReactTooltip place="top" type="dark" effect="float" />
                                            </>
                                        }

                                    </Fragment>
                                }

                                {((props.addOrEdit === addingOrEditing.edditing) && (props.editEnabled === addingOrEditing.edditing) && (props.save) && isModifyEnabled() && suscriptionData.isSuscriptionActive) &&
                                    <button
                                        className={(props.disableSaveIfError && props.disableSaveIfError === true) ? "btn btn-success left-margin disabled" : "btn btn-success left-margin"}
                                        onClick={() => {
                                            if (isAppLoading === false) {
                                                if (props.save) {
                                                    props.save();
                                                }
                                                commonFunctions()
                                            }
                                        }}
                                    >
                                        Guardar {" " + props.from}
                                    </button>
                                }

                                {((isModifyEnabled()) && (props.enableModify != false) && suscriptionData.isSuscriptionActive) &&
                                    <div>
                                        {((props.addOrEdit === addingOrEditing.edditing || addingOrEditing.adding) && (props.editEnabled === addingOrEditing.none) && (props.save)) &&
                                            <button
                                                className="btn btn-info mb-1"
                                                onClick={() => {
                                                    if (isAppLoading === false) {
                                                        props.setEditEnabled(addingOrEditing.edditing)
                                                        commonFunctions()
                                                    }
                                                }}
                                            >
                                                Editar {" " + props.from}
                                            </button>
                                        }
                                    </div>
                                }

                                {((props.addOrEdit === addingOrEditing.edditing || addingOrEditing.adding) && (props.editEnabled === addingOrEditing.none) && (props.thereIsList === true)) &&
                                    <button
                                        className="btn btn-info mb-1 left-margin"
                                        onClick={() => {
                                            if (isAppLoading === false) {
                                                if (props.cancel) {
                                                    props.cancel()
                                                }
                                                commonFunctions()
                                            }
                                        }}
                                    >
                                        Volver a lista
                                    </button>
                                }

                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                {((props.addOrEdit === addingOrEditing.edditing) && (props.thereIsList === false || props.editEnabled === addingOrEditing.edditing) && suscriptionData.isSuscriptionActive) &&
                                    <div>
                                        {(props.restoreForm) &&
                                            <button
                                                className="btn btn-info mb-1 left-margin"
                                                onClick={() => {
                                                    if (isAppLoading === false) {
                                                        if (props.restoreForm) {
                                                            props.restoreForm()
                                                            commonFunctions()
                                                        }
                                                    }
                                                }}
                                            >
                                                Restablecer {props.from}
                                            </button>
                                        }
                                    </div>
                                }

                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                {isAppLoading === true &&
                                    <span className="spinner-border spinner-border-sm mr-4"></span>
                                }

                                {((props.addOrEdit === addingOrEditing.edditing) && (props.editEnabled === addingOrEditing.edditing) && (props.delete) && (deleteEnabled === false) && (props.deleteText) && suscriptionData.isSuscriptionActive) &&
                                    <button
                                        className="btn btn-danger mb-1 ml-auto"
                                        onClick={() => {
                                            if (isAppLoading === false) {
                                                setDeleteEnabled(true)
                                            }
                                        }}
                                    >
                                        Eliminar
                                    </button>
                                }

                                {((deleteEnabled === true) && (props.deleteText)) &&
                                    <div className="form-group mb-1 ml-auto">
                                        <input
                                            style={{ width: '350px' }}
                                            type="text"
                                            className="form-control undefined"
                                            id="inputTextDelete"
                                            autoComplete={"off"}
                                            onFocus={() => {
                                                setDeleteText("")
                                            }}
                                            value={deleteText}
                                            placeholder={"Introduzca '" + props.deleteText + "'"}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                setDeleteText(event.target.value)
                                            }}
                                        />
                                        <button
                                            className={props.deleteText === deleteText ? "btn btn-danger left-margin" : "btn btn-danger disabled left-margin"}
                                            onClick={() => {
                                                if (isAppLoading === false) {
                                                    if (props.deleteText === deleteText) {
                                                        if (props.delete) {
                                                            if (props.deleteText === deleteText) {
                                                                setDeleteEnabled(false)
                                                                props.delete()
                                                            }
                                                        }
                                                    } else {
                                                        setDeleteText("")
                                                    }
                                                }
                                            }}
                                        >
                                            Eliminar
                                        </button>
                                        <button
                                            className="btn btn-warning left-margin"
                                            onClick={() => {
                                                if (isAppLoading === false) {
                                                    setDeleteEnabled(false)
                                                }
                                            }}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                }

                            </div>

                        </div>

                    </div>

                </Fragment >

            }

        </Fragment >

    );
};

export default Buttons;
