import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { ApplicationState } from '../../ApplicationState';
import Buttons from "../../common/Buttons/Buttons";
import {
    editBill, saveFormBill, addOrEditBill, previewBill, setActivateCreateBill,
    setFormsBill, saveSearchFilterBill, addBill,
} from "./BillsStore";
import BillsList from "./BillsList";
import {
    addingOrEditing, UserPermissions, bill, albaranesToBill, initAlbaranesToBill,
    searchFilterList, inicialiceBill
} from "aseguisShared";
import BillsAddForm from "./BillsAddForm";
import { GetUserPermissionsFromLocalStorage } from "../../common/commonInterfaces/commonFunctions";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import BillsForm from "./BillsForm";

const Bills: React.FC = () => {

    const dispatch: React.Dispatch<any> = useDispatch();

    let { permissions } = GetUserPermissionsFromLocalStorage()

    const billForm: bill = useSelector((state: ApplicationState) => state.bills.billForm);
    const billEditForm: bill = useSelector((state: ApplicationState) => state.bills.billEditForm);
    const addingOrEdit: addingOrEditing = useSelector((state: ApplicationState) => state.bills.addOrEditBill) || addingOrEditing.none;
    const searchFilterBills: searchFilterList = useSelector((state: ApplicationState) => state.bills.searchFilterBills);
    const createBillActivated: boolean = useSelector((state: ApplicationState) => state.bills.activateCreateBill);

    const [editEnabled, setEditEnabled] = React.useState(addingOrEditing.none);
    const [errorOnForm, setErrorOnForm] = React.useState(false);

    const [albaranesToBill, setAlbaranesToBill] = React.useState<albaranesToBill>(initAlbaranesToBill);

    function enableEditBill(Bill: bill): void {
        setEditEnabled(addingOrEditing.none);
        dispatch(setFormsBill(Bill));
        dispatch(addOrEditBill(addingOrEditing.edditing));
    }

    function addBillForm(): void {
        setEditEnabled(addingOrEditing.edditing);
        dispatch(setFormsBill(inicialiceBill));
        dispatch(addOrEditBill(addingOrEditing.adding));
    }

    function addNewBill(): void {
        if (errorOnForm === false) {
            dispatch(addBill(albaranesToBill));
        }
    }

    function saveEdited(): void {
        if (errorOnForm === false) {
            setEditEnabled(addingOrEditing.none);
            dispatch(editBill(billEditForm));
        }
    }

    function restoreBillsEdit(): void {
        dispatch(saveFormBill(billForm));
    }

    const usePathname = useLocation().pathname;
    const usePath = useHistory()

    useEffect(() => {
        dispatch(setActivateCreateBill(false));
    }, [billEditForm, albaranesToBill]);

    return (

        <Fragment>

            <Switch>

                {(permissions.bills !== UserPermissions.deshabilitado) &&
                    <Route exact path={allAppRoutes.billsList}>
                        <BillsList
                            onClick={(billToUpdate) => {
                                enableEditBill(billToUpdate)
                                usePath.push(allAppRoutes.billsForm + billToUpdate._id);
                            }}
                            addBill={() => {
                                addBillForm()
                                usePath.push(allAppRoutes.billsAddForm + "new");
                            }}
                            searchBillsFilter={searchFilterBills}
                            saveSearchBillsFilter={(searchFilterBills) => {
                                dispatch(saveSearchFilterBill(searchFilterBills));
                            }}
                        />
                    </Route>
                }

                {permissions.bills !== UserPermissions.deshabilitado &&
                    <Route exact path={allAppRoutes.billsAddForm + ":id"}>
                        <BillsAddForm
                            isEdditing={editEnabled}
                            albaranesToBill={albaranesToBill}
                            setAlbaranesToBill={setAlbaranesToBill}
                            doOnRender={addBillForm}
                        />
                    </Route>
                }

                {permissions.bills !== UserPermissions.deshabilitado &&
                    <Route exact path={allAppRoutes.billsForm + ":id"}>
                        <BillsForm
                            isEdditing={editEnabled}
                            setEdditing={setEditEnabled}
                        />
                    </Route>
                }

                <Redirect from="*" to={allAppRoutes.billsList} />

            </Switch>


            {/*BOTONES*/}
            {usePathname !== allAppRoutes.billsList &&
                <Buttons
                    addOrEdit={addingOrEdit}
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                    cancel={() => {
                        setEditEnabled(addingOrEditing.none);
                        usePath.replace(allAppRoutes.billsList);
                    }}
                    save={saveEdited}
                    add={((usePathname.includes(allAppRoutes.billsAddForm) && createBillActivated) ? addNewBill : undefined)}
                    from="Factura"
                    thereIsList={true}
                    restoreForm={restoreBillsEdit}
                    previsualizar={() => {
                        if (errorOnForm === false) {
                            dispatch(previewBill(albaranesToBill));
                        }
                    }}
                />
            }


        </Fragment >

    );
};

export default Bills;
