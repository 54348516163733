import React, { Fragment, ReactElement, ChangeEvent, useEffect } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { ApplicationState } from "../../../ApplicationState";
import { useSelector } from "react-redux";
import {
    addingOrEditing, company, cargaCompleta, inicializeLineaViajeCarga, inicialiceCargaCompleta,
    validateFormEmpty, validateNumberFormEmpty, lineaViajeCarga, calculateIva
} from "aseguisShared";

interface infoToFormOtros {
    isEdditing: number;
    addOrEdit: number,
    lineaOtros: lineaViajeCarga
    saveLineaOtros: (LineaOtros: lineaViajeCarga) => void
    saveExistingLinea: () => void
    addLinea: () => void
}

function FormOtros(props: infoToFormOtros): ReactElement {

    const [errorForm, setErrorForm] = React.useState(false);
    const [ImportSaved, setImportSaved] = React.useState(props.lineaOtros.importe);

    const company: company = useSelector((state: ApplicationState) => state.company.company);
    const cargaEditForm: cargaCompleta = useSelector((state: ApplicationState) => state.cargaCompleta.cargaCompletaEditForm || inicialiceCargaCompleta);

    const porIva = cargaEditForm.datosGenerales.client.porIva > 0 ? cargaEditForm.datosGenerales.client.porIva : company.porIva  

    useEffect(() => {
        //si hay error en algun sitio, grabamos error general
        if (
            props.lineaOtros.concepto.length < 2 ||
            props.lineaOtros.importe === 0
        ) {
            setErrorForm(true)
        } else {
            setErrorForm(false)
        }
    }, [props.lineaOtros]);

    const calculateDiscount = (value: number) => {
        const importToDiscount = (ImportSaved * value) / 100;
        const newSubtotal = ImportSaved - importToDiscount;
        const newIVA = calculateIva(newSubtotal, porIva)
        const newTotal = newSubtotal + newIVA
        props.saveLineaOtros({
            ...props.lineaOtros,
            descuento: value,
            importe: newSubtotal,
            importeOriginal: ImportSaved,
            total: newTotal,
            iva: newIVA
        })
    }

    const calculateNewImport = (value: number) => {
        const newIVA = calculateIva(value, porIva)
        const newTotal = value + newIVA
        props.saveLineaOtros({
            ...props.lineaOtros,
            descuento: 0,
            importe: value,
            importeOriginal: value,
            total: newTotal,
            iva: newIVA
        })
    }


    return (
        <Fragment>

            <div className="card-body">

                {props.isEdditing === addingOrEditing.edditing &&
                    <div>

                        <div className="form-row">                           
                            <div className="form-group col-md-12">
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="name"> Concepto </label>
                                    {(props.isEdditing === addingOrEditing.edditing) ?
                                        <input
                                            name="concepto"
                                            type="text"
                                            value={props.lineaOtros.concepto}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                props.saveLineaOtros({
                                                    ...props.lineaOtros,
                                                    concepto: event.target.value.trimStart()
                                                })
                                            }}
                                            className={validateFormEmpty(props.lineaOtros.concepto, 2)}
                                        />
                                        : <div>{props.lineaOtros.concepto}</div>}
                                </div>
                            </div>
                        </div>

                        <div className="form-row" style={{ marginTop: '20px' }}>
                            <div className="form-group col-md-7">
                            </div>

                            <div className="form-group col-md-1" style={{ marginTop: '8px' }}>
                                <div> Descuento (%):</div>
                                <div style={{ marginTop: "14px" }}> Importe: </div>
                            </div>

                            <div className="form-group col-md-2">
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    suffix={" %"}
                                    value={props.lineaOtros.descuento}
                                    className="form-control undefined"
                                    isAllowed={(value: NumberFormatValues) => {
                                        const { floatValue } = value;
                                        const numberValue = floatValue ? floatValue : 0
                                        if (numberValue) {
                                            return numberValue < 100;
                                        } else return true
                                    }}
                                    onValueChange={(value, sourceInfo) => {
                                        const { floatValue } = value;
                                        const numberValue = floatValue ? floatValue : 0
                                        //solo si cambia por el evento, actualizamos
                                        if (sourceInfo.source === "event") {
                                            calculateDiscount(numberValue)
                                        }
                                    }}
                                />
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    onValueChange={(value, sourceInfo) => {
                                        const { floatValue } = value;
                                        const numberValue = floatValue ? floatValue : 0

                                        //... solo si cambia por el evento, actualizamos,
                                        //de lo contrario no deja escribir en descuento porque se actualiza
                                        if (sourceInfo.source === "event") {
                                            calculateNewImport(numberValue)
                                            setImportSaved(numberValue)
                                        }
                                    }}
                                    fixedDecimalScale={true}
                                    allowNegative={true}
                                    value={props.lineaOtros.importe}
                                    className={validateNumberFormEmpty(props.lineaOtros.importe)}
                                    suffix={" \u20AC"}
                                />
                            </div>

                        </div>

                    </div>

                }

                {props.isEdditing === addingOrEditing.edditing &&
                    <div className="form-row">

                        {/* LIMPIAR FORMULARIO LINEA VIAJE*/}
                        <div className="form-group col-md-6">
                            <button className="btn btn-info btn-sm"
                                onClick={() => {
                                    props.saveLineaOtros(inicializeLineaViajeCarga)
                                }}>
                                Limpiar formulario
                            </button>
                        </div>

                        <div className="form-group col-md-6 right">
                            {/* GUARDAR FILA EDITADA A VIAJES*/}
                            {props.lineaOtros.id > 0 &&
                                <button className={errorForm === false ? "btn btn-info btn-sm left-margin" : "btn btn-info btn-sm left-margin disabled"}
                                    onClick={() => {
                                        if (errorForm === false) {
                                            props.saveExistingLinea()
                                        }
                                    }}>
                                    Aplicar cambios
                                </button>
                            }

                            {/* ANADIR FILA A VIAJES*/}
                            <button className={errorForm === false ? "btn btn-success btn-sm left-margin" : "btn btn-success btn-sm left-margin disabled"}
                                onClick={() => {
                                    if (errorForm === false) {
                                        props.addLinea()
                                    }
                                }}>
                                Añadir fila
                            </button>

                        </div>

                    </div>
                }

            </div>

        </Fragment>

    );
};

export default FormOtros;