import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, ReactElement, useState } from "react";
import { ApplicationState } from '../../ApplicationState';
import {
    requestAllCargas, exportToExcel, setOpenCloseModalUploadFileCarga,
    setHistoryAddListCarga, downloadCargaPDF
} from './CargaCompletaStore';
import CargaCompletaListFilter from "./CargaCompletaListFilter";
import { filterCargaCompletaTableTH } from "../../common/commonInterfaces/thFilters";
import { GetSuscriptionDataFromLocalStorage, Pagination, } from "../../common/commonInterfaces/commonFunctions";
import NumberFormat from "react-number-format";
import {
    tariffsTypes, loginUsers, formattLineType, getFormattedDateShort,
    cargaCompleta, usersTypes, searchFilterList, suscriptionData
} from "aseguisShared";
import { calculateConceptoHoras } from "./CargaCompletaForms/FormHoras";
import { calculateConceptoDistancia } from "./CargaCompletaForms/FormDistancia";
import ModalPrintAlbaranes from "../../common/ModalPrintAlbaranes/modalPrintAlbaranes";
import AddButtonList from "../../common/Buttons/AddButtonList";

interface infoToCargaList {
    onClick: (cargaCompleta: cargaCompleta) => void
    addCarga?: () => void
    searchFilterCarga: searchFilterList
    saveSearchFilterCarga: (filter: searchFilterList) => void
    excludedCargas?: cargaCompleta[]
}
function CargaCompletaList(props: infoToCargaList): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();
    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const allCargaCompleta: cargaCompleta[] = useSelector((state: ApplicationState) => state.cargaCompleta.allCargasCompletas);
    const countAllCargas: number = useSelector((state: ApplicationState) => state.cargaCompleta.totalCargasCompletas);
    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const numPage: number = useSelector((state: ApplicationState) => state.cargaCompleta.actualPage);
    const totalPages: number = useSelector((state: ApplicationState) => state.cargaCompleta.totalPages);
    const cargaEditForm: cargaCompleta = useSelector((state: ApplicationState) => state.cargaCompleta.cargaCompletaEditForm);
    const modalFilesState: boolean = useSelector((state: ApplicationState) => state.cargaCompleta.openCloseUploadModal);
    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    useEffect(() => {
        dispatch(setHistoryAddListCarga([]))
    }, []);

    useEffect(() => {
        if (props.searchFilterCarga.withTimeout) {
            const timeout = setTimeout(() => {
                dispatch(requestAllCargas(props.searchFilterCarga));
            }, 600)
            return () => clearTimeout(timeout)
        } else {
            dispatch(requestAllCargas(props.searchFilterCarga));
        }
    }, [props.searchFilterCarga, cargaEditForm]);

    const getRowColor = (cargaCompleta: cargaCompleta) => {
        if (userLogged.userType === usersTypes.employee) {
            if (cargaCompleta.idBill > 0 && cargaCompleta.datosEntrega && cargaCompleta.datosEntrega.qrIdReaded.length > 0) { //está entregado y facturado
                return ("#D8D8D8")
            }
            else if (cargaCompleta.idBill > 0 && (cargaCompleta.datosEntrega == null || cargaCompleta.datosEntrega.qrIdReaded.length === 0)) { //está facturado
                return ("#FFE3B0")
            }
            else if (cargaCompleta.idBill === 0 && cargaCompleta.datosEntrega && cargaCompleta.datosEntrega.qrIdReaded.length > 0) { //está entregado y no facturado
                return ("#CCFFCE")
            }
            else if (new Date() > new Date(cargaCompleta.datosGenerales.entrega)) { //está fuera de plazo
                return ("#FFD9D9")
            }
        }
        return ("#FFFFFF")
    }

    const cargasList: JSX.Element[] = allCargaCompleta.map((cargaCompleta, index) => {

        var found = false
        if (props.excludedCargas) {
            props.excludedCargas.map((excludedCarga) => {
                if (excludedCarga.id === cargaCompleta.id) {
                    found = true
                }
            })
        }

        return (
            <Fragment key={index}>
                {found === false &&
                    <tr className={`table-row`}
                        style={{ backgroundColor: getRowColor(cargaCompleta) }}
                        onClick={() => {
                            props.onClick(cargaCompleta)
                        }}
                        key={index}>
                        <td scope="row"><b>{cargaCompleta.id}</b></td>
                        {(userLogged.userType === usersTypes.employee) &&
                            <td>{cargaCompleta.datosGenerales.client.nombre}</td>
                        }
                        <td style={{ whiteSpace: "nowrap" }}>{getFormattedDateShort(cargaCompleta.datosGenerales.recogida)}</td>
                        <td style={{ whiteSpace: "nowrap" }}>{getFormattedDateShort(cargaCompleta.datosGenerales.entrega)}</td>

                        <td>
                            {cargaCompleta.lineasViaje.map((line, index) => (
                                <div key={index}>
                                    <span style={{ fontWeight: "bold" }}>{formattLineType(line.lineType)}:</span>
                                    <br />
                                    {(line.lineType === tariffsTypes.distancia || line.lineType === tariffsTypes.poblacion || line.lineType === tariffsTypes.trayecto) &&
                                        <>
                                            {calculateConceptoDistancia(line)}
                                            {line.concepto.length > 0 &&
                                                <>
                                                    <br />
                                                    {"- Otros conceptos: " + line.concepto}
                                                </>
                                            }
                                        </>
                                    }
                                    {line.lineType === tariffsTypes.horas &&
                                        <>
                                            {calculateConceptoHoras(line)}
                                            {line.concepto.length > 0 &&
                                                <>
                                                    <br />
                                                    {"- Otros conceptos: " + line.concepto}
                                                </>
                                            }
                                        </>
                                    }
                                    {line.lineType === tariffsTypes.otros &&
                                        "- " + line.concepto
                                    }
                                </div>
                            ))}
                        </td>

                        <td>{cargaCompleta.datosGenerales.vehicle.matricula + " - " + cargaCompleta.datosGenerales.vehicle.marca + " " + cargaCompleta.datosGenerales.vehicle.modelo}</td>
                        <td>{cargaCompleta.datosGenerales.typeVehicle.description}</td>
                        {(userLogged.userType === usersTypes.employee && suscriptionData.empleados) &&
                            <td>{cargaCompleta.datosGenerales.driver.name.length > 0 ? (cargaCompleta.datosGenerales.driver.name + " " + cargaCompleta.datosGenerales.driver.apellido1 + " " + cargaCompleta.datosGenerales.driver.apellido2) : "-"}</td>
                        }
                        <td>{cargaCompleta.datosGenerales.referenciaString}</td>
                        <td style={{ whiteSpace: 'nowrap' }}>
                            <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                displayType="text"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={true}
                                value={cargaCompleta.datosEconomicos.subTotal}
                                className="form-control undefined"
                                renderText={value => <div>{value}</div>}
                                suffix={" \u20AC"} />
                        </td>
                        <td>{cargaCompleta.datosEntrega.qrIdReaded.length > 0 ? "Si" : "-"}</td>
                        <td>{cargaCompleta.idBill > 0 ? cargaCompleta.idBill : "-"}</td>
                    </tr>
                }
            </Fragment>
        )

    });

    return (

        <Fragment>

            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        {(userLogged.userType === usersTypes.employee) &&
                            <div className="header-buttons">
                                <button
                                    className="btn btn-info btn-sm left-margin"
                                    onClick={() => {
                                        dispatch(setOpenCloseModalUploadFileCarga(true))
                                    }}>
                                    Imprimir albaranes
                                </button>
                                <button
                                    className="btn btn-info btn-sm left-margin"
                                    onClick={() => {
                                        if (allCargaCompleta.length > 0) {
                                            dispatch(exportToExcel({
                                                ...props.searchFilterCarga,
                                                getAll: true
                                            }))
                                        }
                                    }}>
                                    Exportar a Excel
                                </button>

                                <AddButtonList
                                    addCallback={props.addCarga}
                                />

                            </div>
                        }

                        <div className="card-header" style={{ paddingBottom: '0 !important' }}>
                            <div className="form-inline">
                                <h6 className="m-0 font-weight-bold text-green">
                                    Albaranes carga completa {"(" + countAllCargas.toLocaleString() + ")"}
                                </h6>
                                {(isAppLoading === true) &&
                                    <span className="spinner-border spinner-border-sm ml-4"></span>
                                }
                            </div>

                            <br></br>

                            <CargaCompletaListFilter
                                searchFilterCarga={props.searchFilterCarga}
                                saveSearchFilterCarga={props.saveSearchFilterCarga}
                            />
                        </div>

                        <div className="card-body">
                            <div className="table-responsive table-hover">
                                <table className="table table-sm">
                                    <thead className="thead-light">
                                        <tr>
                                            {filterCargaCompletaTableTH(props.saveSearchFilterCarga, props.searchFilterCarga, "#")}
                                            {(userLogged.userType === usersTypes.employee) &&
                                                filterCargaCompletaTableTH(props.saveSearchFilterCarga, props.searchFilterCarga, "Cliente")
                                            }
                                            {filterCargaCompletaTableTH(props.saveSearchFilterCarga, props.searchFilterCarga, "Fecha Albarán")}
                                            {filterCargaCompletaTableTH(props.saveSearchFilterCarga, props.searchFilterCarga, "Entr prevista")}
                                            <th>Concepto/s</th>
                                            {filterCargaCompletaTableTH(props.saveSearchFilterCarga, props.searchFilterCarga, "Vehículo")}
                                            {filterCargaCompletaTableTH(props.saveSearchFilterCarga, props.searchFilterCarga, "Tipo vehículo")}
                                            {(userLogged.userType === usersTypes.employee && suscriptionData.empleados) &&
                                                filterCargaCompletaTableTH(props.saveSearchFilterCarga, props.searchFilterCarga, "Conductor")
                                            }
                                            {filterCargaCompletaTableTH(props.saveSearchFilterCarga, props.searchFilterCarga, "Referencia/s")}
                                            {filterCargaCompletaTableTH(props.saveSearchFilterCarga, props.searchFilterCarga, "SubTotal")}
                                            {filterCargaCompletaTableTH(props.saveSearchFilterCarga, props.searchFilterCarga, "Entregado")}
                                            {filterCargaCompletaTableTH(props.saveSearchFilterCarga, props.searchFilterCarga, "ID Factura")}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cargasList}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        {/* paginacion */}
                        {Pagination(numPage, totalPages, props.searchFilterCarga, props.saveSearchFilterCarga)}
                    </div>
                </div>
            </div>

            {/* MODAL PARA IMPRIMIR ALBARANES*/}
            <ModalPrintAlbaranes
                modalOpened={modalFilesState}
                setModalOpened={(opened: boolean) => {
                    dispatch(setOpenCloseModalUploadFileCarga(opened))
                }}
                printAlbaranesById={(fromID, untilID, client) => {
                    const cargas: number[] = []
                    for (let i = fromID; i <= untilID; i++) {
                        cargas.push(i)
                    }
                    dispatch(downloadCargaPDF(cargas, client))
                }}
                printAlbaranesByCartaPorte={null}
            />

        </Fragment >

    );
};

export default CargaCompletaList;
