import {useEffect, useState} from 'react';

export const UseDebounce = (input: string, timeToWait: number = 500) => {

    const [debounceValue, setDebounceValue] = useState(input);

    useEffect(() => {

        const timeout = setTimeout(() => {
            setDebounceValue(input);
        }, timeToWait);

        return () => {
            clearTimeout(timeout);
        };

    }, [input]);

    return debounceValue;

};
