import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, ReactElement } from "react";
import { ApplicationState } from '../../ApplicationState';
import { requestAllSuppliers, exportSuppliersToExcel } from './SuppliersStore';
import SuppliersListFilter from "./SuppliersListFilter";
import { supplier, searchFilterList } from "aseguisShared";
import { filterSupplierTableTH,  } from "../../common/commonInterfaces/thFilters";
import { Pagination } from "../../common/commonInterfaces/commonFunctions";
import { ButtonEditarPrivate } from "../../common/Buttons/ButtonEditarPrivate";
import AddButtonList from "../../common/Buttons/AddButtonList";

interface infoTosupplierList {
    onClick: (supplier: supplier) => void
    addSupplier?: () => void
    searchSuppliersFilter: searchFilterList
    saveSearchSuppliersFilter: (filter: searchFilterList) => void
}

function SuppliersList(props: infoTosupplierList): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();
    const allSuppliers: supplier[] = useSelector((state: ApplicationState) => state.suppliers.suppliers) || [];
    const countAllSuppliers: number = useSelector((state: ApplicationState) => state.suppliers.totalSuppliersList);
    const isAppLoading: boolean = useSelector((state: ApplicationState) => state.globalState.isAppLoading);
    const numPage: number = useSelector((state: ApplicationState) => state.suppliers.actualPage);
    const totalPages: number = useSelector((state: ApplicationState) => state.suppliers.totalPages);
    const supplierEditForm: supplier = useSelector((state: ApplicationState) => state.suppliers.supplierEditForm);

    const isModifyEnabled = ButtonEditarPrivate().isModifyEnabled();

    useEffect(() => {
        if (props.searchSuppliersFilter.withTimeout) {
            const timeout = setTimeout(() => {
                dispatch(requestAllSuppliers(props.searchSuppliersFilter));
            }, 600)
            return () => clearTimeout(timeout)
        } else {
            dispatch(requestAllSuppliers(props.searchSuppliersFilter));
        }
    }, [props.searchSuppliersFilter, supplierEditForm]);

    const suppliersList: JSX.Element[] = allSuppliers.map((supplier, index) => {
        return (
            <tr className={`table-row`}
                onClick={() => {
                    props.onClick(supplier)
                }}
                key={index}>
                <td scope="row"><b>{supplier.id}</b></td>
                <td>{supplier.nombre}</td>
                <td>{supplier.email}</td>
                <td>{supplier.nif}</td>
                <td>{supplier.address.localidad}</td>
                <td>{supplier.address.provincia}</td>
                <td>{supplier.tel1}</td>
            </tr>);
    });

    return (

        <Fragment>

            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        {/*aÑadir proveedor button*/}
                        {(props.addSupplier && isModifyEnabled) &&
                            <div className="header-buttons">
                                <button
                                    className="btn btn-info btn-sm left-margin"
                                    onClick={() => {
                                        if (allSuppliers.length > 0) {
                                            dispatch(exportSuppliersToExcel({
                                                ...props.searchSuppliersFilter,
                                                getAll: true
                                            }))
                                        }
                                    }}>
                                    Exportar a Excel
                                </button>

                                <AddButtonList
                                    addCallback={props.addSupplier}
                                />
                                
                            </div>
                        }

                        <div className="card-header">
                            <div className="form-inline">
                                <h6 className="m-0 font-weight-bold text-green">Lista de Proveedores {"(" + countAllSuppliers + ")"}</h6>
                                {(isAppLoading === true) &&
                                    <span className="spinner-border spinner-border-sm ml-4"></span>
                                }
                            </div>
                            <br></br>
                            <SuppliersListFilter
                                searchFilterSuppliers={props.searchSuppliersFilter}
                                saveSearchFilterSuppliers={props.saveSearchSuppliersFilter}
                            />
                        </div>

                        <div className="card-body">
                            <div className="table-responsive table-hover">

                                <table className="table table-sm">
                                    <thead className="thead-light">
                                        <tr>
                                            {filterSupplierTableTH(props.saveSearchSuppliersFilter, props.searchSuppliersFilter, "#")}
                                            {filterSupplierTableTH(props.saveSearchSuppliersFilter, props.searchSuppliersFilter, "Nombre")}
                                            {filterSupplierTableTH(props.saveSearchSuppliersFilter, props.searchSuppliersFilter, "Email")}
                                            {filterSupplierTableTH(props.saveSearchSuppliersFilter, props.searchSuppliersFilter, "Cif")}
                                            {filterSupplierTableTH(props.saveSearchSuppliersFilter, props.searchSuppliersFilter, "Localidad")}
                                            {filterSupplierTableTH(props.saveSearchSuppliersFilter, props.searchSuppliersFilter, "Provincia")}
                                            {filterSupplierTableTH(props.saveSearchSuppliersFilter, props.searchSuppliersFilter, "Tel 1")}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {suppliersList}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* paginacion */}
                        {Pagination(numPage, totalPages, props.searchSuppliersFilter, props.saveSearchSuppliersFilter)}

                    </div>
                </div>
            </div>

        </Fragment >

    );
};

export default SuppliersList;
