import axios from 'axios';
import { Action, Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { logout } from '../../components/Login/LoginStore';
import { initSuscriptionData, suscriptionData } from 'aseguisShared';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface globalState {
    isAppLoading: boolean;
    sessionTokenForGooglePlaces: string;
    suscriptionData: suscriptionData;
}

export const initialGlobalState: globalState = {
    isAppLoading: false,
    sessionTokenForGooglePlaces: "",
    suscriptionData: initSuscriptionData
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface loadingAction {
    type: 'APP_LOADING'
}

export interface notLoadingAction {
    type: 'APP_NOT_LOADING'
}

export interface setSessionTokenForGooglePlacesAction {
    type: 'SET_SESSION_TOKEN_GOOGLE_PLACES',
    newSessionToken: string,
}

export interface ISetSuscriptionData {
    type: 'SET_SUSCRIPTION_DATA',
    suscriptionData: suscriptionData,
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = loadingAction | notLoadingAction | ISetSuscriptionData | setSessionTokenForGooglePlacesAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export function appLoading(): loadingAction {
    return { type: 'APP_LOADING' };
}

export function appNotLoading(): notLoadingAction {
    return { type: 'APP_NOT_LOADING' };
}

export function requestSuscriptionStatus() {
    return async (dispatch: Dispatch) => {
        return axios.get<suscriptionData>('api/stripe/isSuscriptionActive/')
            .then((data) => {
                localStorage.setItem('suscriptionData', JSON.stringify(data.data));
                dispatch(setSuscriptionData(data.data))
            })
            .catch((error) => {
                dispatch(logout());
            })
    }
}

export function setSessionTokenForGoogle(): setSessionTokenForGooglePlacesAction {
    const newUuid = uuidv4()
    return {
        type: 'SET_SESSION_TOKEN_GOOGLE_PLACES',
        newSessionToken: newUuid
    };
}

export function setSuscriptionData(suscriptionData: suscriptionData): ISetSuscriptionData {
    return {
        type: 'SET_SUSCRIPTION_DATA',
        suscriptionData: suscriptionData
    };
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
//export const reducer: Reducer<LoginState> = (state: LoginState | undefined, incomingAction: Action): LoginState => {
function globalReducer(state: globalState = initialGlobalState, incomingAction: Action): globalState {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'APP_LOADING':
            return {
                ...state,
                isAppLoading: true
            };
        case 'APP_NOT_LOADING':
            return {
                ...state,
                isAppLoading: false
            };
        case 'SET_SESSION_TOKEN_GOOGLE_PLACES':
            return {
                ...state,
                sessionTokenForGooglePlaces: action.newSessionToken,
            };
        case 'SET_SUSCRIPTION_DATA':
            return {
                ...state,
                suscriptionData: action.suscriptionData,
            };
        default:
            return state;
    }
}

export default globalReducer;