import React, { Fragment, ReactElement, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from '../../ApplicationState';
import { saveClientEditForm, setValidateClientForm, validateIfExistsFields } from './ClientsStore';
import NumberFormat from 'react-number-format';
import {
    addingOrEditing, formasDePago, plazosEntrega, usersTypes, vencimientosFacturas, loginUsers,
    getFormattedDate, isEmailValidate, isManyEmailValidate, validateAlphaNumeric, validateEmail,
    validateFormEmail, validateFormEmpty, validateManyEmails, client, clientValidateFieldsResult,
    CapitalizefirstLetter, formatedDirection, urlParmsForm, suscriptionData
} from "aseguisShared";
import UserPermissionForm from "../../common/commonInterfaces/UserPermissionForm";
import { useParams } from "react-router-dom";
import ClientIncrSupl from "./ClientIncrSupl";
import GoogleAndClientsSuggestions from "../../common/PlacesAutocomplete/GoogleAndClientsSuggestions";
import ReactTooltip from "react-tooltip";
import InputText from "../../common/Inputs/InputText";
import Select from 'react-select';
import { GetSuscriptionDataFromLocalStorage } from "../../common/commonInterfaces/commonFunctions";

interface infoToClientForm {
    isEdditing: number,
    errorForm: boolean,
    setErrorForm: (value: boolean) => void,
    setEdditing: (value: addingOrEditing) => void,
    doOnRender?: (id: string) => void,
}

function ClientForm(props: infoToClientForm): ReactElement {

    const debounceRef = useRef<NodeJS.Timeout | null>(null);
    const dispatch: React.Dispatch<any> = useDispatch();

    const suscriptionDataStorage = GetSuscriptionDataFromLocalStorage()

    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);
    const client: client = useSelector((state: ApplicationState) => state.clients.clientForm);
    const editClientForm: client = useSelector((state: ApplicationState) => state.clients.clientEditForm);
    const validateFieldsForm: clientValidateFieldsResult = useSelector((state: ApplicationState) => state.clients.validateFieldsForm);
    const addingOrEditClients: addingOrEditing = useSelector((state: ApplicationState) => state.clients.addOrEditClients) || addingOrEditing.none;
    const suscriptionDataState: suscriptionData = useSelector((state: ApplicationState) => state.globalState.suscriptionData);

    const [errorAddressForm, setErrorAddressForm] = useState(false);
    const [goToValidateFields, setGoToValidateFields] = useState(false);
    const [suscriptionData, setSuscriptionData] = useState(suscriptionDataStorage);

    useEffect(() => {
        if (suscriptionDataState && suscriptionDataState.customerId.length > 0) {
            setSuscriptionData(suscriptionDataState)
        }
    }, [suscriptionDataState])

    //para tener siempre la ultima versión...
    let { id } = useParams<urlParmsForm>();
    useEffect(() => {
        if (props.doOnRender && id) {
            props.doOnRender(id)
        }
    }, [id]);

    useEffect(() => {
        if (
            (editClientForm.nif.length < 2) ||
            (editClientForm.nombre.length < 2) ||
            (editClientForm.razonSocial.length < 2) ||
            (editClientForm.email && editClientForm.email.length > 0 && isEmailValidate(editClientForm.email) === false) ||
            (editClientForm.emailFacturacion && editClientForm.emailFacturacion.length > 0 && isManyEmailValidate(editClientForm.emailFacturacion) === false)
        ) {
            props.setErrorForm(true)
        } else {
            props.setErrorForm(errorAddressForm)
        }
    }, [editClientForm, validateFieldsForm.anyFieldExist, errorAddressForm])

    useEffect(() => {
        if (goToValidateFields === true) {
            dispatch(validateIfExistsFields(editClientForm));
            setGoToValidateFields(false)
        }
    }, [goToValidateFields])

    const opcionesMeses = [
        { value: 1, label: 'Enero' },
        { value: 2, label: 'Febrero' },
        { value: 3, label: 'Marzo' },
        { value: 4, label: 'Abril' },
        { value: 5, label: 'Mayo' },
        { value: 6, label: 'Junio' },
        { value: 7, label: 'Julio' },
        { value: 8, label: 'Agosto' },
        { value: 9, label: 'Septiembre' },
        { value: 10, label: 'Octubre' },
        { value: 11, label: 'Noviembre' },
        { value: 12, label: 'Diciembre' },
    ];

    return (

        <Fragment>

            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">
                        {(addingOrEditClients !== addingOrEditing.adding) &&
                            <span> {"# " + editClientForm.id.toString()}</span>
                        }
                        {(addingOrEditClients === addingOrEditing.adding) &&
                            <span> {"Añadir Cliente"}</span>
                        }
                    </h6>
                </div>
                <div className="card-body">

                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="fecAlta">Fecha de Alta</label>
                                <div>{getFormattedDate(editClientForm.fecAlta)}</div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row" style={{ marginTop: "20px" }}>

                        <div className="form-group col-md-4">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="input_nif">NIF / CIF</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={editClientForm.nif}
                                        onChange={(event) => {
                                            var value = validateAlphaNumeric(event.target.value, true).toUpperCase()
                                            dispatch(saveClientEditForm({
                                                ...editClientForm,
                                                nif: value,
                                                fecAlta: addingOrEditClients === addingOrEditing.adding ? new Date() : editClientForm.fecAlta
                                            }));
                                            dispatch(setValidateClientForm({
                                                ...validateFieldsForm,
                                                nifExists: false
                                            }))
                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);
                                        }}
                                        className={validateFormEmpty(editClientForm.nif, 2)}
                                        id="nif" />
                                    :
                                    <input
                                        name="cliNif"
                                        disabled={true}
                                        type="text"
                                        value={client.nif}
                                        className="form-control undefined"
                                    />
                                }

                                <div className="text-danger" style={{ height: "20px" }}>
                                    {(validateFieldsForm.nifExists) &&
                                        <small>NIF ya asignado a otro cliente</small>
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="form-group col-md-6">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="input_tel2">Dirección</label>
                                <GoogleAndClientsSuggestions
                                    isEditing={props.isEdditing}
                                    valueNoEditing={formatedDirection(client.address)}
                                    placeholder="Escriba un lugar para autocompletar..."
                                    activateClientsDirs={false}
                                    initialAddress={editClientForm.address}
                                    onClick={(dest, fromModal) => {
                                        dispatch(saveClientEditForm({
                                            ...editClientForm,
                                            address: dest.address,
                                            nombre: editClientForm.nombre.length > 0 ? editClientForm.nombre : dest.name,
                                            razonSocial: editClientForm.nombre.length > 0 ? editClientForm.nombre : dest.name,
                                            tel1: editClientForm.tel1.length > 0 ? editClientForm.tel1 : dest.tel,
                                        }));
                                    }}
                                    setErrorForm={setErrorAddressForm}
                                />
                            </div>
                        </div>

                        <InputText
                            className="form-control undefined"
                            colMesure="2"
                            autoComplete={false}
                            editEnabled={props.isEdditing}
                            addOrEdit={addingOrEditClients}
                            name="Piso/Pta"
                            valueEdit={editClientForm.address.puerta}
                            value={client.address.puerta}
                            onChange={(event) => {
                                var value = validateAlphaNumeric(event.target.value)
                                dispatch(saveClientEditForm({
                                    ...editClientForm,
                                    address: {
                                        ...editClientForm.address,
                                        puerta: value
                                    }
                                }));
                            }}
                        />

                    </div>

                    <div className="form-row">

                        <div className="form-group col-md-4">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="input_name">Nombre</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={editClientForm.nombre}
                                        onChange={(event) => {
                                            var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                            dispatch(saveClientEditForm({ ...editClientForm, nombre: value }));
                                            dispatch(setValidateClientForm({
                                                ...validateFieldsForm,
                                                nombreExists: false
                                            }))
                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);
                                        }}
                                        className={validateFormEmpty(editClientForm.nombre, 2)}
                                        id="nombre" />
                                    :
                                    <input
                                        name="cliName"
                                        disabled={true}
                                        type="text"
                                        value={client.nombre}
                                        className="form-control undefined"
                                    />
                                }

                                <div className="text-danger" style={{ height: "20px" }}>
                                    {(validateFieldsForm.nombreExists) &&
                                        <small> NOMBRE ya asignado a otro cliente</small>
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="form-group col-md-4">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="input_razonSocial">Razón social</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={editClientForm.razonSocial}
                                        onChange={(event) => {
                                            var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                            dispatch(saveClientEditForm({ ...editClientForm, razonSocial: value }));
                                            dispatch(setValidateClientForm({
                                                ...validateFieldsForm,
                                                razonSocialExists: false
                                            }))
                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);
                                        }}
                                        className={validateFormEmpty(editClientForm.razonSocial, 2)}
                                        id="razonSocial" />
                                    :
                                    <input
                                        name="cliName"
                                        disabled={true}
                                        type="text"
                                        value={client.razonSocial}
                                        className="form-control undefined"
                                    />
                                }

                                <div className="text-danger" style={{ height: "20px" }}>
                                    {(validateFieldsForm.razonSocialExists) &&
                                        <small> RAZÓN SOCIAL ya asignada a otro cliente</small>
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="form-group col-md-2">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="input_tel1">Tel. 1</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <NumberFormat
                                        format="### ## ## ## ## ## ##"
                                        //mask="_"
                                        value={editClientForm.tel1}
                                        id="tel1"
                                        isNumericString={true}
                                        className="form-control undefined"
                                        onValueChange={(value) => {
                                            dispatch(saveClientEditForm({ ...editClientForm, tel1: value.value }));
                                            dispatch(setValidateClientForm({
                                                ...validateFieldsForm,
                                                tel1Exists: false
                                            }))
                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);
                                        }}
                                    />
                                    :
                                    <input
                                        name="cliTel1"
                                        disabled={true}
                                        type="text"
                                        value={client.tel1}
                                        className="form-control undefined"
                                    />
                                }

                                <div className="text-danger" style={{ height: "20px" }}>
                                    {(validateFieldsForm.tel1Exists) &&
                                        <small> TEL ya asignado a otro cliente</small>
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="form-group col-md-2">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="input_tel2">Tel. 2</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <NumberFormat
                                        format="### ## ## ## ## ## ##"
                                        value={editClientForm.tel2}
                                        id="tel2"
                                        isNumericString={true}
                                        className="form-control undefined"
                                        onValueChange={(value) => {
                                            dispatch(saveClientEditForm({ ...editClientForm, tel2: value.value }));
                                            dispatch(setValidateClientForm({
                                                ...validateFieldsForm,
                                                tel2Exists: false
                                            }))
                                            if (debounceRef.current) {
                                                clearTimeout(debounceRef.current);
                                            }
                                            debounceRef.current = setTimeout(() => {
                                                setGoToValidateFields(true);
                                            }, 400);
                                        }}
                                    />
                                    :
                                    <input
                                        name="cliTel2"
                                        disabled={true}
                                        type="text"
                                        value={client.tel2}
                                        className="form-control undefined"
                                    />
                                }

                                <div className="text-danger" style={{ height: "20px" }}>
                                    {(validateFieldsForm.tel2Exists) &&
                                        <small> TEL ya asignado a otro cliente</small>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="form-row">

                        {(suscriptionData.crmClientes) &&
                            <div className="form-group col-md-4">
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="input_email">Email inicio de sesión</label>
                                    {(props.isEdditing === addingOrEditing.edditing) ?
                                        <input
                                            type="text"
                                            value={editClientForm.email}
                                            onChange={(event) => {
                                                var value = validateEmail(event.target.value)
                                                dispatch(saveClientEditForm({
                                                    ...editClientForm,
                                                    email: value
                                                }));
                                                dispatch(setValidateClientForm({
                                                    ...validateFieldsForm,
                                                    emailExists: false
                                                }))
                                                if (debounceRef.current) {
                                                    clearTimeout(debounceRef.current);
                                                }
                                                debounceRef.current = setTimeout(() => {
                                                    setGoToValidateFields(true);
                                                }, 400);
                                            }}
                                            className={(editClientForm.email && editClientForm.email.length > 0) ? validateFormEmail(editClientForm.email) : "form-control"}
                                            id="email" />
                                        :
                                        <input
                                            name="cliEmail"
                                            disabled={true}
                                            type="text"
                                            value={client.email}
                                            className="form-control undefined"
                                        />
                                    }
                                    <div className="text-danger" style={{ height: "20px" }}>
                                        {(validateFieldsForm.emailExists) &&
                                            <small> EMAIL ya asignado a otro cliente</small>
                                        }
                                    </div>

                                </div>
                            </div>
                        }

                        <div className="form-group col-md-8">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="input_email">Email/s envío facturas <small>(separados por espacio)</small></label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={editClientForm.emailFacturacion ? editClientForm.emailFacturacion : ""}
                                        onChange={(event) => {
                                            var emailsArrayInit = event.target.value.split(/\s+/);
                                            var newEmailsArray = emailsArrayInit.map(email => validateEmail(email));
                                            let newEmailsString = newEmailsArray.join(" ");
                                            dispatch(saveClientEditForm({ ...editClientForm, emailFacturacion: newEmailsString }));
                                        }}
                                        className={(editClientForm.emailFacturacion && editClientForm.emailFacturacion.length > 0) ? validateManyEmails(editClientForm.emailFacturacion) : "form-control"}
                                        id="email"
                                    />
                                    :
                                    <input
                                        name="cliEmailFac"
                                        disabled={true}
                                        type="text"
                                        value={client.emailFacturacion || ""}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className="card shadow mb-4">
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-green">Datos económicos</h6>
                </div>
                <div className="card-body">

                    <div className="form-row">

                        {/* COMENTAMOS POR AHORA, EL IVA EN EL CLIENTE PK NO TIENE SENTIDO */}

                        {/*{(editClientForm.address.cpMongo.idPais === "ES") &&*/}
                        {/*    <div className="form-group col-md-3">*/}
                        {/*        <div>*/}
                        {/*            <label style={{ fontWeight: 'bold' }} htmlFor="input_iva">Importe I.V.A (%)</label>*/}
                        {/*            {props.isEdditing === addingOrEditing.edditing ?*/}
                        {/*                <NumberFormat*/}
                        {/*                    decimalSeparator=","*/}
                        {/*                    decimalScale={2}*/}
                        {/*                    fixedDecimalScale={true}*/}
                        {/*                    suffix=" %"*/}
                        {/*                    isAllowed={value => {*/}
                        {/*                        if (value.floatValue == null) {*/}
                        {/*                            return value.formattedValue === ''*/}
                        {/*                        } else {*/}
                        {/*                            return (value.floatValue <= 100 && value.floatValue >= 1)*/}
                        {/*                        }*/}
                        {/*                    }}*/}
                        {/*                    allowNegative={false}*/}
                        {/*                    value={editClientForm.porIva}*/}
                        {/*                    className="form-control undefined"*/}
                        {/*                    onValueChange={(value) => {*/}
                        {/*                        dispatch(saveClientEditForm({ ...editClientForm, porIva: value.floatValue ? value.floatValue : 0 }));*/}
                        {/*                    }} />*/}
                        {/*                :*/}
                        {/*                <NumberFormat*/}
                        {/*                    renderText={value => <div>{value}</div>}*/}
                        {/*                    displayType="text"*/}
                        {/*                    thousandSeparator="."*/}
                        {/*                    decimalSeparator=","*/}
                        {/*                    decimalScale={2}*/}
                        {/*                    fixedDecimalScale={true}*/}
                        {/*                    allowNegative={false}*/}
                        {/*                    suffix={" %"}*/}
                        {/*                    value={client.porIva}*/}
                        {/*                    className="form-control undefined"*/}
                        {/*                />*/}
                        {/*            }*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*}*/}

                        {(suscriptionData.paqueteria) &&
                            <div className="form-group col-md-3">
                                <div>
                                    <label
                                        style={{ fontWeight: 'bold' }}
                                        htmlFor="input_iva"
                                    >
                                        Conversión Kg/m3 &nbsp;
                                        <span data-tip="Si informa un valor para la conversión, no se tendrán en cuenta las tarifas por m3">(*)</span>
                                    </label>
                                    {props.isEdditing === addingOrEditing.edditing ?
                                        <NumberFormat
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            suffix=" kg/m3"
                                            allowNegative={false}
                                            value={editClientForm.m3ToKg}
                                            className="form-control undefined"
                                            onValueChange={(value) => {
                                                dispatch(saveClientEditForm({ ...editClientForm, m3ToKg: value.floatValue ? value.floatValue : 0 }));
                                            }} />
                                        :
                                        <NumberFormat
                                            renderText={value => {
                                                return (
                                                    <input
                                                        name="conversionkgm3"
                                                        disabled={true}
                                                        type="text"
                                                        value={value}
                                                        className="form-control undefined"
                                                    />
                                                )
                                            }}
                                            displayType="text"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            suffix=" kg/m3"
                                            value={client.m3ToKg}
                                            className="form-control undefined"
                                        />
                                    }
                                </div>
                            </div>
                        }

                        {(suscriptionData.paqueteria) &&
                            <div className="form-group col-md-6">
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="input_iva">Facturar de forma conjunta paquetería y carga completa</label>
                                    {props.isEdditing === addingOrEditing.edditing ?
                                        <select
                                            value={editClientForm.facturarCargaPaqJunto ? "Si" : "No"}
                                            className={"form-control undefined"}
                                            id="paqCargaJunto"
                                            onChange={(value) => {
                                                dispatch(saveClientEditForm({ ...editClientForm, facturarCargaPaqJunto: value.target.value === "Si" ? true : false }));
                                            }}>
                                            <option value="Si">Si</option>
                                            <option value="No">No</option>
                                        </select>
                                        :
                                        <input
                                            name="paqYCargaConjuntaBill"
                                            disabled={true}
                                            type="text"
                                            value={client.facturarCargaPaqJunto ? "Si" : "No"}
                                            className="form-control undefined"
                                        />
                                    }
                                </div>
                            </div>
                        }

                    </div>


                    <div className="form-row">

                        <div className="form-group col-md-3">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="input_razonSocial">Forma de pago</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <select
                                        value={editClientForm.datosEconomicos && editClientForm.datosEconomicos.formaPago != null ? editClientForm.datosEconomicos.formaPago : formasDePago.null}
                                        className="form-control undefined"
                                        id="formaPago"
                                        onChange={(event) => {
                                            dispatch(saveClientEditForm({
                                                ...editClientForm,
                                                datosEconomicos: {
                                                    ...editClientForm.datosEconomicos,
                                                    formaPago: event.target.value
                                                }
                                            }))
                                        }}>
                                        <option defaultChecked={true} value={formasDePago.null}>{formasDePago.null}</option>
                                        <option value={formasDePago.transferencia}>{formasDePago.transferencia}</option>
                                        <option value={formasDePago.confirming}>{formasDePago.confirming}</option>
                                        <option value={formasDePago.giro}>{formasDePago.giro}</option>
                                        <option value={formasDePago.contado}>{formasDePago.contado}</option>
                                        <option value={formasDePago.pagare}>{formasDePago.pagare}</option>
                                    </select>
                                    :
                                    <input
                                        name="formaPago"
                                        disabled={true}
                                        type="text"
                                        value={(client.datosEconomicos && client.datosEconomicos.formaPago != null) ? client.datosEconomicos.formaPago : formasDePago.null}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>

                        <div className="form-group col-md-3">
                            {editClientForm.datosEconomicos.formaPago !== formasDePago.contado ?
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="input_razonSocial">Vencimiento</label>
                                    {(props.isEdditing === addingOrEditing.edditing) ?
                                        <select
                                            value={editClientForm.datosEconomicos && editClientForm.datosEconomicos.vencimiento != null ? editClientForm.datosEconomicos.vencimiento : vencimientosFacturas.null}
                                            className="form-control undefined"
                                            id="vencimiento"
                                            onChange={(event) => {
                                                dispatch(saveClientEditForm({
                                                    ...editClientForm,
                                                    datosEconomicos: {
                                                        ...editClientForm.datosEconomicos,
                                                        vencimiento: event.target.value
                                                    }
                                                }))
                                            }}>
                                            <option defaultChecked={true} value={vencimientosFacturas.null}>{vencimientosFacturas.null}</option>
                                            <option value={vencimientosFacturas.d15}>{vencimientosFacturas.d15}</option>
                                            <option value={vencimientosFacturas.d30}>{vencimientosFacturas.d30}</option>
                                            <option value={vencimientosFacturas.d45}>{vencimientosFacturas.d45}</option>
                                            <option value={vencimientosFacturas.d60}>{vencimientosFacturas.d60}</option>
                                            <option value={vencimientosFacturas.d90}>{vencimientosFacturas.d90}</option>
                                            <option value={vencimientosFacturas.d120}>{vencimientosFacturas.d120}</option>
                                            <option value={vencimientosFacturas.d150}>{vencimientosFacturas.d150}</option>
                                            <option value={vencimientosFacturas.d180}>{vencimientosFacturas.d180}</option>
                                        </select>
                                        :
                                        <input
                                            name="vencimiento"
                                            disabled={true}
                                            type="text"
                                            value={(client.datosEconomicos && client.datosEconomicos.vencimiento != null) ? client.datosEconomicos.vencimiento : vencimientosFacturas.null}
                                            className="form-control undefined"
                                        />
                                    }
                                </div>
                                :
                                <div>
                                    <label style={{ fontWeight: 'bold' }} htmlFor="input_razonSocial">Vencimiento</label>
                                    <div>-</div>
                                </div>
                            }
                        </div>

                        <div className="form-group col-md-6">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="input_razonSocial">Núm cuenta bancaria</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <input
                                        type="text"
                                        value={(editClientForm.datosEconomicos && editClientForm.datosEconomicos.ccc != null) ? editClientForm.datosEconomicos.ccc : 0}
                                        onChange={(event) => {
                                            dispatch(saveClientEditForm({
                                                ...editClientForm,
                                                datosEconomicos: {
                                                    ...editClientForm.datosEconomicos,
                                                    ccc: event.target.value
                                                }
                                            }))
                                        }}
                                        className={"form-control"}
                                    />
                                    :
                                    <input
                                        name="ccc"
                                        disabled={true}
                                        type="text"
                                        value={(client.datosEconomicos && client.datosEconomicos.ccc != null) ? client.datosEconomicos.ccc : 0}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>

                    </div>

                    <div className="form-row">

                        <div className="form-group col-md-3">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="input_razonSocial">Día de pago</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <select
                                        value={(editClientForm.datosEconomicos && editClientForm.datosEconomicos.diaDePago) ? editClientForm.datosEconomicos.diaDePago : "Sin determinar"}
                                        className="form-control undefined"
                                        id="diaDePago"
                                        onChange={(event) => {
                                            dispatch(saveClientEditForm({
                                                ...editClientForm,
                                                datosEconomicos: {
                                                    ...editClientForm.datosEconomicos,
                                                    diaDePago: event.target.value
                                                }
                                            }))
                                        }}>
                                        <option defaultChecked={true} value={"0"}>Sin determinar</option>
                                        <option value={"1"}>{"1"}</option>
                                        <option value={"2"}>{"2"}</option>
                                        <option value={"3"}>{"3"}</option>
                                        <option value={"4"}>{"4"}</option>
                                        <option value={"5"}>{"5"}</option>
                                        <option value={"6"}>{"6"}</option>
                                        <option value={"7"}>{"7"}</option>
                                        <option value={"8"}>{"8"}</option>
                                        <option value={"9"}>{"9"}</option>
                                        <option value={"10"}>{"10"}</option>
                                        <option value={"11"}>{"11"}</option>
                                        <option value={"12"}>{"12"}</option>
                                        <option value={"13"}>{"13"}</option>
                                        <option value={"14"}>{"14"}</option>
                                        <option value={"15"}>{"15"}</option>
                                        <option value={"16"}>{"16"}</option>
                                        <option value={"17"}>{"17"}</option>
                                        <option value={"18"}>{"18"}</option>
                                        <option value={"19"}>{"19"}</option>
                                        <option value={"20"}>{"20"}</option>
                                        <option value={"21"}>{"21"}</option>
                                        <option value={"22"}>{"22"}</option>
                                        <option value={"23"}>{"23"}</option>
                                        <option value={"24"}>{"24"}</option>
                                        <option value={"25"}>{"25"}</option>
                                        <option value={"26"}>{"26"}</option>
                                        <option value={"27"}>{"27"}</option>
                                        <option value={"28"}>{"28"}</option>
                                        <option value={"29"}>{"29"}</option>
                                        <option value={"30"}>{"30"}</option>
                                        <option value={"31"}>{"31"}</option>
                                    </select>
                                    :
                                    <input
                                        name="diaPago"
                                        disabled={true}
                                        type="text"
                                        value={(client.datosEconomicos && client.datosEconomicos.diaDePago) ? (client.datosEconomicos.diaDePago === "0" ? "Sin determinar" : client.datosEconomicos.diaDePago) : ("Sin determinar")}
                                        className="form-control undefined"
                                    />
                                }
                            </div>
                        </div>

                        <div className="form-group col-md-3">
                            <div>
                                <label style={{ fontWeight: 'bold' }} htmlFor="input_razonSocial">Meses de pago a excluir</label>
                                {(props.isEdditing === addingOrEditing.edditing) ?
                                    <Select
                                        options={opcionesMeses}
                                        isMulti
                                        value={opcionesMeses.filter(option => editClientForm.datosEconomicos.mesesDePagoExcluidos?.includes(option.value))}
                                        onChange={(selectedOptions) => {
                                            const nuevosMesesDePagoExc = selectedOptions.map(option => option.value);
                                            dispatch(saveClientEditForm({
                                                ...editClientForm,
                                                datosEconomicos: {
                                                    ...editClientForm.datosEconomicos,
                                                    mesesDePagoExcluidos: nuevosMesesDePagoExc
                                                }
                                            }))
                                        }}
                                        closeMenuOnSelect={false}
                                        placeholder="Selecciona los meses de pago a excluir"
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        noOptionsMessage={({ inputValue }) => inputValue.length === 0 ? 'No hay opciones' : `No se encontraron resultados para "${inputValue}"`}
                                    />
                                    :
                                    <Select
                                        options={opcionesMeses}
                                        isMulti
                                        isDisabled={true}
                                        onChange={() => { }}
                                        isSearchable={false}
                                        isClearable={false}
                                        menuIsOpen={false}
                                        value={opcionesMeses.filter(option => client.datosEconomicos.mesesDePagoExcluidos?.includes(option.value))}
                                        closeMenuOnSelect={false}
                                        placeholder="Sin meses de pago excluidos"
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                }
                            </div>
                        </div>

                    </div>

                </div>
            </div>


            <div className="form-group row">

                {(suscriptionData.paqueteria) &&
                    <div className="col-sm-6">
                        <div className="card shadow mb-4" style={(props.isEdditing === addingOrEditing.edditing) ? { minHeight: "560px" } : { minHeight: "375px" }}>
                            <div className="card-header">
                                <h6 className="m-0 font-weight-bold text-green">Datos paquetería</h6>
                            </div>
                            <div className="card-body">

                                <div className="form-row">

                                    <div className="form-group col-md-12">
                                        <div>
                                            <label style={{ fontWeight: 'bold' }} htmlFor="input_plazoEntrega">Plazo Entrega Paquetería</label>
                                            {(props.isEdditing === addingOrEditing.edditing) ?
                                                <select
                                                    value={editClientForm.plazoEntregaPaq}
                                                    className="form-control undefined"
                                                    id="formaPago"
                                                    onChange={(value) => {
                                                        dispatch(saveClientEditForm({
                                                            ...editClientForm,
                                                            plazoEntregaPaq: value.target.value
                                                        }))
                                                    }}>
                                                    <option defaultChecked={true} value={plazosEntrega.null}>{plazosEntrega.null}</option>
                                                    <option value={plazosEntrega.h12}>{plazosEntrega.h12}</option>
                                                    <option value={plazosEntrega.h24}>{plazosEntrega.h24}</option>
                                                    <option value={plazosEntrega.h48}>{plazosEntrega.h48}</option>
                                                    <option value={plazosEntrega.h72}>{plazosEntrega.h72}</option>
                                                </select>
                                                :
                                                <input
                                                    name="plazoEntrega"
                                                    disabled={true}
                                                    type="text"
                                                    value={client.plazoEntregaPaq}
                                                    className="form-control undefined"
                                                />
                                            }
                                        </div>
                                    </div>

                                    <ClientIncrSupl
                                        width={12}
                                        textToShow="Descuentos (%)"
                                        isEdditing={props.isEdditing}
                                        DescIncr={editClientForm.datosEconomicos.descuentosPaqueteria}
                                        deleteDescIncr={(descIncr) => {
                                            dispatch(saveClientEditForm({
                                                ...editClientForm,
                                                datosEconomicos: {
                                                    ...editClientForm.datosEconomicos,
                                                    descuentosPaqueteria: editClientForm.datosEconomicos.descuentosPaqueteria.filter(desc => desc.concepto !== descIncr.concepto)
                                                }
                                            }))
                                        }}
                                        saveDescIncr={(descIncr) => {
                                            dispatch(saveClientEditForm({
                                                ...editClientForm,
                                                datosEconomicos: {
                                                    ...editClientForm.datosEconomicos,
                                                    descuentosPaqueteria: [...editClientForm.datosEconomicos.descuentosPaqueteria, { concepto: descIncr.concepto, porcentaje: descIncr.porcentaje, value: descIncr.value }]
                                                }
                                            }))
                                        }}
                                    />

                                    <ClientIncrSupl
                                        width={12}
                                        textToShow="Incrementos (%)"
                                        isEdditing={props.isEdditing}
                                        DescIncr={editClientForm.datosEconomicos.incrementosPaqueteria}
                                        deleteDescIncr={(descIncr) => {
                                            dispatch(saveClientEditForm({
                                                ...editClientForm,
                                                datosEconomicos: {
                                                    ...editClientForm.datosEconomicos,
                                                    incrementosPaqueteria: editClientForm.datosEconomicos.incrementosPaqueteria.filter(desc => desc.concepto !== descIncr.concepto)
                                                }
                                            }))
                                        }}
                                        saveDescIncr={(descIncr) => {
                                            dispatch(saveClientEditForm({
                                                ...editClientForm,
                                                datosEconomicos: {
                                                    ...editClientForm.datosEconomicos,
                                                    incrementosPaqueteria: [...editClientForm.datosEconomicos.incrementosPaqueteria, { concepto: descIncr.concepto, porcentaje: descIncr.porcentaje, value: descIncr.value }]
                                                }
                                            }))
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="col-sm-6">
                    <div className="card shadow mb-4" style={(props.isEdditing === addingOrEditing.edditing) ? { minHeight: "560px" } : { minHeight: "375px" }}>
                        <div className="card-header">
                            <h6 className="m-0 font-weight-bold text-green">Datos carga completa</h6>
                        </div>
                        <div className="card-body">
                            <div className="form-row">

                                <ClientIncrSupl
                                    width={12}
                                    textToShow="Descuentos (%)"
                                    isEdditing={props.isEdditing}
                                    DescIncr={editClientForm.datosEconomicos.descuentosCarga}
                                    deleteDescIncr={(descIncr) => {
                                        dispatch(saveClientEditForm({
                                            ...editClientForm,
                                            datosEconomicos: {
                                                ...editClientForm.datosEconomicos,
                                                descuentosCarga: editClientForm.datosEconomicos.descuentosCarga.filter(desc => desc.concepto !== descIncr.concepto)
                                            }
                                        }))
                                    }}
                                    saveDescIncr={(descIncr) => {
                                        dispatch(saveClientEditForm({
                                            ...editClientForm,
                                            datosEconomicos: {
                                                ...editClientForm.datosEconomicos,
                                                descuentosCarga: [...editClientForm.datosEconomicos.descuentosCarga, { concepto: descIncr.concepto, porcentaje: descIncr.porcentaje, value: descIncr.value }]
                                            }
                                        }))
                                    }}
                                />

                                <ClientIncrSupl
                                    width={12}
                                    textToShow="Incrementos (%)"
                                    isEdditing={props.isEdditing}
                                    DescIncr={editClientForm.datosEconomicos.incrementosCarga}
                                    deleteDescIncr={(descIncr) => {
                                        dispatch(saveClientEditForm({
                                            ...editClientForm,
                                            datosEconomicos: {
                                                ...editClientForm.datosEconomicos,
                                                incrementosCarga: editClientForm.datosEconomicos.incrementosCarga.filter(desc => desc.concepto !== descIncr.concepto)
                                            }
                                        }))
                                    }}
                                    saveDescIncr={(descIncr) => {
                                        dispatch(saveClientEditForm({
                                            ...editClientForm,
                                            datosEconomicos: {
                                                ...editClientForm.datosEconomicos,
                                                incrementosCarga: [...editClientForm.datosEconomicos.incrementosCarga, { concepto: descIncr.concepto, porcentaje: descIncr.porcentaje, value: descIncr.value }]
                                            }
                                        }))
                                    }}
                                />

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {(suscriptionData.crmClientes) &&
                <div>
                    {(
                        (props.isEdditing === addingOrEditing.edditing && editClientForm.email.length > 0) ||
                        (props.isEdditing !== addingOrEditing.edditing && client.email.length > 0)
                    ) &&
                        <UserPermissionForm
                            userType={usersTypes.client}
                            userLoggedType={userLogged.userType}
                            userLogguedPermissions={userLogged.permisosUsuario}
                            isEdditing={props.isEdditing}
                            nif={editClientForm.nif}
                            associatedId={editClientForm.permisosUsuario.idAssociated}
                            permisosUsuario={client.permisosUsuario}
                            permisosUsuarioEdit={editClientForm.permisosUsuario}
                            setPermisosUsuarioEdit={(permisos) => {
                                dispatch(saveClientEditForm({
                                    ...editClientForm,
                                    permisosUsuario: permisos
                                }));
                            }}
                        />
                    }
                </div>
            }

            <ReactTooltip /> {/*Para sacar notas*/}

        </Fragment>

    );
};

export default ClientForm;